<template>
  <div class="margin-bottom">
    <el-row
      :gutter="20"
      type="flex"
    >
      <el-col :span="6">
        <el-card class="mode">
          <h4 class="margin-bottom">{{ $t("search_engine") }}</h4>
          <search-engine-select
            v-model="searchEngineSelect"
            v-if="searchEngineSelect"
          />
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="mode">
          <frequency-mode v-model="frequencyButton" />
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="height-auto">
          <div class="container">
            <div class="inputs-sections">
              <el-form
                size="mini"
                :inline="true"
                @submit.native.prevent
              >
                <div>
                  <tags-groups-item
                    v-model="tagsGroups"
                    :tags="formattedTags"
                  ></tags-groups-item>
                </div>
                <div class="flex-box">
                  <period-picker
                    v-model="period"
                    :frequency="frequencyButton"
                  ></period-picker>
                  <div class="margin-auto">
                    <el-button
                      type="primary"
                      @click="submit"
                      :loading="isLoading"
                      >{{ $t("search") }}</el-button
                    >
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import TagsGroupsItem from "@/components/formItems/TagsGroupsItem";
import FrequencyMode from "@/components/FrequencyMode";
import PeriodPicker from "@/components/formItems/PeriodPicker";
import SearchEngineSelect from "@/components/SearchEngineSelect";

export default {
  props: {
    initialPeriod: Array,
    tags: Array,
    initialSearchEngine: Object,
    isLoading: Boolean,
  },
  components: {
    TagsGroupsItem,
    PeriodPicker,
    FrequencyMode,
    SearchEngineSelect,
  },
  data() {
    return {
      tagsGroups: [[]],
      period: this.initialPeriod,
      frequencyButton: "WEEK",
      searchEngineSelect: this.initialSearchEngine,
    };
  },
  methods: {
    submit() {
      const params = {
        tagsGroups: JSON.parse(JSON.stringify(this.tagsGroups)),
        startDate: this.period[0],
        endDate: this.period[1],
        rangeDate: {
          start: this.period[0].toString().split("T")[0],
          end: this.period[1].toString().split("T")[0],
        },
        frequency: this.frequencyButton,
        searchEngine: this.searchEngineSelect,
      };
      this.$emit("searchParametersChanged", params);
    },
  },
  watch: {
    frequencyButton() {
      if (this.frequencyButton === "DAY") {
        const start = DateTime.utc().startOf("week").minus({ weeks: 4 });
        const end = DateTime.utc().startOf("day");
        this.period = [start, end];
      } else if (this.frequencyButton === "WEEK") {
        const start = DateTime.utc().startOf("week").minus({ weeks: 12 });
        const end = DateTime.utc().startOf("week");
        this.period = [start, end];
      }
      this.submit();
    },
    searchEngineSelect() {
      this.submit();
    },
  },
  computed: {
    formattedTags() {
      if (this.tags && this.tags.length > 0) {
        let formattedTags = [];
        formattedTags = this.tags.map((tag) => {
          return {
            label:
              tag.name === "None"
                ? this.$t(tag.name.toLowerCase())
                : tag.name + " (" + tag.count + ")",
            value: tag.name,
          };
        });
        return formattedTags;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-bottom {
  margin-bottom: 1rem;
}
::v-deep .el-card__body {
  height: 100%;
}
.mode {
  height: 100%;
  text-align: center;
  font-size: 1rem;
}
.height-auto {
  height: 100%;
}
.container {
  .inputs-sections {
    ::v-deep .el-date-editor--daterange.el-input__inner {
      width: 300px;
    }
  }
  .flex-box {
    display: flex;
    .margin-auto {
      margin-left: auto;
    }
  }
  .action {
    flex: 0;
    text-align: right;
  }
}
</style>

<i18n>
{
  "en": {
    "search": "Search",
    "period": "Period",
    "daily": "Daily",
    "weekly": "Weekly",
    "search_engine": "Search Engine",
    "none": "None"
  },
  "fr": {
    "search": "Rechercher",
    "period": "Fréquence",
    "daily": "Jour",
    "weekly": "Semaine",
    "search_engine": "Moteur de recherche",
    "none": "Aucun"
  },
  "de": {
    "search": "Forschen",
    "period": "Frequenz",
    "daily": "Tag",
    "weekly": "Woche",
    "search_engine": "Suchmaschine",
    "none": "Keiner"
  }
}
</i18n>
