<template>
  <el-select
    v-model="isoCountryCode"
    class="select"
  >
    <el-option
      v-for="country in countries"
      :key="country.locationId"
      :label="country.name"
      :value="country.isoCode"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: String,
    countries: Array,
  },
  computed: {
    isoCountryCode: {
      get() {
        return this.value;
      },
      set(isoCountryCode) {
        this.$emit("input", isoCountryCode);
      },
    },
  },
};
</script>
