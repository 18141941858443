<template>
  <div>
    <el-tag
      v-for="tag in tags"
      :key="tag"
      size="small"
      closable
      @close="removeTag(tag)"
    >
      {{ $t(tag) }}
    </el-tag>
    <el-popover
      v-model="popoverVisible"
      placement="bottom-start"
      width="auto"
      popper-class="tag-input-popover"
    >
      <el-select
        v-model="tagInput"
        filterable
        :placeholder="$t('select_tag')"
        @change="addTag"
      >
        <el-option
          v-for="item in tagsOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        class="add-value-button"
        slot="reference"
        >+ {{ $t("add_tag") }}</el-button
      >
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    tagsOptions: Array,
  },
  data() {
    return {
      tagInput: "",
      popoverVisible: false,
    };
  },
  computed: {
    tags: {
      get() {
        return this.value;
      },
      set(tags) {
        this.$emit("input", tags);
      },
    },
  },
  methods: {
    addTag() {
      if (this.tagInput == "None") {
        this.value.splice(0, this.value.length);
        this.value.push("None");
      } else {
        const index = this.value.indexOf("None");
        if (index > -1) {
          this.value.splice(index, 1);
        }
        if (this.tagInput) this.value.push(this.tagInput);
      }

      this.tagInput = "";
    },
    removeTag(tag) {
      this.value.splice(this.value.indexOf(tag), 1);
    },
  },
};
</script>

<style lang="scss">
.tag-input-popover {
  ::v-deep .el-select .el-input__inner {
    width: 300px;
  }
}
</style>
<style lang="scss" scoped>
.el-tag {
  margin: 0 0.25rem 0.25rem 0;
}

.add-value-button {
  font-size: 12px;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
}
</style>

<i18n>
{
  "en": {
    "tags_filter": "Tags filter",
    "select_tag": "Select tag",
    "add_tag": "Add",
    "None": "None"
  },
  "fr": {
    "tags_filter": "Filtre de groupes",
    "select_tag": "Sélectionnez un groupe",
    "add_tag": "Ajouter",
    "None": "Aucun"
  },
  "de": {
    "tags_filter": "Gruppenfilter",
    "select_tag": "Wählen Sie eine Gruppe aus",
    "add_tag": "Hinzufügen",
    "None": "Keiner"
  }
}
</i18n>
