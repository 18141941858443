<template>
  <div>
    <div
      class="summary"
      v-loading="isLoading"
    >
      <div class="graph">
        <summary-graph
          :average-grades="averageGrades"
          :crawl="crawl"
          :contentData="contentOptimizationData"
        >
        </summary-graph>
      </div>
      <div class="divider">
        <el-divider
          direction="vertical"
          class="vertical"
        />
      </div>
      <div class="grades">
        <categories
          :data="data"
          :contentData="contentOptimizationData"
          :average-grades="averageGrades"
          :crawl="crawl"
          :is-content-optimization-loading="isContentOptimizationLoading"
        >
        </categories>
      </div>
    </div>
  </div>
</template>
<script>
import SummaryGraph from "@/pages/ActionPlan/Summary/Graph";
import Categories from "@/pages/ActionPlan/Summary/Categories";
import Tasks from "@/pages/ActionPlan/Summary/Tasks";

import { RepositoryFactory } from "@/services/repositoryFactory";
const RecommendationsRepository = RepositoryFactory.get(
  "recommendationsRepository"
);

export default {
  components: {
    SummaryGraph,
    Categories,
    Tasks,
  },
  props: {
    keyword: Object,
    data: Object,
    contentOptimizationData: Object,
    isLoading: Boolean,
    isContentOptimizationLoading: Boolean,
    crawl: Object,
  },
  data() {
    return {};
  },
  methods: {
    averageGrade(type) {
      if (this.data && this.data[type]) {
        const contentDetails = this.data[type];
        let numberOfGrade = 0;
        let sumScore = 0;
        contentDetails.forEach((element) => {
          sumScore = sumScore + element.score;
          numberOfGrade = numberOfGrade + 1;
        });
        return Math.round(sumScore / numberOfGrade);
      }
    },
    averageContentGrade() {
      if (!this.contentOptimizationData) return 0;
      return Math.round(this.contentOptimizationData.score * 100);
    },
  },
  computed: {
    averageGrades() {
      return {
        avgContent: this.averageContentGrade(),
        avgTechnical: this.averageGrade("technicalDetails"),
        avgTagsUrl: this.averageGrade("tagsUrlDetails"),
        avgNetlinking: this.averageGrade("netlinkingDetails"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  display: flex;

  .grades {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  .graph {
    margin-bottom: 1rem;
  }

  .divider {
    margin: 0px 8px 0px 8px;

    .vertical {
      height: 100%;
    }
  }
}
</style>
