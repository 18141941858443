<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="40%"
    top="5vh"
  >
    <div class="d-flex mb-4">
      <h3 class="mr-auto ml-auto">{{ $t("reasonOfRefusal") }}</h3>
    </div>
    <el-form
      label-width="200px"
      label-position="right"
    >
      <el-form-item :label="$t('reason')">
        <el-select v-model="form.reasonOfRefusal">
          <el-option
            v-for="item in refusalOptions"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('details')">
        <el-input
          type="textarea"
          rows="3"
          v-model="form.detailsOfRefusal"
        />
      </el-form-item>
    </el-form>
    <div class="d-flex mt-4">
      <el-button
        class="mr-auto ml-auto"
        type="success"
        plain
        @click="submit()"
      >
        {{ $t("refuse") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    backlink: Object,
  },
  data() {
    return {
      refusalOptions: [
        { label: "metricsTooLow", value: "METRICS_TOO_LOW" },
        { label: "wrongThemathic", value: "WRONG_THEMATIC" },
        { label: "other", value: "OTHER" },
      ],
      form: {
        reasonOfRefusal: "METRICS_TOO_LOW",
        detailsOfRefusal: "",
      },
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    submit() {
      this.$emit("refuseBacklink", this.form);
    },
  },
};
</script>

<style></style>
<i18n>
{
  "en": {
    "reasonOfRefusal": "Reason for refusal",
    "refuse": "Refuse",
    "details": "Specify the reason",
    "reason": "Reason",
    "metricsTooLow": "Metrics are too low",
    "wrongThemathic": "Wrong themathic",
    "other": "Other"
  },
  "fr": {
    "reasonOfRefusal": "Raison du refus",
    "refuse": "Refuser le backlink",
    "details": "Précisez la raison",
    "reason": "Raison",
    "metricsTooLow": "Métriques trop basses",
    "wrongThemathic": "Mauvaise thématique",
    "other": "Autre"
  },
  "de": {
    "reasonOfRefusal": "Ablehnungsgrund",
    "refuse": "Backlink verweigern",
    "details": "Geben Sie den Grund an",
    "reason": "Raison",
    "metricsTooLow": "Zu niedrige Metriken",
    "wrongThemathic": "Schlechtes Thema",
    "other": "Andere"
  }
}
</i18n>
