<template>
  <div>
    <div class="flex-box">
      <div class="right">
        <el-button
          size="mini"
          @click="exportExcel()"
        >
          <i class="fas fa-file-excel"></i> {{ $t("export") }}
        </el-button>
      </div>
    </div>
    <div v-if="competitors !== null">
      <ag-grid-vue
        style="height: 800px"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :gridOptions="gridOptions"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :resizable="true"
        :filter="true"
        :floatingFilter="true"
        :localeTextFunc="localeTextFunc"
        :suppressRowClickSelection="true"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        :context="context"
        @grid-ready="onGridReady"
        @first-data-rendered="adjustGrid"
        @row-data-changed="adjustGrid"
        @grid-size-changed="adjustGrid"
      >
      </ag-grid-vue>
      <create-competitor-dialog
        :dialogVisible="dialogVisible"
        :study-links="studyLinks"
        :competitorUrl="competitorUrl"
        @create-competitor="createCompetitor($event)"
        @close-dialog="closeCreateCompetitorDialog()"
      >
      </create-competitor-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import WebsiteRenderer from "@/pages/Competitors/Grids/Shared/WebsiteRenderer";
import ActionsRenderer from "@/pages/Competitors/Grids/Shared/ActionsRenderer";
import NewCompetitorForm from "@/pages/Competitors/Grids/Shared/NewCompetitorForm";
import CompetitorsActionsRenderer from "@/components/gridRenderers/CompetitorsActionsRenderer";
import CreateCompetitorDialog from "@/components/CreateCompetitorDialog";

export default {
  mixins: [agGridMixin],
  props: {
    shareOfVoices: Array,
    allCompetitors: Array,
    competitorTypeOptions: Array,
    studyId: Number,
  },
  components: {
    AgGridVue,
    WebsiteRenderer,
    ActionsRenderer,
    NewCompetitorForm,
    CompetitorsActionsRenderer,
    CreateCompetitorDialog,
  },
  data() {
    return {
      rowData: null,
      gridOptions: {
        onColumnGroupOpened: () => this.adjustGrid(),
        enableRangeSelection: true,
        copyHeadersToClipboard: true,
      },
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      gridApi: null,
      columnApi: null,
      dialogVisible: false,
      competitorUrl: null,
      competitors: null,
    };
  },
  computed: {
    context() {
      return {
        competitors: this.competitors,
        showDialog: this.showCreateCompetitorDialog,
        studyLinks: this.studyLinks,
      };
    },
    ...mapState(["study", "studyLinks"]),
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
      if (this.shareOfVoices) this.mapData();
    },
    adjustGrid() {
      if (this.columnApi != null) {
        if (this.columnApi.columnController.scrollWidth > 0) {
          this.gridApi.sizeColumnsToFit();
        }
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("website"),
          field: "url",
          minWidth: 165,
          filter: "agTextColumnFilter",
          cellRendererFramework: "WebsiteRenderer",
        },
        {
          headerName: this.$i18n.t("bucket1to3"),
          width: 200,
          children: [
            {
              headerName: this.$i18n.t("trafficPercentage"),
              field: "top3TrafficRatio",
              valueFormatter: percentageFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
            },
            {
              headerName: this.$i18n.t("numberOfKeywords"),
              field: "top3KeywordsCount",
              valueFormatter: numberFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
              columnGroupShow: "open",
            },
            {
              headerName: this.$i18n.t("traffic"),
              field: "top3Traffic",
              valueFormatter: numberFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: this.$i18n.t("bucket4to10"),
          children: [
            {
              headerName: this.$i18n.t("trafficPercentage"),
              field: "top4To10TrafficRatio",
              valueFormatter: percentageFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
            },
            {
              headerName: this.$i18n.t("numberOfKeywords"),
              field: "top4To10KeywordsCount",
              valueFormatter: numberFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
              columnGroupShow: "open",
            },
            {
              headerName: this.$i18n.t("traffic"),
              field: "top4To10Traffic",
              valueFormatter: numberFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: this.$i18n.t("bucket11to20"),
          children: [
            {
              headerName: this.$i18n.t("trafficPercentage"),
              field: "top11to20TrafficRatio",
              valueFormatter: percentageFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
            },
            {
              headerName: this.$i18n.t("numberOfKeywords"),
              field: "top11to20KeywordsCount",
              valueFormatter: numberFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
              columnGroupShow: "open",
            },
            {
              headerName: this.$i18n.t("traffic"),
              field: "top11to20Traffic",
              valueFormatter: numberFormatter,
              type: "numericColumn",
              filter: "agNumberColumnFilter",
              filterParams: {
                defaultOption: "greaterThan",
              },
              suppressMenu: true,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: this.$i18n.t("actions"),
          headerClass: "ag-centered-header",
          minWidth: 175,
          maxWidth: 175,
          cellRendererFramework: "CompetitorsActionsRenderer",
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ];
    },
    mapData() {
      this.rowData = this.shareOfVoices.map((urlData) => {
        return {
          url: urlData.url.url,
          top3KeywordsCount: urlData.bucket1to3.numberOfKeywords,
          top3Traffic: urlData.bucket1to3.estimatedTraffic,
          top3TrafficRatio: urlData.bucket1to3.percentageOfTrafficShare,
          top4To10KeywordsCount: urlData.bucket4to10.numberOfKeywords,
          top4To10Traffic: urlData.bucket4to10.estimatedTraffic,
          top4To10TrafficRatio: urlData.bucket4to10.percentageOfTrafficShare,
          top11to20KeywordsCount: urlData.bucket11to20.numberOfKeywords,
          top11to20Traffic: urlData.bucket11to20.estimatedTraffic,
          top11to20TrafficRatio: urlData.bucket11to20.percentageOfTrafficShare,
        };
      });
    },
    showCreateCompetitorDialog(url) {
      this.dialogVisible = true;
      this.competitorUrl = url;
    },
    createCompetitor(form) {
      const create = this.studyLinks.competitors.create;
      this.$api[create.method.toLowerCase()](create.href, form)
        .then(() => {
          this.fetchStudy();
          const params = {
            force: true,
            suppressFlash: false,
          };
          this.gridApi.refreshCells(params);
          this.$message({
            message: this.$i18n.t("competitorCreationSuccess"),
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            message: this.$i18n.t("competitorCreationFailure"),
            type: "error",
            duration: 3000,
          });
        });
      this.dialogVisible = false;
    },
    closeCreateCompetitorDialog() {
      this.dialogVisible = false;
    },
    fetchStudy() {
      const list = this.studyLinks.competitors.list;
      this.$api[list.method.toLowerCase()](list.href)
        .then((response) => {
          this.competitors = response.data.data.map((e) => {
            return {
              url: e.url,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    exportExcel() {
      const params = {
        skipHeader: false,
        onlySelected: false,
        fileName: "Top competitors - SmartKeyword",
        sheetName: "Top competitors",
        columnGroups: true,
        allColumns: true,
      };

      this.gridApi.exportDataAsExcel(params);
    },
  },
  watch: {
    shareOfVoices() {
      if (this.shareOfVoices) this.mapData();
    },
  },
  created() {
    this.fetchStudy();
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let formatPercentage = (number) => {
  return number.toString() + "%";
};

let numberFormatter = (params) => formatNumber(params.value);
let percentageFormatter = (params) => formatPercentage(params.value);
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.flex-box {
  display: flex;
  flex-direction: horizontal;

  .right {
    margin-left: auto;
  }
}

::v-deep .ag-centered-header {
  .ag-header-cell-label {
    justify-content: center;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "website": "Website",
    "bucket1to3": "1-3 Ranking",
    "bucket4to10": "4-10 Ranking",
    "bucket11to20": "11-20 Ranking",
    "numberOfKeywords": "# Keywords",
    "traffic": "Traffic",
    "trafficPercentage": "% Estimated traffic",
    "actions": "Actions",
    "export": "Export"
  },
  "fr": {
    "website": "Site",
    "bucket1to3": "Positions 1-3",
    "bucket4to10": "Positions 4-10",
    "bucket11to20": "Positions 11-20",
    "numberOfKeywords": "# Keywords",
    "traffic": "Trafic",
    "trafficPercentage": "% Trafic  estimé",
    "actions": "Actions",
    "Export": "Exporter"
  },
  "de": {
    "website": "Grundstück",
    "bucket1to3": "Plätze 1-3",
    "bucket4to10": "Plätze 4-10",
    "bucket11to20": "Plätze 11-20",
    "numberOfKeywords": "# Schlüsselwörter",
    "traffic": "Verkehr",
    "trafficPercentage": "% Geschätzt verkehr",
    "actions": "Aktionen",
    "Export": "Exporteur"
  }
}
</i18n>
