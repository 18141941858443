import Repository from "@/services/repository";

export default {
  createKeywords(studyId, texts, tags) {
    return Repository.post(`/a/${studyId}/keywords.json`, {
      keywords: {
        texts,
        tagNames: tags,
      },
    }).then((response) => response.data);
  },

  searchKeywords(studyId, text) {
    return Repository.get(`/a/${studyId}/keywords/search.json`, {
      params: {
        searchQuery: text,
      },
    }).then((response) => response.data);
  },
};
