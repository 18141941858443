import Repository from "@/services/repository";

export default {
  getCompetitor(studyId, competitorId) {
    return Repository.get(`a/${studyId}/competitors/${competitorId}.json`).then(
      (response) => response.data
    );
  },

  getCompetitors(studyId, competitorType) {
    return Repository.get(`a/${studyId}/competitors.json`, {
      params: {
        ...{ competitor_type: competitorType },
      },
    }).then((response) => response.data);
  },

  createCompetitor(studyId, competitorHost, competitorType) {
    return Repository.post(`a/${studyId}/competitors.json`, {
      url: competitorHost,
      competitor_type: competitorType,
    }).then((response) => response.data);
  },

  updateCompetitorTypeOfCompetitor(studyId, competitorId, newCompetitorType) {
    return Repository.patch(
      `a/${studyId}/competitors/${competitorId}/add_competitor_types/${competitorId}.json`,
      { newCompetitorType, competitorId }
    ).then((response) => response.data);
  },

  deleteCompetitor(studyId, competitorId) {
    return Repository.delete(
      `a/${studyId}/competitors/${competitorId}.json`
    ).then((response) => response.data);
  },
};
