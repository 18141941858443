<template>
  <task
    :task="task"
    :current-user="currentUser"
    :user-options="userOptions"
    :study-id="studyId"
    creating-task
    @on-update="(partialTask) => update(partialTask)"
  >
    <template v-slot:top-side>
      <el-button
        class="validate"
        type="success"
        plain
        @click="createTask"
      >
        <i class="fas fa-check"></i> {{ $t("create_task") }}
      </el-button>
      <el-divider />
    </template>
  </task>
</template>

<script>
import Task from "./Task";

export default {
  components: {
    Task,
  },
  props: {
    status: String,
    currentUser: Object,
    userOptions: Array,
    studyId: Number,
    title: String,
    assignedToUserId: Number,
    keywords: Array,
  },
  data() {
    return {
      task: {
        title: this.title || this.$i18n.t("title"),
        description: {},
        status: this.status,
        blocks: [],
        watcherIds: [],
        watchers: [],
        assignedToUserId: this.assignedToUserId,
        assignedToUser: {
          id: this.assignedToUserId,
          firstName: this.currentUser.first_name,
          lastName: this.currentUser.last_name,
        },
        keywords: this.keywords || [],
        pages: [],
        files: [],
        createdByUserId: this.currentUser.id,
      },
    };
  },
  methods: {
    createTask() {
      if (!this.studyId) {
        this.$message({
          message: this.$i18n.t("task_created_failure"),
          type: "error",
          duration: 6000,
        });
        console.log("Cannot create task without study id.");
      } else {
        this.$api
          .post(`/studies/${this.studyId}/tasks`, this.task)
          .then((response) => {
            const data = response.data.data;
            this.$emit("on-created-task", data);
            this.$message({
              message: this.$i18n.t("task_created_success"),
              type: "success",
              duration: 6000,
            });
          })
          .catch((error) => {
            this.$message({
              message: this.$i18n.t("task_created_failure"),
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },
    update(partialTask) {
      this.task = { ...this.task, ...partialTask };
      if ("assignedToUserId" in partialTask) {
        const user = this.userOptions.find(
          (u) => u.id == partialTask.assignedToUserId
        );
        this.task.assignedToUser = user;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.validate {
  width: 100%;
}
</style>

<i18n>
{
  "en": {
    "task_created_failure": "Task creation failed",
    "task_created_success": "Task created successfully",
    "title": "Title",
    "create_task": "Create task"
  },
  "fr": {
    "task_created_failure": "La création de la tâche a échoué",
    "task_created_success": "Tâche créée avec succès",
    "title": "Titre",
    "create_task": "Créer la tâche"
  },
  "de": {
    "task_created_failure": "Aufgabenerstellung fehlgeschlagen",
    "task_created_success": "Aufgabe erfolgreich erstellt",
    "title": "Titel",
    "create_task": "Aufgabe erstellen"
  }
}
</i18n>
