var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',_vm._l((_vm.richResults),function(richResult,index){return _c('el-tooltip',{key:index,attrs:{"placement":"top","transition":"el-fade-in"}},[_c('div',{staticClass:"center",attrs:{"slot":"content"},slot:"content"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.richResultName(richResult.snippet)))])]),_vm._v(" "),_c('br'),_vm._v(" "),(
          richResult.isPositionned &&
          _vm.isCompetitorPositionned(richResult.snippet)
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t("you_are_both_positionned_on_this_snippet")))]):(_vm.isCompetitorPositionned(richResult.snippet))?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t("your_competitor_is_positionned_on_this_snippet")))]):(richResult.isPositionned)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t("you_are_positionned_on_this_snippet")))]):_vm._e()]),_vm._v(" "),(
        !['featured_snippet', 'other', 'related'].includes(
          richResult.snippet.toLowerCase()
        )
      )?_c('i',{staticClass:"fas",class:[
        _vm.richResultClass(richResult.snippet),
        _vm.isHostPositionnedClass(richResult) ]}):_vm._e()])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }