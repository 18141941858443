<template>
  <div class="container">
    <div class="date">
      {{ $t("period") }}
      <el-date-picker
        v-model="period"
        type="daterange"
        unlink-panels
        :format="datePickerFormat"
        :start-placeholder="$t('start_date')"
        :end-placeholder="$t('end_date')"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dates: Object,
  },
  data() {
    return {
      period: null,
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: this.$i18n.t("lastWeek"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              this.period = [start, end];
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("lastMonth"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              this.period = [start, end];
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("last3Month"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              this.period = [start, end];
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("lastYear"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              this.period = [start, end];
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
    };
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
  },
  methods: {
    setDefaultPeriod() {
      if (this.dates.start_date) {
        return [this.dates.start_date, this.dates.end_date];
      } else {
        const today = new Date();
        const default_end_date = new Date(today.setDate(today.getDate() - 3));
        const default_start_date = new Date(
          today.setDate(today.getDate() - 33)
        );

        return [default_start_date, default_end_date];
      }
    },
    updateDate() {
      this.$emit("pick", this.period);
    },
  },
  mounted() {
    this.period = this.setDefaultPeriod();
  },
  watch: {
    period() {
      this.updateDate();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 1rem;
}

.date {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "startDate": "Start date",
    "endDate": "End date",
    "seconds": "seconds",
    "lastMonth": "Last Month",
    "last3Month": "Last 3 Months",
    "lastWeek": "Last Week",
    "lastYear": "Last year",
    "period": "Period"
  },
  "fr": {
    "startDate": "Date de début",
    "endDate": "Date de fin",
    "seconds": "secondes",
    "lastMonth": "Mois dernier",
    "last3Month": "3 derniers mois",
    "lastWeek": "Semaine dernière",
    "lastYear": "Année dernière",
    "period": "Période"
  },
  "de": {
    "startDate": "Startdatum",
    "endDate": "Endtermin",
    "seconds": "Sekunden",
    "lastMonth": "Im vergangenen Monat",
    "last3Month": "letzten 3 Monate",
    "lastWeek": "Letzte Woche",
    "lastYear": "Letztes Jahr",
    "period": "Zeitraum"
  }
}
</i18n>
