<template>
  <div class="d-flex flex-column justify-center align-center">
    <h2>{{ $t("favorites") }}</h2>
    <a href=""
      ><i class="fas fa-question-circle"></i>
      {{ $t("favoritesConfiguration") }}</a
    >
    <br />

    <span class="title">{{ $t("favoriteKeywords") }}</span>
    <el-select
      filterable
      multiple
      :placeholder="$t('favoriteKeywords')"
      v-model="favoriteKeywords"
      class="mt-1"
    >
      <el-option
        v-for="item in keywords"
        :key="item.id"
        :label="item.text"
        :value="item.id"
      >
      </el-option>
    </el-select>

    <span class="title">{{ $t("favoriteTags") }}</span>
    <el-select
      filterable
      multiple
      :placeholder="$t('favoriteTags')"
      v-model="favoriteTags"
      class="mt-1"
    >
      <el-option
        v-for="item in tags"
        :key="item[1].id"
        :label="item[0]"
        :value="item[1].id"
      >
      </el-option>
    </el-select>

    <span class="title">{{ $t("competitors") }}</span>
    <el-select
      filterable
      multiple
      :placeholder="$t('competitors')"
      v-model="competitors"
      class="mt-1"
    >
      <el-option
        v-for="item in favoriteWebsites"
        :key="item.id"
        :label="item.root_domain"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <small class="small"> {{ $t("competitorsHint") }}</small>

    <div class="d-flex flex-colum justify-content-end mt-2">
      <el-button
        class="btn btn-outline-secondary"
        @click="updateFavorites()"
        >{{ $t("saveModifications") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";

const ConfigurationsRepository = RepositoryFactory.get("configurations");

export default {
  props: {
    keywords: Array,
    tags: Array,
    favoriteWebsites: Array,
    configuration: Object,
    study: Object,
  },
  data() {
    return {
      favoriteKeywords: null,
      favoriteTags: this.configuration.favorite_tags,
      competitors: null,
    };
  },

  methods: {
    updateFavorites() {
      ConfigurationsRepository.updateFavorites(this.configuration.account_id, {
        account_configuration: {
          favorite_keywords: this.favoriteKeywords,
          favorite_tags: this.favoriteTags,
          report_competitors: this.competitors,
        },
      })
        .then((data) => {
          this.$message.success({
            message: this.$i18n.t("updateFavoriteSuccess"),
            duration: 3000,
            showClose: true,
            offset: 80,
          });
          window.location.href = data.redirectUri;
        })
        .catch((error) => {
          this.$message.error({
            message: this.$i18n.t("updateFavoriteError"),
            duration: 3000,
            showClose: true,
            offset: 80,
          });
          console.error(error);
        });
    },
    mapFavoriteKeywords() {
      this.favoriteKeywords = this.configuration.favorite_keywords.map(
        (favoriteKeyword) => parseInt(favoriteKeyword)
      );
    },
    mapCompetitors() {
      this.competitors = this.configuration.report_competitors.map(
        (competitor) => parseInt(competitor)
      );
    },
  },
  mounted() {
    this.mapFavoriteKeywords();
    this.mapCompetitors();
  },
};
</script>

<style>
.title {
  margin-top: max(2.5vh, 16px);
  margin-bottom: 2px;
}

.small {
  margin-left: max(0.75vh, 2px);
  color: gray;
}
</style>

<i18n>
{
  "en": {
    "favorites": "Favorites",
    "saveModifications": "Save modifications",
    "updateFavoriteSuccess": "Favorites have been updated succesfully",
    "updateFavoriteError": "An error happened when updating Favorites",
    "favoritesConfiguration": "How to configure your favorites ?",
    "favoriteKeywords": "Favorite keywords",
    "competitorsHint": "Add your favorite competitors from tab \"Competition\"",
    "favoriteTags": "Favorite tags",
    "competitors": "Competitors"
  },
  "fr": {
    "favorites": "Favoris",
    "saveModifications": "Enregistrer les modifications",
    "updateFavoriteSuccess": "Les favories ont été modifiés avec succès",
    "updateFavoriteError": "Une erreur est survenue pendant la modification des favoris",
    "favoritesConfiguration": "Comment configurer ses favoris ?",
    "favoriteKeywords": "Mots-clés favoris",
    "competitorsHint": "Ajoutez vos concurrents favoris depuis l'onglet \"Concurrence\"",
    "favoriteTags": "Tags Favoris",
    "competitors": "Concurrents"
  },
  "de": {
    "favorites": "Favoriten",
    "saveModifications": "Änderungen speichern",
    "updateFavoriteSuccess": "Favoriten wurden erfolgreich geändert",
    "updateFavoriteError": "Beim Bearbeiten von Lesezeichen ist ein Fehler aufgetreten",
    "favoritesConfiguration": "Wie konfiguriere ich meine Favoriten?",
    "favoriteKeywords": "Lieblingsschlüsselwörter",
    "competitorsHint": "Fügen Sie Ihre Lieblingskonkurrenten über die Registerkarte \"Wettbewerb\" hinzu",
    "favoriteTags": "Tags Favoriten",
    "competitors": "Konkurrenten"
  }
}
</i18n>
