<template>
  <top-grids
    :is-admin="isAdmin"
    :data-top-backlinks="dataTopBacklinks"
    :data-top-keywords="dataTopKeywords"
    :data-top-pages="dataTopPages"
    :data-by-country="dataByCountry"
    :metrics="metrics"
    :request="request"
    :is-loading-backlinks="isLoadingBacklinks"
    :is-loading-metrics="isLoadingMetrics"
    :is-loading-pages="isLoadingPages"
    :is-loading-keywords="isLoadingKeywords"
    :is-loading-by-country="isLoadingByCountry"
    :studyId="studyId"
  >
  </top-grids>
</template>

<script>
import TopGrids from "@/pages/DomainSearch/Grids/TopGrids";
export default {
  components: {
    TopGrids,
  },
  props: {
    dataTopBacklinks: Object,
    dataTopPages: Array,
    dataTopKeywords: Array,
    dataByCountry: Array,
    metrics: Object,
    request: Object,
    isLoadingMetrics: Boolean,
    isLoadingBacklinks: Boolean,
    isLoadingPages: Boolean,
    isLoadingKeywords: Boolean,
    isLoadingByCountry: Boolean,
    isAdmin: Boolean,
    studyId: Number,
  },
  data() {
    return {
      activeTab: "tops",
    };
  },
};
</script>
<i18n>
{
  "en": {
    "tops": "Best data",
    "competition": "Competition"
  },
  "fr": {
    "tops": "Meilleurs classements",
    "competition": "Concurrence"
  },
  "de": {
    "tops": "Top-Rankings",
    "competition": "Übereinstimmung"
  }
}
</i18n>
