<template>
    <li class="nav-item pl-3">
      <a
        :href="ctrsConfigurationLink"
        class="nav-link"
      >
        {{$t('ctrsConfiguration')}}
      </a>
    </li>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    data() {
      return {
        ctrsConfigurationLink: null,
      };
    },
    computed: {
      ...mapState(["study", "studyLinks"]),
    },
    mounted() {
      this.ctrsConfigurationLink = `/spa-app/studies/${this.study["id"]}/configuration?tab=ctrsConfiguration`;
    },
  };
  </script>
  
<i18n>
  {
    "en": {
      "ctrsConfiguration": "CTRs Configuration"
    },
    "fr": {
      "ctrsConfiguration": "Configuration des CTRs"
    },
    "de": {
      "ctrsConfiguration": "Konfiguration CTRs"
    }
  }
  </i18n>
  