<template>
  <div>
    <app-introduction
      :title="$t('conversion')"
      :documentations="[
        {
          description: $t('documentation_desc'),
          link: $t('documentationLink'),
        },
      ]"
      :user="currentUser"
    >
      <template v-slot:right>
        <el-button
          icon="el-icon-setting"
          @click="settingsDialog = true"
        ></el-button>
        <el-dialog :visible.sync="settingsDialog">
          <conversion-monitoring
            :configuration="configuration"
            :goals="goals"
            @goals-submitted="reloadPage()"
          ></conversion-monitoring>
        </el-dialog>
      </template>
    </app-introduction>

    <div class="container">
      <el-card>
        <reporting-form
          :dimensions-data="dimensionsData"
          :period="period"
          :frequency="frequency"
          :account-goals="accountGoals"
          :conversion-monitoring-type="conversionMonitoringType"
          @form-update="updateData"
        >
        </reporting-form>
      </el-card>
      <div class="metrics">
        <el-row
          type="flex"
          :gutter="20"
        >
          <el-col :span="6">
            <el-card>
              <reporting-metric
                :metrics="metrics"
                :conversion-monitoring-type="conversionMonitoringType"
                main-text="conversion.seo_conversion"
                main-field="conversions"
                total-text="conversion.ratio_total_conversion"
                total-field="total_conversions"
                comparative-text="vs_previous_period"
                comparative-field="comparative_conversions"
              >
              </reporting-metric>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <reporting-metric
                :metrics="metrics"
                :conversion-monitoring-type="conversionMonitoringType"
                main-text="conversion.sea_conversion"
                main-field="sea_conversions"
                total-text="conversion.ratio_total_conversion"
                total-field="totalConversions"
                comparative-text="vs_previous_period"
                comparative-field="comparative_sea_conversions"
              >
              </reporting-metric>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <reporting-metric
                :metrics="metrics"
                :conversion-monitoring-type="conversionMonitoringType"
                main-text="conversion.brand_seo_conversion"
                main-field="brand_conversions"
                total-text="conversion.ratio_seo_conversion"
                total-field="conversions"
                comparative-text="vs_previous_period"
                comparative-field="brand_comparative_conversions"
              >
              </reporting-metric>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <reporting-metric
                :metrics="metrics"
                :conversion-monitoring-type="conversionMonitoringType"
                main-text="conversion.non_brand_seo_conversion"
                main-field="non_brand_conversions"
                total-text="conversion.ratio_seo_conversion"
                total-field="conversions"
                comparative-text="vs_previous_period"
                comparative-field="non_brand_comparative_conversions"
              >
              </reporting-metric>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <reporting-metric
                :metrics="metrics"
                :conversion-monitoring-type="conversionMonitoringType"
                main-text="conversion.total_conversion_over_period"
                main-field="total_conversions"
                comparative-text="vs_previous_period"
                comparative-field="total_comparative_conversions"
              >
              </reporting-metric>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <el-card>
        <dimension-selector
          v-model="dimension"
          :dimensions-data="dimensionsData"
        ></dimension-selector>
        <conversion-loader
          :dimension="dimension"
          :dimensions-data="dimensionsData"
          :study-id="accountId"
          :filters="filters"
          :period="period"
          :comparative-period="comparativePeriod"
          :frequency="frequency"
          #default="conversionLoaderProps"
        >
          <evolution
            :main-series="conversionLoaderProps.mainSeries"
            :conversion-groups="conversionLoaderProps.conversionGroups"
            :comparative-conversion-groups="
              conversionLoaderProps.comparativeConversionGroups
            "
            :comparative-period="conversionLoaderProps.comparativePeriod"
          ></evolution>
        </conversion-loader>
      </el-card>
      <el-card>
        <el-tabs
          v-model="activeTab"
          :stretch="true"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            :label="$t('pages')"
            name="pages"
          >
            <page-grid
              :account-id="accountId"
              :dimensions-data="dimensionsData"
              :filters="filters"
              :period="period"
              :comparative-period="comparativePeriod"
              :goals="goals"
              :conversion-monitoring-type="conversionMonitoringType"
              @show-brief="showDialog($event)"
            >
            </page-grid>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('keywords')"
            name="keywords"
            lazy
          >
            <keyword-grid
              :account-id="accountId"
              :dimensions-data="dimensionsData"
              :filters="filters"
              :period="period"
              :comparative-period="comparativePeriod"
              :goals="goals"
              :conversion-monitoring-type="conversionMonitoringType"
            >
            </keyword-grid>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <el-dialog
      v-if="grDialogVisible"
      :visible.sync="grDialogVisible"
      :key="struct.url"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h4>
          Page :
          <a
            :href="pageUrl"
            target="_blank"
            >{{ pageUrl }}</a
          >
        </h4>
      </div>
      <google-results-page
        v-if="period && period.length > 0"
        :url="pageUrl"
        :initial-date="endDateAsDateTime"
        :initial-past-date="startDateAsDateTime"
        :current-user="currentUser"
      ></google-results-page>
    </el-dialog>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { RepositoryFactory } from "@/services/repositoryFactory";
const ConversionsRepository = RepositoryFactory.get("conversions");
import AppIntroduction from "@/components/AppIntroduction";
import ConversionLoader from "@/components/ConversionLoader";
import DimensionSelector from "@/components/DimensionSelector";
import ReportingForm from "@/components/forms/ReportingForm";
import ReportingMetric from "@/components/ReportingMetric";
import PageGrid from "@/pages/Conversion/PageGrid";
import KeywordGrid from "@/pages/Conversion/KeywordGrid";
import GoogleResultsPage from "@/pages/GoogleResults";
import ConversionMonitoring from "@/components/StudyConfiguration/ConversionMonitoring";

import Evolution from "./Evolution";

export default {
  components: {
    AppIntroduction,
    ConversionLoader,
    ConversionMonitoring,
    DimensionSelector,
    Evolution,
    KeywordGrid,
    PageGrid,
    ReportingForm,
    ReportingMetric,
    GoogleResultsPage,
  },
  props: {
    accountId: Number,
    accountGoals: Array,
    configuration: Object,
    conversionMonitoringType: String,
    currentUser: Object,
    dimensionsData: Array,
    initialGoals: Array,
  },
  data() {
    return {
      filters: [],
      period: [],
      comparativePeriod: null,
      frequency: "day",
      goals: this.initialGoals,
      metrics: {},
      activeTab: "pages",
      dimension: null,
      struct: {
        start_date: null,
        end_date: null,
        url: null,
      },
      grDialogVisible: false,
      settingsDialog: false,
    };
  },
  methods: {
    fetchMetrics() {
      ConversionsRepository.createConversionMetrics(
        this.accountId,
        this.filters,
        this.period,
        this.comparativePeriod,
        this.goals
      )
        .then((data) => {
          this.metrics = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateData(form) {
      this.filters = form.filters;
      this.period = form.period;
      this.comparativePeriod = form.comparativePeriod;
      this.frequency = form.frequency;
      this.goals = form.goals;
      this.fetchMetrics();
    },
    setDefaultPeriod() {
      const today = new Date();
      const default_end_date = new Date(today.setDate(today.getDate() - 3));
      const default_start_date = new Date(today.setDate(today.getDate() - 33));

      return [default_start_date, default_end_date];
    },
    handleTabClick(tab, event) {
      if (tab.loaded) tab.$slots.default[0].child.adjustGrid();
    },
    showDialog(details) {
      this.pageUrl = details;
      this.grDialogVisible = true;
    },
    reloadPage() {
      location.reload();
    },
  },
  computed: {
    startDateAsDateTime() {
      return DateTime.fromJSDate(new Date(this.period[0]));
    },
    endDateAsDateTime() {
      return DateTime.fromJSDate(new Date(this.period[1]));
    },
  },
  beforeMount() {
    this.period = this.setDefaultPeriod();
    this.fetchMetrics();
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}
.metrics {
  .el-col {
    display: flex;
    flex-direction: column;
  }

  .el-card {
    display: flex;
    flex-grow: 1;

    ::v-deep .el-card__body {
      width: 100%;
    }
  }
}
::v-deep .el-tabs__content {
  padding-top: 15px;
}
</style>

<i18n>
{
  "en": {
    "conversion": "Conversion",
    "documentation_desc": "How to analyse your SEO conversions ?",
    "pages": "Pages",
    "keywords": "Keywords",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-conversion/les-donnees-de-conversion-dans-smartkeyword"
  },
  "fr": {
    "conversion": "Conversion",
    "documentation_desc": "Comment analyser ses conversions SEO ?",
    "pages": "Pages",
    "keywords": "Mots clés",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-conversion/les-donnees-de-conversion-dans-smartkeyword"
  },
  "de": {
    "conversion": "Wandlung",
    "documentation_desc": "Kommentaranalysator ses Conversions SEO ?",
    "pages": "Seiten",
    "keywords": "Schlüsselwörter",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-conversion/les-donnees-de-conversion-dans-smartkeyword"
  }
}
</i18n>
