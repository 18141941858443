<template>
  <div>
    <app-introduction
      :title="$t('domainSearch')"
      :documentations="[
        { link: $t('documentationLink'), description: $t('documentationDesc') },
      ]"
      video-link="https://www.youtube.com/watch?v=9870tHLHKJU&list=PLPfqPP7ygILNa0-P7-2C_Shkey_6JE1t1&index=8"
      :user="currentUser"
    >
    </app-introduction>
    <div class="container">
      <el-card class="form-container">
        <search-form
          :countries="countries"
          :default-iso-country-code="study.isoCountryCode"
          @submit="(newRequest) => (request = newRequest)"
        ></search-form>
      </el-card>
      <div v-if="request">
        <div
          class="metrics data-div"
          v-loading="isLoadingMetrics"
          v-if="dataMetrics"
        >
          <metrics
            :metrics="firstUrlMetrics.metrics"
            :classification="firstUrlMetrics.classification"
            :mainSeries="mainSeries"
            :url="request.url"
            :key="allFetchOver"
          >
          </metrics>
        </div>
        <div class="grids data-div">
          <el-card>
            <grids
              :is-admin="currentUser.is_admin"
              :data-top-backlinks="dataTopBacklinks"
              :data-top-pages="dataTopPages"
              :data-top-keywords="dataTopKeywords"
              :data-by-country="dataByCountry"
              :request="request"
              :metrics="firstUrlMetrics.metrics"
              :is-loading-metrics="isLoadingMetrics"
              :is-loading-backlinks="isLoadingBacklinks"
              :is-loading-pages="isLoadingPages"
              :is-loading-keywords="isLoadingKeywords"
              :is-loading-by-country="isLoadingByCountry"
              :studyId="studyId"
              v-on:showWarning="showWarning($event)"
            >
            </grids>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import AppIntroduction from "@/components/AppIntroduction";
import Metrics from "@/pages/DomainSearch/Metrics";
import OpportunitySeaGrids from "@/pages/DomainSearch/OpportunityAndSea";
import Grids from "@/pages/DomainSearch/Grids";
import { availableSearchEnginesMixin } from "@/mixins/availableSearchEnginesMixin";

import SearchForm from "./SearchForm";

export default {
  props: {
    currentUser: Object,
    studyId: Number,
  },
  components: {
    AppIntroduction,
    SearchForm,
    Metrics,
    Grids,
    OpportunitySeaGrids,
  },
  data() {
    return {
      request: null,
      dataMetrics: null,
      dataTopBacklinks: null,
      dataTopPages: null,
      dataTopKeywords: null,
      dataByCountry: null,
      isLoadingMetrics: false,
      isLoadingBacklinks: false,
      isLoadingPages: false,
      isLoadingKeywords: false,
      isLoadingByCountry: false,
      data: null,
      orgTrafficList: [],
      adTrafficList: [],
      orgKeywordsList: [],
      orgKeywordsBucketsList: [],
      adKeywordsList: [],
      adCostList: [],
      mainSeries: {},
      allFetchOver: false,
      domain: null,
      printWarning: false,
    };
  },
  mixins: [availableSearchEnginesMixin],
  computed: {
    firstUrlMetrics() {
      if (this.dataMetrics && this.dataMetrics.data) {
        return this.dataMetrics.data[0];
      } else {
        return { metrics: {}, classification: {} };
      }
    },
    ...mapState(["study", "studyLinks"]),
  },
  methods: {
    fetchData() {
      let requestMetrics = {};
      requestMetrics.urls = [this.request.url];
      requestMetrics.isoCountryCode = this.request.isoCountryCode;
      this.isLoadingMetrics = true;
      this.allFetchOver = false;
      this.$api
        .post(`/netlinking/urls/metrics`, { ...requestMetrics })
        .then((responseMetrics) => {
          this.dataMetrics = responseMetrics.data;
          this.isLoadingMetrics = false;

          this.fetchPagesData();
          this.fetchBacklinksData();
          this.fetchKeywordsData();
          this.fetchByCountryData();
          this.callAPI();
        })
        .catch((error) => {
          this.isLoadingMetrics = false;
          this.dataMetrics = {};
          this.emptyAllData();

          console.log(error);
        });
    },
    fetchBacklinksData() {
      let attributes = {};
      attributes.urls = [this.request.url];
      attributes.scope = "ONE_PER_DOMAIN";
      attributes.limit = 50;

      let requestBacklinksData = {};
      requestBacklinksData.attributes = attributes;
      requestBacklinksData.type = "URL_BACKLINK_REQUEST";

      let requestBacklinks = {};
      requestBacklinks.data = requestBacklinksData;

      this.isLoadingBacklinks = true;
      this.$api
        .post(
          `/netlinking/urls/backlinks`,
          { ...requestBacklinks },
          { headers: { "Content-Type": "application/vnd.api+json" } }
        )
        .then((response) => {
          this.dataTopBacklinks = response.data;
          this.isLoadingBacklinks = false;
        })
        .catch((error) => {
          this.isLoadingBacklinks = false;
          this.dataTopBacklinks = {};
          console.error(error);
        });
    },
    fetchPagesData() {
      const requestPages = {
        url: {
          url: this.request.url.url,
          type:
            this.request.url.type === "URL" ? "DOMAIN" : this.request.url.type,
        },
        isoCountryCode: this.request.isoCountryCode,
      };
      this.isLoadingPages = true;
      this.$api
        .post(`/netlinking/urls/netlinking-top-pages`, requestPages)
        .then((response) => {
          this.dataTopPages = response.data.data;
          this.isLoadingPages = false;
        })
        .catch((error) => {
          this.isLoadingPages = false;
          this.dataTopPages = [];
          console.log(error);
        });
    },
    fetchKeywordsData() {
      const keywordsRequest = {
        url: {
          url: this.request.url.url,
          type: this.request.url.type,
        },
        isoCountryCode: this.request.isoCountryCode,
      };
      this.isLoadingKeywords = true;
      this.$api
        .post(`/netlinking/keywords`, keywordsRequest)
        .then((response) => {
          this.dataTopKeywords = response.data.data;
          this.isLoadingKeywords = false;
        })
        .catch((error) => {
          this.isLoadingKeywords = false;
          this.dataTopKeywords = [];
          console.log(error);
        });
    },
    fetchByCountryData() {
      const byCountryRequest = {
        url: {
          url: this.request.url.url,
          type: this.request.url.type,
        },
      };
      this.isLoadingByCountry = true;
      this.$api
        .post(`/netlinking/best-countries`, byCountryRequest)
        .then((response) => {
          this.dataByCountry = response.data.data;
          this.isLoadingByCountry = false;
        })
        .catch((error) => {
          this.isLoadingByCountry = false;
          this.dataByCountry = [];
          console.log(error);
        });
    },
    callAPI() {
      const request = {
        rangeDate: {},
        frequency: "MONTHLY",
      };
      request.url = {
        url: new URL(this.request.url.url).hostname,
        type: "DOMAIN",
      };
      const today = new Date();
      request.rangeDate.end =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 < 10 ? "0" : "") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() < 10 ? "0" : "") +
        today.getDate();
      const twoYearsAgo = today;
      twoYearsAgo.setFullYear(today.getFullYear() - 2);
      request.rangeDate.start =
        twoYearsAgo.getFullYear() +
        "-" +
        (twoYearsAgo.getMonth() + 1 < 10 ? "0" : "") +
        (twoYearsAgo.getMonth() + 1) +
        "-" +
        (twoYearsAgo.getDate() < 10 ? "0" : "") +
        twoYearsAgo.getDate();
      request.isoCountryCode = this.request.isoCountryCode;
      this.$api
        .post("/netlinking/website-statistics/history", request)
        .then((response) => {
          this.data = response.data.data;
          this.fetchMetrics();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.data = { history: {} };
            this.fetchMetrics();
          } else {
            console.log(error);
          }
          this.isLoading = false;
        });
    },
    fetchMetrics() {
      this.orgTrafficList = [];
      this.orgKeywordsList = [];
      this.orgKeywordsBucketsList = [];
      this.adCostList = [];
      this.adKeywordsList = [];
      this.adTrafficList = [];
      this.data = this.data.history;
      for (const elt in this.data) {
        this.orgTrafficList.push({
          date: elt,
          value: this.data[elt]["organicTraffic"],
        });
        this.adTrafficList.push({
          date: elt,
          value: this.data[elt]["adwordsTraffic"],
        });
        this.orgKeywordsList.push({
          date: elt,
          total: this.data[elt]["organicKeywords"],
          buckets: this.data[elt]["organicKeywordsBuckets"],
        });
        this.adKeywordsList.push({
          date: elt,
          value: this.data[elt]["adwordsKeywords"],
        });
        this.adCostList.push({
          date: elt,
          value: this.data[elt]["adwordsCost"],
        });
      }
      this.mainSeries.traffic = this.orgTrafficList.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      this.mainSeries.keywords = this.orgKeywordsList.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      this.allFetchOver = true;
    },
    emptyAllData() {
      this.dataMetrics = null;
      this.dataTopBacklinks = null;
      this.dataTopPages = null;
      this.dataTopKeywords = null;
      this.dataByCountry = null;
    },
  },
  watch: {
    request(newRequest) {
      if (isTypeOptionSupported(this.studyLinks, newRequest)) {
        this.fetchData();
      }
    },
  },
};
const isTypeOptionSupported = (studyLinks, request) => {
  return studyLinks.netlinking.competitors.form
    .find((field) => (field.name = "url"))
    .form.find((field) => field.name === "type")
    .options.includes(request.url.type);
};
</script>
<style lang="scss" scoped>
.container {
  .form-container {
    margin-bottom: 2rem;

    .form {
      display: flex;
      justify-content: center;

      .select-type {
        margin-right: 2rem;
      }
    }
  }

  .data-div {
    margin-bottom: 2rem;
  }
}
</style>
<i18n>
{
  "en": {
    "domainSearch": "Domain search",
    "documentationDesc": "How to check if a referring domain is relevant for your SEO strategy ?",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/actions-recherche-de-domaine/la-recherche-de-domaine-referent-dans-smartkeywork"
  },
  "fr": {
    "domainSearch": "Recherche de domaine",
    "documentationDesc": "Comment vérifier qu'un domaine référent est pertinent pour votre stratégie de référencement naturel ?",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/actions-recherche-de-domaine/la-recherche-de-domaine-referent-dans-smartkeywork"
  },
  "de": {
    "domainSearch": "Domänensuche",
    "documentationDesc": "Wie können Sie überprüfen, ob eine verweisende Domain für Ihre natürliche Referenzierungsstrategie relevant ist?",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/actions-recherche-de-domaine/la-recherche-de-domaine-referent-dans-smartkeywork"
  }
}
</i18n>
