import { mapState } from "vuex";

import { DateTime } from "luxon";
export const metaContentsMixin = {
  data() {
    return {
      metaContents: null,
      contentMedian: null,
      urlContentLength: null,
    };
  },
  methods: {
    async fetchPositionAndPagesUrlsAsync(request, callback) {
      try {
        this.isLoading = true;
        let historyResponse = await this.$api.post("/serps/history", request);
        const size = Object.keys(historyResponse.data.data.history).length;
        if (size > 0) {
          let serpsResult = Object.entries(
            historyResponse.data.data.history
          ).sort();
          let serpResult = serpsResult[size - 1][1];
          let topFirstUrls = this.tenFirstUrlsOfSerpForMetaContent(serpResult);
          let metaContentsResponse = await this.$api.post(
            "/pages/urls",
            {
              urls: topFirstUrls
                .map((element) => element.url)
                .filter((element) => element != null),
            },
            { headers: { "Accept-Language": this.$i18n.locale } }
          );
          this.urlContentLength = metaContentsResponse.data.data.find(
            (element) => element.url == this.url
          );
          metaContentsResponse.data.data.forEach((element) => {
            topFirstUrls.forEach((urlData, index) => {
              if (urlData.url == element.url) {
                topFirstUrls[index].contentLength = !!element.contentLength
                  ? element.contentLength
                  : 0;
                topFirstUrls[index].h1s = element.h1s;
                topFirstUrls[index].titles = element.titles;
                topFirstUrls[index].metaDescriptions = element.metaDescriptions;
                topFirstUrls[index].lastCrawlDate = element.lastCrawlDate;
              }
            });
          });
          this.metaContents = topFirstUrls;
          this.contentMedian = Math.round(this.median());
          if (callback) callback(metaContentsResponse);
        } else {
          this.urlContentLength = null;
          this.metaContents = null;
          this.contentMedian = null;
        }
        this.isLoading = false;
        return {
          contentMedian: this.contentMedian,
          metaContents: this.metaContents,
          crawlDate: this.lastMonday,
          urlContentLength: this.urlContentLength,
        };
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    tenFirstUrlsOfSerpForMetaContent(serpResult) {
      if (serpResult && serpResult.left.length > 0) {
        return serpResult.left
          .filter((website) => {
            return website.position <= 10;
          })
          .map((website) => {
            return { url: website.url, type: "DOMAIN" };
          });
      }
    },
    median() {
      let top10PositionFiltered = this.metaContents.filter((a) => {
        if (
          a.hasOwnProperty("contentLength") &&
          a.contentLength !== null &&
          a.contentLength !== undefined
        )
          return a;
      });

      top10PositionFiltered.sort((a, b) => {
        return a.contentLength - b.contentLength;
      });

      let minNumberOfWords = 0;
      if (top10PositionFiltered.length > 0) {
        minNumberOfWords = top10PositionFiltered[0].contentLength;
      }
      let median = 0;
      const size = top10PositionFiltered.length;
      if (size > 0) {
        if (size % 2 == 0) {
          median = Math.floor(
            (top10PositionFiltered[Math.floor((size - 1) / 2)].contentLength +
              top10PositionFiltered[size / 2].contentLength) /
              2
          );
        } else {
          median = top10PositionFiltered[Math.floor(size / 2)].contentLength;
        }
      }
      return median;
    },
  },
  computed: {
    ...mapState(["study"]),

    metaContentsData: {
      get() {
        return {
          contentMedian: this.contentMedian,
          metaContents: this.metaContents,
          crawlDate: this.lastMonday,
          urlContentLength: this.urlContentLength,
        };
      },
      set(newValue) {
        return newValue;
      },
    },
    lastMonday() {
      const monday = DateTime.utc().startOf("week");
      return monday.toString().split("T")[0];
    },
    defaultSearchEngine() {
      return this.study.searchEngines.find(
        (searchEngine) => searchEngine.id == this.study.defaultSearchEngineId
      );
    },
  },
};
