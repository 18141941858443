<template>
  <div v-loading="isLoading">
    <div class="chart-title">
      <span class="chart-name">{{ $t("position_repartition") }}</span>
    </div>
    <el-card
      shadow="never"
      class="kpi"
      v-if="!isNaN(lastDatePercentTop3) && !isNaN(firstDatePercentTop3)"
    >
      Top 3 :
      <span class="focus">{{ lastDatePercentTop3 }}% </span>
      <span
        v-if="lastDatePercentTop3 > firstDatePercentTop3"
        class="positiv"
        >(+{{ lastDatePercentTop3 - firstDatePercentTop3 }}%)</span
      >
      <span
        v-if="lastDatePercentTop3 < firstDatePercentTop3"
        class="negativ"
        >({{ lastDatePercentTop3 - firstDatePercentTop3 }}%)</span
      >
      <span v-if="lastDatePercentTop3 == firstDatePercentTop3">--</span>
    </el-card>
    <el-card
      shadow="never"
      class="kpi"
      v-if="!isNaN(lastDatePercentTop10) && !isNaN(firstDatePercentTop10)"
    >
      Top 10 :
      <span class="focus">{{ lastDatePercentTop10 }}%</span>
      <span
        v-if="lastDatePercentTop10 > firstDatePercentTop10"
        class="positiv"
        >(+{{ lastDatePercentTop10 - firstDatePercentTop10 }}%)</span
      >
      <span
        v-if="lastDatePercentTop10 < firstDatePercentTop10"
        class="negativ"
        >({{ lastDatePercentTop10 - firstDatePercentTop10 }}%)</span
      >
      <span v-if="lastDatePercentTop10 == firstDatePercentTop10">--</span>
    </el-card>
    <el-card v-else> Top 10 : -- </el-card>
    <el-card
      shadow="never"
      class="kpi"
      v-if="!isNaN(lastDatePercentTop30) && !isNaN(firstDatePercentTop30)"
    >
      Top 30 :
      <span class="focus">{{ lastDatePercentTop30 }}%</span>
      <span
        v-if="lastDatePercentTop30 > firstDatePercentTop30"
        class="positiv"
        >(+{{ lastDatePercentTop30 - firstDatePercentTop30 }}%)</span
      >
      <span
        v-if="lastDatePercentTop30 < firstDatePercentTop30"
        class="negativ"
        >({{ lastDatePercentTop30 - firstDatePercentTop30 }}%)</span
      >
      <span v-if="lastDatePercentTop30 == firstDatePercentTop30">--</span>
    </el-card>
    <el-card
      shadow="never"
      class="kpi no-print"
      v-if="!isNaN(lastDatePercentTop100) && !isNaN(firstDatePercentTop100)"
    >
      Top 100 :
      <span class="focus">{{ lastDatePercentTop100 }}%</span>
      <span
        v-if="lastDatePercentTop100 > firstDatePercentTop100"
        class="positiv"
        >(+{{ lastDatePercentTop100 - firstDatePercentTop100 }}%)</span
      >
      <span
        v-if="lastDatePercentTop100 < firstDatePercentTop100"
        class="negativ"
        >({{ lastDatePercentTop100 - firstDatePercentTop100 }}%)</span
      >
      <span v-if="lastDatePercentTop100 == firstDatePercentTop100">--</span>
    </el-card>
    <highcharts
      v-if="isMounted"
      ref="highchart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import stockInit from "highcharts/modules/stock";

stockInit(Highcharts);

export default {
  props: {
    data: Array,
    isLoading: Boolean,
    globalNotes: Array,
    studyNotes: Array,
    request: Object
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      isMounted: false,
      chartOptions: {
        chart: {
          type: "area",
          height: 300,
          zoomType: "x",
          ignoreHiddenSeries: false,
        },
        title: {
          text: "",
        },
        xAxis: [
          {
            type: "datetime",
          },
          {
            labels: {
              rotation: -45,
            },
            linkedTo: 0,
            opposite: true,
            type: "datetime",
          },
        ],
        yAxis: {
          min: 0,
          title: {
            text: this.$i18n.t("number_of_keywords"),
          },
          endOnTick: false,
          reversedStacks: false,
        },
        tooltip: {
          useHTML: true,
          headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
          pointFormatter: function () {
            return (
              "<tr><td>" + this.series.name +
              " : <b>" +
              Math.round(
                (this.y /
                  this.series.xAxis.userOptions.nbKeywords.get(this.x)) *
                  100
              ) +
              "%</b> (" +
              this.y +
              " sur " +
              this.series.xAxis.userOptions.nbKeywords.get(this.x) +
              ")</td></tr>"
            );
          },
          footerFormat: '</table>',
          shared: true,
          xDateFormat: "%Y-%m-%d",
        },
        marker: {
          symbol: 'triangle'
        },
        plotOptions: {
          area: {
            stacking: "percent",
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
        legend: {
          width: 750
        }
      },
      lastDatePercentTop3: null,
      firstDatePercentTop3: null,
      lastDatePercentTop10: null,
      firstDatePercentTop10: null,
      lastDatePercentTop30: null,
      firstDatePercentTop30: null,
      lastDatePercentTop100: null,
      firstDatePercentTop100: null,
    };
  },
  methods: {
    updateSeries() {
      if (this.data && this.data.length > 0) {
        this.chartOptions.series = [];
        let nbKeywords = 0;
        let top13 = {};
        top13.data = [];
        let top410 = {};
        top410.data = [];
        let top1130 = {};
        top1130.data = [];
        let top31100 = {};
        top31100.data = [];
        let greater100 = {};
        greater100.data = [];
        const nbKeywordsPerDate = [];
        const nbNewKeywordsPerDate = new Map();
        const nbKeywordsMap = new Map();
        let lastNbKeywords = 0;
        this.data.forEach((element) => {
          top13.data.push({ x: element.date.getTime(), y: element.top13 });
          top410.data.push({ x: element.date.getTime(), y: element.top410 });
          top1130.data.push({ x: element.date.getTime(), y: element.top1130 });
          top31100.data.push({
            x: element.date.getTime(),
            y: element.top31100,
          });
          greater100.data.push({
            x: element.date.getTime(),
            y:
              element.nbKeywords -
              element.top31100 -
              element.top1130 -
              element.top410 -
              element.top13,
          });
          if (nbKeywords < element.nbKeywords) nbKeywords = element.nbKeywords;

          nbKeywordsMap.set(element.date.getTime(), element.nbKeywords);
          nbKeywordsPerDate.push(element.nbKeywords);
          if (
            nbKeywordsPerDate.length > 1 &&
            element.nbKeywords - lastNbKeywords !== 0
          ) {
            let diff = element.nbKeywords - lastNbKeywords;
            let prefix = diff > 0 ? "+" : "";
            nbNewKeywordsPerDate.set(
              element.date.getTime(),
              prefix + diff + " " + this.$i18n.t("keywords")
            );
          }
          lastNbKeywords = element.nbKeywords;
        });

        top13.name = this.$i18n.t("ranking13");
        top410.name = this.$i18n.t("ranking410");
        top1130.name = this.$i18n.t("ranking1130");
        top31100.name = this.$i18n.t("ranking31100");
        greater100.name = this.$i18n.t("ranking100");
        greater100.visible = false;
        greater100.className = "no-print";

        this.chartOptions.series.push(top13);
        this.chartOptions.series.push(top410);
        this.chartOptions.series.push(top1130);
        this.chartOptions.series.push(top31100);
        this.chartOptions.series.push(greater100);

        this.lastDatePercentTop3 = Math.round(
          (top13.data[top13.data.length - 1].y /
            nbKeywordsPerDate[top13.data.length - 1]) *
            100
        );
        this.firstDatePercentTop3 = Math.round(
          (top13.data[0].y / nbKeywordsPerDate[0]) * 100
        );

        this.lastDatePercentTop10 = Math.round(
          ((top13.data[top13.data.length - 1].y +
            top410.data[top410.data.length - 1].y) /
            nbKeywordsPerDate[top410.data.length - 1]) *
            100
        );
        this.firstDatePercentTop10 = Math.round(
          ((top13.data[0].y + top410.data[0].y) / nbKeywordsPerDate[0]) * 100
        );

        this.lastDatePercentTop30 = Math.round(
          ((top13.data[top13.data.length - 1].y +
            top410.data[top410.data.length - 1].y +
            top1130.data[top1130.data.length - 1].y) /
            nbKeywordsPerDate[top1130.data.length - 1]) *
            100
        );
        this.firstDatePercentTop30 = Math.round(
          ((top13.data[0].y + top410.data[0].y + top1130.data[0].y) /
            nbKeywordsPerDate[0]) *
            100
        );

        this.lastDatePercentTop100 = Math.round(
          ((top13.data[top13.data.length - 1].y +
            top410.data[top410.data.length - 1].y +
            top1130.data[top1130.data.length - 1].y +
            top31100.data[top31100.data.length - 1].y) /
            nbKeywordsPerDate[top31100.data.length - 1]) *
            100
        );
        this.firstDatePercentTop100 = Math.round(
          ((top13.data[0].y +
            top410.data[0].y +
            top1130.data[0].y +
            top31100.data[0].y) /
            nbKeywordsPerDate[0]) *
            100
        );

        this.chartOptions.xAxis[0].nbKeywords = nbKeywordsMap;
        this.chartOptions.xAxis[1].tickPositions = Array.from(
          nbNewKeywordsPerDate.keys()
        );
        this.chartOptions.xAxis[1].labels.formatter = function () {
          return nbNewKeywordsPerDate.get(this.value);
        };
        this.getNotes()
      } else {
        this.lastDatePercentTop3 = null;
        this.firstDatePercentTop3 = null;

        this.lastDatePercentTop10 = null;
        this.firstDatePercentTop10 = null;

        this.lastDatePercentTop30 = null;
        this.firstDatePercentTop30 = null;

        this.lastDatePercentTop100 = null;
        this.firstDatePercentTop100 = null;

        this.chartOptions.series = [];
      }
    },
    initNotes(isGlobal) {
      const googleUpdateTrad = this.$i18n.t("googleUpdate")
      const notesTrad = this.$i18n.t("notes")
      const symbol = isGlobal ? `url(/assets/google.png)` : 'triangle'

      const notes = {
        name: isGlobal ? this.$i18n.t("googleUpdate") : this.$i18n.t("notes"),
        data: [],
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: `<table><tr>
            <th colspan="3" style="border-bottom: 1px solid silver">
              <span style="font-size: 14px">{series.name}</span><br>{point.key}
            </th></tr>`,
            pointFormatter: function () {
            let notes = "";
            let globalNotes = "";
            const activeSeriesNames = this.series.chart.series
              .filter(series => series.visible)
              .map(series => series.name);
            const globalNotesHeader = activeSeriesNames.find((name) => name === googleUpdateTrad)
            const notesHeader = activeSeriesNames.find((name) => name === notesTrad)

            if (!this.titles) return "";
            for (let i = 0; i < this.titles.length; i++) {
              let marginBottom = "margin-bottom: 4px; display: inline-block";

              if (i + 1 >= this.titles.length)
                marginBottom = ''
              if (globalNotesHeader && this.isGlobal[i]) {
                globalNotes += `<b style="font-size: 12px; ${marginBottom}">${this.titles[i]}</b> ${this.comments[i]}<br>`;
              } else if (notesHeader && !this.isGlobal[i]) {
                notes += `<b style="font-size: 12px; ${marginBottom}">${this.titles[i]}</b> ${this.comments[i]}<br>`;
              }
            }
            if (globalNotes && notes) {
              // matter of order to not have note type twice, one in header the other in the body
              if (this.series.name === globalNotesHeader)
                return `<tr><td>${globalNotes}</td></tr>
              <tr style="height: 4px" />
              <tr style="border-bottom: 1px solid silver; font-size: 14px">
                <th>${notesHeader}</th>
              </tr>
              <tr><td>${notes}</td></tr>`;
              else
                return `<tr><td>${notes}</td></tr><tr style="height: 4px" />
              <tr style="border-bottom: 1px solid silver; font-size: 14px">
                <th>${globalNotesHeader}</th>
              </tr>
              <tr><td>${globalNotes}</td></tr>`;
            }
            return `<tr><td>${globalNotes + notes}</td></tr>`
          },
          footerFormat: "</table>"
        },
        type: 'scatter',
        marker: {
          enabled: true,
          symbol: symbol,
          height: 18,
          width: 18,
          fillColor: '#FF7BF9'
        }
      };
      return notes;
    },
    buildNotes() {
      const globalNotes = this.initNotes(true)
      const studyNotes = this.initNotes(false)
      const rangeDate = {
          start: new Date(this.request.rangeDate.start),
          end: new Date(this.request.rangeDate.end),
      }

      this.studyNotes.forEach((noteData) => {
        const time = new Date(noteData.date).getTime()
        const note = studyNotes.data.find((note) => note.date === noteData.date)

        if (time < rangeDate.start || time > rangeDate.end)
          return
        if (note) {
          // put every note with same date in the same note
          note.titles.push(noteData.title)
          note.comments.push(noteData.comment ? noteData.comment : "")
          note.isGlobal.push(false)
        } else {
          studyNotes.data.push({
            x: time, y: 0, allowPointSelect: true, shared: false,
            titles: [noteData.title], comments: [noteData.comment ? noteData.comment : ""],
            date: noteData.date, isGlobal: [false]
          })
        }
      })

      this.globalNotes.forEach((noteData) => {
        const time = new Date(noteData.date).getTime()
        const note = globalNotes.data.find((note) => note.date === noteData.date)
        const existInStudyNote = studyNotes.data.find((note) => note.date === noteData.date)

        if (time < rangeDate.start || time > rangeDate.end)
          return
        if (existInStudyNote) {
          // put every note with same date in the same note
          existInStudyNote.titles.push(noteData.title)
          existInStudyNote.comments.push(noteData.comment ? noteData.comment : "")
          existInStudyNote.isGlobal.push(true)
        }
        if (note) {
          // put every note with same date in the same note
          note.titles.push(noteData.title)
          note.comments.push(noteData.comment ? noteData.comment : "")
          note.isGlobal.push(true)
        } else {
          globalNotes.data.push({
            x: time, y: 0, allowPointSelect: true, shared: false,
            titles: [noteData.title], comments: [noteData.comment ? noteData.comment : ""],
            date: noteData.date, isGlobal: [true]
          })
        }
      })
      return {
        studyNotes: studyNotes, 
        globalNotes: globalNotes,
      }
    },
    getNotes() {
      const {studyNotes, globalNotes} = this.buildNotes()

      this.chartOptions.series.push(globalNotes)
      this.chartOptions.series.push(studyNotes)
    },
  },
  mounted() {
    this.updateSeries();
    this.isMounted = true;
  },
  watch: {
    data() {
      this.updateSeries();
    },
    globalNotes() {
      this.updateSeries();
    },
    studyNotes() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi {
  display: inline-block;
  font-size: 1rem;
  margin-right: 1rem;
}

.focus {
  font-style: bold;
  font-weight: 500;
}

.positiv {
  color: green;
}

.negativ {
  color: red;
}

.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;

  .chart-name {
    font-size: 1.5rem;
  }
}

@media print {
  ::v-deep .no-print {
    display: none !important;
  }
}
</style>

<i18n>
{
  "en": {
    "keywords": "keywords",
    "number_of_keywords": "% keywords",
    "position_repartition": "Ranking distribution",
    "ranking13": "1-3 ranking",
    "ranking410": "4-10 ranking",
    "ranking1130": "11-30 ranking",
    "ranking31100": "31-100 ranking",
    "ranking100": "Ranking > 100",
    "notes": "Notes",
    "googleUpdate": "Google Update"
  },
  "fr": {
    "keywords": "mots clés",
    "number_of_keywords": "% mots clés",
    "position_repartition": "Répartition des positions",
    "ranking13": "Positions 1-3",
    "ranking410": "Positions 4-10",
    "ranking1130": "Positions 11-30",
    "ranking31100": "Positions 31-100",
    "ranking100": "Positions > 100",
    "notes": "Notes",
    "googleUpdate": "Mise à jour Google"
  },
  "de": {
    "keywords": "Schlüsselwörter",
    "number_of_keywords": "% Schlüsselwörter",
    "position_repartition": "Aufschlüsselung der Positionen",
    "ranking13": "Plätze 1-3",
    "ranking410": "Plätze 4-10",
    "ranking1130": "Plätze 11-30",
    "ranking31100": "Plätze 31-100",
    "ranking100": "Positionen > 100",
    "notes": "Notiz",
    "googleUpdate": "Google-Update"
  }
}
</i18n>
