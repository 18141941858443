<template>
  <div>
    <div class="metrics">
      <el-row
        type="flex"
        :gutter="20"
      >
        <el-col :span="12">
          <el-card>
            <div class="kpi">
              <div class="text">{{ $t("companies") }}</div>
              <div class="value">{{ companiesCount }}</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <div class="kpi">
              <div class="text">{{ $t("users") }}</div>
              <div class="value">{{ usersCount }}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="companies">
      <companies
        :company-group-id="companyGroupId"
        :company="company"
        :companySetting="companySetting"
        @refresh="fetchMetrics"
      ></companies>
    </div>
    <div class="managers">
      <managers
        :company-group-id="companyGroupId"
        @refresh="fetchMetrics"
      ></managers>
    </div>
    <div
      class="invoices"
      v-if="invoices.length > 0"
    >
      <invoices :invoices="invoices"></invoices>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompanyGroupsRepository = RepositoryFactory.get("companyGroups");
import Companies from "@/pages/CompanyGroup/Companies";
import Managers from "@/pages/CompanyGroup/Managers";
import Invoices from "@/components/billing/Invoices";

export default {
  props: {
    companyGroupId: Number,
    company: Object,
    companySetting: Object,
  },
  components: {
    Companies,
    Managers,
    Invoices,
  },
  data() {
    return {
      companiesCount: null,
      usersCount: null,
      invoices: [],
    };
  },
  methods: {
    fetchMetrics() {
      CompanyGroupsRepository.getCompanyGroupMetrics(this.companyGroupId)
        .then((data) => {
          this.companiesCount = data.companies_count;
          this.usersCount = data.users_count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchInvoices() {
      CompanyGroupsRepository.getCompanyGroupInvoices(this.companyGroupId)
        .then((data) => {
          this.invoices = data.invoices;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    this.fetchMetrics();
    this.fetchInvoices();
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}
.metrics {
  .el-col {
    display: flex;
    flex-direction: column;
  }

  .el-card {
    display: flex;
    flex-grow: 1;

    ::v-deep .el-card__body {
      width: 100%;
    }

    .kpi {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;

      .text {
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }
      .value {
        margin-bottom: 0.5rem;
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }
}
.text-right {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "companies": "Companies",
    "users": "Users",
    "connections_per_week": "Connections / week"
  },
  "fr": {
    "companies": "Sociétés",
    "users": "Utilisateurs",
    "connections_per_week": "Connections / semaine"
  },
  "de": {
    "companies": "Firmen",
    "users": "Benutzer",
    "connections_per_week": "Verbindungen / Woche"
  }
}
</i18n>
