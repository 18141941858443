<template>
  <el-progress
    :text-inside="true"
    :stroke-width="15"
    :percentage="params.value"
    :color="customColor"
  >
  </el-progress>
</template>

<script>
export default {
  methods: {
    customColor(percentage) {
      if (percentage < 30) {
        return "#ff5c5c";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-progress {
  line-height: inherit;
}
</style>
