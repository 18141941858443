<template>
  <div>
    <h1 class="title">{{ $t("admin_tools") }}</h1>

    <el-card>
      <div
        v-if="!showDeduplication && !showClassification"
        class="keyword-search"
      >
        <h2>{{ $t("keyword_search") }}</h2>
        <el-form
          :model="keywordsSearchForm"
          label-width="120px"
        >
          <el-form-item :label="$t('keyword_volume')">
            <el-input
              type="textarea"
              :autosize="{ maxRows: 20, minRows: 4 }"
              :placeholder="$t('insert_keywords')"
              v-model="keywordsSearchForm.keywords"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              plain
              type="primary"
              @click="parseKeywords('deduplication')"
              >{{ $t("deduplication") }}</el-button
            >
            <el-button
              plain
              type="primary"
              @click="parseKeywords('classification')"
              >{{ $t("classification") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="showDeduplication">
        <keyword-search
          :body="body"
          @cancel-search="cancelGrids($event)"
        >
        </keyword-search>
      </div>
      <div v-else-if="showClassification">
        <keyword-classification
          :body="class_body"
          @cancel-search="cancelGrids($event)"
        >
        </keyword-classification>
      </div>
    </el-card>

    <el-card>
      <div class="page-analysis">
        <h2>{{ $t("page_analysis") }}</h2>
        <el-form
          :model="pageAnalysisForm"
          label-width="120px"
        >
          <el-form-item :label="$t('url')">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="$t('insert_urls')"
              v-model="pageAnalysisForm.urls"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :label="$t('study')"
            class="study"
          >
            <el-select
              v-model="pageAnalysisForm.studyId"
              filterable
            >
              <el-option
                v-for="item in studyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              plain
              type="primary"
              :disabled="pageAnalysisForm.studyId === null"
              @click="exportPagesAnalysis"
              >{{ $t("export") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card>
      <div class="netlinking">
        <h2>{{ $t("netlinking") }}</h2>
        <el-form
          :model="netlinkingForm"
          label-width="120px"
        >
          <el-form-item
            :label="$t('study')"
            class="study"
          >
            <el-select
              v-model="netlinkingForm.studyId"
              filterable
            >
              <el-option
                v-for="item in studyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('competitors')">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="$t('insert_competitors')"
              v-model="netlinkingForm.competitors"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('keyword')">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1 }"
              :placeholder="$t('insert_keyword')"
              v-model="netlinkingForm.keyword"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              plain
              type="primary"
              @click="getNetlinkingWebsite"
              >{{ getNetlinkingButtonLabel() }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card>
      <div class="websites-enricher">
        <h2>{{ $t("websites_enricher") }}</h2>
        <el-form
          :model="websitesEnricherForm"
          label-width="120px"
        >
          <el-form-item :label="$t('websites')">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="$t('insert_websites')"
              v-model="websitesEnricherForm.websites"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              plain
              type="primary"
              @click="enrichWebsites"
              >{{ $t("enrich_websites") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card>
      <best-keywords :study-options="studyOptions"></best-keywords>
    </el-card>
  </div>
</template>

<script>
import * as CSV from "csv-string";
import { RepositoryFactory } from "@/services/repositoryFactory";

import BestKeywords from "@/components/BestKeywords";

const AdminToolsRepository = RepositoryFactory.get("adminTools");
const StudiesRepository = RepositoryFactory.get("studies");

export default {
  components: {
    BestKeywords,
  },
  data() {
    return {
      data: null,
      body: {
        keywords: null,
        granularity: 0.95,
      },
      class_body: {
        keywords: null,
        classifier: {
          granularity: 0.95,
          type: "flat",
          calibration: [],
        },
      },
      showDeduplication: false,
      showClassification: false,
      netlinkingForm: {
        studyId: null,
        competitors: "",
        keyword: "",
      },
      pageAnalysisForm: {
        studyId: null,
        urls: "",
      },
      keywordsSearchForm: {
        studyId: null,
        keywords: "",
      },
      websitesEnricherForm: {
        websites: "",
      },
      studyOptions: [],
      queryFilterOptions: [
        { value: "DOMAIN", label: this.$i18n.t("domain") },
        { value: "SUBDOMAIN", label: this.$i18n.t("subdomain") },
        { value: "FOLDER", label: this.$i18n.t("folder") },
        { value: "URL", label: this.$i18n.t("url") },
      ],
    };
  },
  methods: {
    parseKeywords(value) {
      const cleanedList = this.keywordsSearchForm.keywords.replace(/\"/g, "");
      const parsedCsv = CSV.parse(cleanedList);
      const arr = [];
      parsedCsv.map((elt) => {
        const obj = { text: elt[0], volume: 0 };
        if (elt.length > 1) obj.volume = parseInt(elt[1], 10);
        arr.push(obj);
      });

      if (value == "classification") {
        this.class_body.keywords = arr;
        this.showClassification = true;
      } else if (value == "deduplication") {
        this.body.keywords = arr;
        this.showDeduplication = true;
      }
    },
    exportPagesAnalysis() {
      AdminToolsRepository.createPagesAnalysisExport(this.pageAnalysisForm)
        .then((data) => {
          this.$message({
            message: this.$i18n.t("get_page_analysis_success"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    enrichWebsites() {
      if (this.websitesEnricherForm.websites === "") {
        this.$message({
          message: this.$i18n.t("createEnrichedKeywordEmpty"),
          type: "error",
          duration: 6000,
        });
      } else {
        AdminToolsRepository.createEnrichedWebsites(this.websitesEnricherForm)
          .then((data) => {
            this.$message({
              message: this.$i18n.t("create_enriched_keywords_success"),
              type: "success",
              duration: 6000,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getNetlinkingButtonLabel() {
      if (this.netlinkingForm.competitors.length > 0) {
        return this.$i18n.t("get_netlinking_websites");
      } else if (this.netlinkingForm.keyword.length > 0) {
        return this.$i18n.t("get_netlinking_keyword");
      } else {
        return this.$i18n.t("get_netlinking_website");
      }
    },
    getNetlinkingWebsite() {
      AdminToolsRepository.getNetlinkingWebsite(this.netlinkingForm)
        .then((data) => {
          this.$message({
            message: this.$i18n.t("get_netlinking_website_success"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchStudies() {
      StudiesRepository.getStudies()
        .then((data) => {
          this.studyOptions = data.data.map((study) => ({
            value: parseInt(study.id),
            label: study.attributes.name,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelGrids() {
      this.showDeduplication = false;
      this.showClassification = false;
    },
  },
  created() {
    this.fetchStudies();
  },
};
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 2rem;
}

.el-card {
  margin-bottom: 1rem;
}

.keywords-enricher {
  h2 {
    margin-bottom: 1.5rem;
  }

  .el-textarea {
    margin-bottom: 0.5rem;
  }

  .el-select {
    margin-bottom: 0.5rem;
  }
}

.netlinking {
  h2 {
    margin-bottom: 1.5rem;
  }

  .el-select {
    margin-bottom: 0.5rem;
  }
}

.page-analysis {
  h2 {
    margin-bottom: 1.5rem;
  }

  .el-select {
    margin-bottom: 0.5rem;
  }
}

.keyword-search {
  h2 {
    margin-bottom: 1.5rem;
  }

  .el-select {
    margin-bottom: 0.5rem;
  }
}
</style>

<i18n>
{
  "en": {
    "admin_tools": "Admin tools",
    "keyword": "Keyword",
    "page_analysis": "Page Analysis",
    "keywords": "Keywords",
    "competitors": "Competitors",
    "insert_keyword": "Please insert your keyword (must exist in the study)",
    "insert_keywords": "keyword, volume...",
    "insert_competitors": "Please insert your competitors (one per line, format : 'smartkeyword.io' without https://)",
    "insert_websites": "Please insert your websites (one per line, format : 'smartkeyword.io' without https://)",
    "insert_urls": "Please insert your URLs (one per line)",
    "insert_studies": "Please insert the number of the study",
    "country": "Country",
    "enrich_keywords": "Enrich keywords",
    "enrich_websites": "Enrich websites",
    "study": "Study",
    "url": "URL",
    "get_netlinking_website": "Website audit",
    "get_netlinking_websites": "Competitors audit",
    "get_netlinking_keyword": "Keyword audit",
    "netlinking": "Netlinking",
    "domain": "Domain",
    "subdomain": "Subdomain",
    "directory": "Directory",
    "folder": "Folder",
    "websites": "Websites",
    "websites_enricher": "Websites data",
    "createEnrichedKeywordEmpty": "Your domains list is empty, you should set a list of domains",
    "create_enriched_keywords_success": "Your keywords were successfully submitted, you will receive your enriched keyword by email shortly.",
    "get_netlinking_website_success": "Your audit is being generated, you will receive it by email shortly !",
    "get_page_analysis_success": "Your page analysis is being generated, you will receive it by email shortly !",
    "nb_studies": "Study",
    "export": "Export",
    "next": "Next",
    "keyword_search": "Keyword Search",
    "keyword_volume": "Keywords",
    "classification": "Classification",
    "deduplication": "Deduplication"
  },
  "fr": {
    "admin_tools": "Outils admin",
    "keyword": "Mot-clé",
    "page_analysis": "Analyse de pages",
    "keywords": "Mots clés",
    "competitors": "Concurrents",
    "insert_keyword": "Veuillez insérer votre mot-clé (doit exister dans l'étude)",
    "insert_keywords": "mot clé, volume...",
    "insert_competitors": "Veuillez insérer vos concurrents (un par ligne, format : 'smartkeyword.io' sans https://)",
    "insert_websites": "Veuillez insérer vos noms de domaine (un par ligne, format : 'smartkeyword.io' sans https://)",
    "insert_urls": "Veuillez insérer vos liens URLs(un par ligne)",
    "insert_studies": "Veuillez insérer le numéro de l'étude",
    "country": "Pays",
    "enrich_keywords": "Enrichir les mots clés",
    "enrich_websites": "Enrichir les sites",
    "study": "Etude",
    "url": "URL",
    "get_netlinking_website": "Audit du site",
    "get_netlinking_websites": "Audit des concurrents",
    "get_netlinking_keyword": "Audit du mot-clé",
    "netlinking": "Netlinking",
    "domain": "Domaine",
    "subdomain": "Sous-domaine",
    "directory": "Répertoire",
    "folder": "Répertoire",
    "websites": "Sites web",
    "websites_enricher": "Données de site",
    "createEnrichedKeywordEmpty": "Votre liste de noms de domaine est vide, vous devez insérer une liste de noms de domaine",
    "create_enriched_keywords_success": "Vos mots clés ont été soumis ave succès, vous allez recevoir vos mots clés enrichis par mail dans quelques instants.",
    "get_netlinking_website_success": "Votre audit est en cours de génération, vous allez le recevoir par email dans quelques instants !",
    "get_page_analysis_success": "Votre analyse est en cours de génération, vous allez la recevoir par email dans quelques instants !",
    "nb_studies": "Etude",
    "export": "Exporter",
    "next": "Suivant",
    "keyword_search": "Recherche de mots clés",
    "keyword_volume": "Mots clés",
    "classification": "Classification",
    "deduplication": "Deduplication"
  },
  "de": {
    "admin_tools": "Admin-Tools",
    "keyword": "Stichwort",
    "page_analysis": "Seitenanalyse",
    "keywords": "Schlüsselwörter",
    "competitors": "Konkurrenten",
    "insert_keyword": "Bitte geben Sie Ihr Stichwort ein (muss in der Studie vorhanden sein)",
    "insert_keywords": "Stichwort, Lautstärke...",
    "insert_competitors": "Bitte geben Sie Ihre Mitbewerber ein (einer pro Zeile, Format: 'smartkeyword.io' ohne https://)",
    "insert_websites": "Bitte geben Sie Ihre Domainnamen ein (einer pro Zeile, Format: 'smartkeyword.io' ohne https://)",
    "insert_urls": "Bitte geben Sie Ihre URLs ein (eine pro Zeile)",
    "insert_studies": "Bitte Studiennummer eingeben",
    "country": "Zahlt",
    "enrich_keywords": "Keywords anreichern",
    "enrich_websites": "Websites bereichern",
    "study": "Etüde",
    "url": "URL",
    "get_netlinking_website": "Site-Audit",
    "get_netlinking_websites": "Prüfung von Wettbewerbern",
    "get_netlinking_keyword": "Keyword-Audit",
    "netlinking": "Netzverlinkung",
    "domain": "Domain",
    "subdomain": "Subdomäne",
    "directory": "Verzeichnis",
    "folder": "Verzeichnis",
    "websites": "Sites-Web",
    "websites_enricher": "Standortdaten",
    "createEnrichedKeywordEmpty": "Ihre Liste mit Domainnamen ist leer, Sie müssen eine Liste mit Domainnamen einfügen",
    "create_enriched_keywords_success": "Ihre Keywords wurden erfolgreich übermittelt, Sie erhalten Ihre angereicherten Keywords in wenigen Augenblicken per E-Mail.",
    "get_netlinking_website_success": "Ihr Audit wird erstellt, Sie erhalten es in wenigen Augenblicken per E-Mail!",
    "get_page_analysis_success": "Ihre Analyse wird erstellt, Sie erhalten sie in wenigen Augenblicken per E-Mail!",
    "nb_studies": "Etüde",
    "export": "Exporteur",
    "next": "Folgend",
    "keyword_search": "Stichwortforschung",
    "keyword_volume": "Schlüsselwörter",
    "classification": "Einstufung",
    "deduplication": "Deduplizierung"
  }
}
</i18n>
