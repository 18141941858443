export const gridColumnsToAPITranslation = {
  methods: {
    getFilterTypeForApi(type) {
      switch (type) {
        case "equals":
          return "EQUALS";
          break;
        case "notEqual":
          return "NOT_EQUALS";
          break;
        case "greaterThan":
          return "GREATER_THAN";
          break;
        case "greaterThanOrEqual":
          return "GREATER_THAN_OR_EQUAL";
          break;
        case "lessThan":
          return "LESS_THAN";
          break;
        case "lessThanOrEqual":
          return "LESS_THAN_OR_EQUAL";
          break;
        case "inRange":
          return "BETWEEN";
          break;
        case "contains":
          return "CONTAINS";
          break;
        case "notContains":
          return "NOT_CONTAINS";
          break;
        case "startsWith":
          return "STARTS_WITH";
          break;
        case "endsWith":
          return "ENDS_WITH";
          break;
      }
    },
  },
};
