<template>
  <div>
    <div class="presets">
      <div class="label">
        {{ $t("presets") }}
      </div>
      <div>
        <el-radio-group
          v-model="preset"
          plain
          size="mini"
        >
          <el-radio-button :label="null">{{
            $t("high_traffic")
          }}</el-radio-button>
          <el-radio-button label="high_bounce_rate">{{
            $t("high_bounce_rate")
          }}</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="actions">
      <el-button
        plain
        type="primary"
        @click="excelExport"
        >{{ $t("excel_export") }}</el-button
      >
    </div>
    <ag-grid-vue
      v-loading="isLoading"
      style="height: 800px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :context="context"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      rowSelection="multiple"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const TrafficsRepository = RepositoryFactory.get("traffics");
import { AgGridVue } from "ag-grid-vue";
import LinkRenderer from "@/components/gridRenderers/LinkRenderer";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

export default {
  mixins: [agGridMixin],
  props: {
    accountId: Number,
    dimensionsData: Array,
    filters: Array,
    period: Array,
    comparativePeriod: Array,
  },
  components: {
    AgGridVue,
    LinkRenderer,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
      preset: null,
      isLoading: false,
      context: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    loadGrid() {
      this.setColumnDefs();
      this.fetchData();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("landing_page"),
          field: "landing_page",
          minWidth: 300,
          filter: "agTextColumnFilter",
          cellRendererFramework: "LinkRenderer",
        },
        {
          headerName: this.$i18n.t("sessions"),
          field: "main_sessions",
          valueGetter: (params) => roundNumber(params.data["main_sessions"]),
          valueFormatter: numberFormatter,
          aggFunc: "sum",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
      ];

      if (
        this.comparativePeriod === null ||
        this.comparativePeriod.length === 0
      ) {
        this.columnDefs = this.columnDefs.concat([
          {
            headerName: this.$i18n.t("average_time_on_page"),
            field: "main_avg_time_on_page",
            valueGetter: (params) =>
              roundNumber(params.data["main_avg_time_on_page"]),
            valueFormatter: numberFormatter,
            aggFunc: "avg",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
          },
          {
            headerName: this.$i18n.t("bounce_rate"),
            field: "main_bounce_rate",
            valueGetter: (params) =>
              roundNumber(params.data["main_bounce_rate"]),
            valueFormatter: numberFormatter,
            aggFunc: "avg",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
          },
        ]);
      }

      if (this.comparativePeriod !== null && this.comparativePeriod.length) {
        this.columnDefs = this.columnDefs.concat([
          {
            headerName: this.$i18n.t("comparative_sessions"),
            field: "comparative_sessions",
            valueGetter: (params) =>
              roundNumber(params.data["comparative_sessions"]),
            valueFormatter: numberFormatter,
            aggFunc: "sum",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
          },
          {
            headerName: this.$i18n.t("sessions_evolution"),
            field: "sessions_evolution",
            valueGetter: (params) =>
              roundNumber(params.data["sessions_evolution"]),
            valueFormatter: numberFormatter,
            aggFunc: "sum",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
          },
        ]);
      }

      this.columnDefs.push({
        headerName: this.$i18n.t("number_of_keywords"),
        field: "number_keywords",
        filter: "agNumberColumnFilter",
        type: "numericColumn",
        filterParams: {
          defaultOption: "greaterThan",
        },
        suppressSizeToFit: true,
      });
    },
    fetchData() {
      this.isLoading = true;
      TrafficsRepository.createTrafficGrid(
        this.accountId,
        this.filters,
        "LandingPage",
        this.period,
        this.comparativePeriod,
        this.preset
      )
        .then((data) => {
          this.rowData = data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    excelExport() {
      const columnKeys = this.columnApi
        .getAllDisplayedColumnGroups()
        .map((columnGroup) => columnGroup.colId);
      const params = {
        skipHeader: false,
        columnKeys: columnKeys,
        onlySelected: false,
        fileName: "Traffic Pages Export - SmartKeyword",
        sheetName: "Traffic Pages",
      };

      this.gridApi.exportDataAsExcel(params);
    },
    showDialog(value) {
      this.$emit("show-page", value);
    },
  },
  beforeMount() {
    this.loadGrid();
    this.context = {
      showDialog: this.showDialog,
    };
  },
  watch: {
    $props: {
      handler: "loadGrid",
      deep: true,
    },
    preset() {
      this.fetchData();
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let roundNumber = (number) => Math.round(number * 100) / 100;
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.presets {
  text-align: left;

  .label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}
.actions {
  text-align: right;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "presets": "Presets",
    "high_traffic": "Best pages",
    "high_bounce_rate": "High bounce rate",
    "excel_export": "Export Excel",
    "landing_page": "Landing Page",
    "sessions": "Sessions",
    "comparative_sessions": "Comparative sessions",
    "average_time_on_page": "Average time on page (s)",
    "bounce_rate": "Bounce rate (%)",
    "sessions_evolution": "Sessions Evolution",
    "number_of_keywords": "# Keywords"
  },
  "fr": {
    "presets": "Présélections",
    "high_traffic": "Meilleures pages",
    "high_bounce_rate": "Taux de rebond élevé",
    "excel_export": "Export Excel",
    "landing_page": "Page",
    "sessions": "Sessions",
    "comparative_sessions": "Sessions comparatives",
    "average_time_on_page": "Temps moyen passé sur la page (s)",
    "bounce_rate": "Taux de rebond (%)",
    "sessions_evolution": "Evolution Sessions",
    "number_of_keywords": "# Mots clés"
  },
  "de": {
    "presets": "Voreinstellungen",
    "high_traffic": "Top-Seiten",
    "high_bounce_rate": "Hohe Absprungrate",
    "excel_export": "Excel exportieren",
    "landing_page": "Buchseite",
    "sessions": "Sitzungen",
    "comparative_sessions": "Vergleichende Sitzungen",
    "average_time_on_page": "Durchschnittliche Verweildauer auf Seite(n)",
    "bounce_rate": "Rückprallrate (%)",
    "sessions_evolution": "Evolutionssitzungen",
    "number_of_keywords": "# Schlüsselwörter"
  }
}
</i18n>
