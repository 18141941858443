<template>
  <div>
    <el-card
      class="no-border-on-print"
      v-if="studyResponse"
    >
      <div class="flex-container">
        <h2>{{ $t("shareOfVoices") }}</h2>
      </div>
      <el-card>
        <share-of-voices-chart
          :data="shareOfVoicesOfCompetitors"
          :isLoading="isLoadingShareOfVoices"
        ></share-of-voices-chart>
      </el-card>
    </el-card>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
import { DateTime } from "luxon";
import ShareOfVoicesChart from "@/pages/Competitors/Charts/ShareOfVoicesChart";
const CompetitorsRepository = RepositoryFactory.get("competitorsRepository");

export default {
  props: {
    accountId: Number,
  },
  components: {
    ShareOfVoicesChart,
  },
  data() {
    return {
      shareOfVoicesOfCompetitors: null,
      trackedKeywords: null,
      isLoadingShareOfVoices: false,
      domains: null,
      studyResponse: null,
    };
  },
  methods: {
    fetchStudy() {
      this.$api.get(`/studies/${this.accountId}`).then((response) => {
        this.studyResponse = response.data;
        this.getTrackedKeywords();
      });
    },
    getTrackedKeywords() {
      const linkTrackedKeywordSearch =
        this.studyResponse.links.trackedKeywords.list;
      this.$api[linkTrackedKeywordSearch.method.toLowerCase()](
        linkTrackedKeywordSearch.href
      )
        .then((responseTrackedKeywords) => {
          this.trackedKeywords = responseTrackedKeywords.data.data;
          this.getAllCompetitors();
        })
        .catch((error) => console.error(error));
    },
    getAllCompetitors() {
      CompetitorsRepository.getCompetitors(this.studyResponse.data.id, null)
        .then((data) => {
          this.domains = data.data.map((item) => item.attributes.domain);
          this.getShareOfVoices();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getShareOfVoices() {
      this.isLoadingShareOfVoices = true;
      let defaultSearchEngine = this.studyResponse.data.searchEngines.find(
        (searchEngine) => {
          if (
            this.studyResponse.data.defaultSearchEngineId == searchEngine.id
          ) {
            return searchEngine;
          }
        }
      );
      let request = {
        searchEngineParameters: {
          isoCountryCode: defaultSearchEngine.isoCountryCode,
          isoLanguageCode: defaultSearchEngine.isoLanguageCode,
          device: defaultSearchEngine.device,
        },
        date: DateTime.utc().startOf("week").toString().split("T")[0],
        keywords: this.trackedKeywords.map((trackedKeyword) => ({
          text: trackedKeyword.text,
          locationId: trackedKeyword.locationId,
        })),
        domains: this.domains,
      };

      this.$api
        .post("/positions/share-of-voices", request)
        .then((response) => {
          this.shareOfVoicesOfCompetitors = response.data.data;
        })
        .catch((error) => {
          this.shareOfVoicesOfCompetitors = [];
          console.error(error);
        })
        .finally(() => {
          this.isLoadingShareOfVoices = false;
        });
    },
  },
  created() {
    this.fetchStudy();
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}

.metrics {
  margin-top: 1rem;

  .el-col {
    display: flex;
    flex-direction: column;
  }

  .el-card {
    display: flex;
    flex-grow: 1;

    ::v-deep .el-card__body {
      width: 100%;
    }
  }
}

.date-period {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.chart {
  margin-top: 1rem;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.dashboard {
  margin-bottom: 3rem;
}

@media print {
  .no-print {
    display: none !important;
  }
  .date-period {
    margin-left: 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .no-border-on-print {
    border-style: none;
  }
}
</style>
<i18n>
{
  "en": {
    "shareOfVoices": "Share of voices"
  },
  "fr": {
    "shareOfVoices": "Part de voix des concurrents"
  },
  "de": {
    "shareOfVoices": "Share of Voice der Wettbewerber"
  }
}
</i18n>
