<template>
  <div>
    <el-input
      v-model="targetUrl"
      @input="debounceHandle()"
    >
    </el-input>
    <div>
      <span
        class="error"
        v-if="isBadUrl"
      >
        {{ $t("badlyFormattedUrl") }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    value: String,
  },
  data() {
    return {
      prefix: "https://",
      data: null,
      fetchError: false,
      isBadUrl: false,
      isLoading: false,
    };
  },
  computed: {
    targetUrl: {
      get() {
        return this.value;
      },
      set(targetUrl) {
        this.$emit("input", targetUrl);
      },
    },
    ...mapState(["study", "userLocale"]),
  },
  methods: {
    debounceHandle() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.fetchMetrics(true);
      }, 1000); // delay
    },
    fetchMetrics() {
      if (
        this.checkIfInputIsUrl(this.targetUrl) ||
        this.targetUrl.length === 0
      ) {
        this.isBadUrl = false;
        this.$emit("isTargetUrlCorrectlyFormattedOrEmpty", !this.isBadUrl);
      } else {
        this.isBadUrl = true;
        this.$emit("isTargetUrlCorrectlyFormattedOrEmpty", !this.isBadUrl);
      }
    },
    checkIfInputIsUrl(input) {
      var pattern = new RegExp(
        "^(http(s)?:\\/\\/)" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(input);
    },
  },
  mounted() {
    this.fetchMetrics(false);
  },
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function lastMonthDate() {
  const date = new Date();
  return formatDate(new Date(date.setMonth(date.getMonth() - 1)));
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-group__prepend {
  background-color: #3c54a5;
  color: #fff;
}
.error {
  color: red;
}

.source-orange {
  color: rgb(241, 97, 8);
  font-weight: bold;
}
</style>

<i18n>
{
  "en": {
    "apply": "Apply",
    "badlyFormattedUrl": "The url you typed is not in the correct format",
    "errorFetchingMetrics": "We encountered an error while fetching metrics",
    "trustFlow": "Trust Flow",
    "ratioTFCF": "TF/CF",
    "traffic": "Traffic",
    "referringDomains": "Referring domains",
    "domainAuthority": "Domain authority"
  },
  "fr": {
    "apply": "Appliquer",
    "badlyFormattedUrl": "L'url que vous avez rentrée n'est pas au bon format",
    "errorFetchingMetrics": "Nous avons rencontré une erreur lors du chargement des données",
    "trustFlow": "Trust Flow",
    "ratioTFCF": "TF/CF",
    "traffic": "Trafic",
    "referringDomains": "Domaines reférents",
    "domainAuthority": "Domain authority"
  },
  "de": {
    "apply": "Anwenden",
    "badlyFormattedUrl": "Die eingegebene URL hat nicht das richtige Format.",
    "errorFetchingMetrics": "Beim Laden der Daten ist ein Fehler aufgetreten",
    "trustFlow": "Vertrauensfluss",
    "ratioTFCF": "TF/CF",
    "traffic": "Verkehr",
    "referringDomains": "Verweisende Domains",
    "domainAuthority": "Domänenautorität"
  }
}
</i18n>
