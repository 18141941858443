<template>
  <el-card shadow="never">
    <el-row>
      <el-col :span="20">
        <div class="inputs-sections">
          <el-form
            :model="urlInput"
            :inline="true"
            size="mini"
            @submit.native.prevent
          >
            <url-value
              v-model="urlInput.url"
              class="url-form-item"
            ></url-value>
            <url-scope v-model="urlInput.urlType"></url-scope>
            <max-rank
              v-model="urlInput.maxRank"
              class="max-rank-form-item"
            ></max-rank>
          </el-form>
          <el-form
            size="mini"
            @submit.native.prevent
          >
            <filters
              v-model="excludedKeywords"
              class="filters-form-item"
            ></filters>
            <el-form-item :label="$t('country')">
              <select-search-engines
                v-if="defaultCountryIsoCode"
                v-model="isoCountryCode"
                :countries="countries"
              >
              </select-search-engines>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="action">
          <el-button
            type="primary"
            @click="submit"
            >{{ $t("search") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import SelectSearchEngines from "@/components/formItems/SelectSearchEngines";
import UrlValue from "@/pages/KeywordsSearch/Shared/FormItems/UrlValue";
import UrlScope from "@/pages/KeywordsSearch/Shared/FormItems/UrlScope";
import MaxRank from "@/pages/KeywordsSearch/Shared/FormItems/MaxRank";
import Filters from "@/pages/KeywordsSearch/Shared/FormItems/Filters";

export default {
  components: {
    UrlValue,
    UrlScope,
    MaxRank,
    Filters,
    SelectSearchEngines,
  },
  props: {
    defaultCountryIsoCode: String,
    countries: Array,
  },
  data() {
    return {
      urlInput: {
        url: "",
        urlType: "DOMAIN",
        maxRank: 30,
      },
      excludedKeywords: [],
      isoCountryCode: this.defaultCountryIsoCode,
    };
  },
  methods: {
    submit() {
      const request = {
        isoCountryCode: this.isoCountryCode,
        url: this.urlInput,
        excludedKeywords: this.excludedKeywords,
      };
      this.$emit("submit", request);
    },
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}

.el-form--inline .el-form-item {
  margin-right: 20px;

  &.url-form-item {
    ::v-deep .el-form-item__content {
      width: 250px;
    }
  }

  &.max-rank-form-item {
    .el-input-number--mini {
      width: 80px;
    }
  }
}

.action {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "search": "Search",
    "country": "Country"
  },
  "fr": {
    "search": "Rechercher",
    "country": "Pays"
  },
  "de": {
    "search": "Forschen",
    "country": "Zahlt"
  }
}
</i18n>
