export const topMovementsDataMixin = {
  methods: {
    getTopMovementsDataFromResponse(
      response,
      trackedKeywords,
      topMovements,
      topMovements1To3,
      topMovements4To10,
      topMovements11To30
    ) {
      const topMovementsList = {
        topMovements: topMovements,
        topMovements1To3: topMovements1To3,
        topMovements4To10: topMovements4To10,
        topMovements11To30: topMovements11To30,
      };
      const data = response.data.data;
      data.positionsBuckets.map((bucket) => {
        this.updateSecondaryTopMovements(
          topMovementsList,
          bucket,
          trackedKeywords
        );
      });
      this.updatePrimaryTopMovements(data, topMovementsList, trackedKeywords);
      this.updateTopMovementMetrics(data, topMovementsList);
      return topMovementsList;
    },
    updateSecondaryTopMovements(topMovementsList, bucket, trackedKeywords) {
      const topMovementsBucket =
        topMovementsList[`topMovements${bucket.min}To${bucket.max}`];
      for (const topType of ["entries", "exits"]) {
        if (bucket[topType]) {
          const keywordsData = bucket[topType].map((keyword) => {
            return this.getKeywordInfos(trackedKeywords, keyword);
          });
          this.updateTopMovementKeywordsData(
            topMovementsBucket[topType],
            keywordsData
          );
        }
      }
      this.updateTopMovementVariations(topMovementsBucket, bucket);
    },
    getKeywordInfos(trackedKeywords, keyword) {
      const trackedKeyword = trackedKeywords.find(
        (tk) => tk.text === keyword.text
      );
      return {
        keyword: keyword.text,
        volume: parseInt(keyword.volume),
        position: keyword.endPosition ? keyword.endPosition : null,
        pastPosition: keyword.startPosition ? keyword.startPosition : null,
        estimatedTrafficVariation: keyword.estimatedTrafficVariation
          ? parseInt(keyword.estimatedTrafficVariation)
          : 0,
        tags: trackedKeyword.tags,
        id: trackedKeyword.id,
      };
    },
    updateTopMovementKeywordsData(bucket, keywordsData) {
      bucket.count = keywordsData.length;
      bucket.keywordsData = keywordsData;
    },
    updateTopMovementVariations(topMovementsBucket, bucket) {
      topMovementsBucket.totalTrafficVariationEntry =
        bucket.totalTrafficVariationEntry;
      topMovementsBucket.totalTrafficVariationExit =
        bucket.totalTrafficVariationExit;
    },
    updateTopMovementMetrics(data, topMovementsList) {
      if (data.estimatedTrafficVariations.positiveMovements) {
        topMovementsList.topMovements.highest.metrics =
          data.estimatedTrafficVariations.positiveMovements;
      }
      if (data.estimatedTrafficVariations.negativeMovements) {
        topMovementsList.topMovements.lowest.metrics =
          data.estimatedTrafficVariations.negativeMovements;
      }
    },
    updatePrimaryTopMovements(data, topMovementsList, trackedKeywords) {
      for (const topType of ["highest", "lowest"]) {
        if (data.estimatedTrafficVariations[topType]) {
          const keywordsData = data.estimatedTrafficVariations[topType].map(
            (keyword) => {
              return this.getKeywordInfos(trackedKeywords, keyword);
            }
          );
          this.updateTopMovementKeywordsData(
            topMovementsList.topMovements[topType],
            keywordsData
          );
        }
      }
    },
  },
};
