<template>
  <el-card>
    <h2>{{ $t("companies") }}</h2>
    <div class="actions">
      <div class="text-right">
        <el-button
          plain
          type="primary"
          @click="dialogCreateCompanyVisible = true"
          >{{ $t("create_company") }}</el-button
        >
      </div>
      <el-dialog
        :title="$t('create_company')"
        :visible.sync="dialogCreateCompanyVisible"
      >
        <company-form
          @submit="createCompany($event)"
          @close-dialog="dialogCreateCompanyVisible = false"
        ></company-form>
      </el-dialog>
      <el-dialog
        :title="$t('edit_company')"
        :visible.sync="dialogEditCompanyVisible"
      >
        <company-form
          :companyId="editCompanyId"
          @submit="updateCompany($event)"
          @close-dialog="dialogEditCompanyVisible = false"
        ></company-form>
      </el-dialog>
    </div>
    <div class="grid">
      <ag-grid-vue
        :domLayout="'autoHeight'"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :resizable="true"
        :filter="true"
        :localeTextFunc="localeTextFunc"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        @gridReady="onGridReady"
        @firstDataRendered="adjustGrid"
        @rowDataChanged="adjustGrid"
        :context="context"
      >
      </ag-grid-vue>
    </div>
  </el-card>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompanyGroupsRepository = RepositoryFactory.get("companyGroups");
const CompaniesRepository = RepositoryFactory.get("companies");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import CompanyForm from "@/pages/CompanyGroup/Forms/CompanyForm";

const StatusComponent = Vue.extend({
  template: `
		<el-tag
			:type="params.value === 'subscribed' ? 'success' : params.value === 'suspended' ? 'warning' : 'danger'"
			disable-transitions>
			{{ $t(params.value) }}
		</el-tag>
	`,
  data: function () {
    return {
      companyId: null,
    };
  },
  mounted() {
    this.companyId = this.params.data.id;
  },
  methods: {
    invokeEditCompany() {
      this.params.context.companies.editCompany(this.companyId);
    },
    invokeDeleteCompany() {
      this.params.context.companies.deleteCompany(this.companyId);
    },
  },
  i18n: {
    messages: {
      en: {
        subscribed: "Subscribed",
        inactive: "Inactive",
        suspended: "Suspended",
      },
      fr: {
        subscribed: "Souscrit",
        inactive: "Inactif",
        suspended: "Suspendu",
      },
    },
  },
});

const ActionsComponent = Vue.extend({
  template: `
		<div>
			<el-button size="mini" plain icon="fas fa-edit" @click="invokeEditCompany"></el-button>
			<el-popconfirm
				:confirm-button-text="$t('confirm')"
				:cancel-button-text="$t('cancel')"
				icon="el-icon-info"
				icon-color="red"
				:title="$t('confirmDeletion')"
				@onConfirm="confirmDeletion" >
				<el-button slot="reference" size="mini" type="danger" plain icon="fas fa-trash-alt"></el-button>
			</el-popconfirm>			
		</div>
	`,
  data: function () {
    return {
      companyId: null,
    };
  },
  beforeMount() {},
  mounted() {
    this.companyId = this.params.data.id;
  },
  methods: {
    invokeEditCompany() {
      this.params.context.companies.editCompany(this.companyId);
    },
    invokeDeleteCompany() {
      this.params.context.companies.deleteCompany(this.companyId);
    },
    confirmDeletion() {
      this.invokeDeleteCompany();
    },
  },
  i18n: {
    messages: {
      en: {
        confirmDeletion: "Are you sure you want to delete this company ?",
        confirm: "Confirm",
        cancel: "Cancel",
      },
      fr: {
        confirmDeletion: "Êtes-vous sûr de vouloir supprimer cette société ?",
        confirm: "Confirmer",
        cancel: "Annuler",
      },
    },
  },
});

export default {
  mixins: [agGridMixin],
  props: {
    companyGroupId: Number,
    company: Object,
    companySetting: Object,
  },
  components: {
    AgGridVue,
    CompanyForm,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      dialogCreateCompanyVisible: false,
      editCompanyId: null,
      dialogEditCompanyVisible: false,
      dialogDeleteVisible: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.defaultColDef = {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      };
      this.columnDefs = [
        {
          headerName: this.$i18n.t("name"),
          field: "name",
          minWidth: 200,
          filter: "agTextColumnFilter",
          cellRenderer: this.linkRenderer,
        },
        {
          headerName: this.$i18n.t("studies"),
          field: "studiesCount",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("users"),
          field: "usersCount",
          valueFormatter: numberFormatter,
          aggFunc: "sum",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_users"),
          field: "usersAllowedCount",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_keywords"),
          field: "keywordsLimit",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_competitors"),
          field: "competitorsLimit",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("status"),
          field: "status",
          minWidth: 120,
          cellRendererFramework: StatusComponent,
          cellClass: "text-center",
        },
        {
          headerName: this.$i18n.t("actions"),
          minWidth: 130,
          maxWidth: 130,
          cellRendererFramework: ActionsComponent,
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ];
    },
    fetchData() {
      CompanyGroupsRepository.getCompanyGroupCompanies(this.companyGroupId)
        .then((data) => {
          this.rowData = data.data.map((company) => {
            const companySettingId = company.relationships.companySetting.data
              ? company.relationships.companySetting.data.id
              : null;
            let result = { id: company.id, ...company.attributes };
            if (companySettingId) {
              const companySettingAttributes = data.included.find(
                (companySetting) => companySetting.id === companySettingId
              ).attributes;
              result = { ...result, ...companySettingAttributes };
            }
            return result;
          });
          if (this.company != null && this.companySetting != null) {
            let company = {
              id: this.company.id,
              name: this.company.name,
              status: this.company.status,
              usersAllowedCount: this.company.users_allowed_count,
              companyGroupId: this.company.companyGroupId,
              sellsyThirdId: this.company.sellsy_third_id,
              usersCount: this.companySetting.data.attributes.usersCount,
              studiesCount: this.companySetting.data.attributes.studiesCount,
            };

            const companySettingAttributes = this.companySetting.included.find(
              (companySetting) =>
                companySetting.id ===
                this.companySetting.data.relationships.companySetting.data.id
            ).attributes;
            const userCompany = { ...company, ...companySettingAttributes };
            const index = this.rowData.findIndex(
              (object) => object.id == userCompany.id
            );
            if (index === -1) {
              this.rowData.push(userCompany);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createCompany(form) {
      CompanyGroupsRepository.createCompanyGroupCompany(
        this.companyGroupId,
        form
      )
        .then((response) => {
          this.$message.success(this.$i18n.t("create_company_success"));
          this.fetchData();
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
            type: "error",
          });
        });
    },
    editCompany(companyId) {
      this.editCompanyId = parseInt(companyId);
      this.dialogEditCompanyVisible = true;
    },
    updateCompany(form) {
      CompaniesRepository.updateCompany(this.editCompanyId, form)
        .then((response) => {
          this.$message.success(this.$i18n.t("edit_company_success"));
          this.fetchData();
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
            type: "error",
          });
        });
    },
    deleteCompany(companyId) {
      CompaniesRepository.deleteCompany(companyId)
        .then((response) => {
          this.$message.success(this.$i18n.t("delete_company_success"));
          this.rowData = this.rowData.filter((row) => row.id !== companyId);
          setTimeout(() => {
            this.$emit("refresh");
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="/c/${params.data.id}">${params.value}</a>`;
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.fetchData();
    this.context = { companies: this };
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.actions {
  margin-bottom: 1rem;
}

.grid {
  .text-center {
    text-align: center;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "companies": "Companies",
    "name": "Name",
    "studies": "Studies",
    "users": "Users",
    "max_users": "Max users",
    "max_keywords": "Max keywords",
    "max_competitors": "Max competitors",
    "status": "Status",
    "actions": "Actions",
    "create_company": "Create company",
    "create_company_success": "Company was successfully created.",
    "edit_company": "Edit company",
    "delete_company_confirmation": "Are you sure you want to delete this company ?",
    "delete_company_success": "Company was successfully deleted.",
    "edit_company_success": "Company was successfully edited."
  },
  "fr": {
    "companies": "Sociétés",
    "name": "Nom",
    "studies": "Etudes",
    "max_users": "Max utilisateurs",
    "max_keywords": "Max mots clés",
    "max_competitors": "Max concurrents",
    "status": "Statut",
    "users": "Utilisateurs",
    "actions": "Actions",
    "create_company": "Créer une société",
    "create_company_success": "La société a été créée avec succès.",
    "edit_company": "Editez une société",
    "delete_company_confirmation": "Etes-vous sûr de vouloir supprimer cette société ?",
    "delete_company_success": "La société a été supprimé avec succès.",
    "edit_company_success": "La société a été édité avec succès."
  },
  "de": {
    "companies": "Firmen",
    "name": "Name",
    "studies": "Studien",
    "max_users": "Maximale Benutzer",
    "max_keywords": "Maximale Schlüsselwörter",
    "max_competitors": "Maximale Konkurrenten",
    "status": "Status",
    "users": "Benutzer",
    "actions": "Aktionen",
    "create_company": "Erstellen Sie ein Unternehmen",
    "create_company_success": "Das Unternehmen wurde erfolgreich gegründet.",
    "edit_company": "Bearbeiten Sie ein Unternehmen",
    "delete_company_confirmation": "Möchten Sie dieses Unternehmen wirklich löschen?",
    "delete_company_success": "Das Unternehmen wurde erfolgreich gelöscht.",
    "edit_company_success": "Das Unternehmen wurde erfolgreich bearbeitet."
  }
}
</i18n>
