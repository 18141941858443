<template>
  <li class="nav-item pl-3">
    <a
      id="googleAnalytics4Link"
      v-if="googleAnalyticsExists"
      :href="linkGoogleAnalytics"
      class="nav-link"
    >
      Google Analytics
    </a>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      linkGoogleAnalytics: null,
      googleAnalyticsAnchor: "#googleAnalytics",
    };
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
    googleAnalyticsExists() {
      return this.studyLinks["gaConfigurations"];
    },
  },
  mounted() {
    //this.linkGoogleAnalytics = `/spa/to?redirect_uri=/studies/${this.study["id"]}/configuration?tab=ga3`
    this.linkGoogleAnalytics = `/spa-app/studies/${this.study["id"]}/configuration?tab=ga3`;
  },
};
</script>
