export const availableSearchEnginesMixin = {
  data() {
    return {
      availableSearchEngines: [],
    };
  },
  computed: {
    countries() {
      let uniqAvailableSearchEngines = this.availableSearchEngines.filter(
        (value, index, array) =>
          array.findIndex(
            (t) => t.defaultLocationId === value.defaultLocationId
          ) === index
      );
      uniqAvailableSearchEngines.sort((a, b) => {
        if (a.countryName < b.countryName) return -1;
        if (a.countryName > b.countryName) return 1;
        return 0;
      });

      const countries = uniqAvailableSearchEngines.map((searchEngine) => ({
        name: searchEngine.countryName,
        isoCode: searchEngine.isoCountryCode,
        locationId: searchEngine.defaultLocationId,
      }));

      return countries;
    },
  },
  created() {
    this.$api.get("/search-engines").then((response) => {
      this.availableSearchEngines = response.data.data;
    });
  },
};
