<template>
  <div>
    <app-introduction
      :title="$t('netlinking', { url: study.url })"
      video-link="https://www.youtube.com/watch?v=vQcHKT28SYM&list=PLPfqPP7ygILNa0-P7-2C_Shkey_6JE1t1&index=8&t=0s"
      :user="currentUser"
    >
    </app-introduction>
    <div class="container">
      <div class="data-div">
        <metrics
          v-loading="isLoadingMetrics"
          :metrics="firstUrlMetrics.metrics"
          :classification="firstUrlMetrics.classification"
        >
        </metrics>
      </div>
      <el-card class="data-div">
        <graphs> </graphs>
      </el-card>
      <el-card class="data-div">
        <div class="baclink-missing-explanation">
          <span>
            {{ $t("backlinkMissingExplanation") }}
          </span>
        </div>
        <grids :current-user="currentUser"> </grids>
      </el-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import axios from "axios";
import AppIntroduction from "@/components/AppIntroduction";
import Metrics from "@/pages/Netlinking/Metrics";
import Graphs from "@/pages/Netlinking/Graphs";
import Grids from "@/pages/Netlinking/Grids";

export default {
  components: {
    AppIntroduction,
    Metrics,
    Graphs,
    Grids,
  },
  props: {
    currentUser: Object,
  },
  data() {
    return {
      request: null,
      dataMetrics: null,
      dataTopBacklinks: null,
      dataTopPages: null,
      dataTopKeywords: null,
      isLoadingMetrics: false,
      isLoadingBacklinks: false,
      isLoadingPages: false,
      isLoadingKeywords: false,
    };
  },
  methods: {
    fetchMetrics() {
      let requestMetrics = {};
      requestMetrics.urls = [{ url: this.study.url, type: "DOMAIN" }];
      requestMetrics.isoCountryCode = this.study.isoCountryCode;
      this.isLoadingMetrics = true;
      this.$api
        .post(`/netlinking/urls/metrics`, { ...requestMetrics })
        .then((responseMetrics) => {
          this.dataMetrics = responseMetrics.data;
          this.isLoadingMetrics = false;
        })
        .catch((error) => {
          this.isLoadingMetrics = false;
          this.dataMetrics = {};
          console.log(error);
          this.$message({
            message: this.$i18n.t("failFetch"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
  },
  computed: {
    firstUrlMetrics() {
      if (this.dataMetrics && this.dataMetrics.data) {
        return this.dataMetrics.data[0];
      } else {
        return { metrics: {}, classification: {} };
      }
    },
    ...mapState(["study"]),
  },
  mounted() {
    this.fetchMetrics();
  },
};
</script>
<style lang="scss" scoped>
.container {
  .data-div {
    margin-bottom: 2rem;
  }

  .baclink-missing-explanation {
    font-size: 0.8rem;
    font-style: italic;
    text-align: center;
  }
}
</style>
<i18n>
{
  "en": {
    "netlinking": "Netlinking of %{url}",
    "documentationDesc": "How to analyse your netlinking with SmartKeyword ?",
    "videoDesc": "How to use this screen",
    "failFetch": "Error encountered",
    "backlinkMissingExplanation": "Warning: some backlinks may not appear here because these sites are blocking their crawl. If you have any doubts about some of your backlinks, do not hesitate to contact your dedicated account manager"
  },
  "fr": {
    "netlinking": "Netlinking de %{url}",
    "documentationDesc": "Comment analyser votre netlinking dans SmartKeyword ?",
    "videoDesc": "Comment utiliser cet écran ?",
    "failFetch": "Nous avons rencontré une erreur",
    "backlinkMissingExplanation": "Attention : certains backlinks peuvent ne pas apparaître ici car ces sites bloquent leur exploration (crawl). Si vous avez un doute sur certains de vos backlinks, n'hésitez pas à contacter votre account manager dédié"
  },
  "de": {
    "netlinking": "Netzverlinkung von %{url}",
    "documentationDesc": "Wie analysieren Sie Ihr Netlinking in SmartKeyword?",
    "videoDesc": "Wie benutzt man diesen Bildschirm?",
    "failFetch": "Es ist ein Fehler aufgetreten",
    "backlinkMissingExplanation": "Warnung: Einige Backlinks werden hier möglicherweise nicht angezeigt, da diese Websites ihre Erkundung (Crawling) blockieren. Wenn Sie Zweifel an einigen Ihrer Backlinks haben, zögern Sie nicht, sich an Ihren zuständigen Account Manager zu wenden"
  }
}
</i18n>
