<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="30%"
    append-to-body
  >
    <div slot="title">
      <h3>{{ $t("urlThatWillHaveTheContent") }}</h3>
    </div>
    <el-form
      label-width="150px"
      label-position="right"
    >
      <el-form-item label="Url">
        <el-input
          v-model="url"
          placeholder="https://example.com"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('publishDate')">
        <date-picker v-model="publishDate"> </date-picker>
      </el-form-item>
    </el-form>
    <div class="submit">
      <el-button
        :disabled="url == '' || publishDate == null"
        type="success"
        plain
        @click="$emit('confirm', { url: url, publishDate: publishDate })"
      >
        {{ $t("confirm") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import DatePicker from "@/pages/CampaignManager/FormItems/DatePicker";

export default {
  components: {
    DatePicker,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      url: "",
      publishDate: null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.submit {
  text-align: center;
  margin-top: 1rem;
}
</style>

<i18n>
{
    "en": {
        "confirm": "Confirm",
        "urlThatWillHaveTheContent": "Url that will host the content",
        "publishDate": "Publish date"
    },
    "fr": {
        "confirm": "Valider",
        "urlThatWillHaveTheContent": "Url sur laquelle le contenu est publié",
        "publishDate": "Date de publication"
    },
    "it": {
        "confirm": "Conferma",
        "urlThatWillHaveTheContent": "Url che ospiterà il contenuto",
        "publishDate": "Data di pubblicazione"
    },
    "es": {
        "confirm": "Confirmar",
        "urlThatWillHaveTheContent": "URL que alojará el contenido",
        "publishDate": "Fecha de publicación"
    },
    "de": {
        "confirm": "Bestätigen",
        "urlThatWillHaveTheContent": "URL, die den Inhalt hosten wird",
        "publishDate": "Veröffentlichungsdatum"
    },
    "nl": {
        "confirm": "Bevestigen",
        "urlThatWillHaveTheContent": "Url die de inhoud zal hosten",
        "publishDate": "Publicatie datum"
    }
}
</i18n>
