<template>
  <div>
    <div class="chart-title">
      <el-tooltip
        placement="bottom"
        transition="el-fade-in"
      >
        <div slot="content">
          {{ $t("traffic_evolution_explication") }}
        </div>
        <span
          >{{ $t("traffic_evolution") }}
          <i class="fas fa-question-circle"></i>
        </span>
      </el-tooltip>
    </div>
    <highcharts
      v-if="isMounted"
      v-loading="isLoading"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
    isLoading: Boolean,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      isMounted: false,
      chartOptions: {
        chart: {
          type: "line",
        },
        title: {
          text: "",
        },
        xAxis: [
          {
            type: "datetime",
          },
        ],
        yAxis: {
          title: "",
          min: 0,
        },
        series: [],
        tooltip: {
          shared: true,
        },
        legend: {
          maxHeight: 100,
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      this.chartOptions.series = this.data.series;
    },
  },
  watch: {
    data() {
      if (this.data) this.updateSeries();
    },
  },
  mounted() {
    this.isMounted = true;
    if (this.data) this.updateSeries();
  },
};
</script>

<style scoped lang="scss">
.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
}

@media print {
  .fa-question-circle {
    display: none;
  }
}
</style>

<i18n>
{
  "en": {
    "traffic_evolution_explication": "This traffic is estimated based on the positions on each keyword, search volumes for each keyword, and click estimates based on each position on each keyword.",
    "traffic_evolution": "Evolution of estimated traffic"
  },
  "fr": {
    "traffic_evolution_explication": "Ce trafic est estimé en fonction des positions sur chacun des mots clés, des volumes de recherches de chacun de ceux-ci, et d'estimation de clics en fonction de chaque position sur chaque mot clé.",
    "traffic_evolution": "Evolution du trafic estimé"
  },
  "de": {
    "traffic_evolution_explication": "Dieser Verkehr wird anhand der Positionen für jedes der Schlüsselwörter, des Suchvolumens für jedes dieser Schlüsselwörter und der Schätzung der Klicks gemäß jeder Position für jedes Schlüsselwort geschätzt.",
    "traffic_evolution": "Entwicklung des geschätzten Verkehrs"
  }
}
</i18n>
