<template>
  <span v-if="!params.data.isHost">
    <el-tooltip
      :content="$t('delete_competitor')"
      placement="top"
    >
      <i
        class="far fa-trash-alt"
        @click="deleteCompetitor"
      ></i>
    </el-tooltip>
  </span>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";

export default {
  data() {
    return {};
  },
  methods: {
    deleteCompetitor() {
      this.params.context.deleteCompetitor(this.params.data.id);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.fa-trash-alt {
  cursor: pointer;
}
</style>

<i18n>
{
  "en": {
    "delete_competitor": "Delete competitor"
  },
  "fr": {
    "delete_competitor": "Supprimer concurrent"
  },
  "de": {
    "delete_competitor": "Wettbewerber löschen"
  }
}
</i18n>
