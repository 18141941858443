import Repository from "@/services/repository";

export default {
  getStudies() {
    return Repository.get(`/accounts.json`).then((response) => response.data);
  },

  deleteStudy(studyId) {
    return Repository.delete(`/a/${studyId}.json`).then((response) => response);
  },

  updateStudy(studyId, form) {
    return Repository.patch(`/a/${studyId}.json`, {
      account: {
        companyId: form.companyId,
        url: form.url,
        customName: form.customName,
        country: form.country,
        account_engines: form.searchEngines.map(
          (searchEngine) => searchEngine.id
        ),
        defaultSearchEngine: form.defaultSearchEngine.id,
        studyType: form.studyType,
      },
    }).then((response) => response);
  },
};
