<template>
  <div>
    <el-form :model="form">
      <el-form-item :label="$t('name')">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button
        plain
        @click="closeDialog"
        >{{ $t("cancel") }}</el-button
      >
      <el-button
        plain
        type="primary"
        @click="submitForm"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  props: {
    companyId: Number,
  },
  data() {
    return {
      form: {
        name: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.closeDialog();
      this.$emit("submit", this.form);
      if (!this.companyId) {
        this.resetForm();
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    resetForm() {
      this.form.name = "";
    },
    loadCompany() {
      CompaniesRepository.getCompany(this.companyId)
        .then((data) => {
          const companySettingId =
            data.data.relationships.companySetting.data.id;
          const companySettingAttributes = data.included.find(
            (companySetting) => companySetting.id === companySettingId
          ).attributes;
          this.form = data.data.attributes;
          this.form.companySetting = companySettingAttributes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    if (this.companyId) this.loadCompany();
  },
  watch: {
    companyId() {
      if (this.companyId) this.loadCompany();
    },
  },
};
</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "name": "Name",
    "cancel": "Cancel",
    "confirm": "Confirm"
  },
  "fr": {
    "name": "Nom",
    "cancel": "Annuler",
    "confirm": "Confirmer"
  },
  "de": {
    "name": "Name",
    "cancel": "Stornieren",
    "confirm": "Bestätigen"
  }
}
</i18n>
