<template>
  <div>
    <div class="title">
      <div class="file-title">
        <h4>
          <i class="fas fa-paperclip"></i>
          {{ $t("files") }}
        </h4>
      </div>
      <div v-loading="isUploading">
        <label
          for="taskfile"
          class="label-file"
          >{{ $t("add_a_file") }}</label
        >
        <input
          @change="uploadFile"
          id="taskfile"
          name="file"
          type="file"
        />
      </div>
    </div>
    <div
      class="downloadable-file"
      v-for="file in uploadedFiles"
      :key="file.data.s3name"
    >
      <i class="fas fa-file"></i>
      <span
        class="file-name"
        @click="getFile(file.data.s3name, file.data.name)"
        >{{ file.data.name }}</span
      >
      <i
        class="fas fa-trash delete"
        slot="reference"
        @click="deleteFile(file)"
      ></i>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const FilesRepository = RepositoryFactory.get("files");

export default {
  props: {
    value: Array,
    taskId: String,
    currentUser: Object,
  },
  components: {},
  data() {
    return {
      file: null,
      isUploading: false,
      blocks: this.value,
      s3FodlerName: "tasks",
    };
  },
  methods: {
    uploadFile(event) {
      let file = event.target.files[0];
      if (file) {
        if (file.size > 10485760) {
          this.$message.error(this.$i18n.t("file_is_too_big"));
          return null;
        }
        let form = new FormData();
        form.append("file[file]", file);
        form.append("file[folder]", this.s3FodlerName);
        this.isUploading = true;

        Rails.ajax({
          url: "/files",
          type: "POST",
          data: form,
          dataType: "json",
          success: (data) => {
            let fileObject = {};
            fileObject.createdAt = new Date();
            fileObject.updatedAt = fileObject.createdAt;
            fileObject.userId = this.currentUser.id;
            fileObject.type = "file";
            fileObject.data = { s3name: data, name: file.name };
            this.value.push(fileObject);
            this.$emit("input", this.value);
            this.isUploading = false;
          },
        });
      }
    },
    getFile(s3Name, fileName) {
      FilesRepository.getFile(s3Name, this.s3FodlerName)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFile(file) {
      this.value.splice(this.value.indexOf(file), 1);
      this.$emit("input", this.value);
      FilesRepository.deleteFile(file.data.s3name, this.s3FodlerName)
        .then((data) => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    uploadedFiles() {
      return this.value.filter((block) => {
        return block.type == "file";
      });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
@import "@/styles/variables";
.title {
  margin-bottom: 0.75rem;

  .label-file {
    cursor: pointer;
    color: #1a0dab;
    text-decoration: underline;
  }

  #taskfile {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.file-title {
  margin-top: 1rem;
}

.downloadable-file {
  margin-bottom: 0.8rem;
  display: flex;
  width: 100%;

  .file-name {
    font-size: 1.05rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .delete {
    margin-left: auto;
    color: $--color-danger;
    cursor: pointer;
  }

  .fas {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.downloadable-file:hover {
  background: rgba(9, 30, 66, 0.04);
}
</style>

<i18n>
{
  "en": {
    "files": "Files",
    "file_is_too_big": "File has a higher size than 10mb",
    "add_a_file": "Add a file"
  },
  "fr": {
    "files": "Fichiers",
    "file_is_too_big": "Le fichier a une taille supérieure à 10mo",
    "add_a_file": "Ajouter un fichier"
  },
  "de": {
    "files": "Dateien",
    "file_is_too_big": "Die Datei ist größer als 10 MB",
    "add_a_file": "Fügen Sie eine Datei hinzu"
  }
}
</i18n>
