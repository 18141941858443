<template>
  <div>
    <!-- <div>
            <div class="category indexation">
                <div class="score-and-name">
                    {{ $t("indexation") }}
                    <el-progress
                        class="progress-summary"
                        :text-inside="true"
                        :stroke-width="14" 
                        :percentage="averageContentGrade"
                        :format="format"
                        :color="customColorMethod">
                    </el-progress>
                </div>
                <div class="reco">
                    {{ bestRecommendationContent }}
                </div>
            </div>
        </div> -->
    <div>
      <div class="category balises">
        <div
          v-if="(crawl && crawl.httpStatus == 200) || averageGrades.avgTagsUrl"
        >
          <div class="score-and-name">
            {{ $t("serp") }}
            <el-progress
              class="progress-summary"
              :text-inside="true"
              :stroke-width="14"
              :percentage="averageGrades.avgTagsUrl"
              :format="format"
              :color="customColorMethod"
            >
            </el-progress>
          </div>
          <div
            class="reco"
            v-if="averageGrades.avgTagsUrl < 100"
          >
            <span>
              <i class="fas fa-wrench"></i>
              {{ $t("youCanWorkOn") }} :
              {{ listOfItemsThatNeedWork("tagsUrlDetails") }}
            </span>
          </div>
          <div
            class="reco"
            v-else
          >
            <span>
              <i class="fas fa-medal"></i>
              {{ $t("itsPerfect") }}
            </span>
          </div>
        </div>
        <div
          v-else
          class="text-center"
        >
          <i class="el-icon-warning"></i>{{ $t("crawlerProblem") }}
        </div>
      </div>
    </div>
    <el-divider />
    <div>
      <div
        class="category content"
        v-loading="isContentOptimizationLoading"
      >
        <div
          v-if="(crawl && crawl.httpStatus == 200) || averageGrades.avgContent"
        >
          <div class="score-and-name">
            {{ $t("content") }}
            <el-progress
              class="progress-summary"
              :text-inside="true"
              :stroke-width="14"
              :percentage="averageGrades.avgContent"
              :format="format"
              :color="customColorMethod"
            >
            </el-progress>
          </div>
          <div
            class="reco"
            v-if="averageGrades.avgContent < 100"
          >
            <span>
              <i class="fas fa-wrench"></i>
              {{ $t("youCanWorkOn") }} : {{ contentItemsThatNeedWork }}
            </span>
          </div>
          <div
            class="reco"
            v-else
          >
            <span>
              <i class="fas fa-medal"></i>
              {{ $t("itsPerfect") }}
            </span>
          </div>
        </div>
        <div
          v-else
          class="text-center"
        >
          <i class="el-icon-warning"></i>{{ $t("crawlerProblem") }}
        </div>
      </div>
    </div>
    <el-divider />
    <div>
      <div class="category technical">
        <div
          v-if="
            (crawl && crawl.httpStatus == 200) || averageGrades.avgTechnical
          "
        >
          <div class="score-and-name">
            {{ $t("technical") }}
            <el-progress
              class="progress-summary"
              :text-inside="true"
              :stroke-width="14"
              :percentage="averageGrades.avgTechnical"
              :format="format"
              :color="customColorMethod"
            >
            </el-progress>
          </div>
          <div
            class="reco"
            v-if="averageGrades.avgTechnical < 100"
          >
            <span>
              <i class="fas fa-wrench"></i>
              {{ $t("youCanWorkOn") }} :
              {{ listOfItemsThatNeedWork("technicalDetails") }}
            </span>
          </div>
          <div
            class="reco"
            v-else
          >
            <span>
              <i class="fas fa-medal"></i>
              {{ $t("itsPerfect") }}
            </span>
          </div>
        </div>
        <div
          v-else
          class="text-center"
        >
          <i class="el-icon-warning"></i>{{ $t("crawlerProblem") }}
        </div>
      </div>
    </div>
    <el-divider />
    <div>
      <div class="category netlinking">
        <div class="score-and-name">
          {{ $t("netlinking") }}
          <el-progress
            class="progress-summary"
            :text-inside="true"
            :stroke-width="14"
            :percentage="averageGrades.avgNetlinking"
            :format="format"
            :color="customColorMethod"
          >
          </el-progress>
        </div>
        <div
          class="reco"
          v-if="averageGrades.avgNetlinking < 100"
        >
          <span>
            <i class="fas fa-wrench"></i>
            {{ $t("youCanWorkOn") }} :
            {{ listOfItemsThatNeedWork("netlinkingDetails") }}
          </span>
        </div>
        <div
          class="reco"
          v-else
        >
          <span>
            <i class="fas fa-medal"></i>
            {{ $t("itsPerfect") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    contentData: Object,
    averageGrades: Object,
    crawl: Object,
    isContentOptimizationLoading: Boolean,
    contentData: Object,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    format(percentage) {
      return percentage;
    },
    customColorMethod(percentage) {
      if (percentage < 25) {
        return "#f56c6c";
      } else if (percentage < 50) {
        return "#e6a23c";
      } else if (percentage < 75) {
        return "#1989fa";
      } else {
        return "#67c23a";
      }
    },
    listOfItemsThatNeedWork(type) {
      if (this.data && this.data[type]) {
        return this.data[type]
          .filter((e) => e.score < 100)
          .map((e) => e.label)
          .join(", ");
      }
    },
  },
  computed: {
    contentItemsThatNeedWork() {
      if (this.contentData) {
        let itemsThatNeedWork = [];
        if (this.contentData.tagH1.score < 100)
          itemsThatNeedWork.push(this.$i18n.t("tagH1"));
        if (this.contentData.contentLength.score < 100)
          itemsThatNeedWork.push(this.$i18n.t("contentLength"));
        if (this.contentData.semantic.score < 100)
          itemsThatNeedWork.push(this.$i18n.t("semantic"));

        return itemsThatNeedWork.join(", ");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.category {
  display: flex;
  flex-direction: column;

  .score-and-name {
    font-weight: bold;

    .progress-summary {
      width: 15rem;
    }
  }

  .reco {
    margin-top: 0.4rem;

    .fa-medal {
      color: #ffd700;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "indexation": "Indexation",
    "content": "Content",
    "technical": "Technical",
    "serp": "SERP & Hn",
    "netlinking": "Netlinking",
    "itsPerfect": "Everything is good ! Well done !",
    "youCanWorkOn": "You can improve",
    "crawlerProblem": "A problem occurred during the crawl",
    "tagH1": "H1 tag",
    "contentLength": "Content length",
    "semantic": "Semantic"
  },
  "fr": {
    "indexation": "Indexation",
    "content": "Contenu",
    "technical": "Technique",
    "serp": "SERP & Hn",
    "netlinking": "Netlinking",
    "itsPerfect": "Bravo ! Tout est parfait !",
    "youCanWorkOn": "Vous pouvez améliorer",
    "crawlerProblem": "Un problème est survenu lors du crawl",
    "tagH1": "Balise H1",
    "contentLength": "Taille de contenu",
    "semantic": "Sémantique"
  },
  "de": {
    "indexation": "Indexierung",
    "content": "Inhalt",
    "technical": "Technik",
    "serp": "SERP & Hn",
    "netlinking": "Netzverlinkung",
    "itsPerfect": "Jubeln ! Alles ist perfekt !",
    "youCanWorkOn": "du kannst dich verbessern",
    "crawlerProblem": "Beim Crawlen ist ein Problem aufgetreten",
    "tagH1": "H1-Tag",
    "contentLength": "Inhaltsgröße",
    "semantic": "Semantik"
  }
}
</i18n>
