<template>
  <el-link @click="showDialog(params.value)">{{ params.value }}</el-link>
</template>

<script>
export default {
  methods: {
    showDialog(url) {
      this.params.context.showDialog(url);
    },
  },
};
</script>

<style scoped></style>
