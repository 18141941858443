<template>
  <div class="container">
    <h4>{{ $t("simulator") }}</h4>
    <el-divider />
    <div class="simulator">
      <div class="left w-100">
        <serp
          :title="inputTitleValue"
          :metaDescription="inputMetaValue"
          :url="inputUrlValue"
          :keyword="keyword"
        >
        </serp>
        <el-divider />
        <div class="simulation-form">
          <el-form
            :model="simulation"
            label-position="right"
          >
            <div class="form-item">
              <div>
                <span class="label">{{ $t("title_tag") }} - </span>
                <span
                  v-if="inputTitleValue"
                  v-bind:class="
                    inputTitleValue.length <= maxLimitTitleChars &&
                    inputTitleValue.length >= minLimitTitleChars
                      ? 'goodWidth'
                      : 'badWidth'
                  "
                >
                  {{ inputTitleValue.length }} {{ $t("characters") }}
                </span>
                <span
                  v-else
                  class="badWidth"
                >
                  0 {{ $t("characters") }}</span
                >
                -
                <span
                  v-bind:class="
                    containKeyword(inputTitleValue) ? 'goodWidth' : 'badWidth'
                  "
                >
                  {{ $t("targeted_keyword") }}
                </span>
                -
                <span
                  v-bind:class="
                    widthTitle <= limitTitleWidth ? 'goodWidth' : 'badWidth'
                  "
                >
                  {{ widthTitle }}/{{ limitTitleWidth }} pixels
                </span>
              </div>
              <div class="reco-container">
                <div class="test">
                  <el-input v-model="inputTitleValue"> </el-input>
                  <el-button
                    class="mt-3"
                    type="primary"
                    @click="changeVisibleTitlePopup()"
                    >{{ $t("chatgpt_button") }}</el-button
                  >
                </div>
                <Recommandation :recommendations="titleReco" />
              </div>
              <PopUp
                v-model="dialogTitleVisible"
                :pageInfo="simulation"
                :detailedRecommendations="detailedRecommendations.title"
                :competitorsPages="competitorsPages"
                :score="titleScores"
                popupTitle="title"
                :keyword="keyword"
                @textEvent="handleTextTitleEvent($event)"
              />
            </div>
            <div class="form-item">
              <div>
                <span class="label">{{ $t("meta_description_tag") }} - </span>
                <span
                  v-if="inputMetaValue"
                  v-bind:class="
                    inputMetaValue.length <= maxLimitMetaDescChars &&
                    inputMetaValue.length >= minLimitMetaDescChars
                      ? 'goodWidth'
                      : 'badWidth'
                  "
                >
                  {{ inputMetaValue.length }} {{ $t("characters") }}
                </span>
                <span
                  v-else
                  class="badWidth"
                >
                  0 {{ $t("characters") }}</span
                >
                -
                <span
                  v-bind:class="
                    containKeyword(inputMetaValue) ? 'goodWidth' : 'badWidth'
                  "
                >
                  {{ $t("targeted_keyword") }}
                </span>
                -
                <span
                  v-bind:class="
                    widthMetaDescription <= limitMetaDescWidth
                      ? 'goodWidth'
                      : 'badWidth'
                  "
                >
                  {{ widthMetaDescription }}/{{ limitMetaDescWidth }} pixels
                </span>
              </div>
              <div class="reco-container">
                <div class="test">
                  <el-input
                    v-model="inputMetaValue"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    style=""
                  >
                  </el-input>
                  <el-button
                    class="mt-3"
                    type="primary"
                    @click="changeVisibleMetaPopup()"
                    >{{ $t("chatgpt_button") }}</el-button
                  >
                </div>
                <Recommandation :recommendations="MetaDescReco" />
              </div>
              <PopUp
                @textEvent="handleTextMetaDescEvent($event)"
                v-model="dialogMetaVisible"
                :pageInfo="simulation"
                :detailedRecommendations="
                  detailedRecommendations.metaDescription
                "
                :competitorsPages="competitorsPages"
                :score="metaDescScores"
                popupTitle="metaDescription"
                :keyword="keyword"
              />
            </div>
            <div class="form-item">
              <div>
                <span class="label">{{ $t("url") }} - </span>
                <span
                  v-if="inputUrlValue"
                  v-bind:class="
                    inputUrlValue.length <= maxLimitUrlChars &&
                    inputUrlValue.length >= minLimitUrlChars
                      ? 'goodWidth'
                      : 'badWidth'
                  "
                >
                  {{ inputUrlValue.length }} {{ $t("characters") }}
                </span>
                <span
                  v-else
                  class="badWidth"
                >
                  0 {{ $t("characters") }}</span
                >
              </div>
              <div class="reco-container">
                <div class="test">
                  <el-input
                    v-model="inputUrlValue"
                    style=""
                  />
                  <el-button
                    class="mt-3"
                    type="primary"
                    @click="changeVisibleUrlPopup()"
                    >{{ $t("urlButton") }}</el-button
                  >
                </div>
                <Recommandation :recommendations="UrlReco" />
              </div>
              <PopUpUrl
                v-model="dialogUrlVisible"
                :pageInfo="simulation"
                :detailedRecommendations="
                  encodeUrlRecommendations(detailedRecommendations.url)
                "
                :competitorsPages="competitorsPages"
              />
            </div>
          </el-form>
          <el-button
            type="primary"
            plain
            @click="putActualValues()"
            >{{ $t("put_back_values") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Serp from "@/pages/ActionPlan/Simulator/Serp";
import PopUp from "@/pages/ActionPlan/Simulator/SerpPopup/popUp.vue";
import PopUpUrl from "@/pages/ActionPlan/Simulator/SerpPopup/popUpUrl.vue";
import Recommandation from "./Recommandation.vue";

export default {
  props: {
    pageInfo: Object,
    keyword: Object,
    detailedRecommendations: Object,
    metaContentsData: Object,
  },
  components: {
    Serp,
    PopUp,
    PopUpUrl,
    Recommandation,
  },
  data() {
    return {
      dialogTitleVisible: false,
      dialogMetaVisible: false,
      dialogUrlVisible: false,

      simulation: {
        title: this.pageInfo.title,
        url: this.pageInfo.url,
        metaDescription: this.pageInfo.metaDescription,
      },
      limitUrlWidth: 500,
      limitTitleWidth: 600,
      limitMetaDescWidth: 1200,
      maxLimitTitleChars: 65,
      minLimitTitleChars: 41,
      maxLimitMetaDescChars: 160,
      minLimitMetaDescChars: 101,
      maxLimitUrlChars: 100,
      minLimitUrlChars: 21,
      inputTitleValue: null,
      inputMetaValue: null,
      inputUrlValue: null,
      titleReco: {},
      MetaDescReco: {},
      UrlReco: {},
    };
  },
  computed: {
    competitorsPages() {
      if (this.metaContentsData && this.metaContentsData.metaContents) {
        return this.metaContentsData.metaContents.map((element) => {
          return {
            url: element.url,
            title: element.titles,
            metaDescription: element.metaDescriptions,
            crawlDate: this.metaContentsData.crawlDate,
          };
        });
      } else return [];
    },
    titleScores() {
      return {
        ownScore: this.computeTitleScore(this.simulation.title),
        serpScore: this.computeTitleSerpScore(
          this.metaContentsData.metaContents
        ),
      };
    },
    metaDescScores() {
      return {
        ownScore: this.computeMetaScore(this.simulation.metaDescription),
        serpScore: this.computeMetaDescSerpScore(
          this.metaContentsData.metaContents
        ),
      };
    },
    widthTitle() {
      if (this.simulation.title) {
        return this.measureWidth(
          this.simulation.title,
          "20px Arial,sans-serif"
        );
      } else {
        return 0;
      }
    },
    widthMetaDescription() {
      if (this.simulation.metaDescription) {
        return this.measureWidth(
          this.simulation.metaDescription,
          "14px Arial,sans-serif"
        );
      } else {
        return 0;
      }
    },
    widthUrl() {
      if (this.simulation.url) {
        return this.measureWidth(this.simulation.url, "14px Arial,sans-serif");
      } else {
        return 0;
      }
    },
  },
  methods: {
    changeVisibleTitlePopup() {
      this.dialogTitleVisible = true;
      this.dialogMetaVisible = false;
    },
    changeVisibleMetaPopup() {
      this.dialogTitleVisible = false;
      this.dialogMetaVisible = true;
    },
    changeVisibleUrlPopup() {
      this.dialogUrlVisible = true;
    },
    measureWidth(text, font) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      ctx.font = font;
      return Math.round(ctx.measureText(text).width);
    },
    putActualValues() {
      this.inputTitleValue = this.simulation.title;
      this.inputMetaValue = this.simulation.metaDescription;
      this.inputUrlValue = this.pageInfo.url;
    },
    debounce(newValue, callback) {
      setTimeout(() => {
        callback(newValue);
      }, 300);
    },
    containKeyword(input) {
      if (input) {
        const lowerCaseWords = this.keyword.text.toLowerCase().split(" ");
        const lowerCaseInput = input.toLowerCase();
        let containKeyword = true;
        lowerCaseWords.forEach((word) => {
          if (!lowerCaseInput.includes(word)) {
            containKeyword = false;
          }
        });
        return containKeyword;
      }
      return false;
    },
    textFormat() {
      for (const element of this.competitorsPages) {
        if (element.updatedAt)
          element.updatedAt = new Date(element.updatedAt).toLocaleDateString(
            this.$i18n.locale
          );
        if (element.title)
          element.title = element.title.replaceAll(/&nbsp;/g, " ");
        if (element.metaDescription)
          element.metaDescription = element.metaDescription.replaceAll(
            /&nbsp;/g,
            " "
          );
      }
    },
    titleEdit(text) {
      let tmp = [];
      let size = this.computeTitleSizeScore(text);
      let content = this.computeTitleContentScore(text, this.keyword.text);

      tmp.push(size.comment);
      tmp.push(content.comment);

      this.titleReco = {
        ...this.titleReco,
        statements: tmp,
        score: this.computeScore(size.score, content.score),
      };
    },
    metaDescEdit(text) {
      let tmp = [];
      let size = this.computeMetaDescSizeScore(text);
      let content = this.computeMetaDescContentScore(text, this.keyword.text);

      tmp.push(size.comment);
      tmp.push(content.comment);

      this.MetaDescReco = {
        ...this.MetaDescReco,
        statements: tmp,
        score: this.computeScore(size.score, content.score),
      };
    },
    urlEdit(text) {
      this.UrlReco = {
        ...this.UrlReco,
        statements: [this.computeUrlSizeScore(text).comment],
        score: this.computeUrlSizeScore(text).score,
      };
    },
    handleTextMetaDescEvent(text) {
      this.metaDescEdit(text);
      this.inputMetaValue = text;
    },
    handleTextTitleEvent(text) {
      this.titleEdit(text);
      this.inputTitleValue = text;
    },
    encodeUrlRecommendations(urlRecommendations) {
      return {
        label: urlRecommendations.label,
        name: urlRecommendations.name,
        statements: [urlRecommendations.statements[0]],
        score: urlRecommendations.score,
        url: urlRecommendations.url,
        title: urlRecommendations.title,
        metaDescription: urlRecommendations.metaDescription,
      };
    },

    computeTitleSizeScore(tag) {
      if (tag === null) return { score: 0, comment: "null title tag" };

      const value = tag.length;
      let sizeTitle = {};

      switch (true) {
        case 1 <= value && value <= 20:
          sizeTitle["score"] = 20;
          sizeTitle["comment"] =
            this.$i18n.t("title.size.tooShort.part1") +
            value +
            this.$i18n.t("title.size.tooShort.part2");
          break;
        case 21 <= value && value <= 40:
          sizeTitle["score"] = 70;
          sizeTitle["comment"] =
            this.$i18n.t("title.size.slightlyShort.part1") +
            value +
            this.$i18n.t("title.size.slightlyShort.part2");
          break;
        case 41 <= value && value <= 65:
          sizeTitle["score"] = 100;
          sizeTitle["comment"] = this.$i18n.t("title.size.perfectLength");
          break;
        case 66 <= value && value <= 100:
          sizeTitle["score"] = 70;
          sizeTitle["comment"] =
            this.$i18n.t("title.size.tooLong.part1") +
            value +
            this.$i18n.t("title.size.tooLong.part2");
          break;
        case value >= 101:
          sizeTitle["score"] = 20;
          sizeTitle["comment"] =
            this.$i18n.t("title.size.tooLong.part1") +
            value +
            this.$i18n.t("title.size.tooLong.part2");
          break;
        default:
          sizeTitle["score"] = 0;
          sizeTitle["comment"] = this.$i18n.t("title.size.noWords");
      }
      return sizeTitle;
    },
    computeUrlSizeScore(tag) {
      if (tag === null) return { score: 0, comment: "null url tag" };

      const value = tag.length;
      let sizeUrl = {};

      switch (true) {
        case 1 <= value && value <= 20:
          sizeUrl["score"] = 50;
          sizeUrl["comment"] =
            this.$i18n.t("URL.tooShort.part1") +
            value +
            this.$i18n.t("URL.tooShort.part2");
          break;
        case 21 <= value && value <= 100:
          sizeUrl["score"] = 100;
          sizeUrl["comment"] = this.$i18n.t("URL.perfectLength");
          break;
        case value >= 101:
          sizeUrl["score"] = 70;
          sizeUrl["comment"] =
            this.$i18n.t("URL.tooLong.part1") +
            value +
            this.$i18n.t("URL.tooLong.part2");
          break;
        default:
          sizeUrl["score"] = 0;
          sizeUrl["comment"] = this.$i18n.t("URL.noWords");
      }
      return sizeUrl;
    },
    computeMetaDescSizeScore(tag) {
      if (tag === null)
        return { score: 0, comment: "null meta description tag" };

      const value = tag.length;
      let sizeMeta = {};

      switch (true) {
        case 1 <= value && value <= 100:
          sizeMeta["score"] = 30;
          sizeMeta["comment"] =
            this.$i18n.t("metaDesc.size.tooShort.part1") +
            value +
            this.$i18n.t("metaDesc.size.tooShort.part2");
          break;
        case 101 <= value && value <= 160:
          sizeMeta["score"] = 100;
          sizeMeta["comment"] = this.$i18n.t("metaDesc.size.perfectLength");
          break;
        case 161 <= value && value <= 300:
          sizeMeta["score"] = 30;
          sizeMeta["comment"] =
            this.$i18n.t("metaDesc.size.tooLong.part1") +
            value +
            this.$i18n.t("metaDesc.size.tooLong.part2");
          break;
        case 301 <= value:
          sizeMeta["score"] = 10;
          sizeMeta["comment"] =
            this.$i18n.t("metaDesc.size.tooLong.part1") +
            value +
            this.$i18n.t("metaDesc.size.tooLong.part2");
          break;
        default:
          sizeMeta["score"] = 0;
          sizeMeta["comment"] = this.$i18n.t("metaDesc.size.noWords");
      }
      return sizeMeta;
    },
    checkKeywordInBalise(keywordText, balise) {
      if (balise === null) {
        return "";
      }

      keywordText = keywordText.toLowerCase();
      balise = balise.toLowerCase();

      var clearedKeywordText = keywordText
        .match(/[^{}'<>:&*/.+@£_$€#\[\]"\n+\s-"!?,%^;~`()=]+/g)
        .join(" ");
      // var clearedKeywordText = keywordText.match(/[^_\W]+/g).join(' ');

      const missingWord = [];
      const wordArray = clearedKeywordText.split(" ");

      for (let i = 0; i < wordArray.length; i++) {
        if (balise.indexOf(wordArray[i]) === -1) {
          missingWord.push(wordArray[i]);
        }
      }

      return { missingWord: missingWord, keywordText: wordArray };
    },
    computeTitleContentScore(tag, keyword) {
      if (tag === null || keyword === null) return { score: 0 };

      const contentArray = this.checkKeywordInBalise(keyword, tag);
      let contentTitle = {};

      switch (true) {
        case contentArray.missingWord.length == contentArray.keywordText.length:
          contentTitle["score"] = 10;
          contentTitle["comment"] =
            this.$i18n.t("title.content.noKeywords") + keyword;
          break;
        case contentArray.missingWord.length == 0:
          contentTitle["score"] = 100;
          contentTitle["comment"] =
            this.$i18n.t("title.content.containsAll.part1") +
            keyword +
            this.$i18n.t("title.content.containsAll.part2");
          break;
        case tag.length == 0:
          contentTitle["score"] = 0;
          contentTitle["comment"] = this.$i18n.t("title.content.contains");
          break;
        default:
          contentTitle["score"] = 50;
          contentTitle["comment"] =
            this.$i18n.t("title.content.containsSome.part1") +
            keyword +
            this.$i18n.t("title.content.containsSome.part2") +
            contentArray.missingWord;
      }
      return contentTitle;
    },
    computeMetaDescContentScore(tag, keyword) {
      if (tag === null) return { score: 0 };

      const contentArray = this.checkKeywordInBalise(keyword, tag);
      let contentMeta = {};

      switch (true) {
        case contentArray.missingWord.length == contentArray.keywordText.length:
          contentMeta["score"] = 10;
          contentMeta["comment"] =
            this.$i18n.t("metaDesc.content.noKeywords") + keyword;
          break;
        case contentArray.missingWord.length == 0:
          contentMeta["score"] = 100;
          contentMeta["comment"] =
            this.$i18n.t("metaDesc.content.containsAll.part1") +
            keyword +
            this.$i18n.t("metaDesc.content.containsAll.part2");
          break;
        case tag.length == 0:
          contentMeta["score"] = 0;
          contentMeta["comment"] = this.$i18n.t("metaDesc.content.contains");
          break;
        default:
          contentMeta["score"] = 50;
          contentMeta["comment"] =
            this.$i18n.t("metaDesc.content.containsSome.part1") +
            keyword +
            this.$i18n.t("metaDesc.content.containsSome.part2") +
            contentArray.missingWord;
      }
      return contentMeta;
    },
    computeScore(sizeScore, contentScore) {
      return Math.round((sizeScore * contentScore) / 100);
    },
    computeTitleScore(title) {
      return this.computeScore(
        this.computeTitleSizeScore(title).score,
        this.computeTitleContentScore(title, this.keyword.text).score
      );
    },
    computeMetaScore(metaDesc) {
      return this.computeScore(
        this.computeMetaDescSizeScore(metaDesc).score,
        this.computeMetaDescContentScore(metaDesc, this.keyword.text).score
      );
    },
    computeTitleSerpScore(metaContents) {
      if (!metaContents) return 0;
      let sum = 0;

      for (let metaContent in metaContents) {
        sum += this.computeTitleScore(metaContents[metaContent].titles);
      }

      return Math.round(sum / metaContents.length);
    },
    computeMetaDescSerpScore(metaContents) {
      if (!metaContents) return 0;
      let sum = 0;

      for (let metaContent in metaContents) {
        sum += this.computeMetaScore(
          metaContents[metaContent].metaDescriptions
        );
      }

      return Math.round(sum / metaContents.length);
    },
  },
  created() {
    this.textFormat();
    this.inputTitleValue = this.simulation.title;
    this.inputMetaValue = this.simulation.metaDescription;
    this.inputUrlValue = this.pageInfo.url;
    this.titleReco["statements"] =
      this.detailedRecommendations.title.statements;
    this.titleReco["score"] = this.detailedRecommendations.title.score;
    this.MetaDescReco["statements"] =
      this.detailedRecommendations.metaDescription.statements;
    this.MetaDescReco["score"] =
      this.detailedRecommendations.metaDescription.score;
    this.UrlReco["statements"] = [
      this.detailedRecommendations.url.statements[0],
    ];
    this.UrlReco["score"] = this.detailedRecommendations.url.score;
  },
  watch: {
    inputTitleValue(newTitleValue) {
      this.debounce(newTitleValue, this.titleEdit);
    },
    inputMetaValue(newMetaValue) {
      this.debounce(newMetaValue, this.metaDescEdit);
    },
    inputUrlValue(newUrlValue) {
      this.debounce(newUrlValue, this.urlEdit);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.reco-container {
  display: flex;
  flex-direction: row;
}
.test {
  width: 700px;
  margin-bottom: 3.5em;
}
.container {
  margin-bottom: 1rem;

  .simulator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left {
      .simulation-form {
        .form-item {
          margin-bottom: 1rem;

          .label {
            font-weight: bold;
          }

          .goodWidth {
            color: $--color-success;
          }

          .badWidth {
            color: $--color-danger;
          }
        }
      }
    }

    .divider {
      margin: 0px 8px 0px 8px;

      .vertical {
        height: 100%;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "simulator": "Simulator",
    "in_numbers": "In numbers",
    "url": "Url",
    "title_tag": "Title tag",
    "title": "Title",
    "meta_description": "Meta-description",
    "meta_description_tag": "Meta-description tag",
    "type": "Type",
    "pixels": "Pixels",
    "words": "Words",
    "characters": "characters",
    "put_back_values": "Reload my tags",
    "targeted_keyword": "Targeted keyword",
    "chatgpt_button": "Competitor tags & AI",
    "urlButton": "Competitors",
    "seoScoreGeneration":"Generating SEO scores ... This may take up to a few minutes",
    "title": {
      "size" : {
        "tooLong" : {
          "part1": "🔧 Your title tag is too long (",
          "part2": " characters) ! It should be between 41 and 65 characters."
        },
        "tooShort" : {
          "part1": "🔧 Your title tag is too short (",
          "part2": " characters). Add commercial arguments, your tag must ideally contain between 41 and 65 characters and you must encourage the click."
        },
        "slightlyShort": {
          "part1": "🔧 Your title tag is sightly too short (",
          "part2": " characters). Add commercial arguments, your tag must ideally contain between 41 and 65 characters and you must encourage the click."
        },
        "perfectLength": "👍 Your title tag has a perfect size (between 41 and 65 characters) !",
        "noWords": "🔧 Your title tag does not contain any element... Remember to add some !"
      },
      "content": {
        "noKeywords": "🔧 Your Title tag does not contain any keywords... Remember to use them! You should add the following keywords:",
        "contains": "💁‍♂️ This tag must be incentive and contain the main keyword of your page",
        "containsAll": {
          "part1": "👍 Your Title tag contains all the words of ",
          "part2": ", it is very good !"
        },
        "containsSome": {
          "part1": "🔧 Your Title tag does not contain all the words of ",
          "part2": ". It can be improved! You should add the following keywords : "
        }
      }
    },
    "URL": {
      "tooLong" : {
        "part1": "🔧 Your url is long (",
        "part2": " characters) ! The ideal would be between 21 and 100 characters."
      },
      "tooShort" : {
        "part1": "🔧 Your url is very short (",
        "part2": " characters), are you sure you have included your keywords in your url? It should be between 21 and 100 characters"
      },
      "perfectLength": "👍 Your url has a perfect size (between 21 and 100 characters) !",
      "noWords": "🔧 Your url does not contain any element..."
    },
    "metaDesc": {
      "size" : {      
        "tooLong" : {
          "part1": "🔧 Your meta description tag is too long (",
          "part2": ") ! It should be between 101 and 160 characters."
        },
        "tooShort" : {
          "part1": "🔧 Your meta description tag is too short (",
          "part2": ") ! It should be between 101 and 160 characters. Give more details to your meta description tag to make your prospects want to come to your site even more."
        },
        "perfectLength": "👍 Your meta description tag has a perfect size (between 101 and 160 characters) !",
        "noWords": "🔧 Your meta description tag does not contain any element... Remember to add some !"
      },
      "content" : {
        "noKeywords": "🔧 Your meta description tag does not contain any keywords... Remember to use them! You should add the following keywords :",
        "contains": "💁‍♂️ The meta description tag must be inciting and ideally contain the keywords of ",
        "containsAll": {
          "part1": "👍 Your meta description tag contains all the words of ",
          "part2": ", it's very good !"
        },
        "containsSome": {
          "part1": "🔧 Your meta description tag does not contain all the words of ",
          "part2": ". It can be improved! You should add the following keywords : "
        }
      }
    }
  },
  "fr": {
    "simulator": "Simulateur",
    "in_numbers": "En chiffres",
    "url": "Url",
    "title_tag": "Balise Title",
    "title": "Titre",
    "meta_description": "Méta-description",
    "meta_description_tag": "Balise Méta-description",
    "type": "Type",
    "pixels": "Pixels",
    "words": "Mots",
    "characters": "caractères",
    "put_back_values": "Recharger mes balises",
    "targeted_keyword": "Mot clé ciblé",
    "chatgpt_button": "Balises concurrents & IA",
    "urlButton": "Concurrence",
    "seoScoreGeneration":"Génération des scores SEO... Cela peut prendre jusqu'à quelques minutes",
    "title": {
      "size" : {
        "tooLong" : {
          "part1": "🔧 Votre balise title est trop longue (",
          "part2": " caractères) ! Elle devrait faire entre 41 et 65 caractères."
        },
        "tooShort" : {
          "part1": "🔧 Votre balise title est trop courte (",
          "part2": " caractères). Ajoutez des arguments commerciaux, votre balise doit idéalement contenir entre 41 et 65 caractères et il faut inciter au clic."
        },
        "slightlyShort": {
          "part1": "🔧 Votre balise title est légèrement trop courte (",
          "part2": " caractères). Ajoutez des arguments commerciaux, votre balise doit idéalement contenir entre 41 et 65 caractères et il faut inciter au clic."
        },
        "perfectLength": "👍 Votre balise title a une taille parfaite (entre 41 et 65 caractères) !",
        "noWords": "🔧 Votre balise title ne contient aucun élement... Pensez à en rajouter !"
      },
      "content": {
        "noKeywords": "🔧 Votre balise Title ne contient aucun mot clé... Pensez à les utiliser ! Vous devriez ajouter les mots clés suivants :",
        "contains": "💁‍♂️ Cette balise doit être incitative et contenir le mot clé principal de votre page",
        "containsAll": {
          "part1": "👍 Votre balise Title contient tous les mots de ",
          "part2": ", c'est très bien !"
        },
        "containsSome": {
          "part1": "🔧 Votre balise Title ne contient pas tous les mots de ",
          "part2": ". C'est améliorable ! Vous devriez ajouter les mots clés suivants : "
        }
      }
    },
    "URL": {
      "tooLong" : {
        "part1": "🔧 Votre url est longue (",
        "part2": " caractères) ! L'idéal serait d'être entre 21 et 100 caractères."
      },
      "tooShort" : {
        "part1": "🔧 Votre url est très courte (",
        "part2": " caractères), êtes-vous sûr d'avoir intégré vos mots clés dans votre url ? Elle devrait faire entre 21 et 100 caractères"
      },
      "perfectLength": "👍 Votre url a une taille parfaite (entre 21 et 100 caractères) !",
      "noWords": "🔧 Votre url ne contient aucun élement..."
    },
    "metaDesc": {
      "size" : {      
        "tooLong" : {
          "part1": "🔧 Votre balise meta description est trop longue (",
          "part2": ") ! Elle devrait faire entre 101 et 160 caractères."
        },
        "tooShort" : {
          "part1": "🔧 Votre balise meta description est trop courte (",
          "part2": ") ! Elle devrait faire entre 101 et 160 caractères. Donnez plus de détails à votre balise meta description pour donner encore plus envie à vos prospects de venir sur votre site."
        },
        "perfectLength": "👍 Votre balise meta description a une taille parfaite (entre 101 et 160 caractères) !",
        "noWords": "🔧 Votre balise meta description ne contient aucun élement... Pensez à en rajouter !"
      },
      "content" : {
        "noKeywords": "🔧 Votre balise meta description ne contient aucun mot clé... Pensez à les utiliser ! Vous devriez ajouter les mots clés suivants :",
        "contains": "💁‍♂️ La balise meta description doit être incitative et idéalement contenir les mots clés de ",
        "containsAll": {
          "part1": "👍 Votre balise meta description contient tous les mots de ",
          "part2": ", c'est très bien !"
        },
        "containsSome": {
          "part1": "🔧 Votre balise meta description ne contient pas tous les mots de ",
          "part2": ". C'est améliorable ! Vous devriez ajouter les mots clés suivants : "
        }
      }
    }
  },
  "de": {
    "simulator": "Simulator",
    "in_numbers": "In Zahlen",
    "url": "URL",
    "title_tag": "Balisischer Titel",
    "title": "Titel",
    "meta_description": "Meta-Beschreibung",
    "meta_description_tag": "Meta-Beschreibungs-Tag",
    "type": "Typ",
    "pixels": "Pixel",
    "words": "Wörter",
    "characters": "Figuren",
    "put_back_values": "Meine Tags neu laden",
    "targeted_keyword": "Ziel-Keyword",
    "chatgpt_button": "Mitbewerber-Tags und KI",
    "urlButton": "Konkurrent",
    "seoScoreGeneration":"SEO-Scores werden generiert... Dies kann einige Minuten dauern",
    "title": {
      "size" : {
        "tooLong" : {
          "part1": "🔧 Ihr Title-Tag ist zu lang (",
          "part2": " Figuren) ! Es sollte zwischen 41 und 65 Zeichen lang sein."
        },
        "tooShort" : {
          "part1": "🔧 Ihr Title-Tag ist zu kurz (",
          "part2": " Figuren). Fügen Sie kommerzielle Argumente hinzu, Ihr Tag muss idealerweise zwischen 41 und 65 Zeichen enthalten und Sie müssen den Klick fördern."
        },
        "slightlyShort": {
          "part1": "🔧 Ihr Title-Tag ist etwas zu kurz (",
          "part2": " Figuren). Fügen Sie kommerzielle Argumente hinzu, Ihr Tag muss idealerweise zwischen 41 und 65 Zeichen enthalten und Sie müssen den Klick fördern."
        },
        "perfectLength": "👍 Ihr Title-Tag hat die perfekte Größe (zwischen 41 und 65 Zeichen) !",
        "noWords": "🔧 Ihr Title-Tag enthält kein Element... Denken Sie daran, einige hinzuzufügen !"
      },
      "content": {
        "noKeywords": "🔧 Ihr Title-Tag enthält keine Schlüsselwörter... Denken Sie daran, diese zu verwenden! Sie sollten die folgenden Schlüsselwörter hinzufügen :",
        "contains": "💁‍♂️ Dieses Tag muss ein Anreiz sein und das Hauptschlüsselwort Ihrer Seite enthalten",
        "containsAll": {
          "part1": "👍 Ihr Title-Tag enthält alle Wörter von ",
          "part2": ", es ist sehr gut !"
        },
        "containsSome": {
          "part1": "🔧 Ihr Title-Tag enthält nicht alle Wörter von ",
          "part2": ". Es kann verbessert werden! Sie sollten die folgenden Schlüsselwörter hinzufügen : "
        }
      }
    },
    "URL": {
      "tooLong" : {
        "part1": "🔧 Ihre URL ist lang (",
        "part2": " Figuren) ! Ideal wären zwischen 21 und 100 Zeichen."
      },
      "tooShort" : {
        "part1": "🔧 Ihre URL ist sehr kurz (",
        "part2": " Zeichen), sind Sie sicher, dass Sie Ihre Schlüsselwörter in Ihre URL aufgenommen haben? Es sollte zwischen 21 und 100 Zeichen lang sein"
      },
      "perfectLength": "👍 Ihre URL hat die perfekte Größe (zwischen 21 und 100 Zeichen) !",
      "noWords": "🔧 Ihre URL enthält kein Element..."
    },
    "metaDesc": {
      "size" : {      
        "tooLong" : {
          "part1": "🔧 Ihr Meta-Beschreibungs-Tag ist zu lang (",
          "part2": ") ! Es sollte zwischen 101 und 160 Zeichen lang sein."
        },
        "tooShort" : {
          "part1": "🔧 Ihr Meta-Beschreibungs-Tag ist zu kurz (",
          "part2": ") ! Es sollte zwischen 101 und 160 Zeichen lang sein. Geben Sie Ihrem Meta-Beschreibungs-Tag mehr Details, damit Ihre potenziellen Kunden noch mehr Lust auf Ihre Website haben."
        },
        "perfectLength": "👍 Ihr Meta-Beschreibungs-Tag hat die perfekte Größe (zwischen 101 und 160 Zeichen). !",
        "noWords": "🔧 Ihr Meta-Beschreibungs-Tag enthält kein Element... Denken Sie daran, einige hinzuzufügen !"
      },
      "content" : {
        "noKeywords": "🔧 Ihr Meta-Beschreibungs-Tag enthält keine Schlüsselwörter... Denken Sie daran, diese zu verwenden! Sie sollten die folgenden Schlüsselwörter hinzufügen :",
        "contains": "💁‍♂️ Der Meta-Beschreibungs-Tag muss anregend sein und idealerweise die Schlüsselwörter von enthalten ",
        "containsAll": {
          "part1": "👍 Ihr Meta-Beschreibungs-Tag enthält alle Wörter von ",
          "part2": ", es ist sehr gut !"
        },
        "containsSome": {
          "part1": "🔧 Ihr Meta-Beschreibungs-Tag enthält nicht alle Wörter von ",
          "part2": ". Es kann verbessert werden! Sie sollten die folgenden Schlüsselwörter hinzufügen : "
        }
      }
    }
  }
}
</i18n>
