import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";
import { RepositoryFactory } from "@/services/repositoryFactory";

const StudiesRepository = RepositoryFactory.get("studies");
import api from "../api";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    studyId: null,
    study: null,
    studyLinks: null,
    userLocale: null,
  },
  mutations: {
    SET_STUDY_ID(state, studyId) {
      state.studyId = studyId;
    },
    SET_STUDY(state, studyResponse) {
      state.study = studyResponse.data;
      state.studyLinks = studyResponse.links;
    },
    SET_USER_LANGUAGE(state, userLocale) {
      state.userLocale = userLocale;
    },
  },
  actions: {
    fetchStudy({ commit }, studyId) {
      commit("SET_STUDY_ID", studyId);
      api
        .get("/studies/" + studyId)
        .then((response) => commit("SET_STUDY", response.data))
        .catch((error) => console.log(error));
    },
    fetchUserLanguage({ commit }, userLocale) {
      commit("SET_USER_LANGUAGE", userLocale);
    },
  },
  strict: debug,
});
