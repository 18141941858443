<template>
  <div>
    <div class="container">
      <tree-grid
        v-loading="isLoading"
        :data="data"
      >
      </tree-grid>
      <class-tweaks
        :granularity="body.granularity"
        :deduplication="true"
        @update-type="updateType($event)"
        @cancel-search="cancelSearch($event)"
        @update-granularity="updateGranularity($event)"
        @show-dialog="showDialog"
      >
      </class-tweaks>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h3>Classification</h3>
      </div>
      <classification-form @update-calibration="updateCalibration($event)">
      </classification-form>
    </el-dialog>
  </div>
</template>

<script>
import ClassTweaks from "@/pages/KeywordSearch/Classification/ClassTweaks";
import KeywordsGrid from "@/pages/KeywordSearch/KeywordsGrid";
import TreeGrid from "@/pages/KeywordSearch/Classification/TreeGrid";
import ClassificationForm from "@/pages/KeywordSearch/Classification/ClassificationForm";

export default {
  props: {
    body: Object,
  },
  components: {
    ClassTweaks,
    KeywordsGrid,
    TreeGrid,
    ClassificationForm,
  },
  data() {
    return {
      data: null,
      isLoading: false,
      dialogVisible: false,
    };
  },
  methods: {
    callAPI() {
      this.isLoading = true;
      this.$api
        .post("/semantic/classification", this.body)
        .then((data) => {
          this.data = data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    updateType(type) {
      this.body.classifier.type = type;
    },
    updateCalibration(calibration) {
      this.body.classifier.type = "explicit";
      this.body.classifier.calibration = calibration;
      this.dialogVisible = false;
    },
    updateGranularity(granularity) {
      this.body.classifier.granularity = granularity;
    },
    cancelSearch(value) {
      this.$emit("cancel-search", value);
    },
    showDialog() {
      this.dialogVisible = true;
    },
  },
  beforeMount() {
    this.callAPI();
  },
  watch: {
    body: {
      handler() {
        this.callAPI();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "keywordSearch": "Keywords Search",
    "keywordsToClass": "Keywords to classify"
  },
  "fr": {
    "keywordSearch": "Recherche de mots clés",
    "keywordsToClass": "Mots-clés à classer"
  },
  "de": {
    "keywordSearch": "Stichwortforschung",
    "keywordsToClass": "Keywords zum Ranking"
  }
}
</i18n>
