<template>
  <div v-if="backlinkId">
    <app-introduction
      v-if="backlinkText"
      :title="
        $t('content') +
        ' : ' +
        backlinkData.sourceDomain +
        ' - ' +
        backlinkData.keyword.text
      "
    ></app-introduction>
    <div class="container">
      <backlink-text
        v-if="backlinkText"
        :data="backlinkText"
        :links="backlinkTextLinks"
        :backlink-data="backlinkData"
        @update-text="updateText($event)"
        @update-backlink="updateBacklink($event)"
      >
      </backlink-text>
    </div>
  </div>
</template>

<script>
import BacklinkText from "@/components/CampaignManager/BacklinkText";

import { mapState } from "vuex";

export default {
  props: {
    backlinkId: String,
    netlinkingCampaignId: String,
  },
  components: {
    BacklinkText,
  },
  data() {
    return {
      backlinkText: null,
      backlinkTextLinks: null,
      backlinkData: null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    ...mapState(["study"]),
  },
  methods: {
    updateText(details) {
      this.isLoadingText = true;
      const link = details.link;
      this.$api[link.method.toLowerCase()](link.href, details.entity)
        .then((response) => {
          this.isLoadingText = false;
          this.backlinkText = response.data.data.attributes;
          this.$message({
            message: this.$i18n.t("textSaved"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.log(error);
        });
    },
    updateBacklink(details) {
      this.isLoadingText = true;
      const link = details.link;
      this.$api[link.method.toLowerCase()](link.href, details.entity)
        .then((response) => {
          this.isLoadingText = false;
          this.getBacklinkAndText();
          this.$message({
            message: this.$i18n.t("backlinkUpdated"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.log(error);
        });
    },
    getBacklinkAndText() {
      this.isLoadingText = true;
      this.$api
        .get(
          "https://" +
            process.env.API_HOST +
            "/netlinking-campaigns/" +
            this.netlinkingCampaignId +
            "/backlinks/" +
            this.backlinkId
        )
        .then((response) => {
          this.backlinkData = response.data.data;
          const link = this.backlinkData.actions.text.read;
          this.fetchBacklinkText(link);
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.error(error);
        });
    },
    fetchBacklinkText(link) {
      this.$api[link.method.toLowerCase()](link.href)
        .then((response) => {
          this.isLoadingText = false;
          this.backlinkText = response.data.data.attributes;
          this.backlinkTextLinks = response.data.links;
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.error(error);
        });
    },
  },
  mounted() {
    this.getBacklinkAndText();
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;

  .title {
    font-size: 1.5rem;
  }

  .actions {
    margin-left: auto;
    margin-right: 2rem;

    .dropdown-header {
      font-size: 1.3rem !important;
      padding-top: 0rem;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "textRedaction": "Text redaction",
    "openAsPage": "Open as page",
    "copyLink": "Public link",
    "linkCopiedToClipBoard": "Link copied to clipboard",
    "saveMessageDialog": "You haven't saved your recent changes, do you want to save them before closing ?",
    "copyPublicLink": "Copy content public link",
    "textSaved": "Text has been saved",
    "backlinkUpdated": "Backlink has been updated",
    "content": "Content",
    "backlink": "Backlink"
  },
  "fr": {
    "textRedaction": "Rédaction de contenu",
    "openAsPage": "Ouvrir dans un onglet",
    "copyLink": " Lien public",
    "linkCopiedToClipBoard": "Le lien a été copié",
    "saveMessageDialog": "Vous n'avez pas enregistré vos récentes modifications, voulez vous les enregistrer avant de fermer ?",
    "copyPublicLink": "Copier le lien public du contenu",
    "textSaved": "Texte sauvegardé",
    "backlinkUpdated": "Backlink mis à jour",
    "content": "Contenu",
    "backlink": "Backlink"
  },
  "de": {
    "textRedaction": "Inhalt schreiben",
    "openAsPage": "In einem Tab öffnen",
    "copyLink": "öffentlicher Link",
    "linkCopiedToClipBoard": "Der Link wurde kopiert",
    "saveMessageDialog": "Sie haben Ihre letzten Änderungen nicht gespeichert. Möchten Sie sie vor dem Schließen speichern?",
    "copyPublicLink": "Inhalt kopieren öffentlicher Link",
    "textSaved": "Gespeicherter Text",
    "backlinkUpdated": "Aktualisierter Backlink",
    "content": "Inhalt",
    "backlink": "Rückverlinkung"
  }
}
</i18n>
