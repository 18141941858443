<template>
  <el-card>
    <title-styled :title="title"> </title-styled>
    <el-tabs
      v-model="activeTab"
      :stretch="true"
    >
      <el-tab-pane name="opportunities">
        <div slot="label">{{ $t("short_term_opportunities") }}</div>
        <short-term-opportunity-grid> </short-term-opportunity-grid>
      </el-tab-pane>
      <el-tab-pane name="sea">
        <div slot="label">{{ $t("top_sea_keywords") }}</div>
        <top-sea-keywords-grid> </top-sea-keywords-grid>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
import TitleStyled from "@/pages/DomainSearch/Shared/title";
import ShortTermOpportunityGrid from "@/pages/DomainSearch/OpportunityAndSea/ShortTermOpportunityGrid";
import TopSeaKeywordsGrid from "@/pages/DomainSearch/OpportunityAndSea/TopSeaKeywordsGrid";

export default {
  components: {
    TitleStyled,
    TopSeaKeywordsGrid,
    ShortTermOpportunityGrid,
  },
  data() {
    return {
      activeTab: "opportunities",
      title: this.$i18n.t("opportunities_and_sea_keywords"),
    };
  },
};
</script>
<i18n>
{
  "en": {
    "short_term_opportunities": "Short term opportunities",
    "top_sea_keywords": "Best SEA keywords",
    "opportunities_and_sea_keywords": "Opportunities and SEA Keywords"
  },
  "fr": {
    "short_term_opportunities": "Opportunités court terme",
    "top_sea_keywords": "Top mots clés SEA",
    "opportunities_and_sea_keywords": "Opportunités & Mots clés SEA"
  },
  "de": {
    "short_term_opportunities": "Kurzfristige Chancen",
    "top_sea_keywords": "Top-SEA-Keywords",
    "opportunities_and_sea_keywords": "Chancen & SEA-Keywords"
  }
}
</i18n>
