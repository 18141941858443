<template>
  <div>
    <div class="doc-plan-action">
      <el-link
        href="https://docs.smartkeyword.io/fr/articles/4998332-plan-d-action-focus-netlinking"
        target="_blank"
        type="primary"
        :underline="false"
      >
        <i class="fas fa-question-circle"></i>
        <span>{{ $t("documentationDesc") }}</span>
      </el-link>
    </div>
    <domain
      v-if="data"
      :data="data"
      :keyword="keyword"
      :page="page"
      :current-user="currentUser"
    >
    </domain>
    <el-divider class="fat-divider" />
    <url
      v-if="data"
      :data="data"
      :keyword="keyword"
      :page="page"
      :current-user="currentUser"
    >
    </url>
    <el-divider class="fat-divider" />
    <div>
      <urls-metrics-grid
        v-if="data"
        :page="page"
        :data="data.urlsMetrics"
      >
      </urls-metrics-grid>
    </div>
  </div>
</template>

<script>
import Url from "@/pages/ActionPlan/Netlinking/Url";
import Domain from "@/pages/ActionPlan/Netlinking/Domain";
import UrlsMetricsGrid from "@/pages/ActionPlan/Netlinking/UrlsMetricsGrid";

export default {
  components: {
    Domain,
    Url,
    UrlsMetricsGrid,
  },
  props: {
    data: Object,
    keyword: Object,
    page: Object,
    currentUser: Object,
  },
};
</script>

<style lang="scss" scoped>
.doc-plan-action {
  margin-bottom: 1rem;
}

.fat-divider {
  height: 3px;
}

.flex-box {
  display: flex;
  flex-direction: row;
  width: 100%;

  .small-graph {
    flex-basis: 30%;
  }

  .big-graph {
    flex-basis: 70%;
  }

  .divider {
    margin: 0px 8px 0px 8px;

    .vertical {
      height: 100%;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "documentationDesc": "All about the netlinking of your pages",
    "netlinkingOfMyDomain": "Your domain netlinking",
    "netlinkingOfMyPage": "Your page netlinking"
  },
  "fr": {
    "documentationDesc": "Tout savoir sur le netlinking de vos pages",
    "netlinkingOfMyDomain": "Netlinking de mon domaine",
    "netlinkingOfMyPage": "Netlinking de ma page"
  },
  "de": {
    "documentationDesc": "Alles rund um die Vernetzung Ihrer Seiten",
    "netlinkingOfMyDomain": "Netzverlinkung meiner Domain",
    "netlinkingOfMyPage": "Netzverlinkung meiner Seite"
  }
}
</i18n>
