<template>
  <div>
    <el-row
      type="flex"
      :gutter="20"
      justify="center"
    >
      <el-col :span="6">
        <el-card class="kpi">
          <traffic-metrics
            :metrics="metrics"
            :host="getUrlHost"
            :showWarning="isUrl"
            :showWarningFolder="isFolder"
          >
          </traffic-metrics>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="kpi">
          <div class="value-container">
            <div class="flows">
              <div class="flow">
                <div
                  class="warning"
                  v-if="isNotDomain"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('warningMsg')"
                    placement="right-start"
                  >
                    <i class="el-icon-warning"> </i>
                  </el-tooltip>
                </div>
                <div>
                  <domain-authority :metrics="metrics"> </domain-authority>
                </div>
              </div>
            </div>
            <el-divider class="divider divider-horizontal"></el-divider>
            <div>
              <referring-domains
                :metrics="metrics"
                :host="getUrlHost"
                :showWarningData="checkMetrics"
                :showWarningFolder="isFolder"
              >
              </referring-domains>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="kpi">
          <flows
            :metrics="metrics"
            :host="getUrlHost"
            :showWarning="isFolder"
            :showWarningData="checkMetrics"
          >
          </flows>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="kpi">
          <categories
            :classification="classification"
            :host="getUrlHost"
            :showWarning="isFolder"
            :showWarningData="checkClassification"
          >
          </categories>
        </el-card>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      justify="center"
      style="margin-top: 2rem"
    >
      <el-col>
        <el-card>
          <graph-renderer
            :mainSeries="mainSeries"
            :showWarning="isNotDomain"
          />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TrafficMetrics from "@/pages/DomainSearch/Metrics/Traffic";
import DomainAuthority from "@/components/Metrics/DomainAuthority";
import ReferringDomains from "@/components/Metrics/ReferringDomains";
import Flows from "@/components/Metrics/Flows";
import Categories from "@/components/Metrics/Categories";
import GraphRenderer from "@/pages/DomainSearch/Metrics/GraphRenderer";

export default {
  props: {
    metrics: Object,
    classification: Object,
    mainSeries: Object,
    url: Object,
  },
  components: {
    DomainAuthority,
    ReferringDomains,
    Flows,
    Categories,
    TrafficMetrics,
    GraphRenderer,
  },
  computed: {
    checkMetrics() {
      return !(
        this.metrics.hasOwnProperty("trustFlow") &&
        this.metrics.hasOwnProperty("trustFlow")
      );
    },
    checkClassification() {
      return this.classification.majestic == null;
    },
    isFolder() {
      return this.url.type == "FOLDER";
    },
    isUrl() {
      return this.url.type == "URL";
    },
    isNotDomain() {
      return this.url.type != "DOMAIN";
    },
    getUrlHost() {
      return new URL(this.url.url).host;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "warningMsg": " This report shows the domain authority of the root domain"
  },
  "fr": {
    "warningMsg": " Ce rapport affiche le domain authority du domaine racine"
  },
  "de": {
    "warningMsg": "Dieser Bericht zeigt die Domänenautorität der Stammdomäne an"
  }
}
</i18n>

<style scoped lang="scss">
.kpi {
  height: 100%;

  ::v-deep .el-card__body {
    height: 100%;
  }
}
.warning {
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  color: #e6a23c;
}
</style>
