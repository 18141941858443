<template>
  <el-select
    v-model="item"
    filterable
    allow-create
  >
    <el-option
      v-for="item in options"
      :key="item"
      :label="$t(item.toLowerCase())"
      :value="item"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: String,
    options: Array,
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(item) {
        this.$emit("input", item);
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "bronze": "Bronze",
    "silver": "Silver",
    "gold": "Gold",
    "platinum": "Platinum",
    "diamond": "Diamond",
    "media": "Media",
    "status": "Status",
    "modify": "Article being modified",
    "draft": "Draft",
    "rocketlinks": "RocketLinks",
    "getfluence": "Getfluence",
    "nobs": "NOBS",
    "julien_nishimata": "Julien Nishimata",
    "semjuice": "Semjuice",
    "smartkeyword_marketplace": "SmartKeyword",
    "other": "Other",
    "product": "Product",
    "service": "Service",
    "informational": "Informational",
    "generalist": "Generalist",
    "news_fact": "News",
    "brand": "Brand",
    "added_value": "Added Value of the product",
    "standard": "Standard",
    "premium": "Premium",
    "content_from_scratch": "Creation of new content",
    "optimization_of_existing_text": "Optimization of existing contents",
    "netlinking_editorial_publication": "Netlinking editorial publication",
    "transposition_of_texts": "Transposition of contents",
    "blog_page": "Blog page",
    "category_page": "Category page",
    "product_page": "Product page",
    "home_page": "Home page",
    "landing_page": "Landing page",
    "backlinkStatus": "Backlink Status",
    "bulkChange": "Bulk Change",
    "deliveryDate": "Delivery date",
    "contentStatus": "Content Status",
    "validate": "Validate",
    "review": "Being proofread",
    "publish": "Publish",
    "writing": "Being written",
    "accepted": "Text validated",
    "modificating": "Being modified",
    "finished_writing": "Writing completed",
    "brief_draft": "Brief in draft",
    "brief_submitted": "Brief to validate",
    "brief_validated": "Brief validated",
    "client_review": "Text to validate",
    "client_modificating": "Change requested",
    "client_finished_writing": "Text validated",
    "to_validate": "Domain to validate",
    "canceled": "Backlink canceled",
    "refused": "Domain refused",
    "validated": "Domain validated",
    "redaction": "Article in writing",
    "article_to_validate": "Article to validate",
    "article_validated": "Article validated",
    "publication_pending": "Pending publication",
    "published": "Published"
  },
  "fr": {
    "bronze": "Bronze",
    "silver": "Argent",
    "gold": "Or",
    "platinum": "Platine",
    "diamond": "Diamant",
    "media": "Média",
    "status": "Statut",
    "redaction": "Article en rédaction",
    "published": "Publié",
    "modify": "Article en cours de modification",
    "draft": "Brouillon",
    "rocketlinks": "RocketLinks",
    "getfluence": "Getfluence",
    "nobs": "NOBS",
    "julien_nishimata": "Julien Nishimata",
    "semjuice": "Semjuice",
    "smartkeyword_marketplace": "SmartKeyword",
    "other": "Autre",
    "product": "Produit",
    "service": "Service",
    "informational": "Informationnel",
    "generalist": "Généraliste",
    "news_fact": "Fait d'actualité",
    "brand": "Marque",
    "added_value": "Valeur ajoutée du produit",
    "standard": "Standard",
    "premium": "Premium",
    "content_from_scratch": "Création de nouveaux contenus",
    "optimization_of_existing_text": "Optimisation des contenus existants",
    "netlinking_editorial_publication": "Netlinking - publirédactionnel",
    "transposition_of_texts": "Transposition des contenus",
    "blog_page": "Page de blog",
    "category_page": "Page de catégorie",
    "product_page": "Page de produit",
    "home_page": "Page d'accueil",
    "landing_page": "Page d'atterrissage",
    "backlinkStatus": "Status backlink",
    "bulkChange": "Changement Multiple",
    "deliveryDate": "Date de livraison",
    "contentStatus": "Statut du contenu",
    "validate": "Valider",
    "publish": "Publier",
    "writing": "En cours de rédaction",
    "accepted": "Texte validé",
    "review": "En cours de relecture",
    "modificating": "En cours de modification",
    "finished_writing": "Écriture terminée",
    "brief_draft": "Brief en brouillon",
    "brief_submitted": "Brief à valider",
    "brief_validated": "Brief validé",
    "client_review": "Texte à valider",
    "client_modificating": "Demande de modification",
    "client_finished_writing": "Texte validé",
    "to_validate": "Domaine à valider",
    "canceled": "Backlink annulé",
    "refused": "Domaine refusé",
    "validated": "Domaine validé",
    "article_to_validate": "Article à valider",
    "publication_pending": "Publication en attente",
    "article_validated": "Article validé"
  },
  "de": {
    "bronze": "Bronze",
    "silver": "Geld",
    "gold": "Oder",
    "platinum": "Platine",
    "diamond": "Diamant",
    "media": "Durchschnitt",
    "status": "Status",
    "redaction": "Artikel schriftlich",
    "published": "Veröffentlicht",
    "modify": "Artikel wird geändert",
    "draft": "Desorganisiert",
    "rocketlinks": "RocketLinks",
    "getfluence": "Getfluence",
    "nobs": "NOBS",
    "julien_nishimata": "Julien Nishimata",
    "semjuice": "Halbsaft",
    "smartkeyword_marketplace": "SmartKeyword",
    "other": "Andere",
    "product": "Produkt",
    "service": "Service",
    "informational": "Informativ",
    "generalist": "Generalist",
    "news_fact": "Nachrichtenfakt",
    "brand": "Marke",
    "added_value": "Produktmehrwert",
    "standard": "Standard",
    "premium": "Prämie",
    "content_from_scratch": "Erstellung neuer Inhalte",
    "optimization_of_existing_text": "Optimierung bestehender Inhalte",
    "netlinking_editorial_publication": "Netlinking - redaktionelle Werbung",
    "transposition_of_texts": "Umsetzung von Inhalten",
    "blog_page": "Blog-Seite",
    "category_page": "Kategorieseite",
    "product_page": "Produktseite",
    "home_page": "Startseite",
    "landing_page": "Zielseite",
    "backlinkStatus": "Status-Backlink",
    "bulkChange": "Mehrfache Änderung",
    "deliveryDate": "Liefertermin",
    "contentStatus": "Inhaltsstatus",
    "validate": "Bestätigen",
    "publish": "Veröffentlichen",
    "writing": "Laufende Redaktion",
    "accepted": "Validierter Text",
    "review": "Wird überprüft",
    "modificating": "In Bearbeitung",
    "finished_writing": "Schreiben abgeschlossen",
    "brief_draft": "Kurz im Entwurf",
    "brief_submitted": "Kurz zu validieren",
    "brief_validated": "Kurz validiert",
    "client_review": "Zu validierender Text",
    "client_modificating": "Änderungsanforderung",
    "client_finished_writing": "Validierter Text",
    "to_validate": "Zu validierende Domäne",
    "canceled": "Backlink abgebrochen",
    "refused": "Domäne abgelehnt",
    "validated": "Validierte Domäne",
    "article_to_validate": "Zu validierender Artikel",
    "publication_pending": "Veröffentlichung steht bevor",
    "article_validated": "Artikel validiert"
  }
}
</i18n>
