<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
export default {
  props: {
    statisticsChartData: Array,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: this.$i18n.t("users_per_month"),
        },
        xAxis: {
          categories: [
            this.$i18n.t("jan"),
            this.$i18n.t("feb"),
            this.$i18n.t("mar"),
            this.$i18n.t("apr"),
            this.$i18n.t("may"),
            this.$i18n.t("jun"),
            this.$i18n.t("jul"),
            this.$i18n.t("aug"),
            this.$i18n.t("sep"),
            this.$i18n.t("oct"),
            this.$i18n.t("nov"),
            this.$i18n.t("dec"),
          ],
        },
        series: [
          {
            name: "Inscrits",
            yAxis: 0,
            data: this.statisticsChartData,
          },
        ],
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
            dashStyle: "ShortDash",
          },
          series: {
            enableMouseTracking: false,
            shadow: false,
            animation: false,
          },
          column: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "{point.y} ",
              style: { color: "blue" },
            },
          },
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>

<i18n>
{
  "en": {
    "users_per_month": "Nb. Users by month",
    "jan": "January",
    "feb": "February",
    "mar": "March",
    "apr": "April",
    "may": "May",
    "jun": "June",
    "jul": "July",
    "aug": "August",
    "sep": "September",
    "oct": "October",
    "nov": "November",
    "dec": "December"
  },
  "fr": {
    "users_per_month": "Nombre d'utilisateurs par mois",
    "jan": "Janvier",
    "feb": "Février",
    "mar": "Mars",
    "apr": "Avril",
    "may": "Mai",
    "jun": "Juin",
    "jul": "Juillet",
    "aug": "Août",
    "sep": "Septembre",
    "oct": "Octobre",
    "nov": "Novembre",
    "dec": "Décembre"
  },
  "de": {
    "users_per_month": "Anzahl der Benutzer pro Monat",
    "jan": "Januar",
    "feb": "FEBRUAR",
    "mar": "Mars",
    "apr": "April",
    "may": "Dürfen",
    "jun": "Juni",
    "jul": "Juli",
    "aug": "August",
    "sep": "September",
    "oct": "Oktober",
    "nov": "November",
    "dec": "Dezember"
  }
}
</i18n>
