<template>
  <el-form-item :label="$t('url')">
    <el-input
      v-model="url"
      :placeholder="$t('enter_value')"
    ></el-input>
  </el-form-item>
</template>

<script>
export default {
  props: {
    value: String,
  },
  computed: {
    url: {
      get() {
        return this.value;
      },
      set(url) {
        this.$emit("input", url);
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "url": "Url",
    "value": "Value",
    "enter_value": "Enter value"
  },
  "fr": {
    "url": "Url",
    "value": "Valeur",
    "enter_value": "Entrez valeur"
  },
  "de": {
    "url": "URL",
    "value": "Wert",
    "enter_value": "Wert eingeben"
  }
}
</i18n>
