<template>
  <div>
    <el-button 
      class="icon-size"
      icon="fas fa-pen" 
      type="text" 
      @click="updateNote"
    />
    <el-button 
      class="icon-size"
      style="color: red" 
      icon="fas fa-trash" 
      type="text" 
      :loading="isDeleting"
      @click="deleteNote"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDeleting: false,
      formModel: {
        id: '',
        title: '',
        date: '',
        comment: '',
      },
    }
  },
  mounted() {
    this.formModel.id = this.params.data.id;
    this.formModel.title = this.params.data.title;
    this.formModel.date = this.params.data.date;
    this.formModel.comment = this.params.data.comment;
  },
methods: {
    updateNote() {
      this.params.context.updateNoteForm(this.formModel)
    },
    deleteNote() {
      this.isDeleting = true;
      this.params.context.deleteNote(this.formModel.id)
    },
  }
}
</script>

<style scoped>
.icon-size {
  font-size: 16px;
}
</style>
