export const searchEngineMixin = {
  methods: {
    getDefaultSearchEngine() {
      return this.study.searchEngines.find(
        (searchEngine) => searchEngine.id === this.study.defaultSearchEngineId
      );
    },
    getSearchEngineParametersFromRequest(request) {
      return {
        isoCountryCode: request.searchEngine.isoCountryCode,
        device: request.searchEngine.device,
        isoLanguageCode: request.searchEngine.isoLanguageCode,
      };
    },
  },
};
