<template>
  <span>
    <el-tooltip
      placement="top"
      transition="el-fade-in"
      :disabled="
        !params.data.assignedPage ||
        params.data.assignedPage == params.value ||
        params.data.position == 101
      "
    >
      <div slot="content">
        <div
          v-if="
            params.value &&
            params.data.position < 101 &&
            params.data.assignedPage != params.value &&
            params.data.assignedPage
          "
        >
          {{ $t("positioned_page") }}
        </div>
        <div
          v-if="
            params.value &&
            params.data.position < 101 &&
            params.data.assignedPage != params.value &&
            params.data.assignedPage
          "
        >
          {{ $t("assigned_page_is") }}
          <a
            :href="params.data.assignedPage"
            target="_blank"
            >{{ assignedPath }}</a
          >
        </div>
        <!-- <div v-else-if="params.data.assignedPage">{{ $t("assigned_page") }}</div> -->
      </div>
      <span v-if="params.value && params.data.position < 101">
        <i
          v-if="params.data.assignedPage && assignedPath != positionedPath"
          class="fas fa-circle"
        ></i>
        <span
          class="page"
          @click="showPageDialog(params.value)"
          target="_blank"
          >{{ positionedPath }}</span
        >
      </span>
      <span
        class="page"
        v-else-if="params.data.assignedPage"
        @click="showPageDialog(params.data.assignedPage)"
        target="_blank"
        >{{ assignedPath }}</span
      >
      <span v-else>{{ $t("no_positioned_page") }}</span>
    </el-tooltip>
  </span>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["study", "studyLinks"]),
    positionedPath() {
      let url = this.params.value;
      if (url) {
        if (url.charAt(url.length - 1) == "/") {
          url = url.substr(0, url.length - 1);
        }
      }
      return new URL(url).pathname;
    },
    assignedPath() {
      let url = this.params.data.assignedPage;
      if (url) {
        if (url.charAt(url.length - 1) == "/") {
          url = url.substr(0, url.length - 1);
        }
      }
      return new URL(url).pathname;
    },
  },
  methods: {
    showPageDialog(url) {
     let filter = {
        selectedUrl: url,
        selectedType :"equal",
        mode:"SEARCH_CONSOLE_ONLY",
        period:{ 
          start: this.params.context.startDate,
          end: this.params.context.endDate,
          type:"CUSTOM"
        },
        frequency:"DAY"
     };
      let encodedParams = Object.keys(filter)
      .map((key) =>{ 
        return key+"=" + JSON.stringify(filter[key])
      }).join("&");

      // eslint-disable-next-line no-undef
      let host = process.env.NODE_ENV === "development" ? "http://localhost:8080" :"" ;
      let trafficScreenUrl = host+"/spa-app/studies/"+this.study.id+"/traffic?"+encodedParams;
      window.open(trafficScreenUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.el-tooltip__popper {
  a {
    color: white;
  }
}

.page {
  cursor: pointer;
  color: $--link-color;

  &:hover {
    color: $--link-hover-color;
  }
}

.fa-circle {
  color: $--color-warning;
}
</style>

<i18n>
{
  "en": {
    "assigned_page_is": "Page manually assigned : ",
    "assigned_page": "Page manually assigned.",
    "positioned_page": "This page is positioned in Google.",
    "no_positioned_page": "No ranked page.",
    "no_assigned_page": "No assigned page."
  },
  "fr": {
    "assigned_page_is": "Page assignée manuellement : ",
    "assigned_page": "Page assignée manuellement.",
    "positioned_page": "Cette page est positionnée dans Google.",
    "no_positioned_page": "Pas de page positionnée.",
    "no_assigned_page": "Pas de page assignée."
  },
  "de": {
    "assigned_page_is": "Manuell zugewiesene Seite:",
    "assigned_page": "Manuell zugewiesene Seite.",
    "positioned_page": "Diese Seite ist in Google positioniert.",
    "no_positioned_page": "Keine Seite positioniert.",
    "no_assigned_page": "Keine Seite zugewiesen."
  }
}
</i18n>
