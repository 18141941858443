<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h3
          class="title"
          v-if="isCampaign"
        >
          {{ $t("add_campaign") }}
        </h3>
        <h3
          class="title"
          v-else
        >
          {{ $t("add_project") }}
        </h3>
      </div>
      <dynamic-form
        ref="dynamicFormComponentRef"
        v-model="newCampaign"
        :form="link.form"
      >
      </dynamic-form>
      <div class="submit">
        <el-button
          type="success"
          plain
          @click="submit(link)"
        >
          {{ $t("confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import DynamicForm from "@/components/DynamicForm";

export default {
  components: {
    DynamicForm,
  },
  props: {
    value: Boolean,
    studyId: Number,
    link: Object,
    isCampaign: Boolean,
  },
  data() {
    return {
      newCampaign: {},
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    submit() {
      this.$refs.dynamicFormComponentRef.$refs.dynamicFormRef.validate(
        (valid) => {
          if (valid) {
            let details = {};
            details.campaign = this.newCampaign;
            details.link = this.link;
            this.$emit("add-campaign", details);
            this.dialogVisible = false;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  flex-direction: row;
}

.title {
  text-align: center;
}

.submit {
  margin-top: 1rem;
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "confirm": "Confirm",
    "add_campaign": "Add a campaign",
    "add_project": "Add a project",
    "campaign_name": "Campaign name",
    "budget": "Budget",
    "notes": "Notes"
  },
  "fr": {
    "confirm": "Valider",
    "add_campaign": "Ajouter une campagne",
    "add_project": "Ajouter un projet",
    "campaign_name": "Nom de la campagne",
    "budget": "Budget",
    "notes": "Notes"
  },
  "de": {
    "confirm": "Bestätigen",
    "add_campaign": "Kampagne hinzufügen",
    "add_project": "Füge ein Projekt hinzu",
    "campaign_name": "Kampagnenname",
    "budget": "Budget",
    "notes": "Anmerkungen"
  }
}
</i18n>
