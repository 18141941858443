<template>
  <span>
    <span v-if="params.value && params.data.competitorPosition < 101">
      <a
        target="_blank"
        :href="
          (params.value.startsWith('https://') ? '' : 'https://') + params.value
        "
        >{{ positionedPath }}</a
      >
    </span>
    <span v-else>{{ $t("no_positioned_page") }}</span>
  </span>
</template>

<script>
export default {
  computed: {
    positionedPath() {
      let url = this.params.value;
      if (url) {
        if (url.charAt(url.length - 1) == "/") {
          url = url.substr(0, url.length - 1);
        }
      }
      return new URL(url).pathname;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
</style>

<i18n>
{
  "en": {
    "assigned_page_is": "Page manually assigned : ",
    "assigned_page": "Page manually assigned.",
    "positioned_page": "This page is positioned in Google.",
    "no_positioned_page": "No ranked page.",
    "no_assigned_page": "No assigned page."
  },
  "fr": {
    "assigned_page_is": "Page assignée manuellement : ",
    "assigned_page": "Page assignée manuellement.",
    "positioned_page": "Cette page est positionnée dans Google.",
    "no_positioned_page": "Pas de page positionnée.",
    "no_assigned_page": "Pas de page assignée."
  },
  "de": {
    "assigned_page_is": "Manuell zugewiesene Seite:",
    "assigned_page": "Manuell zugewiesene Seite.",
    "positioned_page": "Diese Seite ist in Google positioniert.",
    "no_positioned_page": "Keine Seite positioniert.",
    "no_assigned_page": "Keine Seite zugewiesen."
  }
}
</i18n>
