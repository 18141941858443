<template>
  <div v-loading="isLoading">
    <div class="period-picker">
      <el-form
        size="medium"
        :inline="true"
        @submit.native.prevent
      >
        <period-picker
          v-model="dates"
          frequency="DAY"
        >
        </period-picker>
      </el-form>
    </div>
    <div class="graph">
      <new-lost-backlinks-graph
        :data="dataNewLostBacklinksCount"
        :dates="dates"
      >
      </new-lost-backlinks-graph>
    </div>
    <div>
      <grid :data="dataNewLostBacklinksData"> </grid>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import Grid from "@/pages/Netlinking/Grids/NewLost/Grid";
import PeriodPicker from "@/pages/Netlinking/Grids/NewLost/PeriodPicker";
import NewLostBacklinksGraph from "@/pages/Netlinking/Grids/NewLost/NewLostBacklinks";
import { formatDateWithDashes } from "@/javascripts/formatDate";

export default {
  components: {
    Grid,
    PeriodPicker,
    NewLostBacklinksGraph,
  },
  data() {
    return {
      dates: [DateTime.utc().minus({ weeks: 1 }), DateTime.utc()],
      dataNewLostBacklinksData: null,
      dataNewLostBacklinksCount: null,
      isLoading: false,
    };
  },
  methods: {
    fetchNewLostBacklinks() {
      let requestNewLostBacklinks = {};
      requestNewLostBacklinks.url = { url: this.study.url, type: "DOMAIN" };
      requestNewLostBacklinks.startDate = formatDateWithDashes(this.dates[0]);
      requestNewLostBacklinks.endDate = formatDateWithDashes(this.dates[1]);
      requestNewLostBacklinks.limit = 1000;
      this.isLoading = true;
      this.$emit("new-backlinks", null);
      this.$emit("lost-backlinks", null);
      this.$api
        .post(`/netlinking/urls/backlinks/new-lost`, {
          ...requestNewLostBacklinks,
        })
        .then((response) => {
          this.dataNewLostBacklinksData = response.data;
          this.$emit(
            "new-backlinks",
            this.dataNewLostBacklinksData.backlinksNewLost.newBacklinks.length
          );
          this.$emit(
            "lost-backlinks",
            this.dataNewLostBacklinksData.backlinksNewLost.lostBacklinks.length
          );
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.dataNewLostBacklinksData = {};
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail_fetch_backlinks"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
    fetchNewLostBacklinksCount() {
      let requestNewLostBacklinksCount = {};
      requestNewLostBacklinksCount.url = {
        url: this.study.url,
        type: "DOMAIN",
      };
      this.$api
        .post(`/netlinking/urls/backlinks/new-lost-count`, {
          ...requestNewLostBacklinksCount,
        })
        .then((response) => {
          this.dataNewLostBacklinksCount = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail_fetch_backlinks"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
  },
  computed: {
    ...mapState(["study"]),
  },
  watch: {
    study() {
      this.fetchNewLostBacklinks();
      this.fetchNewLostBacklinksCount();
    },
    dates() {
      this.fetchNewLostBacklinks();
    },
  },
  mounted() {
    if (this.study) {
      this.fetchNewLostBacklinks();
      this.fetchNewLostBacklinksCount();
    }
  },
};
</script>

<style lang="scss" scoped>
.graph {
  margin-bottom: 1rem;
}

.period-picker {
  text-align: center;
  margin-top: 0.3rem;
}
</style>
<i18n>
{
  "en": {
    "fail_fetch_backlinks": "Backlinks : Error encountered"
  },
  "fr": {
    "fail_fetch_backlinks": "Backlinks : Nous avons rencontré une erreur"
  },
  "de": {
    "fail_fetch_backlinks": "Backlinks: Es ist ein Fehler aufgetreten"
  }
}
</i18n>
