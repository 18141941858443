<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="88%"
      :destroy-on-close="false"
    >
      <HeaderPopup
        v-loading="score.ownScore == -1 && score.serpScore == -1"
        :detailedRecommendations="detailedRecommendations"
        :pageInfo="pageInfo"
        :popupTitle="popupTitle"
        :score="score"
      />
      <div class="container-select">
        <button
          type="button"
          class="recommendation-btn"
          :class="{ clicked: !isRecommendationCgptSelected }"
          @click="selectRecommendation()"
        >
          {{ $t("buttonText", { type: $t(popupTitle) }) }}
        </button>
        <button
          type="button"
          class="recommendation-btn"
          :class="{ clicked: isRecommendationCgptSelected }"
          @click="displayChatgptPage()"
        >
          {{ $t("buttonChatgpt") }}
        </button>
      </div>
      <ConcurentPage
        v-if="!isRecommendationCgptSelected"
        :competitorsPages="competitorsPages"
        :popupTitle="popupTitle"
      />

      <div v-else>
        <div class="loadingTextStyle">
          <div v-if="isLoadingAI">
            <span class="text-center">{{ $t("dataFetchGpt") }}</span>
          </div>
          <div
            class="loadingStyle"
            v-loading="isLoadingAI"
          ></div>
        </div>
        <ChatGptPage
          :aiResponse="responseApiAi"
          :popupTitle="popupTitle"
          @textEvent="handleTextEvent"
          :chatgptError="chatgptError"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderPopup from "./headerPopup.vue";
import ConcurentPage from "./ConcurentPage.vue";
import ChatGptPage from "./ChatGptPage.vue";

import { mapState } from "vuex";

export default {
  props: {
    pageInfo: Object,
    detailedRecommendations: Object,
    competitorsPages: Array,
    score: Object,
    popupTitle: String,
    value: Boolean,
    keyword: Object,
  },
  components: {
    HeaderPopup,
    ConcurentPage,
    ChatGptPage,
  },
  data() {
    return {
      isRecommendationCgptSelected: false,
      responseApiAi: null,
      isLoadingAI: false,
      responseDeterminant: this.popupTitle + "s",
      chatgptError: null,
    };
  },
  methods: {
    selectRecommendation() {
      this.isRecommendationCgptSelected = false;
    },
    triggerAiSuggestionApiCall() {
      this.isRecommendationCgptSelected = true;
      this.$emit("startAPICall");
    },
    displayChatgptPage() {
      this.isRecommendationCgptSelected = true;
      this.callAIsuggestionSerp();
    },
    formatTitleToCallApi(title) {
      return title
        .match(/[A-Z](?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*/g)
        .join("-")
        .toUpperCase();
    },
    callAIsuggestionSerp() {
      const body = {
        data: {
          type: "AI_SUGGESTIONS_REQUEST",
          attributes: {
            keyword: {
              text: this.keyword.text,
              locationId: this.keyword.loc_id,
            },
            searchEngineParameters: {
              isoCountryCode: this.study.isoCountryCode,
              device: this.study.devices[0],
              isoLanguageCode: this.study.isoLanguageCode,
            },
            types: [this.formatTitleToCallApi(this.popupTitle)],
            size: 5,
          },
        },
      };
      if (!this.responseApiAi) {
        this.isLoadingAI = true;
        this.$api
          .post("/ai/suggestions", body)
          .then((response) => {
            this.responseApiAi =
              response.data.data.attributes[this.responseDeterminant];
            this.isLoadingAI = false;
          })
          .catch((error) => {
            this.isLoadingAI = false;
            if (
              error.code === "ECONNABORTED" &&
              error.message.includes("timeout")
            )
              this.chatgptError = this.$i18n.t("chatgptTimeout");
            else this.chatgptError = this.$i18n.t("chatgptError");
            console.error(error);
          });
      }
    },
    handleTextEvent(text, closePopUp) {
      this.$emit("textEvent", text);
      if (!closePopUp) this.dialogVisible = false;
    },
  },
  computed: {
    ...mapState(["study"]),

    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
};
</script>

<style scoped>
.container-select {
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
}

.recommendation-btn {
  margin-right: 1em;
  background-color: white;
  color: #606266;
  border-width: 0;
  height: 50px;
}

.clicked {
  color: #3c54a5;
  border-width: 0 0 2px 0;
  border-color: #3c54a5;
}

:focus {
  outline: none !important;
}
.loadingTextStyle {
  width: 100%;
  text-align: center;
}
.loadingStyle {
  height: 32px;
  width: 100%;
  text-align: center;
}
</style>

<i18n>
  {
    "en": {
      "title": "Title tag ",
      "metaDescription": "Meta-description tag ",
      "buttonText": "{type} competitors",
      "buttonChatgpt" : "AI suggestions",
      "dataFetchGpt":"Fetching data from Chatgpt, this will take a few momeents depending on Chatgpt servers.",
      "chatgptTimeout": "Due to the recent addition of the keyword, the use of chatgpt for this keyword is not yet available. Please try again in a few minutes.",
      "chatgptError": "A problem has occurred. Please try again by refreshing the page. If the error persists despite several attempts, please contact us."
    },
    "fr": {
      "title": "Balise Title ",
      "metaDescription": "Balise Meta description ",
      "buttonText": "{type} concurents",
      "buttonChatgpt" : "Suggestions IA",
      "dataFetchGpt":"Récupération des données de Chatgpt, cela prendra quelques instants en fonction des serveurs de Chatgpt.",
      "chatgptTimeout": "En raison de l'ajout récent du mot clé, l'utilisation de chatgpt pour ce mot clé n'est pas encore disponible, merci de réessayer dans quelques minutes.",
      "chatgptError": "Un problème est survenu, merci de réessayer en rafraîchissant la page. Si l'erreur subsiste malgré plusieurs essais, veuillez nous contacter."
    },
    "de": {
      "title": "Title-Tag ",
      "metaDescription": "Meta-Beschreibung tag ",
      "buttonText": "{type} Konkurrenten",
      "buttonChatgpt" : "KI-Vorschläge",
      "dataFetchGpt":"Abrufen von Daten aus Chatgpt, dies kann je nach Chatgpt-Servern einige Augenblicke dauern.",
      "chatgptTimeout": "Da das Schlüsselwort erst kürzlich hinzugefügt wurde, ist die Verwendung von chatgpt für dieses Schlüsselwort noch nicht verfügbar, bitte versuchen Sie es in ein paar Minuten erneut.",
      "chatgptError": "Es ist ein Problem aufgetreten. Bitte versuchen Sie es erneut, indem Sie die Seite aktualisieren. Wenn der Fehler trotz mehrerer Versuche bestehen bleibt, kontaktieren Sie uns bitte."
    }
  }
</i18n>
