<template>
  <div>
    <div v-if="publishedUrlDomain">
      <a
        :href="publishedUrlDomain"
        target="_blank"
        >{{ params.data.publishedUrl }}<i class="fas fa-external-link-alt"></i
      ></a>
    </div>
    <div v-else>
      <span>-</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    publishedUrlDomain() {
      if (this.params.data.publishedUrl) {
        let url = this.params.data.publishedUrl
          .replace("https://", "")
          .replace("http://", "");
        return "https://" + url;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.fas {
  margin-left: 0.5rem;
}
</style>
