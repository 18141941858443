<template>
  <el-input
    v-if="editing"
    v-model="title"
    autofocus
    @change="onChange"
    @blur="onBlur"
  />
  <h2
    v-else
    @click="onClick"
  >
    {{ title }}
  </h2>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      title: this.value,
      editing: !this.value,
    };
  },
  methods: {
    onClick() {
      this.editing = true;
    },
    onChange(value) {
      this.save(value);
    },
    onBlur() {
      if (this.title) {
        this.editing = false;
      }
    },
    save(value) {
      if (value) {
        this.editing = false;
        if (this.title !== this.value) {
          this.$emit("input", value);
        }
      }
    },
  },
  watch: {
    value(newValue) {
      this.title = newValue;
    },
  },
};
</script>

<style scoped lang="scss"></style>
