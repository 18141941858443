<template>
  <div>
    <div
      v-if="isAdmin"
      class="export"
    >
      <el-button
        :loading="isLoadingPages"
        size="mini"
        @click="exportExcel()"
      >
        <i class="fas fa-file-excel"></i> {{ $t("export") }}
      </el-button>
    </div>
    <ag-grid-vue
      style="height: 650px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      :suppressCellSelection="true"
      @gridReady="onGridReady"
      @gridSizeChanged="adjustGrid"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import WebsiteRenderer from "@/components/gridRenderers/WebsiteRenderer";

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
    WebsiteRenderer,
  },
  props: {
    dataTopPages: Array,
    metrics: Object,
    request: Object,
    isAdmin: Boolean,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
      isLoadingPages: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      if (this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("url"),
          field: "url",
          filter: "agTextColumnFilter",
          minWidth: 500,
          cellRendererFramework: "WebsiteRenderer",
        },
        {
          headerName: this.$i18n.t("trafic"),
          field: "trafic",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
        },
        {
          headerName: this.$i18n.t("%Trafic"),
          field: "percentageTrafic",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: ratioFormatter,
        },
      ];
    },
    mapData() {
      if (!this.dataTopPages) {
        this.rowData = [];
      } else {
        if (this.dataTopPages.length > 0) {
          this.rowData = this.dataTopPages.map((page) => {
            return {
              url: page.url.url,
              trafic: page.traffic,
              percentageTrafic: page.trafficRate,
            };
          });
        } else {
          this.rowData = [];
        }
      }
    },
    exportExcel() {
      const requestPages = {
        url: {
          url: this.request.url.url,
          type:
            this.request.url.type === "URL" ? "DOMAIN" : this.request.url.type,
        },
        isoCountryCode: this.request.isoCountryCode,
        limit: 10000,
      };
      this.isLoadingPages = true;
      let final = null;
      this.$api
        .post(`/netlinking/urls/netlinking-top-pages`, requestPages)
        .then((response) => {
          final = response.data.data;
          this.constructExcel(final);
          this.isLoadingPages = false;
        })
        .catch((error) => {
          this.isLoadingPages = false;
          console.log(error);
        });
    },
    constructExcel(data) {
      var XLSX = require("xlsx");
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Pages Export - SmartKeyword",
        Subject: "Pages",
        Author: "Smartkeyword",
      };
      wb.SheetNames.push("Pages");
      var ws_data = [["Url", "Trafic", "% du trafic"]];
      if (data.length > 0) {
        data.map((page) => {
          ws_data.push([page.url.url, page.traffic, page.trafficRate]);
        });
      }
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      ws["!cols"] = this.fitToColumn(ws_data);
      wb.Sheets["Pages"] = ws;
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        "Page Export - SmartKeyword.xlsx"
      );
    },
    fitToColumn(arrayOfArray) {
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(...arrayOfArray.map((a2) => a2[i].toString().length)),
      }));
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
  },
  beforeMount() {
    this.setColumnDefs();
  },
  mounted() {
    this.mapData();
  },
  watch: {
    dataTopPages() {
      this.mapData();
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);

let ratioFormatter = (params) => {
  return params.value + "%";
};
</script>

<style scoped lang="scss">
.export {
  text-align: right;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "url": "Url",
    "trafic": "Traffic",
    "%Traffic": "% Traffic",
    "export": "Export"
  },
  "fr": {
    "url": "Url",
    "trafic": "Trafic",
    "%Trafic": "% Trafic",
    "export": "Exporter"
  },
  "de": {
    "url": "URL",
    "trafic": "Verkehr",
    "%Trafic": "% Verkehr",
    "export": "Exporteur"
  }
}
</i18n>
