<template>
  <div v-loading="isLoading">
    <el-button
      :loading="exportingBacklinks"
      v-if="currentUser.is_admin"
      @click="exportBacklinks()"
      type="primary"
    >
      {{ $t("export_backlinks") }}
    </el-button>
    <grid :data="dataTopBacklinks"> </grid>
  </div>
</template>

<script>
import Grid from "@/pages/Netlinking/Grids/TopBacklinks/Grid";
import { mapState } from "vuex";
import axios from "axios";

export default {
  components: {
    Grid,
  },
  props: {
    currentUser: Object,
  },
  data() {
    return {
      isLoading: false,
      dataTopBacklinks: null,
      exportingBacklinks: false,
    };
  },
  computed: {
    ...mapState(["study"]),
  },
  methods: {
    fetchTopBacklinksData() {
      let attributes = {};
      attributes.urls = [{ url: this.study.url, type: "DOMAIN" }];
      attributes.scope = "ONE_PER_DOMAIN";
      attributes.limit = 50;

      let requestBacklinksData = {};
      requestBacklinksData.attributes = attributes;
      requestBacklinksData.type = "URL_BACKLINK_REQUEST";

      let requestBacklinks = {};
      requestBacklinks.data = requestBacklinksData;

      this.isLoading = true;
      this.$api
        .post(
          `/netlinking/urls/backlinks`,
          { ...requestBacklinks },
          { headers: { "Content-Type": "application/vnd.api+json" } }
        )
        .then((response) => {
          this.dataTopBacklinks = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.dataTopBacklinks = {};
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail_fetch_top_backlinks"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
    exportBacklinks() {
      let attributes = {};
      attributes.urls = [{ url: this.study.url, type: "DOMAIN" }];
      attributes.scope = "ONE_PER_DOMAIN";
      attributes.limit = 1000;

      let requestBacklinksData = {};
      requestBacklinksData.attributes = attributes;
      requestBacklinksData.type = "URL_BACKLINK_REQUEST";

      let requestExportBacklinks = {};
      requestExportBacklinks.data = requestBacklinksData;

      this.$api
        .post(
          `/netlinking/urls/backlinks`,
          { ...requestExportBacklinks },
          {
            headers: {
              Accept: "application/vnd.ms-excel",
              "Content-Type": "application/vnd.api+json",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "backlinks_" + this.study.url + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.exportingBacklinks = false;
        })
        .catch((error) => {
          this.exportingBacklinks = false;
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail_export"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
  },
  mounted() {
    this.fetchTopBacklinksData();
  },
};
</script>

<i18n>
{
  "en": {
    "fail_fetch_top_backlinks": "Top backlinks : Error encountered",
    "export_backlinks": "Export backlinks",
    "fail_export": "We have encountered a problem"
  },
  "fr": {
    "fail_fetch_top_backlinks": "Top Backlinks : Nous avons rencontré une erreur",
    "export_backlinks": "Export backlinks",
    "fail_export": "Nous avons rencontré un problème"
  },
  "de": {
    "fail_fetch_top_backlinks": "Top-Backlinks: Wir haben einen Fehler festgestellt",
    "export_backlinks": "Backlinks exportieren",
    "fail_export": "Wir sind auf ein Problem gestoßen"
  }
}
</i18n>
