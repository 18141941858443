<template>
  <div>
    <span v-if="params.data.isHost">--</span>
    <el-button
      v-else-if="!params.data.competitorGroup"
      size="small"
      @click="setDialogVisible"
      >{{ $t("add_group") }}</el-button
    >
    <el-button
      v-else
      size="small"
      @click="setDialogVisible"
      >{{
        params.value.charAt(0).toUpperCase() + params.value.slice(1)
      }}</el-button
    >
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    setDialogVisible() {
      this.params.context.setDialogVisible(
        this.params.data.domain,
        this.params.data.competitorGroup
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
</style>
<i18n>
{
  "en": {
    "add_group": "Add to a group"
  },
  "fr": {
    "add_group": "Ajouter à un groupe"
  },
  "de": {
    "add_group": "Zur Gruppe hinzufügen"
  }
}
</i18n>
