import { Mark, getMarkRange } from "@tiptap/core";
import highlightThreadPlugin from "./ThreadSelectionPlugin.js";

export default Mark.create({
  name: "commentMark",

  /**
   * rules to put the comment as inline and to not ruin the editor content
   */
  group: "basic",
  spanning: false,
  priority: 10000,

  /**
   * Renders attributes inside the mark exemple for commentId
   * <span data-comment-id="id" data-color="color" style="background-color:color;"></span>
   */
  addAttributes() {
    return {
      commentId: {
        default: null,
        parseHTML: (el) => el.getAttribute("data-comment-id"),
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }
          return { "data-comment-id": attributes.commentId };
        },
      },
      color: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute("data-color") || element.style.backgroundColor,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }
          return {
            "data-color": attributes.color,
            style: `background-color: ${attributes.color};`,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      /*
      insert a thread inside the editor by adding the mark commentMark
      */
      insertThread:
        (attributes) =>
        ({ editor, state, dispatch }) => {
          let selection = state.selection;
          dispatch(
            state.tr.setMeta("thread", false).addMark(
              selection.from,
              selection.to,
              state.schema.marks.commentMark.create({
                color: attributes.color,
                commentId: attributes.commentId,
              })
            )
          );
        },
      /*
       * Removes a thread from the editor
       * by removing all occurences of commentMark with id passed in attributes
       */
      deleteThread:
        (attributes) =>
        ({ chain, state, dispatch }) => {
          const { schema, doc } = state;
          doc.descendants((node, pos) => {
            node.marks.forEach((mark) => {
              if (mark.type.name == "commentMark") {
                let range = getMarkRange(
                  doc.resolve(pos),
                  schema.marks.commentMark
                );
                if (attributes.commentID == mark.attrs.commentId)
                  dispatch(
                    state.tr
                      .setMeta("thread", false)
                      .removeMark(
                        range.from,
                        range.to,
                        schema.marks.commentMark
                      )
                  );
              }
            });
          });
          return chain().focus().run();
        },
      /*
       *enables and disables the threads based on enable attribute's value
       */
      enableThreads:
        (attributes) =>
        ({ state, dispatch, editor }) => {
          const updateThreadColor = (
            range,
            commentId,
            color,
            state,
            dispatch
          ) => {
            const { schema } = state;
            dispatch(
              state.tr
                .setMeta("thread", true)
                .removeMark(range.from, range.to, schema.marks.commentMark)
            );
            dispatch(
              state.tr.setMeta("thread", true).addMark(
                range.from,
                range.to,
                schema.marks.commentMark.create({
                  color: color,
                  commentId: commentId,
                })
              )
            );
          };
          const activateThreads = (state, dispatch, activate) => {
            const { schema, doc } = state;
            doc.descendants((node, pos) => {
              node.marks.forEach((mark) => {
                if (mark.type.name == "highlightSelection") {
                  let range = getMarkRange(
                    doc.resolve(pos),
                    schema.marks.highlightSelection
                  );
                  dispatch(
                    state.tr
                      .setMeta("thread", true)
                      .removeMark(
                        range.from,
                        range.to,
                        schema.marks.highlightSelection
                      )
                  );
                }
                if (mark.type.name == "commentMark") {
                  let commentId = mark.attrs.commentId;
                  let range = getMarkRange(
                    doc.resolve(pos),
                    schema.marks.commentMark
                  );
                  updateThreadColor(
                    range,
                    commentId,
                    activate ? "yellow" : "transparent",
                    state,
                    dispatch
                  );
                }
              });
            });
          };
          editor.storage.commentMark.enable = attributes.enable;
          activateThreads(state, dispatch, attributes.enable);
        },
    };
  },

  /**
   * follow a rule to detect tags based on name, attributes(classes..etc)
   *  inside the editor html and parse them for example
   *  in our case we're telling parseHTML to look for <span data-comment-id></span>
   */
  parseHTML() {
    return [
      {
        tag: "span[data-comment-id]",
      },
    ];
  },
  /**
   * Used to render the mark, the output would be <span class="comment" id="a number"></span>
   */
  renderHTML({ HTMLAttributes }) {
    return ["span", HTMLAttributes];
  },

  /**uses it to know when to disable threads */
  addStorage() {
    return {
      enable: false,
    };
  },

  /* For more control over the editor,
   * addProseMirrorPlugins gives the option
   * to add plugins to proseMirror
   */
  addProseMirrorPlugins() {
    const extensionThis = this;
    return [highlightThreadPlugin(extensionThis)];
  },
});
