<template>
  <div>
    <div
      class="warning"
      v-if="showWarning"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('warningMsg')"
        placement="right-start"
      >
        <i class="el-icon-warning"> </i>
      </el-tooltip>
    </div>
    <highcharts
      ref="highchart"
      :options="chartOptions"
      :updateArgs="updateArgs"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    frequency: String,
    mainSeries: Object,
    showWarning: Boolean,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          zoomType: "x",
        },
        title: {
          text: this.$i18n.t("trafficKeywords"),
        },
        yAxis: [
          {
            title: {
              text: this.$i18n.t("organicTrafic"),
            },
            min: 0,
          },
          {
            title: {
              text: this.$i18n.t("keywords"),
            },
            min: 0,
            opposite: true,
            stackLabels: {
              enabled: false,
              style: {
                fontWeight: "bold",
              },
            },
          },
        ],
        xAxis: [
          {
            type: "datetime",
          },
        ],
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "{point.x:%d-%m-%Y}: <b>{point.y}</b>",
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        credits: {
          enabled: false,
        },
        series: this.series,
      };
    },
    mainSeriesChart() {
      if (this.mainSeries) {
        let series = [];

        //Computing keywords serie
        if (this.mainSeries["keywords"]) {
          let dataPointsFormatted1to3 = [];
          let dataPointsFormatted4to10 = [];
          let dataPointsFormatted11to20 = [];
          let dataPointsFormattedRemaining = [];
          let dataPointsFormattedTotal = [];

          this.mainSeries["keywords"].forEach(function (
            organicKeywordsData,
            index
          ) {
            let date = new Date(organicKeywordsData.date);
            let keywords1to3 = organicKeywordsData.buckets["1-3"];
            let keywords4to10 = organicKeywordsData.buckets["4-10"];
            let keywords11to20 = organicKeywordsData.buckets["11-20"];
            let totalKeywords = organicKeywordsData.total;
            dataPointsFormatted1to3.push({ x: date, y: keywords1to3 });
            dataPointsFormatted4to10.push({ x: date, y: keywords4to10 });
            dataPointsFormatted11to20.push({ x: date, y: keywords11to20 });
            dataPointsFormattedRemaining.push({
              x: date,
              y: totalKeywords - keywords11to20 - keywords4to10 - keywords1to3,
              total: totalKeywords,
            });
            dataPointsFormattedTotal.push({ x: date, y: totalKeywords });
          });

          series.push({
            type: "column",
            name: this.$i18n.t("bucketedKeywords21+"),
            data: dataPointsFormattedRemaining,
            color: Highcharts.Color(Highcharts.getOptions().colors[5])
              .brighten(0.3)
              .get(),
            yAxis: 1,
            legendIndex: 3,
          });
          series.push({
            type: "column",
            name: this.$i18n.t("bucketedKeywords11to20"),
            data: dataPointsFormatted11to20,
            color: Highcharts.Color(Highcharts.getOptions().colors[5])
              .brighten(0.2)
              .get(),
            yAxis: 1,
            legendIndex: 2,
          });
          series.push({
            type: "column",
            name: this.$i18n.t("bucketedKeywords4to10"),
            data: dataPointsFormatted4to10,
            color: Highcharts.Color(Highcharts.getOptions().colors[5])
              .brighten(0.1)
              .get(),
            yAxis: 1,
            legendIndex: 1,
          });
          series.push({
            type: "column",
            name: this.$i18n.t("bucketedKeywords1to3"),
            data: dataPointsFormatted1to3,
            color: Highcharts.Color(Highcharts.getOptions().colors[5]).get(),
            yAxis: 1,
            legendIndex: 0,
          });

          series.push({
            type: "spline",
            name: this.$i18n.t("keywords"),
            data: dataPointsFormattedTotal,
            color: Highcharts.Color(Highcharts.getOptions().colors[5]).get(),
            yAxis: 1,
            legendIndex: 4,
          });
        }

        //Computing traffic serie
        if (this.mainSeries["traffic"]) {
          let dataPointsFormatted = this.mainSeries["traffic"].map(
            (dataPoint) => {
              return {
                x: new Date(dataPoint.date),
                y: dataPoint.value,
              };
            }
          );
          series.push({
            type: "spline",
            name: this.$i18n.t("organicTrafic"),
            data: dataPointsFormatted,
            color: Highcharts.Color(Highcharts.getOptions().colors[0])
              .brighten(-0.2)
              .get(),
            yAxis: 0,
            legendIndex: 5,
          });
        }

        return series;
      } else {
        return [];
      }
    },
    series() {
      return this.mainSeriesChart;
    },
  },
};
</script>

<style scoped lang="scss">
.warning {
  display: flex;
  justify-content: flex-end;
  color: #e6a23c;
  font-size: 1.5rem;
}
</style>

<i18n>
{
  "en": {
    "trafficKeywords": "Organic Traffic & Keywords",
    "keywords": "Nb of organic keywords",
    "bucketedKeywords1to3": "Keyword nb. 1-3",
    "bucketedKeywords4to10": "Keyword nb. 4-10",
    "bucketedKeywords11to20": "Keyword nb. 11-20",
    "bucketedKeywords21+": "Keyword nb. 21+",
    "organicTrafic": "Organic Traffic",
    "warningMsg": "this graph shows the history of the organic traffic and the count of rganic keywords of the root domain"
  },
  "fr": {
    "trafficKeywords": "Trafic & mots-clés organiques",
    "keywords": "Nb Mots-clés organiques",
    "bucketedKeywords1to3": "Nb Mots-clés 1-3",
    "bucketedKeywords4to10": "Nb Mots-clés 4-10",
    "bucketedKeywords11to20": "Nb Mots-clés 11-20",
    "bucketedKeywords21+": "Nb Mots-clés 21+",
    "organicTrafic": "Trafic organique",
    "warningMsg": "Ce graph affiche l'historique du trafic organique et le nombre de mots-clés organiques du domaine racine"
  },
  "de": {
    "trafficKeywords": "Traffic und organische Keywords",
    "keywords": "Nb Organische Keywords",
    "bucketedKeywords1to3": "Anzahl der Schlüsselwörter 1-3",
    "bucketedKeywords4to10": "Anzahl der Schlüsselwörter 4-10",
    "bucketedKeywords11to20": "Anzahl der Schlüsselwörter 11-20",
    "bucketedKeywords21+": "Anzahl der Keywords 21+",
    "organicTrafic": "Organischer Verkehr",
    "warningMsg": "Dieses Diagramm zeigt den organischen Traffic-Verlauf und die Anzahl der organischen Keywords der Root-Domain"
  }
}
</i18n>
