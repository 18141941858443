<template>
  <div>
    <div class="actions">
      <actions
        :gridApi="gridApi"
        :columnApi="columnApi"
        :tags="tags"
        :account="account"
        :canImportKeywords="canImportKeywords"
        @importKeywords="importKeywords($event)"
      >
      </actions>
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :gridOptions="gridOptions"
      :rowData="rowData"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :suppressContextMenu="true"
      :enableRangeSelection="true"
      :suppressCopyRowsToClipboard="true"
      rowSelection="multiple"
      :suppressAggFuncInHeader="true"
      :rowMultiSelectWithClick="true"
      :groupMultiAutoColumn="false"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { keywordsSearchImportKeywordsMixin } from "@/mixins/keywordsSearchImportKeywordsMixin";
import ScoreRenderer from "@/pages/KeywordsSearch/Shared/ScoreRenderer";
import KeywordImportedRenderer from "@/pages/KeywordsSearch/Shared/KeywordImportedRenderer";
import PresentInDatabaseHeader from "@/pages/KeywordsSearch/Volume/PresentInDatabaseHeader";
import Actions from "@/pages/KeywordsSearch/Shared/Actions";

export default {
  mixins: [keywordsSearchImportKeywordsMixin],
  props: {
    data: Array,
    account: Object,
    tags: Array,
    canImportKeywords: Boolean,
  },
  components: {
    AgGridVue,
    ScoreRenderer,
    PresentInDatabaseHeader,
    KeywordImportedRenderer,
    Actions,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: { defaultColDef: { sortable: true, resizable: true } },
      gridApi: null,
      columnApi: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setData();
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keywords"),
          field: "keyword",
          width: 200,
          minWidth: 150,
          filter: "agTextColumnFilter",
          cellRendererFramework: "KeywordImportedRenderer",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          sortable: true
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          width: 135,
          aggFunc: "max",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueGetter: (params) => roundNumber(params.data["volume"]),
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRenderer: spaceThousandRenderer,
          suppressSizeToFit: true,
          enableValue: true,
          sortable: true
        },
        {
          headerName: this.$i18n.t("bid"),
          field: "bid",
          valueGetter: (params) => roundNumber(params.data["bid"]),
          width: 135,
          type: "numericColumn",
          aggFunc: "avg",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRenderer: spaceThousandRenderer,
          suppressSizeToFit: true,
          enableValue: true,
          sortable: true
        },
        {
          headerName: this.$i18n.t("opportunity_score"),
          field: "competition",
          width: 185,
          type: "numericColumn",
          aggFunc: "avg",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "ScoreRenderer",
          suppressSizeToFit: true,
          enableValue: true,
          sortable: true
        },
        {
          headerName: this.$i18n.t("actions"),
          field: "actions",
          width: 100,
          aggFunc: "first",
          cellStyle: { textAlign: "center" },
          cellRenderer: function (params) {
            return (
              '<a title="Lancer recherche dans Google" class="google-link" href="https://www.google.com/search?q=' +
              params.value +
              '" target="_blank"><i class="fab fa-google"></i></a>' +
              '<a title="Lancer recherche dans Google Trends" class="google-link" href="https://trends.google.fr/trends/explore?date=today%205-y&geo=FR&q=' +
              params.value +
              '" target="_blank"><i class="fas fa-chart-line"></i></a>'
            );
          },
          sorting: false,
          suppressMenu: true,
          filter: false,
        },
      ];
    },
    setData() {
      if (!this.data) {
        this.rowData = [];
        return;
      }

      this.rowData = this.data.map((keyword) => {
        return {
          keyword: keyword.keyword.text,
          volume: keyword.volume,
          competition: Math.round(keyword.opportunityScore * 100),
          bid: keyword.cpc,
          actions: keyword.keyword.text,
          isImportable: this.importableKeywordsText.includes(
            keyword.keyword.text
          ),
        };
      });
    },
  },
  watch: {
    data() {
      this.setData();
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.gridOptions = {};
  },
};

let positionFormatter = (params) => {
  if (params.value < 101) {
    return params.value;
  } else {
    return "> 100";
  }
};

var spaceThousandRenderer = function (params) {
  var roundedNumber = roundNumber(params.value);
  return roundedNumber.toLocaleString("fr");
};

let roundNumber = (number) => Math.round(number * 100) / 100;
</script>

<style scoped lang="scss">
.actions {
  text-align: right;
}

::v-deep .google-link {
  margin-left: 10px;
}

::v-deep .ag-header-cell-label {
  justify-content: center;
}

::v-deep .ag-header-cell {
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "excel_export": "Export Excel",
    "keywords_import": "Import selected keywords",
    "selection": "Selection",
    "keywords": "Keywords",
    "position_mine": "My position",
    "volume": "Volume",
    "domain_name": "Domain Name",
    "bid": "Bid",
    "opportunity_score": "Opportunity score",
    "actions": "Actions",
    "add_keywords_success": "Keywords were added successfully.",
    "add_keywords_fail": "An error occurred.",
    "no_keywords_selected": "No keywords were selected ! Select keywords first in order to add them to your keywords.",
    "select_tags": "Select tags",
    "import": "Import"
  },
  "fr": {
    "excel_export": "Export excel",
    "keywords_import": "Importer les mots clés sélectionnés",
    "selection": "Selection",
    "keywords": "Mots Clés",
    "position_mine": "Ma position",
    "volume": "Volume",
    "domain_name": "Nom de domaine",
    "bid": "Enchère",
    "opportunity_score": "Score d'opportunité",
    "actions": "Actions",
    "add_keywords_success": "Les mots clés ont été importés avec succès.",
    "add_keywords_fail": "Une erreur est survenue.",
    "no_keywords_selected": "Aucun mot clé sélectionné ! Sélectionnez d'abord des mots clés pour pouvoir les ajouter à vos mots clés.",
    "select_tags": "Seléctionnez les groupes de mots clés",
    "import": "Importer"
  },
  "de": {
    "excel_export": "Excel exportieren",
    "keywords_import": "Importieren Sie ausgewählte Schlüsselwörter",
    "selection": "Auswahl",
    "keywords": "Schlüsselwörter",
    "position_mine": "Meine Position",
    "volume": "Volumen",
    "domain_name": "Domänenname",
    "bid": "Gebot",
    "opportunity_score": "Opportunity-Score",
    "actions": "Aktionen",
    "add_keywords_success": "Die Keywords wurden erfolgreich importiert.",
    "add_keywords_fail": "Ein Fehler ist aufgetreten.",
    "no_keywords_selected": "Kein Schlüsselwort ausgewählt! Wählen Sie zuerst Schlüsselwörter aus, damit Sie sie zu Ihren Schlüsselwörtern hinzufügen können.",
    "select_tags": "Wählen Sie Keyword-Gruppen aus",
    "import": "Importeur"
  }
}
</i18n>
