<template>
  <el-card>
    <span class="category-title"> {{ $t("competitorsMetaDescs") }}</span>
    <div class="serp-metaDescs">
      <div
        v-for="metaDesc in metaDescsShown"
        v-bind:key="metaDesc"
      >
        <el-tag v-if="metaDesc">{{ metaDesc }}</el-tag>
      </div>
    </div>
    <div class="flex-box">
      <el-link
        style="margin-left: auto; font-size: 0.85rem"
        type="primary"
        @click="showAllMetaDescs = !showAllMetaDescs"
      >
        {{ showAllMetaDescs ? $t("showLess") : $t("showMore") }}
      </el-link>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    serpMetaDescs: Array,
  },
  data() {
    return {
      showAllMetaDescs: false,
    };
  },
  computed: {
    metaDescsShown() {
      if (this.showAllMetaDescs) return this.serpMetaDescs;
      return this.serpMetaDescs.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-title {
  font-weight: bold;
}

.serp-metaDescs {
  margin-top: 0.5rem;

  .el-tag {
    word-break: break-word;
    white-space: normal;
    height: auto;
    margin-bottom: 0.2rem;
  }
}

.flex-box {
  display: flex;
}
</style>
<i18n>
{
    "en": {
        "competitorsMetaDescs": "Competitors meta descriptions",
        "showLess": "- Show less",
        "showMore": "+ Show more"
    },
    "fr": {
        "competitorsMetaDescs": "Meta descriptions concurrentes",
        "showLess": "- Montrer moins",
        "showMore": "+ Montrer plus"
    },
    "it": {
        "competitorsMetaDescs": "Meta descrizioni dei concorrenti",
        "showLess": "- Mostra meno",
        "showMore": "+ Mostra di più"
    },
    "es": {
        "competitorsMetaDescs": "Metadescripciones de los competidores",
        "showLess": "- Mostrar menos",
        "showMore": "+ Mostrar más"
    },
    "de": {
        "competitorsMetaDescs": "Meta-Beschreibungen der Wettbewerber",
        "showLess": "- Weniger anzeigen",
        "showMore": "+ Mehr anzeigen"
    },
    "nl": {
        "concurrentenMetaDescs": "Metabeschrijvingen van concurrenten",
        "showLess": "- Minder tonen",
        "showMore": "+ Toon meer"
    }
}
</i18n>
