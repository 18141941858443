<template>
  <div
    v-if="metrics"
    class="metrics"
  >
    <el-row
      type="flex"
      :gutter="20"
      justify="center"
      v-bind:class="{ 'no-print': !dimensionsBrandConfigured }"
    >
      <el-col :span="6">
        <el-card>
          <reporting-metric
            :metrics="metrics"
            main-text="traffic.seo_traffic"
            main-field="sessions"
            total-text="traffic.ratio_total_traffic"
            total-field="total_sessions"
            comparative-text="vs_previous_period"
            comparative-field="comparative_sessions"
          >
          </reporting-metric>
        </el-card>
      </el-col>
      <el-col
        :span="6"
        v-bind:class="{ 'no-print': !dimensionsBrandConfigured }"
      >
        <el-card>
          <reporting-metric
            v-if="'brand_sessions' in metrics"
            :metrics="metrics"
            main-text="traffic.brand_seo_traffic"
            main-field="brand_sessions"
            total-text="traffic.ratio_seo_traffic"
            total-field="sessions"
            comparative-text="vs_previous_period"
            comparative-field="brand_comparative_sessions"
          >
          </reporting-metric>
          <reporting-metric
            v-else-if="dimensionsBrandConfigured"
            :metrics="metrics"
            main-text="traffic.brand_seo_traffic"
          >
            <p class="main-value">-</p>
          </reporting-metric>
          <reporting-metric
            v-else-if="!dimensionsBrandConfigured"
            :metrics="metrics"
            main-text="traffic.non_brand_seo_traffic"
          >
            <p class="contact-to-customize">{{ $t("contact_to_customize") }}</p>
          </reporting-metric>
        </el-card>
      </el-col>
      <el-col
        :span="6"
        v-bind:class="{ 'no-print': !dimensionsBrandConfigured }"
      >
        <el-card>
          <reporting-metric
            v-if="'brand_sessions' in metrics"
            :metrics="metrics"
            main-text="traffic.non_brand_seo_traffic"
            main-field="non_brand_sessions"
            total-text="traffic.ratio_seo_traffic"
            total-field="sessions"
            comparative-text="vs_previous_period"
            comparative-field="non_brand_comparative_sessions"
          >
          </reporting-metric>
          <reporting-metric
            v-else-if="dimensionsBrandConfigured"
            :metrics="metrics"
            main-text="traffic.non_brand_seo_traffic"
          >
            <p class="main-value">-</p>
          </reporting-metric>
          <reporting-metric
            v-else-if="!dimensionsBrandConfigured"
            :metrics="metrics"
            main-text="traffic.non_brand_seo_traffic"
          >
            <p class="contact-to-customize">{{ $t("contact_to_customize") }}</p>
          </reporting-metric>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <reporting-metric
            :metrics="metrics"
            main-text="traffic.total_traffic_over_period"
            main-field="total_sessions"
            comparative-text="vs_previous_period"
            comparative-field="total_comparative_sessions"
          >
          </reporting-metric>
        </el-card>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      :gutter="20"
      class="hidden-when-no-print"
      v-if="!dimensionsBrandConfigured"
    >
      <el-col :span="12">
        <el-card>
          <reporting-metric
            :metrics="metrics"
            main-text="traffic.seo_traffic"
            main-field="sessions"
            total-text="traffic.ratio_total_traffic"
            total-field="total_sessions"
            comparative-text="vs_previous_period"
            comparative-field="comparative_sessions"
          >
          </reporting-metric>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <reporting-metric
            :metrics="metrics"
            main-text="traffic.total_traffic_over_period"
            main-field="total_sessions"
            comparative-text="vs_previous_period"
            comparative-field="total_comparative_sessions"
          >
          </reporting-metric>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ReportingMetric from "@/components/ReportingMetric";

export default {
  props: {
    metrics: Object,
    dimensionsData: Array,
  },
  components: {
    ReportingMetric,
  },
  computed: {
    dimensionsBrandConfigured() {
      if (this.dimensionsData[2].configured) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.metrics {
  .el-card {
    margin-bottom: 1rem;
  }

  .el-col {
    display: flex;
    flex-direction: column;
  }

  .el-card {
    display: flex;
    flex-grow: 1;

    ::v-deep .el-card__body {
      width: 100%;
    }

    .contact-to-customize {
      color: #6c757d;
      font-size: 0.8rem;
    }
  }

  .main-value {
    margin-bottom: 0.5rem;
    font-size: 2rem;
    font-weight: 500;
  }

  .hidden-when-no-print {
    display: none;
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  .hidden-when-no-print {
    display: flex !important;
  }
}
</style>

<i18n>
{
  "en": {
    "contact_to_customize": "If you want to group by Brand/Non Brand or any other criteria, contact us !"
  },
  "fr": {
    "contact_to_customize": "Si vous souhaitez grouper votre traffic par Marque/Hors-marque ou un autre critère, contactez-nous !"
  },
  "de": {
    "contact_to_customize": "Wenn Sie Ihren Traffic nach Marke/Nicht-Marke oder einem anderen Kriterium gruppieren möchten, kontaktieren Sie uns!"
  }
}
</i18n>
