<template>
  <div>
    <div class="dropdown-header">
      <i class="fas fa-space-shuttle"></i>
      Admin
    </div>

    <a
      v-for="item of menuItems"
      class="dropdown-item"
      :href="item.link"
      :underline="false"
    >
      {{ item.label }}
      <el-badge
        v-if="item.badgeCount"
        :value="item.badgeCount"
      ></el-badge>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    menuItems: Array,
  },
};
</script>

<style scoped></style>
