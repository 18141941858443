<template>
  <div>
    <div class="actions">
      <div class="text-right">
        <el-button
          plain
          type="primary"
          @click="dialogCreateCompanyVisible = true"
          >{{ $t("create_company") }}</el-button
        >
      </div>
      <el-dialog
        :title="$t('create_company')"
        :visible.sync="dialogCreateCompanyVisible"
      >
        <company-form
          @submit="createCompany($event)"
          @close-dialog="dialogCreateCompanyVisible = false"
        ></company-form>
      </el-dialog>
      <el-dialog
        :title="$t('edit_company')"
        :visible.sync="dialogEditCompanyVisible"
      >
        <company-form
          :companyId="editCompanyId"
          @submit="updateCompany($event)"
          @close-dialog="dialogEditCompanyVisible = false"
        ></company-form>
      </el-dialog>
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :rowData="rowData"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :resizable="true"
      :sorting="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      :suppressCellSelection="true"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
      :context="context"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompaniesRepository = RepositoryFactory.get("companies");
const UsersRepository = RepositoryFactory.get("users");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import CompanyForm from "@/pages/AdminOverview/Forms/CompanyForm";

const StatusComponent = Vue.extend({
  template: `
		<el-tag
			:type="params.value === 'subscribed' ? 'success' : params.value === 'suspended' ? 'warning' : 'danger'"
			disable-transitions>
			{{ $t(params.value) }}
		</el-tag>
	`,
  data: function () {
    return {
      companyId: null,
    };
  },
  mounted() {
    this.companyId = this.params.data.id;
  },
  methods: {
    invokeEditCompany() {
      this.params.context.companies.editCompany(this.companyId);
    },
    invokeDeleteCompany() {
      this.params.context.companies.deleteCompany(this.companyId);
    },
  },
  i18n: {
    messages: {
      en: {
        subscribed: "Subscribed",
        suspended: "Suspended",
        inactive: "Inactive",
      },
      fr: {
        subscribed: "Souscrit",
        suspended: "Suspendu",
        inactive: "Inactif",
      },
    },
  },
});

const ActionsComponent = Vue.extend({
  template: `
		<div>
			<el-button size="mini" plain icon="fas fa-edit" @click="invokeEditCompany"></el-button>
			<el-button size="mini" type="danger" plain icon="fas fa-trash-alt" @click="invokeDeleteCompany"></el-button>
		</div>
	`,
  data: function () {
    return {
      companyId: null,
    };
  },
  mounted() {
    this.companyId = this.params.data.id;
  },
  methods: {
    invokeEditCompany() {
      this.params.context.companies.editCompany(this.companyId);
    },
    invokeDeleteCompany() {
      this.params.context.companies.deleteCompany(this.companyId);
    },
  },
});

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
    CompanyForm,
  },
  data() {
    return {
      defaultColDef: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      dialogCreateCompanyVisible: false,
      editCompanyId: null,
      dialogEditCompanyVisible: false,
      accountManagers: [],
    };
  },
  computed: {
    columnDefs() {
      return ((accountManagers) => [
        {
          headerName: "ID",
          field: "id",
          minWidth: 120,
          maxWidth: 120,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterValueGetter: (params) =>
            params.data ? parseInt(params.data.id) : null,
          filterParams: {
            defaultOption: "equals",
          },
        },
        {
          headerName: this.$i18n.t("name"),
          field: "name",
          minWidth: 150,
          filter: "agTextColumnFilter",
          cellRenderer: this.linkRenderer,
        },
        {
          headerName: this.$i18n.t("studies"),
          field: "studiesCount",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_users"),
          field: "usersAllowedCount",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_keywords"),
          field: "keywordsLimit",
          maxWidth: 125,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("crawler_max_urls"),
          field: "maxUrlsToCrawl",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
			    headerClass: "headerClass alignStart",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_competitors"),
          field: "competitorsLimit",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: "Account Managers",
          field: "accountManagers",
          valueGetter: this.accountManagerGetter(accountManagers),
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("status"),
          field: "status",
          minWidth: 100,
          maxWidth: 100,
          cellStyle:{
            "overflow":"unset"
          },
          cellRendererFramework: StatusComponent,
        },
        {
          headerName: this.$i18n.t("actions"),
          minWidth: 130,
          maxWidth: 130,
          cellRendererFramework: ActionsComponent,
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sortable: false,
        },
      ])(this.accountManagers);
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.defaultColDef = {
        menuTabs: ["filterMenuTab"],
				sortable: true,
				resizable: true,
				autoHeight: true,
				headerComponentParams: {
					template:
						'<div class="ag-cell-label-container" role="presentation">' +
						'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
						'  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
						'    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
						'    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
						'    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
						'    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
						'    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="width:100%; ' +
						'white-space: normal;"></span>' +
						'    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
						"  </div>" +
						"</div>"
				}
      };
    },
    setAccountManagers() {
      UsersRepository.getAccountManagers()
        .then((data) => {
          this.accountManagers = data.data.map((user) => {
            return {
              id: parseInt(user.id),
              firstName: user.attributes.firstName,
              lastName: user.attributes.lastName,
            };
          });
          this.fetchData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    accountManagerGetter(accountManagers) {
      return (accountManagerIds) => {
        return accountManagerIds.data.accountManagers
          .map((accountManagerId) =>
            accountManagers.find(
              (accountManager) => accountManager.id === accountManagerId
            )
          )
          .filter((accountManager) => accountManager != null)
          .map(
            (accountManager) =>
              accountManager.firstName + " " + accountManager.lastName
          )
          .join(";");
      };
    },
    fetchData() {
      CompaniesRepository.getCompanies()
        .then((data) => {
          this.rowData = data.data.map((company) => {
            const companySettingId = company.relationships.companySetting.data
              ? company.relationships.companySetting.data.id
              : null;
            let result = { id: company.id, ...company.attributes };
            if (companySettingId) {
              const companySettingAttributes = data.included.find(
                (companySetting) => companySetting.id === companySettingId
              ).attributes;
              result = { ...result, ...companySettingAttributes };
            }
            return result;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createCompany(form) {
      CompaniesRepository.createCompany(form)
        .then((data) => {
          const companySettingId =
            data.data.relationships.companySetting.data.id;
          const companySettingAttributes = data.included.find(
            (companySetting) => companySetting.id === companySettingId
          ).attributes;
          this.rowData.unshift({
            id: data.data.id,
            ...data.data.attributes,
            ...companySettingAttributes,
          });
          this.$message.success(this.$i18n.t("create_success"));
        })
        .catch((error) => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
            type: "error",
          });
        });
    },
    editCompany(companyId) {
      this.editCompanyId = parseInt(companyId);
      this.dialogEditCompanyVisible = true;
    },
    updateCompany(form) {
      CompaniesRepository.updateCompany(this.editCompanyId, form)
        .then((data) => {
          const currentIndex = this.rowData.findIndex(
            (row) => row.id == this.editCompanyId
          );
          const companySettingId =
            data.data.relationships.companySetting.data.id;
          const companySettingAttributes = data.included.find(
            (companySetting) => companySetting.id === companySettingId
          ).attributes;
          this.rowData.splice(currentIndex, 1, {
            id: data.data.id,
            ...data.data.attributes,
            ...companySettingAttributes,
          });
          this.$message.success(this.$i18n.t("update_success"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteCompany(companyId) {
      if (confirm(this.$i18n.t("delete_confirmation"))) {
        CompaniesRepository.deleteCompany(companyId)
          .then((data) => {
            this.rowData = this.rowData.filter((row) => row.id !== companyId);
            this.$message.success(this.$i18n.t("delete_success"));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="/c/${params.data.id}">${params.value}</a>`;
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.setAccountManagers();
    this.context = { companies: this };
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.text-center {
  text-align: center;
}
.headerClass{
  font-size: 8px;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "crawler_max_urls": "Max urls to crawl",
    "create_company": "Create company",
    "create_success": "Company was successfully created.",
    "edit_company": "Edit company",
    "update_success": "Company was successfully updated.",
    "delete_confirmation": "Are you sure you want to delete this company ?",
    "delete_success": "Company was successfully deleted.",
    "company_group": "Company group",
    "select_company_group": "Select company group",
    "name": "Name",
    "studies": "Studies",
    "max_users": "Max users",
    "max_keywords": "Max keywords",
    "max_competitors": "Max competitors",
    "status": "Status",
    "select_status": "Select status",
    "subscribed": "Subscribed",
    "suspended": "Suspended",
    "inactive": "Inactive",
    "actions": "Actions"
  },
  "fr": {
    "crawler_max_urls": "Max URLs à crawler",
    "create_company": "Créer une société",
    "create_success": "La société a été créé avec succès.",
    "edit_company": "Editer la société",
    "update_success": "La société a été mis à jour avec succès.",
    "delete_confirmation": "Etes-vous sûr de vouloir supprimer cette société ?",
    "delete_success": "La société a été supprimée avec succès.",
    "company_group": "Groupe de sociétés",
    "select_company_group": "Sélectionnez le groupe de sociétés",
    "name": "Nom",
    "studies": "Etudes",
    "max_users": "Max utilisateurs",
    "max_keywords": "Max mots clés",
    "max_competitors": "Max concurrents",
    "status": "Statut",
    "select_status": "Sélectionnez le statut",
    "subscribed": "Souscrit",
    "suspended": "Suspendu",
    "inactive": "Inactif",
    "actions": "Actions"
  },
  "de": {
    "crawler_max_urls":"Maximale Anzahl von URLs zum Crawlen",
    "create_company": "Erstellen Sie ein Unternehmen",
    "create_success": "Das Unternehmen wurde erfolgreich gegründet.",
    "edit_company": "Unternehmen bearbeiten",
    "update_success": "Das Unternehmen wurde erfolgreich aktualisiert.",
    "delete_confirmation": "Möchten Sie dieses Unternehmen wirklich löschen?",
    "delete_success": "Das Unternehmen wurde erfolgreich gelöscht.",
    "company_group": "Gruppe von Firmen",
    "select_company_group": "Wählen Sie die Unternehmensgruppe aus",
    "name": "Name",
    "studies": "Studien",
    "max_users": "Maximale Benutzer",
    "max_keywords": "Maximale Schlüsselwörter",
    "max_competitors": "Maximale Konkurrenten",
    "status": "Status",
    "select_status": "Status auswählen",
    "subscribed": "Gezeichnet",
    "suspended": "Ausgesetzt",
    "inactive": "Leerlauf",
    "actions": "Aktionen"
  }
}
</i18n>