<template>
  <el-card shadow="never">
    <el-row>
      <el-col :span="20">
        <div class="inputs-sections">
          <el-form
            size="mini"
            @submit.native.prevent
            label-position="top"
          >
            <keywords-bulk
              @input="
                (newKeywords) => {
                  keywords = newKeywords;
                }
              "
            ></keywords-bulk>
          </el-form>
          <el-form
            :inline="true"
            size="mini"
            @submit.native.prevent
          >
            <el-form-item :label="$t('country')">
              <select-search-engines
                v-if="defaultCountryIsoCode"
                v-model="isoCountryCode"
                :countries="countries"
              >
              </select-search-engines>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="action">
          <el-button
            type="primary"
            @click="submit"
            >{{ $t("search") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import SelectSearchEngines from "@/components/formItems/SelectSearchEngines";
import KeywordsBulk from "@/pages/KeywordsSearch/Shared/FormItems/KeywordsBulk";

export default {
  components: {
    KeywordsBulk,
    SelectSearchEngines,
  },
  props: {
    defaultCountryIsoCode: String,
    countries: Array,
  },
  data() {
    return {
      keywords: [],
      isoCountryCode: this.defaultCountryIsoCode,
    };
  },
  methods: {
    submit() {
      const request = {
        keywords: this.keywords,
        isoCountryCode: this.isoCountryCode,
      };
      this.$emit("submit", request);
    },
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}

.action {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "search": "Search",
    "country": "Country"
  },
  "fr": {
    "search": "Rechercher",
    "country": "Pays"
  },
  "de": {
    "search": "Forschen",
    "country": "Zahlt"
  }
}
</i18n>
