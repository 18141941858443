import { mapState } from "vuex";

export const listAndImportKeywordsMixin = {
  data() {
    return {
      trackedKeywords: null,
    };
  },
  methods: {
    getStudyKeywords(rawGridData) {
      this.$api[this.listTrackedKeywords.method.toLowerCase()](
        this.listTrackedKeywords.href
      )
        .then((response) => {
          this.trackedKeywords = response.data.data;
          this.rowData = rawGridData.map((keyword) => {
            keyword.isImportable = !this.trackedKeywords
              .map((keyword) => keyword.text)
              .includes(keyword.keyword);
            return keyword;
          });
          this.isLoading = false;
        })
        .catch((error) => console.error(error));
    },
    importKeywords(data) {
      let requestData = data.keywords.map((keyword) => {
        let keywordObj = {};
        keywordObj.text = keyword;
        keywordObj.locationId = this.defaultSearchEngineLocationId;
        keywordObj.tags = data.tags;
        return keywordObj;
      });
      this.$api[this.createOrReplaceKeywords.method.toLowerCase()](
        this.createOrReplaceKeywords.href,
        requestData
      )
        .then((_) => {
          this.mapData();
          this.$message.success(this.$i18n.t("add_keywords_success"));
        })
        .catch((error) => {
          console.error(error);
          this.$message.error(this.$i18n.t("add_keywords_fail"));
        });
    },
  },
  computed: {
    ...mapState(["studyLinks", "study"]),
    defaultSearchEngineLocationId() {
      return this.study["searchEngines"].find(
        (engine) => engine.id == this.study["defaultSearchEngineId"]
      ).defaultLocationId;
    },
    listTrackedKeywords() {
      return this.studyLinks["trackedKeywords"].list;
    },
    createOrReplaceKeywords() {
      return this.studyLinks["trackedKeywords"].createOrReplace;
    },
    accountId() {
      return this.study["id"];
    },
  },
  created() {
    this.isRowSelectable = (params) => params.data.isImportable;
  },
};
