<template>
  <div>
    <div class="actions">
      <div class="text-right">
        <el-button
          plain
          type="primary"
          @click="dialogCreateCompanyGroupVisible = true"
          >{{ $t("create_company_group") }}</el-button
        >
      </div>
      <el-dialog
        :title="$t('create_company_group')"
        :visible.sync="dialogCreateCompanyGroupVisible"
      >
        <company-group-form
          @submit="createCompanyGroup($event)"
          @close-dialog="dialogCreateCompanyGroupVisible = false"
        ></company-group-form>
      </el-dialog>
      <el-dialog
        :title="$t('edit_company_group')"
        :visible.sync="dialogEditCompanyGroupVisible"
      >
        <company-group-form
          :companyGroupId="editCompanyGroupId"
          @submit="updateCompanyGroup($event)"
          @close-dialog="dialogEditCompanyGroupVisible = false"
        ></company-group-form>
      </el-dialog>
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :rowData="rowData"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      :suppressCellSelection="true"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
      :context="context"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompanyGroupsRepository = RepositoryFactory.get("companyGroups");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import CompanyGroupForm from "@/pages/AdminOverview/Forms/CompanyGroupForm";

const ActionsComponent = Vue.extend({
  template: `
		<div>
			<el-button size="mini" plain icon="fas fa-edit" @click="invokeEditCompanyGroup"></el-button>
			<el-button size="mini" type="danger" plain icon="fas fa-trash-alt" @click="invokeDeleteCompanyGroup"></el-button>
		</div>
	`,
  data: function () {
    return {
      companyGroupId: null,
    };
  },
  mounted() {
    this.companyGroupId = this.params.data.id;
  },
  methods: {
    invokeEditCompanyGroup() {
      this.params.context.companyGroups.editCompanyGroup(this.companyGroupId);
    },
    invokeDeleteCompanyGroup() {
      this.params.context.companyGroups.deleteCompanyGroup(this.companyGroupId);
    },
  },
});

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
    CompanyGroupForm,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      dialogCreateCompanyGroupVisible: false,
      editCompanyGroupId: null,
      dialogEditCompanyGroupVisible: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.defaultColDef = {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      };
      this.columnDefs = [
        {
          headerName: "ID",
          field: "id",
          minWidth: 100,
          maxWidth: 120,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterValueGetter: (params) =>
            params.data ? parseInt(params.data.id) : null,
          filterParams: {
            defaultOption: "equals",
          },
        },
        {
          headerName: this.$i18n.t("name"),
          field: "name",
          minWidth: 200,
          filter: "agTextColumnFilter",
          cellRenderer: this.linkRenderer,
        },
        {
          headerName: this.$i18n.t("max_users"),
          field: "usersLimit",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("studies"),
          field: "studiesCount",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_studies"),
          field: "studiesLimit",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_keywords"),
          field: "keywordsLimit",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("max_competitors"),
          field: "competitorsLimit",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("actions"),
          minWidth: 130,
          maxWidth: 130,
          cellRendererFramework: ActionsComponent,
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sortable: false,
        },
      ];
    },
    fetchData() {
      CompanyGroupsRepository.getCompanyGroups()
        .then((data) => {
          this.rowData = data.data.map((companyGroup) => ({
            id: companyGroup.id,
            ...companyGroup.attributes,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createCompanyGroup(form) {
      CompanyGroupsRepository.createCompanyGroup(form)
        .then((response) => {
          this.rowData.unshift({
            id: response.data.data.id,
            ...response.data.data.attributes,
          });
          this.$message.success(this.$i18n.t("create_success"));
        })
        .catch((error) => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
            type: "error",
          });
        });
    },
    editCompanyGroup(companyGroupId) {
      this.editCompanyGroupId = parseInt(companyGroupId);
      this.dialogEditCompanyGroupVisible = true;
    },
    updateCompanyGroup(form) {
      CompanyGroupsRepository.updateCompanyGroup(this.editCompanyGroupId, form)
        .then((response) => {
          const currentIndex = this.rowData.findIndex(
            (row) => row.id == this.editCompanyGroupId
          );
          this.rowData.splice(currentIndex, 1, {
            id: response.data.data.id,
            ...response.data.data.attributes,
          });
          this.$message.success(this.$i18n.t("update_success"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteCompanyGroup(companyGroupId) {
      if (confirm(this.$i18n.t("delete_confirmation"))) {
        CompanyGroupsRepository.deleteCompanyGroup(companyGroupId)
          .then((data) => {
            this.rowData = this.rowData.filter(
              (row) => row.id !== companyGroupId
            );
            this.$message.success(this.$i18n.t("delete_success"));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="/company_groups/${params.data.id}">${params.value}</a>`;
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.fetchData();
    this.context = { companyGroups: this };
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.text-center {
  text-align: center;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "create_company_group": "Create company group",
    "create_success": "Company group was successfully created.",
    "name": "name",
    "max_users": "Max users",
    "studies": "Studies",
    "max_studies": "Max studies",
    "max_keywords": "Max keywords",
    "max_competitors": "Max competitors",
    "actions": "Actions",
    "edit": "Edit",
    "edit_company_group": "Edit company group",
    "update_success": "Company group was successfully updated.",
    "delete": "Delete",
    "delete_confirmation": "Are you sure you want to delete this company group ?",
    "delete_success": "Company group was successfully deleted."
  },
  "fr": {
    "create_company_group": "Créer un groupe de sociétés",
    "create_success": "Le groupe de sociétés a été créé avec succès.",
    "name": "Nom",
    "max_users": "Max utilisateurs",
    "studies": "Etudes",
    "max_studies": "Max études",
    "max_keywords": "Max mots clés",
    "max_competitors": "Max concurrents",
    "actions": "Actions",
    "edit": "Editer",
    "edit_company_group": "Editer le groupe de sociétés",
    "update_success": "Le groupe de sociétés a été mis à jour avec succès.",
    "delete": "Supprimer",
    "delete_confirmation": "Etes-vous sûr de vous vouloir supprimer ce groupe de sociétés ?",
    "delete_success": "Le groupe de sociétés a été supprimé avec succès."
  },
  "de": {
    "create_company_group": "Erstellen Sie eine Unternehmensgruppe",
    "create_success": "Die Unternehmensgruppe hat sich erfolgreich etabliert.",
    "name": "Name",
    "max_users": "Maximale Benutzer",
    "studies": "Studien",
    "max_studies": "Max studiert",
    "max_keywords": "Maximale Schlüsselwörter",
    "max_competitors": "Maximale Konkurrenten",
    "actions": "Aktionen",
    "edit": "Bearbeiten",
    "edit_company_group": "Firmengruppe bearbeiten",
    "update_success": "Die Unternehmensgruppe wurde erfolgreich aktualisiert.",
    "delete": "LÖSCHEN",
    "delete_confirmation": "Möchten Sie diese Unternehmensgruppe wirklich löschen?",
    "delete_success": "Die Firmengruppe wurde erfolgreich gelöscht."
  }
}
</i18n>
