<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      width="75%"
      top="5vh"
    >
      <div
        slot="title"
        class="flex-box"
        v-loading="isLoadingBrief"
      >
        <h2 class="title">
          <span style="font-weight: bold">{{ $t("briefRedaction") }}</span> -
          {{ content.data.keyword.text }}
        </h2>
        <div class="actions">
          <el-button
            type="small"
            @click="openInNewTab()"
            ><i class="fas fa-external-link-alt"></i>
            {{ $t("openAsPage") }}</el-button
          >
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('copyPublicLink')"
            placement="top-start"
          >
            <span>
              <el-button
                type="small"
                @click="copyToClipboard()"
                ><i class="fas fa-link"></i> {{ $t("copyLink") }}</el-button
              >
            </span>
          </el-tooltip>
        </div>
      </div>
      <brief
        v-if="dialogVisible && brief"
        :is-loading-brief="isLoadingBrief"
        :brief="brief"
        :content="content"
        :is-stand-alone="false"
        :is-brief-generated="isBriefGenerated"
        @update-brief="updateBrief($event)"
        @update-content="$emit('update-content', $event)"
      >
      </brief>
    </el-dialog>
  </span>
</template>

<script>
import Brief from "@/components/CampaignManager/Brief";
import { mapState } from "vuex";

export default {
  props: {
    value: Boolean,
    content: Object,
  },
  components: {
    Brief,
  },
  data() {
    return {
      brief: null,
      isLoadingBrief: false,
      isBriefGenerated: false,
    };
  },
  computed: {
    ...mapState(["userLocale", "study"]),
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    updateBrief(details) {
      this.isLoadingBrief = true;
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.entity
      )
        .then((response) => {
          this.$emit("refresh-content", this.content);
          this.brief = response.data;
          this.isBriefGenerated = false;
          this.isLoadingBrief = false;
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("errorMessage"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
          this.isLoadingBrief = false;
        });
    },
    openInNewTab() {
      window.open(
        window.location.origin +
          "/a/" +
          this.study.id +
          "/contents/" +
          this.content.data.id +
          "/brief"
      );
    },
    copyToClipboard() {
      const el = document.createElement("textarea");
      el.value =
        window.location.origin + this.content.links.public.href + "/brief";
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message({
        message: this.$i18n.t("linkCopiedToClipBoard"),
        type: "success",
        duration: 2000,
        offset: 80,
      });
    },
  },
  mounted() {
    if (this.content.links.brief) {
      this.isLoadingBrief = true;
      let link;
      if (this.content.links.brief.read) {
        link = this.content.links.brief.read;
        this.$api[link.method.toLowerCase()](link.href, {
          headers: { "Accept-Language": this.userLocale },
        })
          .then((response) => {
            this.isLoadingBrief = false;
            this.brief = response.data;
          })
          .catch((error) => {
            this.$message({
              message: this.$i18n.t("errorMessage"),
              type: "error",
              duration: 6000,
            });
            console.log(error);
            this.isLoadingBrief = false;
          });
      } else if (this.content.links.brief.generate) {
        this.isBriefGenerated = true;
        link = this.content.links.brief.generate;
        this.$message({
          message: this.$i18n.t("briefGeneration"),
          type: "info",
          duration: 10000,
          offset: 80,
        });
        this.$api[link.method.toLowerCase()](link.href, {
          headers: { "Accept-Language": this.userLocale },
          timeout: 180000,
        })
          .then((response) => {
            this.isLoadingBrief = false;
            this.brief = response.data;
          })
          .catch((error) => {
            this.$message({
              message: this.$i18n.t("errorMessage"),
              type: "error",
              duration: 6000,
            });
            console.log(error);
            this.isLoadingBrief = false;
          });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;

  .title {
    font-size: 1.5rem;
  }

  .actions {
    margin-left: auto;
    margin-right: 2rem;

    .dropdown-header {
      font-size: 1.3rem !important;
      padding-top: 0rem;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "briefRedaction": "Brief redaction",
    "openAsPage": "Open as page",
    "copyLink": "Public link",
    "linkCopiedToClipBoard": "Link copied to clipboard",
    "errorMessage": "We encountered an error",
    "briefGeneration": "Brief generation... It may take up to a few minutes, you can leave this page without interrupting the generation.",
    "copyPublicLink": "Copy brief public link"
  },
  "fr": {
    "briefRedaction": "Rédaction du brief",
    "openAsPage": "Ouvrir dans un onglet",
    "copyLink": "Lien public",
    "linkCopiedToClipBoard": "Le lien a été copié",
    "errorMessage": "Nous avons rencontré une erreur",
    "briefGeneration": "Génération du brief... Cela peut prendre jusqu'à quelques minutes, vous pouvez quitter cette page sans que ça interrompt la génération.",
    "copyPublicLink": "Copier le lien public du brief"
  },
  "de": {
    "briefRedaction": "Erstellung des Briefings",
    "openAsPage": "In einem Tab öffnen",
    "copyLink": "öffentlicher Link",
    "linkCopiedToClipBoard": "Der Link wurde kopiert",
    "errorMessage": "Es ist ein Fehler aufgetreten",
    "briefGeneration": "Briefing erstellen... Dies kann einige Minuten dauern, Sie können diese Seite verlassen, ohne dass die Generierung unterbrochen wird.",
    "copyPublicLink": "Kopieren Sie den öffentlichen Kurzlink"
  }
}
</i18n>
