<template>
  <div>
    <app-introduction :title="$t('textRedaction')"></app-introduction>
    <div class="container">
      <el-card>
        <h2
          class="title"
          v-if="backlink"
        >
          {{
            backlink.data.sourceDomain ? backlink.data.sourceDomain + " - " : ""
          }}
          {{ backlink.data.keyword.text }}
        </h2>
        <backlink-text
          v-if="backlinkText"
          :data="backlinkText"
          :links="backlinkTextLinks"
          :backlink-data="backlink"
          :is-public="true"
          @update-text="updateText($event)"
          @update-backlink="updateBacklink($event)"
        >
        </backlink-text>
      </el-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import AppIntroduction from "@/components/AppIntroduction";
import BacklinkText from "@/components/CampaignManager/BacklinkText";

export default {
  components: {
    AppIntroduction,
    BacklinkText,
  },
  data() {
    return {
      backlink: null,
      backlinkText: null,
      backlinkTextLinks: null,
      isLoadingText: false,
      translatedLanguages: ["fr", "en", "it", "de", "nl", "es"],
    };
  },
  methods: {
    fetchBacklink() {
      const backlinkId = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      );
      if (backlinkId) {
        axios
          .get(
            "https://" +
              process.env.API_HOST +
              "/backlinks/public/" +
              backlinkId
          )
          .then((response) => {
            this.backlink = response.data;
            this.fetchBacklinkText(response.data.links.text.read);
          })
          .catch((error) => console.error(error));
      }
    },
    fetchBacklinkText(link) {
      axios[link.method.toLowerCase()](
        "https://" + process.env.API_HOST + link.href
      )
        .then((response) => {
          this.isLoadingText = false;
          this.backlinkText = response.data.data;
          this.backlinkTextLinks = response.data.links;
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.error(error);
        });
    },
    updateText(details) {
      this.isLoadingText = true;
      axios[details.link.method.toLowerCase()](
        "https://" + process.env.API_HOST + details.link.href,
        details.entity
      )
        .then((response) => {
          this.backlinkText = response.data.data;
          this.backlinkTextLinks = response.data.links;
          if (details.link.method.toLowerCase() == "put") {
            this.$message({
              message: this.$i18n.t("textSaved"),
              type: "success",
              duration: 6000,
            });
          }
          this.isLoadingText = false;
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.error(error);
        });
    },
    updateBacklink(details) {
      this.isLoadingText = true;
      axios[details.link.method.toLowerCase()](
        "https://" + process.env.API_HOST + details.link.href,
        details.entity
      )
        .then((response) => {
          this.backlink = response.data;
          this.fetchBacklinkText(response.data.links.text.read);
          this.isLoadingText = false;
          this.$message({
            message: this.$i18n.t("backlinkUpdated"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoadingText = false;
        });
    },
  },
  beforeMount() {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang) {
      if (
        this.translatedLanguages.includes(
          userLang.substring(0, 2).toLowerCase()
        )
      ) {
        this.$root.$i18n.locale = userLang;
      } else {
        this.$root.$i18n.locale = "en";
      }
    } else {
      this.$root.$i18n.locale = "en";
    }
  },
  mounted() {
    this.fetchBacklink();
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
</style>

<i18n>
{
    "en": {
        "textRedaction": "Text redaction",
        "contentText": "Content text",
        "textSaved": "Text has been saved",
        "backlinkUpdated":"Backlink updated",
        "openBrief": "See the brief"
    },
    "fr": {
        "textRedaction": "Rédaction de contenu",
        "contentText": "Texte du contenu",
        "textSaved": "Texte sauvegardé",
        "backlinkUpdated":"Contenu mis à jour",
        "openBrief": "Ouvrir le brief"
    },
    "it": {
        "textRedaction": "Scrittura di contenuti",
        "contentText": "Testo contenuto",
        "textSaved": "Testo salvato",
        "backlinkUpdated":"Backlink aggiornato",
        "openBrief": "Vedi il riassunto"
    },
    "de": {
        "textRedaction": "Textschwärzung",
        "contentText": "Inhaltstext",
        "textSaved": "Text wurde gespeichert",
        "backlinkUpdated":"Backlinks aktualisiert",
        "openBrief": "Siehe die kurzbeschreibung"
    },
    "nl": {
        "textRedaction": "Tekstredactie",
        "contentText": "Inhoud tekst",
        "textSaved": "Tekst is opgeslagen",
        "backlinkUpdated":"Backlink bijgewerkt",
        "openBrief": "Zie de brief"
    },
    "es": {
        "textRedaction": "Redacción de texto",
        "contentText": "Texto de contenido",
        "textSaved": "El texto ha sido guardado",
        "backlinkUpdated":"Backlink actualizado",
        "openBrief": "Ver el resumen"
    }
}
</i18n>
