<template>
  <div>
    <el-form-item
      :label="$t('tagsFilter')"
      v-if="tagOptions"
    >
      <div
        v-for="(tagGroup, index) in tagsGroups"
        v-bind:key="tagsGroups.indexOf(tagGroup)"
      >
        <div v-if="index == tagsGroups.length - 1 || tagGroup.length > 0">
          <tag-group
            :tags-options="tagOptions"
            :value="tagGroup"
          >
          </tag-group>
          <div class="divider-container">
            <el-divider
              class="divider"
              v-if="index < tagsGroups.length - 1"
              >{{ $t("and") }}</el-divider
            >
          </div>
        </div>
      </div>
      <el-button
        primary
        v-if="tagsGroups[tagsGroups.length - 1].length > 0"
        @click="addTagGroup"
      >
        {{ $t("addAGroup") }}
      </el-button>
    </el-form-item>
  </div>
</template>

<script>
import TagGroup from "@/components/formItems/TagGroup";
export default {
  components: {
    TagGroup,
  },
  props: {
    value: Array,
    tags: Array,
  },
  data() {
    return {};
  },
  computed: {
    tagsGroups: {
      get() {
        return this.value;
      },
      set(tagsGroups) {
        this.$emit("input", tagsGroups.slice());
      },
    },
    tagOptions() {
      if (this.tags) {
        return this.tags.map(function (tag) {
          return { value: tag.value, label: tag.label };
        });
      }
    },
  },
  methods: {
    addTagGroup() {
      this.value.push([]);
    },
  },
};
</script>
<style lang="scss" scoped>
.divider-container {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;

  .divider {
    margin: 0;
  }
}
</style>

<i18n>
{
  "en": {
    "tagsFilter": "Tags filter",
    "and": "And",
    "addAGroup": "Add a tag group"
  },
  "fr": {
    "tagsFilter": "Filtre de tag",
    "and": "Et",
    "addAGroup": "Ajouter un groupe de tag"
  },
  "de": {
    "tagsFilter": "Filtre de tag",
    "and": "Und",
    "addAGroup": "Fügen Sie eine Tag-Gruppe hinzu"
  }
}
</i18n>
