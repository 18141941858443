<template>
  <div>
    <highcharts
      ref="highchart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 300,
        },
        title: {
          text: this.$i18n.t("my_referring_domains_vs_serp"),
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    mapData() {
      if (this.data) {
        this.chartOptions.series = [];
        this.chartOptions.xAxis.categories = [];

        this.chartOptions.xAxis.categories.push(
          this.$i18n.t("#_referring_domains_median")
        );
        this.chartOptions.xAxis.categories.push(this.$i18n.t("analyzed_url"));

        this.chartOptions.series.push({
          name: this.$i18n.t("referring_domains"),
          data: [
            Math.round(this.data.serpMedianReferringDomains),
            this.data.urlValues.metrics.referringDomainsWithEquity,
          ],
        });
      } else {
        this.chartOptions.series = [];
        this.chartOptions.xAxis.categories = [];
      }
    },
  },
  watch: {
    data() {
      this.mapData();
    },
  },
  mounted() {
    if (this.data) {
      this.mapData();
    }
  },
};
</script>

<i18n>
{
  "en": {
    "#_referring_domains_median": "# Median referring domains",
    "analyzed_url": "Your page",
    "referring_domains": "Referring Domains",
    "my_referring_domains_vs_serp": "Your referring domains vs the SERP",
    "serp": "SERP"
  },
  "fr": {
    "#_referring_domains_median": "# Domaines référents median",
    "analyzed_url": "Votre page",
    "referring_domains": "Domaines référents",
    "my_referring_domains_vs_serp": "Mes domaines référents vs la SERP",
    "serp": "SERP"
  },
  "de": {
    "#_referring_domains_median": "# Mittlere verweisende Domains",
    "analyzed_url": "Deine Seite",
    "referring_domains": "Verweisende Domains",
    "my_referring_domains_vs_serp": "Meine verweisenden Domains im Vergleich zum SERP",
    "serp": "SERP"
  }
}
</i18n>
