<template>
    <li class="nav-item">
      <a
        v-if="matomoConfigurationExists"
        :href="matomoLink"
        class="nav-link"
      >
        Matomo
      </a>
    </li>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    data() {
      return {
        matomoLink: null,
        googleGscAnchor: "#googleGSC",
      };
    },
    computed: {
      ...mapState(["study", "studyLinks"]),
      matomoConfigurationExists() {
        return this.studyLinks["matomo"];
      },
    },
    mounted() {
      this.matomoLink = `/spa-app/studies/${this.study["id"]}/configuration?tab=matomo`;
    },
  };
  </script>
  