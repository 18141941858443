<template>
  <div>
    <el-form-item :label="$t('category_filter')">
      <el-tag
        v-for="classAdwordFilter in value"
        :key="classAdwordFilter"
        size="small"
        closable
        @close="removeClassAdwordFilter(classAdwordFilter)"
      >
        {{ getNodeLabel(classAdwordFilter).data.label }}
      </el-tag>
      <el-popover
        placement="bottom-start"
        v-model="showPopup"
        trigger="click"
        width="400"
        popper-class="keyword-filter-input-popover"
        ref="popover"
      >
        <el-form
          :inline="true"
          label-position="top"
          label-width="100px"
          size="small"
          @submit.native.prevent
        >
          <el-input
            v-bind:placeholder="$t('placeholder')"
            v-model="filterText"
            class="inputFilterClassAdwords"
          >
          </el-input>
          <el-tree
            :data="dataTree"
            :filter-node-method="filterNode"
            accordion
            show-checkbox
            check-strictly
            node-key="id"
            ref="tree"
            highlight-current
          >
          </el-tree>
          <div class="form-action">
            <el-form-item>
              <el-button
                plain
                type="primary"
                @click="addCategoryFilter"
                >{{ $t("add_category_filter") }}</el-button
              >
            </el-form-item>
          </div>
        </el-form>
        <el-button
          class="add-value-button"
          slot="reference"
          >+ {{ $t("add_category_filter") }}</el-button
        >
      </el-popover>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      dataTree: null,
      filterText: "",
      classAdwords: [],
      showPopup: false,
    };
  },
  created: function () {
    this.debouncedFilterTree = _.debounce(this.filterTree, 500);

    if (this.$i18n.t("current_language") === "fr") {
      fetch("/assets/taxonomies/adwords/adwords_classes_fr.json")
        .then((res) => res.json())
        .then((out) => {
          this.dataTree = out;
        })
        .catch((err) => {
          throw err;
        });
    } else if (this.$i18n.t("current_language") === "en") {
      fetch("/assets/taxonomies/adwords/adwords_classes_en.json")
        .then((res) => res.json())
        .then((out) => {
          this.dataTree = out;
        })
        .catch((err) => {
          throw err;
        });
    }
  },
  methods: {
    addCategoryFilter() {
      var checkedKeys = this.$refs.tree.getCheckedKeys();
      checkedKeys.forEach((checkedKey) => {
        if (!this.value.includes(checkedKey)) {
          this.value.push(checkedKey);
        }
      });
      this.showPopup = false;
    },
    getCheckedKeys() {
      return this.$refs.tree.getCheckedKeys();
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    filterTree(val) {
      this.$refs.tree.filter(val);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    getNodeLabel(classAdwordFilter) {
      if (classAdwordFilter) return this.$refs.tree.getNode(classAdwordFilter);
    },
    removeClassAdwordFilter(classAdwordFilter) {
      this.value.splice(this.value.indexOf(classAdwordFilter), 1);
    },
  },
  watch: {
    filterText(val) {
      this.debouncedFilterTree(val);
    },
  },
};
</script>

<style scoped lang="scss">
.keyword-filter-input-popover {
  .el-popover__title {
    font-weight: 500;
  }

  ::v-deep label {
    margin-bottom: 0;
  }

  ::v-deep .form-action {
    text-align: right;
    margin-top: 0.5rem;
  }
}

.el-tree {
  max-height: 720px;
  overflow-y: scroll;
}

.inputFilterClassAdwords {
  margin-bottom: 0.5rem;
}

.add-value-button {
  font-size: 12px;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
}
</style>

<i18n>
{
  "en": {
    "category_filter": "Filter by categories",
    "add_category_filter": "Add a filter on categories",
    "placeholder": "By category",
    "current_language": "en"
  },
  "fr": {
    "category_filter": "Filtre de catégories",
    "add_category_filter": "Ajouter un filtre sur la catégorie",
    "placeholder": "Par catégorie",
    "current_language": "fr"
  },
  "de": {
    "category_filter": "Kategoriefilter",
    "add_category_filter": "Fügen Sie der Kategorie einen Filter hinzu",
    "placeholder": "Nach Kategorie",
    "current_language": "fr"
  }
}
</i18n>
