<template>
  <el-input
    type="textarea"
    :rows="3"
    v-model="description"
  >
  </el-input>
</template>

<script>
export default {
  props: {
    value: String,
  },
  computed: {
    description: {
      get() {
        return this.value;
      },
      set(textarea) {
        this.$emit("input", textarea);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
