<template>
  <div>
    <slot></slot>
    <!-- <div v-if="!studyId || studyLinks">
      <slot name="header"></slot>
    </div>
    <div v-if="!studyId || study">
      <slot name="main"></slot>
    </div>
    <slot name="footer"></slot> -->
  </div>
</template>

<script>
// import { mapState } from "vuex";

export default {
  // props: {
  //   studyId: Number,
  // },
  // computed: {
  //   ...mapState(["study", "studyLinks"])
  // }
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {},
  "fr": {},
  "de": {}
}
</i18n>
