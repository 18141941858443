<template>
  <div>
    <div>
      <div class="score-title">
        <el-tooltip
          :content="$t('rate_explained_by_reputation_of_domain')"
          placement="top"
        >
          <el-progress
            class="progress-dashboard"
            type="dashboard"
            :stroke-width="10"
            :percentage="data.netlinkingDetails[0].score"
            :format="format"
            :color="customColor"
          >
          </el-progress>
        </el-tooltip>
        <h3>{{ $t("netlinking_of_my_domain") }}</h3>
      </div>
      <div>
        <div class="flex-box">
          <domain-authority-graph
            v-if="data"
            class="small-graph"
            :data="data.domainAuthority"
          >
          </domain-authority-graph>
          <div class="divider">
            <el-divider
              direction="vertical"
              class="vertical"
            />
          </div>
          <backlinks-distribution-graph
            v-if="data"
            class="big-graph"
            url-or-domain="DOMAIN"
            :data="data.domainsBacklinksDistribution"
          >
          </backlinks-distribution-graph>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BacklinksDistributionGraph from "@/pages/ActionPlan/Netlinking/Shared/BacklinksDistributionGraph";
import DomainAuthorityGraph from "@/pages/ActionPlan/Netlinking/Domain/DomainAuthorityGraph";

export default {
  components: {
    BacklinksDistributionGraph,
    DomainAuthorityGraph,
  },
  props: {
    data: Object,
    keyword: Object,
    page: Object,
    currentUser: Object,
  },
  methods: {
    format(percentage) {
      return percentage;
    },
    customColor(percentage) {
      if (percentage < 25) {
        return "#f56c6c";
      } else if (percentage < 50) {
        return "#e6a23c";
      } else if (percentage < 75) {
        return "#1989fa";
      } else {
        return "#67c23a";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.score-title {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .progress-dashboard {
    margin-right: 1.4rem;

    ::v-deep .el-progress__text {
      font-size: 1.25rem !important;
    }

    ::v-deep .el-progress-circle {
      height: 90px !important;
      width: 90px !important;
    }
  }
}

.flex-box {
  display: flex;
  flex-direction: row;
  width: 100%;

  .small-graph {
    flex-basis: 30%;
  }

  .big-graph {
    flex-basis: 70%;
  }

  .divider {
    margin: 0px 8px 0px 8px;

    .vertical {
      height: 100%;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "netlinking_of_my_domain": "Your domain netlinking",
    "rate_explained_by_reputation_of_domain": "The score is calculated based on the domain's reputation compared to those of the SERP"
  },
  "fr": {
    "netlinking_of_my_domain": "Netlinking de mon domaine",
    "rate_explained_by_reputation_of_domain": "La note est calculée en fonction de la réputation du domaine par rapport à ceux de la SERP"
  },
  "de": {
    "netlinking_of_my_domain": "Netzverlinkung meiner Domain",
    "rate_explained_by_reputation_of_domain": "Der Score wird basierend auf der Reputation der Domain im Vergleich zu denen der SERP berechnet"
  }
}
</i18n>
