export const keywordsSearchImportKeywordsMixin = {
  props: {
    links: Object,
  },
  computed: {
    trackedKeywordLink() {
      if (!this.links) return null;
      return this.links["tracked-keywords"];
    },
    importableKeywords() {
      if (this.trackedKeywordLink) {
        return this.trackedKeywordLink.import.formTemplate.options;
      }
    },
    importableKeywordsText() {
      if (this.importableKeywords) {
        return this.importableKeywords.map((keyword) => keyword.text);
      }
    },
  },
  methods: {
    importKeywords(keywordsAndTags) {
      if (this.links && this.links["tracked-keywords"].import) {
        if (keywordsAndTags.keywords.length > 0) {
          const link = this.links["tracked-keywords"].import;
          const formTemplate = link.formTemplate;

          const request = keywordsAndTags.keywords.map((keyword) => {
            let keywordTemplate = formTemplate.options.find(
              (option) => option.text == keyword
            );
            keywordTemplate.tags = keywordsAndTags.tags;
            return keywordTemplate;
          });

          this.$api[link.method.toLowerCase()](link.href, request)
            .then((response) => {
              this.$message.success(this.$i18n.t("add_keywords_success"));
            })
            .catch((error) => {
              if (error.response.status === 403) {
                const data = error.response.data;
                this.$message.error(
                  this.$i18n.t(data.error, {
                    count_available: data.keywords_count_available,
                    count: data.new_keywords_count,
                  })
                );
              } else {
                this.$message.error(this.$i18n.t("add_keywords_fail"));
              }
            });
        } else {
          this.$message.warning(this.$i18n.t("no_keywords_selected"));
        }
      }
    },
  },
};
