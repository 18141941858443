<template>
  <el-card>
    <el-tabs
      v-model="activeTab"
      tab-position="left"
    >
      <el-tab-pane
        name="trafficEvolution"
        :label="$t('trafficEvolution')"
      >
        <el-card shadow="never">
          <evolution-chart
            :data="trafficEvolutionData"
            :isLoading="isLoading"
          ></evolution-chart>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="shareOfVoices"
        :label="$t('shareOfVoices')"
        lazy
      >
        <el-card shadow="never">
          <share-of-voices-chart
            :data="shareOfVoices"
            :isLoading="isLoadingShareOfVoices"
          ></share-of-voices-chart>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="trafficTypology"
        :label="$t('trafficTypology')"
        lazy
      >
        <el-card shadow="never">
          <bubble-chart
            :data="bubbleData"
            :isLoading="isLoading"
          ></bubble-chart>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import BubbleChart from "@/pages/Competitors/Charts/BubbleChart";
import EvolutionChart from "@/pages/Competitors/Charts/EvolutionChart";
import ShareOfVoicesChart from "@/pages/Competitors/Charts/ShareOfVoicesChart";

export default {
  props: {
    websitesStatistics: Array,
    selectedCompetitorType: String,
    shareOfVoices: Array,
    isLoading: Boolean,
    isLoadingShareOfVoices: Boolean,
  },
  components: {
    BubbleChart,
    EvolutionChart,
    ShareOfVoicesChart,
  },
  data() {
    return {
      activeTab: "trafficEvolution",
      data: null,
      trafficEvolutionData: null,
      bubbleData: null,
    };
  },
  methods: {
    mapForCharts() {
      this.trafficEvolutionData = {
        series: [],
      };

      this.bubbleData = {
        series: [],
      };

      if (this.websitesStatistics[0].websiteStatisticsByDates.length == 0)
        return;

      for (const website of this.websitesStatistics) {
        const trafficEvolutionDataPoints = Object.entries(
          website.websiteStatisticsByDates
        )
          .sort()
          .map((statsByDate) => {
            return {
              x: new Date(statsByDate[0]).getTime(),
              y: parseInt(statsByDate[1].estimatedTraffic),
            };
          });
        let visible = true;
        if (
          website.competitorGroup != "direct" &&
          this.selectedCompetitorType == null
        )
          visible = false;
        if (website.isHost) visible = true;
        this.trafficEvolutionData.series.push({
          name: website.url.url,
          data: trafficEvolutionDataPoints,
          visible: visible,
        });

        const lastKeywordDate = Object.entries(
          website.websiteStatisticsByDates
        ).sort()[
          Object.entries(website.websiteStatisticsByDates).length - 1
        ][1];
        if (website.isHost) {
          const colorHost = "#00E676";
          this.bubbleData.series.push({
            name: website.url.url,
            x: lastKeywordDate.positionBucketsStatistics.find(
              (bucket) => bucket.positionMin == 1 && bucket.positionMax == 10
            ).numberOfKeywords,
            y: parseInt(lastKeywordDate.estimatedTraffic),
            z: lastKeywordDate.positionBucketsStatistics.find(
              (bucket) => bucket.positionMin == 1 && bucket.positionMax == 1
            ).numberOfKeywords,
            color: colorHost,
          });
        } else {
          this.bubbleData.series.push({
            name: website.url.url,
            x: lastKeywordDate.positionBucketsStatistics.find(
              (bucket) => bucket.positionMin == 1 && bucket.positionMax == 10
            ).numberOfKeywords,
            y: parseInt(lastKeywordDate.estimatedTraffic),
            z: lastKeywordDate.positionBucketsStatistics.find(
              (bucket) => bucket.positionMin == 1 && bucket.positionMax == 1
            ).numberOfKeywords,
          });
        }
      }
    },
  },
  watch: {
    websitesStatistics() {
      if (this.websitesStatistics) this.mapForCharts();
    },
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "trafficEvolution": "Evolution of estimated traffic",
    "trafficTypology": "Traffic typology",
    "shareOfVoices": "Share of voices"
  },
  "fr": {
    "trafficEvolution": "Evolution du trafic estimé",
    "trafficTypology": "Typologie du trafic",
    "shareOfVoices": "Part de voix des concurrents"
  },
  "de": {
    "trafficEvolution": "Entwicklung des geschätzten Verkehrs",
    "trafficTypology": "Verkehrstypologie",
    "shareOfVoices": "Share of Voice der Wettbewerber"
  }
}
</i18n>
