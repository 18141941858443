<template>
  <div>
    <app-introduction :title="$t('briefText')"></app-introduction>
    <div class="container">
      <el-card>
        <Brief
          v-if="brief"
          :is-stand-alone="true"
          :is-loading-brief="isLoadingBrief"
          :brief="brief"
          :backlink="backlink"
        >
          <template v-slot:title>
            <div class="title">
              <span style="font-weight: bold">{{ $t("briefText") }}</span> -
              {{ backlink.data.keyword.text }}
            </div>
          </template>
        </Brief>
      </el-card>
    </div>
  </div>
</template>

<script>
import Brief from "@/components/CampaignManager/Brief";
import axios from "axios";

import AppIntroduction from "@/components/AppIntroduction";

export default {
  components: {
    AppIntroduction,
    Brief,
  },
  data() {
    return {
      backlink: null,
      brief: null,
      isLoadingBrief: false,
    };
  },
  methods: {
    fetchBacklink() {
      this.isLoadingBrief = true;
      const url = window.location.href.split("/");
      const backlinkId = url[url.length - 2];
      if (backlinkId) {
        axios
          .get(
            "https://" +
              process.env.API_HOST +
              "/backlinks/public/" +
              backlinkId
          )
          .then((response) => {
            this.backlink = response.data;
            if (this.backlink.links.brief && this.backlink.links.brief.read) {
              const link = this.backlink.links.brief.read;
              axios[link.method.toLowerCase()](
                "https://" + process.env.API_HOST + link.href
              ).then((response) => {
                this.isLoadingBrief = false;
                this.brief = response.data;
              });
            }
          });
      }
    },
  },
  mounted() {
    this.fetchBacklink();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
</style>

<i18n>
{
  "en": {
    "briefRedaction": "Brief redaction",
    "briefText": "Brief",
    "validate": "Validate the brief",
    "briefSaved": "Brief has been saved"
  },
  "fr": {
    "briefRedaction": "Rédaction de brief",
    "briefText": "Brief",
    "validate": "Valider le brief",
    "briefSaved": "Brief sauvegardé"
  },
  "de": {
    "briefRedaction": "Kurzes Schreiben",
    "briefText": "Knapp",
    "validate": "Bestätigen Sie den Auftrag",
    "briefSaved": "Kurz gespeichert"
  }
}
</i18n>
