<template>
  <div>
    <span>{{ params.valueFormatted }}</span>
    <el-tag
      v-if="progression != null"
      :type="type"
      size="mini"
      disable-transitions
    >
      <i
        class="fas"
        :class="caretDirection"
      ></i>
      <span v-if="this.progression != 0">{{ progression }}</span>
    </el-tag>
  </div>
</template>

<script>
export default {
  data() {
    return {
      progression: null,
    };
  },
  computed: {
    type() {
      if (this.progression > 0) {
        return "success";
      } else if (this.progression < 0) {
        return "danger";
      } else {
        return "info";
      }
    },
    caretDirection() {
      if (this.progression > 0) {
        return "fa-arrow-up";
      } else if (this.progression < 0) {
        return "fa-arrow-down";
      } else {
        return "fa-equals";
      }
    },
  },
  mounted() {
    if (
      this.params.data.pastEstimatedTraffic &&
      this.params.data.estimatedTraffic
    ) {
      this.progression =
        this.params.data.estimatedTraffic -
        this.params.data.pastEstimatedTraffic;
    }
  },
};
</script>
