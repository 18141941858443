<template>
  <div
    v-loading="
      mainSeriesLoading ||
      conversionGroupsLoading ||
      comparativeConversionGroupsLoading
    "
  >
    <slot
      :dimension="dimension"
      :main-series="mainSeries"
      :conversion-groups="conversionGroups"
      :comparative-conversion-groups="comparativeConversionGroups"
      :comparative-period="comparativePeriod"
    ></slot>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const ConversionsRepository = RepositoryFactory.get("conversions");

export default {
  props: {
    dimension: String,
    dimensionsData: Array,
    studyId: Number,
    filters: Array,
    period: Array,
    comparativePeriod: Array,
    frequency: String,
  },
  data() {
    return {
      mainSeries: null,
      mainSeriesLoading: false,
      conversionGroups: null,
      conversionGroupsLoading: false,
      comparativeConversionGroups: null,
      comparativeConversionGroupsLoading: false,
    };
  },
  methods: {
    fetchData() {
      this.fetchMainSeries();
      this.fetchConversionGroups();
      this.fetchComparativeConversionGroups();
    },
    fetchMainSeries() {
      this.mainSeriesLoading = true;
      ConversionsRepository.createConversionChart(
        this.studyId,
        this.filters,
        this.period,
        this.comparativePeriod,
        this.frequency
      )
        .then((data) => {
          this.mainSeries = data;
          this.mainSeriesLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.mainSeriesLoading = false;
        });
    },
    fetchConversionGroups() {
      if (this.dimension) {
        this.conversionGroupsLoading = true;
        ConversionsRepository.createConversionChartGroups(
          this.studyId,
          this.filters,
          this.dimension,
          this.period,
          this.frequency
        )
          .then((data) => {
            this.conversionGroups = data;
            this.conversionGroupsLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.conversionGroupsLoading = false;
          });
      } else {
        this.conversionGroups = null;
      }
    },
    fetchComparativeConversionGroups() {
      if (this.dimension && this.comparativePeriod) {
        this.comparativeConversionGroupsLoading = true;
        ConversionsRepository.createConversionChartGroups(
          this.studyId,
          this.filters,
          this.dimension,
          this.comparativePeriod,
          this.frequency
        )
          .then((data) => {
            this.comparativeConversionGroups = data;
            this.comparativeConversionGroupsLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.comparativeConversionGroupsLoading = false;
          });
      } else {
        this.comparativeConversionGroups = null;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    $props: {
      handler: "fetchData",
      deep: true,
    },
  },
};
</script>

<style scoped></style>
