<template>
  <el-form-item :label="$t('due_date')">
    <el-date-picker
      :value="value"
      type="daterange"
      align="center"
      :format="datePickerFormat"
      value-format="yyyy-MM-dd"
      :start-placeholder="$t('start_date')"
      :range-separator="$t('to')"
      :end-placeholder="$t('end_date')"
      @input="(event) => $emit('input', event)"
      :picker-options="pickerOptions"
    ></el-date-picker>
  </el-form-item>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1,
      },
    };
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "due_date": "Due date",
    "end_date": "End date",
    "start_date": "Start date",
    "to": "To"
  },
  "fr": {
    "due_date": "Date d'échéance",
    "end_date": "Date de fin",
    "start_date": "Date de début",
    "to": "à"
  },
  "de": {
    "due_date": "Geburtstermin",
    "end_date": "Endtermin",
    "start_date": "Startdatum",
    "to": "Zu"
  }
}
</i18n>
