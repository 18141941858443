<template>
  <div>
    <app-introduction
      :title="$t('traffic')"
      video-link="https://www.youtube.com/watch?v=OSIkYUaC5gA"
      :documentations="[
        {
          link: $t('documentationLink'),
          description: $t('documentation_desc'),
        },
      ]"
      :user="currentUser"
    >
      <template v-slot:right>
        <el-button
          type="primary"
          :loading="downloading"
          @click="downloadRecommendations"
        >
          {{ $t("export_recommendations") }}
        </el-button>
      </template>
    </app-introduction>

    <slot></slot>

    <div class="container">
      <el-card>
        <reporting-form
          :dimensions-data="dimensionsData"
          :period="period"
          :frequency="frequency"
          @form-update="updateData"
        ></reporting-form>
      </el-card>

      <metrics
        :metrics="metrics"
        :dimensionsData="dimensionsData"
      ></metrics>

      <el-card>
        <dimension-selector
          v-model="dimension"
          :dimensions-data="dimensionsData"
        ></dimension-selector>
        <traffic-loader
          :dimension="dimension"
          :dimensions-data="dimensionsData"
          :study-id="studyId"
          :filters="filters"
          :period="period"
          :comparative-period="comparativePeriod"
          :frequency="frequency"
          #default="trafficLoaderProps"
        >
          <evolution
            :frequency="trafficLoaderProps.frequency"
            :main-series="trafficLoaderProps.mainSeries"
            :traffic-groups="trafficLoaderProps.trafficGroups"
            :comparative-traffic-groups="
              trafficLoaderProps.comparativeTrafficGroups
            "
            :comparative-period="trafficLoaderProps.comparativePeriod"
          ></evolution>
        </traffic-loader>
      </el-card>

      <el-card>
        <el-tabs
          v-model="activeTab"
          :stretch="true"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            :label="$t('pages')"
            name="pages"
          >
            <page-grid
              :account-id="studyId"
              :dimensions-data="dimensionsData"
              :filters="filters"
              :period="period"
              :comparative-period="comparativePeriod"
              @show-page="showPageDialog($event)"
            >
            </page-grid>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('keywords')"
            name="keywords"
            lazy
          >
            <keyword-grid
              :account-id="studyId"
              :dimensions-data="dimensionsData"
              :filters="filters"
              :period="period"
              :comparative-period="comparativePeriod"
            >
            </keyword-grid>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <el-dialog
      v-if="grDialogVisible"
      :visible.sync="grDialogVisible"
      :key="struct.url"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h4>
          Page :
          <a
            :href="pageUrl"
            target="_blank"
            >{{ pageUrl }}</a
          >
        </h4>
      </div>
      <google-results-page
        v-if="period && period.length > 0"
        :url="pageUrl"
        :initial-date="endDateAsDateTime"
        :initial-past-date="startDateAsDateTime"
        :current-user="currentUser"
      ></google-results-page>
    </el-dialog>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { RepositoryFactory } from "@/services/repositoryFactory";

const TrafficsRepository = RepositoryFactory.get("traffics");
import Metrics from "@/pages/Traffic/Metrics";
import PageGrid from "@/pages/Traffic/PageGrid";
import KeywordGrid from "@/pages/Traffic/KeywordGrid";

import AppIntroduction from "@/components/AppIntroduction";
import DimensionSelector from "@/components/DimensionSelector";
import TrafficLoader from "@/components/TrafficLoader";
import ReportingForm from "@/components/forms/ReportingForm";
import GoogleResultsPage from "@/pages/GoogleResults";

import Evolution from "./Evolution";

export default {
  components: {
    AppIntroduction,
    DimensionSelector,
    Evolution,
    KeywordGrid,
    Metrics,
    PageGrid,
    ReportingForm,
    TrafficLoader,
    GoogleResultsPage,
  },
  props: {
    studyId: Number,
    dimensionsData: Array,
    currentUser: Object,
  },
  data() {
    return {
      filters: [],
      period: [],
      comparativePeriod: null,
      frequency: "day",
      metrics: null,
      dimension: null,
      activeTab: "pages",
      downloading: false,
      struct: {
        start_date: null,
        end_date: null,
        url: null,
      },
      grDialogVisible: false,
      pageUrl: null,
    };
  },
  methods: {
    fetchMetrics() {
      TrafficsRepository.createTrafficMetrics(
        this.studyId,
        this.filters,
        this.period,
        this.comparativePeriod
      )
        .then((data) => {
          this.metrics = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateData(form) {
      this.filters = form.filters;
      this.period = form.period;
      this.comparativePeriod = form.comparativePeriod;
      this.frequency = form.frequency;
      this.fetchMetrics();
    },
    setDefaultPeriod() {
      const today = new Date();
      const default_end_date = new Date(today.setDate(today.getDate() - 3));
      const default_start_date = new Date(today.setDate(today.getDate() - 33));

      return [default_start_date, default_end_date];
    },
    handleTabClick(tab, event) {
      if (tab.loaded) tab.$slots.default[0].child.adjustGrid();
    },
    downloadRecommendations() {
      this.downloading = true;
      TrafficsRepository.createRecommendations(
        this.studyId,
        this.filters,
        this.period,
        this.comparativePeriod
      )
        .then((data) => {
          const url = window.URL.createObjectURL(
            new Blob([data], { type: "application/xlsx" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "recommendations.xlsx");
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        })
        .catch((error) => {
          this.downloading = false;
          this.$message({
            message: this.$i18n.t("export_error"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    showPageDialog(details) {
      this.pageUrl = details;
      this.grDialogVisible = true;
    },
  },
  computed: {
    startDateAsDateTime() {
      return DateTime.fromJSDate(new Date(this.period[0]));
    },
    endDateAsDateTime() {
      return DateTime.fromJSDate(new Date(this.period[1]));
    },
  },
  beforeMount() {
    this.period = this.setDefaultPeriod();
    this.fetchMetrics();
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}

::v-deep .el-tabs__content {
  padding-top: 15px;
}
</style>

<i18n>
{
  "en": {
    "documentation_desc": "How to analyse your SEO data ?",
    "export_error": "Error while exporting recommendations",
    "export_recommendations": "Export recommendations",
    "keywords": "Keywords",
    "pages": "Pages",
    "traffic": "Traffic",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-trafic/les-donnees-de-trafic-dans-smartkeyword"
  },
  "fr": {
    "documentation_desc": "Comment analyser son trafic SEO ?",
    "export_error": "Erreur durant l'export de recommendations",
    "export_recommendations": "Exporter les recommendations",
    "keywords": "Mots clés",
    "pages": "Pages",
    "traffic": "Trafic",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-trafic/les-donnees-de-trafic-dans-smartkeyword"
  },
  "de": {
    "documentation_desc": "Wie analysiert man seinen SEO-Traffic?",
    "export_error": "Fehler beim Exportieren von Empfehlungen",
    "export_recommendations": "Empfehlungen exportieren",
    "keywords": "Schlüsselwörter",
    "pages": "Seiten",
    "traffic": "Verkehr",
    "documentationLink": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-trafic/les-donnees-de-trafic-dans-smartkeyword"
  }
}
</i18n>
