<template>
  <div class="container-details">
    <el-progress
      type="circle"
      :percentage="recommendations.score"
      status="success"
      :width="25"
      :stroke-width="3"
      :color="setColorCircle"
      :show-text="false"
    >
    </el-progress>
    <div class="recommendation-text">
      <div
        v-for="(item, index) in recommendations.statements"
        :key="index"
      >
        <p>{{ item }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recommendations: Object,
  },
  computed: {
    setColorCircle() {
      if (this.recommendations.score <= 50) return "#f56c6c";
      else if (
        this.recommendations.score > 50 &&
        this.recommendations.score < 90
      )
        return "#e6a23c";
      else return "#67c23a";
    },
  },
  methods: {
    formatText() {
      for (let i = 0; i < this.recommendations.statements.length; i++) {
        let text = this.recommendations.statements[i];
        if (text) text = text.replaceAll(/&quot;&quot;/g, '"');
        if (text) text = text.replaceAll(/&quot;/g, '"');
        if (text) text = text.replaceAll(/&#39;/g, "'");
        this.recommendations.statements[i] = text;
      }
    },
  },
  created() {
    this.formatText();
  },
};
</script>

<style scoped>
.container-details {
  display: flex;
  flex-direction: row;
  align-items: top;
  margin-left: 2em;
}

.recommendation-text {
  padding-left: 2rem;
  width: 450px;
}
</style>
