<template>
  <div v-if="task && userOptions">
    <div class="container white-background">
      <task-update-form
        :user-options="userOptions"
        :current-user="currentUser"
        :value="task"
        :creating-task="false"
        :is-stand-alone="true"
        @delete-task="deleteTask"
      >
      </task-update-form>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
import TaskUpdateForm from "@/components/task/TaskUpdateForm.vue";
const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  props: {
    currentUser: Object,
    account: Object,
    taskId: String,
  },
  components: {
    TaskUpdateForm,
  },
  data() {
    return {
      task: null,
      creatingTask: false,
      userOptions: null,
    };
  },
  methods: {
    callAPI() {
      this.$api
        .get(`/studies/${this.account.id}/tasks/${this.taskId}`)
        .then((response) => {
          this.task = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchUsers() {
      CompaniesRepository.getCompanyUsersForTasks(
        this.currentUser.company_id
      ).then((data) => {
        this.userOptions = data.data.map((user) => ({
          id: parseInt(user.id),
          firstName: user.attributes.firstName,
          lastName: user.attributes.lastName,
        }));
      });
    },
    deleteTask(task) {
      this.$api
        .delete(`/studies/${task.studyId}/tasks/${task.id}`)
        .then(() => {
          document.location.href =
            window.location.origin + `/a/${task.studyId}/tasks`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    this.fetchUsers();
    this.callAPI();
  },
};
</script>

<style lang="scss" scoped>
.white-background {
  background-color: white;
}
</style>
