<template>
  <div class="da-metrics-container">
    <div
      class="warning"
      v-if="showWarningData"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('warningMsgData')"
        placement="right-start"
      >
        <i class="el-icon-warning"> </i>
      </el-tooltip>
    </div>
    <div
      class="warning"
      v-else-if="showWarning"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('warningMsg', [host])"
        placement="right-start"
      >
        <i class="el-icon-warning"> </i>
      </el-tooltip>
    </div>
    <div class="value-container">
      <div class="flows">
        <div class="flow">
          <div>
            <el-progress
              class="small-dashboard"
              type="dashboard"
              :percentage="metrics.trustFlow"
              :format="format"
              :color="customColorMethod"
            >
            </el-progress>
          </div>
          <div class="small-text">
            {{ $t("trustFlow") }}
          </div>
        </div>
        <div class="flow">
          <div>
            <el-progress
              class="small-dashboard"
              type="dashboard"
              :percentage="metrics.citationFlow"
              :format="format"
              :color="customColorMethod"
            >
            </el-progress>
          </div>
          <div class="small-text">
            {{ $t("citationFlow") }}
          </div>
        </div>
      </div>
      <el-divider class="divider divider-horizontal"></el-divider>
      <div>
        <div
          v-if="metrics.trustFlow && metrics.trustFlow"
          class="value"
        >
          {{ ratioTrustFlowCitationFlow | formatNumber }}%
        </div>
        <div
          v-else
          class="text"
        >
          --
        </div>
        <div class="small-text">
          {{ $t("ratioTFCF") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    metrics: Object,
    host: String,
    showWarning: Boolean,
    showWarningData: Boolean,
  },
  methods: {
    format(percentage) {
      return percentage;
    },
    customColorMethod(percentage) {
      if (percentage < 25) {
        return "#f56c6c";
      } else if (percentage < 50) {
        return "#e6a23c";
      } else if (percentage < 75) {
        return "#1989fa";
      } else {
        return "#67c23a";
      }
    },
  },
  computed: {
    ratioTrustFlowCitationFlow() {
      if (this.metrics) {
        return Math.round(
          (this.metrics.trustFlow / this.metrics.citationFlow) * 100
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.da-metrics-container {
  height: 100%;

  .value-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .value {
      font-size: 1.25rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    .flows {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100%;

      .flow {
        text-align: center;
        .small-text {
          font-size: 0.9rem;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          text-align: center;
        }

        .small-dashboard {
          ::v-deep .el-progress__text {
            font-size: 1.25rem !important;
          }
          ::v-deep .el-progress-circle {
            width: 100px !important;
            height: 100px !important;
          }
        }
      }
    }

    .divider-horizontal {
      height: 1px;
    }
  }

  .da {
    text-align: center;

    ::v-deep .el-progress__text {
      font-size: 1.25rem !important;
    }
  }

  .divider-vertical {
    height: 100%;
    width: 2px;
  }

  .small-text {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-align: center;
  }
}
.warning {
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  color: #e6a23c;
}
</style>
<i18n>
{
  "en": {
    "trustFlow": "Trust Flow",
    "citationFlow": "Citation Flow",
    "ratioTFCF": "Ratio TF/CF",
    "warningMsg": " This report shows the Trust flow and Citation flow of {0}",
    "warningMsgData": "Data unavailable"
  },
  "fr": {
    "trustFlow": "Trust Flow",
    "citationFlow": "Citation Flow",
    "ratioTFCF": "Ratio TF/CF",
    "warningMsg": " Ce rapport affiche Trust flow and Citation flow de {0}",
    "warningMsgData": "Données indisponibles"
  },
  "de": {
    "trustFlow": "Vertrauensfluss",
    "citationFlow": "Zitierfluss",
    "ratioTFCF": "Verhältnis TF/CF",
    "warningMsg": "Dieser Bericht zeigt den Vertrauensfluss und den Zitierfluss von {0}",
    "warningMsgData": "Keine Daten verfügbar"
  }
}
</i18n>
