import Repository from "@/services/repository";

export default {
  updateAlertStatus(studyId, alertId, status) {
    return Repository.patch(`/a/${studyId}/alerts/${alertId}.json`, {
      id: alertId,
      status: status,
    }).then((response) => response.data);
  },
};
