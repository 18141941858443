<template>
  <div>
    <h4>{{ $t("classifiedKeywords") }}</h4>
    <div class="text-right">
      <el-button
        plain
        type="primary"
        @click="exportExcel"
        >{{ $t("exportExcel") }}</el-button
      >
    </div>
    <ag-grid-vue
      style="height: 400px"
      @grid-ready="onGridReady"
      class="ag-theme-material"
      :rowData="rowData"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :autoGroupColumnDef="autoGroupColumnDef"
      :treeData="true"
      :animateRows="true"
      :groupDefaultExpanded="groupDefaultExpanded"
      :getDataPath="getDataPath"
      :getRowNodeId="getRowNodeId"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const PagesRepository = RepositoryFactory.get("pages");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

export default {
  mixins: [agGridMixin],
  props: {
    data: Object,
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      gridOptions: {},
      gridApi: null,
      columnApi: null,
      rowData: null,
      columnDefs: null,
      defaultColDef: null,
      autoGroupColumnDef: null,
      groupDefaultExpanded: null,
      getDataPath: null,
      id: 1,
      getRowNodeId: null,
      res: [],
      path: [],
      maxLength: -1,
    };
  },
  methods: {
    loadGrid() {
      this.setColumnDefs();
      this.mapData();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          field: "volume",
          aggFunc: "sum",
          maxWidth: 150,
          valueFormatter: numberFormatter,
        },
      ];
      this.autoGroupColumnDef = {
        headerName: this.$i18n.t("keyword"),
        minWidth: 330,
        cellRendererParams: {
          suppressCount: true,
        },
      };
      this.defaultColDef = {
        flex: 1,
        filter: true,
        sortable: true,
        resizable: true,
      };
      this.groupDefaultExpanded = -1;
      this.getDataPath = (data) => {
        return data.filePath;
      };
      this.getRowNodeId = (data) => {
        return data.id;
      };
    },
    deepMap(path, data) {
      data.map((elt) => {
        var row = {};
        row.id = this.id;
        this.id += 1;
        var newPath = [...path];
        newPath.push(elt.text);
        row.filePath = newPath;
        if (elt.type == "category") {
          this.res.push(row);
          this.maxLength =
            this.maxLength > newPath.length ? this.maxLength : newPath.length;
          this.deepMap(newPath, elt.children);
        } else if (elt.type == "keyword") {
          row.keyword = elt.text;
          row.volume = elt.volume;
          this.res.push(row);
        }
      });
    },
    mapData() {
      if (this.data) {
        var ret = null;
        var path = ["/"];
        this.res = [];
        this.maxLength = -1;
        this.deepMap(path, this.data.data);
        this.rowData = this.res;
        this.res = [];
      } else this.rowData = [];
    },
    getRowStyle(params) {
      if (params.node.group) {
        return { "font-weight": "bold" };
      }
    },
    exportExcel() {
      let data = this.rowData;
      var XLSX = require("xlsx");
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Keyword Export - SmartKeyword",
        Subject: "Keywords",
        Author: "Smartkeyword",
      };
      wb.SheetNames.push("Keywords");
      var ws_data = [];
      var header = [];
      for (let i = 1; i < this.maxLength; i++) {
        header.push("Categorie " + i);
      }
      header.push("Mot-clé");
      header.push("Volume");
      ws_data.push(header);
      let keywordRows = data.filter((row) => row.hasOwnProperty("keyword"));
      keywordRows.forEach((row) => {
        let arr = [];
        if (row.filePath) {
          // Deleting the last element which is the keyword
          row.filePath.pop();
          // Deleting the first element which is a /
          row.filePath.shift();
          arr = row.filePath;
          while (arr.length < this.maxLength - 1) {
            arr.push("");
          }
          arr.push(row.keyword);
          arr.push(row.volume);
        }
        ws_data.push(arr);
      });
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      ws["!cols"] = this.fitToColumn(ws_data);
      wb.Sheets["Keywords"] = ws;
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        "Keyword Export - SmartKeyword.xlsx"
      );
    },
    fitToColumn(arrayOfArray) {
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => {
            if (typeof a2[i] != "undefined") {
              return a2[i].toString().length;
            }
          })
        ),
      }));
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
  },
  beforeMount() {
    this.loadGrid();
  },
  watch: {
    data: {
      handler() {
        this.mapData();
        this.adjustGrid();
      },
      deep: true,
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
  padding-bottom: 0.5rem;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "keyword": "Keyword",
    "groupedKeywords": "Grouped Keywords",
    "volume": "Volume",
    "source": "Source",
    "exportExcel": "Export Excel",
    "classifiedKeywords": "Classified Keywords"
  },
  "fr": {
    "keyword": "Mot clé",
    "groupedKeywords": "Mots clés groupés",
    "volume": "Volume",
    "source": "Source",
    "exportExcel": "Export Excel",
    "classifiedKeywords": "Mots-clés classés"
  },
  "de": {
    "keyword": "Stichwort",
    "groupedKeywords": "Gruppierte Schlüsselwörter",
    "volume": "Volumen",
    "source": "Quelle",
    "exportExcel": "Excel exportieren",
    "classifiedKeywords": "Geordnete Schlüsselwörter"
  }
}
</i18n>
