<template>
  <div>
    <div class="parameters">
      <div class="text-right">
        <el-button
          icon="fas fa-cog"
          plain
          @click="parametersDialogVisible = true"
        ></el-button>
      </div>
      <el-dialog
        :title="$t('parameters')"
        :visible.sync="parametersDialogVisible"
      >
        <el-table
          :data="parametersTableData"
          style="width: 100%"
        >
          <el-table-column
            prop="attribute"
            :label="$t('attribute')"
          >
          </el-table-column>
          <el-table-column
            prop="value"
            :label="$t('value')"
            width="100"
          >
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <div class="metrics">
      <el-row
        type="flex"
        :gutter="20"
      >
        <el-col :span="12">
          <el-card>
            <div class="kpi">
              <div class="text">{{ $t("studies") }}</div>
              <div class="value">{{ studiesCount }}</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <div class="kpi">
              <div class="text">{{ $t("users") }}</div>
              <div class="value">{{ usersCount }}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="studies">
      <studies
        :company-id="companyId"
        :current-user="currentUser"
        @refresh="fetchMetrics"
      ></studies>
    </div>
    <div class="users">
      <users
        :company-id="companyId"
        @refresh="fetchMetrics"
      ></users>
    </div>
    <div
      class="invoices"
      v-if="invoices.length > 0"
    >
      <invoices :invoices="invoices"></invoices>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompaniesRepository = RepositoryFactory.get("companies");
import Studies from "@/pages/CompanyEdit/Studies";
import Users from "@/pages/CompanyEdit/Users";
import Invoices from "@/components/billing/Invoices";

export default {
  props: {
    companyId: Number,
    currentUser: Object
  },
  components: {
    Studies,
    Users,
    Invoices,
  },
  data() {
    return {
      parametersTableData: [],
      usersAllowedCount: null,
      keywordsLimit: null,
      competitorsLimit: null,
      studiesCount: null,
      invoices: [],
      usersCount: null,
      parametersDialogVisible: false,
    };
  },
  methods: {
    fetchParameters() {
      CompaniesRepository.getCompanyParameters(this.companyId)
        .then((data) => {
          this.parametersTableData = [
            {
              attribute: this.$i18n.t("max_number_users"),
              value: data.usersAllowedCount,
            },
            {
              attribute: this.$i18n.t("max_number_keywords"),
              value: data.keywordsLimit,
            },
            {
              attribute: this.$i18n.t("max_number_competitors"),
              value: data.competitorsLimit,
            },
          ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchMetrics() {
      CompaniesRepository.getCompanyMetrics(this.companyId)
        .then((data) => {
          this.studiesCount = data.studiesCount;
          this.usersCount = data.usersCount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchInvoices() {
      CompaniesRepository.getCompanyInvoices(this.companyId)
        .then((data) => {
          this.invoices = data.invoices;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    this.fetchMetrics();
    this.fetchParameters();
    this.fetchInvoices();
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}
.metrics {
  .el-col {
    display: flex;
    flex-direction: column;
  }

  .el-card {
    display: flex;
    flex-grow: 1;

    ::v-deep .el-card__body {
      width: 100%;
    }

    .kpi {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;

      .text {
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }
      .value {
        margin-bottom: 0.5rem;
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }
}
.parameters {
  margin-bottom: 1rem;
}
.text-right {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "parameters": "Settings",
    "attribute": "Attribute",
    "value": "Value",
    "max_number_users": "Maximum number of users",
    "max_number_keywords": "Maximum number of keywords",
    "max_number_competitors": "Maximum number of competitors"
  },
  "fr": {
    "parameters": "Paramètres",
    "attribute": "Attribut",
    "value": "Valeur",
    "max_number_users": "Nombre maximum d'utilisateurs",
    "max_number_keywords": "Nombre maximum de mots clés",
    "max_number_competitors": "Nombre maximum de concurrents"
  },
  "de": {
    "parameters": "Einstellungen",
    "attribute": "Attribut",
    "value": "Wert",
    "max_number_users": "Maximale Anzahl von Benutzern",
    "max_number_keywords": "Maximale Anzahl von Schlüsselwörtern",
    "max_number_competitors": "Maximale Teilnehmerzahl"
  }
}
</i18n>
