<template>
  <div>
    <div class="chart-title">
      <span class="chart-name">{{ $t("seasonality") }} </span>
    </div>
    <div>
      <highcharts
        ref="highchart"
        :options="chartOptions"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 300,
          zoomType: "x",
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yAxis: {
          title: {
            text: this.$i18n.t("volume"),
          },
        },
        tooltip: {
          pointFormat: "Volume: <b> {point.y:,.0f} </b>",
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      this.chartOptions.series = [];
      if (this.data) {
        this.chartOptions.series.push({
          data: [
            this.data[1],
            this.data[2],
            this.data[3],
            this.data[4],
            this.data[5],
            this.data[6],
            this.data[7],
            this.data[8],
            this.data[9],
            this.data[10],
            this.data[11],
            this.data[12],
          ],
          name: this.$i18n.t("volume"),
        });
      } else {
        this.chartOptions.series = [];
      }
    },
  },
  mounted() {
    this.updateSeries();
  },
  watch: {
    data() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;

  .chart-name {
    font-size: 1.5rem;
  }
}
</style>

<i18n>
{
  "en": {
    "volume": "Volume",
    "seasonality": "Seasonality"
  },
  "fr": {
    "volume": "Volume",
    "seasonality": "Saisonnalité"
  },
  "de": {
    "volume": "Volumen",
    "seasonality": "Saisonalität"
  }
}
</i18n>
