var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('el-collapse',[_c('el-collapse-item',[_c('div',{staticClass:"flex-box",attrs:{"slot":"title"},slot:"title"},[_c('span',[_c('span',{staticClass:"campaign-name"},[_vm._v("\n              "+_vm._s(_vm.data.name)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"campaign-info"},[_vm._v("\n              - "+_vm._s(_vm.currencyFormat(_vm.data.budget))+"\n            ")]),_vm._v(" "),(_vm.campaignDetails)?_c('span',{staticClass:"campaign-info"},[_vm._v("\n              "+_vm._s(_vm.campaignDate)+" -\n              "+_vm._s(_vm.isStatusTerminated()
                  ? _vm.$t("terminated")
                  : _vm.data.status == "IN_PROGRESS"
                  ? _vm.$t("in_progress")
                  : _vm.$t(
                      _vm.campaignDetails.meta.netlinkingCampaignStatus.toLowerCase()
                    ))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.campaignDetails && _vm.clientInfoIsEmpty)?_c('el-tooltip',{attrs:{"placement":"top","content":_vm.$t('you_have_to_fill_up_client_info')}},[_c('span',{staticClass:"alert-notif"},[_vm._v("\n                - "),_c('i',{staticClass:"fas fa-exclamation-triangle"})])]):_vm._e()],1),_vm._v(" "),(_vm.campaignDetails)?_c('div',{staticClass:"delete-update-buttons"},[_c('edit-campaign-button',{attrs:{"actions":_vm.campaignDetails.actions},on:{"edit":function($event){return _vm.showEditCampaignDialog($event)}}}),_vm._v(" "),_c('terminate-campaign-button',{attrs:{"actions":_vm.campaignDetails.actions},on:{"terminate":function($event){return _vm.terminateCampaign($event)}}}),_vm._v(" "),_c('delete-campaign-button',{attrs:{"actions":_vm.campaignDetails.actions},on:{"delete":function($event){return _vm.deleteCampaign($event)}}})],1):_vm._e()]),_vm._v(" "),_c('el-divider'),_vm._v(" "),(_vm.campaignDetails)?_c('div',{staticClass:"client"},[(
              _vm.campaignDetails.actions &&
              _vm.campaignDetails.actions.updateClientInfo
            )?_c('h4',{staticClass:"mini-title"},[_c('strong',[_vm._v(_vm._s(_vm.$t("client_infos")))])]):_vm._e(),_vm._v(" "),_c('div',[(
                _vm.campaignDetails.actions &&
                _vm.campaignDetails.actions.updateClientInfo
              )?_c('el-button',{staticClass:"button",attrs:{"size":"small","type":"primary","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.showEditClientDialog(_vm.campaignDetails.actions.updateClientInfo)}}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" "+_vm._s(_vm.$t("modify"))+"\n            ")]):_vm._e()],1),_vm._v(" "),(
              _vm.campaignDetails.actions &&
              _vm.campaignDetails.actions.launchCampaign
            )?_c('el-button',{staticClass:"launch-button",attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.launchCampaign(_vm.campaignDetails.actions.launchCampaign)}}},[_vm._v("\n            "+_vm._s(_vm.$t("launch_campaign"))+"\n          ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.campaignDetails)?_c('div',{staticClass:"description"},[_c('h4',{staticClass:"mini-title"},[_c('strong',[_vm._v(_vm._s(_vm.$t("description")))])]),_vm._v(" "),_c('div',{key:_vm.data.description,staticClass:"desc-data"},[_vm._v("\n            "+_vm._s(_vm.data.description)+"\n          ")])]):_vm._e(),_vm._v(" "),(_vm.campaignDetails)?_c('div',{staticClass:"watchers"},[_c('h4',{staticClass:"mini-title"},[_c('strong',[_vm._v(_vm._s(_vm.$t("watchers")))])]),_vm._v(" "),_c('div',_vm._l((_vm.campaignDetails.watchers),function(watcher){return _c('el-tag',{key:watcher.id,staticClass:"watcher"},[_vm._v("\n              "+_vm._s(watcher.firstName + " " + watcher.lastName)+"\n            ")])}),1)]):_vm._e(),_vm._v(" "),_c('el-divider'),_vm._v(" "),(_vm.campaignDetails)?_c('backlinks',{attrs:{"data":_vm.campaignDetails,"bulkUpdate":_vm.bulkUpdateLink,"admin":_vm.admin,"study-url":_vm.studyUrl,"default-search-engine":_vm.defaultSearchEngine,"account":_vm.account,"readCampaign":this.data.actions.read},on:{"refresh-meta":function (newMeta) { return (_vm.campaignDetails.meta = newMeta); },"backlinks":function($event){return _vm.assignBacklinks($event)},"refresh-campaign":function($event){return _vm.fetchCampaignDetails()}}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.data.actions && _vm.data.actions.update)?_c('edit-campaign-dialog',{attrs:{"data":_vm.data.actions.update,"isCampaign":true},on:{"update-campaign":function($event){return _vm.updateCompaign($event)}},model:{value:(_vm.editCampaignDialogVisible),callback:function ($$v) {_vm.editCampaignDialogVisible=$$v},expression:"editCampaignDialogVisible"}}):_vm._e(),_vm._v(" "),(
      _vm.campaignDetails &&
      _vm.campaignDetails.actions &&
      _vm.campaignDetails.actions.updateClientInfo
    )?_c('edit-client-dialog',{attrs:{"data":_vm.campaignDetails.actions.updateClientInfo},on:{"update-client":function($event){return _vm.updateClientInfo($event)}},model:{value:(_vm.editClientDialogVisible),callback:function ($$v) {_vm.editClientDialogVisible=$$v},expression:"editClientDialogVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }