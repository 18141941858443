<template>
  <div>
    <div class="d-flex flex-row justify-content-between mb-1">
      <span class="category-title"> {{ $t("titleTag") }}</span>
      <el-tooltip
        :content="$t('tooltipTitle')"
        placement="top"
      >
        <i class="fas fa-question-circle my-auto"></i>
      </el-tooltip>
    </div>
    <div class="seo-scores">
      <div class="element seo-score">
        <el-progress
          class="mini-dashboard"
          type="dashboard"
          :percentage="ownScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div class="text">
          {{ $t("myScore") }}
        </div>
      </div>
      <el-divider
        direction="vertical"
        class="vertical-divider"
      />
      <div class="element seo-score">
        <el-progress
          class="mini-dashboard"
          type="dashboard"
          :percentage="serpScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div class="text">
          {{ $t("serp") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ownScore: Number,
    serpScore: Number,
  },
  data() {
    return {
      colors: [
        { color: "#FF0000", percentage: 20 },
        { color: "#FFCC00", percentage: 40 },
        { color: "#4ECDC4", percentage: 60 },
        { color: "#66FF00", percentage: 80 },
        { color: "#008000", percentage: 100 },
      ],
    };
  },
  methods: {
    format(percentage) {
      return percentage;
    },
  },
};
</script>

<style lang="scss" scoped>
.seo-scores {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;

  .vertical-divider {
    height: unset;
  }

  .element {
    flex: 1;
  }

  .seo-score {
    text-align: center;

    .text {
      text-align: center;
      font-size: 0.9rem;
      word-break: break-word;
    }

    .mini-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 0.8rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}
.category-title {
  font-weight: bold;
}
</style>

<i18n>
{
    "en": {
        "titleTag": "Title tag",
        "serp": "SERP",
        "myScore": "My score",
        "tooltipTitle":"To improve your score, try to reserve your best keywords for this tag. You can also try to change the location of your keyword within it."
    },
    "fr": {
        "titleTag": "Balise Title",
        "serp": "SERP",
        "myScore": "Mon score",
        "tooltipTitle":"Pour améliorer votre score, essayez de réserver vos meilleurs mots clés pour cette balise. Vous pouvez aussi tenter de modifier l'emplacement de votre mot clé au sein de celle-ci."
    },
    "it": {
        "titleTag": "Tag del titolo",
        "serpe": "SERP",
        "myScore": "Il mio punteggio",
        "tooltipTitle":"Per migliorare il tuo punteggio, prova a riservare le tue migliori parole chiave per questo tag. Puoi anche provare a cambiare la posizione della tua parola chiave al suo interno."
    },
    "es": {
        "titleTag": "Etiqueta de título",
        "serp": "SERP",
        "myScore": "Mi puntuación",
        "tooltipTitle":"Para mejorar su puntaje, intente reservar sus mejores palabras clave para esta etiqueta. También puede intentar cambiar la ubicación de su palabra clave dentro de ella."
    },
    "de": {
        "titleTag": "Titel-Tag",
        "serp": "SERP",
        "myScore": "Meine Punktzahl",
        "tooltipTitle":"Um Ihre Punktzahl zu verbessern, versuchen Sie, Ihre besten Keywords für dieses Tag zu reservieren. Sie können auch versuchen, die Position Ihres Keywords darin zu ändern."
    },
    "nl": {
        "titleTag": "Titeltag",
        "serp": "SERP",
        "myScore": "Mijn score",
        "tooltipTitle":"Als u uw score wilt verbeteren, kunt u proberen uw beste zoekwoorden voor deze tag te reserveren. U kunt ook proberen de locatie van uw zoekwoord erin te wijzigen."   
    }
}
</i18n>
