<template>
  <el-date-picker
    v-model="period"
    :format="datePickerFormat"
    align="center"
    :picker-options="pickerOptions"
  >
  </el-date-picker>
</template>

<script>
import { DateTime } from "luxon";
import { dateToDateTimeUTC } from "@/javascripts/dateHelpers";

export default {
  props: {
    value: String,
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
    period: {
      get() {
        if (this.value) {
          return this.value;
        }
      },
      set(period) {
        if (period) {
          const offset = period.getTimezoneOffset();
          period = new Date(period.getTime() - offset * 60 * 1000);
          this.$emit("input", period.toISOString().split("T")[0]);
        } else {
          this.$emit("input", null);
        }
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "period": "Period",
    "to": "To",
    "start_date": "Start date",
    "end_date": "End date"
  },
  "fr": {
    "period": "Période",
    "to": "à",
    "start_date": "Date de début",
    "end_date": "Date de fin"
  },
  "de": {
    "period": "Zeitraum",
    "to": "Zu",
    "start_date": "Startdatum",
    "end_date": "Endtermin"
  }
}
</i18n>
