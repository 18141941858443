import { Node } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

const avoidDoubleBrOnPaste = Node.create({
  name: "avoidDoubleBrOnPaste",
  inline: true,
  group: "inline",
  priority: 10001,

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("DoubleBr"),
        props: {
          // Replace all <br> tag by <p> empty paragraph
          // ProseMirror will create a single <br class="ProseMirror-trailingBreak">
          // to each <br> existing and wrap them into <p>
          transformPastedHTML(html) {
            return html.replace(/\<br\s*\/>/g, "<p>$&</p>");
          },
        },
      }),
    ];
  },
});

export default avoidDoubleBrOnPaste;

// clipboardTextSerializer(slice) {} -> Modify copy behavior
