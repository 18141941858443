<template>
  <div>
    <div class="grid">
      <ag-grid-vue
        :domLayout="'autoHeight'"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :embedFullWidthRows="true"
        :resizable="true"
        :sorting="true"
        :filter="true"
        :enableRangeSelection="true"
        :localeTextFunc="localeTextFunc"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        @grid-ready="onGridReady"
        @first-data-rendered="adjustGrid"
        @row-data-changed="adjustGrid"
        :context="context"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const AlertsRepository = RepositoryFactory.get("alerts");
import { format } from "date-fns";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
import { agGridMixin } from "@/mixins/agGridMixin";

import PriorityRenderer from "@/pages/Alerts/PriorityRenderer";
import DetailsRenderer from "@/pages/Alerts/DetailsRenderer";
import ActionsRenderer from "@/pages/Alerts/ActionsRenderer";

export default {
  mixins: [agGridMixin],
  props: {
    accountId: Number,
    alertsJson: Object,
  },
  components: {
    AgGridVue,
    PriorityRenderer,
    DetailsRenderer,
    ActionsRenderer,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: this.alertsJson.data,
      gridApi: null,
      columnApi: null,
    };
  },

  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
      this.gridApi.sizeColumnsToFit();
    },
    adjustGrid() {
      if (this.gridApi) this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("priority"),
          width: 120,
          field: "attributes.priority",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "lowerThan",
          },
          cellRendererFramework: "PriorityRenderer",
        },
        {
          headerName: this.$i18n.t("category"),
          field: "attributes.category",
          minWidth: 100,
          filter: "agTextColumnFilter",
          cellRenderer: this.categoryRenderer,
        },
        {
          headerName: this.$i18n.t("target"),
          minWidth: 150,
          filter: "agTextColumnFilter",
          valueGetter: this.targetRendererValue,
        },
        {
          headerName: this.$i18n.t("description"),
          field: "attributes.data.message",
          minWidth: 300,
          width: 300,
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("details"),
          field: "attributes",
          minWidth: 100,
          width: 100,
          filter: "agTextColumnFilter",
          cellRendererFramework: "DetailsRenderer",
        },
        {
          headerName: this.$i18n.t("actions"),
          field: "attributes",
          minWidth: 200,
          width: 200,
          filter: "agTextColumnFilter",
          cellRendererFramework: "ActionsRenderer",
        },
      ];
    },
    categoryRenderer(params) {
      switch (params.value) {
        case "page_monitoring":
          return this.$i18n.t("page_monitoring");
        case "robots_monitoring":
          return this.$i18n.t("robots_monitoring");
        case "config":
          return this.$i18n.t("config_monitoring");
        default:
          return "";
      }
    },
    targetRendererValue(params) {
      switch (params.data.attributes.category) {
        case "page_monitoring": {
          if (params.data.attributes.googleResultUrl) {
            return params.data.attributes.googleResultUrl;
          } else if (params.data.attributes.keywordText) {
            return params.data.attributes.keywordText;
          }
        }
        case "robots_monitoring":
          return "/robots.txt";
        case "config": {
          return params.data.attributes.data.config;
        }
        default:
          return "";
      }
    },
    updateAlertStatus(alert, status) {
      AlertsRepository.updateAlertStatus(this.accountId, alert.id, status)
        .then((data) => {
          this.remove(alert);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remove(alert) {
      for (const [i, value] of this.rowData.entries()) {
        if (value.attributes.id == alert.id) {
          this.rowData.splice(i, 1);
          break;
        }
      }
    },
  },
  beforeMount() {
    this.context = {
      componentParent: this,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";
.filter-tasks-popover {
  width: 300px;
  height: 600px;
  overflow: scroll;

  .filter-item {
    padding: 10px;
    margin-bottom: 0.5rem;
    border-radius: $--border-radius-base;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: $--color-primary;
    }

    &.active {
      color: #fff;
      background-color: $--color-primary;
    }

    .user {
      display: inline-block;
      flex: 0 0 auto;
      margin-left: auto;
      color: $--color-text-primary;
      background-color: $--color-info-light;
      border-radius: 25em;
      height: 28px;
      width: 28px;
      line-height: 28px;
      text-align: center;
      font-weight: 500;
    }
  }
}
.archived-tasks-popover {
  height: 600px;
  overflow: scroll;

  .no-archived-tasks-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: $--color-info;
    }
  }
}
</style>
<style scoped lang="scss">
@import "@/styles/variables";

.actions {
  margin-bottom: 1rem;
  text-align: right;
}

.board {
  margin-bottom: 2rem;
}

.task-dialog {
  ::v-deep .el-dialog {
    max-width: 760px;
    width: inherit;

    .el-dialog__header {
      color: $--color-text-secondary;

      .el-dialog__title {
        font-size: 1.5rem;
        margin-bottom: 0.25rem;
      }

      .el-input {
        width: 90%;
      }

      .list-name {
        text-decoration: underline;
      }
    }
  }

  ::v-deep .el-dialog__body {
    padding: 15px 20px 30px;
  }
}
</style>

<i18n>
{
  "en": {
    "actions": "Actions",
    "category": "Category",
    "description": "Description",
    "details": "Details",
    "config_monitoring": "Configuration",
    "page_monitoring": "Page monitoring",
    "robots_monitoring": "Robots.txt monitoring",
    "detail": "Detail",
    "priority": "Priority",
    "target": "Target"
  },
  "fr": {
    "actions": "Actions",
    "category": "Catégorie",
    "description": "Description",
    "details": "Détails",
    "config_monitoring": "Configuration",
    "page_monitoring": "Surveillance de page",
    "robots_monitoring": "Surveillance du robots.txt",
    "detail": "Détail",
    "priority": "Priorité",
    "target": "Cible"
  },
  "de": {
    "actions": "Aktionen",
    "category": "Kategorie",
    "description": "Beschreibung",
    "details": "Einzelheiten",
    "config_monitoring": "Aufbau",
    "page_monitoring": "Seitenüberwachung",
    "robots_monitoring": "Überwachung von robots.txt",
    "detail": "Einzelhandel",
    "priority": "Priorität",
    "target": "Ziel"
  }
}
</i18n>
