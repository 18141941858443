<template>
  <div>
    <div>
      <el-input-number v-model="contentLength"> </el-input-number>
    </div>
    <div v-if="keyword">
      <div v-if="metaContentsData.contentMedian || isLoading">
        <span> {{ $t("serpMedian") }} </span>
        <span v-loading="isLoading"
          >{{ metaContentsData.contentMedian }}
          <span class="ml-2">
            {{ $t("calculatedAt", { date: formattedDateLocale }) }}
          </span>
        </span>
      </div>
      <div v-else>
        {{ $t("noDataForKeyword") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { metaContentsMixin } from "@/mixins/metaContentsMixin";

export default {
  mixins: [metaContentsMixin],
  props: {
    value: Number,
    keyword: [Object, String, Array],
  },
  data() {
    return {
      contentLengthMedian: null,
      isLoadingContentOptimization: false,
      isLoading: false,
    };
  },
  methods: {
    fetchContentsMeta() {
      if (this.keyword && this.keyword.text) {
        this.isLoading = true;
        const request = {
          text: this.keyword.text,
          locationId: this.keyword.locationId,
          searchEngineParameters: {
            isoCountryCode: this.defaultSearchEngine.isoCountryCode,
            device: this.defaultSearchEngine.device,
            isoLanguageCode: this.defaultSearchEngine.isoLanguageCode,
          },
          rangeDate: {
            start: this.lastMonday,
            end: this.lastMonday,
          },
          frequency: !!this.keyword.update_frequency
            ? this.keyword.update_frequency
            : "DAILY",
        };
        this.fetchPositionAndPagesUrlsAsync(request);
      }
    },
  },
  computed: {
    contentLength: {
      get() {
        return this.value;
      },
      set(contentLength) {
        this.$emit("input", contentLength);
      },
    },
    ...mapState(["study"]),
    defaultSearchEngine() {
      return this.study.searchEngines.find(
        (searchEngine) => searchEngine.id == this.study.defaultSearchEngineId
      );
    },
    formattedDateLocale() {
      if (this.lastMonday) {
        let date = this.lastMonday.split("-");
        let year = date[0];
        let month = date[1];
        let day = date[2];
        return new Date(year, month - 1, day).toLocaleDateString(
          this.$i18n.locale
        );
      }
    },
  },
  watch: {
    async keyword() {
      await this.fetchContentsMeta();
    },
  },
  async mounted() {
    await this.fetchContentsMeta();
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "serpMedian": "SERP Median: ",
    "calculatedAt": "Calculated at: {date}",
    "noDataForKeyword": "No data for this keyword"
  },
  "fr": {
    "serpMedian": "Médiane de la SERP : ",
    "calculatedAt": "Calculé le: {date}",
    "noDataForKeyword": "Pas de données pour ce mot clé"
  },
  "de": {
    "serpMedian": "SERP-Median:",
    "calculatedAt": "Berechnet am: {date}",
    "noDataForKeyword": "Keine Daten für dieses Keyword"
  }
}
</i18n>
