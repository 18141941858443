<template>
  <div>
    <el-form
      :model="form"
      label-width="150px"
    >
      <el-form-item :label="$t('first_name')">
        <el-input v-model="form.firstName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('last_name')">
        <el-input v-model="form.lastName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('email')">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item :label="$t('phone')">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button
        plain
        @click="closeDialog"
        >{{ $t("cancel") }}</el-button
      >
      <el-button
        plain
        type="primary"
        @click="submitForm"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.closeDialog();
      this.$emit("submit", this.form);
      this.resetForm();
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    resetForm() {
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.phone = "";
    },
  },
};
</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "first_name": "First name",
    "last_name": "Last name",
    "email": "Email",
    "phone": "Phone",
    "cancel": "Cancel",
    "confirm": "Confirm"
  },
  "fr": {
    "first_name": "Prénom",
    "last_name": "Nom",
    "email": "Email",
    "phone": "Téléphone",
    "cancel": "Annuler",
    "confirm": "Confirmer"
  },
  "de": {
    "first_name": "Vorname",
    "last_name": "Name",
    "email": "Email",
    "phone": "Telefon",
    "cancel": "Stornieren",
    "confirm": "Bestätigen"
  }
}
</i18n>
