<template>
  <div
    class="custom-container"
    v-loading="exportingBacklinks"
  >
    <el-tooltip
      placement="top"
      transition="el-fade-in"
    >
      <div slot="content">{{ $t("download_explanation") }}</div>
      <el-button
        type="primary"
        @click="exportBacklinks('csv')"
        ><i class="fa fa-file"></i> {{ $t("download_in_csv") }}</el-button
      >
    </el-tooltip>
    <el-tooltip
      placement="top"
      transition="el-fade-in"
    >
      <div slot="content">{{ $t("download_explanation") }}</div>
      <el-button
        type="primary"
        @click="exportBacklinks('xlsx')"
        ><i class="far fa-file-excel"></i>
        {{ $t("download_in_xls") }}</el-button
      >
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    studyId: Number,
    competitorId: Number,
    competitorUrl: String,
  },
  components: {},
  data() {
    return {
      exportingBacklinks: false,
    };
  },
  methods: {
    exportBacklinks(format) {
      this.exportingBacklinks = true;

      let attributes = {};
      attributes.urls = [{ url: this.competitorUrl, type: "DOMAIN" }];
      attributes.scope = "ONE_PER_DOMAIN";
      attributes.limit = 500;

      let requestBacklinksData = {};
      requestBacklinksData.attributes = attributes;
      requestBacklinksData.type = "URL_BACKLINK_REQUEST";

      let requestExportBacklinks = {};
      requestExportBacklinks.data = requestBacklinksData;

      this.$api
        .post(
          `/netlinking/urls/backlinks`,
          { ...requestExportBacklinks },
          { headers: { "Content-Type": "application/vnd.api+json" } }
        )
        .then((response) => {
          const data = response.data.data[0].attributes.urlBacklinkData.map(
            (backlink) => {
              return {
                sourceUrl: backlink.sourceUrl.url,
                anchorText: backlink.anchorText,
                linkType: backlink.linkType,
                sourceUrlTrustFlow: backlink.sourceUrl.metrics.trustFlow,
                sourceUrlCitationFlow: backlink.sourceUrl.metrics.citationFlow,
                sourceDomainTrustFlow: backlink.sourceDomain.metrics.trustFlow,
                sourceDomainCitationFlow:
                  backlink.sourceDomain.metrics.citationFlow,
                sourceDomainDomainAuthority:
                  backlink.sourceDomain.metrics.domainAuthority,
                sourceDomainReferringDomains:
                  backlink.sourceDomain.metrics.referringDomains,
                targetUrl: backlink.targetUrl.url,
                targetUrlTrustFlow: backlink.targetUrl.metrics.trustFlow,
                targetUrlCitationFlow: backlink.targetUrl.metrics.citationFlow,
                noFollow: backlink.noFollow,
              };
            }
          );

          let csv = this.convertToCSV(data);

          const link = document.createElement("a");
          if (format == "xlsx") {
            link.setAttribute(
              "href",
              "data:text/xls;charset=utf-8,%EF%BB%BF" + encodeURI(csv)
            );
            link.setAttribute(
              "download",
              "backlinks_" + this.competitorUrl + ".xls"
            );
          } else if (format == "csv") {
            link.setAttribute(
              "href",
              "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv)
            );
            link.setAttribute(
              "download",
              "backlinks_" + this.competitorUrl + ".csv"
            );
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.exportingBacklinks = false;
        })
        .catch((error) => {
          this.exportingBacklinks = false;
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail_export"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
    convertToCSV(items) {
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");

      return csv;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.custom-container {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "download_in_csv": "Download in CSV",
    "download_in_xls": "Download in XLS",
    "download_explanation": "Only the first 500 backlinks are available for download, contact us if you need more !",
    "fail_export": "We have encountered a problem"
  },
  "fr": {
    "download_in_csv": "Télécharger au format CSV",
    "download_in_xls": "Télécharger au format XLS",
    "download_explanation": "Seuls les 500 premiers backlinks sont disponibles au téléchargement, si vous avez besoin de davantage de résultats, vous pouvez nous contacter via le chat en bas à droite de votre écran.",
    "fail_export": "Nous avons rencontré un problème"
  },
  "de": {
    "download_in_csv": "Download im CSV-Format",
    "download_in_xls": "Download im XLS-Format",
    "download_explanation": "Nur die ersten 500 Backlinks stehen zum Download zur Verfügung, wenn Sie weitere Ergebnisse benötigen, können Sie uns über den Chat unten rechts auf Ihrem Bildschirm kontaktieren.",
    "fail_export": "Wir sind auf ein Problem gestoßen"
  }
}
</i18n>
