<template>
  <div>
    <a
      target="_blank"
      :href="`https://${params.value}`"
      >{{ params.value }}</a
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.host {
  color: #28a745;
}
</style>
