<template>
  <div v-if="params.data">
    <span v-if="!params.data.isImportable">
      <div
        ref="eWrapper"
        class="marginRight ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
        role="presentation"
      >
        <input
          ref="eInput"
          class="ag-input-field-input ag-checkbox-input"
          type="checkbox"
        />
      </div>
      {{ params.value }}
      <el-tooltip :content="$t('alreadyImported')">
        <span
          class="isImported"
          v-if="!params.data.isImportable"
        >
          <i class="fas fa-check"></i>
        </span>
      </el-tooltip>
    </span>
    <span v-else>
      {{ params.value }}
    </span>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

.isImported {
  color: $--color-success;
}
.hideSelection {
  height: 46px;
  display: flex;
  align-items: center;
  flex: none;
}
.marginRight {
  margin-right: 24px;
  opacity: 0.3;
}
</style>
<i18n>
{
  "en": {
    "alreadyImported": "Already imported"
  },
  "fr": {
    "alreadyImported": "Déjà importé"
  },
  "de": {
    "alreadyImported": "Bereits importiert"
  }
}
</i18n>
