<template>
  <div>
    <span
      :class="color"
      style="font-weight: bold; font-size: 1.1rem"
      >{{ params.data.seoScore }}</span
    >
    <span v-if="!isNaN(params.data.seoScore)">/ 100</span>
  </div>
</template>

<script>
export default {
  computed: {
    color() {
      if (isNaN(this.params.data.seoScore)) return;
      if (this.params.data.seoScore < 20) return "very-low";
      if (this.params.data.seoScore < 40) return "low";
      if (this.params.data.seoScore < 60) return "medium";
      if (this.params.data.seoScore < 80) return "good";
      return "very-good";
    },
  },
};
</script>
<style lang="scss" scoped>
.very-low {
  color: #ff0000;
}
.low {
  color: #ffcc00;
}
.medium {
  color: #4ecdc4;
}
.good {
  color: #66ff00;
}
.very-good {
  color: #008000;
}
</style>
