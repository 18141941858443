<template>
  <div>
    <div class="export-button">
      <el-button
        size="mini"
        @click="exportData"
        ><i class="fas fa-file-excel"></i> {{ $t("excel_export") }}</el-button
      >
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :sorting="true"
      :filter="true"
      :floatingFilter="true"
      rowSelection="multiple"
      :suppressRowClickSelection="true"
      :suppressCellSelection="true"
      :suppressContextMenu="true"
      :pagination="true"
      :paginationPageSize="400"
      :cacheBlockSize="400"
      :localeTextFunc="localeTextFunc"
      @pagination-changed="onPaginationChanged"
      :suppressPaginationPanel="true"
      rowModelType="serverSide"
      @grid-ready="onGridReady"
      @first-data-rendered="adjustGrid"
      @row-data-changed="adjustGrid"
      :context="context"
    >
    </ag-grid-vue>
    <div class="grid-pagination">
      <span id="numberEntities">{{ firstIndex }} - {{ lastIndex }}</span>
      <el-divider
        direction="vertical"
        class="divider"
      ></el-divider>
      <el-button
        size="mini"
        @click="firstPage()"
        :disabled="firstPageDisabled"
        ><i class="fas fa-angle-double-left"></i
      ></el-button>
      <el-button
        size="mini"
        @click="previousPage()"
        :disabled="firstPageDisabled"
        ><i class="fas fa-angle-left"></i
      ></el-button>
      <span
        class="value-page"
        id="lbCurrentPage"
        >Page {{ pageNumber }}</span
      >
      <el-button
        size="mini"
        @click="nextPage()"
        :disabled="nextPageDisabled"
        ><i class="fas fa-angle-right"></i>
      </el-button>
      <el-button
        id="lastPage"
        @click="lastPage()"
        size="mini"
        :disabled="lastPageDisabled || nextPageDisabled"
        ><i class="fas fa-angle-double-right"></i
      ></el-button>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { agGridMixin } from "@/mixins/agGridMixin";
import { gridColumnsToAPITranslation } from "@/mixins/gridColumnsToAPITranslation";
import { gridPagination } from "@/mixins/gridPagination";
import KeywordRenderer from "@/components/gridRenderers/KeywordRenderer";
import RichResultsRenderer from "@/components/gridRenderers/RichResultsRenderer";
import PageRenderer from "@/components/gridRenderers/PageRenderer";
import PositionRenderer from "@/components/gridRenderers/PositionRenderer";
import CompetitorPositionRenderer from "@/components/gridRenderers/PositionCompetitorRenderer";
import ActionsRenderer from "@/components/gridRenderers/ActionsRenderer";
import OpportunityScoreRenderer from "@/components/gridRenderers/OpportunityScoreRenderer";
import CompetitorPageRenderer from "@/components/gridRenderers/CompetitorPageRenderer";

import { RepositoryFactory } from "@/services/repositoryFactory";
import { formatDateWithDashes } from "@/javascripts/formatDate";

const RemoveKeywordsRepository = RepositoryFactory.get("removeKeywords");
const KeywordPagesRepository = RepositoryFactory.get("keywordPages");

export default {
  mixins: [agGridMixin, gridPagination, gridColumnsToAPITranslation],
  props: {
    request: Object,
    websites: Object,
    preset: String,
    studyId: Number,
    currentUser: Object,
  },
  components: {
    AgGridVue,
    KeywordRenderer,
    PageRenderer,
    RichResultsRenderer,
    PositionRenderer,
    ActionsRenderer,
    OpportunityScoreRenderer,
    CompetitorPositionRenderer,
    CompetitorPageRenderer,
  },
  data() {
    return {
      rowData: null,
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      gridApi: null,
      columnApi: null,
      gridOptions: { onColumnVisible: () => this.adjustGrid() },
      precedentRequest: null,
      snippetValues: [
        "Images",
        "Videos",
        "Map",
        "Answer box",
        "Carousel",
        "People also ask",
        "Knowledge graph",
        "Local pack",
        "Google review",
        "Shopping",
        "Twitter",
        "Top stories",
        "Google flights",
        "Jobs",
        "Paid",
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();

      var fakeServer = this.createFakeServer();
      var datasource = this.createServerSideDatasource(
        this,
        fakeServer,
        this.request,
        this.websites,
        this.preset,
        this.studyId
      );
      params.api.setServerSideDatasource(datasource);
    },
    updateData() {
      var fakeServer = this.createFakeServer();
      var datasource = this.createServerSideDatasource(
        this,
        fakeServer,
        this.request,
        this.websites,
        this.preset,
        this.studyId
      );
      this.gridApi.setServerSideDatasource(datasource);
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 100,
          filter: "agTextColumnFilter",
          cellRendererFramework: "KeywordRenderer",
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filterParams: {
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$i18n.t("tags"),
          field: "tags",
          width: 125,
          valueFormatter: (params) => params.value.join("|"),
          filter: "agTextColumnFilter",
          hide: true,
        },
        {
          headerName: this.$i18n.t("rich_results"),
          suppressMenu: true,
          field: "richResults",
          minWidth: 100,
          maxWidth: 150,
          filter: "agSetColumnFilter",
          keyCreator: (params) => {
            if (params.value == null) {
              return [""];
            } else {
              return Object.keys(params.value).map((x) =>
                x.replace(/_/g, " ").toLowerCase()
              );
            }
          },
          filterParams: {
            suppressAndOrCondition: true,
            values: this.snippetValues,
            applyButton: true,
            newRowsAction: "keep",
          },
          cellRendererFramework: "RichResultsRenderer",
          sorting: false,
        },
        {
          headerName: this.$i18n.t("page"),
          field: "page",
          minWidth: 100,
          filter: "agTextColumnFilter",
          cellRendererFramework: "PageRenderer",
          cellClass: (params) => {
            if (params.value == null) return "muted";
          },
          filterParams: {
            suppressAndOrCondition: true,
            newRowsAction: "keep",
            applyButton: true,
          },
          sorting: false,
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          width: 125,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          sort: "desc",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$i18n.t("position"),
          field: "position",
          width: 125,
          valueGetter: (params) =>
            params.data.position ? params.data.position : 101,
          valueFormatter: positionFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
            filterOptions: [
              "equals",
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "inRange"
            ]
          },
          cellRendererFramework: "PositionRenderer",
        },
        {
          headerName: this.$i18n.t("estimatedTraffic"),
          field: "estimatedTraffic",
          width: 125,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          hide: true,
        },
        {
          headerName: this.$i18n.t("position_competitor"),
          field: "competitorPosition",
          width: 125,
          valueGetter: (params) =>
            params.data.competitorPosition
              ? params.data.competitorPosition
              : 101,
          valueFormatter: positionFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          cellRendererFramework: "CompetitorPositionRenderer",
        },

        {
          headerName: this.$i18n.t("competitorUrl"),
          field: "competitorUrl",
          minWidth: 100,
          cellClass: (params) => {
            if (params.value == null) return "muted";
          },
          sorting: false,
          hide: true,
          filter: false,
          cellRendererFramework: "CompetitorPageRenderer",
        },
        {
          headerName: this.$i18n.t("opportunity_score"),
          field: "opportunityScore",
          width: 150,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          cellRendererFramework: "OpportunityScoreRenderer",
        },
        {
          headerName: this.$i18n.t("actions"),
          headerClass: "ag-centered-header",
          minWidth: 130,
          maxWidth: 160,
          cellRendererFramework: "ActionsRenderer",
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ];
    },
    exportData() {
      const params = {
        skipHeader: false,
        columnKeys: [
          "keyword",
          "tags",
          "page",
          "volume",
          "position",
          "competitorPosition",
          "competitorUrl",
        ],
        onlySelected: false,
        fileName: "Competitor keyword export",
        sheetName: "Keywords",
        processCellCallback: (params) => {
          if (params.value && params.column.colDef.field.includes("position")) {
            if (params.value > 100) {
              return "> 100";
            } else {
              return params.value;
            }
          } else {
            if (params.value && params.column.colId === "tags") {
              return params.value.join("|");
            }
            return params.value;
          }
        },
      };
      this.gridApi.exportDataAsExcel(params);
    },
    deleteKeyword(keywordId) {
      RemoveKeywordsRepository.createRemoveKeywords(this.studyId, [keywordId])
        .then((data) => {
          this.$message({
            message: this.$i18n.t("delete_keywords_success"),
            type: "success",
            duration: 6000,
          });
          this.$emit("refresh-data");
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("delete_keywords_fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    createTask(keywordId, keywordText) {
      const taskTitle = this.$i18n.t("work_on_keyword", {
        keywordText: keywordText,
      });
      const task = { title: taskTitle, state: "pending", keywordId: keywordId };
      this.$api
        .post(`/studies/${this.studyId}/tasks`, { ...task })
        .then(() => {
          this.$message({
            message: this.$i18n.t("task_created_success"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("task_created_failure"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    updateKeywordPage(keywordId, url, rowNode) {
      KeywordPagesRepository.updatePage(this.studyId, keywordId, url)
        .then((url) => {
          rowNode.setDataValue("assignedPage", url);
          this.gridApi.refreshClientSideRowModel("filter");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showKeyword(keywordId) {
      this.$emit("show-keyword", keywordId);
    },
    showTaskDialog(keywordId, keywordText) {
      this.$emit("show-task", { id: keywordId, text: keywordText });
    },
    deselectAllRows() {
      this.gridApi.deselectAll();
    },
    shouldResetRowSelection(request, precedentRequest) {
      var result = {};
      let tempRequest = { ...request };
      let tempPrecedentRequest = { ...precedentRequest };
      if (tempRequest.offset === tempPrecedentRequest.offset) {
        if (
          JSON.stringify(tempRequest) === JSON.stringify(tempPrecedentRequest)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        delete tempRequest.limit;
        delete tempRequest.offset;
        delete tempPrecedentRequest.limit;
        delete tempPrecedentRequest.offset;
        if (
          JSON.stringify(tempRequest) === JSON.stringify(tempPrecedentRequest)
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    createFakeServer() {
      return {
        getData: function (
          context,
          options,
          request,
          websites,
          preset,
          studyId
        ) {
          let requestedRows;
          let lastRow;
          let requestToSend = context.createRequest(
            options,
            request,
            websites,
            preset
          );
          if (context.precedentRequest != null) {
            if (
              context.shouldResetRowSelection(
                requestToSend,
                context.precedentRequest
              )
            ) {
              context.deselectAllRows();
            }
          }
          context.precedentRequest = requestToSend;

          return context.$api
            .post(`/studies/${studyId}/positions/index`, requestToSend)
            .then((response) => {
              const data = response.data;
              requestedRows = data.data.map((keyword) => {
                return {
                  id: keyword.id,
                  keyword: keyword.text,
                  page: keyword.position ? keyword.position.url : null,
                  assignedPage: keyword.positionAssigned
                    ? keyword.positionAssigned.url
                    : null,
                  volume: parseInt(keyword.volume),
                  position: keyword.position ? keyword.position.position : null,
                  pastPosition: keyword.pastPosition
                    ? keyword.pastPosition.position
                    : null,
                  richResults: keyword.serpResultType,
                  tags: keyword.tags,
                  opportunityScore: keyword.opportunityScore,
                  status: keyword.status,
                  estimatedTraffic: keyword.estimatedTraffic,
                  competitorUrl:
                    keyword.competitorPosition &&
                    keyword.competitorPosition.position
                      ? keyword.competitorPosition.position.url
                      : null,
                  competitorPosition:
                    keyword.competitorPosition &&
                    keyword.competitorPosition.position
                      ? keyword.competitorPosition.position.position
                      : null,
                  pastCompetitorPosition:
                    keyword.competitorPosition &&
                    keyword.competitorPosition.pastPosition
                      ? keyword.competitorPosition.pastPosition.position
                      : null,
                  richResultsCompetitor:
                    keyword.competitorPosition &&
                    keyword.competitorPosition.richResults
                      ? keyword.competitorPosition.richResults
                      : null,
                };
              });
              lastRow = getLastRowIndex(options, requestedRows);
              if (data.data.length === 0) {
                return {
                  success: false,
                };
              }
              return {
                success: true,
                rows: requestedRows,
                lastRow: lastRow,
              };
            });
        },
      };
    },
    createServerSideDatasource(
      context,
      server,
      request,
      websites,
      preset,
      studyId
    ) {
      return {
        getRows: function (params) {
          server
            .getData(
              context,
              params.request,
              request,
              websites,
              preset,
              studyId
            )
            .then((response) => {
              setTimeout(function () {
                if (response.success) {
                  params.successCallback(response.rows, response.lastRow);
                } else {
                  params.parentNode.gridApi.showNoRowsOverlay();
                  params.failCallback();
                }
              }, 500);
            });
        },
      };
    },
    createRequest(options, request, websites, preset) {
      let requestApi = {
        offset: options.startRow,
        limit: options.endRow,
        date: formatDateWithDashes(request.endDate),
        pastDate: formatDateWithDashes(request.startDate),
        frequency: request.frequency === "DAY" ? "DAILY" : "WEEKLY",
        searchEngineParameters: { device: request.searchEngine.device },
        competitorUrl: { url: websites.competitor.domain, type: "DOMAIN" },
      };
      if (request.tagsGroups && request.tagsGroups.length > 0) {
        let tempTagsGroups = this.request.tagsGroups.slice();
        tempTagsGroups.forEach((tagsGroup, index, object) => {
          if (tagsGroup.length === 0) {
            object.splice(index, 1);
          }
        });
        if (tempTagsGroups.length > 0) {
          requestApi.tagsGroups = tempTagsGroups;
        }
      }
      switch (preset) {
        case "ALL_KEYWORDS":
          break;
        case "ONLY_COMPETITOR":
          requestApi.competitorPosition = {
            value: 10,
            type: "LESS_THAN_OR_EQUAL",
          };
          requestApi.position = { value: 10, type: "GREATER_THAN" };
          break;
        case "ONLY_YOU":
          requestApi.competitorPosition = { value: 10, type: "GREATER_THAN" };
          requestApi.position = { value: 10, type: "LESS_THAN_OR_EQUAL" };
          break;
        case "BOTH":
          requestApi.competitorPosition = {
            value: 10,
            type: "LESS_THAN_OR_EQUAL",
          };
          requestApi.position = { value: 10, type: "LESS_THAN_OR_EQUAL" };
          break;
      }

      if (options.sortModel.length > 0) {
        requestApi.sortBy = {};
        requestApi.sortBy.fieldName = options.sortModel[0].colId
          .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
          .toUpperCase();
        requestApi.sortBy.order = options.sortModel[0].sort.toUpperCase();
      }
      if (options.filterModel != null) {
        Object.keys(options.filterModel).forEach((column, index) => {
          let filter = {};
          if (options.filterModel[column].filterType !== "set") {
            filter.type = this.getFilterTypeForApi(
              options.filterModel[column].type
            );

            if (
              options.filterModel[column].filterType === "number" &&
              options.filterModel[column].type === "inRange"
            ) {
              filter.min = options.filterModel[column].filter;
              filter.max = options.filterModel[column].filterTo;
            } else {
              filter.value = options.filterModel[column].filter;
            }
          }
          switch (column) {
            case "volume":
              requestApi.volume = filter;
              break;
            case "position":
              requestApi.position = filter;
              break;
            case "competitorPosition":
              requestApi.competitorPosition = filter;
            case "opportunityScore":
              requestApi.opportunityScore = filter;
              break;
            case "page":
              requestApi.page = filter;
              break;
            case "keyword":
              requestApi.keyword = filter;
              break;
            case "richResults":
              requestApi.serpResultTypes = options.filterModel[
                column
              ].values.map((value) => upperSnakeCase(value));
              break;
          }
        });
      }
      return requestApi;
    },
  },
  beforeMount() {
    this.context = {
      studyId: this.studyId,
      showKeyword: this.showKeyword,
      updateKeywordPage: this.updateKeywordPage,
      deleteKeyword: this.deleteKeyword,
      showTaskDialog: this.showTaskDialog,
      currentUser: this.currentUser,
    };
  },
  watch: {
    request: {
      handler: function (val, oldVal) {
        this.updateData();
      },
      deep: true,
    },
    preset() {
      this.updateData();
    },
  },
};

function upperSnakeCase(str) {
  const strArr = str.split(" ");
  const snakeArr = strArr.reduce((acc, val) => {
    return acc.concat(val.toUpperCase());
  }, []);
  return snakeArr.join("_");
}
function getLastRowIndex(options, results) {
  if (!results) return undefined;
  var currentLastRow = options.startRow + results.length;
  return currentLastRow < options.endRow ? currentLastRow : undefined;
}

let positionFormatter = (params) => {
  if (params.value < 101) {
    return params.value;
  } else {
    return "> 100";
  }
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
.grid-pagination {
  margin-top: 1rem;
  text-align: right;

  .divider {
    width: 2px;
  }

  .value-page {
    margin-right: 0.7rem;
    margin-left: 0.7rem;
  }
}

::v-deep .ag-paging-row-summary-panel:nth-child(-n + 1) {
  display: none;
}

.export-button {
  text-align: right;
}

::v-deep .rich-results {
  i {
    margin-right: 0.25rem;
  }
}
</style>

<i18n>
{
  "en": {
    "estimatedTraffic": "Estimated traffic",
    "excel_export": "Export Excel",
    "keyword": "Keyword",
    "tags": "Tags",
    "rich_results": "Snippets",
    "page": "Page",
    "volume": "Volume",
    "position": "My rank",
    "opportunity_score": "Opportunity score",
    "actions": "Actions",
    "position_competitor": "Competitor",
    "add_tags_success": "Tags were added successfully.",
    "add_tags_fail": "Adding tags failed.",
    "remove_tags_success": "Tags were removed successfully.",
    "remove_tags_fail": "Removing tags failed.",
    "delete_keywords_confirmation": "Are you sure you want to delete these keywords ?",
    "delete_keywords_success": "Keywords are being deleted, it may take a few minutes",
    "delete_keywords_fail": "Keywords deletion failed.",
    "export_keywords_success": "Keywords were successfully exported, an email was sent to you with the export file.",
    "export_keywords_fail": "Keywords export failed.",
    "work_on_keyword": "Work on keyword: %{keywordText}",
    "task_created_success": "Task created successfully",
    "task_created_failure": "Task creation failed",
    "to": "to",
    "of": "of",
    "no_rows_to_show": "No rows to show"
  },
  "fr": {
    "estimatedTraffic": "Trafic estimé",
    "excel_export": "Export Excel",
    "keyword": "Mot clé",
    "tags": "Groupes",
    "rich_results": "Snippets",
    "page": "Page",
    "volume": "Volume",
    "position": "Ma position",
    "opportunity_score": "Score d'opportunité",
    "actions": "Actions",
    "position_competitor": "Concurrent",
    "add_tags_success": "Les groupes ont été ajoutés avec succès.",
    "add_tags_fail": "L'ajout de groupes a échoué.",
    "remove_tags_success": "Les groupes ont été retirés avec succès.",
    "remove_tags_fail": "Le retrait de groupes a échoué.",
    "delete_keywords_confirmation": "Êtes-vous sûr de vouloir supprimer ces mots clés ?",
    "delete_keywords_success": "La suppression des mots-clés est en cours, cette opération peut prendre quelques minutes.",
    "delete_keywords_fail": "La suppression des mots clés a échoué.",
    "export_keywords_success": "Les mots clés ont été exporté avec succès, un email vous a été envoyé avec le fichier d'export.",
    "export_keywords_fail": "L'export de mots clés a échoué.",
    "work_on_keyword": "Travailler le mot clé : %{keywordText}",
    "task_created_success": "Tâche créée avec succès",
    "task_created_failure": "La création de la tâche a échoué",
    "to": "à",
    "of": "sur",
    "no_rows_to_show": "Pas de données à montrer..."
  },
  "de": {
    "estimatedTraffic": "Geschätzter Verkehr",
    "excel_export": "Excel exportieren",
    "keyword": "Stichwort",
    "tags": "Gruppen",
    "rich_results": "Ausschnitte",
    "page": "Buchseite",
    "volume": "Volumen",
    "position": "Meine Position",
    "opportunity_score": "Opportunity-Score",
    "actions": "Aktionen",
    "position_competitor": "Gleichzeitig",
    "add_tags_success": "Die Gruppen wurden erfolgreich hinzugefügt.",
    "add_tags_fail": "Gruppen konnten nicht hinzugefügt werden.",
    "remove_tags_success": "Die Gruppen wurden erfolgreich entfernt.",
    "remove_tags_fail": "Gruppen konnten nicht entfernt werden.",
    "delete_keywords_confirmation": "Möchten Sie diese Keywords wirklich entfernen?",
    "delete_keywords_success": "Keywords werden gerade gelöscht, dieser Vorgang kann einige Minuten dauern.",
    "delete_keywords_fail": "Keywords konnten nicht entfernt werden.",
    "export_keywords_success": "Die Keywords wurden erfolgreich exportiert, Sie haben eine E-Mail mit der Exportdatei erhalten.",
    "export_keywords_fail": "Export der Keywords fehlgeschlagen.",
    "work_on_keyword": "Bearbeiten Sie das Schlüsselwort: %{keywordText}",
    "task_created_success": "Aufgabe erfolgreich erstellt",
    "task_created_failure": "Aufgabenerstellung fehlgeschlagen",
    "to": "Zu",
    "of": "An",
    "no_rows_to_show": "Keine Daten zum Anzeigen..."
  }
}
</i18n>
