<template>
  <div v-if="studyLinks.netlinking">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["studyLinks"]),
  },
};
</script>

<i18n>
{
  "en": {},
  "fr": {},
  "de": {}
}
</i18n>
