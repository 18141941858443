<template>
  <div>
    <el-dropdown
      style="margin-right: 10px"
      :hide-on-click="false"
      trigger="click"
      size="small"
      :disabled="gridColumns"
    >
      <el-button
        @click="openList()"
        size="small"
      >
        <i class="fas fa-cog"></i> {{ $t("manageColumns") }}
        <span
          v-if="gridColumns"
          class="activatedColumns"
          >{{ numberOfColumns }}</span
        >
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <el-checkbox @change="(value) => selectAll(value)">
            {{ $t("selectAll") }}
          </el-checkbox>
        </el-dropdown-item>
        <el-dropdown-item
          v-for="column in gridColumns"
          :key="column.colId"
        >
          <el-checkbox
            v-model="column.visible"
            @change="(value) => onCheckChange(value, column.colId)"
          >
            {{ $t(column.headerName) }}
          </el-checkbox>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    disabledBulkButton: Boolean,
    data: Array,
  },
  data() {
    return {
      gridColumns: this.data,
    };
  },
  methods: {
    openList() {
      this.$emit("open-column-visible-list");
    },
    onCheckChange(isChecked, colId) {
      this.$emit("update-grid-columns", {
        column: colId,
        isVisible: isChecked,
      });
    },
    selectAll(isChecked) {
      this.onCheckChange(isChecked, "all");
    },
  },
  computed: {
    numberOfColumns() {
      if (this.data) {
        let displayedColumnLength = this.data.filter(
          (column) => column.visible
        ).length;
        return displayedColumnLength + "/" + this.data.length;
      }
    },
  },
  mounted() {
    this.openList();
    this.gridColumns = this.data;
  },
  watch: {
    data() {
      this.gridColumns = this.data;
    },
  },
};
</script>

<style>
.add-backlink {
  text-align: end;
}
.el-dropdown-menu__item:not(.is-disabled):hover,
.el-dropdown-menu__item:focus {
  background-color: transparent;
  color: #6376b7;
}
</style>
<i18n>
{
  "en": {
    "selectAll": "Select all",
    "writingType": "Redaction type",
    "manageColumns": "Manage columns",
    "keyword": "Keyword",
    "contentLength": "# of words",
    "status": "Status",
    "responsible": "Owner",
    "seoScore": "SEO Score",
    "expectedDeliveryDate": "Expected delivery date",
    "deliveryDate": "Delivery date",
    "publishDate": "Publish date",
    "version": "Version",
    "pageType": "Type of page",
    "name": "Name",
    "quality": "Quality",
    "domain": "Domain",
    "DA": "DA",
    "TF": "TF",
    "CF": "CF",
    "topical": "Topical TF",
    "DR": "DR",
    "trafic": "Traffic",
    "sourceDomain": "Domain",
    "backlinkType": "Quality",
    "contents": "Contents",
    "actions": "Actions",
    "client": "Client",
    "bulkChange": "Bulk Change",
    "sourceUrl": "source URL"
  },
  "fr": {
    "selectAll": "Tout sélectionner",
    "writingType": "Type de rédaction",
    "manageColumns": "Gérer les colonnes",
    "keyword": "Mot clé",
    "contentLength": "Nb. de mots",
    "status": "Statut",
    "responsible": "Responsable",
    "seoScore": "Score SEO",
    "expectedDeliveryDate": "Date prévue de livraison",
    "deliveryDate": "Date de livraison",
    "publishDate": "Date de publication",
    "version": "Version",
    "pageType": "Type de page",
    "actions": "Actions",
    "name": "Nom",
    "quality": "Qualité",
    "domain": "Domaine",
    "DA": "DA",
    "TF": "TF",
    "CF": "CF",
    "topical": "Topical TF",
    "DR": "DR",
    "trafic": "Trafic",
    "sourceDomain": "Domaine",
    "backlinkType": "Qualité",
    "contents": "Contenus",
    "client": "Client",
    "bulkChange": "Changement Multiple",
    "sourceUrl": "URL Source"
  },
  "de": {
    "selectAll": "Wählen Sie Alle",
    "writingType": "Art des Schreibens",
    "manageColumns": "Spalten verwalten",
    "keyword": "Stichwort",
    "contentLength": "Nummer. von Wörtern",
    "status": "Status",
    "responsible": "Verantwortlich",
    "seoScore": "Bewerten Sie SEO",
    "expectedDeliveryDate": "Voraussichtliches Lieferdatum",
    "deliveryDate": "Liefertermin",
    "publishDate": "Veröffentlichungsdatum",
    "version": "Ausführung",
    "pageType": "Seitentyp",
    "actions": "Aktionen",
    "name": "Name",
    "quality": "Qualität",
    "domain": "Domain",
    "DA": "UND",
    "TF": "TF",
    "CF": "CF",
    "topical": "Aktuelle TF",
    "DR": "DR",
    "trafic": "Verkehr",
    "sourceDomain": "Domain",
    "backlinkType": "Qualität",
    "contents": "Inhalt",
    "client": "Klient",
    "bulkChange": "Mehrfache Änderung",
    "sourceUrl": "URL-Quelle"
  }
}
</i18n>
