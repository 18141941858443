<template>
  <div>
    <search-form @submit="updateRequest"></search-form>
    <metrics
      v-if="data"
      :data="data"
    ></metrics>
    <el-card
      v-if="data"
      shadow="never"
      v-loading="isLoading"
    >
      <grid
        :data="data.keywordsBroad"
        :account="account"
        :tags="tags"
      ></grid>
    </el-card>
    <el-card
      v-else-if="isLoading"
      v-loading="isLoading"
      class="loading-card"
    />
  </div>
</template>

<script>
import { keywordsSearchMixin } from "@/mixins/keywordsSearchMixin";
import SearchForm from "@/pages/KeywordsSearch/Websites/SearchForm";
import Metrics from "@/pages/KeywordsSearch/Shared/Metrics";
import Grid from "@/pages/KeywordsSearch/Websites/Grid";

export default {
  mixins: [keywordsSearchMixin],
  components: {
    SearchForm,
    Metrics,
    Grid,
  },
};
</script>
<style lang="scss" scoped>
.loading-card {
  height: 300px;
}
</style>

<i18n>
{
  "en": {
    "loading": "Loading",
    "create_search_fail": "An error occurred",
    "no_data": "No result, try to broaden your search and check the spelling of the keyword"
  },
  "fr": {
    "loading": "Chargement en cours",
    "create_search_fail": "An error occurred",
    "no_data": "Pas de résultat, essayez d'élargir votre recherche et vérifiez l'orthographe du mot-clé"
  },
  "de": {
    "loading": "Wird geladen",
    "create_search_fail": "Ein Fehler ist aufgetreten",
    "no_data": "Kein Ergebnis, erweitern Sie Ihre Suche und überprüfen Sie die Schreibweise des Schlüsselworts"
  }
}
</i18n>
