<template>
  <div>
    <app-introduction
      :title="$t('campaignManager')"
      :user="currentUser"
    >
      <template
        v-slot:right
        v-if="currentUser.is_admin || currentUser.company_group_id == 8"
      >
        <el-switch
          v-model="toggleIsAdmin"
          inactive-text="Admin"
        >
        </el-switch>
      </template>
    </app-introduction>
    <div class="container">
      <add-campaign-button
        v-if="
          contentProjectsModuleResponse && contentProjectsModuleResponse.create
        "
        @click="addCampaignDialogVisible = true"
        :isCampaign="false"
      >
      </add-campaign-button>
      <div
        class="campaigns"
        v-loading="isLoadingCampaigns"
      >
        <campaign
          class="campaign"
          v-for="campaign in campaigns"
          :key="campaign.id"
          :data="campaign.data"
          :links="campaign.links"
          :meta="campaign.meta"
          :isAdmin="toggleIsAdmin"
          @delete-campaign="callAPI($event)"
          @update-campaign="callAPI($event)"
          @update-client-info="callClientInfoAPI($event)"
          @terminate-campaign="callAPI($event)"
          @refresh-meta="(newMeta) => (campaign.meta = newMeta)"
        >
          {{ campaign.name }}
        </campaign>
      </div>
    </div>
    <add-campaign-dialog
      v-if="
        contentProjectsModuleResponse && contentProjectsModuleResponse.create
      "
      :link="contentProjectsModuleResponse.create"
      v-model="addCampaignDialogVisible"
      @add-campaign="callAPI($event)"
      :study-id="study.id"
      :isCampaign="false"
    >
    </add-campaign-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AddCampaignDialog from "@/components/CampaignManager/AddCampaignDialog";
import AddCampaignButton from "@/components/CampaignManager/AddCampaignButton";
import Campaign from "@/pages/ContentManager/Campaign";

import { errorHandlerMixin } from "@/mixins/errorHandlerMixin.js";

export default {
  mixins: [errorHandlerMixin],
  components: {
    AddCampaignDialog,
    AddCampaignButton,
    Campaign,
  },
  props: {
    currentUser: Object,
  },
  data() {
    return {
      isLoadingCampaigns: false,
      campaigns: [],
      toggleIsAdmin: false,
      addCampaignDialogVisible: false,
      contentProjectsModuleResponse: null,
    };
  },
  methods: {
    fetchCampaigns() {
      this.isLoadingCampaigns = true;
      const list = this.contentProjectsModuleResponse.list;
      this.campaigns = [];
      this.$api[list.method.toLowerCase()](list.href)
        .then((response) => {
          this.campaigns = response.data.data;
          this.isLoadingCampaigns = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoadingCampaigns = false;
        });
    },
    callAPI(details) {
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.campaign
      )
        .then((response) => {
          this.fetchCampaigns();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    callClientInfoAPI(clientInfo) {
      this.$api[clientInfo.link.method.toLowerCase()](
        clientInfo.link.href,
        clientInfo.form
      )
        .then((response) => {
          this.$message({
            message: this.$i18n.t("clientInfoUpdated"),
            type: "succes",
            duration: 6000,
          });
          this.fetchCampaigns();
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("clientInfoUpdateFailed"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    callContentProjectsModuleAPI() {
      let module = this.contentProjectsModule;
      this.$api[module.method.toLowerCase()](module.href, {
        headers: { "Accept-Language": this.$i18n.locale },
      })
        .then((response) => {
          this.contentProjectsModuleResponse = response.data.data;
          this.fetchCampaigns();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
    contentProjectsModule() {
      return this.studyLinks["contentProjects"];
    },
  },
  mounted() {
    this.callContentProjectsModuleAPI();
  },
};
</script>

<style lang="scss" scoped>
.container {
  .campaigns {
    .campaign {
      margin-bottom: 1rem;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "campaignManager": "Content project manager",
    "clientInfoUpdated": "Editorial brief was updated successfully",
    "clientInfoUpdateFailed": "the update of the editorial brief failed"
  },
  "fr": {
    "campaignManager": "Gestion de projet de contenu",
    "clientInfoUpdated": "Brief editorial a été modifiée avec succès",
    "clientInfoUpdateFailed": "La Modification du brief editorial a échouée"
  },
  "de": {
    "campaignManager": "Inhaltliches Projektmanagement",
    "clientInfoUpdated": "Kurzes Editorial wurde erfolgreich geändert",
    "clientInfoUpdateFailed": "Bearbeiten des redaktionellen Briefings fehlgeschlagen"
  }
}
</i18n>
