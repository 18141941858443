<template>
  <div class="chart">
    <h3>{{ $t("positions_history") }}</h3>
    <highcharts
      ref="highchart"
      :options="chartOptions"
      :updateArgs="updateArgs"
    ></highcharts>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
import { dateToDateTimeUTC } from "@/javascripts/dateHelpers";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    websitesPositions: Array,
    selectedDate: DateTime,
    date: DateTime,
    pastDate: DateTime,
    tasks: Array,
    backlinks: Array,
    contents: Array,
    globalNotes: Array,
    studyNotes: Array,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          zoomType: "x",
          height: 250,
        },
        title: {
          text: "",
        },
        yAxis: [
          {
            type: "logarithmic",
            title: {
              text: this.$i18n.t("position"),
            },
            endOnTick: false,
            reversed: true,
            minTickInterval: 1,
            min: 1,
          },
          {
            visible: false,
          },
        ],
        xAxis: [
          {
            type: "datetime",
          },
        ],
        tooltip: {
          useHTML: true,
          backgroundColor: "rgba(255,255,255,1)",
          shared: true,
          xDateFormat: "%d/%m/%Y",
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        credits: {
          enabled: false,
        },
        series: [],
      },
    };
  },
  computed: {
    ...mapState(["study"]),
  },
  methods: {
    setSeries() {
      let series = [];
      this.addTasksSeries(series);
      this.addBacklinksSeries(series);
      this.addContentsSeries(series);

      for (const [i, websitePositions] of this.websitesPositions.entries()) {
        for (const [positionnedUrl, value] of Object.entries(
          websitePositions.positionnedUrls
        )) {
          const dataPoints = value.positionsByDates.map((positionByDate) => {
            const date = new Date(positionByDate.date);

            return {
              x: date,
              y: positionByDate.position,
              url:
                positionnedUrl != "notPositionnedUrl"
                  ? positionnedUrl
                  : this.$i18n.t("notPositionned"),
            };
          });

          let id;
          let linkedToId;
          if (
            series.find((serie) => serie.name == websitePositions.domain) ==
            undefined
          ) {
            id = websitePositions.domain;
          } else {
            linkedToId = websitePositions.domain;
          }
          if (websitePositions.domain.includes(this.study.url)) {
            series.push({
              type: "spline",
              id: id,
              name: websitePositions.domain,
              linkedTo: linkedToId,
              data: dataPoints,
              yAxis: 0,
              color: Highcharts.Color(Highcharts.getOptions().colors[0]).get(),
              marker: {
                fillColor: Highcharts.Color(
                  Highcharts.getOptions().colors[0]
                ).get(),
              },
              visible: true,
              cursor: "pointer",
              tooltip: {
                headerFormat: "<table><tr><td>{point.x:%d/%m/%Y}<br><b>{series.name}</b><br></td></tr>",
                pointFormat: "<tr><td>{point.url}<br>{point.y}</td></tr>",
                footerFormat: "</table>"
              },
              point: {
                events: {
                  click: () => {
                    const point = event.point;
                    if (!point.selected) point.select();
                    const date = point.x;
                    this.$emit("update:selected-date", dateToDateTimeUTC(date));
                  },
                },
              },
            });
          } else {
            series.push({
              type: "spline",
              id: id,
              name: websitePositions.domain,
              linkedTo: linkedToId,
              data: dataPoints,
              yAxis: 0,
              color: Highcharts.Color(
                Highcharts.getOptions().colors.slice(1)[i]
              ).get(),
              marker: {
                fillColor: Highcharts.Color(
                  Highcharts.getOptions().colors.slice(1)[i]
                ).get(),
              },
              visible: false,
              tooltip: {
                headerFormat: "<table><tr><td>{point.x:%d/%m/%Y}<br><b>{series.name}</b><br></td></tr>",
                pointFormat: "<tr><td>{point.url}<br>{point.y}</td></tr>",
                footerFormat: "</table>"
              },
            });
          }
        }
      }

      this.chartOptions.series = series;
      this.getNotes()
    },
    addTasksSeries(series) {
      const tasksMap = new Map();
      this.tasks
        .map((task) => ({
          ...task,
          completionDate: new Date(task.completionDate),
        }))
        .filter((task) => task.status === "COMPLETED" && task.completionDate)
        .filter((task) => task.completionDate >= this.pastDate.toJSDate())
        .filter((task) => task.completionDate <= this.date.toJSDate())
        .forEach((task) => {
          let tasksArray = [];
          if (tasksMap.has(task.completionDate.getTime())) {
            tasksArray = tasksMap.get(task.completionDate.getTime());
          }
          tasksArray.push(task);
          tasksMap.set(task.completionDate.getTime(), tasksArray);
        });
      const tasksPoints = Array.from(tasksMap).map((entry) => {
        return {
          x: entry[0],
          y: 0,
          tasks: this.formatTasks(entry[1]),
        };
      });

      series.push({
        type: "scatter",
        name: this.$i18n.t("tasks"),
        marker: {
          fillColor: "red",
          symbol: "triangle",
        },
        data: tasksPoints,
        yAxis: 1,
        tooltip: {
          headerFormat: "<table><tr><td><b>{series.name}</b></td></tr>",
          pointFormat: "<tr><td>{point.x:%d/%m/%Y}</td></tr><br/><br/><tr><td>{point.tasks}</td></tr>",
          footerFormat: "</table>",
        },
      });
    },
    addBacklinksSeries(series) {
      let backlinksGroupedByDates = {};
      this.backlinks.forEach((backlink) => {
        const date = backlink.publishedDate;
        if (backlinksGroupedByDates[date]) {
          backlinksGroupedByDates[date].push(backlink);
        } else {
          backlinksGroupedByDates[date] = [backlink];
        }
      });

      const backlinksPoints = Object.entries(backlinksGroupedByDates)
        .map(([backlinksDate, backlinks]) => {
          return {
            x: new Date(backlinksDate).getTime(),
            y: 0,
            backlinks: backlinks,
          };
        })
        .filter(
          (point) =>
            point.x >= this.pastDate.toJSDate() &&
            point.x <= this.date.toJSDate()
        );
      const publicationOfBacklink = this.$t("publicationOfBacklink");
      const keyword = this.$t("keyword");
      const urlTarget = this.$t("urlTarget");
      const locale = this.$i18n.locale;
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      series.push({
        type: "scatter",
        name: this.$i18n.t("netlinking"),
        marker: {
          fillColor: "blue",
          symbol: "triangle",
        },
        data: backlinksPoints,
        yAxis: 1,
        tooltip: {
          headerFormat: "<table><tr><td><b>{series.name}</b></td></tr>",
          pointFormatter: function () {
            let output =
              "<tr><td>" + new Date(this.x).toLocaleDateString(locale, options) + "<br><br>";
            this.backlinks.forEach((backlink) => {
              output +=
                "<b>" +
                publicationOfBacklink +
                " - " +
                backlink.sourceDomain +
                "</b><br>" +
                keyword +
                ": <b>" +
                backlink.keyword.text +
                "</b><br>" +
                urlTarget +
                ": <b>" +
                backlink.targetUrl +
                "</b><br><br></td></tr>";
            });

            return output;
          },
        },
        footerFormat: "</table>"
      });
    },
    addContentsSeries(series) {
      let contentsGroupedByDates = {};
      this.contents.forEach((content) => {
        const date = content.data.publishDate;
        if (contentsGroupedByDates[date]) {
          contentsGroupedByDates[date].push(content.data);
        } else {
          contentsGroupedByDates[date] = [content.data];
        }
      });
      const contentsPoints = Object.entries(contentsGroupedByDates)
        .map(([contentsDate, contents]) => {
          return {
            x: new Date(contentsDate).getTime(),
            y: 0,
            contents: contents,
          };
        })
        .filter(
          (point) =>
            point.x >= this.pastDate.toJSDate() &&
            point.x <= this.date.toJSDate()
        );
      const publicationOfContent = this.$t("publicationOfContent");
      const keyword = this.$t("keyword");
      const urlTarget = this.$t("urlTarget");
      const locale = this.$i18n.locale;
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      series.push({
        type: "scatter",
        name: this.$i18n.t("content"),
        marker: {
          fillColor: "green",
          symbol: "triangle",
        },
        data: contentsPoints,
        yAxis: 1,
        tooltip: {
          headerFormat: "<table><tr><td><b>{series.name}</b><br></td></tr>",
          pointFormatter: function (a) {
            let output =
              new Date(this.x).toLocaleDateString(locale, options) + "<br><br>";
            this.contents.forEach((content) => {
              output +=
                "<b>" +
                publicationOfContent +
                "</b><br>" +
                keyword +
                ": <b>" +
                content.keyword.text +
                "</b><br>" +
                urlTarget +
                ": <b>" +
                content.url +
                "</b><br><br>";
            });

            return output;
          },
        },
        footerFormat: "</table>"
      });
    },
    formatTasks(tasks) {
      return tasks
        .map((task) => this.formatTaskType(task.type) + "<b>" + task.title)
        .join("<br/><br/>");
    },
    formatTaskType(type) {
      let icon = "";
      if (type === "CONTENT") {
        icon = "fas fa-align-left";
      } else if (type === "TECHNICAL") {
        icon = "fas fa-cogs";
      } else if (type === "NETLINKING") {
        icon = "fas fa-project-diagram";
      } else if (type === "UX") {
        icon = "fas fa-drafting-compass";
      }

      if (!icon) {
        return "";
      } else {
        return '<i class="' + icon + '"></i> ';
      }
    },
    initNotes(isGlobal) {
      const googleUpdateTrad = this.$i18n.t("googleUpdate")
      const notesTrad = this.$i18n.t("notes")
      const symbol = isGlobal ? `url(/assets/google.png)` : 'triangle'

      const notes = {
        name: isGlobal ? this.$i18n.t("googleUpdate") : this.$i18n.t("notes"),
        data: [],
        yAxis: 1,
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<table><tr>\
            <th colspan="3" style="border-bottom: 1px solid silver">\
              <span style="font-size: 14px">{series.name}</span><br>{point.key}\
            </th></tr>',
          pointFormatter: function () {
            let notes = "";
            let globalNotes = "";
            const activeSeriesNames = this.series.chart.series
              .filter(series => series.visible)
              .map(series => series.name);
            const globalNotesHeader = activeSeriesNames.find((name) => name === googleUpdateTrad)
            const notesHeader = activeSeriesNames.find((name) => name === notesTrad)

            if (!this.titles) return "";
            for (let i = 0; i < this.titles.length; i++) {
              let marginBottom = "margin-bottom: 4px; display: inline-block";

              if (i + 1 >= this.titles.length)
                marginBottom = ''
              if (globalNotesHeader && this.isGlobal[i]) {
                globalNotes += `<b style="font-size: 12px; ${marginBottom}">${this.titles[i]}</b> ${this.comments[i]}<br>`;
              } else if (notesHeader && !this.isGlobal[i]) {
                notes += `<b style="font-size: 12px; ${marginBottom}">${this.titles[i]}</b> ${this.comments[i]}<br>`;
              }
            }
            if (globalNotes && notes) {
              // matter of order to not have note type twice, one in header the other in the body
              if (this.series.name === globalNotesHeader)
                return `<tr><td>${globalNotes}</td></tr>
              <tr style="height: 4px" />
              <tr style="border-bottom: 1px solid silver; font-size: 14px">
                <th>${notesHeader}</th>
              </tr>
              <tr><td>${notes}</td></tr>`;
              else
                return `<tr><td>${notes}</td></tr><tr style="height: 4px" />
              <tr style="border-bottom: 1px solid silver; font-size: 14px">
                <th>${globalNotesHeader}</th>
              </tr>
              <tr><td>${globalNotes}</td></tr>`;
            }
            return `<tr><td>${globalNotes + notes}</td></tr>`
          },
          footerFormat: "</table>"
        },
        type: 'scatter',
        marker: {
          enabled: true,
          symbol: symbol,
          height: 18,
          width: 18,
          fillColor: '#FF7BF9'
        }
      };
      return notes;
    },
    buildNotes() {
      const globalNotes = this.initNotes(true)
      const studyNotes = this.initNotes(false)
      const rangeDate = {
          start: new Date(this.pastDate.toJSDate()),
          end: new Date(this.date.toJSDate()),
      }

      this.studyNotes.forEach((noteData) => {
        const time = new Date(noteData.date).getTime()
        const note = studyNotes.data.find((note) => note.date === noteData.date)

        if (time < rangeDate.start || time > rangeDate.end)
          return
        if (note) {
          // put every note with same date in the same note
          note.titles.push(noteData.title)
          note.comments.push(noteData.comment ? noteData.comment : "")
          note.isGlobal.push(false)
        } else {
          studyNotes.data.push({
            x: time, y: 0, allowPointSelect: true, shared: false,
            titles: [noteData.title], comments: [noteData.comment ? noteData.comment : ""],
            date: noteData.date, isGlobal: [false]
          })
        }
      })
      this.globalNotes.forEach((noteData) => {
        const time = new Date(noteData.date).getTime()
        const note = globalNotes.data.find((note) => note.date === noteData.date)
        const existInStudyNote = studyNotes.data.find((note) => note.date === noteData.date)

        if (time < rangeDate.start || time > rangeDate.end)
          return
        if (existInStudyNote) {
          // put every note with same date in the same note
          existInStudyNote.titles.push(noteData.title)
          existInStudyNote.comments.push(noteData.comment ? noteData.comment : "")
          existInStudyNote.isGlobal.push(true)
        }
        if (note) {
          // put every note with same date in the same note
          note.titles.push(noteData.title)
          note.comments.push(noteData.comment ? noteData.comment : "")
          note.isGlobal.push(true)
        } else {
          globalNotes.data.push({
            x: time, y: 0, allowPointSelect: true, shared: false,
            titles: [noteData.title], comments: [noteData.comment ? noteData.comment : ""],
            date: noteData.date, isGlobal: [true]
          })
        }
      })
      return {
        studyNotes: studyNotes, 
        globalNotes: globalNotes,
      }
    },
    getNotes() {
      const {studyNotes, globalNotes} = this.buildNotes()

      this.chartOptions.series.push(globalNotes)
      this.chartOptions.series.push(studyNotes)
    },
  },
  watch: {
    websitesPositions() {
      this.setSeries();
    },
    tasks() {
      this.setSeries();
    },
    backlinks() {
      this.setSeries();
    },
    contents() {
      this.setSeries();
    },
    globalNotes() {
      this.setSeries();
    },
    studyNotes() {
      this.setSeries();
    }
  },
  mounted() {
    this.setSeries();
  },
};
</script>

<style scoped lang="scss">
.chart {
  margin-bottom: 2rem;

  h3 {
    font-size: 1.25rem;
  }
}
</style>

<i18n>
{
  "en": {
    "design": "Design",
    "kind": "Kind",
    "netlinking": "Netlinking",
    "tasks": "Tasks",
    "technical": "Technical",
    "positions_history": "Historical ranking",
    "position": "Rank",
    "notPositionned": "Page not positioned",
    "content": "Content",
    "backlink": "Backlink",
    "keyword": "Keyword",
    "urlTarget": "Targetted Url",
    "urlSource": "Source Url",
    "url": "Url",
    "name": "Name",
    "publicationOfBacklink": "Backlink published",
    "publicationOfContent": "Content published",
    "googleUpdate": "Google Update",
    "notes": "Notes"
  },
  "fr": {
    "content": "Contenu",
    "design": "Design",
    "kind": "Genre",
    "netlinking": "Netlinking",
    "tasks": "Tâches",
    "technical": "Technique",
    "positions_history": "Historique des positions",
    "position": "Position",
    "notPositionned": "Page non positionnée",
    "backlink": "Backlink",
    "keyword": "Mot clé",
    "urlTarget": "Page visée",
    "urlSource": "Url source",
    "url": "Url",
    "name": "Nom",
    "publicationOfBacklink": "Backlink publié",
    "publicationOfContent": "Contenu publié",
    "googleUpdate": "Mise à jour Google",
    "notes": "Notes"
  },
  "de": {
    "content": "Inhalt",
    "design": "Design",
    "kind": "Genre",
    "netlinking": "Netzverlinkung",
    "tasks": "Aufgaben",
    "technical": "Technik",
    "positions_history": "Standortgeschichte",
    "position": "Position",
    "notPositionned": "Seite nicht positioniert",
    "backlink": "Rückverlinkung",
    "keyword": "Stichwort",
    "urlTarget": "Zielseite",
    "urlSource": "URL-Quelle",
    "url": "URL",
    "name": "Name",
    "publicationOfBacklink": "Backlink veröffentlicht",
    "publicationOfContent": "Veröffentlichte Inhalte",
    "googleUpdate": "Google-Update",
    "notes": "Notiz"
  }
}
</i18n>
