<template>
  <el-form-item :label="$t('urls')">
    <el-tag
      v-for="url in value"
      :key="url.url"
      size="small"
      closable
      @close="removeUrl(url)"
    >
      {{ url.url }} ({{ getUrlScopeLabel(url.scope)
      }}<template v-if="url.maxRank">, &lt; {{ url.maxRank }}</template
      >)
    </el-tag>
    <el-popover
      placement="bottom-start"
      trigger="click"
      popper-class="url-input-popover"
    >
      <el-form
        :model="urlInput"
        :inline="true"
        label-position="top"
        label-width="100px"
        size="small"
        @keyup.enter.native="addUrl"
      >
        <el-row
          type="flex"
          :gutter="10"
        >
          <el-col :span="8">
            <el-form-item :label="$t('url')">
              <el-input
                v-model="urlInput.url"
                :placeholder="$t('enter_value')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('scope')">
              <el-select
                v-model="urlInput.scope"
                :placeholder="$t('select_scope')"
              >
                <el-option
                  v-for="item in urlScopeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <span slot="label"
                >{{ $t("max_rank") }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('max_rank_explanation')"
                  placement="top"
                >
                  <i class="fas fa-question-circle"></i>
                </el-tooltip>
              </span>
              <el-input-number
                v-model="urlInput.maxRank"
                :placeholder="$t('enter_max_rank')"
                controls-position="right"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-action">
          <el-form-item>
            <el-button
              plain
              type="primary"
              @click="addUrl"
              >{{ $t("add_url") }}</el-button
            >
          </el-form-item>
        </div>
      </el-form>
      <el-button
        class="add-value-button"
        slot="reference"
        >+ {{ $t("add_url") }}</el-button
      >
    </el-popover>
  </el-form-item>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      urlInput: {
        url: "",
        scope: "DOMAIN",
        maxRank: 30,
      },
      urlScopeOptions: [
        { value: "DOMAIN", label: this.$i18n.t("domain") },
        { value: "SUBDOMAIN", label: this.$i18n.t("subdomain") },
        { value: "FOLDER", label: this.$i18n.t("folder") },
        { value: "URL", label: this.$i18n.t("url") },
      ],
    };
  },
  methods: {
    addUrl() {
      let url = this.urlInput;
      if (url) {
        this.value.push(url);
      }
      this.urlInput = {
        url: "",
        scope: "DOMAIN",
        maxRank: 30,
      };
    },
    removeUrl(url) {
      this.value.splice(this.value.indexOf(url), 1);
    },
    getUrlScopeLabel(value) {
      return this.urlScopeOptions.find((o) => o.value === value).label;
    },
  },
};
</script>

<style lang="scss">
.url-input-popover {
  .el-popover__title {
    font-weight: 500;
  }

  ::v-deep label {
    margin-bottom: 0;
  }

  ::v-deep .form-action {
    text-align: right;
  }
}
</style>
<style lang="scss" scoped>
.el-tag {
  margin: 0 0.25rem 0.25rem 0;
}

.add-value-button {
  font-size: 12px;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
}
</style>

<i18n>
{
  "en": {
    "urls": "Urls",
    "value": "Value",
    "enter_value": "Enter value",
    "scope": "Scope",
    "select_scope": "Select scope",
    "max_rank": "Maximum rank",
    "enter_max_rank": "Enter maximum rank",
    "add_url": "Add url",
    "domain": "Domain",
    "subdomain": "Subdomain",
    "folder": "Folder",
    "url": "Url",
    "max_rank_explanation": "Highest position the url is positionned"
  },
  "fr": {
    "urls": "Urls",
    "value": "Valeur",
    "enter_value": "Entrez une valeur",
    "scope": "Portée",
    "select_scope": "Sélectionnez une portée",
    "max_rank": "Rang maximum",
    "enter_max_rank": "Entrez le rang maximum",
    "add_url": "Ajouter url",
    "domain": "Domaine",
    "subdomain": "Sous-domaine",
    "folder": "Dossier",
    "url": "Url",
    "max_rank_explanation": "Position maximum sur laquelle l’url est positionnée"
  },
  "de": {
    "urls": "URLs",
    "value": "Wert",
    "enter_value": "Geben Sie einen Wert ein",
    "scope": "Umfang",
    "select_scope": "Wählen Sie einen Stab aus",
    "max_rank": "Hat maximal geklingelt",
    "enter_max_rank": "Geben Sie den maximalen Rang ein",
    "add_url": "URL hinzufügen",
    "domain": "Domain",
    "subdomain": "Subdomäne",
    "folder": "Fall",
    "url": "URL",
    "max_rank_explanation": "Maximale Position, auf der die URL positioniert ist"
  }
}
</i18n>
