<template>
  <div class="flex-container">
    <el-switch
      v-model="advancedSearch"
      :active-text="$t('advanced')"
      :inactive-text="$t('simple')"
    >
    </el-switch>
    <div class="inputs-sections">
      <el-form
        :model="form"
        size="mini"
        :inline="true"
        label-width="130x"
        class="form"
      >
        <div>
          <el-form-item
            :label="$t('filters')"
            class="filters"
          >
            <el-popover
              placement="bottom"
              width="600"
              trigger="click"
            >
              <div class="filters-tooltip">
                <div
                  v-for="(filter, index) in form.filters"
                  :key="index"
                >
                  <div class="filter-row">
                    <el-select
                      v-model="filter.type"
                      :placeholder="$t('filterType')"
                    >
                      <el-option
                        v-for="item in filtersTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="filter.value"
                      multiple
                      filterable
                      :placeholder="$t('filterValue')"
                    >
                      <el-option
                        v-for="item in filterValueOptionsSearch(filter.type)"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-button @click="removeFilter(filter)">
                      <i class="fas fa-minus"></i>
                    </el-button>
                  </div>
                </div>
                <div class="filter-add">
                  <el-button @click="addFilter">
                    <i class="fas fa-plus"></i>
                    {{ $t("addFilter") }}
                  </el-button>
                </div>
              </div>
              <el-button slot="reference">{{ $t("selectFilters") }}</el-button>
            </el-popover>
            <el-tag
              v-for="item in nonNullFilters"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :type="item.type"
              closable
              size="small"
              @close="removeFilter(item)"
            >
              {{ filtersTypeOptions.find((x) => x.value === item.type).label }}
              : {{ labelNamesOf(item.value, item.type) }}
              <!-- {{ filterValueOptionsSearch(item.type).filter(x => item.value.includes(x.value)) }} -->
            </el-tag>
          </el-form-item>
          <el-form-item :label="$t('period')">
            <el-date-picker
              v-model="form.period"
              type="daterange"
              :range-separator="$t('to')"
              :format="datePickerFormat"
              align="center"
              :start-placeholder="$t('startDate')"
              :end-placeholder="$t('endDate')"
              :picker-options="periodPickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('comparativePeriod')"
            v-if="advancedSearch"
          >
            <el-date-picker
              ref="comparativeDatePicker"
              v-model="form.comparativePeriod"
              type="daterange"
              :range-separator="$t('to')"
              :format="datePickerFormat"
              align="center"
              :start-placeholder="$t('startDate')"
              :end-placeholder="$t('endDate')"
              :picker-options="comparativePeriodPickerOptions"
              @focus="setMainPeriodOnComparativePeriodPicker"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div
          v-if="advancedSearch"
          class="advanced-search"
        >
          <el-form-item
            :label="$t('frequency')"
            class="frequency"
          >
            <div>
              <el-select v-model="form.frequency">
                <el-option
                  v-for="item in frequencyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>

        <el-form-item
          v-if="conversionMonitoringType === 'goals_completions_data'"
          :label="$t('goals')"
          class="goals"
        >
          <div>
            <el-select
              v-model="form.goals"
              multiple
              filterable
              collapse-tags
              :placeholder="$t('selectGoals')"
            >
              <el-option
                v-for="item in goalsOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="action">
      <el-button
        type="primary"
        @click="onSubmit"
        >{{ $t("apply") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  subDays,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  subYears,
} from "date-fns";
import { formatDateWithDashes } from "@/javascripts/formatDate";

export default {
  props: {
    dimensionsData: Array,
    period: Array,
    frequency: String,
    conversionMonitoringType: String,
    accountGoals: Array,
  },
  data() {
    return {
      form: {
        filters: [{ type: "", value: "" }],
        period: this.period,
        comparativePeriod: null,
        frequency: this.frequency,
        goals: [],
      },
      filtersTypeOptions: [],
      activeCollapse: [],
      periodPickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: this.$i18n.t("lastWeek"),
            onClick(picker) {
              let end = startOfWeek(subDays(new Date(), 3));
              let start = subDays(end, 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("lastMonth"),
            onClick(picker) {
              let end = startOfMonth(subDays(new Date(), 3));
              let start = subMonths(end, 1);
              end = subDays(end, 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("last3Months"),
            onClick(picker) {
              let end = startOfMonth(subDays(new Date(), 3));
              let start = subMonths(end, 3);
              end = subDays(end, 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("lastYear"),
            onClick(picker) {
              let end = startOfMonth(subDays(new Date(), 3));
              const start = subYears(end, 1);
              end = subDays(end, 1);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      comparativePeriodPickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: this.$i18n.t("lastPeriod"),
            onClick(picker) {
              const mainPeriodEnd = picker.mainPeriod[1];
              const mainPeriodStart = picker.mainPeriod[0];
              const numberOfDays =
                (mainPeriodEnd - mainPeriodStart) / (1000 * 60 * 60 * 24);
              const end = new Date(mainPeriodEnd);
              end.setDate(mainPeriodEnd.getDate() - numberOfDays - 1);
              const start = new Date(mainPeriodStart);
              start.setDate(mainPeriodStart.getDate() - numberOfDays - 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("previousWeek"),
            onClick(picker) {
              const mainPeriodEnd = picker.mainPeriod[1];
              const mainPeriodStart = picker.mainPeriod[0];
              let end = subWeeks(mainPeriodEnd, 1);
              let start = subWeeks(mainPeriodStart, 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("previousMonth"),
            onClick(picker) {
              const mainPeriodEnd = picker.mainPeriod[1];
              const mainPeriodStart = picker.mainPeriod[0];
              const end = subMonths(mainPeriodEnd, 1);
              const start = subMonths(mainPeriodStart, 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("previousYear"),
            onClick(picker) {
              const mainPeriodEnd = picker.mainPeriod[1];
              const mainPeriodStart = picker.mainPeriod[0];
              const end = subYears(mainPeriodEnd, 1);
              const start = subYears(mainPeriodStart, 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("last52Weeks"),
            onClick(picker) {
              const mainPeriodEnd = picker.mainPeriod[1];
              const mainPeriodStart = picker.mainPeriod[0];
              let start_dow = new Date(mainPeriodStart).getDay();
              let end_dow = new Date(mainPeriodEnd).getDay();
              let end = subYears(mainPeriodEnd, 1);
              let start = subYears(mainPeriodStart, 1);
              while (end.getDay() !== end_dow) {
                end = subDays(end, -1);
              }
              while (start.getDay() !== start_dow) {
                start = subDays(start, -1);
              }
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      frequencyOptions: [
        { value: "day", label: this.$i18n.t("day") },
        { value: "week", label: this.$i18n.t("week") },
        { value: "month", label: this.$i18n.t("month") },
      ],
      goalsOptions: [],
      advancedSearch: false,
    };
  },
  methods: {
    addFilter() {
      this.form.filters.push({ type: "", value: "" });
    },
    removeFilter(filter) {
      const index = this.form.filters.indexOf(filter);
      if (index !== -1) {
        this.form.filters.splice(index, 1);
      }
    },
    onSubmit() {
      const form = {
        filters: this.form.filters,
        period: this.formatPeriod(this.form.period),
        comparativePeriod: this.formatPeriod(this.form.comparativePeriod),
        frequency: this.form.frequency,
        goals: this.form.goals,
      };
      this.$emit("form-update", JSON.parse(JSON.stringify(form)));
    },
    formatPeriod(period) {
      if (period === null || period === undefined) return null;
      return [formatDateWithDashes(period[0]), formatDateWithDashes(period[1])];
    },
    loadDimensionsOptions() {
      // Options for filters type and groupings
      let options = this.dimensionsData.map((dimension) => {
        if (!dimension.name.includes("Dimension")) {
          return {
            label: this.$i18n.t(dimension.name.toLowerCase()),
            value: dimension.name,
          };
        } else {
          return {
            label: dimension.display_name,
            value: dimension.name,
          };
        }
      });
      return options;
    },
    loadDimensionsOptionsAlt() {
      // Options for filters valueIt’s frustrating when even non-subtle “hints” are just them being friendly...
      let options = {};
      this.dimensionsData.map((dimension) => {
        const dimensionOptions = dimension.options.map((dimensionOption) => {
          return {
            label: dimensionOption.display_name,
            value: dimensionOption.name,
          };
        });
        Object.assign(options, { [dimension.name]: dimensionOptions });
      });
      return options;
    },
    filterValueOptionsSearch(filterType) {
      return this.loadDimensionsOptionsAlt()[filterType];
    },
    setMainPeriodOnComparativePeriodPicker() {
      if (this.$refs.comparativeDatePicker.picker) {
        this.$refs.comparativeDatePicker.picker.mainPeriod = this.form.period;
      }
    },
    loadGoalsOptions() {
      let options = this.accountGoals.map((goal) => {
        return {
          label: goal.name,
          value: goal.gid,
        };
      });
      return options;
    },
    labelNamesOf(values, type) {
      let names = [];
      let possibleData = this.filterValueOptionsSearch(type);
      values.forEach(function (element) {
        names.push(possibleData.find((x) => x.value === element).label);
      });
      if (names.toString().length > 50)
        return "[" + names.join(", ").substring(0, 99) + "...]";
      return "[" + names.join(", ") + "]";
    },
  },
  mounted() {
    this.filtersTypeOptions = this.loadDimensionsOptions();
    if (this.accountGoals) {
      this.goalsOptions = this.loadGoalsOptions();
    }
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
    nonNullFilters: function () {
      return this.form.filters.filter((filter) => filter.value.length > 0);
    },
  },
};
</script>

<style lang="scss">
// Global styles to style the elements inside the popover
.filters-tooltip {
  .filter-row {
    margin-bottom: 0.5rem;

    ::v-deep .el-select .el-input__inner {
      width: 250px;
    }
  }

  .filter-add {
    text-align: center;
  }
}
</style>
<style scoped lang="scss">
.flex-container {
  display: flex;
  justify-content: space-between;

  .el-switch {
    height: 28px;
    margin-right: 2rem;
  }

  .inputs-sections {
    flex: 1;
  }

  .el-form {
    .el-form-item {
      margin-right: 2rem;
    }

    .el-date-editor.el-input__inner {
      width: 250px;
    }

    ::v-deep .el-date-editor .el-range-separator {
      width: initial;
    }

    .frequency {
      ::v-deep .el-select .el-input__inner {
        width: 120px;
      }
    }

    .goals {
      ::v-deep .el-select .el-input__inner {
        width: 250px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "country": "Country",
    "device": "Device",
    "brand": "Brand",
    "filters": "Filters",
    "selectFilters": "+ Add",
    "filterType": "Filter type",
    "filterValue": "Filter value",
    "addFilter": "Add filter",
    "period": "Period",
    "comparativePeriod": "Comparative period",
    "startDate": "Start date",
    "endDate": "End date",
    "lastPeriod": "Last period",
    "lastWeek": "Last week",
    "lastMonth": "Last month",
    "last3Months": "Last 3 months",
    "previousWeek": "Previous week",
    "previousMonth": "Previous month",
    "previousYear": "Previous year",
    "to": "To",
    "frequency": "Frequency",
    "day": "Day",
    "week": "Week",
    "month": "Month",
    "goals": "Goals",
    "selectGoals": "Select Goals",
    "apply": "Apply",
    "simple": "Simple",
    "advanced": "Advanced",
    "lastYear": "Last year",
    "last52Weeks": "Last 52 weeks"
  },
  "fr": {
    "country": "Pays",
    "device": "Appareil",
    "brand": "Marque",
    "filters": "Filtres",
    "selectFilters": "+ Ajouter",
    "filterType": "Type de filtre",
    "filterValue": "Valeur du filtre",
    "addFilter": "Ajouter un filtre",
    "period": "Période",
    "comparativePeriod": "Période comparative",
    "startDate": "Date de début",
    "endDate": "Date de fin",
    "lastPeriod": "Période précédente",
    "lastWeek": "Dernière semaine",
    "lastMonth": "Dernier mois",
    "last3Months": "3 derniers mois",
    "previousWeek": "Semaine précédente",
    "previousMonth": "Mois précédent",
    "previousYear": "Année précédente",
    "to": "à",
    "frequency": "Fréquence",
    "day": "Jour",
    "week": "Semaine",
    "month": "Mois",
    "goals": "Objectifs",
    "selectGoals": "Sélectionnez des objectifs",
    "apply": "Appliquer",
    "simple": "Simple",
    "advanced": "Avancé",
    "lastYear": "Dernière année",
    "last52Weeks": "52 semaines précédentes"
  },
  "de": {
    "country": "Zahlt",
    "device": "Gerät",
    "brand": "Marke",
    "filters": "Filter",
    "selectFilters": "+ Hinzufügen",
    "filterType": "Filter Typ",
    "filterValue": "Filterwert",
    "addFilter": "Fügen Sie einen Filter hinzu",
    "period": "Zeitraum",
    "comparativePeriod": "Vergleichszeitraum",
    "startDate": "Startdatum",
    "endDate": "Endtermin",
    "lastPeriod": "Vorherige Periode",
    "lastWeek": "Letzte Woche",
    "lastMonth": "Im vergangenen Monat",
    "last3Months": "letzten 3 Monate",
    "previousWeek": "Letzte Woche",
    "previousMonth": "Im vergangenen Monat",
    "previousYear": "Letztes Jahr",
    "to": "Zu",
    "frequency": "Frequenz",
    "day": "Tag",
    "week": "Woche",
    "month": "Monat",
    "goals": "Ziele",
    "selectGoals": "Ziele auswählen",
    "apply": "Anwenden",
    "simple": "Einfach",
    "advanced": "Vorauszahlung",
    "lastYear": "Letztes Jahr",
    "last52Weeks": "52 vergangene Wochen"
  }
}
</i18n>
