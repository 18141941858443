<template>
  <div class="add-backlink">
    <el-button
      plain
      type="secondary"
      size="small"
      :disabled="disabledBulkButton"
      @click="$emit('open-bulk-change-dialog')"
    >
      <i class="fas fa-edit"></i> {{ $t("bulkChange") }}
    </el-button>

    <el-button
      plain
      type="primary"
      size="small"
      @click="$emit('click')"
    >
      <i class="fas fa-plus"></i> {{ message }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    disabledBulkButton: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.add-backlink {
  text-align: end;
}
.el-dropdown-menu__item:not(.is-disabled):hover,
.el-dropdown-menu__item:focus {
  background-color: transparent;
  color: #6376b7;
}
</style>
<i18n>
{
  "en": {
    "selectAll": "Select all",
    "writingType": "Redaction type",
    "manageColumns": "Manage columns",
    "keyword": "Keyword",
    "contentLength": "# of words",
    "status": "Status",
    "responsible": "Owner",
    "seoScore": "SEO Score",
    "version": "Version",
    "pageType": "Type of page",
    "name": "Name",
    "quality": "Quality",
    "domain": "Domain",
    "DA": "DA",
    "TF": "TF",
    "CF": "CF",
    "topical": "Topical TF",
    "DR": "DR",
    "trafic": "Traffic",
    "sourceDomain": "Domain",
    "backlinkType": "Quality",
    "contents": "Contents",
    "actions": "Actions",
    "client": "Client",
    "bulkChange": "Bulk Change"
  },
  "fr": {
    "selectAll": "Tout sélectionner",
    "writingType": "Type de rédaction",
    "manageColumns": "Gérer les colonnes",
    "keyword": "Mot clé",
    "contentLength": "Nb. de mots",
    "status": "Statut",
    "responsible": "Responsable",
    "seoScore": "Score SEO",
    "version": "Version",
    "pageType": "Type de page",
    "actions": "Actions",
    "name": "Nom",
    "quality": "Qualité",
    "domain": "Domaine",
    "DA": "DA",
    "TF": "TF",
    "CF": "CF",
    "topical": "Topical TF",
    "DR": "DR",
    "trafic": "Trafic",
    "sourceDomain": "Domaine",
    "backlinkType": "Qualité",
    "contents": "Contenus",
    "client": "Client",
    "bulkChange": "Changement Multiple"
  },
  "de": {
    "selectAll": "Wählen Sie Alle",
    "writingType": "Art des Schreibens",
    "manageColumns": "Spalten verwalten",
    "keyword": "Stichwort",
    "contentLength": "Nummer. von Wörtern",
    "status": "Status",
    "responsible": "Verantwortlich",
    "seoScore": "Bewerten Sie SEO",
    "version": "Ausführung",
    "pageType": "Seitentyp",
    "actions": "Aktionen",
    "name": "Name",
    "quality": "Qualität",
    "domain": "Domain",
    "DA": "UND",
    "TF": "TF",
    "CF": "CF",
    "topical": "Aktuelle TF",
    "DR": "DR",
    "trafic": "Verkehr",
    "sourceDomain": "Domain",
    "backlinkType": "Qualität",
    "contents": "Inhalt",
    "client": "Klient",
    "bulkChange": "Mehrfache Änderung"
  }
}
</i18n>
