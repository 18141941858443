<template>
  <div v-if="data">
    <div
      v-if="!empty && !submited"
      class="grid"
    >
      <p>{{ data.length }} {{ $t("successImport") }}</p>
      <ag-grid-vue
        :domLayout="'autoHeight'"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowDataDisplay"
        :embedFullWidthRows="true"
        :resizable="true"
        :sorting="true"
        :filter="true"
        :localeTextFunc="localeTextFunc"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        @grid-ready="onGridReady"
        @first-data-rendered="adjustGrid"
        @row-data-changed="adjustGrid"
      >
      </ag-grid-vue>
      <div class="action">
        <el-button
          type="primary"
          @click="onSubmit()"
          plain
          >{{ $t("import") }}</el-button
        >
      </div>
      <el-divider />
    </div>
    <div
      v-if="!empty && submited"
      class="grid"
    >
      <div v-if="suc">
        <el-alert
          :title="this.$i18n.t('keywordsImported')"
          type="success"
          :closable="false"
          show-icon
        >
        </el-alert>
      </div>
      <div v-else>
        <el-alert
          :title="this.$i18n.t('errorWhileImporting')"
          type="error"
          :closable="false"
          show-icon
        >
        </el-alert>
      </div>
      <el-divider />
    </div>
    <div
      v-if="!emptyW"
      class="grid"
    >
      <el-alert
        :title="this.$i18n.t('warning')"
        type="error"
        :description="rowDataW.length + this.$i18n.t('failedImport')"
        show-icon
        :closable="false"
      >
      </el-alert>
    </div>
    <div v-if="empty && emptyW">
      <el-alert
        :title="this.$i18n.t('warning')"
        type="error"
        :description="this.$i18n.t('failedToRead')"
        show-icon
        :closable="false"
      >
      </el-alert>
    </div>
    <div class="action">
      <el-link @click="redirectToKeywords()"
        >{{ $t("goBack") }} <i class="fas fa-history"></i
      ></el-link>
    </div>
  </div>
</template>

<script>
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
import { agGridMixin } from "@/mixins/agGridMixin";

import TagRenderer from "@/pages/Keywords/Import/ImportExcel/TagRenderer";
import { mapState } from "vuex";

export default {
  mixins: [agGridMixin],
  props: {
    data: Array,
  },
  components: {
    AgGridVue,
    TagRenderer,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: [],
      rowDataW: [],
      gridApi: null,
      columnApi: null,
      empty: false,
      emptyW: false,
      submited: false,
      rowDataDisplay: [],
      rowDataWDisplay: [],
      suc: false,
      err: false,
    };
  },

  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.getRowData();
      this.setColumnDefs();
      this.gridApi.sizeColumnsToFit();
    },
    adjustGrid() {
      if (this.gridApi) this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          field: "text",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("tags"),
          field: "tags",
          filter: "agTextColumnFilter",
          cellRendererFramework: "TagRenderer",
        },
        {
          headerName: this.$i18n.t("page"),
          field: "assignedUrl",
          minWidth: 100,
          filter: "agTextColumnFilter",
          cellRenderer: this.linkRenderer,
        },
        {
          headerName: this.$i18n.t("frequency"),
          field: "frequency",
          width: 100,
          filter: "agTextColumnFilter",
        },
      ];
    },
    getRowData() {
      this.rowData = this.data.map((input) => {
        return {
          text: input.text.trim(),
          tags: input.tags,
          locationId: input.locationId,
          assignedUrl: input.assignedUrl,
          frequency: input.frequency,
          exists: input.exists,
        };
      });
      this.rowData = this.rowData.filter((input) => input.exists);
      this.empty = this.rowData.length == 0;
      this.rowDataDisplay =
        this.rowData.length > 16 ? this.rowData.slice(0, 16) : this.rowData;
      this.rowDataW = this.data.map((input) => {
        return {
          text: input.text.trim(),
          tags: input.tags,
          assignedUrl: input.assignedUrl,
          frequency: input.frequency,
          exists: input.exists,
        };
      });
      this.rowDataW = this.rowDataW.filter(
        (input) => typeof input.exists === "undefined" || !input.exists
      );
      this.emptyW = this.rowDataW.length == 0;
      this.rowDataWDisplay =
        this.rowDataW.length > 16 ? this.rowDataW.slice(0, 16) : this.rowDataW;
    },
    onSubmit() {
      this.$api
        .put(`/studies/${this.study.id}/tracked-keywords`, this.rowData)
        .then((rep) => {
          this.response = rep.data.data;
          this.submited = true;
          this.suc = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.submited = true;
          console.log(error);
          this.err = true;
          this.isLoading = false;
        });
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="${params.value}" target="_blank">${params.value}</a>`;
    },
    redirectToKeywords() {
      window.location.replace(`/a/${this.study.id}/keywords`);
    },
  },
  computed: {
    ...mapState(["study"]),
  },
};
</script>

<style lang="scss" scoped>
.action {
  margin-top: 1rem;
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "keyword": "Keyword",
    "page": "Page",
    "import": "Import",
    "frequency": "Frequency",
    "warning": "Warning",
    "tags": "Tags",
    "goBack": "Go back to my keywords screen",
    "failedImport": " keywords are not in the database and won't be imported",
    "successImport": "keywords will be impacted by your changes",
    "errorWhileImporting": "Error while importing keywords",
    "success": "Success",
    "error": "Error",
    "keywordsImported": "keywords imported",
    "failedToRead": "No keywords detected"
  },
  "fr": {
    "keyword": "Mot-clé",
    "page": "Page associée",
    "import": "Importer",
    "tags": "Tags",
    "warning": "Attention",
    "frequency": "Fréquence",
    "goBack": "Revenir à l'écran mes mots-clés",
    "failedImport": " mots-clés ne font pas partie de la base de donnée et ne seront pas importés",
    "successImport": "mots-clés seront impactés par vos changements",
    "errorWhileImporting": "Erreur lors de l'import des mots-clés",
    "success": "Succès",
    "error": "Erreur",
    "keywordsImported": "mots-clés importés",
    "failedToRead": "Aucun mot-clé récupéré"
  },
  "de": {
    "keyword": "Stichwort",
    "page": "Verwandte Seite",
    "import": "Importeur",
    "tags": "Stichworte",
    "warning": "Aufmerksamkeit",
    "frequency": "Frequenz",
    "goBack": "Gehen Sie zurück zum Bildschirm „Meine Keywords“.",
    "failedImport": "Schlüsselwörter sind nicht Teil der Datenbank und werden nicht importiert",
    "successImport": "Keywords sind von Ihren Änderungen betroffen",
    "errorWhileImporting": "Fehler beim Importieren von Keywords",
    "success": "Schlag",
    "error": "Fehler",
    "keywordsImported": "importierte Schlüsselwörter",
    "failedToRead": "Keine abgerufenen Schlüsselwörter"
  }
}
</i18n>
