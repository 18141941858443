<template>
  <div>
    <app-introduction :title="$t('addKeywords')"></app-introduction>

    <div class="container">
      <div class="row justify-content-around">
        <div class="col-md-3">
          <div class="card shadow-sm my-3">
            <div class="card-body">
              <div class="text-center">
                <p class="text-muted">
                  <i class="far fa-hand-paper fa-3x"></i>
                </p>
                <el-link
                  :href="keywordsImportPath"
                  :underline="false"
                >
                  <el-button type="primary">
                    {{ $t("addManual") }}
                  </el-button>
                </el-link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card shadow-sm my-3">
            <div class="card-body">
              <div class="text-center">
                <p class="text-muted">
                  <i class="far fa-file-excel fa-3x"></i>
                </p>
                <el-link
                  :href="importFilePath"
                  :underline="false"
                >
                  <el-button type="primary">
                    {{ $t("addImport") }}
                  </el-button>
                </el-link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card shadow-sm my-3">
            <div class="card-body">
              <div class="text-center">
                <p class="text-muted">
                  <i class="far fa-hand-paper fa-3x"></i>
                </p>
                <el-link
                  :href="tags"
                  :underline="false"
                >
                  <el-button type="primary">
                    {{ $t("manageMyTags") }}
                  </el-button>
                </el-link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card shadow-sm my-3">
            <div class="card-body">
              <div class="text-center">
                <p class="text-muted">
                  <i class="far fa-file-excel fa-3x"></i>
                </p>
                <el-link
                  :href="keywordsImportExcel"
                  :underline="false"
                >
                  <el-button type="primary">
                    {{ $t("associateKeywordsToPage") }}
                  </el-button>
                </el-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIntroduction from "@/components/AppIntroduction";

export default {
  components: {
    AppIntroduction,
  },
  props: {
    keywordsImportPath: String,
    importFilePath: String,
    keywordsImportExcel: String,
    tags: String,
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "addKeywords": "Manage my keywords",
    "addImport": "Import an Excel file",
    "addManual": "Add / Edit keywords manually",
    "associateKeywordsToPage": "Page to keywords bulk association",
    "manageMyTags": "Manage keywords groups"
  },
  "fr": {
    "addKeywords": "Gérer mes mots clés",
    "addImport": "Importer un fichier Excel",
    "addManual": "Ajouter / Éditer manuellement",
    "associateKeywordsToPage": "Associer des pages aux mots-clés",
    "manageMyTags": "Gérer des groupes de mots-clés"
  },
  "de": {
    "addKeywords": "Meine Keywords verwalten",
    "addImport": "Importieren Sie eine Excel-Datei",
    "addManual": "Manuell hinzufügen / bearbeiten",
    "associateKeywordsToPage": "Verknüpfen Sie Seiten mit Schlüsselwörtern",
    "manageMyTags": "Verwalten Sie Gruppen von Schlüsselwörtern"
  }
}
</i18n>
