<template>
  <div>
    <div v-if="keywordReady">
      <el-progress
        class="print-background"
        :text-inside="true"
        :stroke-width="15"
        :percentage="params.value"
        :color="customColor"
      >
      </el-progress>
    </div>
    <div v-else-if="keywordNoData">
      <span>
        {{ $t("no_data") }}
      </span>
    </div>
    <div v-else>
      <span>
        {{ $t("calculation_in_progress") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    customColor(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
  },
  computed: {
    keywordReady() {
      return this.params.data.status == "READY" || !this.params.data.status;
    },
    keywordNoData() {
      return this.params.data.status == "NO_DATA";
    },
  },
};
</script>

<style lang="scss" scoped>
.el-progress {
  line-height: inherit;
}

@media print {
  .print-background {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>

<i18n>
{
  "en": {
    "calculation_in_progress": "--",
    "no_data": "--"
  },
  "fr": {
    "calculation_in_progress": "--",
    "no_data": "--"
  },
  "de": {
    "calculation_in_progress": "--",
    "no_data": "--"
  }
}
</i18n>
