<template>
  <div class="content">
    <div class="holder">
      <h4 class="title">
        <strong>{{ $t("contents") }}</strong>
      </h4>
      <slot name="entityButton"></slot>
    </div>
    <div>
      <el-button
        style="margin: 20px 0 0 5px"
        class="select-all"
        @click="selectAllRows"
        size="mini"
        type="primary"
        plain
      >
        <i class="fas fa-list"></i>
        {{ $t("selectAll") }}
      </el-button>
    </div>
    <div class="grid">
      <ag-grid-vue
        domLayout="autoHeight"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :gridOptions="gridOptions"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :context="context"
        rowSelection="multiple"
        :localeTextFunc="localeTextFunc"
        :enableRangeSelection="true"
        :copyHeadersToClipboard="false"
        :suppressCopyRowsToClipboard="true"
        :suppressRowClickSelection="true"
        :suppressContextMenu="true"
        :applyColumnDefOrder="true"
        :pagination="true"
        :paginationPageSize="100"
        @pagination-changed="onPaginationChanged"
        :suppressPaginationPanel="true"
        @gridReady="onGridReady"
        @firstDataRendered="adjustGrid"
        @gridSizeChanged="adjustGrid"
        @rowDataChanged="onRowDataUpdated"
        @selection-changed="selectionChanged"
        @filterChanged="onFilterChanged"
      >
      </ag-grid-vue>
    </div>
    <div class="grid-pagination mt-3">
      <span>{{ $t("numberOfContentsPerPage") }}</span>
      <el-select
        v-model="paginationSize"
        @change="(value) => setNewPaginationSize(value)"
        size="mini"
        style="width: 70px"
      >
        <el-option
          v-for="item in paginationSizeOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-divider
        direction="vertical"
        class="divider"
      ></el-divider>
      <span id="numberEntities">{{ firstIndex }} - {{ lastIndex }}</span>
      <el-divider
        direction="vertical"
        class="divider"
      ></el-divider>
      <el-button
        size="mini"
        @click="firstPage()"
        :disabled="firstPageDisabled"
        ><i class="fas fa-angle-double-left"></i
      ></el-button>
      <el-button
        size="mini"
        @click="previousPage()"
        :disabled="firstPageDisabled"
        ><i class="fas fa-angle-left"></i
      ></el-button>
      <span
        class="value-page"
        id="lbCurrentPage"
        >Page {{ pageNumber }}</span
      >
      <el-button
        size="mini"
        @click="nextPage()"
        :disabled="nextPageDisabled"
        ><i class="fas fa-angle-right"></i>
      </el-button>
      <el-button
        id="lastPage"
        @click="lastPage()"
        size="mini"
        :disabled="lastPageDisabled || nextPageDisabled"
        ><i class="fas fa-angle-double-right"></i
      ></el-button>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import { gridPagination } from "@/mixins/gridPagination";
import ActionsSelector from "@/pages/ContentManager/Contents/Renderers/ActionsSelector";
import SeoScoreRenderer from "@/pages/ContentManager/Contents/Renderers/SeoScoreRenderer";
import PublishedUrlLinkRenderer from "@/pages/ContentManager/Contents/Renderers/PublishedUrlLinkRenderer";

import { mapState } from "vuex";

export default {
  mixins: [agGridMixin, gridPagination],
  components: {
    AgGridVue,
    ActionsSelector,
    SeoScoreRenderer,
    PublishedUrlLinkRenderer,
  },
  props: {
    companyId: Number,
    data: Array,
    isAdmin: Boolean,
    selectedFilter: String
  },

  data() {
    return {
      dialogVisible: false,
      rowData: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: { sortable: true, resizable: true },
      paginationSize: 100,
      paginationSizeOptions: [50, 100, 150],
    };
  },
  methods: {
    onFilterChanged() {
      if (this.$parent.isSettingFilter) {
        return;
      }
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel && Object.keys(this.gridApi.getFilterModel(filterModel).length > 0)) {
        this.$emit("save-selected-fitlers", filterModel)
      }
      let isEmptyValues = this.gridApi.getFilterModel().status?.values.length === 0
      
      if (this.gridApi.getFilterModel() && (Object.keys(this.gridApi.getFilterModel()).length === 0 || isEmptyValues)){
        this.$emit("reset-selected-filter");
      }
    },
    onRowDataUpdated() {
      if (this.$parent.savedFilterModel) {
        this.$parent.isSettingFilter = true;
        this.gridApi.setFilterModel(this.$parent.savedFilterModel);
        this.$parent.isSettingFilter = false;
      }
    },
    setFilter(filterValue) {
      let filters = {
        status: {
          filterType: "set",
          values: [this.$t(filterValue.toLowerCase())],
        },
      };
      this.gridApi.setFilterModel(filters);
    },
    reset() {
      this.gridApi.setFilterModel(null);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
      this.$emit('grid-ready', this.gridApi);
    },
    adjustGrid() {
      if (this.columnApi && this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
        this.$emit("grid-columns-controller", this.columnApi.columnController);

        //set the already selected filter before updating the grid
        if(this.selectedFilter)
          this.setFilter(this.selectedFilter)
      }
    },
    mapData() {
      if (this.data && this.data.length > 0) {
        const userDateFormat = this.userLocale == "fr" ? "fr-FR" : "en-GB";
        this.rowData = this.data.map((content) => {
          const data = content.data;
          const text = content.data.text;
          const links = content.links;
          return {
            id: data.id,
            name: data.name ? data.name : "",
            keyword: data.keyword ? data.keyword.text : "",
            contentLength: data.contentLength ? data.contentLength : "-",
            costPerWord: data.costPerWord ? data.costPerWord : null,
            costPerWordWithoutMargin: data.costPerWordWithoutMargin
              ? data.costPerWordWithoutMargin
              : null,
            seoScore:
              text && text.seoScore ? Math.round(text.seoScore * 100) : "-",
            text: text && text ? text.text : null,
            responsible: data.responsible ? data.responsible : "-",
            status: data.contentStatus ? data.contentStatus : "-",
            expectedDeliveryDate: data.expectedDeliveryDate
              ? data.expectedDeliveryDate
              : null,
            deliveryDate: data.deliveryDate ? data.deliveryDate : null,
            publishDate: data.publishDate ? data.publishDate : null,
            publishedUrl: data.url ? data.url : null,
            version: text && text.version ? text.version : 1,
            pageType: data.pageType ? data.pageType : "",
            writingType: data.writingType ? data.writingType : "",
            creationDate: data.creationDate
              ? new Date(data.creationDate).toLocaleString(userDateFormat, {
                  month: "long",
                })
              : "-",
            actions: links,
          };
        });
      } else {
        this.rowData = [];
      }
    },
    setColumnDefs() {
      this.columnDefs = [];
      this.columnDefs.push(
        {
          headerName: this.$i18n.t("creationDate"),
          field: "creationDate",
          minWidth: 120,
          width: 120,
          filter: "agTextColumnFilter",
          hide: true,
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 170,
          width: 170,
          filter: "agTextColumnFilter",
          checkboxSelection: true,
          filterParams: {
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          floatingFilter: true,
        }
      );
      if (this.isAdmin) {
        this.columnDefs.push({
          headerName: this.$i18n.t("name"),
          field: "name",
          minWidth: 100,
          width: 100,
          filter: "agTextColumnFilter",
          hide: true,
          floatingFilter: true,
        });
      }
      this.columnDefs.push(
        {
          headerName: this.$i18n.t("contentLength"),
          field: "contentLength",
          minWidth: 100,
          width: 100,
          filter: "agNumberColumnFilter",
          suppressMenu: true,
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("costPerWord"),
          field: "costPerWord",
          minWidth: 100,
          width: 100,
          filter: "agNumberColumnFilter",
          suppressMenu: true,
          floatingFilter: true,
          cellRenderer: (params) => {
            if (params.value) {
              return this.currencyFloatFormat(params.value);
            } else {
              if (this.$i18n.locale === "fr") return "0 €";
              else return "€0";
            }
          },
          hide: true,
        }
      );
      if (this.isAdmin) {
        this.columnDefs.push({
          headerName: this.$i18n.t("costPerWordWithoutMargin"),
          field: "costPerWordWithoutMargin",
          minWidth: 100,
          width: 100,
          filter: "agNumberColumnFilter",
          suppressMenu: true,
          floatingFilter: true,
          cellRenderer: (params) => {
            if (params.value) {
              return this.currencyFloatFormat(params.value);
            } else {
              if (this.$i18n.locale === "fr") return "0 €";
              else return "€0";
            }
          },
          hide: true,
        });
      }

      this.columnDefs.push(
        {
          headerName: this.$i18n.t("status"),
          field: "status",
          minWidth: 180,
          width: 180,
          filter: "agSetColumnFilter",
          cellRenderer: (params) => {
            return this.$i18n.t(params.value.toLowerCase());
          },
          keyCreator: (params) => {
            return this.$i18n.t(params.value.toLowerCase());
          },
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("responsible"),
          field: "responsible",
          minWidth: 115,
          width: 115,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cellRenderer: (params) => {
            return this.$i18n.t(params.value.toLowerCase());
          },
        },
        {
          headerName: this.$i18n.t("seoScore"),
          field: "seoScore",
          minWidth: 100,
          width: 100,
          filter: "agNumberColumnFilter",
          cellRendererFramework: "SeoScoreRenderer",
          suppressMenu: true,
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("expectedDeliveryDate"),
          field: "expectedDeliveryDate",
          minWidth: 180,
          width: 180,
          suppressMenu: true,
          sortable: true,
          filter: "agTextColumnFilter",
          suppressSizeToFit: true,
          floatingFilter: true,
          cellRenderer: (params) => {
            if (params.value) {
              return new Date(params.value).toLocaleDateString(
                this.$i18n.locale === "en" ? "en-US" : "fr-FR",
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }
              );
            } else {
              return "-";
            }
          },
        },
        {
          headerName: this.$i18n.t("deliveryDate"),
          field: "deliveryDate",
          minWidth: 140,
          width: 140,
          suppressMenu: true,
          sortable: true,
          filter: "agTextColumnFilter",
          suppressSizeToFit: true,
          floatingFilter: true,
          cellRenderer: (params) => {
            if (params.value) {
              return new Date(params.value).toLocaleDateString();
            } else {
              return "-";
            }
          },
        },
        {
          headerName: this.$i18n.t("publishDate"),
          field: "publishDate",
          hide: true,
          minWidth: 140,
          width: 140,
          suppressMenu: true,
          sortable: true,
          filter: "agTextColumnFilter",
          suppressSizeToFit: true,
          floatingFilter: true,
          cellRenderer: (params) => {
            if (params.value) {
              return new Date(params.value).toLocaleDateString();
            } else {
              return "-";
            }
          },
        },
        {
          headerName: this.$i18n.t("publishedUrl"),
          field: "publishedUrl",
          width: 150,
          minWidth: 150,
          hide: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: "PublishedUrlLinkRenderer",
        },
        {
          headerName: this.$i18n.t("writingType"),
          field: "writingType",
          hide: true,
          width: 140,
          suppressMenu: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cellRenderer: (params) => {
            return this.$i18n.t(params.value.toLowerCase());
          },
        },
        {
          headerName: this.$i18n.t("version"),
          field: "version",
          hide: true,
          width: 90,
          suppressMenu: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cellRenderer: (params) => {
            return "V" + params.value;
          },
        },
        {
          headerName: this.$i18n.t("pageType"),
          field: "pageType",
          hide: true,
          width: 170,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cellRenderer: (params) => {
            return this.$i18n.t(params.value.toLowerCase());
          },
        },
        {
          headerName: this.$i18n.t("actions"),
          field: "actions",
          minWidth: 120,
          width: 120,
          cellRendererFramework: "ActionsSelector",
          suppressMenu: true,
          sortable: false,
          filter: false,
          suppressSizeToFit: false,
          floatingFilter: true,
        }
      );
    },
    currencyFloatFormat(value) {
      return this.$options.filters.formatCurrencyFloat(
        value,
        this.$i18n.locale
      );
    },
    editContent(details) {
      this.$emit("show-edit-content-dialog", details);
    },
    deleteContent(details) {
      this.$emit("delete-content", details);
    },
    showTextDialog(content) {
      this.$emit("show-text-dialog", content);
    },
    showBriefDialog(content) {
      this.$emit("show-brief-dialog", content);
    },
    selectionChanged() {
      let selectedRowsFiltered;
      if (
        this.gridApi.getFilterModel() &&
        this.gridApi.getFilterModel().status &&
        this.gridApi.getFilterModel().status.values
      )
        selectedRowsFiltered = this.gridApi
          .getSelectedRows()
          .filter((row) =>
            this.gridApi
              .getFilterModel()
              .status.values.includes(this.$t(row.status.toLowerCase()))
          );
      else selectedRowsFiltered = this.gridApi.getSelectedRows();
      this.$emit("selection-changed-grid", selectedRowsFiltered);
    },
  },
  beforeMount() {
    this.context = {
      editContent: this.editContent,
      deleteContent: this.deleteContent,
      showTextDialog: this.showTextDialog,
      showBriefDialog: this.showBriefDialog,
    };
  },
  computed: {
    ...mapState(["userLocale"]),
  },
  mounted() {
    this.mapData();
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.mapData();
      },
      deep: true,
    },
    isAdmin() {
      this.setColumnDefs();
    },
  },
};
let formatNumber = (number) => {
  if (Number.isInteger(number)) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  } else {
    return number;
  }
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
.grid-pagination {
  margin-top: 1rem;
  text-align: right;

  .divider {
    width: 2px;
  }

  .value-page {
    margin-right: 0.7rem;
    margin-left: 0.7rem;
  }
}

.holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin-top: auto;
  margin-bottom: auto;
}

//min height so we can display fully the filters when there are 0 rows
::v-deep .ag-layout-auto-height {
  min-height: 350px;
}
</style>
<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "name": "Name",
    "writingType": "Writing type",
    "contents": "Contents",
    "keyword": "Keyword",
    "actions": "Actions",
    "seoScore": "SEO Score",
    "status": "Status",
    "draft": "Draft",
    "writing": "Being written",
    "accepted": "Text validated",
    "review": "Being proofread",
    "published": "Text published",
    "modificating": "Being modified",
    "finished_writing": "Writing completed",
    "brief_draft": "Brief in draft",
    "brief_submitted": "Brief to validate",
    "brief_validated": "Brief validated",
    "client_review": "Text to validate",
    "client_modificating": "Change requested",
    "client_finished_writing": "Writing completed",
    "expectedDeliveryDate": "Expected delivery date",
    "deliveryDate": "Delivery date",
    "contentLength": "# of words",
    "responsible": "Owner",
    "smartkeyword": "Smartkeyword",
    "client": "Client",
    "version": "Version",
    "pageType": "Type of page",
    "blog_page": "Blog page",
    "category_page": "Category page",
    "product_page": "Product page",
    "home_page": "Home page",
    "landing_page": "Landing page",
    "numberOfContentsPerPage": "Number of contents per page",
    "content_from_scratch": "Creation of new content",
    "optimization_of_existing_text": "Optimization of existing contents",
    "netlinking_editorial_publication": "Netlinking editorial publication",
    "transposition_of_texts": "Transposition of contents",
    "creationDate": "Month",
    "publishDate": "Publish date",
    "costPerWord": "Cost/Word",
    "costPerWordWithoutMargin": "Cost/Word without margin",
    "selectAll": "Select all displayed contents",
    "publishedUrl": "Published URL"
  },
  "fr": {
    "name": "Nom",
    "writingType": "Type de rédaction",
    "contents": "Contenus",
    "keyword": "Mot clé",
    "actions": "Actions",
    "seoScore": "Score SEO",
    "status": "Statut",
    "draft": "Brouillon",
    "writing": "En cours de rédaction",
    "accepted": "Texte validé",
    "review": "En cours de relecture",
    "published": "Texte publié",
    "modificating": "En cours de modification",
    "finished_writing": "Écriture terminée",
    "brief_draft": "Brief en brouillon",
    "brief_submitted": "Brief à valider",
    "brief_validated": "Brief validé",
    "client_review": "Texte à valider",
    "client_modificating": "Demande de modification",
    "client_finished_writing": "Écriture terminée",
    "expectedDeliveryDate": "Date prévue de livraison",
    "deliveryDate": "Date de livraison",
    "contentLength": "Nb. de mots",
    "responsible": "Responsable",
    "smartkeyword": "Smartkeyword",
    "client": "Client",
    "version": "Version",
    "pageType": "Type de page",
    "blog_page": "Page de blog",
    "category_page": "Page de catégorie",
    "product_page": "Page de produit",
    "home_page": "Page d'accueil",
    "landing_page": "Page d'atterrissage",
    "numberOfContentsPerPage": "Nombre de contenus par page",
    "content_from_scratch": "Création de nouveaux contenus",
    "optimization_of_existing_text": "Optimisation des contenus existants",
    "netlinking_editorial_publication": "Netlinking - publirédactionnel",
    "transposition_of_texts": "Transposition des contenus",
    "creationDate": "Mois",
    "publishDate": "Date de publication",
    "costPerWord": "Prix/Mot",
    "costPerWordWithoutMargin": "Prix/Mot sans marge",
    "selectAll": "Sélectionner tous les contenus de la page",
    "publishedUrl": "URL publiée"
  },
  "de": {
    "name": "Name",
    "writingType": "Art des Schreibens",
    "contents": "Inhalt",
    "keyword": "Stichwort",
    "actions": "Aktionen",
    "seoScore": "Bewerten Sie SEO",
    "status": "Status",
    "draft": "Desorganisiert",
    "writing": "Laufende Redaktion",
    "accepted": "Validierter Text",
    "review": "Wird überprüft",
    "published": "Texte veröffentlicht",
    "modificating": "In Bearbeitung",
    "finished_writing": "Schreiben abgeschlossen",
    "brief_draft": "Kurz im Entwurf",
    "brief_submitted": "Kurz zu validieren",
    "brief_validated": "Kurz validiert",
    "client_review": "Zu validierender Text",
    "client_modificating": "Änderungsanforderung",
    "client_finished_writing": "Schreiben abgeschlossen",
    "expectedDeliveryDate": "Voraussichtliches Lieferdatum",
    "deliveryDate": "Liefertermin",
    "contentLength": "Nummer. von Wörtern",
    "responsible": "Verantwortlich",
    "smartkeyword": "Intelligentes Schlüsselwort",
    "client": "Klient",
    "version": "Ausführung",
    "pageType": "Seitentyp",
    "blog_page": "Blog-Seite",
    "category_page": "Kategorieseite",
    "product_page": "Produktseite",
    "home_page": "Startseite",
    "landing_page": "Zielseite",
    "numberOfContentsPerPage": "Anzahl der Inhalte pro Seite",
    "content_from_scratch": "Erstellung neuer Inhalte",
    "optimization_of_existing_text": "Optimierung bestehender Inhalte",
    "netlinking_editorial_publication": "Netlinking - redaktionelle Werbung",
    "transposition_of_texts": "Umsetzung von Inhalten",
    "creationDate": "Monat",
    "publishDate": "Veröffentlichungsdatum",
    "costPerWord": "Preis/Wort",
    "costPerWordWithoutMargin": "Preis/Wort ohne Marge",
    "selectAll": "Wählen Sie den gesamten Seiteninhalt aus",
    "publishedUrl": "Veröffentlichte URL"
  }
}
</i18n>
