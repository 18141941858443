import Repository from "@/services/repository";

export default {
  fetchPages(studyId, websiteId) {
    return Repository.get(`/a/${studyId}/websites/${websiteId}.json`).then(
      (response) => response.data
    );
  },

  searchPages(studyId, text) {
    return Repository.get(`/a/${studyId}/google_results_page/search.json`, {
      params: {
        searchQuery: text,
      },
    }).then((response) => response.data);
  },
};
