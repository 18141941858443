<template>
  <div>
    <ag-grid-vue
      style="height: 300px"
      class="grid ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :filter="false"
      :rowData="rowData"
      :resizable="true"
      rowSelection="multiple"
      :localeTextFunc="localeTextFunc"
      :suppressRowClickSelection="true"
      :suppressCellSelection="true"
      :suppressContextMenu="true"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
      :context="context"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import KeywordRenderer from "@/components/gridRenderers/KeywordRenderer";
import PositionRenderer from "@/components/gridRenderers/PositionRenderer";

import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
export default {
  mixins: [agGridMixin],
  props: {
    keywordsData: Array,
    studyId: Number,
    startDate: Number,
    endDate: Number,
    topType: String,
  },
  components: {
    AgGridVue,
    KeywordRenderer,
    PositionRenderer,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
      headerHeight: null,
    };
  },
  computed: {
    sortOrder() {
      if (this.topType == "ENTRY") {
        return "desc";
      } else if (this.topType == "EXIT") {
        return "asc";
      }
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
      this.rowData = this.keywordsData;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          headerTooltip: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 100,
          cellRendererFramework: "KeywordRenderer",
          suppressMenu: true,
          sorting: false,
          filter: false,
        },
        {
          headerName: this.$i18n.t("volume"),
          headerTooltip: this.$i18n.t("volume"),
          field: "volume",
          width: 95,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: false,
          suppressSizeToFit: true,
          suppressMenu: true,
          filter: false,
          sorting: false,
        },
        {
          headerName: this.$i18n.t("position"),
          headerTooltip: this.$i18n.t("position"),
          field: "position",
          width: 90,
          valueFormatter: positionFormatter,
          type: "numericColumn",
          suppressSizeToFit: true,
          suppressMenu: true,
          sorting: false,
          filter: false,
          cellRendererFramework: "PositionRenderer",
        },
        {
          field: "pastPosition",
          hide: "true",
          suppressToolPanel: true,
          suppressMenu: true,
          sorting: false,
          filter: false,
        },
        {
          headerName: this.$i18n.t("traffic_variation"),
          headerTooltip: this.$i18n.t("traffic_variation"),
          field: "estimatedTrafficVariation",
          width: 90,
          type: "numericColumn",
          sort: this.sortOrder,
          suppressSizeToFit: true,
          cellClass: (params) => {
            if (params.value > 0) return "positive";
            if (params.value < 0) return "negative";
            return "neutral";
          },
          suppressMenu: true,
          sorting: false,
          filter: false,
        },
      ];
    },
    showKeyword(keywordId) {
      this.$emit("show-keyword", keywordId);
    },
    showTaskDialog(keywordId, keywordText) {
      this.$emit("show-task", { id: keywordId, text: keywordText });
    },
  },
  watch: {
    keywordsData() {
      this.rowData = this.keywordsData;
    },
  },
  beforeMount() {
    this.context = {
      studyId: this.studyId,
      showKeyword: this.showKeyword,
      showTaskDialog: this.showTaskDialog,
    };
  },
};

let positionFormatter = (params) => {
  if (params.value) {
    return params.value;
  } else {
    return "> 100";
  }
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

::v-deep .positive {
  color: $--color-success;
  text-align: right;
}
::v-deep .neutral {
  text-align: right;
}

::v-deep .negative {
  color: $--color-danger;
  text-align: right;
}

::v-deep .ag-header-cell {
  padding-left: 0;
}

@media print {
  .grid {
    height: 550px !important;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "excel_export": "Export to Excel",
    "keyword": "Keyword",
    "volume": "Volume",
    "position": "Position",
    "traffic_variation": "+/- Estimated traffic"
  },
  "fr": {
    "excel_export": "Export Excel",
    "keyword": "Mot clé",
    "volume": "Volume",
    "position": "Position",
    "traffic_variation": "+/- Trafic estimé"
  },
  "de": {
    "excel_export": "Excel exportieren",
    "keyword": "Stichwort",
    "volume": "Volumen",
    "position": "Position",
    "traffic_variation": "+/- Geschätzter Verkehr"
  }
}
</i18n>
