export default {
  backlinks: {
    domainRefused: {
      metrics_too_low: "Métriques trop basses",
      wrong_thematic: "Thématique incorrecte",
      da_too_low: "DA trop bas",
      tf_too_low: "TF trop bas",
      traffic_too_low_or_null: "Trafic trop bas ou nul",
      price_too_high: "Prix trop élevé",
      site_not_relevant: "Site non pertinent",
      keywords_not_relevant: "Mots-clés non pertinents",
      link_already_exists: "Lien déjà existant",
      other: "Autre",
    },
    gridHeader: {
      target_url: "Page visée",
    }
  },
};
