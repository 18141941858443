<template>
  <div v-if="meta">
    <div class="text-center">
      <span class="container">
        <div
          class="d-flex"
          style="justify-content: space-between"
        >
          <el-card
            class="mr-4"
            :body-style="{ height: '100%' }"
            style="flex: 1"
          >
            <div
              class="h-100 w-100 d-flex flex-column justify-content-between align-items-center"
            >
              <h5>{{ $t("budget_delivred") }}</h5>
              <div>
                <div :class="colorCampaignStatus">
                  <h1 class="mt-3">
                    {{ deliveredBudgetStats.percentageOfDeliveredBudget }}
                  </h1>
                  <h4>
                    {{
                      $t("deliveredBudget", {
                        value: currencyFormat(
                          deliveredBudgetStats.deliveredBudget
                        ),
                      })
                    }}
                  </h4>
                </div>
                <div v-if="deliveredBudgetStats.numberOfPublishedBacklinks">
                  <p>
                    <strong>{{ $t("correspondTo") }}</strong>
                  </p>
                  <ul class="text-left mb-0">
                    <li>
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            deliveredBudgetStats.numberOfPublishedBacklinks
                              .action
                          )
                        "
                      >
                        {{
                          deliveredBudgetStats.numberOfPublishedBacklinks.value
                        }}
                        {{ $t("publishedBacklinks") }}</el-button
                      >
                    </li>
                  </ul>
                </div>
                <!-- Trick to align both stats -->
                <div
                  style="visibility: hidden"
                  v-if="
                    remaingingBudgetStats.numberOfRefusedDomains ||
                    remaingingBudgetStats.numberOfCanceledBacklinks
                  "
                >
                  <p>
                    <strong>{{ $t("correspondTo") }}</strong>
                  </p>
                  <ul class="text-left mb-0">
                    <li v-if="remaingingBudgetStats.numberOfRefusedDomains">
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            remaingingBudgetStats.numberOfRefusedDomains.action
                          )
                        "
                      >
                        {{ remaingingBudgetStats.numberOfRefusedDomains.value }}
                        {{ $t("refusedDomains") }}</el-button
                      >
                    </li>
                    <li v-if="remaingingBudgetStats.numberOfCanceledBacklinks">
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            remaingingBudgetStats.numberOfCanceledBacklinks
                              .action
                          )
                        "
                      >
                        {{
                          remaingingBudgetStats.numberOfCanceledBacklinks.value
                        }}
                        {{ $t("canceledBacklinks") }}</el-button
                      >
                    </li>
                  </ul>
                </div>
                <!-- End of trick -->
              </div>
              <div />
            </div>
          </el-card>
          <el-card
            class="mr-4"
            :body-style="{ height: '100%' }"
            style="flex: 2"
          >
            <h5 class="px-3 mb-3">{{ $t("budget_in_process") }}</h5>
            <div
              class="d-flex"
              style="justify-content: space-between"
            >
              <div
                :body-style="{ height: '100%' }"
                style="flex: 1"
              >
                <div class="h-100 w-100 d-flex flex-column align-items-center">
                  <div>
                    <h5>
                      {{ $t("at") }} <b>{{ study.companyName }}</b>
                    </h5>
                    <h2>
                      {{
                        clientPendingBudgetStats.clientPendingBudgetPercentage
                      }}
                    </h2>
                    <p>
                      {{
                        $t("totalClientBudget", {
                          clientPendingBudget: currencyFormat(
                            clientPendingBudgetStats.clientPendingBudget
                          ),
                        })
                      }}
                    </p>
                  </div>
                  <p
                    v-if="
                      clientPendingBudgetStats.numberOfDomainsToValidate ||
                      clientPendingBudgetStats.numberOfArticlesToValidate
                    "
                  >
                    <strong>{{ $t("correspondTo") }}</strong>
                  </p>
                  <ul class="text-left mb-0">
                    <li
                      @click="
                        changeFilter(
                          clientPendingBudgetStats.numberOfDomainsToValidate
                            .action
                        )
                      "
                      v-if="clientPendingBudgetStats.numberOfDomainsToValidate"
                    >
                      <el-button
                        size="small"
                        type="text"
                      >
                        {{
                          clientPendingBudgetStats.numberOfDomainsToValidate
                            .value
                        }}
                        {{ $t("domainsToValidate") }}</el-button
                      >
                    </li>
                    <li
                      @click="
                        changeFilter(
                          clientPendingBudgetStats.numberOfArticlesToValidate
                            .action
                        )
                      "
                      v-if="clientPendingBudgetStats.numberOfArticlesToValidate"
                    >
                      <el-button
                        size="small"
                        type="text"
                      >
                        {{
                          clientPendingBudgetStats.numberOfArticlesToValidate
                            .value
                        }}
                        {{ $t("articlesToValidate") }}</el-button
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div
                :body-style="{ height: '100%' }"
                style="flex: 1"
              >
                <div class="h-100 w-100 d-flex flex-column align-items-center">
                  <div>
                    <h5>{{ $t("at") }} <b>Smartkeyword</b></h5>
                    <h2>
                      {{
                        smartkeywordPendingBudgetStats.smartkeywordPendingBudgetPercentage
                      }}
                    </h2>
                    <p>
                      {{
                        $t("smartkeywordBudgetStats", {
                          smartkeywordPendingBudget: currencyFormat(
                            smartkeywordPendingBudgetStats.smartkeywordPendingBudget
                          ),
                        })
                      }}
                    </p>
                  </div>
                  <p
                    v-if="
                      smartkeywordPendingBudgetStats.numberOfArticlesToReview ||
                      smartkeywordPendingBudgetStats.numberOfArticlesToModify ||
                      smartkeywordPendingBudgetStats.numberOfArticlesToPublish ||
                      smartkeywordPendingBudgetStats.numberOfDomainsToBuy ||
                      smartkeywordPendingBudgetStats.numberOfArticlesWaitingToPublish
                    "
                  >
                    <strong>{{ $t("correspondTo") }}</strong>
                  </p>
                  <ul class="text-left mb-0">
                    <li
                      v-if="smartkeywordPendingBudgetStats.numberOfDomainsToBuy"
                    >
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            smartkeywordPendingBudgetStats.numberOfDomainsToBuy
                              .action
                          )
                        "
                      >
                        {{
                          smartkeywordPendingBudgetStats.numberOfDomainsToBuy
                            .value
                        }}
                        {{ $t("domainsToBuy") }}</el-button
                      >
                    </li>
                    <li
                      v-if="
                        smartkeywordPendingBudgetStats.numberOfArticlesToReview
                      "
                    >
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            smartkeywordPendingBudgetStats
                              .numberOfArticlesToReview.action
                          )
                        "
                      >
                        {{
                          smartkeywordPendingBudgetStats
                            .numberOfArticlesToReview.value
                        }}
                        {{ $t("articlesToReview") }}</el-button
                      >
                    </li>
                    <li
                      v-if="
                        smartkeywordPendingBudgetStats.numberOfArticlesToModify
                      "
                    >
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            smartkeywordPendingBudgetStats
                              .numberOfArticlesToModify.action
                          )
                        "
                      >
                        {{
                          smartkeywordPendingBudgetStats
                            .numberOfArticlesToModify.value
                        }}
                        {{ $t("articlesToModify") }}</el-button
                      >
                    </li>
                    <li
                      v-if="
                        smartkeywordPendingBudgetStats.numberOfArticlesWaitingToPublish
                      "
                    >
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            smartkeywordPendingBudgetStats
                              .numberOfArticlesWaitingToPublish.action
                          )
                        "
                      >
                        {{
                          smartkeywordPendingBudgetStats
                            .numberOfArticlesWaitingToPublish.value
                        }}
                        {{ $t("articlesWaintingToPublish") }}</el-button
                      >
                    </li>
                    <li
                      v-if="
                        smartkeywordPendingBudgetStats.numberOfArticlesToPublish
                      "
                    >
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            smartkeywordPendingBudgetStats
                              .numberOfArticlesToPublish.action
                          )
                        "
                      >
                        {{
                          smartkeywordPendingBudgetStats
                            .numberOfArticlesToPublish.value
                        }}
                        {{ $t("articlesToPublish") }}</el-button
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </el-card>
          <el-card
            :body-style="{ height: '100%' }"
            style="flex: 1"
          >
            <div
              class="h-100 w-100 d-flex justify-content-between flex-column align-items-center"
            >
              <h5>{{ $t("budget_remaining") }}</h5>
              <div>
                <h1 class="mt-3">
                  {{ remaingingBudgetStats.percentageOfRemaingingBudget }}
                </h1>
                <h4>
                  {{
                    $t("remaingingBudgetStatsRemainingBudget", {
                      remainingBudget: currencyFormat(
                        remaingingBudgetStats.remainingBudget
                      ),
                    })
                  }}
                </h4>
                <div
                  v-if="
                    remaingingBudgetStats.numberOfRefusedDomains ||
                    remaingingBudgetStats.numberOfCanceledBacklinks
                  "
                >
                  <p>
                    <strong>{{ $t("including") }}</strong>
                  </p>
                  <ul class="text-left mb-0">
                    <li v-if="remaingingBudgetStats.numberOfRefusedDomains">
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            remaingingBudgetStats.numberOfRefusedDomains.action
                          )
                        "
                      >
                        {{ remaingingBudgetStats.numberOfRefusedDomains.value }}
                        {{ $t("refusedDomains") }}</el-button
                      >
                    </li>
                    <li v-if="remaingingBudgetStats.numberOfCanceledBacklinks">
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            remaingingBudgetStats.numberOfCanceledBacklinks
                              .action
                          )
                        "
                      >
                        {{
                          remaingingBudgetStats.numberOfCanceledBacklinks.value
                        }}
                        {{ $t("canceledBacklinks") }}</el-button
                      >
                    </li>
                  </ul>
                </div>
                <!-- Trick to align both stats -->
                <div
                  style="visibility: hidden"
                  v-if="deliveredBudgetStats.numberOfPublishedBacklinks"
                >
                  <p>
                    <strong>{{ $t("correspondTo") }}</strong>
                  </p>
                  <ul class="text-left mb-0">
                    <li>
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          changeFilter(
                            deliveredBudgetStats.numberOfPublishedBacklinks
                              .action
                          )
                        "
                      >
                        {{
                          deliveredBudgetStats.numberOfPublishedBacklinks.value
                        }}
                        {{ $t("publishedBacklinks") }}</el-button
                      >
                    </li>
                  </ul>
                </div>
                <!-- End of trick -->
              </div>
              <div />
            </div>
          </el-card>
        </div>
      </span>
    </div>
    <div>
      <!--not finished -->
      <div class="container">
        <div class="row">
          <div class="col-2">
            <h5>{{ $t("projectStatus") }}</h5>
          </div>
          <div class="col-10">
            <h4 :class="colorCampaignStatus">{{ netlinkingCampaignStatus }}</h4>
          </div>
        </div>
        <div
          class="row"
          v-if="!campaign.isTerminated"
        >
          <div class="col-2">
            <h5>{{ $t("explanations") }}</h5>
          </div>
          <div class="col-10">
            <p v-if="isOnTrack">
              {{
                $t("onTrackExplications", {
                  theoreticalAdvancement: theoreticalAdvancement,
                })
              }}
            </p>
            <p v-else>
              <b
                >{{
                  $t("onDelayExplicationsFirstPart", {
                    percentageOfDelay: percentageOfDelay,
                  })
                }}
              </b>
              {{
                $t("onDelayExplications", {
                  theoreticalAdvancement: theoreticalAdvancement,
                  theoreticalBudgetToSpend: currencyFormat(
                    theoreticalBudgetToSpend
                  ),
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    meta: Object,
    campaign: Object,
  },
  methods: {
    formatNumber(number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
    percentageBuilder(value) {
      return Math.round(value * 100) / 100;
    },
    changeFilter(action) {
      this.$emit("change-filter-value", action);
    },
    currencyFormat(value) {
      return this.$options.filters.formatCurrency(value, this.$i18n.locale);
    },
  },
  computed: {
    ...mapState(["study"]),
    totalBudget() {
      return this.meta.totalBudget;
    },
    netlinkingCampaignStatus() {
      if (this.campaign.isTerminated) return this.$t("terminated");
      return this.$t(this.meta.netlinkingCampaignStatus.toLowerCase());
    },
    theoreticalAdvancement() {
      return this.percentageBuilder(this.meta.theoreticalAdvancement) + "%";
    },
    percentageOfDelay() {
      return this.percentageBuilder(this.meta.percentageOfDelay) + "%";
    },
    theoreticalBudgetToSpend() {
      return this.meta.theoreticalBudgetToSpend;
    },
    deliveredBudgetStats() {
      const object = this.meta.deliveredBudgetStats;

      let computedObject = {
        percentageOfDeliveredBudget:
          this.percentageBuilder(object.percentageOfDeliveredBudget) + "%",
        deliveredBudget: object.deliveredBudget,
      };
      if (object.numberOfPublishedBacklinks) {
        computedObject.numberOfPublishedBacklinks = {
          action: object.numberOfPublishedBacklinks.action,
          value: this.formatNumber(object.numberOfPublishedBacklinks.value),
        };
      }
      return computedObject;
    },
    smartkeywordPendingBudgetStats() {
      const object = this.meta.smartkeywordPendingBudgetStats;
      let computedObject = {
        smartkeywordPendingBudget: object.smartkeywordPendingBudget,
        smartkeywordPendingBudgetPercentage:
          this.percentageBuilder(object.smartkeywordPendingBudgetPercentage) +
          "%",
      };
      if (object.numberOfDomainsToBuy) {
        computedObject.numberOfDomainsToBuy = {
          action: object.numberOfDomainsToBuy.action,
          value: this.formatNumber(object.numberOfDomainsToBuy.value),
        };
      }
      if (object.numberOfArticlesToReview) {
        computedObject.numberOfArticlesToReview = {
          action: object.numberOfArticlesToReview.action,
          value: this.formatNumber(object.numberOfArticlesToReview.value),
        };
      }
      if (object.numberOfArticlesToModify) {
        computedObject.numberOfArticlesToModify = {
          action: object.numberOfArticlesToModify.action,
          value: this.formatNumber(object.numberOfArticlesToModify.value),
        };
      }
      if (object.numberOfArticlesWaitingToPublish) {
        computedObject.numberOfArticlesWaitingToPublish = {
          action: object.numberOfArticlesWaitingToPublish.action,
          value: this.formatNumber(
            object.numberOfArticlesWaitingToPublish.value
          ),
        };
      }
      if (object.numberOfArticlesToPublish) {
        computedObject.numberOfArticlesToPublish = {
          action: object.numberOfArticlesToPublish.action,
          value: this.formatNumber(object.numberOfArticlesToPublish.value),
        };
      }
      return computedObject;
    },
    clientPendingBudgetStats() {
      const object = this.meta.clientPendingBudgetStats;
      let computedObject = {
        clientPendingBudgetPercentage:
          this.percentageBuilder(object.clientPendingBudgetPercentage) + "%",
        clientPendingBudget: object.clientPendingBudget,
      };
      if (object.numberOfDomainsToValidate)
        computedObject.numberOfDomainsToValidate = {
          value: this.formatNumber(object.numberOfDomainsToValidate.value),
          action: object.numberOfDomainsToValidate.action,
        };
      if (object.numberOfArticlesToValidate)
        computedObject.numberOfArticlesToValidate = {
          value: this.formatNumber(object.numberOfArticlesToValidate.value),
          action: object.numberOfArticlesToValidate.action,
        };
      return computedObject;
    },
    remaingingBudgetStats() {
      const object = this.meta.remaingingBudgetStats;
      let computedObject = {
        percentageOfRemaingingBudget:
          this.percentageBuilder(object.percentageOfRemaingingBudget) + "%",
        remainingBudget: object.remaingingBudget,
      };
      if (object.numberOfRefusedDomains)
        computedObject.numberOfRefusedDomains = {
          value: this.formatNumber(object.numberOfRefusedDomains.value),
          action: object.numberOfRefusedDomains.action,
        };
      if (object.numberOfCanceledBacklinks)
        computedObject.numberOfCanceledBacklinks = {
          value: this.formatNumber(object.numberOfCanceledBacklinks.value),
          action: object.numberOfCanceledBacklinks.action,
        };
      return computedObject;
    },
    colorCampaignStatus() {
      if (this.campaign.isTerminated) return;
      if (this.isOnTrack) return "text-success";
      else return "text-warning";
    },
    isOnTrack() {
      if (
        this.meta &&
        this.meta.netlinkingCampaignStatus &&
        this.meta.netlinkingCampaignStatus == "ON_TRACK"
      )
        return true;
    },
  },
};
</script>

<style scoped>
.financial-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
h6 {
  font-weight: bold;
}
h4 {
  font-weight: bold;
}
</style>
<i18n>
{
  "en": {
    "deliveredBudget": "{value} of the total budget",
    "totalClientBudget": "{clientPendingBudget} of the total budget",
    "numberOfPublishedBacklinks": "{numberOfPublishedBacklinks} published backlink(s)",
    "smartkeywordBudgetStats": "{smartkeywordPendingBudget} of the total budget",
    "remaingingBudgetStatsRemainingBudget": "{remainingBudget} of the total budget",
    "canceledBacklinks": "canceled backlink(s) to replace",
    "refusedDomains": "refused domain(s) to replace",
    "publishedBacklinks": "published backlink(s)",
    "inYourWebsite": "on your website",
    "at": "at",
    "correspondTo": "Correspond to:",
    "including": "Including:",
    "domainsToValidate": "domain(s) to approve",
    "articlesToValidate": "article(s) to approve",
    "domainsToBuy": "backlink(s) to buy",
    "articlesToReview": "article(s) being written",
    "articlesToModify": "article(s) to modify",
    "articlesToPublish": "article(s) to publish",
    "articlesWaintingToPublish": "article(s) waiting to be publish",
    "atSmartkeyword": "AT SMARTKEYWORD",
    "summary": "Summary",
    "orderedWords": "Ordered Words",
    "wordsWritten": "Written Words",
    "textsDelivered": "Texts Delivered",
    "draftingInProgress": "Drafting in progress",
    "briefAwaitingValidation": "Brief awaiting validation",
    "completion": "Completed",
    "on_track": "On Track",
    "delay": "Behind schedule",
    "onDelayExplicationsFirstPart": "{percentageOfDelay} of delay.",
    "onDelayExplications": "To this day we should be at at least {theoreticalAdvancement} of budget delivered on the campaign. {theoreticalBudgetToSpend} of budget must be delivered to achieve this objective",
    "onTrackExplications": "To this day we should be at at least {theoreticalAdvancement} of budget delivered on the campaign.",
    "budget_delivred": "% OF BUDGET DELIVERED",
    "budget_in_process": "% OF BUDGET IN PROCESS",
    "budget_remaining": "% OF BUDGET REMAINING",
    "youStillHave": "You still have",
    "projectStatus": "Campaign status",
    "explanations": "Explanation: ",
    "terminated": "Terminated"
  },
  "fr": {
    "deliveredBudget": "soit {value} du budget total",
    "totalClientBudget": "soit {clientPendingBudget} du budget total",
    "numberOfPublishedBacklinks": "{numberOfPublishedBacklinks} backlink(s) publié(s)",
    "smartkeywordBudgetStats": "{smartkeywordPendingBudget} du budget total",
    "remaingingBudgetStatsRemainingBudget": "soit {remainingBudget} du budget total",
    "canceledBacklinks": "backlink(s) annulé(s) à remplacer",
    "refusedDomains": "domaine(s) refusé(s) à remplacer",
    "publishedBacklinks": "backlink(s) publié(s)",
    "inYourWebsite": "sur votre site",
    "at": "chez",
    "correspondTo": "Correspond à :",
    "including": "Dont :",
    "domainsToValidate": "domaine(s) à valider",
    "articlesToValidate": "article(s) à valider",
    "domainsToBuy": "backlink(s) à acheter",
    "articlesToReview": "article(s) en cours de rédaction",
    "articlesToModify": "article(s) à modifier",
    "articlesToPublish": "article(s) à publier",
    "articlesWaintingToPublish": "article(s) en attente de publication",
    "atSmartkeyword": "CHEZ SMARTKEYWORD",
    "summary": "Résumé",
    "orderedWords": "Nombre de mots commandés",
    "wordsWritten": "Nombre de mots rédigés",
    "textsDelivered": "Nombre de textes livrés",
    "draftingInProgress": "Rédaction en cours",
    "briefAwaitingValidation": "Brief en attente de validation",
    "completion": "% de Completion",
    "on_track": "On track",
    "delay": "En retard",
    "onDelayExplicationsFirstPart": "{percentageOfDelay} de retard.",
    "onDelayExplications": "À ce jour nous devrions avoir au moins {theoreticalAdvancement} de budget livré sur la campagne. {theoreticalBudgetToSpend} de budget doivent être livrés pour atteindre cet objectif.",
    "onTrackExplications": "À ce jour nous devrions avoir au moins {theoreticalAdvancement} de budget livré sur la campagne.",
    "budget_delivred": "% DE BUDGET LIVRÉ",
    "budget_in_process": "% DE BUDGET EN COURS DE TRAITEMENT",
    "budget_remaining": "% DE BUDGET RESTANT",
    "youStillHave": "Vous avez encore",
    "projectStatus": "Statut de la campagne",
    "explanations": "Explication :",
    "terminated": "Terminée"
  },
  "de": {
    "deliveredBudget": "Lassen Sie {Wert} des Gesamtbudgets",
    "totalClientBudget": "sei {clientPendingBudget} das Gesamtbudget",
    "numberOfPublishedBacklinks": "{numberOfPublishedBacklinks} Backlink(s) publié(s)",
    "smartkeywordBudgetStats": "{smartkeywordPendingBudget} Ihr Gesamtbudget",
    "remaingingBudgetStatsRemainingBudget": "soit {remainingBudget} Ihr Gesamtbudget",
    "canceledBacklinks": "stornierte Backlinks, die ersetzt werden sollen",
    "refusedDomains": "Domain(s) weigerten sich, ersetzt zu werden",
    "publishedBacklinks": "veröffentlichte(r) Backlink(s)",
    "inYourWebsite": "Auf deiner Webseite",
    "at": "im Haus von",
    "correspondTo": "Entspricht:",
    "including": "Von welchem ​​:",
    "domainsToValidate": "Domain(s) zu validieren",
    "articlesToValidate": "zu validierende Artikel",
    "domainsToBuy": "Backlink(s) zu kaufen",
    "articlesToReview": "Artikel, die geschrieben werden",
    "articlesToModify": "Artikel zu ändern",
    "articlesToPublish": "Artikel, die veröffentlicht werden sollen",
    "articlesWaintingToPublish": "Artikel, die auf Veröffentlichung warten",
    "atSmartkeyword": "CHEZ SMARTKEYWORD",
    "summary": "Fortsetzen",
    "orderedWords": "Anzahl der bestellten Wörter",
    "wordsWritten": "Anzahl der geschriebenen Wörter",
    "textsDelivered": "Anzahl der zugestellten Texte",
    "draftingInProgress": "Ausarbeitung läuft",
    "briefAwaitingValidation": "Kurz wartet auf Validierung",
    "completion": "% Fertigstellung",
    "on_track": "Auf der Strecke",
    "delay": "in Verzug",
    "onDelayExplicationsFirstPart": "{percentageOfDelay} verzögert.",
    "onDelayExplications": "Inzwischen sollten wir mindestens {theoreticalAdvancement} Budget für die Kampagne bereitgestellt haben. {theoreticalBudgetToSpend} des Budgets muss bereitgestellt werden, um dieses Ziel zu erreichen.",
    "onTrackExplications": "Inzwischen sollten wir mindestens {theoreticalAdvancement} Budget für die Kampagne bereitgestellt haben.",
    "budget_delivred": "% DES BUDGETS GELIEFERT",
    "budget_in_process": "% DES BUDGETS IN BEARBEITUNG",
    "budget_remaining": "% VERBLEIBENDES BUDGET",
    "youStillHave": "Sie haben immer noch",
    "projectStatus": "Kampagnenstatus",
    "explanations": "Erläuterung :",
    "terminated": "Vollendet"
  }
}
</i18n>
