<template>
  <div v-loading="isLoading">
    <div class="chart-title">
      <span class="chart-name">{{ $t("visibility") }} </span>
      <a
        v-if="user && user.locale === 'fr'"
        class="no-print"
        target="blank"
        href="http://docs.smartkeyword.io/guide-smartkeyword/donnees-smartkeyword/visibilite-definition-et-exemples"
      >
        <i
          :title="$t('visibility_definition')"
          class="fas fa-question-circle"
        ></i>
      </a>
      <span
        class="value"
        v-if="visibilityValue"
      >
        {{ visibilityValue }}%
      </span>
      <span
        class="change"
        v-if="visibilityChange"
      >
        <span
          v-if="visibilityChange > 0"
          class="positive-change"
          ><i class="fas fa-arrow-up"></i>{{ visibilityChange }}%</span
        >
        <span
          v-else-if="visibilityChange == 0"
          class="neutral-change"
          ><i class="fas fa-equals"></i
        ></span>
        <span
          v-else-if="visibilityChange < 0"
          class="negative-change"
          ><i class="fas fa-arrow-down"></i>{{ visibilityChange }}%</span
        >
      </span>
    </div>
    <highcharts
      ref="highchart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Array,
    isLoading: Boolean,
    user: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      visibilityChange: null,
      visibilityValue: null,
      chartOptions: {
        chart: {
          height: 300,
          zoomType: "x",
        },
        title: {
          text: "",
          useHTML: true,
        },
        xAxis: [
          {
            type: "datetime",
          },
          {
            labels: {
              rotation: -45,
            },
            linkedTo: 0,
            opposite: true,
            type: "datetime",
          },
        ],
        yAxis: {
          title: {
            text: this.$i18n.t("visibility") + " (%)",
          },
          endOnTick: false,
          minTickInterval: 1,
        },
        tooltip: {
          pointFormatter: function () {
            return "<b>" + this.y + "%</b>";
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      if (this.data && this.data.length > 0) {
        this.chartOptions.series = [];
        let nbKeywordsPerDate = [];
        let nbNewKeywordsPerDate = new Map();
        let lastNbKeywords = 0;
        const visibilityData = [];
        this.data.forEach((element) => {
          visibilityData.push({
            x: element.date.getTime(),
            y: Math.round(
              ((element.top13 + element.top410) / element.nbKeywords) * 100
            ),
          });

          nbKeywordsPerDate.push(element.nbKeywords);
          if (
            nbKeywordsPerDate.length > 1 &&
            element.nbKeywords - lastNbKeywords !== 0
          ) {
            let diff = element.nbKeywords - lastNbKeywords;
            let prefix = diff > 0 ? "+" : "";
            nbNewKeywordsPerDate.set(
              element.date.getTime(),
              prefix + diff + " " + this.$i18n.t("keywords")
            );
          }
          lastNbKeywords = element.nbKeywords;
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("percentage_keywords_in_first_page"),
          data: visibilityData,
        });
        this.chartOptions.xAxis[1].tickPositions = Array.from(
          nbNewKeywordsPerDate.keys()
        );
        this.chartOptions.xAxis[1].labels.formatter = function () {
          return nbNewKeywordsPerDate.get(this.value);
        };

        this.visibilityValue = visibilityData[visibilityData.length - 1].y;
        this.visibilityChange =
          visibilityData[visibilityData.length - 1].y - visibilityData[0].y;
      } else {
        this.chartOptions.series = [];
        this.visibilityValue = null;
        this.visibilityChange = null;
      }
    },
  },
  mounted() {
    this.updateSeries();
  },
  watch: {
    data() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;

  .value {
    font-size: 1.2rem;
    margin-left: 0.35rem;
  }

  .chart-name {
    font-size: 1.5rem;
  }

  .fa-question-circle {
    font-size: 1.1rem;
  }

  .change {
    font-size: 0.9rem;

    .positive-change {
      color: $--color-success;
    }

    .neutral-change {
      color: $--color-warning;
    }

    .negative-change {
      color: $--color-danger;
    }
  }
}

@media print {
  ::v-deep .no-print {
    display: none !important;
  }
}
</style>

<i18n>
{
  "en": {
    "keywords": "keywords",
    "visibility": "Visibility",
    "visibility_definition": "Percentage of keywords present on the first result page",
    "percentage_keywords_in_first_page": "% of keywords in the first search result page"
  },
  "fr": {
    "keywords": "mots clés",
    "visibility": "Visibilité",
    "visibility_definition": "Pourcentage de mots clés sur la première page de résultat Google",
    "percentage_keywords_in_first_page": "% de mots clés en 1ère page"
  },
  "de": {
    "keywords": "Schlüsselwörter",
    "visibility": "Sichtweite",
    "visibility_definition": "Prozentsatz der Keywords auf der ersten Seite der Google-Ergebnisse",
    "percentage_keywords_in_first_page": "% der Keywords auf der 1. Seite"
  }
}
</i18n>
