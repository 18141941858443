<template>
  <div>
    <div class="header">
      <div v-if="!isStandAlone">
        <task-title
          v-if="!isStandAlone"
          class="title"
          :value="task.title"
          @input="(title) => $emit('on-update', { title })"
        />
      </div>
      <div v-else>
        <section class="app-introduction">
          <div class="container">
            <div>
              <task-title
                :value="task.title"
                @input="(title) => $emit('on-update', { title })"
              />
            </div>
          </div>
        </section>
      </div>
      <div
        v-if="!creatingTask"
        class="task-actions"
      >
        <task-action-dropdown
          :task="task"
          :is-stand-alone="isStandAlone"
          :is-hover="true"
          v-on="$listeners"
        >
        </task-action-dropdown>
      </div>
    </div>
    <div style="margin-top: 1rem">
      <div
        class="task-container"
        :class="task.page ? ' width-left' : ''"
      >
        <div class="left-side">
          <description
            ref="description"
            :value="task.description"
            :creating-task="creatingTask"
            @input="(description) => $emit('on-update', { description })"
          />
          <el-divider />
          <files
            :value="task.blocks"
            :task-id="task.id"
            :current-user="currentUser"
            @input="(blocks) => $emit('on-update', { blocks })"
          />
          <el-divider />
          <comments
            :value="task.blocks"
            :current-user="currentUser"
            :user-options="userOptions"
            @input="(blocks) => $emit('on-update', { blocks })"
          />
        </div>

        <el-divider direction="vertical" />

        <div>
          <div class="right-side-item">
            <slot name="top-side" />
          </div>

          <kind
            class="right-side-item"
            :value="task.kind"
            @click="outsideClick()"
            @input="(kind) => $emit('on-update', { kind })"
          />
          <assigned-to
            class="right-side-item"
            @click="outsideClick()"
            :value="task.assignedToUser ? task.assignedToUser.id : null"
            :user-options="enrichUserOptions()"
            @input="
              (assignedToUserId) => $emit('on-update', { assignedToUserId })
            "
          />
          <priority
            class="right-side-item"
            @click="outsideClick()"
            :value="task.priority"
            @input="(priority) => $emit('on-update', { priority })"
          />
          <watchers
            class="right-side-item"
            @click="outsideClick()"
            :value="task.watcherIds"
            :displayed-watchers="task.watchers"
            :study-id="studyId"
            :current-user="currentUser"
            :add-watcher-action="task.actions ? task.actions.addWatcher : null"
            :remove-watcher-action="
              task.actions ? task.actions.removeWatcher : null
            "
            @input="
              (watcherCallApi) => $emit('on-update-action', watcherCallApi)
            "
          />
          <date-picker
            class="right-side-item"
            :value="task.dueDate"
            :name="'due_date'"
            :className="'fas fa-clock'"
            @input="(dueDate) => $emit('on-update', { dueDate })"
          />
          <date-picker
            class="right-side-item"
            :value="task.reminderDate"
            :name="'remind_date'"
            :className="'fas fa-bell'"
            @input="(reminderDate) => $emit('on-update', { reminderDate })"
          />
          <date-picker
            class="right-side-item"
            :value="task.completionDate"
            :name="'completion_date'"
            :className="'fas fa-calendar-check'"
            @input="(completionDate) => $emit('on-update', { completionDate })"
          />
          <keywords
            class="right-side-item"
            @click="outsideClick()"
            :value="task.keywords"
            :study-id="studyId"
            @input="(keywords) => $emit('on-update', { keywords })"
            @show-keyword="showKeyword($event)"
          />
          <pages
            class="right-side-item"
            @click="outsideClick()"
            :value="task.pages"
            :study-id="studyId"
            @input="(pages) => $emit('on-update', { pages })"
          />
          <status
            class="right-side-item"
            @click="outsideClick()"
            :value="task.status"
            @input="(status) => $emit('on-update', { status })"
          />
          <div
            class="right-side-item"
            v-if="!creatingTask"
          >
            <slot name="bottom-side" />
          </div>
        </div>
      </div>
      <keyword-dialog
        v-if="keywordDialogVisible"
        v-model="keywordDialogVisible"
        :keyword-id="selectedKeywordId"
        :currentUser="currentUser"
        :studyId="studyId"
      ></keyword-dialog>
    </div>
  </div>
</template>

<script>
import KeywordDialog from "@/pages/KeywordDialog";
import Comments from "./Comments";
import DatePicker from "./DatePicker";
import Description from "./Description";
import Files from "./Files";
import Keywords from "./Keywords";
import Kind from "./Kind";
import Pages from "./Pages";
import Priority from "./Priority";
import TaskTitle from "./Title";
import AssignedTo from "./AssignedTo";
import Status from "./Status";
import Watchers from "./Watchers";
import TaskActionDropdown from "@/components/task/TaskActionDropdown";

import { isEqual, unionWith } from "lodash";

export default {
  components: {
    KeywordDialog: () => import("@/pages/KeywordDialog"),
    Comments,
    DatePicker,
    Description,
    Files,
    Keywords,
    Kind,
    Pages,
    Priority,
    TaskTitle,
    AssignedTo,
    Status,
    Watchers,
    TaskActionDropdown,
  },
  props: {
    task: Object,
    currentUser: Object,
    userOptions: Array,
    studyId: Number,
    creatingTask: Boolean,
    isStandAlone: Boolean,
  },
  data() {
    return {
      keywordDialogVisible: false,
      selectedKeywordId: null,
      newTitle: "",
    };
  },
  methods: {
    copyToClipboard() {
      const el = document.createElement("textarea");
      el.value =
        window.location.origin +
        `/studies/${this.studyId}/tasks/${this.task.id}`;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message({
        message: this.$i18n.t("linkCopiedToClipBoard"),
        type: "success",
        duration: 2000,
        offset: 80,
      });
    },
    showKeyword(keywordId) {
      this.selectedKeywordId = keywordId;
      this.keywordDialogVisible = true;
    },
    outsideClick() {
      this.$refs.description.outsideClick();
    },
    enrichUserOptions() {
      if (this.task.assignedToUser) {
        const indexOfAssignedUser = this.userOptions.findIndex(
          (userOption) => userOption.id == this.task.assignedToUser.id
        );
        if (indexOfAssignedUser == -1) {
          this.userOptions.push(this.task.assignedToUser);
        }
      }
      return this.userOptions;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;

  .task-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.title {
  padding-bottom: 1rem;
}

.task-container {
  display: flex;

  .left-side {
    flex-grow: 1;
  }
}

.width-left {
  width: 500px;
}

.el-divider--vertical {
  height: unset;
  margin: 1rem;
  width: 2px;
}
</style>

<style lang="scss">
.right-side-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.7rem;

  h4 {
    display: inline-block;
    font-size: 1rem;
  }
  .value {
    width: 100%;
  }
}
</style>

<i18n>
{
  "en": {
    "copyPage": "Copy link",
    "linkCopiedToClipBoard": "Link copied to clipboard"
  },
  "fr": {
    "copyPage": "Copier le lien",
    "linkCopiedToClipBoard": "Lien a été copié"
  },
  "de": {
    "copyPage": "Link kopieren",
    "linkCopiedToClipBoard": "Link wurde kopiert"
  }
}
</i18n>
