<template>
  <el-form-item :label="$t('add_up_to_1000_keywords')">
    <el-input
      type="textarea"
      :rows="5"
      :placeholder="$t('input_keywords_instructions')"
      v-model="textarea"
      @keyup.enter.stop.prevent.native=""
    >
    </el-input>
  </el-form-item>
</template>

<script>
export default {
  data() {
    return {
      textarea: "",
    };
  },
  watch: {
    textarea(value) {
      this.$emit(
        "input",
        value.split("\n").map((text) => text.trim())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.el-textarea {
  width: 500px;
}
</style>

<i18n>
{
  "en": {
    "keywords": "Keywords",
    "input_keywords_instructions": "Enter your keywords each on one line",
    "add_up_to_1000_keywords": "Add up to 1,000 keywords to get their search volumes"
  },
  "fr": {
    "keywords": "Mots clés",
    "input_keywords_instructions": "Entrez un mot clé par ligne",
    "add_up_to_1000_keywords": "Ajouter jusqu’à 1 000 mots clés pour récupérer leurs volumes de recherches"
  },
  "de": {
    "keywords": "Schlüsselwörter",
    "input_keywords_instructions": "Geben Sie ein Schlüsselwort pro Zeile ein",
    "add_up_to_1000_keywords": "Fügen Sie bis zu 1.000 Keywords hinzu, um ihr Suchvolumen wiederherzustellen"
  }
}
</i18n>
