<template>
  <div class="container">
    <el-card>
      <div class="title">
        <h4>
          {{ $t("keywordsBringingVisits") }}
          <span v-if="rowData">({{ rowData.length }})</span>
        </h4>
      </div>
      <div class="actions">
        <import-and-export-grid-actions
          v-if="accountId"
          :gridApi="gridApi"
          :columnApi="columnApi"
          :accountId="accountId"
          :isRowsSelected="isRowsSelected"
          @importKeywords="importKeywords($event)"
          @exportExcel="exportExcel()"
        >
        </import-and-export-grid-actions>
      </div>
      <div ref="grid">
        <ag-grid-vue
          v-loading="isLoading"
          style="height: 400px; width: 100%"
          class="ag-theme-material"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rowData"
          :resizable="true"
          :filter="true"
          :floatingFilter="true"
          :isRowSelectable="isRowSelectable"
          rowSelection="multiple"
          @selection-changed="selectionChanged"
          @gridReady="onGridReady"
          @firstDataRendered="adjustGrid"
          @gridSizeChanged="adjustGrid"
          @rowDataChanged="adjustGrid"
        >
        </ag-grid-vue>
      </div>
    </el-card>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { agGridMixin } from "@/mixins/agGridMixin";
import "ag-grid-enterprise";
import ImportAndExportGridActions from "@/components/ImportAndExportGridActions";
import KeywordImportedRenderer from "@/components/gridRenderers/KeywordImportedRenderer";
import { listAndImportKeywordsMixin } from "@/mixins/listAndImportKeywordsMixin";

export default {
  props: {
    data: Array,
  },
  mixins: [agGridMixin, listAndImportKeywordsMixin],
  components: {
    AgGridVue,
    ImportAndExportGridActions,
    KeywordImportedRenderer,
  },
  data() {
    return {
      rowData: null,
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      gridApi: null,
      columnApi: null,
      gridOptions: { onColumnVisible: () => this.adjustGrid() },
      isLoading: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
    },
    adjustGrid() {
      if (this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          filter: "agTextColumnFilter",
          minWidth: 300,
          width: 400,
          cellRendererFramework: "KeywordImportedRenderer",
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: this.$i18n.t("clicks"),
          field: "clicks",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          sort: "desc",
          filter: "agNumberColumnFilter",
          maxWidth: 200,
        },
        {
          headerName: this.$i18n.t("impressions"),
          field: "impressions",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          sort: "desc",
          filter: "agNumberColumnFilter",
          maxWidth: 200,
        },
        {
          headerName: this.$i18n.t("position"),
          field: "position",
          valueFormatter: roundNumberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          maxWidth: 200,
        },
        {
          headerName: this.$i18n.t("ctr"),
          field: "ctr",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          maxWidth: 200,
        },
      ];
    },
    mapData() {
      this.isLoading = true;
      if (this.data && this.data.length > 0) {
        this.rowData = this.data.map((keyword) => {
          return {
            keyword: keyword.keyword,
            clicks: keyword.clicks,
            impressions: keyword.impressions,
            position: keyword.position,
            ctr: keyword.ctr,
            keyword_id: keyword.keyword_id,
            isImportable: true,
          };
        });
        this.getStudyKeywords(this.rowData);
      } else {
        this.rowData = [];
      }
      setTimeout(() => this.adjustGrid(), 0);
    },
    exportExcel() {
      const columnKeys = this.columnApi
        .getAllDisplayedColumnGroups()
        .map((columnGroup) => columnGroup.colId);
      const params = {
        skipHeader: false,
        columnKeys: columnKeys,
        onlySelected: false,
        fileName: "Page Export - SmartKeyword",
        sheetName: "Page",
      };

      this.gridApi.exportDataAsExcel(params);
    },
  },
  mounted() {
    this.mapData();
  },
  watch: {
    data() {
      this.mapData();
    },
  },
};
let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);

let roundNumberFormatter = (params) => {
  if (!Number.isNaN(params.value)) {
    return formatNumber(Math.round(params.value));
  } else {
    return params.value;
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  margin-bottom: 1rem;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
.buttons-container {
  .button1 {
    float: right;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
  .button2 {
    text-align: right;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
}
</style>

<i18n>
{
  "en": {
    "excelExport": "Export to Excel",
    "addKeywords": "Add to my keywords",
    "keywordsBringingVisits": "Keywords generating SEO Traffic",
    "keyword": "Keyword",
    "clicks": "Clicks",
    "impressions": "Impressions",
    "position": "Position",
    "addKeywordsSuccess": "Keywords were added successfully.",
    "addKeywordsFail": "An error occurred.",
    "noKeywordsSelected": "No keywords were selected ! Select keywords first in order to add them to your keywords.",
    "ctr": "CTR (%)",
    "keywords_import": "Import selected keywords",
    "add_keywords_success": "Keywords were added successfully.",
    "add_keywords_fail": "An error occurred."
  },
  "fr": {
    "excelExport": "Export Excel",
    "addKeywords": "Ajouter à mes mots clés",
    "keywordsBringingVisits": "Mots clés rapportant des visites",
    "keyword": "Mot clé",
    "clicks": "Clics",
    "impressions": "Impressions",
    "position": "Position",
    "addKeywordsFail": "Une erreur est survenue.",
    "addKeywordsSuccess": "Mots clés ajoutés avec succès.",
    "noKeywordsSelected": "Aucun mot clé sélectionné ! Sélectionnez d'abord des mots clés pour pouvoir les ajouter à vos mots clés.",
    "ctr": "CTR (%)",
    "keywords_import": "Importer les mots clés sélectionnés",
    "add_keywords_success": "Les mots clés ont été importés avec succès.",
    "add_keywords_fail": "Une erreur est survenue."
  },
  "de": {
    "excelExport": "Excel exportieren",
    "addKeywords": "Zu meinen Schlüsselwörtern hinzufügen",
    "keywordsBringingVisits": "Schlüsselwörter, die Besuche melden",
    "keyword": "Stichwort",
    "clicks": "Klicks",
    "impressions": "Impressionen",
    "position": "Position",
    "addKeywordsFail": "Ein Fehler ist aufgetreten.",
    "addKeywordsSuccess": "Keywords erfolgreich hinzugefügt.",
    "noKeywordsSelected": "Kein Schlüsselwort ausgewählt! Wählen Sie zuerst Schlüsselwörter aus, damit Sie sie zu Ihren Schlüsselwörtern hinzufügen können.",
    "ctr": "Klickrate (%)",
    "keywords_import": "Importieren Sie ausgewählte Schlüsselwörter",
    "add_keywords_success": "Die Keywords wurden erfolgreich importiert.",
    "add_keywords_fail": "Ein Fehler ist aufgetreten."
  }
}
</i18n>
