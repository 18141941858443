<template>
  <div>
    <ag-grid-vue
      style="height: 550px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :localeTextFunc="localeTextFunc"
      :suppressRowClickSelection="true"
      :suppressCellSelection="true"
      :suppressContextMenu="true"
      @grid-ready="onGridReady"
      @first-data-rendered="adjustGrid"
      @row-data-changed="adjustGrid"
      :context="context"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import PageRenderer from "@/components/gridRenderers/PageRenderer";
import PositionRenderer from "@/components/gridRenderers/PositionRenderer";
import CompetitorPositionRenderer from "@/components/gridRenderers/PositionCompetitorRenderer";
import OpportunityScoreRenderer from "@/components/gridRenderers/OpportunityScoreRenderer";

import { RepositoryFactory } from "@/services/repositoryFactory";
const RemoveKeywordsRepository = RepositoryFactory.get("removeKeywords");
const KeywordPagesRepository = RepositoryFactory.get("keywordPages");

export default {
  mixins: [agGridMixin],
  props: {
    keywordsData: Array,
    studyId: Number,
  },
  components: {
    AgGridVue,
    PageRenderer,
    PositionRenderer,
    OpportunityScoreRenderer,
    CompetitorPositionRenderer,
  },
  data() {
    return {
      rowData: null,
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      gridApi: null,
      columnApi: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 100,
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          width: 125,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          sort: { direction: "desc", priority: 1 },
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
        },
        {
          headerName: this.$i18n.t("position"),
          field: "position",
          width: 125,
          valueGetter: (params) =>
            params.data.position ? params.data.position : 101,
          valueFormatter: positionFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "PositionRenderer",
        },
        {
          headerName: this.$i18n.t("position_competitor"),
          field: "positionCompetitor",
          width: 125,
          valueGetter: (params) =>
            params.data.positionCompetitor
              ? params.data.positionCompetitor
              : 101,
          valueFormatter: positionFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "CompetitorPositionRenderer",
        },
        {
          headerName: this.$i18n.t("opportunity_score"),
          field: "opportunityScore",
          width: 150,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "OpportunityScoreRenderer",
        },
      ];
    },
  },
  beforeMount() {
    this.context = {
      studyId: this.studyId,
    };
  },
  watch: {
    keywordsData() {
      if (this.keywordsData) this.rowData = this.keywordsData;
    },
  },
};

let positionFormatter = (params) => {
  if (params.value < 101) {
    return params.value;
  } else {
    return "> 100";
  }
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
@media print {
}
</style>

<i18n>
{
  "en": {
    "keyword": "Keyword",
    "rich_results": "Snippets",
    "page": "Page",
    "volume": "Volume",
    "position": "My rank",
    "opportunity_score": "Opportunity score",
    "actions": "Actions",
    "position_competitor": "Competitor"
  },
  "fr": {
    "keyword": "Mot clé",
    "rich_results": "Snippets",
    "page": "Page",
    "volume": "Volume",
    "position": "Ma position",
    "opportunity_score": "Score d'opportunité",
    "actions": "Actions",
    "position_competitor": "Concurrent"
  },
  "de": {
    "keyword": "Stichwort",
    "rich_results": "Ausschnitte",
    "page": "Buchseite",
    "volume": "Volumen",
    "position": "Meine Position",
    "opportunity_score": "Opportunity-Score",
    "actions": "Aktionen",
    "position_competitor": "Gleichzeitig"
  }
}
</i18n>
