import { DateTime } from "luxon";

export const dateTimeToEpochDay = (dateTime) => {
  const epoch = DateTime.utc(1970, 1, 1);
  return dateTime.diff(epoch, "days").days;
};

export const epochDayToDateTime = (epochDay) => {
  const epoch = DateTime.utc(1970, 1, 1);
  return epoch.plus({ days: epochDay });
};

export const dateToDateTimeUTC = (date) => {
  return DateTime.fromObject({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    zone: "utc",
  });
};
