<template>
  <el-button
    v-if="!isAlreadyCompetitor"
    v-on:click="addCompetitor(params.data.website)"
    size="mini"
    >{{ $t("add_as_competitor") }}</el-button
  >
  <el-button
    v-else
    type="info"
    plain
    disabled
    size="mini"
    >{{ $t("already_competitor") }}</el-button
  >
</template>

<script>
export default {
  computed: {
    isAlreadyCompetitor() {
      const domainCompetitorsList = this.params.context.allCompetitors.map(
        (e) => {
          return {
            domain: e.attributes.domain,
          };
        }
      );
      if (
        domainCompetitorsList.filter((e) =>
          this.params.data.website.includes(e.domain)
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    addCompetitor(host) {
      this.params.context.addCompetitor(host);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "add_as_competitor": "Add this competitor",
    "already_competitor": "Already added"
  },
  "fr": {
    "add_as_competitor": "Ajouter ce concurrent",
    "already_competitor": "Concurrent déjà ajouté"
  },
  "de": {
    "add_as_competitor": "Fügen Sie diesen Konkurrenten hinzu",
    "already_competitor": "Wettbewerber bereits hinzugefügt"
  }
}
</i18n>
