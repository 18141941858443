<template>
  <div>
    <app-introduction
      v-if="backlink"
      :title="$t('briefText') + ': ' + backlink.keyword.text"
    ></app-introduction>
    <div class="container">
      <el-card>
        <brief
          v-if="brief"
          :is-loading-brief="isLoadingBrief"
          :brief="brief"
          :backlink="backlink"
          :is-stand-alone="true"
          @update-brief="updateBrief($event)"
        >
        </brief>
      </el-card>
    </div>
  </div>
</template>
<script>
import Brief from "@/components/CampaignManager/Brief";
import AppIntroduction from "@/components/AppIntroduction";
import { mapState } from "vuex";
import { errorHandlerMixin } from "@/mixins/errorHandlerMixin.js";

export default {
  mixins: [errorHandlerMixin],
  components: {
    AppIntroduction,
    Brief,
  },
  props: {
    studyId: String,
    backlinkId: String,
    netlinkingCampaignId: String,
  },
  data() {
    return {
      brief: null,
      isLoadingBrief: false,
      backlink: null,
    };
  },
  computed: {
    ...mapState(["userLocale", "study"]),
  },
  methods: {
    updateBrief(details) {
      this.isLoadingBrief = true;
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.entity
      )
        .then((response) => {
          this.brief = response.data;
          this.isLoadingBrief = false;
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("errorMessage"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
          this.isLoadingBrief = false;
        });
    },
    fetchBacklink() {
      this.isLoadingBrief = true;
      this.$api["get"](
        "https://" +
          process.env.API_HOST +
          "/netlinking-campaigns/" +
          this.netlinkingCampaignId +
          "/backlinks/" +
          this.backlinkId +
          ""
      )
        .then((response) => {
          this.backlink = response.data.data;
          if (this.backlink.actions.brief) {
            this.isLoadingBrief = true;
            let link = this.backlink.actions.brief.read;
            this.$api[link.method.toLowerCase()](link.href, {
              headers: { "Accept-Language": this.userLocale },
            })
              .then((response) => {
                this.isLoadingBrief = false;
                this.brief = response.data;
              })
              .catch((error) => {
                this.$message({
                  message: this.$i18n.t("errorMessage"),
                  type: "error",
                  duration: 6000,
                });
                console.log(error);
                this.isLoadingBrief = false;
              });
          }
        })
        .catch((error) => console.error(error));
    },
  },
  mounted() {
    this.fetchBacklink();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
</style>

<style lang="scss" scoped>
.flex-box {
  display: flex;

  .title {
    font-size: 1.5rem;
  }

  .actions {
    margin-left: auto;
    margin-right: 2rem;

    .dropdown-header {
      font-size: 1.3rem !important;
      padding-top: 0rem;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "briefText": "Brief",
    "briefRedaction": "Brief redaction",
    "openAsPage": "Open as page",
    "copyLink": "Copy public link",
    "linkCopiedToClipBoard": "Link copied to clipboard",
    "errorMessage": "We encountered an error",
    "briefGeneration": "Brief generation... It may take up to a few minutes, you can leave this page without interrupting the generation.",
    "contentUpdated": "Content updated",
    "openBrief": "See the brief"
  },
  "fr": {
    "briefText": "Brief",
    "briefRedaction": "Rédaction du brief",
    "openAsPage": "Ouvrir dans un onglet",
    "copyLink": "Copier lien public",
    "linkCopiedToClipBoard": "Le lien a été copié",
    "errorMessage": "Nous avons rencontré une erreur",
    "briefGeneration": "Génération du brief... Cela peut prendre jusqu'à quelques minutes, vous pouvez quitter cette page sans que ça interrompt la génération.",
    "contentUpdated": "Content updated",
    "openBrief": "See the brief"
  },
  "de": {
    "briefText": "Knapp",
    "briefRedaction": "Erstellung des Briefings",
    "openAsPage": "In einem Tab öffnen",
    "copyLink": "Öffentlichen Link kopieren",
    "linkCopiedToClipBoard": "Der Link wurde kopiert",
    "errorMessage": "Es ist ein Fehler aufgetreten",
    "briefGeneration": "Briefing erstellen... Dies kann einige Minuten dauern, Sie können diese Seite verlassen, ohne dass die Generierung unterbrochen wird.",
    "contentUpdated": "Inhalt aktualisiert",
    "openBrief": "Siehe die Kurzbeschreibung"
  }
}
</i18n>
