<template>
  <el-select
    v-model="keyword"
    value-key="text"
    :placeholder="$t('keywordPlaceholder')"
    filterable
    size="medium"
    remote
    default-first-option
    :remote-method="searchKeywordsOptions"
    :loading="keywordsOptionsLoading"
  >
    <el-option
      v-for="keywordOption in keywordsOptions"
      :key="keywordOption.text"
      :label="keywordOption.text"
      :value="keywordOption"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: [Array, String, Object],
  },
  data() {
    return {
      keywordsOptionsLoading: false,
      keywordsOptions: [],
      isLoadingContentOptimization: false,
    };
  },
  methods: {
    searchKeywordsOptions(query) {
      if (query != "") {
        this.keywordsOptionsLoading = true;
        let requestTrackedKeywordsSearch = {
          text: { type: "CONTAINS", value: query },
        };
        this.$api[this.studyLinks.searchTrackedKeywords.method.toLowerCase()](
          this.studyLinks.searchTrackedKeywords.href,
          requestTrackedKeywordsSearch
        )
          .then((responseTrackedKeywords) => {
            this.keywordsOptions = responseTrackedKeywords.data.data;
            this.keywordsOptionsLoading = false;
          })
          .catch((error) => {
            this.keywordsOptionsLoading = false;
            console.log(error);
          });
      }
    },
  },
  computed: {
    keyword: {
      get() {
        this.keywordsOptions = [this.value];
        return this.value;
      },
      set(keyword) {
        this.$emit("selectedStudyKeyword", keyword);
        this.$emit("input", keyword);
      },
    },
    ...mapState(["study", "studyLinks"]),
  },
  mounted() {
    this.$emit("selectedStudyKeyword", this.keyword);
  },
};
</script>

<i18n>
{
  "en": {
    "keywordPlaceholder": "Select a keyword",
    "failTofetchContentOptimization": "We encountered an error while fetching SERP metrics"
  },
  "fr": {
    "keywordPlaceholder": "Sélectionner un mot clé",
    "failTofetchContentOptimization": "Nous avons rencontré une erreur lors du chargement des données de la SERP"
  },
  "de": {
    "keywordPlaceholder": "Wählen Sie ein Schlüsselwort aus",
    "failTofetchContentOptimization": "Beim Laden von Daten aus dem SERP ist ein Fehler aufgetreten"
  }
}
</i18n>
