<template>
  <div
    class="root py-2 px-2"
    v-loading.sync="isLoadingBubbleMenu"
    @keypress="($event) => enterClicked($event)"
  >
    <div class="sk-container">
      <div
        v-if="thread"
        class="texts"
      >

        <div
          class="w-100"
          v-for="(mComment, index) in comments"
          :key="index"
        >
          <div class="d-flex justify-content-between">
            <div class="w-100">
              <div class="d-flex flex-row align-items-center">
                <el-avatar
                  :size="24"
                  icon="el-icon-user-solid"
                ></el-avatar>
                <small class="font-weight-bold mx-2">
                  {{ mComment.data.attributes.ownerName }}</small
                >
                <span class="dateText">{{
                  mComment.data.attributes.date.substring(0, 10)
                }}</span>
              </div>
              <span
                v-if="isUpdateCommentOn == mComment.data.id"
                class="d-flex flex-row justify-content-center m-2"
              >
                <el-input
                  type="danger"
                  size="mini"
                  color="danger"
                  v-model="mComment.data.attributes.comment"
                />
                <el-button
                  class="btn"
                  type="success"
                  size="mini"
                  icon="el-icon-check"
                  plan
                  @click="
                    updateComment(
                      mComment.data.id,
                      mComment.data.attributes.comment
                    )
                  "
                  aria-hidden="true"
                />
              </span>
              <p
                v-else
                class="commentContent mb-1"
              >
                {{ mComment.data.attributes.comment }}
              </p>
            </div>
            <el-dropdown
              v-if="mComment.links"
              size="medium"
              class="mr-2"
              trigger="click"
              @command="handleCommand"
            >
              <span class="el-dropdown-link"
                ><i
                  class="fas fa-ellipsis-v"
                  aria-hidden="true"
              /></span>
              <el-dropdown-menu
                slot="dropdown"
                style="z-index: 10000"
              >
                <el-dropdown-item :command="'update' + mComment.data.id">
                  <span>{{ $t("modify") }}</span>
                </el-dropdown-item>
                <el-dropdown-item :command="'delete' + mComment.data.id"
                  ><span>{{ $t("delete") }}</span></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="comment-divider"></div>
        </div>
      </div>

      <div class="btn-section">
        <el-input
          type="text"
          size="mini"
          ref="myInput"
          @focus="enableButtonsAndColorSelection()"
          @focusout="disableButtons()"
          v-model="comment"
        />

        <el-button
          :disabled="!!!comment"
          class="btn"
          type="success"
          size="small"
          icon="el-icon-chat-dot-round"
          @click="
            isPublic || thread != null
              ? createComment(thread.links)
              : createThread()
          "
        >
          {{ $t("comment") }}
        </el-button>
      </div>
      <el-button
        class="w-100 text-center mt-2"
        v-if="thread && !isPublic"
        type="danger"
        size="mini"
        icon="el-icon-delete"
        @click="deleteThread"
        >{{ $t("deleteDiscussion") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    thread: Object,
    isPublic: Boolean,
    apiCallWhenNotPublic: Function,
    openBubble: Boolean,
  },
  data() {
    return {
      comment: null,
      isDisableButtons: true,
      isUpdateCommentOn: -1,
      isLoadingBubbleMenu: false,
      comments: null,
      notUpdated: true,
    };
  },
  methods: {
    createThread() {
      this.$emit("save-thread", {
        toggleLoading: (toggle) => {
          this.toggleLoading(toggle);
        },
        createComment: (links) => {
          this.createComment(links);
        },
      });
    },
    deleteThread() {
      this.$emit("delete-thread");
    },
    getComments() {
      this.toggleLoading(true);
      axios(
        this.apiCallWhenNotPublic(
          this.thread.links.comments.meta.method.toLowerCase(),
          this.thread.links.comments.href
        )
      )
        .then((response) => {
          this.comments = response.data.data;
          this.toggleLoading(false);
          this.notUpdated = false;
        })
        .catch((error) => {
          this.toggleLoading(false);
          console.error(error);
        });
    },

    createComment(links) {
      if (this.comment) {
        this.toggleLoading(true);
        axios(
          this.apiCallWhenNotPublic(
            links.create.meta.method.toLowerCase(),
            links.create.href,
            {
              comment: this.comment,
            }
          )
        )
          .then((response) => {
            this.comments = response.data.data;
            this.toggleLoading(false);
          })
          .catch((error) => {
            this.toggleLoading(false);
            console.error(error);
          });
        this.comment = null;
      }
    },

    updateComment(id, comment) {
      if (this.comments) {
        let toUpdateComment = this.getSelectedComment(id);
        this.toggleLoading(true);
        axios(
          this.apiCallWhenNotPublic(
            toUpdateComment.links.update.meta.method.toLowerCase(),
            toUpdateComment.links.update.href,
            {
              comment: comment,
            }
          )
        )
          .then((response) => {
            this.comments = response.data.data;
            this.toggleModifyComment();
            this.toggleLoading(false);
          })
          .catch((error) => {
            this.toggleLoading(false);
            console.error(error);
          });
      }
    },
    deleteComment(id) {
      let toDeleteComment = this.getSelectedComment(id);
      this.toggleLoading(true);
      axios(
        this.apiCallWhenNotPublic(
          toDeleteComment.links.delete.meta.method.toLowerCase(),
          toDeleteComment.links.delete.href
        )
      )
        .then(() => {
          this.comments = this.comments.filter(
            (element) => element.data.id != id
          );
          this.toggleLoading(false);
        })
        .catch((error) => {
          this.toggleLoading(false);
          console.error(error);
        });
    },

    getSelectedComment(id) {
      return this.comments.find((element) => element.data.id == id);
    },
    toggleModifyComment(id) {
      this.isUpdateCommentOn = id;
    },
    toggleLoading(value) {
      this.isLoadingBubbleMenu = value;
    },
    enableButtonsAndColorSelection() {
      this.$emit("color-selection");
      this.isDisableButtons = false;
    },
    disableButtons() {
      this.isDisableButtons = this.comment == null || this.comment.length <= 0;
    },
    handleCommand(command) {
      this.comments.forEach((comment) => {
        if (command == `update${comment.data.id}`) {
          this.toggleModifyComment(comment.data.id);
          return;
        }
        if (command == `delete${comment.data.id}`) {
          this.deleteComment(comment.data.id);
          return;
        }
      });
    },
    enterClicked($event) {
      if ($event.key == "Enter")
        if (this.isPublic || this.thread != null)
          this.createComment(this.thread.links);
        else this.createThread();
    },
    dateDisplay(date) {
      return date.substring(0, 10);
    },
  },
  watch: {
    thread(val, Oldval) {
      if (val != null && JSON.stringify(val) != JSON.stringify(Oldval)) {
        this.getComments();
        this.comment = null;
      }
    },
    openBubble(val, oldVal) {
      if (val == false) this.comment = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  background: white;
  box-shadow: 0px 0px 4px rgb(104, 104, 104);
  border-radius: 8px;
}
.sk-container {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container.style {
  width: 300px;
}

.btn {
  margin: 0px 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  border: none;
  border-radius: 18px;
}

.btn.btn-save {
  background: rgb(67, 156, 207);
  color: white;
}

.btn.btn-save:hover {
  background: rgb(2, 98, 153);
}

.btn.btn-cancel {
  background: rgb(206, 206, 206);
  color: black;
}
.btn.btn-cancel:hover {
  background: rgb(90, 90, 90);
  color: white;
}

.thread-input {
  border-radius: 18px;
  padding: 8px 8px;
  margin: 8px;
  border: gray 1px dashed;
  width: 100%;
  transition: 250ms ease-in-out;
}

.thread-input.small {
  padding: 4px 4px;
  margin: 4px;
}

.thread-input:focus {
  outline: none;
  border: rgba(67, 156, 207, 0) 1px dashed;
  box-shadow: 0 0 5px rgb(67, 156, 207);
}
.texts {
  width: 100%;
  margin: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.texts p {
  margin: 2px 4px;
  padding: 2px;
  width: 100%;
}
.btn-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dateText {
  font-size: 11px;
  color: gray;
}
.commentContent {
  margin-left: 24px;
  font-size: 11px;
  word-break: break-word;
}
input {
  font-size: 11px;
}
.comment-divider {
  display: block;
  width: 90%;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  height: 1px;
  background-color: rgb(119, 115, 115);
}
</style>

<i18n>
{
  "en": {
    "modify": "Modify",
    "delete": "Delete",
    "deleteDiscussion": "Delete Discussion",
    "comment": "Comment"
  },
  "fr": {
    "modify": "Modifier",
    "delete": "Supprimer",
    "deleteDiscussion": "Supprimer la discussion",
    "comment": "commenter"
  },
  "de": {
    "modify": "Modifikator",
    "delete": "LÖSCHEN",
    "deleteDiscussion": "Chat löschen",
    "comment": "Kommentator"
  }
}
</i18n>
