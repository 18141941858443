<template>
  <div>
    <a
      :title="$t('search_google')"
      class="google-link"
      :href="'https://www.google.com/search?q=' + params.value"
      target="_blank"
      ><i class="fab fa-google"></i
    ></a>
    <a
      :title="$t('search_google_trends')"
      class="google-link"
      :href="
        'https://trends.google.fr/trends/explore?date=today%205-y&geo=FR&q=' +
        params.value
      "
      target="_blank"
      ><i class="fas fa-chart-line"></i
    ></a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
</style>

<i18n>
{
  "en": {
    "search_google": "Search in Google",
    "search_google_trends": "Search in Google trends"
  },
  "fr": {
    "search_google": "Lancer recherche dans Google",
    "search_google_trends": "Lancer recherche dans Google Trends"
  },
  "de": {
    "search_google": "Suche in Google starten",
    "search_google_trends": "Suche in Google Trends starten"
  }
}
</i18n>
