<template>
  <el-dialog
    append-to-body
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="40%"
    top="5vh"
  >
    <div class="d-flex mb-4">
      <h3 class="mr-auto ml-auto">{{ $t("reasonOfDelay") }}</h3>
    </div>
    <el-form
      label-width="200px"
      label-position="right"
    >
      <el-form-item :label="$t('reason')">
        <el-select v-model="form.reasonOfDelay">
          <el-option
            v-for="item in reasonOptions"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('details')">
        <el-input
          type="textarea"
          rows="3"
          v-model="form.detailsOfDelay"
        />
      </el-form-item>
    </el-form>
    <div class="d-flex mt-4">
      <el-button
        class="mr-auto ml-auto"
        type="success"
        plain
        @click="submit()"
      >
        {{ $t("confirm") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    backlink: Object,
  },
  data() {
    return {
      reasonOptions: [
        { label: "websiteNotResponding", value: "WEBSITE_NOT_RESPONDING" },
        {
          label: "modificationsTakeTooLong",
          value: "MODIFICATIONS_TAKE_TOO_LONG",
        },
        { label: "busyEditorialCalendar", value: "BUSY_EDITORIAL_CALENDAR" },
        { label: "other", value: "OTHER" },
      ],
      form: {
        reasonOfDelay: "MODIFICATIONS_TAKE_TOO_LONG",
        detailsOfDelay: "",
      },
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    submit() {
      this.dialogVisible = false;
      this.$emit("submit-form", this.form);
    },
  },
};
</script>

<style></style>
<i18n>
{
  "en": {
    "reasonOfDelay": "Reason for refusal",
    "confirm": "confirm",
    "details": "Specify the reason",
    "reason": "Reason",
    "websiteNotResponding": "The site is no longer responding",
    "modificationsTakeTooLong": "Modifications are in progress",
    "busyEditorialCalendar": "Editorial calendar too busy",
    "other": "Autre"
  },
  "fr": {
    "reasonOfDelay": "Raison du délais",
    "confirm": "Confirmer",
    "details": "Précisez la raison",
    "reason": "Raison",
    "websiteNotResponding": "Le site ne répond plus",
    "modificationsTakeTooLong": "Les modifications sont en cours",
    "busyEditorialCalendar": "Calendrier éditorial trop chargé",
    "other": "Autre"
  },
  "de": {
    "reasonOfDelay": "Grund für die Verzögerung",
    "confirm": "Bestätigen",
    "details": "Geben Sie den Grund an",
    "reason": "Raison",
    "websiteNotResponding": "Die Seite reagiert nicht mehr",
    "modificationsTakeTooLong": "Änderungen sind in Bearbeitung",
    "busyEditorialCalendar": "Überladener Redaktionskalender",
    "other": "Andere"
  }
}
</i18n>
