<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="75%"
    top="5vh"
  >
    <div slot="title">
      <h3 class="title">{{ $t("edit_client_info") }}</h3>
    </div>
    <div v-if="data.update">
      <dynamic-form
        v-model="updatedClient"
        :form="data.update.form"
      >
      </dynamic-form>
      <div class="submit">
        <el-button
          type="success"
          plain
          @click="submit()"
        >
          {{ $t("validate") }}
        </el-button>
      </div>
    </div>
    <div v-else>
      <editorial-brief-form
        :readClientInfoLink="data.read"
        :disabled="true"
      >
      </editorial-brief-form>
    </div>
  </el-dialog>
</template>

<script>
import DynamicForm from "@/components/DynamicForm";
import EditorialBriefForm from "@/components/EditorialBriefForm";
export default {
  props: {
    value: Boolean,
    data: Object,
  },
  components: {
    DynamicForm,
    EditorialBriefForm,
  },
  data() {
    return {
      updatedClient: {},
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    submit() {
      if (
        this.updatedClient.valueProposition.length != 0 &&
        this.updatedClient.product.length != 0
      ) {
        let details = {};
        details.form = this.updatedClient;
        details.link = this.data.update;
        this.$emit("update-client-info", details);
        this.dialogVisible = false;
      } else {
        this.$message({
          message: this.$i18n.t("emptyField"),
          type: "error",
          duration: 6000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
}

.submit {
  margin-top: 1rem;
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "edit_client_info": "Client Information",
    "emptyField": "Value Proposition and Product / Site service must not be empty.",
    "validate": "Validate"
  },
  "fr": {
    "edit_client_info": "Informations Client",
    "emptyField": "Proposition de valeur et Produit / service du site ne doivent pas être vides",
    "validate": "Valider"
  },
  "de": {
    "edit_client_info": "Kundeninformation",
    "emptyField": "Leistungsversprechen und Produkt/Service der Website dürfen nicht leer sein",
    "validate": "Bestätigen"
  }
}
</i18n>
