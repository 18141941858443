<template>
  <div>
    <div>
      <el-table :data="competitorsPages">
        <el-table-column
          :prop="popupTitle"
          :label="capitalizeTitle"
          :min-width="40"
        >
          <template slot-scope="scope">
            <p class="textBreakLine">{{ scope.row[popupTitle] }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="url"
          label="Url"
          :min-width="50"
        >
          <template slot-scope="scope">
            <a
              :href="scope.row.url"
              target="_blank"
              >{{ scope.row.url }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="crawlDate"
          :label="$t('date')"
          :min-width="10"
        >
          <template slot-scope="scope">
            <span>
              {{
                new Date(scope.row.crawlDate).toLocaleDateString($i18n.locale)
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    popupTitle: String,
    competitorsPages: Array,
  },
  computed: {
    capitalizeTitle() {
      return this.$t("tag") + " " + this.popupTitle;
    },
  },
};
</script>

<style scoped>
.textBreakLine {
  word-break: break-word;
}
</style>

<i18n>
  {
    "en": {
      "title": "Title tags of your competitors: be inspired!",
      "metaDescription": "Meta description tags of your competitors: be inspired!",
      "date": "Retrieved on",
      "tag": "Tag"
    },
    "fr": {
      "title": "Balises Title de vos concurents : inspirez-vous !",
      "metaDescription": "Balises Meta description de vos concurents : inspirez-vous !",
      "date": "Récupérée le",
      "tag": "Balise"
    },
    "de": {
      "title": "Title-Tag Ihrer Mitbewerber: Lassen Sie sich inspirieren !",
      "metaDescription": "Meta-Tag Ihrer Mitbewerber: Lassen Sie sich inspirieren !",
      "date": "Erholte sich weiter",
      "tag": "Tag"
    }
  }
</i18n>
