import Metrics from "@/pages/KeywordsSearch/Shared/Metrics";
import { RepositoryFactory } from "@/services/repositoryFactory";
const KeywordsSearchesRepository = RepositoryFactory.get("keywordsSearches");

export const keywordsSearchMixin = {
  props: {
    account: Object,
    tags: Array,
  },
  components: {
    Metrics,
  },
  data() {
    return {
      request: null,
      data: null,
      isLoading: false,
    };
  },
  methods: {
    updateRequest(request) {
      this.request = request;
      this.fetchData();
    },
    fetchData() {
      this.isLoading = true;
      KeywordsSearchesRepository.createSearch(this.account.id, this.request)
        .then((data) => {
          if (
            data.keywordsBroad.length > 0 ||
            data.keywordsExact.length > 0 ||
            data.keywordsQuestions.length > 0 ||
            data.keywordsStrict.length > 0
          ) {
            this.data = data;
          } else {
            this.$message.error(this.$i18n.t("no_data"));
            this.data = null;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.$message.error(this.$i18n.t("create_search_fail"));
          this.isLoading = false;
        });
    },
  },
};
