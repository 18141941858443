<template>
  <el-form-item
    :label="$t('scope')"
    label-width="60px"
    class="scope-form-item"
  >
    <el-select
      v-model="scope"
      :placeholder="$t('select_scope')"
    >
      <el-option
        v-for="item in urlScopeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  props: {
    value: String,
  },
  computed: {
    scope: {
      get() {
        return this.value;
      },
      set(scope) {
        this.$emit("input", scope);
      },
    },
  },
  data() {
    return {
      urlScopeOptions: [
        { value: "DOMAIN", label: this.$i18n.t("domain") },
        { value: "SUB_DOMAIN", label: this.$i18n.t("subdomain") },
        { value: "FOLDER", label: this.$i18n.t("folder") },
        { value: "URL", label: this.$i18n.t("url") },
      ],
    };
  },
};
</script>

<i18n>
{
  "en": {
    "scope": "Scope",
    "select_scope": "Select scope",
    "domain": "Domain",
    "subdomain": "Subdomain",
    "folder": "Folder",
    "url": "Url"
  },
  "fr": {
    "scope": "Portée",
    "select_scope": "Sélectionnez la portée",
    "domain": "Domaine",
    "subdomain": "Sous-domaine",
    "folder": "Dossier",
    "url": "Url"
  },
  "de": {
    "scope": "Umfang",
    "select_scope": "Bereich auswählen",
    "domain": "Domain",
    "subdomain": "Subdomäne",
    "folder": "Fall",
    "url": "URL"
  }
}
</i18n>
