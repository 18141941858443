<template>
  <div>
    <el-tag
      class="print-background"
      :type="getPriorityStyle()"
    >
      {{ this.getPriorityText() }}
    </el-tag>
  </div>
</template>

<script>
export default {
  methods: {
    getPriorityStyle() {
      if (this.params.value < 25) {
        return "info";
      } else if (this.params.value < 75) {
        return "warning";
      } else {
        return "danger";
      }
    },
    getPriorityText() {
      if (this.params.value < 25) {
        return this.$i18n.t("info");
      } else if (this.params.value < 50) {
        return this.$i18n.t("warning");
      } else if (this.params.value < 75) {
        return this.$i18n.t("alert");
      } else {
        return this.$i18n.t("critical");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-progress {
  line-height: inherit;
}

@media print {
  .print-background {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>

<i18n>
{
  "en": {
    "category": "Category"
  },
  "fr": {
    "info": "Info",
    "warning": "Warning",
    "alert": "Alerte",
    "critical": "Critique"
  },
  "de": {
    "info": "Die Info",
    "warning": "Warnung",
    "alert": "Alarm",
    "critical": "Kritik"
  }
}
</i18n>
