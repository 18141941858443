<template>
  <span>
    <el-tooltip
      v-if="params.data.tags && params.data.tags.length > 0 && keywordReady"
      placement="right"
      transition="el-fade-in"
    >
      <div
        slot="content"
        class="tooltip-ellipsis"
      >
        {{ params.data.tags.join(", ") }}
      </div>
      <span
        :id="'keyword-' + params.data.id"
        class="keyword"
        @click="params.context.showKeyword(params.data.id)"
        >{{ params.value }}</span
      >
    </el-tooltip>
    <span
      :id="'keyword-' + params.data.id"
      v-else-if="keywordReady"
      class="keyword"
      @click="params.context.showKeyword(params.data.id)"
      >{{ params.value }}</span
    >
    <span
      :id="'keyword-' + params.data.id"
      v-else
      >{{ params.value }}</span
    >
  </span>
</template>

<script>
export default {
  computed: {
    keywordReady() {
      return this.params.data.status == "READY" || !this.params.data.status;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.tooltip-ellipsis {
  text-overflow: ellipsis;
  max-width: 500px;
}

.keyword {
  cursor: pointer;
  color: $--link-color;

  &:hover {
    color: $--link-hover-color;
  }
}
</style>
