<template>
  <highcharts
    ref="highchart"
    :options="chartOptions"
    :updateArgs="updateArgs"
  ></highcharts>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    mainSeries: Object,
    conversionGroups: Object,
  },
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          zoomType: "xy",
        },
        title: {
          text: this.$i18n.t("conversionEvolution"),
        },
        yAxis: {
          title: {
            text: this.$i18n.t("conversions"),
          },
          min: 0,
        },
        xAxis: [
          {
            type: "datetime",
          },
        ],
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "{point.name:%d-%m-%Y}: {point.y}",
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        credits: {
          enabled: false,
        },
        series: this.series,
      };
    },
    conversionChartGroups() {
      if (this.conversionGroups) {
        return Object.entries(this.conversionGroups)
          .filter(([name]) => name)
          .map(([name, dataPoints], index) => {
            const dataPointsFormatted = dataPoints.map((dataPoint) => {
              return {
                x: new Date(dataPoint.date),
                y: Math.round(dataPoint.value),
              };
            });
            return {
              type: "column",
              name: name,
              data: dataPointsFormatted,
              color: Highcharts.Color(
                Highcharts.getOptions().colors.slice(4, 1)[index]
              ).get(),
              tooltip: {
                headerFormat: "<br><b>{series.name}</b><br>",
                pointFormatter: function () {
                  if (this.percentage) {
                    return (
                      this.x.toLocaleDateString().replace("/", "-") +
                      ": " +
                      this.y +
                      " (" +
                      Math.round(this.percentage) +
                      "%)"
                    );
                  }
                  return (
                    this.x.toLocaleDateString().replace("/", "-") +
                    ": " +
                    this.y
                  );
                },
              },
            };
          });
      } else {
        return [];
      }
    },
    mainSeriesChart() {
      if (this.mainSeries) {
        return Object.values(this.mainSeries).map((dataPoints, index) => {
          const dataPointsFormatted = dataPoints.map((dataPoint) => {
            return {
              x: new Date(dataPoint.date),
              y: dataPoint.value,
              name: new Date(dataPoint.display_date),
            };
          });
          if (index == 0) {
            return {
              type: "spline",
              name: this.$i18n.t("conversions"),
              data: dataPointsFormatted,
              color: Highcharts.Color(Highcharts.getOptions().colors[4]).get(),
            };
          } else {
            return {
              type: "spline",
              name: this.$i18n.t("comparativeConversions"),
              data: dataPointsFormatted,
              dashStyle: "ShortDot",
              color: Highcharts.Color(Highcharts.getOptions().colors[4])
                .setOpacity(0.7)
                .get(),
            };
          }
        });
      } else {
        return [];
      }
    },
    series() {
      return this.conversionChartGroups.concat(this.mainSeriesChart);
    },
  },
};
</script>

<style scoped lang="scss">
.group-buttons {
  margin-bottom: 1rem;

  .label {
    margin-right: 1rem;
  }

  .active {
    color: #3a8ee6;
    border-color: #3a8ee6;
    outline: 0;
    z-index: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "groupBy": "Group by",
    "none": "None",
    "conversionEvolution": "SEO Conversion evolution",
    "conversions": "Conversions",
    "comparativeConversions": "Comparative conversions"
  },
  "fr": {
    "groupBy": "Grouper par",
    "none": "Aucun",
    "conversionEvolution": "Evolution de la conversion SEO",
    "conversions": "Conversions",
    "comparativeConversions": "Conversions comparatives"
  },
  "de": {
    "groupBy": "Gruppiere nach",
    "none": "Keiner",
    "conversionEvolution": "Evolution der Conversion-SEO",
    "conversions": "Konvertierungen",
    "comparativeConversions": "Vergleichende Umrechnungen"
  }
}
</i18n>
