<template>
  <div>
    <div class="chart-title">
      <span>{{ $t("shareOfVoices") }}</span>
    </div>
    <highcharts
      v-if="isMounted"
      v-loading="isLoading"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import { mapState } from "vuex";

export default {
  props: {
    data: Array,
    isLoading: Boolean,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      isMounted: false,
      chartOptions: {
        chart: {
          type: "column",
          inverted: true,
        },
        title: {
          text: "",
        },
        xAxis: {
          labels: {
            formatter() {
              if (this.axis.userOptions.studyUrl == this.value) {
                return "<span style='color: #28a745'>" + this.value + "</span>";
              }
              return this.value;
            },
          },
        },
        yAxis: {
          labels: {
            format: "{value}%",
          },
          title: "",
        },
        series: [],
        legend: {
          maxHeight: 100,
          reversed: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        tooltip: {
          pointFormatter: function () {
            return (
              this.series.name +
              " : <b>" +
              this.y +
              "%</b> (" +
              this.trafficTooltip +
              ")"
            );
          },
        },
      },
    };
  },
  computed: {
    ...mapState(["study"]),
  },
  methods: {
    updateSeries() {
      if (!this.data || this.data.size == 0) {
        this.chartOptions.series = [];
        return;
      }

      let categories = [];
      let series1to3Bucket = [];
      let series4to10Bucket = [];
      this.data.slice(0, 15).forEach((urlData) => {
        categories.push(urlData.url.url);

        series1to3Bucket.push({
          y: urlData.bucket1to3.percentageOfTrafficShare,
          trafficTooltip: this.$i18n.t("trafficTooltip", {
            traffic: urlData.bucket1to3.estimatedTraffic,
          }),
        });
        series4to10Bucket.push({
          y: urlData.bucket4to10.percentageOfTrafficShare,
          trafficTooltip: this.$i18n.t("trafficTooltip", {
            traffic: urlData.bucket4to10.estimatedTraffic,
          }),
        });
      });

      this.chartOptions.xAxis.categories = categories;
      this.chartOptions.xAxis.studyUrl = this.study.url;
      this.chartOptions.series = [
        {
          name: this.$i18n.t("shareOfVoice4To10"),
          data: series4to10Bucket,
          color: "#434348",
        },
        {
          name: this.$i18n.t("shareOfVoice1To3"),
          data: series1to3Bucket,
          color: "#7cb5ec",
        },
      ];
    },
  },
  watch: {
    data() {
      if (this.data) this.updateSeries();
    },
  },
  mounted() {
    this.isMounted = true;
    if (this.data) this.updateSeries();
  },
};
</script>

<style scoped lang="scss">
.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
}

@media print {
  .fa-question-circle {
    display: none;
  }
}
</style>

<i18n>
{
  "en": {
    "shareOfVoices": "Share of voices",
    "shareOfVoice1To3": "Share of voice 1-3 ranking",
    "shareOfVoice4To10": "Share of voice 4-10 ranking",
    "trafficTooltip": "Estimated traffic: %{traffic}"
  },
  "fr": {
    "shareOfVoices": "Part de voix des concurrents",
    "shareOfVoice1To3": "Parts de voix positions 1-3",
    "shareOfVoice4To10": "Parts de voix positions 4-10",
    "trafficTooltip": "Trafic estimé: %{traffic}"
  },
  "de": {
    "shareOfVoices": "Share of Voice der Wettbewerber",
    "shareOfVoice1To3": "Voice teilt die Positionen 1-3",
    "shareOfVoice4To10": "Voice teilt die Positionen 4-10",
    "trafficTooltip": "Geschätzter Traffic: %{traffic}"
  }
}
</i18n>
