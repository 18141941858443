import axios from "axios";

export const contentOptimizationMixin = {
  data() {
    return {
      request: null,
      contentOptimizationData: null,
      isLoadingContentOptimization: false,
    };
  },
  methods: {
    async getMultipleContentOptimization(metaContentsResponse) {
      let urls = metaContentsResponse.map((element) => element.url);
      let request = {
        keywordsWithUrls: [
          {
            keyword: {
              text: this.keyword.text,
              locationId: this.keyword.loc_id,
            },
            urls: urls,
          },
        ],
        searchEngineParameters: {
          country: this.defaultSearchEngine.isoCountryCode,
          language: this.defaultSearchEngine.isoLanguageCode,
        },
      };
      try {
        let response = await this.$api.post(
          "/semantic/urls-content-optimization",
          request
        );
        let seoScores = [];
        urls.forEach((url) => {
          let element = response.data.data[0].urlsRecommendations.find((responseElement) => {
              let decodedUrl = decodeURIComponent(url);
              return (responseElement.url == decodedUrl);
            }
          );
          if (element) {
            let scores = this.contentRelevanceScoresForMultipleUrlsEndpoint(element);
            seoScores.push({
              url: element.url,
              ownScore: scores.seoScore,
            });
          }
        });
        return seoScores;
      } catch (error) {
        console.error(error);
      }
    },

    fetchContentOptimization(contentId, metaDesc, title, text) {
      this.isLoadingContentOptimization = true;

      let request = {
        text: text,
        metaDescription: metaDesc,
        title: title,
      };

      this.$api
        .post("contents/" + contentId + "/semantic", request)
        .then((response) => {
          this.contentOptimizationData = response.data.data;
          this.isLoadingContentOptimization = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoadingContentOptimization = false;
        });
    },
    fetchPublicContentContentOptimization(metaDesc, title, text, publicId) {
      this.isLoadingContentOptimization = true;

      let request = {
        text: text,
        metaDescription: metaDesc,
        title: title,
      };

      axios
        .post(
          "https://" +
            process.env.API_HOST +
            `/contents/public/${publicId}/semantic`,
          request
        )
        .then((response) => {
          this.contentOptimizationData = response.data.data;
          this.isLoadingContentOptimization = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoadingContentOptimization = false;
        });
    },
    contentRelevanceScoresForMultipleUrlsEndpoint(element) {
      if (
        !element ||
        !element.recommendations.data.semantic ||
        !element.recommendations.data.semantic.serpDetails
      )
        return { seoScore: 0 };
      return { seoScore: element.recommendations.data.semantic.score * 100 };
    },
  },
  computed: {
    contentLength() {
      let content = this.contentText;
      if (content == "<p></p>" || !content) return 0;
      content = content.replace(/\<span data-comment-id[^>]*>|<\/span>/g, "");
      content = content.replace(/<[^>]*>/g, " ");
      content = content.replace(/\s+/g, " ");
      content = content.trim();
      return content.split(" ").length;
    },
    seoScore() {
      if (!this.contentOptimizationData) return 0;
      return this.contentOptimizationData.score * 100;
    },
    serpSeoScore() {
      if (!this.contentOptimizationData) return 0;
      return (
        this.h1Scores.serpScore * 0.2 +
        this.contentLengthScores.serpScore * 0.3 +
        this.contentRelevanceScores.serpScore * 0.4 +
        this.titleScores.serpScore * 0.1
      );
    },
    recommendedKeywords() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.semantic
      )
        return [];
      return this.contentOptimizationData.semantic.keywordsDetails;
    },
    relatedKeywords() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.additionalKeywords
      )
        return [];
      return this.contentOptimizationData.additionalKeywords.relatedKeywords;
    },
    peopleAlsoAskKeywords() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.additionalKeywords
      )
        return [];
      return this.contentOptimizationData.additionalKeywords.peopleAlsoAsk;
    },
    h1Scores() {
      if (!this.contentOptimizationData || !this.contentOptimizationData.tagH1)
        return { ownScore: 0, serpScore: 0 };
      const sumSerpScore =
        this.contentOptimizationData.tagH1.serpDetails.reduce(function (a, b) {
          return a + b.score;
        }, 0);
      const serpScore =
        sumSerpScore / this.contentOptimizationData.tagH1.serpDetails.length;
      return {
        ownScore: this.contentOptimizationData.tagH1.score * 100,
        serpScore: serpScore * 100,
      };
    },
    titleScores() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.tagTitle
      )
        return { ownScore: 0, serpScore: 0 };
      const sumSerpScore =
        this.contentOptimizationData.tagTitle.serpDetails.reduce(function (
          a,
          b
        ) {
          return a + b.score;
        },
        0);
      const serpScore =
        sumSerpScore / this.contentOptimizationData.tagTitle.serpDetails.length;
      return {
        ownScore: this.contentOptimizationData.tagTitle.score * 100,
        serpScore: serpScore * 100,
      };
    },
    metaDescScores() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.tagMetaDescription
      )
        return { ownScore: 0, serpScore: 0 };
      const sumSerpScore =
        this.contentOptimizationData.tagMetaDescription.serpDetails.reduce(
          function (a, b) {
            return a + b.score;
          },
          0
        );
      const serpScore =
        sumSerpScore /
        this.contentOptimizationData.tagMetaDescription.serpDetails.length;
      return {
        ownScore: this.contentOptimizationData.tagMetaDescription.score * 100,
        serpScore: serpScore * 100,
      };
    },
    contentLengthScores() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.contentLength
      )
        return { ownScore: 0, serpScore: 0 };
      const sumSerpScore =
        this.contentOptimizationData.contentLength.serpDetails.reduce(function (
          a,
          b
        ) {
          return a + b.score;
        },
        0);
      const serpScore =
        sumSerpScore /
        this.contentOptimizationData.contentLength.serpDetails.length;
      return {
        ownScore: this.contentOptimizationData.contentLength.score * 100,
        serpScore: serpScore * 100,
      };
    },
    contentRelevanceScores() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.semantic
      )
        return { ownScore: 0, serpScore: 0 };
      const sumSerpScore =
        this.contentOptimizationData.semantic.serpDetails.reduce(function (
          a,
          b
        ) {
          return a + b.score;
        },
        0);
      const serpScore =
        sumSerpScore / this.contentOptimizationData.semantic.serpDetails.length;
      return {
        ownScore: this.contentOptimizationData.semantic.score * 100,
        serpScore: serpScore * 100,
      };
    },
    serpTitles() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.tagTitle
      )
        return [];
      return [
        ...new Set(
          this.contentOptimizationData.tagTitle.serpDetails.map(
            (serpDetail) => serpDetail.titles[0]
          )
        ),
      ];
    },
    ownH1() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.tagH1 ||
        !this.contentOptimizationData.tagH1.h1s.length > 0
      )
        return "";
      return this.contentOptimizationData.tagH1.h1s[0];
    },
    serpH1s() {
      if (!this.contentOptimizationData || !this.contentOptimizationData.tagH1)
        return [];
      return [
        ...new Set(
          this.contentOptimizationData.tagH1.serpDetails.map(
            (serpDetail) => serpDetail.h1s[0]
          )
        ),
      ];
    },
    serpMetaDescs() {
      if (
        !this.contentOptimizationData ||
        !this.contentOptimizationData.tagMetaDescription
      )
        return [];
      return [
        ...new Set(
          this.contentOptimizationData.tagMetaDescription.serpDetails.map(
            (serpDetail) => serpDetail.metaDescriptions[0]
          )
        ),
      ];
    },
    htmlContent() {
      let htmlContent = "";
      if (this.tagMetaDescription && this.tagMetaDescription != "")
        htmlContent +=
          "<head><meta name='description' content=" +
          this.tagMetaDescription +
          "></head>";
      if (this.tagTitle && this.tagTitle != "")
        htmlContent += "<title>" + this.tagTitle + "</title>";
      htmlContent += this.contentText;
      return htmlContent;
    },
  },
};
