<template>
  <div class="kpi">
    <div v-loading="isLoading">
      <div class="main-text">
        {{ $t("entry_and_exit") }} Position {{ start }} {{ $t("to") }} {{ end }}
      </div>
      <span class="metrics-data"
        >{{ nbKeywordEntry }} <i class="fas fa-arrow-up"></i> /
        {{ nbKeywordExit }} <i class="fas fa-arrow-down"></i
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bucketData: Object,
    start: Number,
    end: Number,
  },
  components: {},
  data() {
    return {
      nbKeywordEntry: 0,
      nbKeywordExit: 0,
      isLoading: false,
    };
  },
  methods: {
    refreshData() {
      this.isLoading = true;
      this.nbKeywordEntry = 0;
      this.nbKeywordExit = 0;

      if (this.bucketData == null) return;

      let topMovement = this.bucketData["entries"].find(
        (topMovement) =>
          topMovement.positionMin == this.start &&
          topMovement.positionMax == this.end
      );
      this.nbKeywordEntry = topMovement.nbKeywords;

      topMovement = this.bucketData["exits"].find(
        (topMovement) =>
          topMovement.positionMin == this.start &&
          topMovement.positionMax == this.end
      );
      this.nbKeywordExit = topMovement.nbKeywords;

      this.isLoading = false;
    },
  },
  watch: {
    bucketData() {
      this.refreshData();
    },
  },
  created() {
    this.refreshData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.kpi {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  .main-text {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
}
.metrics-data {
  font-size: 1.25rem;
}

.fa-arrow-up {
  color: $--color-success;
}

.fa-arrow-down {
  color: $--color-danger;
}
</style>

<i18n>
{
  "en": {
    "entry_and_exit": "Entry / Exit",
    "to": "to"
  },
  "fr": {
    "entry_and_exit": "Entrée / Sortie",
    "to": "à"
  },
  "de": {
    "entry_and_exit": "Ausgang eingeben",
    "to": "Zu"
  }
}
</i18n>
