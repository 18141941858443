<template>
  <el-dialog
    v-if="metaContentsData"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="75%"
    top="5vh"
  >
    <div
      slot="title"
      class="mr-5"
    >
      <div>
        <h3>
          {{ $t("tagH1") }} <strong>{{ keyword.text }}</strong>
        </h3>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between">
      <div>
        <div class="mb-4">
          <h4>{{ $t("yourH1") }}</h4>
          <i
            ><b>{{ tagH1 }}</b></i
          >
        </div>
        <div class="m-3">
          <div
            v-for="(reco, index) in tagH1Recommandations"
            v-bind:key="index"
          >
            {{ reco }}
          </div>
        </div>
      </div>
      <slot />
    </div>
    <div>
      <h4>{{ $t("h1sOfCompetitors") }}</h4>
      <el-table
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          prop="h1"
          :label="$t('tagH1')"
        >
          <template slot-scope="scope">
            <i>{{ scope.row.h1 }}</i>
          </template>
        </el-table-column>
        <el-table-column
          prop="url"
          label="URL"
          class-name="wrap-text"
        >
          <template slot-scope="scope">
            <a
              :href="scope.row.url"
              target="_blank"
              >{{ scope.row.url }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="lastCrawlDate"
          :label="$t('fetchedAt')"
          width="150"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.lastCrawlDate }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import H1Scores from "@/components/ContentOptimization/H1Scores";

export default {
  components: {
    H1Scores,
  },
  props: {
    value: Boolean,
    metaContentsData: Object,
    keyword: Object,
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    tagH1() {
      if (
        this.metaContentsData &&
        this.metaContentsData.urlContentLength &&
        this.metaContentsData.urlContentLength.h1s
      ) {
        return this.metaContentsData.urlContentLength.h1s.length > 0
          ? this.metaContentsData.urlContentLength.h1s[0]
          : null;
      }
    },
    tableData() {
      if (this.metaContentsData && this.metaContentsData.metaContents) {
        return this.metaContentsData.metaContents.map((serpDetail) => {
          if (serpDetail.h1s && serpDetail.h1s.length > 0) {
            return {
              url: serpDetail.url,
              h1: serpDetail.h1s[0],
              lastCrawlDate: serpDetail.lastCrawlDate,
            };
          } else {
            return {
              url: serpDetail.url,
              h1: "",
              lastCrawlDate: serpDetail.lastCrawlDate,
            };
          }
        });
      }
    },
    tagH1Recommandations() {
      let recommendations = [];
      if (
        this.metaContentsData &&
        this.metaContentsData.urlContentLength &&
        this.keyword
      ) {
        const tagH1 =
          this.metaContentsData.urlContentLength.h1s.length > 0
            ? this.metaContentsData.urlContentLength.h1s[0]
            : null;
        if (tagH1) {
          if (tagH1.length < 20) {
            recommendations.push(
              this.$i18n.t("tagH1IsTooShort", { size: tagH1.length })
            );
          } else if (tagH1.length > 100) {
            recommendations.push(
              this.$i18n.t("tagH1IsTooLong", { size: tagH1.length })
            );
          } else {
            recommendations.push(this.$i18n.t("tagH1IsGoodSize"));
          }

          if (tagH1.toUpperCase().includes(this.keyword.text.toUpperCase())) {
            recommendations.push(
              this.$i18n.t("tagH1ContainsKeyword", {
                keyword: this.keyword.text,
              })
            );
          } else {
            recommendations.push(
              this.$i18n.t("tagH1DoesntContainsKeyword", {
                keyword: this.keyword.text,
              })
            );
          }

          if (this.metaContentsData.urlContentLength.h1s.length > 1) {
            recommendations.push(this.$i18n.t("tagH1IsNotUniq"));
          } else {
            recommendations.push(this.$i18n.t("tagH1IsUniq"));
          }
        } else {
          recommendations.push(this.$i18n.t("noH1Detected"));
        }
      }
      return recommendations;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-text {
  white-space: nowrap;
  min-width: max-content;
  width: 50vw;
}
</style>

<i18n>
{
    "en": {
        "tagH1IsTooShort": "🔧 Your h1 tag is too short ({size} characters)! It should be between 20 and 100 characters.",
        "tagH1IsTooLong": "🔧 Your h1 tag is too long ({size} characters)! It should be between 20 and 100 characters.",
        "tagH1IsGoodSize": "👍 Your h1 tag is the perfect size (between 20 and 100 characters) !",
        "tagH1ContainsKeyword": "👍 Your h1 tag contains all the words from: {keyword}, it is very good !",
        "tagH1DoesntContainsKeyword": "🔧 Your h1 tag does not contain the keyword: {keyword}... Remember to use it!",
        "tagH1IsNotUniq": "🔧 H1 tag must be uniq on the page. Remove useless H1 tags!",
        "tagH1IsUniq": "👍 Very good, H1 tag exists and is unique!",
        "noH1Detected": "🔧 No H1 tag, add one!",
        "tagH1": "H1 tag",
        "h1sOfCompetitors": "Competitors' H1s",
        "yourH1": "Your H1: ",
        "fetchedAt": "Fetched on"
    },
    "fr": {
        "tagH1IsTooShort": "🔧 Votre balise h1 est trop courte ({size} caractères), elle devrait avoir entre 20 et 100 caractères !",
        "tagH1IsTooLong": "🔧 Votre balise h1 est trop longue ({size} caractères), elle devrait faire entre 20 et 100 caractères !",
        "tagH1IsGoodSize": "👍 Votre balise h1 a une taille parfaite (entre 20 et 100 caractères) !",
        "tagH1ContainsKeyword": "👍 Votre balise h1 contient tous les mots : {keyword}, c'est très bien !",
        "tagH1DoesntContainsKeyword": "🔧 Votre balise h1 ne contient pas le mot clé : {keyword}... Pensez à l'utiliser !",
        "tagH1IsNotUniq": "🔧 La balise H1 doit être unique sur la page. Retirez les balises H1 inutiles !",
        "tagH1IsUniq": "👍 C'est très bien, la balise H1 existe et est unique !",
        "noH1Detected": "🔧 Pas de balise H1 détectée, ajoutez en une !",
        "tagH1": "Balise H1",
        "h1sOfCompetitors": "Balises H1 de vos concurrents : inspirez-vous !",
        "yourH1": "Votre balise H1: ",
        "fetchedAt": "Récupérée le "
    },
    "de": {
        "tagH1IsTooShort": "🔧 Ihr h1-Tag ist zu kurz ({size} Zeichen)! Es sollte zwischen 20 und 100 Zeichen lang sein.",
        "tagH1IsTooLong": "🔧 Ihr h1-Tag ist zu lang ({size} characters)! Es sollte zwischen 20 und 100 Zeichen lang sein.",
        "tagH1IsGoodSize": "👍 Ihr h1-Tag hat die perfekte Größe (zwischen 20 und 100 Zeichen)!",
        "tagH1ContainsKeyword": "👍 Ihr h1-Tag enthält alle Wörter aus: {Schlüsselwort}, das ist sehr gut !",
        "tagH1DoesntContainsKeyword": "🔧 Ihr h1-Tag enthält nicht das Schlüsselwort: {keyword}... Remember to use it!",
        "tagH1IsNotUniq": "🔧 H1 tag must be uniq on the page. Remove useless H1 tags!",
        "tagH1IsUniq": "👍 Sehr gut, H1-Tag existiert und ist eindeutigÜbersetzt mit www.DeepL.com/Translator (kostenlose Version)",
        "noH1Detected": "🔧 Kein H1-Tag, füge einen hinzu!",
        "tagH1": "H1-Tag",
        "h1sOfCompetitors": "H1-Tags Ihrer Mitbewerber: Lassen Sie sich inspirieren!",
        "yourH1": "Ihr H1-Tag",
        "fetchedAt": "Abgerufen am"
    }
}
</i18n>
