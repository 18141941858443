import axios from "axios";
import humps from "humps";
import qs from "qs";

const baseURL = "/";
const apiClient = axios.create({
  baseURL,
});

document.addEventListener("DOMContentLoaded", () => {
  // Setup X-CSRF-Token header from rails generated token in meta tag
  apiClient.defaults.headers.common["X-CSRF-Token"] = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");
});

export default apiClient;
