<template>
  <div class="title">
    <h2>{{ title }}</h2>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 1.5rem;
}
</style>
