<template>
  <li class="nav-item pl-3">
    <a
      id="googleAnalytics4Link"
      v-if="googleAnalytics4Exists"
      :href="linkGoogleAnalytics4"
      class="nav-link"
    >
      Google Analytics - GA4 - BETA
    </a>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      linkGoogleAnalytics4: null,
      googleAnalytics4Anchor: "#googleAnalytics4",
    };
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
    googleAnalytics4Exists() {
      return this.studyLinks["ga4Configurations"];
    },
  },
  mounted() {
    //this.linkGoogleAnalytics4 = `/spa/to?redirect_uri=/studies/${this.study["id"]}/configuration?tab=ga4`
    this.linkGoogleAnalytics4 = `/spa-app/studies/${this.study["id"]}/configuration?tab=ga4`;
  },
};
</script>
