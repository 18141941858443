<template>
  <div>
    <app-introduction :title="$t('addKeywordsViaFile')"></app-introduction>

    <div class="container">
      <el-card v-if="!next">
        <div class="center">
          <div>
            <label for="avatar">{{ $t("importFormat") }}</label>
          </div>
          <div class="input">
            <div>
              <button
                class="styleClass"
                onclick="document.getElementById('avatar').click()"
              >
                {{ $t("importFile") }}
              </button>
            </div>
            <input
              type="file"
              class="hidden"
              id="avatar"
              name="avatar"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              style="display: none"
              @change="switchIsSelected"
            />
          </div>
          <span
            v-model="isFileSelected"
            v-if="this.isFileSelected === false"
            >{{ $t("noFileImported") }}</span
          >
          <el-button
            type="success"
            plain
            @click="previewFiles()"
            >{{ $t("validate") }}</el-button
          >
        </div>
        <el-link @click="getTemplate()"
          >{{ $t("template") }} <i class="fas fa-file-download"></i
        ></el-link>
      </el-card>
      <el-card v-else>
        <grid-import :data="response" />
      </el-card>
    </div>
  </div>
</template>

<script>
import AppIntroduction from "@/components/AppIntroduction";
import GridImport from "@/pages/Keywords/Import/ImportExcel/GridImport";
import { mapState } from "vuex";

import axios from "axios";

export default {
  components: {
    AppIntroduction,
  },
  data() {
    return {
      output: null,
      data: [],
      next: false,
      isLoading: false,
      response: null,
      isFileSelected: false,
    };
  },
  methods: {
    switchIsSelected(event) {
      this.isFileSelected = true;
    },
    previewFiles() {
      this.isLoading = true;
      var file = document.getElementById("avatar").files[0];
      if (!file) return;
      var promise = new Promise(this.getBuffer(file));
      promise
        .then((data) => {
          this.dataConstructor(data);
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    getTemplate() {
      axios
        .get("/assets/template.xlsx", {
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "template.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
    getBuffer(fileData) {
      return function (resolve) {
        var reader = new FileReader();
        reader.readAsArrayBuffer(fileData);
        reader.onload = function () {
          var arrayBuffer = reader.result;
          var bytes = new Uint8Array(arrayBuffer);
          var XLSX = require("xlsx");
          var workbook = XLSX.read(bytes, { type: "array" });
          var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          var out = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          resolve(out);
        };
      };
    },
    dataConstructor(data) {
      data.map((arr) => {
        var obj = {};
        if (typeof arr[0] !== "undefined" && typeof arr[1] != "undefined") {
          obj.text = arr[0].trim();
          obj.assignedUrl = arr[1];
          this.data.push(obj);
        }
      });
      this.data.shift();
      this.callAPI();
    },
    callAPI() {
      this.$api
        .post(`/studies/${this.study.id}/tracked-keywords/preview`, this.data)
        .then((rep) => {
          this.response = rep.data.data;
          this.next = true;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapState(["study"]),
  },
};
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input {
  flex: 1;
  margin: 0.5rem;
}
</style>

<i18n>
{
  "en": {
    "addKeywordsViaFile": "Page to keywords bulk association",
    "validate": "Validate",
    "importFormat": "Please import files with .xlsx format",
    "template": "Download the file template",
    "importFile": "Import file",
    "noFileImported": "No file imported"
  },
  "fr": {
    "addKeywordsViaFile": "Association de pages aux mots-clés",
    "validate": "Valider",
    "importFormat": "Veuillez importer un fichier au format .xlsx",
    "template": "Télécharger le template",
    "importFile": "Choisir un fichier à importer",
    "noFileImported": "Aucun fichier importé"
  },
  "de": {
    "addKeywordsViaFile": "Zuordnung von Seiten zu Schlüsselwörtern",
    "validate": "Bestätigen",
    "importFormat": "Bitte importieren Sie eine Datei im .xlsx-Format",
    "template": "Vorlage herunterladen",
    "importFile": "Wählen Sie eine zu importierende Datei aus",
    "noFileImported": "Keine importierte Datei"
  }
}
</i18n>
