<template>
  <el-date-picker
    v-model="period"
    type="daterange"
    :format="datePickerFormat"
    align="center"
    :picker-options="pickerOptions"
  >
  </el-date-picker>
</template>

<script>
import { DateTime } from "luxon";
import { dateToDateTimeUTC } from "@/javascripts/dateHelpers";

export default {
  props: {
    value: Object,
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
    period: {
      get() {
        if (this.value && this.value.start && this.value.end) {
          return [this.value.start, this.value.end];
        }
      },
      set(period) {
        const offset = period[0].getTimezoneOffset();
        const start = new Date(period[0].getTime() - offset * 60 * 1000);
        const end = new Date(period[1].getTime() - offset * 60 * 1000);
        this.$emit("input", {
          start: start.toISOString().split("T")[0],
          end: end.toISOString().split("T")[0],
        });
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1,
      },
    };
  },
  mounted() {
    if (!(this.value && this.value.start && this.value.end)) {
      const today = new Date();
      const oneMonthFromNow = new Date();
      oneMonthFromNow.setDate(today.getDate() + 30);
      this.period = [today, oneMonthFromNow];
    }
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
"en": {
  "period": "Period",
  "to": "To",
  "start_date": "Start date",
  "end_date": "End date"
},
"fr": {
  "period": "Période",
  "to": "à",
  "start_date": "Date de début",
  "end_date": "Date de fin"
},
"de": {
  "period": "Zeitraum",
  "to": "Zu",
  "start_date": "Startdatum",
  "end_date": "Endtermin"
}
}
</i18n>
