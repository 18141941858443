<template>
  <el-form-item :label="$t('period')">
    <el-date-picker
      v-model="period"
      type="daterange"
      :range-separator="$t('to')"
      :format="datePickerFormat"
      align="center"
      :start-placeholder="$t('start_date')"
      :end-placeholder="$t('end_date')"
      :picker-options="periodPickerOptions"
    >
    </el-date-picker>
  </el-form-item>
</template>

<script>
import { DateTime } from "luxon";
import { dateToDateTimeUTC } from "@/javascripts/dateHelpers";

export default {
  props: {
    value: Array,
    frequency: String,
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
    period: {
      get() {
        return [this.value[0].toJSDate(), this.value[1].toJSDate()];
      },
      set(period) {
        this.$emit("input", [
          dateToDateTimeUTC(period[0]),
          dateToDateTimeUTC(period[1]),
        ]);
      },
    },
    periodPickerOptions() {
      return {
        firstDayOfWeek: 1,
        disabledDate(time) {
          const dateTime = dateToDateTimeUTC(time);
          return (
            dateTime.diffNow("days").days > 0 ||
            dateTime.diffNow("days").days < -31
          );
        },
        shortcuts: [
          {
            text: this.$i18n.t("last_week"),
            onClick(picker) {
              const end = DateTime.utc();
              const start = end.minus({ weeks: 1 });
              picker.$emit("pick", [start.toJSDate(), end.toJSDate()]);
            },
          },
          {
            text: this.$i18n.t("last_month"),
            onClick(picker) {
              const end = DateTime.utc();
              const start = end.minus({ weeks: 4 });
              picker.$emit("pick", [start.toJSDate(), end.toJSDate()]);
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "period": "Period",
    "to": "To",
    "start_date": "Start date",
    "end_date": "End date",
    "last_week": "Last week",
    "last_month": "Last month",
    "last_3_months": "Last 3 months",
    "last_year": "Last year"
  },
  "fr": {
    "period": "Période",
    "to": "à",
    "start_date": "Date de début",
    "end_date": "Date de fin",
    "last_week": "Dernière semaine",
    "last_month": "Dernier mois",
    "last_3_months": "3 derniers mois",
    "last_year": "Dernière année"
  },
  "de": {
    "period": "Zeitraum",
    "to": "Zu",
    "start_date": "Startdatum",
    "end_date": "Endtermin",
    "last_week": "Letzte Woche",
    "last_month": "Im vergangenen Monat",
    "last_3_months": "letzten 3 Monate",
    "last_year": "Letztes Jahr"
  }
}
</i18n>
