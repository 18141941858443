<template>
  <div>
    <app-introduction
      :title="$t('all_keywords')"
      video-link="https://www.youtube.com/watch?v=db8ABP0IwCE"
      :documentations="[
        {
          link: $t('documentationLink'),
          description: $t('documentation_desc'),
        },
      ]"
      :user="currentUser"
    >
      <template v-slot:right>
        <el-link :underline="false">
          <el-button
            size="small"
            type="primary"
            @click="exportAllKeywords()"
          >
            <i class="fas fa-file"></i>
            {{ $t("export_keywords") }}
          </el-button>
        </el-link>
        <el-link
          :href="importPath"
          :underline="false"
        >
          <el-button
            size="small"
            type="primary"
          >
            {{ $t("manageMyKeywords") }}
          </el-button>
        </el-link>
      </template>
    </app-introduction>

    <div class="container">
      <div>
        <keyword-search-form
          @searchParametersChanged="updateRequestParams"
          :initial-search-engine="
            study.searchEngines.find(
              (searchEngine) => searchEngine.id === study.defaultSearchEngineId
            )
          "
          :tags="tags"
          :initial-period="period"
          :is-loading="isSearchOngoing"
        ></keyword-search-form>

        <div>
          <charts
            v-if="request.searchEngine"
            :request="request"
            :study-id="studyId"
            :study-url="studyUrl"
            :tracked-keywords="trackedKeywords"
            :user="currentUser"
            :is-loading-tracked-keywords="isLoadingTrackedKeywords"
            @is-loading="(value) => (isLoadingCharts = value)"
          ></charts>

          <el-card>
            <el-tabs
              v-model="activeTab"
              :stretch="true"
            >
              <el-tab-pane name="allKeywords">
                <div slot="label">
                  {{ $t("all_keywords") }}
                  <span v-if="isLoadingTrackedKeywords"
                    >(<i class="fas fa-spinner fa-spin"></i>)</span
                  >
                  <span v-else-if="allKeywordsCount !== null"
                    >({{ allKeywordsCount }})</span
                  >
                </div>
                <all-keywords
                  v-if="request.searchEngine"
                  :study-id="studyId"
                  :tracked-keywords-metrics="trackedKeywordsMetrics"
                  :request="request"
                  :tags="userTags"
                  :current-user="currentUser"
                  :is-loading-metrics="isLoadingTrackedKeywords"
                  @refresh-tags="getTags"
                  @show-keyword="showKeyword"
                  @show-task="showTask"
                  @show-page="showPageDialog($event)"
                >
                </all-keywords>
              </el-tab-pane>
              <el-tab-pane name="topVariations">
                <div slot="label">
                  {{ $t("top_variations") }}
                  <span v-if="isLoadingTopMovements || isLoadingTrackedKeywords"
                    >(<i class="fas fa-spinner fa-spin"></i>)</span
                  >
                  <span
                    v-else-if="
                      topMovements.highest.metrics &&
                      topMovements.highest.metrics.numberOfKeywords !== null &&
                      topMovements.lowest.metrics &&
                      topMovements.lowest.metrics.numberOfKeywords !== null
                    "
                  >
                    ({{ topMovements.highest.metrics.numberOfKeywords
                    }}<i class="fas fa-arrow-up"></i>
                    {{ topMovements.lowest.metrics.numberOfKeywords
                    }}<i class="fas fa-arrow-down"></i>)
                  </span>
                </div>
                <div
                  v-loading="isLoadingTopMovements || isLoadingTrackedKeywords"
                >
                  <el-row>
                    <el-col
                      :xs="24"
                      :lg="12"
                    >
                      <top-movements
                        :study-id="studyId"
                        :keywords-data="topMovements.highest.keywordsData"
                        :total-traffic-variation="
                          topMovements.highest.metrics.totalTrafficVariation
                        "
                        top-type="ENTRY"
                        :start-date="startDate"
                        :end-date="endDate"
                        :exportableData="true"
                        @show-keyword="showKeyword"
                      >
                      </top-movements>
                    </el-col>
                    <el-col
                      :xs="24"
                      :lg="12"
                    >
                      <top-movements
                        :study-id="studyId"
                        :keywords-data="topMovements.lowest.keywordsData"
                        :total-traffic-variation="
                          topMovements.lowest.metrics.totalTrafficVariation
                        "
                        top-type="EXIT"
                        :start-date="startDate"
                        :end-date="endDate"
                        :exportableData="true"
                        @show-keyword="showKeyword"
                      >
                      </top-movements>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane name="topOneThree">
                <div slot="label">
                  {{ $t("top") }}
                  <div>
                    {{ $t("top_1_3") }}
                    <span
                      v-if="isLoadingTopMovements || isLoadingTrackedKeywords"
                      >(<i class="fas fa-spinner fa-spin"></i>)</span
                    >
                    <span
                      v-else-if="
                        topMovements1To3.entries.count !== null &&
                        topMovements1To3.exits.count !== null
                      "
                    >
                      ({{ topMovements1To3.entries.count
                      }}<i class="fas fa-arrow-up"></i>
                      {{ topMovements1To3.exits.count
                      }}<i class="fas fa-arrow-down"></i>)
                    </span>
                  </div>
                </div>
                <div
                  v-loading="isLoadingTopMovements || isLoadingTrackedKeywords"
                >
                  <el-row>
                    <el-col
                      :xs="24"
                      :lg="12"
                    >
                      <top-movements
                        :study-id="studyId"
                        :keywords-data="topMovements1To3.entries.keywordsData"
                        :total-traffic-variation="
                          topMovements1To3.totalTrafficVariationEntry
                        "
                        :start="1"
                        :end="3"
                        top-type="ENTRY"
                        :start-date="startDate"
                        :end-date="endDate"
                        :exportableData="true"
                        @show-keyword="showKeyword"
                      >
                      </top-movements>
                    </el-col>
                    <el-col
                      :xs="24"
                      :lg="12"
                    >
                      <top-movements
                        :study-id="studyId"
                        :keywords-data="topMovements1To3.exits.keywordsData"
                        :total-traffic-variation="
                          topMovements1To3.totalTrafficVariationExit
                        "
                        :start="1"
                        :end="3"
                        top-type="EXIT"
                        :start-date="startDate"
                        :end-date="endDate"
                        :exportableData="true"
                        @show-keyword="showKeyword"
                      >
                      </top-movements>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane name="topFourTen">
                <div slot="label">
                  {{ $t("top") }}
                  <div>
                    {{ $t("top_4_10") }}
                    <span
                      v-if="isLoadingTopMovements || isLoadingTrackedKeywords"
                      >(<i class="fas fa-spinner fa-spin"></i>)</span
                    >
                    <span
                      v-else-if="
                        topMovements4To10.entries.count !== null &&
                        topMovements4To10.exits.count !== null
                      "
                    >
                      ({{ topMovements4To10.entries.count
                      }}<i class="fas fa-arrow-up"></i>
                      {{ topMovements4To10.exits.count
                      }}<i class="fas fa-arrow-down"></i>)
                    </span>
                  </div>
                </div>
                <div
                  v-loading="isLoadingTopMovements || isLoadingTrackedKeywords"
                >
                  <el-row>
                    <el-col
                      :xs="24"
                      :lg="12"
                    >
                      <top-movements
                        :study-id="studyId"
                        :keywords-data="topMovements4To10.entries.keywordsData"
                        :total-traffic-variation="
                          topMovements4To10.totalTrafficVariationEntry
                        "
                        :start="4"
                        :end="10"
                        top-type="ENTRY"
                        :start-date="startDate"
                        :end-date="endDate"
                        :exportableData="true"
                        @show-keyword="showKeyword"
                      >
                      </top-movements>
                    </el-col>
                    <el-col
                      :xs="24"
                      :lg="12"
                    >
                      <top-movements
                        :study-id="studyId"
                        :keywords-data="topMovements4To10.exits.keywordsData"
                        :total-traffic-variation="
                          topMovements4To10.totalTrafficVariationExit
                        "
                        :start="4"
                        :end="10"
                        top-type="EXIT"
                        :start-date="startDate"
                        :end-date="endDate"
                        :exportableData="true"
                        @show-keyword="showKeyword"
                      >
                      </top-movements>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane name="topElevenThirty">
                <div slot="label">
                  {{ $t("top") }}
                  <div>
                    {{ $t("top_11_30") }}
                    <span
                      v-if="isLoadingTopMovements || isLoadingTrackedKeywords"
                      >(<i class="fas fa-spinner fa-spin"></i>)</span
                    >
                    <span
                      v-else-if="
                        topMovements11To30.entries.count !== null &&
                        topMovements11To30.exits.count !== null
                      "
                    >
                      ({{ topMovements11To30.entries.count
                      }}<i class="fas fa-arrow-up"></i>
                      {{ topMovements11To30.exits.count
                      }}<i class="fas fa-arrow-down"></i>)
                    </span>
                  </div>
                </div>
                <div
                  v-loading="isLoadingTopMovements || isLoadingTrackedKeywords"
                >
                  <el-row>
                    <el-col
                      :xs="24"
                      :lg="12"
                    >
                      <top-movements
                        :study-id="studyId"
                        :keywords-data="topMovements11To30.entries.keywordsData"
                        :total-traffic-variation="
                          topMovements11To30.totalTrafficVariationEntry
                        "
                        :start="11"
                        :end="30"
                        top-type="ENTRY"
                        :start-date="startDate"
                        :end-date="endDate"
                        :exportableData="true"
                        @show-keyword="showKeyword"
                      >
                      </top-movements>
                    </el-col>
                    <el-col
                      :xs="24"
                      :lg="12"
                    >
                      <top-movements
                        :study-id="studyId"
                        :keywords-data="topMovements11To30.exits.keywordsData"
                        :total-traffic-variation="
                          topMovements11To30.totalTrafficVariationExit
                        "
                        :start="11"
                        :end="30"
                        top-type="EXIT"
                        :start-date="startDate"
                        :end-date="endDate"
                        :exportableData="true"
                        @show-keyword="showKeyword"
                      >
                      </top-movements>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane
                :label="$t('positions_history')"
                name="positionsHistory"
                lazy
              >
                <positions-history
                  :study-id="studyId"
                  :study-response="studyResponse"
                  :tracked-keywords="trackedKeywords"
                  :url="url"
                  :start-date="startDate"
                  :end-date="endDate"
                  :frequency="request.frequency"
                  :search-engine="request.searchEngine"
                  @show-keyword="showKeyword"
                ></positions-history>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </div>
      <keyword-dialog
        v-if="keywordDialogVisible"
        v-model="keywordDialogVisible"
        :keyword-id="selectedKeywordId"
        :initial-date="endDate"
        :initial-past-date="startDate"
        :initial-search-engine="request.searchEngine"
        :initial-frequency="request.frequency"
        :currentUser="currentUser"
        :user-options="userOptions"
        :studyId="studyId"
      >
      </keyword-dialog>
      <el-dialog
        :visible.sync="taskDialogVisible"
        :close-on-click-modal="false"
        width="62%"
      >
        <task-creation-form
          v-if="selectedKeyword"
          :key="selectedKeyword.id"
          :study-id="study.id"
          :title="$t('work_on_keyword', { keywordText: selectedKeyword.text })"
          :keywords="[{ text: selectedKeyword.text, id: selectedKeyword.id }]"
          :current-user="currentUser"
          :user-options="userOptions"
          :assigned-to-user-id="currentUser.id"
          @on-created-task="taskDialogVisible = false"
        />
      </el-dialog>
      <el-dialog
        v-if="grDialogVisible"
        :destroy-on-close="true"
        :visible.sync="grDialogVisible"
        width="75%"
        top="5vh"
      >
        <div slot="title">
          <h4>
            Page :
            <a
              :href="pageUrl"
              target="_blank"
              >{{ pageUrl }} &nbsp;<i class="fas fa-external-link-alt"></i
            ></a>
          </h4>
        </div>
        <google-results-page
          :url="pageUrl"
          :initial-date="date"
          :initial-past-date="pastDate"
          :current-user="currentUser"
          :user-options="userOptions"
          :initial-search-engine="request.searchEngine"
          :initial-frequency="request.frequency"
        ></google-results-page>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import {dateTimeToEpochDay} from "@/javascripts/dateHelpers";
import AppIntroduction from "@/components/AppIntroduction";
import KeywordSearchForm from "@/components/KeywordSearchForm.vue";
import Charts from "@/pages/Keywords/Charts";
import AllKeywords from "@/pages/Keywords/AllKeywords";
import TopMovements from "@/pages/Keywords/TopMovements";
import PositionsHistory from "@/pages/Keywords/PositionsHistory";
import KeywordDialog from "@/pages/KeywordDialog";
import TaskCreationForm from "@/components/task/TaskCreationForm";
import GoogleResultsPage from "@/pages/GoogleResults";

import { RepositoryFactory } from "@/services/repositoryFactory";
import { topMovementsDataMixin } from "@/mixins/topMovementsDataMixin";
import { searchEngineMixin } from "@/mixins/searchEngineMixin";
import { formatDateWithDashes } from "@/javascripts/formatDate";

const KeywordsExportsRepository = RepositoryFactory.get("keywordsExports");
const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  components: {
    AppIntroduction,
    KeywordSearchForm,
    Charts,
    AllKeywords,
    TopMovements,
    PositionsHistory,
    KeywordDialog,
    TaskCreationForm,
    GoogleResultsPage,
  },
  mixins: [topMovementsDataMixin, searchEngineMixin],
  props: {
    studyId: Number,
    companyId: Number,
    currentUser: Object,
    importPath: String,
    studyUrl: String,
  },
  data() {
    return {
      trackedKeywordResponse: null,
      form: [],
      period: [
        DateTime.utc().startOf("week").minus({ weeks: 12 }),
        DateTime.utc().startOf("week"),
      ],
      activeTab: "allKeywords",
      request: {
        frequency: "WEEK",
        filterStudyTags: [],
        tagsGroups: [[]],
        startDate: {
          epochDay: dateTimeToEpochDay(
            DateTime.utc().startOf("week").minus({ weeks: 12 })
          ),
        },
        endDate: {
          epochDay: dateTimeToEpochDay(DateTime.utc().startOf("week")),
        },
      },
      isLoadingTopMovements: false,
      isLoadingCharts: false,
      isLoadingKeywords: false,
      isLoadingTrackedKeywords: false,
      topMovements: {
        highest: {
          count: null,
          keywordsData: null,
          metrics: {
            totalTrafficVariation: null,
            numberOfKeywords: null,
          },
        },
        lowest: {
          count: null,
          keywordsData: null,
          metrics: {
            totalTrafficVariation: null,
            numberOfKeywords: null,
          },
        },
      },
      topMovements1To3: {
        entries: {
          count: null,
          keywordsData: null,
        },
        exits: {
          count: null,
          keywordsData: null,
        },
      },
      topMovements4To10: {
        entries: {
          count: null,
          keywordsData: null,
        },
        exits: {
          count: null,
          keywordsData: null,
        },
      },
      topMovements11To30: {
        entries: {
          count: null,
          keywordsData: null,
        },
        exits: {
          count: null,
          keywordsData: null,
        },
      },
      tags: [],
      selectedKeywordId: null,
      keywordDialogVisible: false,
      taskDialogVisible: false,
      selectedKeyword: null,
      userOptions: [],
      pageUrl: null,
      grDialogVisible: false,
      url: null,
      studyResponse: null,
    };
  },
  computed: {
    userTags() {
      return this.tags.filter(
        (tag) => tag["name"] !== "All" && tag["name"] !== "None"
      );
    },
    endDate() {
      return new DateTime(this.request.endDate);
    },
    startDate() {
      return new DateTime(this.request.startDate);
    },
    trackedKeywordsMetrics() {
      if (this.trackedKeywordResponse) {
        return this.trackedKeywordResponse.meta;
      }
      return null;
    },
    trackedKeywords() {
      if (this.trackedKeywordResponse) {
        return this.trackedKeywordResponse.data;
      }
      return null;
    },
    allKeywordsCount() {
      if (this.trackedKeywordResponse) {
        return this.trackedKeywordResponse.meta.count;
      }
      return null;
    },
    ...mapState(["study"]),
    isSearchOngoing() {
      return (
        this.isLoadingCharts ||
        this.isLoadingTopMovements ||
        this.isLoadingKeywords ||
        this.isLoadingTrackedKeywords
      );
    },
  },
  watch: {
    request: {
      deep: true,
      handler(val, oldVal) {
        if (
          val.frequency !== oldVal.frequency ||
          JSON.stringify(val.filterStudyTags) !==
            JSON.stringify(oldVal.filterStudyTags) ||
          JSON.stringify(val.tagsGroups) !== JSON.stringify(oldVal.tagsGroups)
        ) {
          this.getTrackedKeywords();
        } else {
          this.getTopMovementsData();
        }
      },
    },
  },
  mounted() {
    this.request.rangeDate = {
      start: this.period[0].toString().split("T")[0],
      end: this.period[1].toString().split("T")[0],
    };
    this.request.startDate = DateTime.utc()
      .startOf("week")
      .minus({ weeks: 12 });
    this.request.endDate = DateTime.utc().startOf("week");
    this.request.searchEngine = this.study.searchEngines.find(
      (searchEngine) => searchEngine.id === this.study.defaultSearchEngineId
    );
    this.getTrackedKeywords();
  },
  methods: {
    formatDateWithDashes,
    getTrackedKeywords() {
      this.isLoadingTrackedKeywords = true;
      this.$api
        .get(`/studies/${this.studyId}`)
        .then((response) => {
          this.url = response.data.data.url;
          const linkTrackedKeywordSearch =
            response.data.links.searchTrackedKeywords;
          this.studyResponse = response.data;
          this.form = linkTrackedKeywordSearch.form;
          this.getTags();
          this.tags.push({ name: "None", count: 0 });
          let requestTrackedKeywordsSearch = {};
          if (this.request.frequency === "DAY") {
            requestTrackedKeywordsSearch.updateFrequency = "DAILY";
          }
          if (this.request.tagsGroups && this.request.tagsGroups.length > 0) {
            let tempTagsGroups = this.request.tagsGroups.slice();
            tempTagsGroups.forEach((tagsGroup, index, object) => {
              if (tagsGroup.length === 0) {
                object.splice(index, 1);
              }
            });
            if (tempTagsGroups.length > 0) {
              requestTrackedKeywordsSearch.tagsGroups = tempTagsGroups;
            }
          }

          if (
            this.request.filterStudyTags &&
            this.request.filterStudyTags.length > 0
          ) {
            requestTrackedKeywordsSearch.tags = this.request.filterStudyTags;
          }

          this.$api[linkTrackedKeywordSearch.method](
            linkTrackedKeywordSearch.href,
            requestTrackedKeywordsSearch
          )
            .then((responseTrackedKeywords) => {
              this.trackedKeywordResponse = responseTrackedKeywords.data;
              this.isLoadingTrackedKeywords = false;
              this.getTopMovementsData();
            })
            .catch((error) => {
              this.isLoadingTrackedKeywords = false;
              console.error(error);
            });
        })
        .catch((error) => {
          this.isLoadingTrackedKeywords = false;
          console.error(error);
        });
    },
    fetchUsers() {
      CompaniesRepository.getCompanyUsersForTasks(this.companyId).then(
        (data) => {
          this.userOptions = data.data.map((user) => ({
            id: parseInt(user.id),
            firstName: user.attributes.firstName,
            lastName: user.attributes.lastName,
          }));
        }
      );
    },
    getTopMovementsData() {
      if (!this.trackedKeywords) return;

      this.isLoadingTopMovements = true;

      let requestApi = {
        url: {
          url: this.study.url,
          type: "DOMAIN",
        },
        searchEngineParameters: this.getSearchEngineParametersFromRequest(
          this.request
        ),
        positionsBuckets: [
          { min: 1, max: 3 },
          { min: 4, max: 10 },
          { min: 11, max: 30 },
        ],
        frequency: this.request.frequency === "WEEK" ? "WEEKLY" : "DAILY",
        rangeDate: {
          start: this.request.rangeDate.start,
          end: this.request.rangeDate.end,
        },
      };
      requestApi.keywords = this.trackedKeywords.map((trackedKeyword) => {
        return {
          text: trackedKeyword.text,
          locationId: trackedKeyword.locationId,
        };
      });
      this.$api
        .post(`/positions/top-movements`, requestApi)
        .then((response) => {
          const topMovementsList = this.getTopMovementsDataFromResponse(
            response,
            this.trackedKeywords,
            this.topMovements,
            this.topMovements1To3,
            this.topMovements4To10,
            this.topMovements11To30
          );
          this.topMovements = topMovementsList.topMovements;
          this.topMovements1To3 = topMovementsList.topMovements1To3;
          this.topMovements4To10 = topMovementsList.topMovements4To10;
          this.topMovements11To30 = topMovementsList.topMovements11To30;
          this.isLoadingTopMovements = false;
        })
        .catch((error) => {
          this.topMovements = {
            highest: this.resetPrimaryTopMovementsData(),
            lowest: this.resetPrimaryTopMovementsData(),
          };
          this.topMovements1To3 = this.resetSecondaryTopMovementsData();
          this.topMovements4To10 = this.resetSecondaryTopMovementsData();
          this.topMovements11To30 = this.resetSecondaryTopMovementsData();
          this.isLoadingTopMovements = false;
          console.error(error);
        });
    },
    getTags() {
      this.tags = this.form[1].options.map((tag) => {
        return { name: tag.name, count: tag.numberOfKeywords };
      });
    },
    showKeyword(keywordId) {
      this.selectedKeywordId = keywordId;
      this.keywordDialogVisible = true;
    },
    showTask(keyword) {
      this.selectedKeyword = keyword;
      this.taskDialogVisible = true;
    },
    exportAllKeywords(type) {
      KeywordsExportsRepository.createExport(this.studyId, "xlsx")
        .then((data) => {
          this.$message({
            message: this.$i18n.t("export_keywords_success"),
            type: "success",
            duration: 6000,
            offset: 80,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("export_keywords_fail"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
          console.error(error);
        });
    },
    showPageDialog(details) {
      this.pageUrl = details;
      this.grDialogVisible = true;
    },
    resetPrimaryTopMovementsData() {
      return {
        count: null,
        keywordsData: null,
        metrics: { totalTrafficVariation: null, numberOfKeywords: null },
      };
    },
    resetSecondaryTopMovementsData() {
      return {
        entries: { count: null, keywordsData: null },
        exits: { count: null, keywordsData: null },
      };
    },
    updateRequestParams(newParams) {
      this.request = newParams;
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.el-card {
  margin-bottom: 1rem;
}

::v-deep .is-top {
  line-height: 15px;
}

.fa-arrow-up {
  color: $--color-success;
}

.fa-arrow-down {
  color: $--color-danger;
}
</style>

<i18n>
{
  "en": {
    "manageMyKeywords": "Manage my keywords",
    "all_keywords": "All keywords",
    "documentation_desc": "How to identify your best keywords",
    "explicative_video": "How to use this screeen",
    "keywords_of": "Keywords of %{url}",
    "top_variations": "Top variations",
    "top": "Entrances / Exits",
    "top_1_3": "Rankings 1 to 3",
    "top_4_10": "Rankings 4 to 10",
    "top_11_30": "Rankings 11 to 30",
    "positions_history": "Historical ranking",
    "work_on_keyword": "Work on keyword: %{keywordText}",
    "task_created_success": "Task created successfully",
    "task_created_failure": "Task creation failed",
    "export_keywords": "Export all keywords",
    "export_keywords_success": "Keywords were successfully exported, an email will be sent to you with the export file.",
    "export_keywords_fail": "Keywords export failed.",
    "documentationLink": "http://docs.smartkeyword.io/guide-smartkeyword/mots-cles/identifier-vos-meilleurs-mots-cles"
  },
  "fr": {
    "manageMyKeywords": "Gérer mes mots-clés",
    "all_keywords": "Tous les mots clés",
    "documentation_desc": "Comment identifier vos meilleurs mots clés",
    "explicative_video": "Comment utiliser cet écran",
    "keywords_of": "Mots clés de %{url}",
    "top_variations": "Top variations",
    "top": "Entrées / Sorties",
    "top_1_3": "Positions 1 à 3",
    "top_4_10": "Positions 4 à 10",
    "top_11_30": "Positions 11 à 30",
    "positions_history": "Historique des positions",
    "work_on_keyword": "Travailler le mot clé : %{keywordText}",
    "task_created_success": "Tâche créée avec succès",
    "task_created_failure": "La création de la tâche a échoué",
    "export_keywords": "Export tous les mots clés",
    "export_keywords_success": "Les mots clés ont été exportés avec succès, un email va vous être envoyé avec le fichier d'export.",
    "export_keywords_fail": "L'export de mots clés a échoué.",
    "documentationLink": "http://docs.smartkeyword.io/guide-smartkeyword/mots-cles/identifier-vos-meilleurs-mots-cles"
  },
  "de": {
    "manageMyKeywords": "Meine Keywords verwalten",
    "all_keywords": "Alle Schlüsselwörter",
    "documentation_desc": "So identifizieren Sie Ihre besten Keywords",
    "explicative_video": "So verwenden Sie diesen Bildschirm",
    "keywords_of": "%{url} Schlüsselwörter",
    "top_variations": "Top-Varianten",
    "top": "Einträge Ausgänge",
    "top_1_3": "Platz 1 bis 3",
    "top_4_10": "Platz 4 bis 10",
    "top_11_30": "Plätze 11 bis 30",
    "positions_history": "Standortgeschichte",
    "work_on_keyword": "Bearbeiten Sie das Schlüsselwort: %{keywordText}",
    "task_created_success": "Aufgabe erfolgreich erstellt",
    "task_created_failure": "Aufgabenerstellung fehlgeschlagen",
    "export_keywords": "Exportieren Sie alle Schlüsselwörter",
    "export_keywords_success": "Die Keywords wurden erfolgreich exportiert, Sie erhalten eine E-Mail mit der Exportdatei.",
    "export_keywords_fail": "Export der Keywords fehlgeschlagen.",
    "documentationLink": "http://docs.smartkeyword.io/guide-smartkeyword/mots-cles/identifier-vos-meilleurs-mots-cles"
  }
}
</i18n>
