import Repository from "@/services/repository";

export default {
  createTrafficMetrics(studyId, filters, period, comparativePeriod) {
    return Repository.post(`/a/${studyId}/traffic/metrics.json`, {
      facade: {
        filters,
        period,
        comparativePeriod,
      },
    }).then((response) => response.data);
  },

  createTrafficChart(studyId, filters, period, comparativePeriod, frequency) {
    return Repository.post(`/a/${studyId}/traffic/chart.json`, {
      facade: {
        filters,
        period,
        comparativePeriod,
        frequency,
      },
    }).then((response) => response.data);
  },

  createTrafficChartGroups(studyId, filters, group, period, frequency) {
    return Repository.post(`/a/${studyId}/traffic/chart_groups.json`, {
      facade: {
        filters,
        group,
        period,
        frequency,
      },
    }).then((response) => response.data);
  },

  createTrafficGrid(
    studyId,
    filters,
    group,
    period,
    comparativePeriod,
    preset
  ) {
    return Repository.post(`/a/${studyId}/traffic/grid.json`, {
      facade: {
        filters,
        group,
        period,
        comparativePeriod,
        preset,
      },
    }).then((response) => response.data);
  },

  createRecommendations(studyId, filters, period, comparativePeriod) {
    return Repository.get(`/a/${studyId}/traffic/export_recommendations`, {
      params: {
        filters,
        period,
        comparativePeriod,
      },
      responseType: "arraybuffer",
    }).then((response) => response.data);
  },
};
