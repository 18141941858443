import { Mark, getMarkRange } from "@tiptap/core";

export default Mark.create({
  name: "highlightSelection",
  group: "basic",
  spanning: false,
  priority: 10001,

  addAttributes() {
    return {
      color: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute("data-highlight-color") ||
          element.style.backgroundColor,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }
          return {
            "data-highlight-color": attributes.color,
            style: `background-color: ${attributes.color};`,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      insertHighlightSelection:
        (attributes) =>
        ({ state, dispatch }) => {
          let selection = state.selection;
          dispatch(
            state.tr.setMeta("thread", true).addMark(
              selection.from,
              selection.to,
              state.schema.marks.highlightSelection.create({
                color: attributes.color,
              })
            )
          );
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-highlight-color]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["span", HTMLAttributes];
  },
});
