import CompaniesRepository from "@/services/companiesRepository";
import CompanyGroupsRepository from "@/services/companyGroupsRepository";
import ConversionsRepository from "@/services/conversionsRepository";
import FilesRepository from "@/services/filesRepository";
import StudiesRepository from "@/services/studiesRepository";
import TrafficsRepository from "@/services/trafficsRepository";
import UsersRepository from "@/services/usersRepository";
import PagesRepository from "@/services/pagesRepository";
import KeywordsRepository from "@/services/keywordsRepository";
import ConfigurationsRepository from "@/services/configurationsRepository";
import AdminToolsRepository from "@/services/adminToolsRepository";
import AlertsRepository from "@/services/alertsRepository";
import KeywordsSearchesRepository from "@/services/keywordsSearchesRepository";
import KeywordsExportsRepository from "@/services/keywordsExportsRepository";
import RemoveKeywordsRepository from "@/services/removeKeywordsRepository";
import AddTagsRepository from "@/services/addTagsRepository";
import RemoveTagsRepository from "@/services/removeTagsRepository";
import KeywordPagesRepository from "@/services/keywordPagesRepository";
import CompetitorsRepository from "@/services/competitorsRepository";
import NetlinkingRepository from "@/services/netlinkingRepository";
import RefreshDataRepository from "@/services/refreshDataRepository";
import NpsRatingsRepository from "@/services/npsRatingsRepository";
import EditorialBriefRepository from "@/services/editorialBriefRepository";
import RecommendationsRepository from "@/services/recommendationsRepository";
import GoogleResultsRepository from "@/services/googleResultsRepository";
import SellsyRepository from "@/services/sellsyRepository";
import ImportKeywordsRepository from "@/services/importKeywordsRepository";

const repositories = {
  companies: CompaniesRepository,
  companyGroups: CompanyGroupsRepository,
  conversions: ConversionsRepository,
  studies: StudiesRepository,
  traffics: TrafficsRepository,
  users: UsersRepository,
  pages: PagesRepository,
  keywords: KeywordsRepository,
  configurations: ConfigurationsRepository,
  adminTools: AdminToolsRepository,
  alerts: AlertsRepository,
  files: FilesRepository,
  keywordsSearches: KeywordsSearchesRepository,
  keywordsExports: KeywordsExportsRepository,
  removeKeywords: RemoveKeywordsRepository,
  addTags: AddTagsRepository,
  removeTags: RemoveTagsRepository,
  keywordPages: KeywordPagesRepository,
  competitorsRepository: CompetitorsRepository,
  netlinkingRepository: NetlinkingRepository,
  refreshData: RefreshDataRepository,
  npsRatingsRepository: NpsRatingsRepository,
  editorialBrief: EditorialBriefRepository,
  recommendationsRepository: RecommendationsRepository,
  googleResultsRepository: GoogleResultsRepository,
  sellsyRepository: SellsyRepository,
  importKeywordsRepository: ImportKeywordsRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
