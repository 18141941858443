<template>
  <div
    class="flex-center"
    v-if="metrics"
  >
    <div class="flow">
      <div v-if="metrics.domainAuthority">
        <el-progress
          class="small-dashboard"
          type="dashboard"
          :percentage="metrics.domainAuthority"
          :format="format"
          :color="customColorMethod"
        >
        </el-progress>
      </div>
      <div
        v-else
        class="text"
      >
        --
      </div>
      <div class="small-text">
        {{ $t("domainAuthority") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    metrics: Object,
  },
  methods: {
    format(percentage) {
      return percentage;
    },
    customColorMethod(percentage) {
      if (percentage < 25) {
        return "#f56c6c";
      } else if (percentage < 50) {
        return "#e6a23c";
      } else if (percentage < 75) {
        return "#1989fa";
      } else {
        return "#67c23a";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .flow {
    text-align: center;

    .small-text {
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      text-align: center;
    }

    .small-dashboard {
      ::v-deep .el-progress__text {
        font-size: 1.25rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }
}
</style>
<i18n>
{
  "en": {
    "domainAuthority": "Domain Authority"
  },
  "fr": {
    "domainAuthority": "Domain Authority"
  },
  "de": {
    "domainAuthority": "Domänenautorität"
  }
}
</i18n>
