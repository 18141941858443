import Repository from "@/services/repository";

export default {
  createExport(studyId, type) {
    return Repository.post(`/a/${studyId}/keywords_exports.json?type=${type}`, {
      type,
    }).then((response) => response.data);
  },

  createSemanticalExport(studyId, keywordIds, type) {
    return Repository.post(
      `/a/${studyId}/keywords_semantic_exports.json?type=${type}`,
      {
        keywordIds,
      }
    ).then((response) => response.data);
  },

  technicalExport(studyId, keywordId, params) {
    return Repository.get(
      `/a/${studyId}/keywords/${keywordId}/export_technical.xls`,
      {
        params,
        responseType: "arraybuffer",
      }
    ).then((response) => response.data);
  },
};
