<template>
  <div>
    <span>{{ $t("enterClass") }}</span>
    <el-input
      type="textarea"
      :autosize="{ maxRows: 20, minRows: 4 }"
      :placeholder="$t('keywordCategroy')"
      v-model="data"
    >
    </el-input>
    <div class="submit">
      <el-button
        class="submit-button"
        plain
        type="success"
        @click="parseKeywords"
        >{{ $t("validate") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import * as CSV from "csv-string";

export default {
  data() {
    return {
      data: null,
      calibration: [],
    };
  },
  methods: {
    parseKeywords() {
      const parsedCsv = CSV.parse(this.data);
      parsedCsv.map((elt) => {
        const obj = { text: elt[0], category: elt[1], volume: 0 };
        if (elt.length > 2) obj.volume = parseInt(elt[2], 10);
        this.calibration.push(obj);
      });
      this.sendCommand();
    },
    sendCommand() {
      this.$emit("update-type", "explicit");
      this.$emit("update-calibration", this.calibration);
      this.calibration = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.submit {
  text-align: center;
  padding-top: 2rem;
}
</style>

<i18n>
{
  "en": {
    "enterClass": "Enter your classification under the format 'keyword, category' (one tuple per line)",
    "keywordCategroy": "keyword, Categroy...",
    "validate": "Validate"
  },
  "fr": {
    "enterClass": "Entrez votre classification sous le format 'mot-clé, catégorie' (un couple mot-clé/catégorie par ligne)",
    "keywordCategroy": "mot-clé, catégorie...",
    "validate": "Valider"
  },
  "de": {
    "enterClass": "Geben Sie Ihre Klassifizierung im Format „Schlüsselwort, Kategorie“ ein (ein Schlüsselwort/Kategorie-Paar pro Zeile).",
    "keywordCategroy": "Schlagwort, Kategorie...",
    "validate": "Bestätigen"
  }
}
</i18n>
