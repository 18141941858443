<template>
  <el-card
    class="dashboard page-breaker no-border-on-print"
    v-if="accountConfiguration"
  >
    <div class="flex-container">
      <h2>
        {{ $t("conversion") }}
        <span class="date-period">
          <span
            >{{ periodDatesShown[0] | formatDateFromLocale }} {{ $t("to") }}
            {{ periodDatesShown[1] | formatDateFromLocale }}</span
          >
          <span v-if="comparisonPeriodDates[0]"
            >vs {{ comparisonPeriodDatesShown[0] | formatDateFromLocale }}
            {{ $t("to") }}
            {{ comparisonPeriodDatesShown[1] | formatDateFromLocale }}</span
          >
        </span>
      </h2>
      <el-button
        v-on:click="linkToConversion"
        class="no-print"
      >
        {{ $t("analyseDetail") }}
      </el-button>
    </div>
    <div
      class="metrics"
      v-if="
        accountConfiguration.conversionMonitoringType &&
        accountConfiguration.conversionMonitoringType !== 'on'
      "
    >
      <el-row
        type="flex"
        :gutter="20"
      >
        <el-col :span="6">
          <el-card v-loading="isLoading">
            <reporting-metric
              :metrics="conversionMetrics"
              v-if="conversionMetrics"
              main-text="conversion.seo_conversion"
              main-field="conversions"
              total-text="conversion.ratio_total_conversion"
              total-field="total_conversions"
              comparative-text="vs_previous_period"
              comparative-field="comparative_conversions"
              :conversionMonitoringType="
                accountConfiguration.conversionMonitoringType
              "
            >
            </reporting-metric>
          </el-card>
        </el-col>
        <!-- <el-col :span="6" class="no-print">
                    <el-card v-loading="isLoading">
                        <reporting-metric
                            :metrics="conversionMetrics"
                            v-if="conversionMetrics"
                            main-text="conversion.sea_conversion"
                            main-field="seaConversions"
                            total-text="conversion.ratio_total_conversion"
                            total-field="totalConversions"
                            comparative-text="vs_previous_period"
                            comparative-field="comparativeSeaConversions">
                        </reporting-metric>
                    </el-card>
                </el-col> -->
        <el-col :span="6">
          <el-card v-loading="isLoading">
            <reporting-metric
              :metrics="conversionMetrics"
              v-if="conversionMetrics"
              main-text="conversion.brand_seo_conversion"
              main-field="brand_conversions"
              total-text="conversion.ratio_seo_conversion"
              total-field="conversions"
              comparative-text="vs_previous_period"
              comparative-field="brand_comparative_conversions"
              :conversionMonitoringType="
                accountConfiguration.conversionMonitoringType
              "
            >
            </reporting-metric>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card v-loading="isLoading">
            <reporting-metric
              :metrics="conversionMetrics"
              v-if="conversionMetrics"
              main-text="conversion.non_brand_seo_conversion"
              main-field="non_brand_conversions"
              total-text="conversion.ratio_seo_conversion"
              total-field="conversions"
              comparative-text="vs_previous_period"
              comparative-field="non_brand_comparative_conversions"
              :conversionMonitoringType="
                accountConfiguration.conversionMonitoringType
              "
            >
            </reporting-metric>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card v-loading="isLoading">
            <reporting-metric
              :metrics="conversionMetrics"
              v-if="conversionMetrics"
              main-text="conversion.total_conversion_over_period"
              main-field="total_conversions"
              comparative-text="vs_previous_period"
              comparative-field="total_comparative_conversions"
              :conversionMonitoringType="
                accountConfiguration.conversionMonitoringType
              "
            >
            </reporting-metric>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-card
      v-if="
        accountConfiguration.conversionMonitoringType &&
        accountConfiguration.conversionMonitoringType !== 'on'
      "
    >
      <conversion-loader
        v-if="frequency"
        :dimensions-data="dimensionsData"
        :study-id="accountId"
        :period="periodDates"
        :comparativePeriod="comparisonPeriodDates"
        :frequency="frequency"
        #default="conversionLoaderProps"
      >
        <conversion-chart
          :main-series="conversionLoaderProps.mainSeries"
          :traffic-groups="conversionLoaderProps.conversionGroups"
        ></conversion-chart>
      </conversion-loader>
    </el-card>
    <el-card
      class="error-message no-print"
      v-if="
        accountConfiguration.conversionMonitoringType == null ||
        accountConfiguration.conversionMonitoringType === 'on'
      "
    >
      {{ $t("monitoringTypeIsNullMessage") }}
      <el-button
        type="warning"
        v-on:click="linkToEditConfiguration"
        plain
        >{{ $t("editConfiguration") }}</el-button
      >
    </el-card>
  </el-card>
</template>

<script>
import { subDays, subMonths, subYears, subWeeks } from "date-fns";

import ConversionLoader from "@/components/ConversionLoader";
import ReportingMetric from "@/components/ReportingMetric";
import ConversionChart from "@/pages/Conversion/Evolution/Chart";

import { RepositoryFactory } from "@/services/repositoryFactory";
const ConversionsRepository = RepositoryFactory.get("conversions");

export default {
  props: {
    accountId: Number,
    accountConfigurationJson: Object,
    dimensionsData: Array,
  },
  components: {
    ReportingMetric,
    ConversionChart,
  },
  data() {
    return {
      periodDates: [],
      periodDatesShown: [],
      comparisonPeriodDates: [],
      comparisonPeriodDatesShown: [],
      periodInDays: null,
      frequency: null,
      conversionMetrics: null,
      comparisonPeriod: null,
      isLoading: false,
      accountConfiguration: {},
    };
  },
  methods: {
    fetchConversionMetrics() {
      this.isLoading = true;
      ConversionsRepository.createConversionMetrics(
        this.accountId,
        null,
        this.periodDates,
        this.comparisonPeriodDates,
        null
      )
        .then((data) => {
          this.conversionMetrics = data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setPeriod() {
      const today = new Date();
      const endDate = new Date(today.setDate(today.getDate() - 3));
      let startDate = null;
      if (this.accountConfiguration.period == 7)
        startDate = subWeeks(endDate, 1);
      else if (this.accountConfiguration.period == 30)
        startDate = subMonths(endDate, 1);
      else if (this.accountConfiguration.period == 90)
        startDate = subMonths(endDate, 3);
      else if (this.accountConfiguration.period == 180)
        startDate = subMonths(endDate, 6);
      else if (this.accountConfiguration.period == 365)
        startDate = subYears(endDate, 1);

      this.periodDatesShown[0] =
        ("0" + startDate.getDate()).slice(-2) +
        "/" +
        ("0" + (startDate.getMonth() + 1)).slice(-2) +
        "/" +
        startDate.getFullYear();
      this.periodDatesShown[1] =
        ("0" + endDate.getDate()).slice(-2) +
        "/" +
        ("0" + (endDate.getMonth() + 1)).slice(-2) +
        "/" +
        endDate.getFullYear();

      return [startDate, endDate];
    },
    setComparisonPeriod() {
      let endDate = null;
      let startDate = null;
      if (
        this.accountConfiguration.cmpPeriod === "last_period" ||
        (this.accountConfiguration.period === 30 &&
          this.accountConfiguration.cmpPeriod === "month") ||
        (this.accountConfiguration.period === 365 &&
          this.accountConfiguration.cmpPeriod === "year")
      ) {
        const numberOfDays =
          (this.periodDates[1] - this.periodDates[0]) / (1000 * 60 * 60 * 24);
        endDate = new Date(this.periodDates[1]);
        endDate.setDate(this.periodDates[1].getDate() - numberOfDays - 1);
        startDate = new Date(this.periodDates[0]);
        startDate.setDate(this.periodDates[0].getDate() - numberOfDays - 1);
      } else if (this.accountConfiguration.cmpPeriod === "month") {
        endDate = subMonths(this.periodDates[1], 1);
        startDate = subMonths(this.periodDates[0], 1);
      } else if (this.accountConfiguration.cmpPeriod === "year") {
        endDate = subYears(this.periodDates[1], 1);
        startDate = subYears(this.periodDates[0], 1);
      } else if (this.accountConfiguration.cmpPeriod === "52weeks") {
        const mainPeriodEnd = this.periodDates[1];
        const mainPeriodStart = this.periodDates[0];
        let start_dow = new Date(mainPeriodStart).getDay();
        let end_dow = new Date(mainPeriodEnd).getDay();
        endDate = subYears(mainPeriodEnd, 1);
        startDate = subYears(mainPeriodStart, 1);
        while (endDate.getDay() != end_dow) {
          endDate = subDays(endDate, -1);
        }
        while (startDate.getDay() != start_dow) {
          startDate = subDays(startDate, -1);
        }
      }
      this.comparisonPeriodDatesShown[0] =
        ("0" + startDate.getDate()).slice(-2) +
        "/" +
        ("0" + (startDate.getMonth() + 1)).slice(-2) +
        "/" +
        startDate.getFullYear();
      this.comparisonPeriodDatesShown[1] =
        ("0" + endDate.getDate()).slice(-2) +
        "/" +
        ("0" + (endDate.getMonth() + 1)).slice(-2) +
        "/" +
        endDate.getFullYear();
      return [startDate, endDate];
    },
    setAccountConfiguration() {
      this.accountConfiguration.cmpPeriod =
        this.accountConfigurationJson.data.attributes.cmpPeriod;
      this.accountConfiguration.conversionMonitoringType =
        this.accountConfigurationJson.data.attributes.conversionMonitoringType;
      this.accountConfiguration.frequency =
        this.accountConfigurationJson.data.attributes.frequency;
      this.accountConfiguration.graphType =
        this.accountConfigurationJson.data.attributes.graphType;
      this.accountConfiguration.period =
        this.accountConfigurationJson.data.attributes.period;
    },
    linkToConversion() {
      window.open(`/a/${this.accountId}/conversion`);
    },
    linkToEditConfiguration() {
      window.open(
        `/a/${this.accountId}/configuration/edit#conversionMonitoring`
      );
    },
  },
  created() {
    if (this.accountConfigurationJson) {
      this.setAccountConfiguration();
      this.periodDates = this.setPeriod();
      this.frequency = this.accountConfiguration.frequency;
      if (
        this.accountConfiguration.cmpPeriod &&
        this.accountConfiguration.cmpPeriod !== ""
      )
        this.comparisonPeriodDates = this.setComparisonPeriod();
      if (
        this.accountConfiguration.conversionMonitoringType &&
        this.accountConfiguration.conversionMonitoringType !== "on"
      )
        this.fetchConversionMetrics();
    }
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}
.metrics {
  margin-top: 1rem;
  .el-col {
    display: flex;
    flex-direction: column;
  }

  .el-card {
    display: flex;
    flex-grow: 1;

    ::v-deep .el-card__body {
      width: 100%;
    }
  }
}

.date-period {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.chart {
  margin-top: 1rem;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.dashboard {
  margin-bottom: 3rem;
}

.error-message {
  margin-top: 1rem;
  font-size: 1.2rem;
}

@media print {
  .no-print {
    display: none !important;
  }

  .date-period {
    margin-left: 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .no-border-on-print {
    border-style: none;
  }
}
</style>

<i18n>
{
  "en": {
    "analyseDetail": "Detailed Analysis",
    "conversion": "Conversion",
    "to": "to",
    "monitoringTypeIsNullMessage": "In order to see this graph, please specify the conversion monitoring type you want : ",
    "editConfiguration": "Edit configuration"
  },
  "fr": {
    "analyseDetail": "Analyser en détail",
    "conversion": "Conversion",
    "to": "au",
    "monitoringTypeIsNullMessage": "Veuillez spécifier le type de suivi de conversion voulu : ",
    "editConfiguration": "Modifier la configuration"
  },
  "de": {
    "analyseDetail": "Analysieren Sie im Detail",
    "conversion": "Wandlung",
    "to": "Bei",
    "monitoringTypeIsNullMessage": "Bitte geben Sie die Art des gewünschten Conversion-Trackings an:",
    "editConfiguration": "Konfiguration ändern"
  }
}
</i18n>
