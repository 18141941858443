<template>
  <div
    v-loading="
      mainSeriesLoading ||
      trafficGroupsLoading ||
      comparativeTrafficGroupsLoading
    "
  >
    <slot
      :frequency="frequency"
      :dimension="dimension"
      :main-series="mainSeries"
      :traffic-groups="trafficGroups"
      :comparative-traffic-groups="comparativeTrafficGroups"
      :comparative-period="comparativePeriod"
    ></slot>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const TrafficsRepository = RepositoryFactory.get("traffics");

export default {
  props: {
    dimension: String,
    dimensionsData: Array,
    studyId: Number,
    filters: Array,
    period: Array,
    comparativePeriod: Array,
    frequency: String,
  },
  data() {
    return {
      mainSeries: null,
      mainSeriesLoading: false,
      trafficGroups: null,
      trafficGroupsLoading: false,
      comparativeTrafficGroups: null,
      comparativeTrafficGroupsLoading: false,
    };
  },
  methods: {
    fetchData() {
      this.fetchMainSeries();
      this.fetchTrafficGroups();
      this.fetchComparativeTrafficGroups();
    },
    fetchMainSeries() {
      this.mainSeriesLoading = true;
      TrafficsRepository.createTrafficChart(
        this.studyId,
        this.filters,
        this.period,
        this.comparativePeriod,
        this.frequency
      )
        .then((data) => {
          this.mainSeries = data;
          this.mainSeriesLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.mainSeriesLoading = false;
        });
    },
    fetchTrafficGroups() {
      if (this.dimension) {
        this.trafficGroupsLoading = true;
        TrafficsRepository.createTrafficChartGroups(
          this.studyId,
          this.filters,
          this.dimension,
          this.period,
          this.frequency
        )
          .then((data) => {
            this.trafficGroups = data;
            this.trafficGroupsLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.trafficGroupsLoading = false;
          });
      } else {
        this.trafficGroups = null;
      }
    },
    fetchComparativeTrafficGroups() {
      if (this.dimension && this.comparativePeriod) {
        this.comparativeTrafficGroupsLoading = true;
        TrafficsRepository.createTrafficChartGroups(
          this.studyId,
          this.filters,
          this.dimension,
          this.comparativePeriod,
          this.frequency
        )
          .then((data) => {
            this.comparativeTrafficGroups = data;
            this.comparativeTrafficGroupsLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.comparativeTrafficGroupsLoading = false;
          });
      } else {
        this.comparativeTrafficGroups = null;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    $props: {
      handler: "fetchData",
      deep: true,
    },
  },
};
</script>

<style scoped></style>
