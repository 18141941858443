<template>
  <div class="kpi">
    <div>
      <div class="main-text">{{ $t(mainText) }}</div>
    </div>
    <div v-if="this.$slots.default">
      <slot></slot>
    </div>
    <div v-else-if="typeof value !== 'undefined'">
      <div
        v-if="conversionMonitoringType === 'e_commerce_data'"
        class="main-value"
      >
        {{ currencyFormat(value) }}
        <span
          v-if="comparativeValue"
          :class="comparativeChangeClass"
          class="comparative"
        >
          <i
            v-if="comparativeChange < 0"
            class="fas fa-arrow-down"
          ></i>
          <i
            v-if="comparativeChange > 0"
            class="fas fa-arrow-up"
          ></i>
          {{ comparativeChange }}%
        </span>
      </div>
      <div
        v-else
        class="main-value"
      >
        {{ value | formatNumber }}
        <span
          v-if="comparativeValue"
          :class="comparativeChangeClass"
          class="comparative"
        >
          <i
            v-if="comparativeChange < 0"
            class="fas fa-arrow-down"
          ></i>
          <i
            v-if="comparativeChange > 0"
            class="fas fa-arrow-up"
          ></i>
          {{ comparativeChange }}%
        </span>
      </div>
      <div
        v-if="totalValue && totalField"
        class="total"
      >
        {{ $t(totalText, { value: totalRatioValue }) }}
      </div>
      <!-- <div v-if="comparativeValue" class="comparative">
				{{ $t(comparativeText) }} : {{ comparativeValue | formatNumber }} <span :class="comparativeChangeClass">({{ comparativeChange }}%)</span>
			</div> -->
    </div>
    <div v-else>
      <div class="main-value">-</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    metrics: Object,
    conversionMonitoringType: String,
    mainText: String,
    mainField: String,
    totalText: String,
    totalField: String,
    comparativeText: String,
    comparativeField: String,
    dimensionsData: Array,
  },
  methods: {
    currencyFormat(value) {
      return this.$options.filters.formatCurrency(value, this.$i18n.locale);
    },
  },
  computed: {
    value() {
      return this.metrics[this.mainField];
    },
    totalValue() {
      return this.metrics[this.totalField];
    },
    totalRatioValue() {
      return this.metrics.hasOwnProperty(this.totalField)
        ? Math.round((this.value / this.totalValue) * 100)
        : null;
    },
    comparativeValue() {
      return this.metrics.hasOwnProperty(this.comparativeField)
        ? this.metrics[this.comparativeField]
        : null;
    },
    comparativeChange() {
      return this.metrics.hasOwnProperty(this.comparativeField)
        ? Math.round(
            ((this.value - this.comparativeValue) / this.comparativeValue) * 100
          )
        : null;
    },
    comparativeChangeClass() {
      return {
        "positive-change": this.comparativeChange > 0,
        "negative-change": this.comparativeChange < 0,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";
.kpi {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  .main-text {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
  .main-value {
    margin-bottom: 0.5rem;
    font-size: 1.85rem;
    font-weight: 500;
  }

  .total {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }

  .comparative {
    color: #6c757d;
    font-size: 0.9rem;
  }

  .positive-change {
    color: $--color-success;
  }

  .negative-change {
    color: $--color-danger;
  }
}

@media print {
  .kpi {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    .main-text {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      font-size: 0.85rem;
      letter-spacing: 0.1rem;
      height: 3em;
    }
    .main-value {
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
      font-weight: 500;
      height: 2em;
    }
    .total {
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
    }

    .comparative {
      font-size: 0.75rem;
      height: 3em;
    }

    .positive-change {
      color: $--color-success;
    }

    .negative-change {
      color: $--color-danger;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "traffic": {
      "seo_traffic": "SEO traffic",
      "brand_seo_traffic": "Branded SEO Traffic",
      "non_brand_seo_traffic": "Non-branded SEO Traffic",
      "total_traffic_over_period": "Total traffic over period",
      "ratio_total_traffic": "{value}% of total traffic",
      "ratio_seo_traffic": "{value}% of SEO traffic"
    },
    "conversion": {
      "seo_conversion": "SEO conversions",
      "sea_conversion": "SEA conversions",
      "brand_seo_conversion": "Branded SEO Traffic",
      "non_brand_seo_conversion": "Non-branded SEO Traffic",
      "total_conversion_over_period": "Tot. conversions",
      "ratio_total_conversion": "{value}% of conversions",
      "ratio_seo_conversion": "{value}% of SEO conversions"
    },
    "vs_previous_period": "vs previous period"
  },
  "fr": {
    "traffic": {
      "seo_traffic": "Trafic SEO",
      "brand_seo_traffic": "Trafic SEO Marque",
      "non_brand_seo_traffic": "Trafic SEO Hors-Marque",
      "total_traffic_over_period": "Trafic total",
      "ratio_total_traffic": "{value}% du trafic total",
      "ratio_seo_traffic": "{value}% du trafic SEO"
    },
    "conversion": {
      "seo_conversion": "Conversions SEO",
      "sea_conversion": "Conversions SEA",
      "brand_seo_conversion": "SEO marque",
      "non_brand_seo_conversion": "SEO Hors-Marque",
      "total_conversion_over_period": "Conversions tot.",
      "ratio_total_conversion": "{value}% des conversions",
      "ratio_seo_conversion": "{value}% des conversions SEO"
    },
    "vs_previous_period": "vs période précédente"
  },
  "de": {
    "traffic": "{'seo_traffic': 'Traffic SEO', 'brand_seo_traffic': 'Traffic SEO Marque', 'non_brand_seo_traffic': 'Traffic SEO Hors-Marque', 'total_traffic_over_period': 'Traffic gesamt', 'ratio_total_traffic': '{value}% du traffic total', 'ratio_seo_traffic': '{value}% du traffic SEO'}",
    "conversion": "{'seo_conversion': 'Conversions SEO', 'sea_conversion': 'Conversions SEA', 'brand_seo_conversion': 'SEO marque', 'non_brand_seo_conversion': 'SEO Hors-Marque', 'total_conversion_over_period': 'Conversions tot.', ' ratio_total_conversion': '{value}% der Conversions', 'ratio_seo_conversion': '{value}% der Conversions SEO'}",
    "vs_previous_period": "gegenüber Vorperiode"
  }
}
</i18n>
