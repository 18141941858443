<template>
  <div>
    <h4>{{ $t(popupTitle) }}</h4>
    <p>{{ pageInfo[popupTitle] }}</p>
    <div class="recommendation">
      <div class="recommendation-text">
        <div
          v-for="(item, index) in detailedRecommendations.statements"
          :key="index"
        >
          <p>{{ item }}</p>
        </div>
      </div>
      <div v-loading="isLoadingContentOptimization">
        <H1Scores
          :isActionPlanDialog="true"
          :ownScore="Math.round(score.ownScore)"
          :serpScore="Math.round(score.serpScore)"
        >
        </H1Scores>
      </div>
    </div>
  </div>
</template>

<script>
import H1Scores from "@/components/ContentOptimization/H1Scores";
import { contentOptimizationMixin } from "@/mixins/contentOptimizationMixin";

export default {
  mixins: [contentOptimizationMixin],
  props: {
    detailedRecommendations: Object,
    popupTitle: String,
    score: Object,
    pageInfo: Object,
  },
  components: {
    H1Scores,
  },
};
</script>

<style scoped>
.recommendation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.recommendation-text {
  display: flex;
  flex-direction: column;
  padding: 2em 0 0 1em;
}
</style>

<i18n>
  {
    "en": {
      "title": "Your Title tag :",
      "metaDescription": "Your Meta-description tag :"
    },
    "fr": {
      "title": "Votre balise Title :",
      "metaDescription": "Votre balise Meta description :"
    },
    "de": {
      "title": "Ihr Title-Tag :",
      "metaDescription": "Ihr Meta-Beschreibung Tag :"
    }
  }
</i18n>
