<template>
  <div class="center">
    <el-tooltip
      v-if="params.data.backlinkType == 'MEDIA'"
      :content="$t('media')"
      placement="top"
    >
      <i class="fas fa-bullhorn media"></i>
    </el-tooltip>

    <el-tooltip
      v-if="params.data.backlinkType == 'DIAMOND'"
      :content="$t('diamond')"
      placement="top"
    >
      <i class="fas fa-gem diamond"></i>
    </el-tooltip>

    <el-tooltip
      v-if="params.data.backlinkType == 'PLATINUM'"
      :content="$t('platinum')"
      placement="top"
    >
      <i class="fas fa-crown platinum"></i>
    </el-tooltip>

    <el-tooltip
      v-if="params.data.backlinkType == 'GOLD'"
      :content="$t('gold')"
      placement="top"
    >
      <i class="fas fa-medal gold"></i>
    </el-tooltip>

    <el-tooltip
      v-if="params.data.backlinkType == 'SILVER'"
      :content="$t('silver')"
      placement="top"
    >
      <i class="fas fa-medal silver"></i>
    </el-tooltip>

    <el-tooltip
      v-if="params.data.backlinkType == 'BRONZE'"
      :content="$t('bronze')"
      placement="top"
    >
      <i class="fas fa-medal bronze"></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
  font-size: 1.2rem;

  .media {
    color: red;
  }

  .diamond {
    color: #21ffff;
  }

  .platinum {
    color: #cdd5e8;
  }

  .gold {
    color: #ffd700;
  }

  .silver {
    color: #a9a9a9;
  }

  .bronze {
    color: #6a5211;
  }
}
</style>

<i18n>
{
  "en": {
    "bronze": "Bronze",
    "silver": "Silver",
    "gold": "Gold",
    "platinum": "Platinum",
    "diamond": "Diamond",
    "media": "Media"
  },
  "fr": {
    "bronze": "Bronze",
    "silver": "Argent",
    "gold": "Or",
    "platinum": "Platine",
    "diamond": "Diamant",
    "media": "Média"
  },
  "de": {
    "bronze": "Bronze",
    "silver": "Geld",
    "gold": "Oder",
    "platinum": "Platine",
    "diamond": "Diamant",
    "media": "Durchschnitt"
  }
}
</i18n>
