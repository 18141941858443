<template>
  <el-card class="card">
    <div v-if="isResponseEmpty">
      <p>{{ $t("chatgptEmptyReply") }}</p>
    </div>
    <div v-else>
      <div v-if="genAiTab === 'h1'">
        <el-radio-group v-model="selectedH1Value">
          <div
            v-for="(item, index) in aiResponseArray"
            :key="index"
          >
            <el-radio
              :label="index"
              size="large"
              class="radioButton"
              >{{ item }}</el-radio
            >
          </div>
        </el-radio-group>
      </div>
      <div v-else-if="genAiTab === 'plan'">
        <el-radio-group v-model="selectedPlanValue">
          <div
            v-for="(item, index) in aiResponseArray"
            :key="index"
          >
            <el-radio
              :label="index"
              size="large"
              class="radioButton"
            >
              <Plan :aiResponseArray="item" />
            </el-radio>
          </div>
        </el-radio-group>
      </div>
      <div v-else>
        {{ $t('generateContent') }}
      </div>
      <div class="buttonContainer">
        <div v-if="genAiTab === 'content'">
          <el-button
            @click="textGenAi('generate')"
            type="primary"
            >{{ $t("generateText") }}
          </el-button>
          <el-button
            @click="textGenAi('optimize')"
            type="primary"
            >{{ $t("OptText") }}
          </el-button>
        </div>
        <el-button v-else
          @click="selectRadio()"
          type="primary"
          >{{ $t("selectButton") }}
        </el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
import Plan from "@/pages/ActionPlan/Content/chatGptTable/planFormat.vue";

export default {
  props: {
    aiResponseArray: Array,
    genAiTab: String,
  },
  components: {
    Plan,
  },
  data() {
    return {
      selectedPlanValue: null,
      selectedH1Value: null,
    };
  },
  methods: {
    selectRadio() {
      if (this.genAiTab === 'h1') {
        this.$emit(
          "radioEvent",
          this.aiResponseArray[this.selectedH1Value],
          true
        );
      } else {
        this.$emit(
          "radioEvent",
          this.aiResponseArray[this.selectedPlanValue],
          false
        );
      }
    },
    textGenAi(action) {
      this.$emit("genAiEvent", action)
    }
  },
  computed: {
    isResponseEmpty() {
      if (this.aiResponseArray[0].length === 0) return true;
      else return false;
    },
  },
};
</script>

<style scoped>
::v-deep .el-radio__input {
  padding-top: 0.25em !important;
}

::v-deep .el-radio__label {
  width: 100%;
}

.el-radio {
  margin-right: 0;
  white-space: normal;
  display: flex;
  line-height: 1.4;
}

.buttonContainer {
  text-align: center;
  margin-top: 2em;
}
</style>

<i18n>
  {
    "en": {
      "plan" : "Plan",
      "selectButton" : "Select",
      "plus" : "view more",
      "less" : "view less",
      "chatgptEmptyReply": "Unfortunately Chatgpt was unable to provide any interesting results for this keyword.",
      "generateContent": "Generate a text using our generating AI",
      "generateText": "Generate",
      "OptText": "Optimize"
    },
    "fr": {
      "plan" : "Plan",
      "selectButton" : "Sélectionner",
      "plus" : "voir plus",
      "less" : "voir moins",
      "chatgptEmptyReply": "Malheureusement Chatgpt n'a pas pu proposer de résultat pertinent pour ce mot clé.",
      "generateContent": "Générer un texte en utilisant notre IA générative.",
      "generateText": "Générer",
      "OptText": "Optimiser"
    },
    "de": {
      "plan" : "Planen",
      "selectButton" : "Wählen",
      "plus" : "mehr sehen",
      "less" : "weniger sehen",
      "chatgptEmptyReply": "Leider konnte Chatgpt keine interessanten Ergebnisse für dieses Stichwort liefern.",
      "generateContent": "Generieren Sie einen Text mithilfe unserer generativen KI.",
      "generateText": "Erzeugen"
    }
  }
</i18n>
