<template>
  <div class="users-edit mb-5">
    <app-introduction :title="$t('modifyProfil')"></app-introduction>

    <div class="container">
      <el-card>
        <el-row justify="center">
          <el-col
            :span="16"
            :offset="4"
          >
            <el-tabs tab-position="left">
              <el-tab-pane :label="$t('general')">
                <slot></slot>
              </el-tab-pane>
              <el-tab-pane :label="$t('changePassword')">
                <change-password
                  :changePasswordEndpoint="changePasswordEndpoint"
                  :currentUser="currentUser"
                ></change-password>
              </el-tab-pane>
              <el-tab-pane :label="$t('access')">
                <el-form label-width="100px">
                  <el-form-item
                    v-for="(clientGrant, index) of clientGrants"
                    :label="clientGrant.client_id"
                    :key="index"
                  >
                    <div
                      v-if="
                        clientGrant.refresh_tokens &&
                        clientGrant.refresh_tokens.length > 0
                      "
                    >
                      <el-input
                        :value="clientGrant.refresh_tokens[0].token"
                        readonly
                      >
                        <el-button
                          slot="append"
                          icon="el-icon-delete"
                          type="danger"
                          plain
                          @click="
                            deleteRefreshToken(
                              clientGrant.id,
                              clientGrant.refresh_tokens[0].id
                            )
                          "
                        ></el-button>
                      </el-input>
                    </div>
                    <div v-else>
                      <el-button
                        @click="createRefreshToken(clientGrant.id)"
                        type="primary"
                        plain
                        >{{ $t("generateKey") }}</el-button
                      >
                      <el-button
                        @click="deleteClientGrant(clientGrant.id)"
                        icon="el-icon-delete"
                        type="danger"
                        plain
                      ></el-button>
                    </div>
                  </el-form-item>
                </el-form>
                <el-button
                  @click="dialogVisible = true"
                  size="medium"
                  type="primary"
                  plain
                >
                  <i class="fas fa-plus-square"></i>
                  <span>{{ $t("createNewAccess") }}</span>
                </el-button>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <el-dialog
      :title="$t('createNewAccess')"
      :visible.sync="dialogVisible"
    >
      <el-input
        v-model="newClientGrant"
        :placeholder="$t('applicationName')"
      ></el-input>
      <span slot="footer">
        <el-button @click="dialogVisible = false">{{ $t("cancel") }}</el-button>
        <el-button
          @click="createClientGrant"
          type="primary"
          >{{ $t("confirm") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ChangePassword from "./ChangePassword.vue";

export default {
  components: {
    ChangePassword,
  },
  props: {
    currentUser: Object,
  },
  data() {
    return {
      apiKey: "",
      clientGrants: [],
      dialogVisible: false,
      newClientGrant: "",
      changePasswordEndpoint: null,
    };
  },
  methods: {
    listClientGrants() {
      this.$api
        .get(`/users/${this.currentUser.id}/client-grants`)
        .then((response) => {
          this.clientGrants = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createClientGrant() {
      this.$api
        .post(`/users/${this.currentUser.id}/client-grants`, {
          client_id: this.newClientGrant,
        })
        .then((response) => {
          this.dialogVisible = false;
          this.clientGrants.push(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteClientGrant(clientGrantId) {
      this.$api
        .delete(`/users/${this.currentUser.id}/client-grants/${clientGrantId}`)
        .then(() => {
          this.clientGrants.splice(
            this.clientGrants.findIndex(
              (clientGrant) => clientGrant.id === clientGrantId
            ),
            1
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createRefreshToken(clientGrantId) {
      this.$api
        .post(
          `/users/${this.currentUser.id}/client-grants/${clientGrantId}/refresh-tokens`
        )
        .then(() => {
          this.listClientGrants();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteRefreshToken(clientGrantId, refreshTokenId) {
      this.$api
        .delete(
          `/users/${this.currentUser.id}/client-grants/${clientGrantId}/refresh-tokens/${refreshTokenId}`
        )
        .then(() => {
          this.listClientGrants();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getUserModule() {
      this.$api
        .get(`/users/${this.currentUser.id}/module`, {
          headers: { Accept: "application/vnd.api+json" },
        })
        .then((response) => {
          this.changePasswordEndpoint =
            response.data.data.attributes.changePassword;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.listClientGrants();
    this.getUserModule();
  },
};
</script>

<i18n>
{
  "en": {
    "applicationName": "Application name",
    "access": "Access",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "createNewAccess": "Create new access",
    "general": "General",
    "generateKey": "Generate key",
    "modifyProfil": "Modify my account",
    "changePassword": "Change your password"
  },
  "fr": {
    "applicationName": "Nom de l'application",
    "access": "Accès",
    "cancel": "Annuler",
    "confirm": "Confirmer",
    "createNewAccess": "Créer un nouvel accès",
    "general": "Général",
    "generateKey": "Générer la clé",
    "modifyProfil": "Modifier mon profil",
    "changePassword": "Changer votre mot de passe"
  },
  "de": {
    "applicationName": "App Name",
    "access": "Zugang",
    "cancel": "Stornieren",
    "confirm": "Bestätigen",
    "createNewAccess": "Erstellen Sie einen neuen Zugang",
    "general": "Allgemein",
    "generateKey": "Schlüssel generieren",
    "modifyProfil": "Mein Profil bearbeiten",
    "changePassword": "Ändern Sie Ihr Passwort"
  }
}
</i18n>
