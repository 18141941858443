<template>
  <div>
    <el-popover
      placement="top"
      close-delay="0"
      width="100"
      trigger="hover"
      :title="this.resolveText()"
    >
      <el-button
        slot="reference"
        size="small"
        type="success"
        icon="el-icon-check"
        @click="resolve"
      ></el-button>
    </el-popover>
    <el-popover
      placement="top"
      close-delay="0"
      width="100"
      trigger="hover"
      :title="this.ignoreOnceText()"
    >
      <el-button
        slot="reference"
        size="small"
        type="warning"
        icon="el-icon-remove"
        @click="ignoreOnce"
      ></el-button>
    </el-popover>
    <el-popover
      placement="top"
      close-delay="0"
      width="100"
      trigger="hover"
      :title="this.ignoreAlwaysText()"
    >
      <el-button
        slot="reference"
        size="small"
        type="danger"
        icon="el-icon-error"
        @click="ignoreAlways"
      ></el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  methods: {
    ignoreOnceText() {
      return this.$i18n.t("ignore_once");
    },
    ignoreOnce() {
      this.params.context.componentParent.updateAlertStatus(
        this.params.value,
        "ignoreOnce"
      );
    },

    ignoreAlwaysText() {
      return this.$i18n.t("ignore_always");
    },
    ignoreAlways() {
      this.params.context.componentParent.updateAlertStatus(
        this.params.value,
        "ignoreAlways"
      );
    },

    resolveText() {
      return this.$i18n.t("resolve");
    },
    resolve() {
      this.params.context.componentParent.updateAlertStatus(
        this.params.value,
        "resolve"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.el-progress {
  line-height: inherit;
}

@media print {
  .print-background {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>

<i18n>
{
  "en": {
    "ignore_once": "Ignore once",
    "ignore_always": "Ignore always",
    "resolve": "Mark as solved"
  },
  "fr": {
    "ignore_once": "Ignorer une fois",
    "ignore_always": "Ignorer toujours",
    "resolve": "Marquer comme résolu"
  },
  "de": {
    "ignore_once": "einmal überspringen",
    "ignore_always": "Immer ignorieren",
    "resolve": "Als gelöst markieren"
  }
}
</i18n>
