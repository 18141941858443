<template>
  <div>tasks</div>
</template>

<script>
export default {
  props: {
    keyword: Object,
    accountId: Number,
  },
  data() {
    return {
      tasks: null,
    };
  },
  methods: {
    fetchTasks() {
      if (this.accountId) {
        this.$api
          .get(`/studies/${this.accountId}/tasks`, {
            params: { keyword_id: this.keyword.id },
          })
          .then((response) => {
            console.log(response);
            this.tasks = response.data;
          });
      }
    },
  },
  mounted() {
    this.fetchTasks();
  },
};
</script>
