<template>
  <div class="competitors">
    <h4>
      {{
        $t("competitors_on_first_page", { date: selectedDate.toLocaleString() })
      }}
    </h4>
    <ag-grid-vue
      style="height: 300px"
      class="ag-theme-material"
      :gridOptions="gridOptions"
      @gridReady="onGridReady"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :filter="true"
      :localeTextFunc="localeTextFunc"
      :suppressContextMenu="true"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import { epochDayToDateTime } from "@/javascripts/dateHelpers";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

export default {
  mixins: [agGridMixin],
  props: {
    websitesPositions: Array,
    selectedDate: DateTime,
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
    };
  },
  computed: {
    ...mapState(["study"]),
  },
  methods: {
    onGridReady(params) {
      this.setRowData();
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setRowData() {
      // Filter out own study from competitors
      const websitesPositions = this.websitesPositions.filter(
        (website) => !website.domain.includes(this.study.url)
      );
      this.rowData = websitesPositions.map((websitePositions) => {
        const selectedPositionByDate = websitePositions.positionsByDates.find(
          (positionByDate) => {
            const date = epochDayToDateTime(
              parseInt(positionByDate.date.epochDay)
            );
            return date.equals(this.selectedDate);
          }
        );

        return {
          domain: websitePositions.domain,
          ...(selectedPositionByDate && { url: selectedPositionByDate.url }),
          ...(selectedPositionByDate && {
            position: selectedPositionByDate.position,
          }),
        };
      });
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("domain"),
          field: "domain",
          filter: "agTextColumnFilter",
          cellRenderer: (params) =>
            `<a href="https://${params.value}" target="_blank">${params.value}</a>`,
        },
        {
          headerName: this.$i18n.t("url"),
          field: "url",
          valueFormatter: (params) => (params.value ? params.value : "-"),
          cellRenderer: (params) => {
            if (params.value)
              return `<a href="${params.value}" target="_blank">${params.value}</a>`;
            else return params.valueFormatted;
          },
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("position"),
          field: "position",
          valueFormatter: (params) => (params.value ? params.value : "> 100"),
          comparator: positionComparator,
          width: 150,
          sort: "asc",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
      ];
    },
  },
  beforeMount() {
    this.gridOptions = {};
    this.setColumnDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  watch: {
    websitesPositions() {
      this.setRowData();
    },
    selectedDate() {
      this.setRowData();
    },
  },
};

const positionComparator = (position1, position2) => {
  if (position1 === undefined && position2 === undefined) {
    return 0;
  }
  if (position1 === undefined) {
    return 1;
  }
  if (position2 === undefined) {
    return -1;
  }
  return position1 - position2;
};
</script>

<style lang="scss" scoped>
.competitors {
  margin-bottom: 2rem;

  h4 {
    font-size: 1rem;
  }
}
</style>

<i18n>
{
  "en": {
    "competitors_on_first_page": "Competitors on 1st page as of %{date}",
    "domain": "Domain",
    "url": "URL",
    "position": "Position"
  },
  "fr": {
    "competitors_on_first_page": "Concurrents en 1ère page au %{date}",
    "domain": "Domaine",
    "url": "URL",
    "position": "Position"
  },
  "de": {
    "competitors_on_first_page": "Konkurrenten auf der 1. Seite am %{date}",
    "domain": "Domain",
    "url": "URL",
    "position": "Position"
  }
}
</i18n>
