<template>
  <div>
    <p class="small">{{ $t("screen_description") }}</p>
    <el-steps
      :active="activeStep"
      finish-status="success"
      align-center
    >
      <el-step
        :title="$t('pages')"
        :description="$t('select_pages')"
      ></el-step>
      <el-step
        :title="$t('confirmation')"
        :description="$t('confirmation_description')"
      ></el-step>
    </el-steps>
    <div
      v-show="activeStep === 0"
      class="pages-selector"
    >
      <el-table :data="monitoredPages">
        <el-table-column :label="$t('url')">
          <template v-slot:default="slotProps">
            <el-input
              :disabled="
                slotProps.row.status == 'page_stored' ||
                slotProps.row.status == 'page_validated'
              "
              v-model="slotProps.row.pageUrl"
              size="small"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column :label="$t('status')">
          <template v-slot:default="slotProps">
            <div
              v-if="slotProps.row.loading"
              v-loading="slotProps.row.loading"
            >
              loading
            </div>
            <div v-else>
              <el-tooltip
                v-if="slotProps.row.message"
                class="item"
                effect="dark"
                :content="slotProps.row.message"
                placement="top-start"
              >
                <el-tag :type="computePageStatusTagType(slotProps.row.status)">
                  {{ $t(slotProps.row.status) }}
                  <i class="fas fa-info-circle"></i>
                </el-tag>
              </el-tooltip>
              <el-tag
                v-else
                :type="computePageStatusTagType(slotProps.row.status)"
              >
                {{ $t(slotProps.row.status) }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          :label="$t('actions')"
        >
          <template v-slot:default="slotProps">
            <div class="actions">
              <el-tooltip
                v-if="
                  slotProps.row.status != 'page_stored' &&
                  slotProps.row.status != 'page_validated' &&
                  slotProps.row.pageUrl.length > 4
                "
                class="item"
                effect="dark"
                placement="top-start"
                :content="$t('validate_page')"
              >
                <el-button
                  @click="getPageStatus(slotProps.$index)"
                  type="warning"
                  icon="fas fa-eye"
                  size="small"
                >
                </el-button>
              </el-tooltip>
              <el-button
                @click="removeMonitoredPage(slotProps.$index)"
                type="danger"
                icon="fas fa-trash-alt"
                size="small"
              >
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="actions">
        <el-button
          v-if="monitoredPages.length < 10"
          plain
          @click="addMonitoredPage"
          size="small"
          >{{ $t("add_monitored_page") }}</el-button
        >
      </div>
      <div class="step-navigation">
        <el-button
          :disabled="!pagesValidated"
          plain
          type="primary"
          @click="activeStep++"
          >{{ $t("next_step") }}</el-button
        >
      </div>
    </div>
    <div
      v-show="activeStep === 1"
      class="pages-selector"
    >
      <div class="confirmation">
        <div class="instruction">
          <i class="fas fa-info-circle"></i>
          {{ $t("confirmation_instruction") }}
        </div>
        <div class="step-navigation">
          <el-button
            v-if="activeStep > 0"
            @click="activeStep--"
            >{{ $t("previous_step") }}</el-button
          >
          <el-button
            @click="submitAlerts"
            plain
            type="primary"
            >{{ $t("submit") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const ConfigurationsRepository = RepositoryFactory.get("configurations");

export default {
  props: {
    accountId: Number,
    alertsJson: Object,
  },
  data() {
    this.alertsJson.monitoredPages.map((page) => (page.status = "page_stored"));
    return {
      monitoredPages: this.alertsJson.monitoredPages,
      activeStep: 0,
    };
  },
  computed: {
    pagesValidated() {
      let status = this.monitoredPages.length > 0;
      this.monitoredPages.forEach((page) => {
        if (page.status == "page_new" || page.status == "page_error") {
          status = false;
        }
      });
      return status;
    },
  },
  methods: {
    computePageStatusTagType(pageStatus) {
      if (pageStatus == "page_stored" || pageStatus == "page_validated")
        return "success";
      if (pageStatus == "page_warning") return "success";
      if (pageStatus == "page_error") return "danger";
      return "";
    },
    addMonitoredPage() {
      var index = this.monitoredPages.length;
      this.monitoredPages.push({
        pageUrl: "",
        status: "page_new",
      });
    },
    getPageStatus(index) {
      //Display loading
      this.monitoredPages[index].loading = true;
      this.monitoredPages = [...this.monitoredPages]; //Refresh grid

      //Fetch page status
      let pageData = this.monitoredPages[index];
      ConfigurationsRepository.getPageStatus(this.accountId, pageData.pageUrl)
        .then((data) => {
          this.monitoredPages[index] = data;
          this.monitoredPages = [...this.monitoredPages]; //Refresh grid
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeMonitoredPage(index) {
      if (index !== -1) this.monitoredPages.splice(index, 1);
    },
    handleDisplayNameChange(value, object) {
      // Convert display_name to name used in DB, lowercased, stripped non alphanumeric characters and replaced spaces by underscore
      object.name = formatStringForDB(value);
    },
    submitAlerts() {
      this.alertsJson.monitoredPages = this.monitoredPages;
      ConfigurationsRepository.updateAlerts(this.accountId, this.alertsJson)
        .then((data) => {
          this.$message.success(this.$i18n.t("update_success"));
          this.activeStep = 0;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

let formatStringForDB = (string) => {
  return string.toLowerCase().replace(/\s+/g, "_");
};
</script>

<style scoped lang="scss">
.el-steps {
  margin-bottom: 3rem;
}

.pages-selector {
  > .actions {
    margin-top: 1rem;
    text-align: center;
  }
  .el-table {
    width: 100%;
  }
}

.confirmation {
  .instruction {
    margin: 5rem 2rem;
    text-align: center;
  }
}

.step-navigation {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}
</style>

<i18n>
{
  "en": {
    "actions": "Actions",
    "pages": "Pages",
    "select_pages": "Select the pages to be monitored.",
    "confirmation": "Confirmation",
    "confirmation_description": "Confirm your changes",
    "name": "Name",
    "edit": "Edit",
    "validate_page": "Validate your page",
    "add_monitored_page": "Add a page",
    "previous_step": "Previous step",
    "next_step": "Next step",
    "page_stored": "OK",
    "page_new": "New",
    "page_validated": "Validated",
    "page_error": "Error",
    "page_warning": "Validated (with redirect)",
    "submit": "Submit",
    "confirmation_instruction": "Click on 'Submit' to save your changes.",
    "update_success": "The configuration of the monitoring of your pages was successfully updated.",
    "status": "Status",
    "screen_description": "Configuration of the daily alerting service",
    "url": "URL"
  },
  "fr": {
    "actions": "Actions",
    "pages": "Pages",
    "select_pages": "Sélectionnez les pages à surveiller.",
    "confirmation": "Confirmation",
    "confirmation_description": "Confirmer vos changements",
    "name": "Nom",
    "edit": "Editer",
    "validate_page": "Valider votre page",
    "add_monitored_page": "Ajouter une page",
    "previous_step": "Etape précédente",
    "next_step": "Etape suivante",
    "page_stored": "OK",
    "page_new": "Nouveau",
    "page_validated": "Validée",
    "page_warning": "Validée (avec redirect)",
    "page_error": "Erreur",
    "submit": "Confirmer",
    "confirmation_instruction": "Cliquez sur 'Confirmer' pour sauvegarder vos changements.",
    "update_success": "La surveillance de vos pages a été mise à jour avec succès.",
    "status": "Statut",
    "screen_description": "Configuration du service d'alertes journalières",
    "url": "URL"
  },
  "de": {
    "actions": "Aktionen",
    "pages": "Seiten",
    "select_pages": "Wählen Sie die zu überwachenden Seiten aus.",
    "confirmation": "Bestätigung",
    "confirmation_description": "Bestätigen Sie Ihre Änderungen",
    "name": "Name",
    "edit": "Bearbeiten",
    "validate_page": "Bestätigen Sie Ihre Seite",
    "add_monitored_page": "Seite hinzufügen",
    "previous_step": "Vorheriger Schritt",
    "next_step": "nächster Schritt",
    "page_stored": "OK",
    "page_new": "Neu",
    "page_validated": "Bestätigt",
    "page_warning": "Validiert (mit Weiterleitung)",
    "page_error": "Fehler",
    "submit": "Bestätigen",
    "confirmation_instruction": "Klicken Sie auf „Bestätigen“, um Ihre Änderungen zu speichern.",
    "update_success": "Ihre Seitenüberwachung wurde erfolgreich aktualisiert.",
    "status": "Status",
    "screen_description": "Konfiguration des täglichen Benachrichtigungsdienstes",
    "url": "URL"
  }
}
</i18n>
