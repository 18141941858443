<template>
  <el-card>
    <el-tabs
      v-model="activeTab"
      :stretch="true"
    >
      <el-tab-pane
        v-for="item in tabs"
        :key="item.name"
        :label="$t(item.label)"
        :name="item.name"
        lazy
      >
        <keep-alive>
          <component :is="item.component"></component>
        </keep-alive>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import Companies from "@/pages/AdminOverview/Companies";
import Users from "@/pages/AdminOverview/Users";
import Studies from "@/pages/AdminOverview/Studies";
import CompanyGroups from "@/pages/AdminOverview/CompanyGroups";

export default {
  components: {
    Companies,
    Users,
    Studies,
    CompanyGroups,
  },
  data() {
    return {
      activeTab: "companies",
      tabs: [
        {
          label: "companies",
          name: "companies",
          component: "Companies",
        },
        {
          label: "users",
          name: "users",
          component: "Users",
        },
        {
          label: "studies",
          name: "studies",
          component: "Studies",
        },
        {
          label: "company_groups",
          name: "companyGroups",
          component: "CompanyGroups",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-tabs__content {
  padding-top: 15px;
}
</style>

<i18n>
{
  "en": {
    "companies": "Companies",
    "users": "Users",
    "studies": "Studies",
    "company_groups": "Company groups"
  },
  "fr": {
    "companies": "Sociétés",
    "users": "Utilisateurs",
    "studies": "Etudes",
    "company_groups": "Groupes de sociétés"
  },
  "de": {
    "companies": "Firmen",
    "users": "Benutzer",
    "studies": "Studien",
    "company_groups": "Unternehmensgruppen"
  }
}
</i18n>
