<template>
  <el-card
    class="serp"
    shadow="never"
  >
    <div class="url">{{ prettierUrl }}</div>
    <br />
    <div class="title">{{ prettierTitle }}</div>
    <div
      class="meta-description"
      v-html="boldKeyword(prettierMetaDescription)"
    >
      {{ boldKeyword(prettierMetaDescription) }}
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    title: String,
    metaDescription: String,
    url: String,
    keyword: Object,
  },
  data() {
    return {
      limitUrlWidth: 500,
      limitTitleWidth: 600,
      limitMetaDescWidth: 1200,
    };
  },
  methods: {
    measureWidth(text, font) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      ctx.font = font;
      return Math.round(ctx.measureText(text).width);
    },
    boldKeyword(input) {
      if (input) {
        return input.replace(
          new RegExp(
            "(\\b)(" + this.keyword.text.split(" ").join("|") + ")(\\b)",
            "ig"
          ),
          "$1<b>$2</b>$3"
        );
      }
    },
  },
  computed: {
    prettierTitle() {
      if (this.title) {
        let prettierTitle = this.title;
        while (
          this.measureWidth(prettierTitle, "20px Arial,sans-serif") >
          this.limitTitleWidth
        ) {
          var lastIndex = prettierTitle.lastIndexOf(" ");
          prettierTitle = prettierTitle.substring(0, lastIndex);
          prettierTitle = prettierTitle + "...";
        }
        return prettierTitle;
      }
    },
    prettierMetaDescription() {
      if (this.metaDescription) {
        let prettierMetaDesc = this.metaDescription;
        while (
          this.measureWidth(prettierMetaDesc, "14px Arial,sans-serif") >
          this.limitMetaDescWidth
        ) {
          var lastIndex = prettierMetaDesc.lastIndexOf(" ");
          prettierMetaDesc = prettierMetaDesc.substring(0, lastIndex);
          prettierMetaDesc = prettierMetaDesc + "...";
        }
        return prettierMetaDesc;
      }
    },
    prettierUrl() {
      if (this.url) {
        let prettierUrl = this.url;
        let urlHasBeenCropped = false;
        while (
          this.measureWidth(prettierUrl, "14px Arial,sans-serif") >
          this.limitUrlWidth
        ) {
          prettierUrl = prettierUrl.substring(0, prettierUrl.length - 1);
          urlHasBeenCropped = true;
        }
        if (urlHasBeenCropped) {
          prettierUrl = prettierUrl + "...";
        }
        return prettierUrl;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.serp {
  width: 700px;

  .title {
    display: inline-block;
    color: #1a0dab;
    white-space: nowrap;
    font-size: 20px;
    line-height: 1.3;
  }

  .url {
    display: inline-block;
    color: #202124;
    font-size: 14px;
    white-space: nowrap;
    height: 18px;
    line-height: 1.3;
  }

  .meta-description {
    color: #545454;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.4;
  }
}
</style>
