<template>
    <li class="nav-item">
      <a
        v-if="pianoConfigurationExists"
        :href="pianoLink"
        class="nav-link"
      >
        piano
      </a>
    </li>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    data() {
      return {
        pianoLink: null,
        googleGscAnchor: "#googleGSC",
      };
    },
    computed: {
      ...mapState(["study", "studyLinks"]),
      pianoConfigurationExists() {
        return this.studyLinks["piano"];
      },
    },
    mounted() {
      this.pianoLink = `/spa-app/studies/${this.study["id"]}/configuration?tab=piano`;
    },
  };
  </script>
  