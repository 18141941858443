<template>
  <div
    v-loading="isLoadingAI"
    class="popup-inner"
  >
    <div v-if="aiResponse">
      <h3 class="title-tab-">{{ $t(popupTitle) }}</h3>
      <div
        v-for="(item, index) in aiResponse"
        :key="index"
      >
        <RowItem
          @textEvent="handleTextEvent($event)"
          :text="item"
          :isLast="index === aiResponse.length - 1"
        />
      </div>
    </div>
    <div v-if="chatgptError">
      <p>{{ chatgptError }}</p>
    </div>
  </div>
</template>

<script>
import RowItem from "./RowItemChatgpt.vue";

export default {
  props: {
    aiResponse: Array,
    isLoadingAI: Boolean,
    popupTitle: String,
    chatgptError: String,
  },
  components: {
    RowItem,
  },
  methods: {
    handleTextEvent(text, closePopUp) {
      this.$emit("textEvent", text, closePopUp);
    },
  },
};
</script>

<style scoped>
.popup-inner {
  background: #fff;
  align-items: center;
}
</style>

<i18n>
  {
    "en": {
      "title": "Title tags recommended by ChatGPT",
      "metaDescription": "Meta description tags recommended by ChatGPT"
    },
    "fr": {
      "title": "Balises Title recommandées par ChatGPT",
      "metaDescription": "Balises Méta-description recommandées par ChatGPT"
    },
    "de": {
      "title": "Von ChatGPT empfohlener title-tag",
      "metaDescription": "Von ChatGPT empfohlener meta description-tag"
    }
  }
</i18n>
