<template>
  <div>
    <el-row
      type="flex"
      :gutter="20"
      justify="center"
    >
      <el-col :span="8">
        <el-card class="kpi">
          <div class="value-container">
            <div class="flows">
              <div class="flow">
                <div>
                  <domain-authority :metrics="metrics"> </domain-authority>
                </div>
              </div>
            </div>
            <el-divider class="divider divider-horizontal"></el-divider>
            <div>
              <referring-domains :metrics="metrics"> </referring-domains>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card class="kpi">
          <flows :metrics="metrics"> </flows>
        </el-card>
      </el-col>
      <el-col :span="7">
        <el-card class="kpi">
          <categories :classification="classification"> </categories>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import DomainAuthority from "@/components/Metrics/DomainAuthority";
import ReferringDomains from "@/components/Metrics/ReferringDomains";
import Flows from "@/components/Metrics/Flows";
import Categories from "@/components/Metrics/Categories";

export default {
  props: {
    metrics: Object,
    classification: Object,
  },
  components: {
    DomainAuthority,
    ReferringDomains,
    Flows,
    Categories,
  },
};
</script>
<style lang="scss" scoped>
.kpi {
  height: 100%;

  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>
