<template>
  <el-form
    ref="contentFormRef"
    :model="content"
    label-width="300px"
    label-position="right"
  >
    <el-form-item
      v-if="mappedForm.name"
      :label="$t('name')"
      :rules="rulesAssigner('name', mappedForm.name.mandatory)"
    >
      <el-input
        v-model="content.name"
        size="medium"
      />
    </el-form-item>
    <el-form-item
      v-if="mappedForm.keyword"
      :label="$t('keyword')"
      :prop="'keyword'"
      :rules="rulesAssigner('keyword', mappedForm.keyword.mandatory)"
    >
      <SelectStudyKeyword v-model="content.keyword" />
    </el-form-item>
    <el-form-item
      v-if="mappedForm.pageType"
      :label="$t('pageType')"
      :prop="'pageType'"
      :rules="rulesAssigner('pageType', mappedForm.pageType.mandatory)"
    >
      <Selector
        v-model="content.pageType"
        :options="pageTypeOptions"
      />
    </el-form-item>
    <el-form-item
      v-if="mappedForm.contentLength"
      :label="$t('contentLength')"
      :prop="'contentLength'"
      :rules="
        rulesAssigner('contentLength', mappedForm.contentLength.mandatory)
      "
    >
      <ContentLength
        v-model="content.contentLength"
        :keyword="content.keyword"
      />
    </el-form-item>
    <el-form-item
      v-if="mappedForm.costPerWord"
      :label="$t('costPerWord')"
      :prop="'costPerWord'"
      :rules="rulesAssigner('costPerWord', mappedForm.costPerWord.mandatory)"
    >
      <el-input-number
        v-model="content.costPerWord"
        :precision="3"
        :step="0.01"
      >
      </el-input-number>
    </el-form-item>
    <el-form-item
      v-if="mappedForm.costPerWordWithoutMargin"
      :label="$t('costPerWordWithoutMargin')"
      :prop="'costPerWordWithoutMargin'"
      :rules="
        rulesAssigner(
          'costPerWordWithoutMargin',
          mappedForm.costPerWordWithoutMargin.mandatory
        )
      "
    >
      <el-input-number
        v-model="content.costPerWordWithoutMargin"
        :precision="3"
        :step="0.01"
      >
      </el-input-number>
    </el-form-item>
    <el-form-item
      v-if="mappedForm.writingType"
      :label="$t('writingType')"
      :prop="'writingType'"
      :rules="rulesAssigner('writingType', mappedForm.writingType.mandatory)"
    >
      <Selector
        v-model="content.writingType"
        :options="writingTypeOptions"
      />
    </el-form-item>
    <el-form-item
      v-if="mappedAdditionalForm.existingTextUrl"
      :label="$t('existingTextUrl')"
    >
      <el-input v-model="content.existingTextUrl" />
    </el-form-item>
    <el-form-item
      v-if="mappedAdditionalForm.wordCount"
      :label="$t('wordCount')"
    >
      <el-input v-model="content.wordCount" />
    </el-form-item>
    <el-form-item
      v-if="mappedAdditionalForm.sourceLanguage"
      :label="$t('sourceLanguage')"
    >
      <el-select v-model="content.sourceLanguage">
        <el-option
          v-for="item in sourceLanguageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      v-if="mappedAdditionalForm.targetLanguage"
      :label="$t('targetLanguage')"
    >
      <el-select v-model="content.targetLanguage">
        <el-option
          v-for="item in targetLanguageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import ContentLength from "@/components/formItems/ContentLength";
import SelectStudyKeyword from "@/components/formItems/StudyKeywordsSelect";
import Selector from "@/pages/CampaignManager/FormItems/Select";
import TextArea from "@/pages/CampaignManager/FormItems/TextArea";

export default {
  components: {
    SelectStudyKeyword,
    Selector,
    ContentLength,
    TextArea,
  },
  props: {
    form: Array,
    additionalFields: Object,
    value: Object,
  },
  data() {
    return {
      pageTypeOptions: [],
      writingTypeOptions: [],
      sourceLanguageOptions: [],
      targetLanguageOptions: [],
      mappedForm: {},
      mappedAdditionalForm: {},
    };
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(content) {
        this.$emit("input", content);
      },
    },
  },
  watch: {
    "content.writingType": {
      handler: function (newVal, oldVal) {
        delete this.content.existingTextUrl;
        delete this.content.sameTopicUrl;
        delete this.content.sourceLanguage;
        delete this.content.targetLanguage;
        delete this.content.wordCount;
        delete this.content.targetLanguage;
        this.mappedAdditionalForm = {};
        if (this.additionalFields && this.additionalFields.writingType) {
          const fields = this.additionalFields.writingType;
          let matchingFields = null;
          switch (newVal) {
            case "CONTENT_FROM_SCRATCH":
              matchingFields = fields.contentFromScratch;
              break;
            case "OPTIMIZATION_OF_EXISTING_TEXT":
              matchingFields = fields.optimizationOfExistingText;
              break;
            case "NETLINKING_EDITORIAL_PUBLICATION":
              matchingFields = fields.netlinkingEditorialPublication;
              break;
            case "TRANSPOSITION_OF_TEXTS":
              matchingFields = fields.transpositionOfTexts;
              const sourceLanguageField = matchingFields.find(
                (field) => field.name === "sourceLanguage"
              );
              if (sourceLanguageField)
                this.sourceLanguageOptions = sourceLanguageField.options;

              const targetLanguage = matchingFields.find(
                (field) => field.name === "targetLanguage"
              );
              if (targetLanguage)
                this.targetLanguageOptions = targetLanguage.options;
              break;
            default:
              break;
          }
          if (matchingFields) {
            matchingFields.forEach((field) => {
              this.mappedAdditionalForm[field.name] = {
                value: field.value,
              };
            });
          }

          if (this.mappedAdditionalForm.existingTextUrl)
            this.$set(
              this.content,
              "existingTextUrl",
              this.mappedForm.existingTextUrl.value
            );
          if (this.mappedAdditionalForm.secondaryKeywordAnchor)
            this.$set(
              this.content,
              "secondaryKeywordAnchor",
              this.mappedForm.secondaryKeywordAnchor.value
            );
          if (this.mappedAdditionalForm.secondaryKeywordUrl)
            this.$set(
              this.content,
              "secondaryKeywordUrl",
              this.mappedForm.secondaryKeywordUrl.value
            );
          if (this.mappedAdditionalForm.outgoingLinks)
            this.$set(
              this.content,
              "outgoingLinks",
              this.mappedForm.outgoingLinks.value
            );
          if (this.mappedAdditionalForm.sameTopicUrl)
            this.$set(
              this.content,
              "sameTopicUrl",
              this.mappedForm.sameTopicUrl.value
            );
          if (this.mappedAdditionalForm.sourceLanguage)
            this.$set(
              this.content,
              "sourceLanguage",
              this.mappedForm.sourceLanguage.value
            );
          if (this.mappedAdditionalForm.targetLanguage)
            this.$set(
              this.content,
              "targetLanguage",
              this.mappedForm.targetLanguage.value
            );
        }
      },
    },
  },
  mounted() {
    if (this.form) {
      this.mappedForm = {};
      this.form.forEach((field) => {
        this.mappedForm[field.name] = {
          value: field.value,
          mandatory: field.mandatory,
        };
      });

      const pageTypeField = this.form.find((field) => field.name == "pageType");
      if (pageTypeField) this.pageTypeOptions = pageTypeField.options;

      const writingTypeField = this.form.find(
        (field) => field.name == "writingType"
      );
      if (writingTypeField) this.writingTypeOptions = writingTypeField.options;

      if (this.mappedForm.name)
        this.$set(this.content, "name", this.mappedForm.name.value);
      if (this.mappedForm.keyword)
        this.$set(this.content, "keyword", this.mappedForm.keyword.value);
      if (this.mappedForm.pageType)
        this.$set(this.content, "pageType", this.mappedForm.pageType.value);
      if (this.mappedForm.contentLength)
        this.$set(
          this.content,
          "contentLength",
          this.mappedForm.contentLength.value
        );
      if (this.mappedForm.writingType)
        this.$set(
          this.content,
          "writingType",
          this.mappedForm.writingType.value
        );
      if (this.mappedForm.costPerWord)
        this.$set(
          this.content,
          "costPerWord",
          this.mappedForm.costPerWord.value
        );
      if (this.mappedForm.costPerWordWithoutMargin)
        this.$set(
          this.content,
          "costPerWordWithoutMargin",
          this.mappedForm.costPerWordWithoutMargin.value
        );
    }
  },
  methods: {
    rulesAssigner(name, mandatory) {
      if (mandatory) {
        var translatedName = this.$i18n.t(name);
        return [
          {
            required: true,
            message: this.$t("required", { fieldName: translatedName }),
            trigger: "change",
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
<i18n>
{
  "en": {
    "name": "Name",
    "pageType": "Type of page",
    "writingType": "Type of writing",
    "contentLength": "Content length (words)",
    "keyword": "Keyword",
    "content_from_scratch": "Creation of new content",
    "optimization_of_existing_text": "Optimization of existing contents",
    "netlinking_editorial_publication": "Netlinking editorial publication",
    "transposition_of_texts": "Transposition of contents",
    "secondaryKeywordAnchor": "Anchor of secondary keyword",
    "secondaryKeywordUrl": "URL of secondary keywor",
    "outgoingLinks": "Outgoing links",
    "sameTopicUrl": "Example of article",
    "existingTextUrl": "URL of the existing text",
    "sourceLanguage": "Source Language",
    "targetLanguage": "Target Language",
    "costPerWord": "Cost/Word",
    "costPerWordWithoutMargin": "Cost/Word without margin",
    "required": "The field {fieldName} is mandatory",
    "editorialChartUrl":  "Editorial Chart URL",
    "wordCount": "Word count"
  },
  "fr": {
    "name": "Nom",
    "pageType": "Type de page",
    "writingType": "Type de rédaction",
    "contentLength": "Taille du contenu (mots)",
    "keyword": "Mot clé",
    "content_from_scratch": "Création de nouveaux contenus",
    "optimization_of_existing_text": "Optimisation des contenus existants",
    "netlinking_editorial_publication": "Netlinking - publirédactionnel",
    "transposition_of_texts": "Transposition des contenus",
    "secondaryKeywordAnchor": "Ancre du mot clé secondaire",
    "secondaryKeywordUrl": "Url du mot clé secondaire",
    "outgoingLinks": "Liens sortants",
    "sameTopicUrl": "Exemple d'article",
    "existingTextUrl": "URL du texte existant",
    "sourceLanguage": "Langue source",
    "targetLanguage": "Langue cible",
    "costPerWord": "Prix/Mot",
    "required": "Le champs {fieldName} est obligatoire",
    "costPerWordWithoutMargin": "Prix/Mot sans marge",
    "editorialChartUrl": "URL de la charte éditoriale",
    "wordCount":"Nombre de mots actuellement sur la page"
  },
  "de": {
    "name": "Name",
    "pageType": "Seitentyp",
    "writingType": "Art des Schreibens",
    "contentLength": "Inhaltsgröße (Wörter)",
    "keyword": "Stichwort",
    "content_from_scratch": "Erstellung neuer Inhalte",
    "optimization_of_existing_text": "Optimierung bestehender Inhalte",
    "netlinking_editorial_publication": "Netlinking - redaktionelle Werbung",
    "transposition_of_texts": "Umsetzung von Inhalten",
    "secondaryKeywordAnchor": "Sekundärer Keyword-Anker",
    "secondaryKeywordUrl": "Sekundäre Keyword-URL",
    "outgoingLinks": "Ausgehende Links",
    "sameTopicUrl": "Beispielartikel",
    "existingTextUrl": "URL du texte existant",
    "sourceLanguage": "Ausgangssprache",
    "targetLanguage": "Zielsprache",
    "costPerWord": "Preis/Wort",
    "costPerWordWithoutMargin": "Preis/Wort ohne Marge",
    "required": "Das Feld {fieldName} ist obligatorisch",
    "editorialChartUrl":  "URL des Redaktionsplans",
    "wordCount": "Wortzahl"
  }
}
</i18n>
