import Repository from "@/services/repository";

export default {
  uploadTaskFile(file, folder) {
    return Repository.post(`/files.json`, {
      params: {
        file: file,
        folder: folder,
      },
    }).then((response) => response.data);
  },
  getFile(s3Name, folder) {
    return Repository.get(`/files.json`, {
      params: {
        s3Name: s3Name,
        folder: folder,
      },
      responseType: "arraybuffer",
    }).then((response) => response.data);
  },
  deleteFile(s3Name, folder) {
    return Repository.delete(`/files.json`, {
      params: {
        s3Name: s3Name,
        folder: folder,
      },
    }).then((response) => response.data);
  },
};
