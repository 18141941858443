<template>
  <div>
    <div class="chart-title">
      <el-tooltip
        placement="bottom"
        transition="el-fade-in"
      >
        <div slot="content">
          {{ $t("traffic_typology_explication") }}
        </div>
        <span
          >{{ $t("traffic_typology") }}
          <i class="fas fa-question-circle"></i>
        </span>
      </el-tooltip>
    </div>
    <highcharts
      v-if="isMounted"
      v-loading="isLoading"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
HighchartsMore(Highcharts);
HighchartsNoData(Highcharts);

export default {
  props: {
    data: Object,
    isLoading: Boolean,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      isMounted: false,
      chartOptions: {
        chart: {
          type: "bubble",
          plotBorderWidth: 1,
          zoomType: "xy",
        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: this.$i18n.t("nb_keywords_first_page"),
          },
          plotLines: [
            {
              zIndex: 3,
            },
          ],
          allowDecimals: false,
          min: 1,
        },
        yAxis: {
          title: {
            text: this.$i18n.t("traffic_estimated"),
          },
          allowDecimals: false,
          min: 0,
          plotLines: [
            {
              zIndex: 3,
            },
          ],
        },
        series: [{ data: [] }],
        tooltip: {
          useHTML: true,
          headerFormat: "<table>",
          pointFormat: `<tr><th colspan="2"><h4>{point.name}</h3></th></tr>
					<tr><th>${this.$i18n.t(
            "traffic_estimated"
          )}:</th><td><center>{point.y}</center></td></tr>
					<tr><th>${this.$i18n.t(
            "nb_keywords_first_page"
          )}:</th><td><center>{point.x}</center></td></tr>
					<tr><th>${this.$i18n.t(
            "nb_keywords_first_position"
          )}:</th><td><center>{point.z}</center></td></tr>`,
          footerFormat: "</table>",
          followPointer: true,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: " {point.name} ",
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      this.chartOptions.series[0].data = this.data.series;
    },
  },
  watch: {
    data() {
      if (this.data) this.updateSeries();
    },
  },
  mounted() {
    this.isMounted = true;
    if (this.data) this.updateSeries();
  },
};
</script>

<style scoped lang="scss">
.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
}
</style>

<i18n>
{
  "en": {
    "nb_keywords_first_page": "# keywords on first page",
    "nb_keywords_first_position": "# keywords in first position",
    "nb_keywords_first_position_short": "# keywords in first position",
    "traffic_estimated": "Estimated SEO Traffic",
    "traffic_typology": "Traffic typology",
    "traffic_typology_explication": "The size of the bubbles corresponds to the number of keywords in 1st position"
  },
  "fr": {
    "nb_keywords_first_page": "Nombre de mots clés en 1ère page",
    "nb_keywords_first_position": "Nombre de mots clés en 1ère position",
    "nb_keywords_first_position_short": "Nombre de 1ères positions",
    "traffic_estimated": "Trafic SEO estimé",
    "traffic_typology": "Typologie du trafic",
    "traffic_typology_explication": "La taille des bulles correspond aux nombre de mots clés en 1ère position"
  },
  "de": {
    "nb_keywords_first_page": "Anzahl der Keywords auf der 1. Seite",
    "nb_keywords_first_position": "Anzahl der Keywords an 1. Position",
    "nb_keywords_first_position_short": "Anzahl der 1. Plätze",
    "traffic_estimated": "Geschätzter SEO-Traffic",
    "traffic_typology": "Verkehrstypologie",
    "traffic_typology_explication": "Die Größe der Bubbles entspricht der Anzahl der Keywords an 1. Position"
  }
}
</i18n>
