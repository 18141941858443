<template>
  <el-card style="text-align: center">
    <h2 style="font-size: 25px">{{ $t("404NotFound") }}</h2>
    <div>
      <i
        class="el-icon-warning"
        style="font-size: 80px"
      ></i>
    </div>
    <el-divider></el-divider>
    <span>{{ $t("redirect1") }}</span>
    <el-link href="/">
      <u>{{ $t("clickLink") }}</u>
    </el-link>
    <span>{{ $t("redirect2") }}</span>
  </el-card>
</template>

<script>
export default {};
</script>

<i18n>
{
  "fr": {
    "404NotFound": "Oups! La page que vous demandez n'existe pas",
    "redirect1": "Veuillez cliquer sur ",
    "clickLink": "ce lien",
    "redirect2": "afin d'être redirigé vers la page d'accueil"
  },
  "en": {
    "404NotFound": "Oups! The page you requested does not exist",
    "redirect1": "Please click on ",
    "clickLink": "this link",
    "redirect2": "to be redirected to the Home page"
  },
  "de": {
    "404NotFound": "Hoppla! Die angeforderte Seite existiert nicht",
    "redirect1": "bitte klicken",
    "clickLink": "dieser Link",
    "redirect2": "auf die Homepage umgeleitet werden"
  }
}
</i18n>
