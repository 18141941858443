<template>
  <div>
    <div class="studies">
      <el-card>
        <h2>{{ $t("studies") }}</h2>
        <div class="grid">
          <ag-grid-vue
            :domLayout="'autoHeight'"
            class="ag-theme-material"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="rowData"
            :resizable="true"
            :filter="true"
            :localeTextFunc="localeTextFunc"
            :suppressCellSelection="true"
            :suppressContextMenu="true"
            @gridReady="onGridReady"
            @firstDataRendered="adjustGrid"
            @rowDataChanged="adjustGrid"
          >
          </ag-grid-vue>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
  },
  props: {
    companyId: Number,
  },
  data() {
    return {
      rowData: null,
      gridApi: null,
      columnApi: null,
    };
  },
  computed: {
    defaultColDef() {
      return {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      };
    },
    columnDefs() {
      return [
        {
          headerName: this.$i18n.t("name"),
          field: "name",
          minWidth: 300,
          filter: "agTextColumnFilter",
          cellRenderer: this.linkRenderer,
        },
        {
          headerName: this.$i18n.t("url"),
          field: "url",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("study_type"),
          field: "studyType",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("country"),
          field: "country",
          filter: "agTextColumnFilter",
        },
      ];
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    fetchData() {
      CompaniesRepository.getCompanyStudies(this.companyId)
        .then((data) => {
          this.rowData = data.data.map((study) => ({
            id: study.id,
            name: study.attributes.name,
            url: study.attributes.url,
            studyType: this.$i18n.t(study.attributes.studyType),
            country: study.attributes.country,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="/a/${params.data.id}">${params.value}</a>`;
    },
  },
  beforeMount() {
    this.fetchData();
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "studies": "Studies",
    "name": "Name",
    "url": "URL",
    "country": "Country",
    "users": "Users",
    "study_type": "Study type",
    "main_study": "Main study",
    "secondary_study": "Secondary study",
    "oneshot_study": "Oneshot study"
  },
  "fr": {
    "studies": "Etudes",
    "name": "Nom",
    "url": "URL",
    "country": "Pays",
    "users": "Utilisateurs",
    "study_type": "Type d'étude",
    "main_study": "Etude principale",
    "secondary_study": "Etude secondaire",
    "oneshot_study": "Etude oneshot"
  },
  "de": {
    "studies": "Studien",
    "name": "Name",
    "url": "URL",
    "country": "Zahlt",
    "users": "Benutzer",
    "study_type": "Studientyp",
    "main_study": "Hauptstudium",
    "secondary_study": "Zweitstudium",
    "oneshot_study": "Oneshot-Studie"
  }
}
</i18n>
