<template>
  <el-tabs
    v-model="activeTab"
    :stretch="true"
    tab-position="left"
  >
    <el-tab-pane name="keywords">
      <div
        slot="label"
        v-loading="isLoadingKeywords || isLoadingMetrics"
      >
        {{ $t("topKeywords") }}
      </div>
      <top-keywords-grid
        v-if="dataTopKeywords !== null"
        v-loading="isLoadingKeywords || isLoadingMetrics"
        :request="request"
        :data-top-keywords="dataTopKeywords"
      >
      </top-keywords-grid>
    </el-tab-pane>
    <el-tab-pane name="pages">
      <div
        slot="label"
        v-loading="isLoadingPages || isLoadingMetrics"
      >
        {{ $t("topPages") }}
      </div>
      <top-pages-grid
        :isAdmin="isAdmin"
        :data-top-pages="dataTopPages"
        :metrics="metrics"
        :request="request"
        v-loading="isLoadingPages || isLoadingMetrics"
      >
      </top-pages-grid>
    </el-tab-pane>
    <el-tab-pane name="backlinks">
      <div
        slot="label"
        v-loading="isLoadingBacklinks || isLoadingMetrics"
      >
        {{ $t("topBacklinks") }}
      </div>
      <top-backlinks-grid
        :data-top-backlinks="dataTopBacklinks"
        v-loading="isLoadingBacklinks || isLoadingMetrics"
      >
      </top-backlinks-grid>
    </el-tab-pane>
    <el-tab-pane name="competitor">
      <div
        slot="label"
        v-loading="isLoadingMetrics"
      >
        {{ $t("topCompetitor") }}
      </div>
      <div
        class="warning-msg"
        v-if="request.url.type != 'DOMAIN'"
      >
        <i
          style="color: #e6a23c"
          class="el-icon-warning"
          v-html="$t('warningMsg', [domain])"
        ></i>
      </div>
      <top-competitor-grid
        :request="request"
        :metrics="metrics"
        v-loading="isLoadingMetrics"
        :studyId="studyId"
      >
      </top-competitor-grid>
    </el-tab-pane>
    <el-tab-pane
      name="byCountry"
      lazy
    >
      <div
        slot="label"
        v-loading="isLoadingMetrics"
      >
        {{ $t("byCountryRepartition") }}
      </div>
      <div
        class="warning-msg"
        v-if="request.url.type != 'DOMAIN'"
      >
        <i
          style="color: #e6a23c"
          class="el-icon-warning"
          v-html="$t('warningMsg', [domain])"
        ></i>
      </div>
      <div
        class="by-country-graphs"
        v-if="isMounted"
      >
        <div style="flex: 1">
          <by-country-graph
            :data="dataByCountry"
            data-key="organicTraffic"
            :request="request"
            v-loading="isLoadingByCountry || isLoadingMetrics"
          >
          </by-country-graph>
        </div>
        <div style="flex: 1">
          <by-country-graph
            :data="dataByCountry"
            data-key="organicKeywords"
            :request="request"
            v-loading="isLoadingByCountry || isLoadingMetrics"
          >
          </by-country-graph>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import TitleStyled from "@/pages/DomainSearch/Shared/title";

import TopBacklinksGrid from "./TopBacklinksGrid";
import TopCompetitorGrid from "./TopCompetitorsGrid";
import TopKeywordsGrid from "./TopKeywordsGrid";
import TopPagesGrid from "./TopPagesGrid";
import ByCountryGraph from "./ByCountryGraph.vue";

export default {
  components: {
    TitleStyled,
    TopPagesGrid,
    TopKeywordsGrid,
    TopBacklinksGrid,
    TopCompetitorGrid,
    ByCountryGraph,
  },
  props: {
    dataTopBacklinks: Object,
    dataTopPages: Array,
    dataTopKeywords: Array,
    dataByCountry: Array,
    isLoadingMetrics: Boolean,
    isLoadingBacklinks: Boolean,
    isLoadingPages: Boolean,
    isLoadingKeywords: Boolean,
    isLoadingByCountry: Boolean,
    metrics: Object,
    request: Object,
    isAdmin: Boolean,
    studyId: Number,
  },
  data() {
    return {
      activeTab: "keywords",
      title: this.$i18n.t("topKeywordsPagesBacklinks"),
      isMounted: false,
    };
  },
  computed: {
    domain() {
      let hostnames = new URL(this.request.url.url).hostname.split(".");
      return (
        hostnames[hostnames.length - 2] + "." + hostnames[hostnames.length - 1]
      );
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
<style lang="scss" scoped>
.by-country-graphs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.warning-msg {
  text-align: center;
  font-size: 1rem;
  color: black;
  margin-bottom: 1rem;
}
</style>
<i18n>
{
  "en": {
    "topKeywordsPagesBacklinks": "Best Keywords / Pages / Backlinks",
    "topKeywords": "Best keywords",
    "topPages": "Best pages",
    "topBacklinks": "Best backlinks",
    "topCompetitor": "Best competitors",
    "byCountryRepartition": "Breakdown by country",
    "warningMsg": " This report shows the competitors for <strong>{0}</strong> domain"
  },
  "fr": {
    "topKeywordsPagesBacklinks": "Top mots clés / Pages / Backlinks",
    "topKeywords": "Top mots clés",
    "topPages": "Top pages",
    "topBacklinks": "Top backlinks",
    "topCompetitor": "Top concurrents",
    "byCountryRepartition": "Répartition par pays",
    "warningMsg": " Ce rapport affiche les concurrents pour le domaine <strong>{0}</strong>"
  },
  "de": {
    "topKeywordsPagesBacklinks": "Top Keywords / Seiten / Backlinks",
    "topKeywords": "Top-Schlüsselwörter",
    "topPages": "Top-Seiten",
    "topBacklinks": "Top-Backlinks",
    "topCompetitor": "Top-Konkurrenten",
    "byCountryRepartition": "Aufschlüsselung nach Ländern",
    "warningMsg": "Dieser Bericht zeigt Mitbewerber für die Domain <strong>{0}</strong> an"
  }
}
</i18n>
