<template>
  <div class="flex-box">
    <div class="block">
      <span>{{ $t("granularity") }}</span>
      <el-slider
        v-model="granularityScale"
        :format-tooltip="formatTooltip"
      ></el-slider>
    </div>
    <div class="apply-button">
      <el-button
        plain
        type="danger"
        @click="cancelSearch"
        >{{ $t("cancel") }}</el-button
      >
      <el-button
        plain
        type="primary"
        @click="updateKeywords"
        >{{ $t("apply") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      granularity: 0.95,
    };
  },
  computed: {
    granularityScale: {
      get() {
        return this.granularity * 100;
      },
      set(granularityScale) {
        this.granularity = granularityScale / 100;
      },
    },
  },
  methods: {
    formatTooltip(val) {
      return val / 100;
    },
    updateKeywords() {
      this.$emit("update-keywords", this.granularity);
    },
    cancelSearch() {
      this.$emit("cancel-search", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  .block {
    width: 10%;
    text-align: center;
    margin-top: 2rem;
  }
  .apply-button {
    margin-left: auto;
  }
}
</style>
<i18n>
{
  "en": {
    "apply": "Apply",
    "cancel": "Cancel",
    "granularity": "Granularity"
  },
  "fr": {
    "apply": "Appliquer",
    "cancel": "Annuler",
    "granularity": "Granularité"
  },
  "de": {
    "apply": "Anwenden",
    "cancel": "Stornieren",
    "granularity": "Die Granularität"
  }
}
</i18n>
