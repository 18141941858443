export const agGridMixin = {
  data() {
    return {
      gridOptions: {
        enableRangeSelection: true,
        copyHeadersToClipboard: true,
        onColumnGroupOpened: () => this.adjustGrid(),
      },
      isRowsSelected: false,
    };
  },
  methods: {
    selectionChanged(params) {
      this.gridApi = params.api;
      if (this.gridApi.getSelectedNodes().length > 0)
        this.isRowsSelected = true;
      else this.isRowsSelected = false;
    },
    autoSizeAll() {
      const allColumnIds = [];
      for (const columnGroup of this.columnApi.getAllDisplayedColumnGroups()) {
        allColumnIds.push(columnGroup.colId);
      }
      this.columnApi.autoSizeColumns(allColumnIds);
    },
    localeTextFunc(key, defaultValue) {
      const gridKey = "grid." + key;
      const locale = this.$i18n.locale;
      const gridTranslations = this.$i18n.messages[locale].grid;
      if (gridTranslations && gridTranslations.hasOwnProperty(key)) {
        return this.$i18n.t(gridKey);
      } else {
        return defaultValue;
      }
    },
    checkRenderer(params) {
      if (params.value) {
        return `<i class="fas fa-check"></i>`;
      }
    },
    selectAllRows() {
      var allDisplayedAreSelected = true;
      this.gridApi.getRenderedNodes().forEach((node) => {
        if (!node.isSelected()) allDisplayedAreSelected = false;
      });

      this.gridApi.getRenderedNodes().forEach((node) => {
        node.setSelected(!allDisplayedAreSelected, false);
      });
    },
  },
};
