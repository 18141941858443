<template>
  <div>
    <div class="presets">
      <div class="label">
        {{ $t("presets") }}
      </div>
      <div>
        <el-radio-group
          v-model="preset"
          plain
          size="mini"
        >
          <el-radio-button label="ALL_KEYWORDS">{{
            $t("all")
          }}</el-radio-button>
          <el-tooltip
            placement="bottom"
            :content="$t('only_competitor_explanation')"
          >
            <el-radio-button label="ONLY_COMPETITOR">{{
              $t("only_competitor")
            }}</el-radio-button>
          </el-tooltip>
          <el-tooltip
            placement="bottom"
            :content="$t('both_explanation')"
          >
            <el-radio-button label="BOTH">{{
              $t("you_and_competitor")
            }}</el-radio-button>
          </el-tooltip>
          <el-tooltip
            placement="bottom"
            :content="$t('only_you_explanation')"
          >
            <el-radio-button label="ONLY_YOU">{{
              $t("only_you")
            }}</el-radio-button>
          </el-tooltip>
        </el-radio-group>
      </div>
    </div>
    <grid
      :studyId="studyId"
      :request="params"
      :websites="websites"
      :preset="preset"
      :keywords-data="rowData"
      :current-user="currentUser"
      @show-keyword="$emit('show-keyword', $event)"
      @show-task="$emit('show-task', $event)"
    >
    </grid>
  </div>
</template>

<script>
import Grid from "@/pages/Competitor/AllKeywords/Grid";

export default {
  props: {
    params: Object,
    websites: Object,
    studyId: Number,
    currentUser: Object,
  },
  components: {
    Grid,
  },
  data() {
    return {
      keywordsData: null,
      rowData: null,
      isLoading: false,
      preset: "ALL_KEYWORDS",
    };
  },
  methods: {
    filterData() {
      switch (this.preset) {
        case "only_competitor":
          this.preset = "ONLY_COMPETITOR";
          break;
        case "you_and_competitor":
          this.preset = "BOTH";
          break;
        case "only_you":
          this.preset = "ONLY_YOU";
          break;
        case "all":
          this.preset = "ALL_KEYWORDS";
          break;
      }
    },
  },
  watch: {
    preset() {
      // this.filterData()
    },
  },
};
</script>
<style scoped lang="scss">
.presets {
  text-align: left;

  .label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}
</style>

<i18n>
{
  "en": {
    "presets": "Presets of first-page keywords",
    "all": "All keywords",
    "only_competitor": "Only your competitor",
    "you_and_competitor": "You and your competitor",
    "only_you": "Only you",
    "only_competitor_explanation": "Keywords where only your competitor is positioned in the top10",
    "only_you_explanation": "Keywords where only you are positioned in the top10",
    "both_explanation": "keywords where you and your competitor are positioned in the top10"
  },
  "fr": {
    "presets": "Présélections de mots-clés en première page",
    "all": "Tous les mots clés",
    "only_competitor": "Uniquement votre concurrent",
    "you_and_competitor": "Vous et votre concurrent",
    "only_you": "Uniquement vous",
    "only_competitor_explanation": "Les mots clés où seulement votre concurrent est positionné dans le top10",
    "only_you_explanation": "Les mots clés où seulement vous êtes positionné dans le top10",
    "both_explanation": "Les mots clés où vous et votre concurrent êtes positionnés dans le top10"
  },
  "de": {
    "presets": "Titelseiten-Keyword-Voreinstellungen",
    "all": "Alle Schlüsselwörter",
    "only_competitor": "Nur Ihr Konkurrent",
    "you_and_competitor": "Sie und Ihr Konkurrent",
    "only_you": "nur du",
    "only_competitor_explanation": "Keywords, bei denen nur Ihr Konkurrent in den Top 10 positioniert ist",
    "only_you_explanation": "Die Keywords, bei denen nur Sie in den Top10 positioniert sind",
    "both_explanation": "Die Keywords, bei denen Sie und Ihr Mitbewerber in den Top 10 positioniert sind"
  }
}
</i18n>
