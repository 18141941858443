import { format } from "date-fns";
import en from "date-fns/locale/en";
import fr from "date-fns/locale/fr";

export function formatDate(date, formatStr) {
  return format(date, formatStr, { locale: I18nLocale === "fr" ? fr : en });
}

export function formatDateWithDashes(date) {
 return date.toString().split('T')[0];
}
