<template>
  <el-card
    class="no-border-on-print"
    v-if="studyResponse"
  >
    <div class="flex-container">
      <h2>
        {{ $t("positions") }}
        <span class="date-period"
          >{{ periodDatesShown[0] | formatDateFromLocale }} {{ $t("to") }}
          {{ periodDatesShown[1] | formatDateFromLocale }}</span
        >
      </h2>
      <el-button
        v-on:click="linkToKeywords"
        class="no-print"
      >
        {{ $t("analyseDetail") }}
      </el-button>
    </div>
    <el-card class="chart">
      <visibility-chart
        :account-id="accountId"
        :data="positionsData"
        :user="currentUser"
        v-loading="isLoading"
      ></visibility-chart>
    </el-card>
    <div class="top-movements-grids">
      <el-row :gutter="20">
        <el-col
          :span="12"
          :lg="12"
        >
          <top-movements-grid
            class="top-movements-grid page-break"
            :keywords-data="topTrafficMovements.highest.keywordsData"
            :study-id="accountId"
            top-type="ENTRY"
            :start-date="positionsRequest.start_date.epochDay"
            :end-date="positionsRequest.end_date.epochDay"
            @show-keyword="showKeyword"
          >
          </top-movements-grid>
        </el-col>
        <el-col
          :span="12"
          :lg="12"
        >
          <top-movements-grid
            class="top-movements-grid"
            :keywords-data="topTrafficMovements.lowest.keywordsData"
            :study-id="accountId"
            top-type="EXIT"
            :start-date="positionsRequest.start_date.epochDay"
            :end-date="positionsRequest.end_date.epochDay"
            @show-keyword="showKeyword"
          >
          </top-movements-grid>
        </el-col>
      </el-row>
    </div>
    <keyword-dialog
      v-model="keywordDialogVisible"
      :keyword-id="selectedKeywordId"
      :initial-search-engine="defaultSearchEngine"
      :currentUser="currentUser"
      :user-options="userOptions"
      :studyId="accountId"
    ></keyword-dialog>
  </el-card>
</template>

<script>
import { subDays, subMonths, subWeeks, subYears } from "date-fns";
import { DateTime } from "luxon";
import { dateTimeToEpochDay } from "@/javascripts/dateHelpers";
import { RepositoryFactory } from "@/services/repositoryFactory";
import TopMovementsMetric from "@/pages/Keywords/TopMovements/Metric";
import VisibilityChart from "@/pages/Keywords/Charts/VisibilityChart";
import TopMovementsGrid from "@/pages/Summary/TopMovements";
import KeywordDialog from "@/pages/KeywordDialog";
import { formatDateWithDashes } from "@/javascripts/formatDate";

const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  props: {
    accountId: Number,
    accountConfigurationJson: Object,
    bestTag: String,
    companyId: Number,
    currentUser: Object,
  },
  computed: {
    accountConfiguration() {
      return {
        cmpPeriod: this.accountConfigurationJson.data.attributes.cmpPeriod,
        conversionMonitoringType:
          this.accountConfigurationJson.data.attributes
            .conversionMonitoringType,
        frequency: this.accountConfigurationJson.data.attributes.frequency,
        graphType: this.accountConfigurationJson.data.attributes.graphType,
        period: this.accountConfigurationJson.data.attributes.period,
      };
    },
    defaultSearchEngine() {
      return this.studyResponse.data.searchEngines.find(
        (searchEngine) =>
          searchEngine.id === this.studyResponse.data.defaultSearchEngineId
      );
    },
    periodDates() {
      const end_date = DateTime.utc().startOf("week");
      let start_date = null;
      if (this.accountConfiguration.period === 7)
        start_date = end_date.minus({days: 7})
      else if (this.accountConfiguration.period === 30)
        start_date = end_date.minus({month: 1})
      else if (this.accountConfiguration.period === 90)
        start_date = end_date.minus({month: 3})
      else if (this.accountConfiguration.period === 180)
        start_date = end_date.minus({month: 6});
      else if (this.accountConfiguration.period === 365)
        start_date = end_date.minus({year: 1});

      this.periodDatesShown[0] = start_date.toFormat('dd/MM/yyyy');
      this.periodDatesShown[1] = end_date.toFormat('dd/MM/yyyy');

      return [start_date, end_date];
    },
    positionsRequest() {
      let frequency = "WEEK";
      if (this.accountConfiguration.frequency === "day") {
        frequency = "WEEK";
      } else if (this.accountConfiguration.frequency === "week") {
        frequency = "WEEK";
      } else if (this.accountConfiguration.frequency === "month") {
        frequency = "MONTH";
      }
      let tagsArray;
      if (this.bestTag) {
        tagsArray = [this.bestTag];
      } else {
        tagsArray = ["All"];
      }
      return {
        filterStudyTags: tagsArray,
        frequency: frequency,
        start_date: {
          epochDay: dateTimeToEpochDay(
            DateTime.utc(
              this.periodDates[0].year,
              this.periodDates[0].month,
              this.periodDates[0].day
            ).startOf("week")
          ),
        },
        end_date: {
          epochDay: dateTimeToEpochDay(
            DateTime.utc(
              this.periodDates[1].year,
              this.periodDates[1].month,
              this.periodDates[1].day
            ).startOf("week")
          ),
        },
        positionBuckets: [
          { positionMin: 1, positionMax: 3 },
          { positionMin: 4, positionMax: 10 },
          { positionMin: 11, positionMax: 30 },
          { positionMin: 31, positionMax: 100 },
        ],
      };
    },
  },
  components: {
    TopMovementsMetric,
    VisibilityChart,
    TopMovementsGrid,
    KeywordDialog,
  },
  data() {
    return {
      bucketData: null,
      periodDatesShown: [],
      comparisonPeriodDates: [],
      comparisonPeriodDatesShown: [],
      periodInDays: null,
      frequency: null,
      positionsData: null,
      comparisonPeriod: null,
      isLoading: false,
      lastDatePercentTop10: null,
      firstDatePercentTop10: null,
      topTrafficMovements: {
        highest: {
          count: null,
          keywordsData: null,
        },
        lowest: {
          count: null,
          keywordsData: null,
        },
      },
      selectedKeywordId: null,
      keywordDialogVisible: false,
      taskDialogVisible: false,
      userOptions: null,
      selectedTask: null,
      defaultTask: {
        archived: false,
        completionDate: null,
        description: null,
        dueDate: null,
        kind: null,
        pageId: null,
        position: null,
        priority: null,
        userId: null,
      },
      trackedKeywords: [],
      studyResponse: null,
    };
  },
  methods: {
    fetchPositionsData() {
      this.isLoading = true;
      const request = {
        urls: [{ url: this.studyResponse.data.url, type: "DOMAIN" }],
        keywords: this.trackedKeywords.map((trackedKeyword) => ({
          text: trackedKeyword.text,
          locationId: trackedKeyword.locationId,
        })),
        searchEngineParameters: {
          isoCountryCode: this.defaultSearchEngine.isoCountryCode,
          device: this.defaultSearchEngine.device,
          isoLanguageCode: this.defaultSearchEngine.isoLanguageCode,
        },
        positionsBuckets: [
          { min: 1, max: 3 },
          { min: 4, max: 10 },
          { min: 11, max: 30 },
          { min: 31, max: 100 },
        ],
        frequency:
          this.positionsRequest.frequency === "WEEK" ? "WEEKLY" : "DAILY",
        rangeDate: {
          start: formatDateWithDashes(this.periodDates[0]),
          end: formatDateWithDashes(this.periodDates[1]),
        },
      };

      this.$api
        .post("/positions/websites-statistics-history", request)
        .then((response) => {
          const websiteStatistics = response.data.data.find(
            (ws) => ws.url.url === this.studyResponse.data.url
          );
          this.positionsData = Object.entries(
            websiteStatistics.websiteStatisticsByDates
          )
            .map(([date, websiteStatistics]) => {
              return {
                avgPosition: websiteStatistics.averagePosition,
                estimatedTraffic: websiteStatistics.estimatedTraffic,
                nbKeywords: websiteStatistics.numberOfKeywords,
                top13: websiteStatistics.positionBucketsStatistics.find(
                  (bucket) =>
                    bucket.positionMin === 1 && bucket.positionMax === 3
                ).numberOfKeywords,
                top410: websiteStatistics.positionBucketsStatistics.find(
                  (bucket) =>
                    bucket.positionMin === 4 && bucket.positionMax === 10
                ).numberOfKeywords,
                top1130: websiteStatistics.positionBucketsStatistics.find(
                  (bucket) =>
                    bucket.positionMin === 11 && bucket.positionMax === 30
                ).numberOfKeywords,
                top31100: websiteStatistics.positionBucketsStatistics.find(
                  (bucket) =>
                    bucket.positionMin === 31 && bucket.positionMax === 100
                ).numberOfKeywords,
                date: new Date(date),
              };
            })
            .sort((a, b) => a.date - b.date);
          this.lastDatePercentTop10 = Math.round(
            ((this.positionsData[this.positionsData.length - 1].top13 +
              this.positionsData[this.positionsData.length - 1].top410) /
              this.positionsData[this.positionsData.length - 1].nbKeywords) *
              100
          );
          this.firstDatePercentTop10 = Math.round(
            ((this.positionsData[0].top13 + this.positionsData[0].top410) /
              this.positionsData[0].nbKeywords) *
              100
          );
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchStudy() {
      this.$api.get(`/studies/${this.accountId}`).then((response) => {
        this.studyResponse = response.data;
        this.getTrackedKeywords();
      });
    },
    getTrackedKeywords() {
      const linkTrackedKeywordSearch =
        this.studyResponse.links.searchTrackedKeywords;
      let requestTrackedKeywordsSearch = {
        frequency: this.positionsRequest.frequency,
      };
      if (this.bestTag !== null && this.bestTag !== undefined) {
        requestTrackedKeywordsSearch.tags = [this.bestTag];
      }
      this.$api[linkTrackedKeywordSearch.method](
        linkTrackedKeywordSearch.href,
        requestTrackedKeywordsSearch
      ).then((responseTrackedKeywords) => {
        this.trackedKeywords = responseTrackedKeywords.data.data;
        this.getTopMovementsData();
        this.fetchPositionsData();
      });
    },
    getTopMovementsData() {
      let requestApi = {
        url: {
          url: this.studyResponse.data.url,
          type: "DOMAIN",
        },
        searchEngineParameters: {
          isoCountryCode: this.defaultSearchEngine.isoCountryCode,
          device: this.defaultSearchEngine.device,
          isoLanguageCode: this.defaultSearchEngine.isoLanguageCode,
        },
        positionsBuckets: [
          { min: 1, max: 3 },
          { min: 4, max: 10 },
          { min: 11, max: 30 },
        ],
        frequency:
          this.positionsRequest.frequency === "WEEK" ? "WEEKLY" : "DAILY",
        rangeDate: {
          start: formatDateWithDashes(this.periodDates[0]),
          end: formatDateWithDashes(this.periodDates[1]),
        },
      };

      requestApi.keywords = this.trackedKeywords.map((trackedKeyword) => {
        return {
          text: trackedKeyword.text,
          locationId: trackedKeyword.locationId,
        };
      });

      this.$api
        .post(`/positions/top-movements`, requestApi)
        .then((response) => {
          const data = response.data.data;
          for (const topType of ["highest", "lowest"]) {
            if (data.estimatedTrafficVariations[topType]) {
              const keywordsData = data.estimatedTrafficVariations[topType].map(
                (keyword) => {
                  const trackedKeyword = this.trackedKeywords.find(
                    (tk) => tk.text === keyword.text
                  );
                  return {
                    keyword: keyword.text,
                    volume: parseInt(keyword.volume),
                    position: keyword.endPosition ? keyword.endPosition : null,
                    pastPosition: keyword.startPosition
                      ? keyword.startPosition
                      : null,
                    estimatedTrafficVariation: keyword.estimatedTrafficVariation
                      ? parseInt(keyword.estimatedTrafficVariation)
                      : 0,
                    id: trackedKeyword.id,
                    tags: trackedKeyword.tags,
                  };
                }
              );
              this.topTrafficMovements[topType].count = keywordsData.length;
              this.topTrafficMovements[topType].keywordsData = keywordsData;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    setComparisonPeriod() {
      let end_date = null;
      let start_date = null;
      if (
        this.accountConfiguration.cmpPeriod === "last_period" ||
        (this.accountConfiguration.period === 30 &&
          this.accountConfiguration.cmpPeriod === "month") ||
        (this.accountConfiguration.period === 365 &&
          this.accountConfiguration.cmpPeriod === "year")
      ) {
        const numberOfDays =
          (this.periodDates[1] - this.periodDates[0]) / (1000 * 60 * 60 * 24);
        end_date = new Date(this.periodDates[1]);
        end_date.setDate(this.periodDates[1].getDate() - numberOfDays - 1);
        start_date = new Date(this.periodDates[0]);
        start_date.setDate(this.periodDates[0].getDate() - numberOfDays - 1);
      } else if (this.accountConfiguration.cmpPeriod === "month") {
        end_date = subMonths(this.periodDates[1], 1);
        start_date = subMonths(this.periodDates[0], 1);
      } else if (this.accountConfiguration.cmpPeriod === "year") {
        end_date = subYears(this.periodDates[1], 1);
        start_date = subYears(this.periodDates[0], 1);
      } else if (this.accountConfiguration.cmpPeriod === "52weeks") {
        const mainPeriodEnd = this.periodDates[1];
        const mainPeriodStart = this.periodDates[0];
        let start_dow = new Date(mainPeriodStart).getDay();
        let end_dow = new Date(mainPeriodEnd).getDay();
        end_date = subYears(mainPeriodEnd, 1);
        start_date = subYears(mainPeriodStart, 1);
        while (end_date.getDay() != end_dow) {
          end_date = subDays(end_date, -1);
        }
        while (start_date.getDay() != start_dow) {
          start_date = subDays(start_date, -1);
        }
      }
      this.comparisonPeriodDatesShown[0] =
        ("0" + start_date.getDate()).slice(-2) +
        "/" +
        ("0" + (start_date.getMonth() + 1)).slice(-2) +
        "/" +
        start_date.getFullYear();
      this.comparisonPeriodDatesShown[1] =
        ("0" + end_date.getDate()).slice(-2) +
        "/" +
        ("0" + (end_date.getMonth() + 1)).slice(-2) +
        "/" +
        end_date.getFullYear();
      return [start_date, end_date];
    },
    linkToKeywords() {
      window.open(`/a/${this.accountId}/keywords`);
    },
    showKeyword(keywordId) {
      this.selectedKeywordId = keywordId;
      this.keywordDialogVisible = true;
    },
    showTask(keyword) {
      const taskTitle = this.$i18n.t("workOnKeyword", {
        keywordText: keyword.text,
      });
      this.selectedTask = {
        ...this.defaultTask,
        title: taskTitle,
        state: "pending",
        keywordId: keyword.id,
        keyword: { text: keyword.text },
      };
      this.taskDialogVisible = true;
    },
    createTask(task) {
      this.$api
        .post(`/studies/${this.accountId}/tasks`, task)
        .then(() => {
          this.$message({
            message: this.$i18n.t("taskCreatedSuccess"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("taskCreatedFailure"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    closeTaskDialog() {
      this.taskDialogVisible = false;
    },
    setUserOptions() {
      CompaniesRepository.getCompanyUsersForTasks(this.companyId).then(
        (data) => {
          this.userOptions = data.data.map((user) => ({
            id: parseInt(user.id),
            firstName: user.attributes.firstName,
            lastName: user.attributes.lastName,
          }));
        }
      );
    },
  },
  created() {
    this.fetchStudy();
    if (
      this.accountConfiguration.cmpPeriod &&
      this.accountConfiguration.cmpPeriod !== ""
    )
      this.comparisonPeriodDates = this.setComparisonPeriod();
    this.setUserOptions();
  },
};
</script>

<style scoped lang="scss">
.kpi {
  display: inline-block;
  font-size: 1.25rem;
  margin-top: 1rem;
}

.positiv {
  color: green;
}

.negativ {
  color: red;
}

.chart {
  margin-top: 1rem;
}

.el-card {
  margin-bottom: 1rem;
}

.metrics {
  margin-top: 1rem;

  .el-col {
    display: flex;
    flex-direction: column;
  }

  .el-card {
    display: flex;
    flex-grow: 1;
    text-align: center;

    ::v-deep .el-card__body {
      width: 100%;
    }
  }
}

// .top-movements-grids {
// 	page-break-before: always;
// }

.date-period {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.page-break {
  page-break-after: always;
}

.page-breaker-before {
  page-break-before: always;
}

@media print {
  .no-print {
    display: none;
  }

  .date-period {
    margin-left: 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .no-border-on-print {
    border-style: none;
  }

  .top-movements-grid {
    padding-top: 4rem;
  }

  // Remove float so that we can page break between cols
  // [class*="el-col-"] {
  // 	float: none !important;
  // }
}
</style>

<i18n>
{
  "en": {
    "analyseDetail": "Detailed Analysis",
    "positions": "Positions",
    "to": "to",
    "taskCreatedSuccess": "Task created successfully",
    "taskCreatedFailure": "Task creation failed",
    "workOnKeyword": "Work on keyword: %{keywordText}"
  },
  "fr": {
    "analyseDetail": "Analyser en détail",
    "positions": "Positions",
    "to": "au",
    "taskCreatedSuccess": "Tâche créée avec succès",
    "taskCreatedFailure": "La création de la tâche a échoué",
    "workOnKeyword": "Travailler le mot clé : %{keywordText}"
  },
  "de": {
    "analyseDetail": "Analysieren Sie im Detail",
    "positions": "Positionen",
    "to": "Bei",
    "taskCreatedSuccess": "Aufgabe erfolgreich erstellt",
    "taskCreatedFailure": "Aufgabenerstellung fehlgeschlagen",
    "workOnKeyword": "Bearbeiten Sie das Schlüsselwort: %{keywordText}"
  }
}
</i18n>
