<template>
  <el-form-item :label="$t('max_rank')">
    <el-tooltip
      class="item"
      effect="dark"
      :content="$t('max_rank_explanation')"
      placement="top"
    >
      <i class="fas fa-question-circle"></i>
    </el-tooltip>
    <el-input-number
      v-model="maxRank"
      :placeholder="$t('enter_max_rank')"
      controls-position="right"
    ></el-input-number>
  </el-form-item>
</template>

<script>
export default {
  props: {
    value: Number,
  },
  computed: {
    maxRank: {
      get() {
        return this.value;
      },
      set(maxRank) {
        this.$emit("input", maxRank);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.fa-question-circle {
  margin-left: -10px;
}
</style>

<i18n>
{
  "en": {
    "max_rank": "Maximum rank",
    "enter_max_rank": "Enter maximum rank",
    "max_rank_explanation": "Highest position the url is positionned"
  },
  "fr": {
    "max_rank": "Rang maximum",
    "enter_max_rank": "Entrez le rang maximum",
    "max_rank_explanation": "Position maximum sur laquelle l’url est positionnée"
  },
  "de": {
    "max_rank": "Hat maximal geklingelt",
    "enter_max_rank": "Geben Sie den maximalen Rang ein",
    "max_rank_explanation": "Maximale Position, auf der die URL positioniert ist"
  }
}
</i18n>
