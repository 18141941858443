import Repository from "@/services/repository";

export default {
  createTagsAddition(studyId, keywordIds, tags) {
    return Repository.post(`/a/${studyId}/keywords/add_tags.json`, {
      keywordIds,
      tags,
    }).then((response) => response.data);
  },
};
