<template>
  <div v-if="contentId">
    <app-introduction
      v-if="content"
      :title="$t('Content') + ': ' + content.data.keyword.text"
    ></app-introduction>
    <div class="container">
      <el-card>
        <content-text
          v-if="content"
          :is-stand-alone="false"
          :isPublic="false"
          :is-loading-text="isLoadingText"
          :data="content"
          @update-text="updateText($event)"
          @update-content="updateContent($event)"
        >
          <template v-slot:title>
            <div
              class="title"
              v-if="content"
            >
              <h2>
                <span style="font-weight: bold">{{ $t("textRedaction") }}</span>
                - {{ content.data.keyword.text }} - {{ content.data.name }}
              </h2>
              <el-button
                type="primary"
                plain
                style="margin-left: auto"
                @click="openBriefPage()"
                >{{ $t("openBrief") }}</el-button
              >
            </div>
          </template>
        </content-text>
      </el-card>
    </div>
  </div>
</template>

<script>
import AppIntroduction from "@/components/AppIntroduction";
import ContentText from "@/components/CampaignManager/ContentText";

export default {
  components: {
    AppIntroduction,
    ContentText,
  },
  data() {
    return {
      content: null,
      isLoadingText: false,
    };
  },
  props: {
    studyId: String,
    contentId: String,
  },
  methods: {
    fetchContent() {
      if (this.contentId) {
        this.$api["get"](
          "https://" + process.env.API_HOST + "/contents/" + this.contentId
        )
          .then((response) => {
            this.content = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    updateText(details) {
      this.isLoadingText = true;
      this.$api[details.link.method.toLowerCase()](
        "https://" + process.env.API_HOST + details.link.href,
        details.entity
      )
        .then((response) => {
          this.content.data.text = { ...response.data.data };
          if (details.link.method.toLowerCase() == "put") {
            this.$message({
              message: this.$i18n.t("textSaved"),
              type: "success",
              duration: 6000,
            });
          }
          this.isLoadingText = false;
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.error(error);
        });
    },
    updateContent(details) {
      this.isLoadingText = true;
      this.$api[details.link.method.toLowerCase()](
        "https://" + process.env.API_HOST + details.link.href,
        details.entity
      )
        .then((response) => {
          this.content = response.data;
          this.isLoadingText = false;
          this.$message({
            message: this.$i18n.t("contentUpdated"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoadingText = false;
        });
    },
    openBriefPage() {
      window.open(
        window.location.origin +
          "/a/" +
          this.studyId +
          "/contents/" +
          this.contentId +
          "/brief"
      );
    },
  },
  mounted() {
    this.fetchContent();
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
</style>
<i18n>
{
  "en": {
    "Content": "Content",
    "textRedaction": "Text redaction",
    "briefText": "Content text",
    "validate": "Validate the brief",
    "textSaved": "Text has been saved",
    "contentUpdated": "Content updated",
    "openBrief": "See the brief"
  },
  "fr": {
    "Content": "Contenu",
    "textRedaction": "Rédaction de contenu",
    "briefText": "Texte du contenu",
    "validate": "Valider le brief",
    "textSaved": "Texte sauvegardé",
    "contentUpdated": "Contenu mis à jour",
    "openBrief": "Ouvrir le brief"
  },
  "de": {
    "Content": "Inhalt",
    "textRedaction": "Inhalt schreiben",
    "briefText": "Inhaltstext",
    "validate": "Bestätigen Sie den Auftrag",
    "textSaved": "Gespeicherter Text",
    "contentUpdated": "Inhalt aktualisiert",
    "openBrief": "Brief öffnen"
  }
}
</i18n>
