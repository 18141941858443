<template>
  <el-form>
    <el-form-item :label="$t('group_by')">
      <el-radio-group
        :value="value"
        @input="(event) => $emit('input', event)"
      >
        <el-radio-button
          v-for="dimension in dimensionOptions"
          :key="dimension.value"
          :label="dimension.value"
          >{{ dimension.label }}</el-radio-button
        >
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    value: String,
    dimensionsData: Array,
  },
  computed: {
    dimensionOptions() {
      // Options for filters type and groupings
      let options = this.dimensionsData.map((dimension) => {
        if (!dimension.name.includes("Dimension")) {
          return {
            label: this.$i18n.t(dimension.name.toLowerCase()),
            value: dimension.name,
          };
        } else {
          return {
            label: dimension.display_name,
            value: dimension.name,
          };
        }
      });
      options.unshift({
        label: this.$i18n.t("none"),
        value: null,
      });

      return options;
    },
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "country": "Country",
    "device": "Device",
    "brand": "Brand",
    "group_by": "Group by",
    "none": "None"
  },
  "fr": {
    "country": "Pays",
    "device": "Appareil",
    "brand": "Marque",
    "group_by": "Grouper par",
    "none": "Aucun"
  },
  "de": {
    "country": "Zahlt",
    "device": "Gerät",
    "brand": "Marke",
    "group_by": "Gruppiere nach",
    "none": "Keiner"
  }
}
</i18n>
