<template>
  <div>
    <div v-if="data">
      <div class="progress-dashboard-container">
        <div>
          <el-progress
            class="print-background"
            type="dashboard"
            :percentage="totalPercentage"
            :color="colors"
            :width="250"
            :format="customText(totalPercentage)"
          >
          </el-progress>
        </div>
        <div class="percentage-info">
          <el-tag type="danger"><i class="el-icon-error"></i> 0-49 </el-tag>
          <el-tag type="warning"><i class="el-icon-warning"></i> 50-89 </el-tag>
          <el-tag type="success"
            ><i class="el-icon-success"></i> 90-100
          </el-tag>
        </div>
        <div
          class="pass"
          v-if="totalPercentage >= 90"
        >
          <p>
            <span class="success">{{ $t("pass") }}</span> {{ $t("assessment") }}
          </p>
        </div>
        <div v-else>
          <p>
            <span class="error">{{ $t("notPass") }}</span>
            {{ $t("assessment") }}
          </p>
        </div>
      </div>
      <el-divider />
      <div class="metrics-container">
        <div class="block">
          <div class="sub-container">
            <i :class="getCssClass(fcp.status)"></i>
            <div>{{ fcp.name }}</div>
            <div>{{ fcp.time }} s</div>
          </div>
          <el-progress
            class="print-background"
            :text-inside="true"
            :stroke-width="15"
            :percentage="fcp.percentage"
            :status="fcp.status"
          >
          </el-progress>
        </div>

        <div class="block">
          <div class="sub-container">
            <i :class="getCssClass(fid.status)"></i>
            <div>{{ fid.name }}</div>
            <div>{{ fid.time }} ms</div>
          </div>
          <el-progress
            class="print-background"
            :text-inside="true"
            :stroke-width="15"
            :percentage="fid.percentage"
            :status="fid.status"
          >
          </el-progress>
        </div>

        <div class="block">
          <div class="sub-container">
            <i :class="getCssClass(lcp.status)"></i>
            <div>{{ lcp.name }}</div>
            <div>{{ lcp.time }} s</div>
          </div>
          <el-progress
            class="print-background"
            :text-inside="true"
            :stroke-width="15"
            :percentage="lcp.percentage"
            :status="lcp.status"
          >
          </el-progress>
        </div>

        <div class="block">
          <div class="sub-container">
            <i :class="getCssClass(cls.status)"></i>
            <div>{{ cls.name }}</div>
            <div>{{ cls.time }}</div>
          </div>
          <el-progress
            class="print-background"
            :text-inside="true"
            :stroke-width="15"
            :percentage="cls.percentage"
            :status="cls.status"
          >
          </el-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base64url from "base64url";

export default {
  props: {
    url: String,
  },
  data() {
    return {
      data: null,
      cls: {
        name: null,
        percentage: null,
        time: null,
        status: null,
      },
      fcp: {
        name: null,
        percentage: null,
        time: null,
        status: null,
      },
      fid: {
        name: null,
        percentage: null,
        time: null,
        status: null,
      },
      lcp: {
        name: null,
        percentage: null,
        time: null,
        status: null,
      },
      totalPercentage: 0,
      colors: [
        { color: "#f56c6c", percentage: 50 },
        { color: "#e6a23c", percentage: 90 },
        { color: "#67c23a", percentage: 100 },
      ],
    };
  },
  methods: {
    getSpeedMetrics() {
      const encodedUrl = base64url(this.url);

      this.$api.get(`/pages/url/${encodedUrl}`).then((response) => {
        this.data = response.data.meta.speedMetrics;
        this.getMetrics();
        this.getTotalPercentage();
      });
    },
    getTotalPercentage() {
      this.totalPercentage = Math.round(
        (this.cls.percentage +
          this.fcp.percentage +
          this.fid.percentage +
          this.lcp.percentage) /
          4
      );
    },
    getMetrics() {
      this.cls.name = this.getName(this.data[0].name);
      this.cls.percentage = this.getPercentage(this.data[0]);
      this.cls.time = this.data[0].value / 100;
      this.cls.status = this.getStatus(this.data[0].category);

      this.fcp.name = this.getName(this.data[1].name);
      this.fcp.percentage = this.getPercentage(this.data[1]);
      this.fcp.time = (this.data[1].value / 1000).toFixed(1);
      this.fcp.status = this.getStatus(this.data[1].category);

      this.fid.name = this.getName(this.data[2].name);
      this.fid.percentage = this.getPercentage(this.data[2]);
      this.fid.time = this.data[2].value;
      this.fid.status = this.getStatus(this.data[2].category);

      this.lcp.name = this.getName(this.data[3].name);
      this.lcp.percentage = this.getPercentage(this.data[3]);
      this.lcp.time = (this.data[3].value / 1000).toFixed(1);
      this.lcp.status = this.getStatus(this.data[3].category);
    },
    getName(name) {
      switch (name) {
        case "CLS":
          return "Cumulative Layout Shift (CLS)";
        case "FCP":
          return "First Contentful Paint (FCP)";
        case "FID":
          return "First Input Delay (FID)";
        case "LCP":
          return "Largest Contentful Paint (LCP)";
        default:
          return "";
      }
    },
    getPercentage(metric) {
      return Math.round(((metric.max - metric.value) / metric.max) * 100);
    },
    getStatus(category) {
      switch (category) {
        case "SLOW":
          return "exception";
        case "AVERAGE":
          return "warning";
        case "FAST":
          return "success";
        default:
          return "exception";
      }
    },
    getCssClass(status) {
      switch (status) {
        case "exception":
          return "el-icon-error error";
        case "warning":
          return "el-icon-warning warning";
        case "success":
          return "el-icon-success success";
        default:
          return "exception";
      }
    },
    customText(percentage) {
      return () => {
        return percentage;
      };
    },
  },
  mounted() {
    this.getSpeedMetrics();
  },
};
</script>
<style scoped lang="scss">
.metrics-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3%;
}

.block {
  width: 45%;
  margin-left: 5%;
}

.sub-container {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}

.progress-dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 20px;
  margin-top: 15%;
}

.success {
  color: #67c23a;
}
.warning {
  color: #e6a23c;
}
.error {
  color: #f56c6c;
}

.el-progress {
  line-height: inherit;
}

@media print {
  .print-background {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>

<i18n>
{
  "en": {
    "pass": "Pass",
    "notPass": "Does not pass",
    "assessment": " the assessment test."
  },
  "fr": {
    "pass": "Passe",
    "notPass": "Ne passe pas ",
    "assessment": " le test d'évaluation."
  },
  "de": {
    "pass": "Passieren",
    "notPass": "Durchfahrt verboten",
    "assessment": "die Feststellungsprüfung."
  }
}
</i18n>
