<template>
  <div>
    <app-introduction :title="$t('briefText')"></app-introduction>
    <div class="container">
      <el-card>
        <Brief
          v-if="brief"
          :is-stand-alone="true"
          :is-loading-brief="isLoadingBrief"
          :brief="brief"
          :content="content"
        >
          <template v-slot:title>
            <div class="title">
              <span style="font-weight: bold">{{ $t("briefText") }}</span> -
              {{ content.data.keyword.text }}
            </div>
          </template>
        </Brief>
      </el-card>
    </div>
  </div>
</template>

<script>
import Brief from "@/components/CampaignManager/Brief";
import axios from "axios";

import AppIntroduction from "@/components/AppIntroduction";

export default {
  components: {
    AppIntroduction,
    Brief,
  },
  data() {
    return {
      content: null,
      brief: null,
      isLoadingBrief: false,
    };
  },
  methods: {
    fetchContent() {
      this.isLoadingBrief = true;
      const url = window.location.href.split("/");
      const contentId = url[url.length - 2];
      if (contentId) {
        axios
          .get(
            "https://" + process.env.API_HOST + "/contents/public/" + contentId
          )
          .then((response) => {
            this.content = response.data;

            if (this.content.links.brief && this.content.links.brief.read) {
              const link = this.content.links.brief.read;
              axios[link.method.toLowerCase()](
                "https://" + process.env.API_HOST + link.href
              ).then((response) => {
                this.isLoadingBrief = false;
                this.brief = response.data;
              });
            }
          });
      }
    },
  },
  mounted() {
    this.fetchContent();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
</style>

<i18n>
{
  "en": {
    "briefRedaction": "Brief redaction",
    "briefText": "Brief",
    "validate": "Validate the brief",
    "briefSaved": "Brief has been saved"
  },
  "fr": {
    "briefRedaction": "Rédaction de brief",
    "briefText": "Brief",
    "validate": "Valider le brief",
    "briefSaved": "Brief sauvegardé"
  },
  "de": {
    "briefRedaction": "Kurzes Schreiben",
    "briefText": "Knapp",
    "validate": "Bestätigen Sie den Auftrag",
    "briefSaved": "Kurz gespeichert"
  }
}
</i18n>
