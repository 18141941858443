<template>
  <div class="form">
    <el-form
      :inline="true"
      @submit.native.prevent
    >
      <el-form-item :label="$t('country')">
        <select-search-engines
          v-model="isoCountryCode"
          class="select"
          :countries="countries"
        >
        </select-search-engines>
      </el-form-item>
      <el-form-item label="Type">
        <el-select
          v-model="typeValue"
          class="select"
        >
          <el-option
            v-for="type in typeOptions"
            :key="type.value"
            :label="type.label"
            :value="type.value"
            :title="type.title"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Url">
        <el-input
          v-model="urlValue"
          class="url"
          @keyup.enter.native="submit"
        ></el-input>
      </el-form-item>
      <el-button @click="submit">
        {{ $t("search") }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import SelectSearchEngines from "@/components/formItems/SelectSearchEngines";

export default {
  components: {
    SelectSearchEngines,
  },
  props: {
    countries: Array,
    defaultIsoCountryCode: String,
  },
  computed: {
    typeOptions() {
      const options = [
        {
          value: "DOMAIN",
          label: this.$i18n.t("domain"),
          title: this.$i18n.t("domainExplication"),
        },
      ];
      options.push({
        value: "SUBDOMAIN",
        label: this.$i18n.t("subdomain"),
        title: this.$i18n.t("subDomainExplication"),
      });
      options.push({
        value: "FOLDER",
        label: this.$i18n.t("folder"),
        title: this.$i18n.t("folderExplication"),
      });
      options.push({
        value: "URL",
        label: this.$i18n.t("url"),
        title: this.$i18n.t("urlExplication"),
      });
      return options;
    },
  },
  data() {
    return {
      typeValue: "DOMAIN",
      urlValue: "",
      prefix: "https://",
      isoCountryCode: this.defaultIsoCountryCode,
    };
  },
  methods: {
    submit() {
      if (this.urlValue.length > 0) {
        let input;
        if (
          !this.urlValue.includes("https://") &&
          !this.urlValue.includes("http://")
        ) {
          input = this.prefix + this.urlValue;
        } else {
          input = this.urlValue;
        }

        if (this.checkIfInputIsUrl(input)) {
          const request = {
            url: {
              url: input,
              type: this.typeValue,
            },
            isoCountryCode: this.isoCountryCode,
          };
          this.$emit("submit", request);
          return;
        }
      }
      this.$message({
        message: this.$i18n.t("bad_url"),
        type: "error",
        duration: 6000,
        offset: 80,
      });
    },
    checkIfInputIsUrl(input) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(input);
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  .select {
    width: 115px;
  }

  .url {
    width: 500px;
  }
}
</style>

<i18n>
{
  "en": {
    "bad_url": "The url you typed is not in the correct format",
    "country": "Country",
    "domain": "Domain",
    "domainExplication": "Search for data associated with an entire domain (eg, example.com)",
    "folder": "Folder",
    "folderExplication": "A generic search to find URLs that start with your search term (for example http://example.com/directory/*)",
    "search": "Search",
    "subdomain": "Subdomain",
    "subDomainExplication": "Search for data associated with a subdomain (eg, blog.example.com)",
    "url": "Url",
    "urlExplication": "Search for data associated with a single URL (eg http://example.com/index.html)"
  },
  "fr": {
    "bad_url": "L'url que vous avez rentrée n'est pas au bon format",
    "country": "Pays",
    "domain": "Domaine",
    "domainExplication": "Rechercher des données associées à un domaine entier (par exemple, exemple.com)",
    "folder": "Dossier",
    "folderExplication": "Une recherche générique pour trouver des URL qui commencent par votre terme de recherche (par exemple http://exemple.com/répertoire/*)",
    "search": "Rechercher",
    "subdomain": "Sous-domaine",
    "subDomainExplication": "Rechercher des données associées à un sous-domaine (par exemple, blog.exemple.com)",
    "url": "Url",
    "urlExplication": "Rechercher des données associées à une seule URL (par exemple http://exemple.com/index.html)"
  },
  "de": {
    "bad_url": "Die eingegebene URL hat nicht das richtige Format.",
    "country": "Zahlt",
    "domain": "Domain",
    "domainExplication": "Suchen Sie nach Daten, die einer ganzen Domain zugeordnet sind (z. B. example.com)",
    "folder": "Fall",
    "folderExplication": "Eine generische Suche, um URLs zu finden, die mit Ihrem Suchbegriff beginnen (z. B. http://example.com/directory/*)",
    "search": "Forschen",
    "subdomain": "Subdomäne",
    "subDomainExplication": "Suchen Sie nach Daten, die einer Subdomain zugeordnet sind (z. B. blog.example.com)",
    "url": "URL",
    "urlExplication": "Suchen Sie nach Daten, die mit einer einzelnen URL verknüpft sind (z. B. http://example.com/index.html)"
  }
}
</i18n>
