<template>
  <el-card>
    <h2>{{ $t("managers") }}</h2>
    <div class="actions">
      <div class="text-right">
        <el-button
          plain
          type="primary"
          @click="dialogInviteManagerVisible = true"
          >{{ $t("invite_manager") }}</el-button
        >
      </div>
      <el-dialog
        :title="$t('invite_manager')"
        :visible.sync="dialogInviteManagerVisible"
      >
        <manager-form
          @submit="inviteManager($event)"
          @close-dialog="dialogInviteManagerVisible = false"
        ></manager-form>
      </el-dialog>
    </div>
    <div class="grid">
      <ag-grid-vue
        :domLayout="'autoHeight'"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :resizable="true"
        :filter="true"
        :localeTextFunc="localeTextFunc"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        @gridReady="onGridReady"
        @firstDataRendered="adjustGrid"
        @rowDataChanged="adjustGrid"
        :context="context"
      >
      </ag-grid-vue>
    </div>
  </el-card>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompanyGroupsRepository = RepositoryFactory.get("companyGroups");
const UsersRepository = RepositoryFactory.get("users");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import ManagerForm from "@/pages/CompanyGroup/Forms/ManagerForm";

const EmailComponent = Vue.extend({
  template: `
		<div>
			{{ params.value }}
			<el-tooltip effect="dark" :content="$t('tooltip_content')" placement="right">
				<i class="fas fa-envelope" v-if="params.data.isInvitationPending"></i>
			</el-tooltip>
		</div>
	`,
  i18n: {
    messages: {
      en: {
        tooltip_content: "Invitation pending",
      },
      fr: {
        tooltip_content: "Invitation en attente",
      },
    },
  },
});

const ActionsComponent = Vue.extend({
  template: `
		<div>
			<a :href="'/u/' + userId + '/edit'"><el-button size="mini" plain icon="fas fa-user-edit"></el-button></a>
			<el-button size="mini" type="danger" plain icon="fas fa-trash-alt" @click="invokeDeleteUser"></el-button>
		</div>
	`,
  data: function () {
    return {
      userId: null,
    };
  },
  mounted() {
    this.userId = this.params.data.id;
  },
  methods: {
    invokeDeleteUser() {
      this.params.context.managers.deleteUser(this.userId);
    },
  },
});

export default {
  mixins: [agGridMixin],
  props: {
    companyGroupId: Number,
  },
  components: {
    AgGridVue,
    ManagerForm,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      dialogInviteManagerVisible: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.defaultColDef = {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      };
      this.columnDefs = [
        {
          headerName: this.$i18n.t("email"),
          field: "email",
          minWidth: 300,
          filter: "agTextColumnFilter",
          cellRendererFramework: EmailComponent,
        },
        {
          headerName: this.$i18n.t("first_name"),
          field: "firstName",
          minWidth: 300,
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("last_name"),
          field: "lastName",
          minWidth: 300,
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("actions"),
          minWidth: 130,
          maxWidth: 130,
          cellRendererFramework: ActionsComponent,
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ];
    },
    fetchData() {
      CompanyGroupsRepository.getCompanyGroupManagers(this.companyGroupId)
        .then((data) => {
          this.rowData = data.data.map((user) => ({
            id: user.id,
            ...user.attributes,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(userId) {
      if (confirm(this.$i18n.t("deletion_confirmation"))) {
        UsersRepository.deleteUser(userId)
          .then((response) => {
            this.$message.success(this.$i18n.t("delete_user_success"));
            this.fetchData();
            setTimeout(() => {
              this.$emit("refresh");
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    inviteManager(form) {
      CompanyGroupsRepository.createManager(this.companyGroupId, form)
        .then((response) => {
          this.$message.success(this.$i18n.t("invite_manager_success"));
          this.fetchData();
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
            type: "error",
          });
        });
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.fetchData();
    this.context = { managers: this };
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.actions {
  margin-bottom: 1rem;
}

.grid {
  ::v-deep a + .el-button {
    margin-left: 10px;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "managers": "Administrators",
    "email": "Email",
    "first_name": "First name",
    "last_name": "Last name",
    "phone": "Phone",
    "actions": "Actions",
    "deletion_confirmation": "Are you sure you want to delete this user ?",
    "delete_user_success": "User was successfully deleted.",
    "invite_manager": "Invite administrators",
    "invite_owner_success": "An email has been sent to invite the manager to join your company group."
  },
  "fr": {
    "managers": "Gestionnaires",
    "email": "Email",
    "first_name": "Prénom",
    "last_name": "Nom",
    "phone": "Téléphone",
    "actions": "Actions",
    "deletion_confirmation": "Etes-vous sûr de vouloir supprimer cet utilisateur ?",
    "delete_user_success": "L'utilisateur a été supprimé avec succès.",
    "invite_manager": "Inviter un gestionnaire",
    "invite_manager_success": "Un email a été envoyé pour inviter le gestionnaire à rejoindre votre groupe de sociétés."
  },
  "de": {
    "managers": "Manager",
    "email": "Email",
    "first_name": "Vorname",
    "last_name": "Name",
    "phone": "Telefon",
    "actions": "Aktionen",
    "deletion_confirmation": "Möchten Sie diesen Benutzer wirklich löschen?",
    "delete_user_success": "Der Benutzer wurde erfolgreich gelöscht.",
    "invite_manager": "Laden Sie einen Manager ein",
    "invite_manager_success": "Eine E-Mail wurde gesendet, um den Manager einzuladen, Ihrer Unternehmensgruppe beizutreten."
  }
}
</i18n>
