var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.meta)?_c('div',[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"container"},[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between"}},[_c('el-card',{staticClass:"mr-4",staticStyle:{"flex":"1"},attrs:{"body-style":{ height: '100%' }}},[_c('div',{staticClass:"h-100 w-100 d-flex flex-column justify-content-between align-items-center"},[_c('h5',[_vm._v(_vm._s(_vm.$t("budget_delivred")))]),_vm._v(" "),_c('div',[_c('div',{class:_vm.colorCampaignStatus},[_c('h1',{staticClass:"mt-3"},[_vm._v("\n                  "+_vm._s(_vm.deliveredBudgetStats.percentageOfDeliveredBudget)+"\n                ")]),_vm._v(" "),_c('h4',[_vm._v("\n                  "+_vm._s(_vm.$t("deliveredBudget", {
                      value: _vm.currencyFormat(
                        _vm.deliveredBudgetStats.deliveredBudget
                      ),
                    }))+"\n                ")])]),_vm._v(" "),(_vm.deliveredBudgetStats.numberOfPublishedBacklinks)?_c('div',[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("correspondTo")))])]),_vm._v(" "),_c('ul',{staticClass:"text-left mb-0"},[_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.deliveredBudgetStats.numberOfPublishedBacklinks
                            .action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.deliveredBudgetStats.numberOfPublishedBacklinks.value)+"\n                      "+_vm._s(_vm.$t("publishedBacklinks")))])],1)])]):_vm._e(),_vm._v(" "),(
                  _vm.remaingingBudgetStats.numberOfRefusedDomains ||
                  _vm.remaingingBudgetStats.numberOfCanceledBacklinks
                )?_c('div',{staticStyle:{"visibility":"hidden"}},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("correspondTo")))])]),_vm._v(" "),_c('ul',{staticClass:"text-left mb-0"},[(_vm.remaingingBudgetStats.numberOfRefusedDomains)?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.remaingingBudgetStats.numberOfRefusedDomains.action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.remaingingBudgetStats.numberOfRefusedDomains.value)+"\n                      "+_vm._s(_vm.$t("refusedDomains")))])],1):_vm._e(),_vm._v(" "),(_vm.remaingingBudgetStats.numberOfCanceledBacklinks)?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.remaingingBudgetStats.numberOfCanceledBacklinks
                            .action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.remaingingBudgetStats.numberOfCanceledBacklinks.value)+"\n                      "+_vm._s(_vm.$t("canceledBacklinks")))])],1):_vm._e()])]):_vm._e()]),_vm._v(" "),_c('div')])]),_vm._v(" "),_c('el-card',{staticClass:"mr-4",staticStyle:{"flex":"2"},attrs:{"body-style":{ height: '100%' }}},[_c('h5',{staticClass:"px-3 mb-3"},[_vm._v(_vm._s(_vm.$t("budget_in_process")))]),_vm._v(" "),_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between"}},[_c('div',{staticStyle:{"flex":"1"},attrs:{"body-style":{ height: '100%' }}},[_c('div',{staticClass:"h-100 w-100 d-flex flex-column align-items-center"},[_c('div',[_c('h5',[_vm._v("\n                    "+_vm._s(_vm.$t("at"))+" "),_c('b',[_vm._v(_vm._s(_vm.study.companyName))])]),_vm._v(" "),_c('h2',[_vm._v("\n                    "+_vm._s(_vm.clientPendingBudgetStats.clientPendingBudgetPercentage)+"\n                  ")]),_vm._v(" "),_c('p',[_vm._v("\n                    "+_vm._s(_vm.$t("totalClientBudget", {
                        clientPendingBudget: _vm.currencyFormat(
                          _vm.clientPendingBudgetStats.clientPendingBudget
                        ),
                      }))+"\n                  ")])]),_vm._v(" "),(
                    _vm.clientPendingBudgetStats.numberOfDomainsToValidate ||
                    _vm.clientPendingBudgetStats.numberOfArticlesToValidate
                  )?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("correspondTo")))])]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"text-left mb-0"},[(_vm.clientPendingBudgetStats.numberOfDomainsToValidate)?_c('li',{on:{"click":function($event){return _vm.changeFilter(
                        _vm.clientPendingBudgetStats.numberOfDomainsToValidate
                          .action
                      )}}},[_c('el-button',{attrs:{"size":"small","type":"text"}},[_vm._v("\n                      "+_vm._s(_vm.clientPendingBudgetStats.numberOfDomainsToValidate
                          .value)+"\n                      "+_vm._s(_vm.$t("domainsToValidate")))])],1):_vm._e(),_vm._v(" "),(_vm.clientPendingBudgetStats.numberOfArticlesToValidate)?_c('li',{on:{"click":function($event){return _vm.changeFilter(
                        _vm.clientPendingBudgetStats.numberOfArticlesToValidate
                          .action
                      )}}},[_c('el-button',{attrs:{"size":"small","type":"text"}},[_vm._v("\n                      "+_vm._s(_vm.clientPendingBudgetStats.numberOfArticlesToValidate
                          .value)+"\n                      "+_vm._s(_vm.$t("articlesToValidate")))])],1):_vm._e()])])]),_vm._v(" "),_c('div',{staticStyle:{"flex":"1"},attrs:{"body-style":{ height: '100%' }}},[_c('div',{staticClass:"h-100 w-100 d-flex flex-column align-items-center"},[_c('div',[_c('h5',[_vm._v(_vm._s(_vm.$t("at"))+" "),_c('b',[_vm._v("Smartkeyword")])]),_vm._v(" "),_c('h2',[_vm._v("\n                    "+_vm._s(_vm.smartkeywordPendingBudgetStats.smartkeywordPendingBudgetPercentage)+"\n                  ")]),_vm._v(" "),_c('p',[_vm._v("\n                    "+_vm._s(_vm.$t("smartkeywordBudgetStats", {
                        smartkeywordPendingBudget: _vm.currencyFormat(
                          _vm.smartkeywordPendingBudgetStats.smartkeywordPendingBudget
                        ),
                      }))+"\n                  ")])]),_vm._v(" "),(
                    _vm.smartkeywordPendingBudgetStats.numberOfArticlesToReview ||
                    _vm.smartkeywordPendingBudgetStats.numberOfArticlesToModify ||
                    _vm.smartkeywordPendingBudgetStats.numberOfArticlesToPublish ||
                    _vm.smartkeywordPendingBudgetStats.numberOfDomainsToBuy ||
                    _vm.smartkeywordPendingBudgetStats.numberOfArticlesWaitingToPublish
                  )?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("correspondTo")))])]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"text-left mb-0"},[(_vm.smartkeywordPendingBudgetStats.numberOfDomainsToBuy)?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.smartkeywordPendingBudgetStats.numberOfDomainsToBuy
                            .action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.smartkeywordPendingBudgetStats.numberOfDomainsToBuy
                          .value)+"\n                      "+_vm._s(_vm.$t("domainsToBuy")))])],1):_vm._e(),_vm._v(" "),(
                      _vm.smartkeywordPendingBudgetStats.numberOfArticlesToReview
                    )?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.smartkeywordPendingBudgetStats
                            .numberOfArticlesToReview.action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.smartkeywordPendingBudgetStats
                          .numberOfArticlesToReview.value)+"\n                      "+_vm._s(_vm.$t("articlesToReview")))])],1):_vm._e(),_vm._v(" "),(
                      _vm.smartkeywordPendingBudgetStats.numberOfArticlesToModify
                    )?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.smartkeywordPendingBudgetStats
                            .numberOfArticlesToModify.action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.smartkeywordPendingBudgetStats
                          .numberOfArticlesToModify.value)+"\n                      "+_vm._s(_vm.$t("articlesToModify")))])],1):_vm._e(),_vm._v(" "),(
                      _vm.smartkeywordPendingBudgetStats.numberOfArticlesWaitingToPublish
                    )?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.smartkeywordPendingBudgetStats
                            .numberOfArticlesWaitingToPublish.action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.smartkeywordPendingBudgetStats
                          .numberOfArticlesWaitingToPublish.value)+"\n                      "+_vm._s(_vm.$t("articlesWaintingToPublish")))])],1):_vm._e(),_vm._v(" "),(
                      _vm.smartkeywordPendingBudgetStats.numberOfArticlesToPublish
                    )?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.smartkeywordPendingBudgetStats
                            .numberOfArticlesToPublish.action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.smartkeywordPendingBudgetStats
                          .numberOfArticlesToPublish.value)+"\n                      "+_vm._s(_vm.$t("articlesToPublish")))])],1):_vm._e()])])])])]),_vm._v(" "),_c('el-card',{staticStyle:{"flex":"1"},attrs:{"body-style":{ height: '100%' }}},[_c('div',{staticClass:"h-100 w-100 d-flex justify-content-between flex-column align-items-center"},[_c('h5',[_vm._v(_vm._s(_vm.$t("budget_remaining")))]),_vm._v(" "),_c('div',[_c('h1',{staticClass:"mt-3"},[_vm._v("\n                "+_vm._s(_vm.remaingingBudgetStats.percentageOfRemaingingBudget)+"\n              ")]),_vm._v(" "),_c('h4',[_vm._v("\n                "+_vm._s(_vm.$t("remaingingBudgetStatsRemainingBudget", {
                    remainingBudget: _vm.currencyFormat(
                      _vm.remaingingBudgetStats.remainingBudget
                    ),
                  }))+"\n              ")]),_vm._v(" "),(
                  _vm.remaingingBudgetStats.numberOfRefusedDomains ||
                  _vm.remaingingBudgetStats.numberOfCanceledBacklinks
                )?_c('div',[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("including")))])]),_vm._v(" "),_c('ul',{staticClass:"text-left mb-0"},[(_vm.remaingingBudgetStats.numberOfRefusedDomains)?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.remaingingBudgetStats.numberOfRefusedDomains.action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.remaingingBudgetStats.numberOfRefusedDomains.value)+"\n                      "+_vm._s(_vm.$t("refusedDomains")))])],1):_vm._e(),_vm._v(" "),(_vm.remaingingBudgetStats.numberOfCanceledBacklinks)?_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.remaingingBudgetStats.numberOfCanceledBacklinks
                            .action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.remaingingBudgetStats.numberOfCanceledBacklinks.value)+"\n                      "+_vm._s(_vm.$t("canceledBacklinks")))])],1):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.deliveredBudgetStats.numberOfPublishedBacklinks)?_c('div',{staticStyle:{"visibility":"hidden"}},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("correspondTo")))])]),_vm._v(" "),_c('ul',{staticClass:"text-left mb-0"},[_c('li',[_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeFilter(
                          _vm.deliveredBudgetStats.numberOfPublishedBacklinks
                            .action
                        )}}},[_vm._v("\n                      "+_vm._s(_vm.deliveredBudgetStats.numberOfPublishedBacklinks.value)+"\n                      "+_vm._s(_vm.$t("publishedBacklinks")))])],1)])]):_vm._e()]),_vm._v(" "),_c('div')])])],1)])]),_vm._v(" "),_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('h5',[_vm._v(_vm._s(_vm.$t("projectStatus")))])]),_vm._v(" "),_c('div',{staticClass:"col-10"},[_c('h4',{class:_vm.colorCampaignStatus},[_vm._v(_vm._s(_vm.netlinkingCampaignStatus))])])]),_vm._v(" "),(!_vm.campaign.isTerminated)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('h5',[_vm._v(_vm._s(_vm.$t("explanations")))])]),_vm._v(" "),_c('div',{staticClass:"col-10"},[(_vm.isOnTrack)?_c('p',[_vm._v("\n            "+_vm._s(_vm.$t("onTrackExplications", {
                theoreticalAdvancement: _vm.theoreticalAdvancement,
              }))+"\n          ")]):_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("onDelayExplicationsFirstPart", {
                  percentageOfDelay: _vm.percentageOfDelay,
                }))+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t("onDelayExplications", {
                theoreticalAdvancement: _vm.theoreticalAdvancement,
                theoreticalBudgetToSpend: _vm.currencyFormat(
                  _vm.theoreticalBudgetToSpend
                ),
              }))+"\n          ")])])]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }