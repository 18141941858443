<template>
  <div class="traffic-container">
    <div
      class="warning"
      v-if="showWarningData"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('warningMsgData')"
        placement="right-start"
      >
        <i class="el-icon-warning"> </i>
      </el-tooltip>
    </div>
    <div
      class="warning"
      v-else-if="showWarning"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('warningMsg')"
        placement="right-start"
      >
        <i class="el-icon-warning"> </i>
      </el-tooltip>
    </div>
    <div
      class="warning"
      v-else-if="showWarningFolder"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('warningMsgFolder', [host])"
        placement="right-start"
      >
        <i class="el-icon-warning"> </i>
      </el-tooltip>
    </div>

    <div class="value-container">
      <div>
        <div class="value">
          {{
            isOrganicTrafficNull() ? "-" : metrics.organicTraffic | formatNumber
          }}
        </div>
        <div class="text">
          {{ $t("visits_per_month") }}
        </div>
      </div>
    </div>
    <el-divider class="divider" />
    <div class="traffic-container-bottom">
      <div>
        <div class="value">
          {{
            isOrganicTrafficNull()
              ? "-"
              : metrics.organicKeywords | formatNumber
          }}
        </div>
        <div class="text">
          {{ $t("keywords_positionned") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    metrics: Object,
    host: String,
    showWarning: Boolean,
    showWarningFolder: Boolean,
  },
  methods: {
    isOrganicTrafficNull() {
      return this.metrics.organicTraffic == null;
    },
  },
  computed: {
    showWarningData() {
      return (
        !this.metrics.hasOwnProperty("organicTraffic") &&
        !this.metrics.hasOwnProperty("organicKeywords")
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.traffic-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  .value-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .value {
      text-align: center;
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
    }
  }

  .text {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.065rem;
  }

  .divider {
    height: 2px;
    margin-bottom: 15px;
  }
}

.warning {
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  color: #e6a23c;
}

.traffic-container-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .value {
    text-align: center;
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
  }

  .text {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.065rem;
  }
}
</style>
<i18n>
{
  "en": {
    "traffic": "Traffic",
    "visits_per_month": "Visit/Month",
    "keywords_positionned": "Ranked keywords",
    "urls": "Urls",
    "warningMsg": " This report shows the traffic and positioned keywords of the root domain",
    "warningMsgData": "Data unavailable",
    "warningMsgFolder": " This report shows the traffic and positioned keywords of {0}"
  },
  "fr": {
    "traffic": "Trafic",
    "visits_per_month": "Visites/Mois",
    "keywords_positionned": "Mots clés positionnés",
    "urls": "Urls",
    "warningMsg": " Ce rapport affiche le trafic et les mots clés positionnés du domaine racine",
    "warningMsgData": "Données indisponibles",
    "warningMsgFolder": " Ce rapport affiche le trafic et les mots clés positionnés du {0}"
  },
  "de": {
    "traffic": "Verkehr",
    "visits_per_month": "Besuche/Monat",
    "keywords_positionned": "Positionierte Schlüsselwörter",
    "urls": "URLs",
    "warningMsg": "Dieser Bericht zeigt den Verkehr und die gerankten Keywords der Root-Domain",
    "warningMsgData": "Keine Daten verfügbar",
    "warningMsgFolder": "Dieser Bericht zeigt den Traffic und die Keywords, die von {0} geordnet sind."
  }
}
</i18n>
