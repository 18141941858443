<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h3 class="title">{{ title }}</h3>
      </div>
      <ContentForm
        ref="contentFormComponentRef"
        v-model="content"
        :form="link.form"
        :additional-fields="link.additionalFields"
      />
      <div class="submit">
        <el-button
          type="success"
          plain
          @click="submit(link)"
        >
          {{ $t("confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import ContentForm from "@/pages/ContentManager/Contents/ContentForm";
import DynamicForm from "@/components/DynamicForm";

export default {
  components: {
    DynamicForm,
    ContentForm,
  },
  props: {
    title: String,
    value: Boolean,
    link: Object,
  },
  data() {
    return {
      content: {},
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    submit() {
      this.$refs.contentFormComponentRef.$refs.contentFormRef.validate(
        (valid) => {
          if (valid) {
            let details = {};
            details.entity = this.content;
            details.link = this.link;
            this.$emit("add-entity", details);
            this.dialogVisible = false;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  flex-direction: row;
}

.submit {
  margin-top: 1rem;
  text-align: center;
}

.title {
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "confirm": "Confirm"
  },
  "fr": {
    "confirm": "Valider"
  },
  "de": {
    "confirm": "Bestätigen"
  }
}
</i18n>
