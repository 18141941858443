<template>
  <li class="nav-item">
    <a
      v-if="googleAccountExist"
      :href="linkGoogleAccount"
      class="nav-link"
    >
      {{ $t("googleConfiguration") }}
    </a>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      linkGoogleAccount: null,
      googleAccountAnchor: "#googleAccount",
    };
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
    googleAccountExist() {
      return this.studyLinks["googleAccount"];
    },
  },
  mounted() {
    //this.linkGoogleAccount = `/spa/to?redirect_uri=/studies/${this.study["id"]}/configuration?tab=googleAccount`
    this.linkGoogleAccount = `/spa-app/studies/${this.study["id"]}/configuration?tab=googleAccount`;
  },
};
</script>

<i18n>
{
  "en": {
    "googleConfiguration": "Google Configuration"
  },
  "fr": {
    "googleConfiguration": "Configuration Google"
  },
  "de": {
    "googleConfiguration": "Konfiguration Google"
  }
}
</i18n>
