<template>
  <li class="nav-item pl-3">
    <a
      v-if="googleAdsExists"
      :href="linkGoogleAds"
      class="nav-link"
    >
      {{ $t("googleAds") }}
    </a>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      linkGoogleAds: null,
      googleAccountAnchor: "#googleAds",
    };
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
    googleAdsExists() {
      return this.studyLinks["googleAds"];
    },
  },
  mounted() {
    //this.linkGoogleAds = `/spa/to?redirect_uri=/studies/${this.study["id"]}/configuration?tab=googleAds`
    this.linkGoogleAds = `/spa-app/studies/${this.study["id"]}/configuration?tab=googleAds`;
  },
};
</script>

<i18n>
    {
      "en": {
        "googleAds": "Google Ads"
      },
      "fr": {
        "googleAds": "Google Ads"
      },
      "de": {
        "googleAds": "Google Ads"
      }
    }
    </i18n>
