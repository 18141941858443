<template>
  <span>
    <el-tooltip
      placement="top"
      transition="el-fade-in"
    >
      <div slot="content">
        {{ $t("present_in_database_explanation") }}
      </div>
      <span
        >{{ $t("present_in_database_header") }}
        <i class="fas fa-question-circle"></i
      ></span>
    </el-tooltip>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "present_in_database_explanation": "Keywords may not be present in our database, they will be displayed with null data",
    "present_in_database_header": "Keyword present ?"
  },
  "fr": {
    "present_in_database_explanation": "Des mots clés peuvent ne pas être présent dans notre base de données, ils seront affichés avec des données nulles.",
    "present_in_database_header": "Mot clé présent ?"
  },
  "de": {
    "present_in_database_explanation": "Schlüsselwörter sind möglicherweise nicht in unserer Datenbank vorhanden, sie werden mit Nulldaten angezeigt.",
    "present_in_database_header": "Stichwort vorhanden?"
  }
}
</i18n>
