<template>
  <section class="app-introduction">
    <div class="container">
      <div class="boxed-title flex-container">
        <div>
          <h1>{{ title }}</h1>

          <div>
            <slot name="sub-header"></slot>
          </div>
          <div v-if="user && user.locale === 'fr'">
            <div
              v-if="videoLink"
              class="app-introduction-subtext"
            >
              <el-link
                :href="videoLink"
                target="_blank"
                type="primary"
                :underline="false"
              >
                <i class="fas fa-video"></i>
                <span>{{ $t("explicative_video") }}</span>
              </el-link>
            </div>
            <div
              class="container-for"
              v-for="documentation in documentations"
            >
              <div
                v-if="documentation.link && documentation.description"
                class="app-introduction-subtext"
              >
                <el-link
                  :href="documentation.link"
                  target="_blank"
                  type="primary"
                  :underline="false"
                >
                  <i class="fas fa-question-circle"></i>
                  <span>{{ documentation.description }}</span>
                </el-link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    explicativeVideo: String,
    videoLink: String,
    documentations: Array,
    user: Object,
  },
};
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<i18n>
{
  "en": {
    "explicative_video": "How to use this screen"
  },
  "fr": {
    "explicative_video": "Comment utiliser cet écran"
  },
  "de": {
    "explicative_video": "So verwenden Sie diesen Bildschirm"
  }
}
</i18n>
