<template>
  <div>
    <highcharts
      ref="highchart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
    urlOrDomain: String,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 300,
        },
        title: {
          text: this.$i18n.t("#_referring_domains_vs_serp"),
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    mapData() {
      if (this.data) {
        this.chartOptions.series = [];
        this.chartOptions.xAxis.categories = [];

        this.chartOptions.xAxis.categories.push(this.$i18n.t("serp"));
        if (this.urlOrDomain == "DOMAIN") {
          this.chartOptions.xAxis.categories.push(this.$i18n.t("your_site"));
        } else {
          this.chartOptions.xAxis.categories.push(this.$i18n.t("your_page"));
        }

        this.chartOptions.series.push({
          name: this.$i18n.t("tf_from_0_to_10"),
          data: [
            Math.round(this.data.serpMedians.from0to10),
            this.data.urlValues.from0to10,
          ],
          visible: false,
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("tf_from_11_to_20"),
          data: [
            Math.round(this.data.serpMedians.from11to20),
            this.data.urlValues.from11to20,
          ],
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("tf_from_21_to_30"),
          data: [
            Math.round(this.data.serpMedians.from21to30),
            this.data.urlValues.from21to30,
          ],
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("tf_from_31_to_40"),
          data: [
            Math.round(this.data.serpMedians.from31to40),
            this.data.urlValues.from31to40,
          ],
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("tf_from_41_to_50"),
          data: [
            Math.round(this.data.serpMedians.from41to50),
            this.data.urlValues.from41to50,
          ],
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("tf_from_51_to_100"),
          data: [
            Math.round(this.data.serpMedians.from51to100),
            this.data.urlValues.from51to100,
          ],
        });
      } else {
        this.chartOptions.series = [];
        this.chartOptions.xAxis.categories = [];
      }
    },
  },
  watch: {
    data() {
      this.mapData();
    },
  },
  mounted() {
    if (this.data) {
      this.mapData();
    }
  },
};
</script>

<i18n>
{
  "en": {
    "#_referring_domains_vs_serp": "Repartition of your Referring domains vs the SERP's ",
    "number_of_backlinks": "Number of backlinks",
    "your_site": "Your website",
    "your_page": "Your page",
    "serp": "SERP",
    "tf_from_0_to_10": "Domain with TF < 10",
    "tf_from_11_to_20": "Domain with TF 11-20",
    "tf_from_21_to_30": "Domain with TF 21-30",
    "tf_from_31_to_40": "Domain with TF 31-40",
    "tf_from_41_to_50": "Domain with TF 41-50",
    "tf_from_51_to_100": "Domain with TF > 50"
  },
  "fr": {
    "#_referring_domains_vs_serp": "Répartition domaines référents vs la SERP",
    "number_of_backlinks": "Nombre de backlinks",
    "your_site": "Votre site",
    "your_page": "Votre page",
    "serp": "SERP",
    "tf_from_0_to_10": "TF Domaine < 10",
    "tf_from_11_to_20": "TF Domaine 11-20",
    "tf_from_21_to_30": "TF Domaine 21-30",
    "tf_from_31_to_40": "TF Domaine 31-40",
    "tf_from_41_to_50": "TF Domaine 41-50",
    "tf_from_51_to_100": "TF Domaine > 50"
  },
  "de": {
    "#_referring_domains_vs_serp": "Aufschlüsselung der verweisenden Domains im Vergleich zum SERP",
    "number_of_backlinks": "Backlink-Name",
    "your_site": "Deine Seite",
    "your_page": "Deine Seite",
    "serp": "SERP",
    "tf_from_0_to_10": "TF-Domäne < 10",
    "tf_from_11_to_20": "TF Domaine 11-20",
    "tf_from_21_to_30": "TF Domaine 21-30",
    "tf_from_31_to_40": "TF Domaine 31-40",
    "tf_from_41_to_50": "TF-Domäne 41-50",
    "tf_from_51_to_100": "TF-Domäne > 50"
  }
}
</i18n>
