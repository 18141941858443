export default {
  backlinks: {
    domainRefused: {
      metrics_too_low: "metriken zu niedrig",
      wrong_thematic: "falsches thema",
      da_too_low: "da zu niedrig",
      tf_too_low: "tf zu niedrig",
      traffic_too_low_or_null: "zu niedriger oder kein traffic",
      price_too_high: "preis zu hoch",
      site_not_relevant: "website nicht relevant",
      keywords_not_relevant: "keywords nicht relevant",
      link_already_exists: "link existiert bereits",
      other: "sonstiges",
    },
    gridHeader: {
      target_url: "Zielseite",
    }
  },
};
