<template>
  <div>
    <div class="actions">
      <div class="text-right">
        <el-button
          plain
          type="primary"
          @click="
            () => {
              creatingStudy = true;
              dialogCreateStudyVisible = true;
            }
          "
          >{{ $t("createStudy") }}</el-button
        >
      </div>
      <el-dialog
        :title="$t('createStudy')"
        :visible.sync="dialogCreateStudyVisible"
      >
        <study-form
          :key="creatingStudy"
          :studyTypes="studyTypes"
          @submit="createStudy($event)"
          @close-dialog="dialogCreateStudyVisible = false"
        ></study-form>
      </el-dialog>
      <el-dialog
        :title="$t('editStudy')"
        :visible.sync="dialogEditStudyVisible"
      >
        <study-form
          :key="editStudyId"
          :studyId="editStudyId"
          :studyTypes="studyTypes"
          @submit="updateStudy($event)"
          @close-dialog="dialogEditStudyVisible = false"
        ></study-form>
      </el-dialog>
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      :suppressCellSelection="true"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
      :context="context"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { RepositoryFactory } from "@/services/repositoryFactory";
const StudiesRepository = RepositoryFactory.get("studies");
const CompaniesRepository = RepositoryFactory.get("companies");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import StudyForm from "@/pages/AdminOverview/Forms/StudyForm";

const ActionsComponent = Vue.extend({
  template: `
		<div>
			<el-button size="mini" plain icon="fas fa-edit" @click="invokeEditStudy"></el-button>
			<el-button size="mini" type="danger" plain icon="fas fa-trash-alt" @click="invokeDeleteStudy"></el-button>
		</div>
	`,
  data: function () {
    return {
      studyId: null,
    };
  },
  mounted() {
    this.studyId = this.params.data.id;
  },
  methods: {
    invokeEditStudy() {
      this.params.context.studies.editStudy(this.studyId);
    },
    invokeDeleteStudy() {
      this.params.context.studies.deleteStudy(this.studyId);
    },
  },
});

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
    StudyForm,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      dialogCreateStudyVisible: false,
      creatingStudy: false,
      editStudyId: null,
      dialogEditStudyVisible: false,
      studyTypes: [],
    };
  },
  methods: {
    getStudyTypes() {
      this.$api
        .get("studies/module", {headers: { 'Accept': 'application/vnd.api+json' }})
        .then((response) => {
          const create = response.data.data.attributes.create;
          const study_type_obj = create.meta.form[1].form.find((attribute) => attribute.name === "studyType")

          this.studyTypes = study_type_obj.options
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.defaultColDef = {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      };
      this.columnDefs = [
        {
          headerName: "ID",
          field: "id",
          minWidth: 100,
          maxWidth: 120,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterValueGetter: (params) =>
            params.data ? parseInt(params.data.id) : null,
          filterParams: {
            defaultOption: "equals",
          },
        },
        {
          headerName: this.$i18n.t("url"),
          field: "url",
          minWidth: 300,
          filter: "agTextColumnFilter",
          cellRenderer: this.linkRenderer,
        },
        {
          headerName: this.$i18n.t("study_type"),
          field: "studyType",
          valueFormatter: params => this.$i18n.t(params.value),
          filter: "agTextColumnFilter",
          filterValueGetter: params => this.$i18n.t(params.data.studyType)
        },
        {
          headerName: this.$i18n.t("customName"),
          field: "customName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("country"),
          field: "country",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("actions"),
          minWidth: 130,
          maxWidth: 130,
          cellRendererFramework: ActionsComponent,
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ];
    },
    fetchData() {
      StudiesRepository.getStudies()
        .then((data) => {
          this.rowData = data.data.map((account) => ({
            id: account.id,
            ...account.attributes,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createStudy(form) {
      CompaniesRepository.createCompanyStudy(form.companyId, form)
        .then((response) => {
          this.rowData.unshift({
            id: response.data.data.id,
            ...response.data.data.attributes,
          });
          this.creatingStudy = false;
          this.$message({
            message: this.$i18n.t("createSuccess"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
            type: "error",
          });
        });
    },
    editStudy(studyId) {
      this.editStudyId = parseInt(studyId);
      this.dialogEditStudyVisible = true;
    },
    updateStudy(form) {
      StudiesRepository.updateStudy(this.editStudyId, form)
        .then((response) => {
          const currentIndex = this.rowData.findIndex(
            (row) => row.id == this.editStudyId
          );
          this.rowData.splice(currentIndex, 1, {
            id: response.data.data.id,
            ...response.data.data.attributes,
          });
          this.$message({
            message: this.$i18n.t("updateSuccess"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            message: this.$i18n.t("updateFail"),
            type: "error",
            duration: 6000,
          });
        });
    },
    deleteStudy(studyId) {
      if (confirm(this.$i18n.t("deleteConfirmation"))) {
        StudiesRepository.deleteStudy(studyId)
          .then((data) => {
            this.rowData = this.rowData.filter((row) => row.id !== studyId);
            this.$message.success(this.$i18n.t("deleteSuccess"));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="/a/${params.data.id}">${params.value}</a>`;
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.fetchData();
    this.getStudyTypes();
    this.context = { studies: this };
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.text-center {
  text-align: center;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "url": "URL",
    "customName": "Custom name",
    "country": "Country",
    "actions": "Actions",
    "createStudy": "Create study",
    "createSuccess": "Study was created successfully.",
    "editStudy": "Edit study",
    "updateSuccess": "Study was successfully updated.",
    "updateFail": "We encountered an error while updating the study",
    "deleteConfirmation": "Are you sure you want to delete this study ?",
    "deleteSuccess": "Study was successfully deleted.",
    "main_study": "Main study",
    "study_type": "Study type",
    "secondary_study": "Secondary study",
    "oneshot_study": "Oneshot study"
  },
  "fr": {
    "url": "URL",
    "customName": "Nom personnalisé",
    "country": "Pays",
    "actions": "Actions",
    "createStudy": "Créer une étude",
    "createSuccess": "L'étude a été créée avec succès.",
    "editStudy": "Editer l'étude",
    "updateSuccess": "L'étude a été mise à jour avec succès.",
    "updateFail": "Nous avons rencontré une erreur lors de la msie à jour de l'étude",
    "deleteConfirmation": "Etes-vous sûr de vouloir supprimer cette étude ?",
    "deleteSuccess": "L'étude a été supprimée avec succès.",
    "main_study": "Etude principale",
    "study_type": "Type d'étude",
    "secondary_study": "Etude secondaire",
    "oneshot_study": "Etude oneshot"
  },
  "de": {
    "url": "URL",
    "customName": "benutzerdefinierter Name",
    "country": "Zahlt",
    "actions": "Aktionen",
    "createStudy": "Erstellen Sie eine Studie",
    "createSuccess": "Die Studie wurde erfolgreich erstellt.",
    "editStudy": "Studie bearbeiten",
    "updateSuccess": "Die Studie wurde erfolgreich aktualisiert.",
    "updateFail": "Beim Aktualisieren der Studie ist ein Fehler aufgetreten",
    "deleteConfirmation": "Möchten Sie diese Studie wirklich löschen?",
    "deleteSuccess": "Die Studie wurde erfolgreich gelöscht.",
    "main_study": "Hauptstudium",
    "study_type": "Studientyp",
    "secondary_study": "Zweitstudium",
    "oneshot_study": "Oneshot-Studie"
  }
}
</i18n>
