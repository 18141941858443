<template>
  <el-form
    label-width="200px"
    label-position="right"
    :model="formModel"
    :rules="validationRules"
    ref="formRef"
  >
    <el-form-item
      :label="$t('reasonOfRefusal')"
      prop="reasonOfRefusal"
    >
      <el-select v-model="formModel.reasonOfRefusal">
        <el-option
          v-for="option in refusalOptions"
          :value="option"
          :label="getLabel(option)"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('detailsOfRefusal')">
      <el-input
        v-model="formModel.detailsOfRefusal"
        type="textarea"
        rows="3"
      />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    bulkUpdate: Object,
  },
  data() {
    return {
      formModel: {},
      validationRules: {
        reasonOfRefusal: [
          {
            required: true,
            message: this.$t("reasonOfRefusalRequiredMessage"),
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    getLabel(option) {
      return this.$t(`backlinks.domainRefused.${option.toLowerCase()}`);
    },
  },
  computed: {
    refusalOptions() {
      return this.bulkUpdate.additionalFields.status.refused[0].options;
    },
  },
};
</script>

<style scoped lang="scss"></style>
<i18n>
{
  "en": {
    "reasonOfRefusal": "Reason of refusal",
    "detailsOfRefusal": "Details of refusal",

    "reasonOfRefusalRequiredMessage": "The reason is mandatory"
  },
  "fr": {
    "reasonOfRefusal": "Raison",
    "detailsOfRefusal": "Précisez la raison",

    "reasonOfRefusalRequiredMessage": "Le motif est obligatoire"
  },
  "de": {
    "reasonOfRefusal": "Grund der Ablehnung",
    "detailsOfRefusal": "Details der Ablehnung",

    "reasonOfRefusalRequiredMessage": "Der Grund ist obligatorisch"
  }
}
</i18n>
