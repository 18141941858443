import Repository from "@/services/repository";

export default {
  createEnrichedWebsites(form) {
    return Repository.post(`/admin/tools/enriched_websites_exports.json`, {
      enrichedWebsitesExport: {
        websites: form.websites,
      },
    }).then((response) => response.data);
  },

  getNetlinkingWebsite(form) {
    return Repository.post(`/admin/tools/netlinking_exports.json`, {
      netlinkingExport: {
        accountId: form.studyId,
        competitors: form.competitors,
        keyword: form.keyword,
      },
    }).then((response) => response.data);
  },

  createPagesAnalysisExport(form) {
    return Repository.post(`/admin/tools/pages_analysis_exports.json`, {
      pageAnalysisExport: {
        accountId: form.studyId,
        urls: form.urls,
      },
    }).then((response) => response.data);
  },
};
