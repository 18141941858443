<template>
  <el-card>
    <h2>{{ $t("studies") }}</h2>
    <div class="actions">
      <div class="text-right">
        <el-button
          plain
          type="primary"
          @click="dialogCreateStudyVisible = true"
          >{{ $t("create_study") }}</el-button
        >
      </div>
      <el-dialog
        :title="$t('create_study')"
        :visible.sync="dialogCreateStudyVisible"
      >
        <study-form
          :current-user="currentUser"
          :studyTypes="studyTypes"
          @submit="createStudy($event)"
          @close-dialog="dialogCreateStudyVisible = false"
        ></study-form>
      </el-dialog>
      <el-dialog
        :title="$t('edit_study')"
        :visible.sync="dialogEditStudyVisible"
      >
        <study-form
          :studyId="editStudyId"
          :current-user="currentUser"
          :studyTypes="studyTypes"
          @submit="updateStudy($event)"
          @close-dialog="dialogEditStudyVisible = false"
        ></study-form>
      </el-dialog>
    </div>
    <div class="grid">
      <ag-grid-vue
        :domLayout="'autoHeight'"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :resizable="true"
        :filter="true"
        :localeTextFunc="localeTextFunc"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        @gridReady="onGridReady"
        @firstDataRendered="adjustGrid"
        @rowDataChanged="adjustGrid"
        :context="context"
      >
      </ag-grid-vue>
    </div>
  </el-card>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompaniesRepository = RepositoryFactory.get("companies");
const StudiesRepository = RepositoryFactory.get("studies");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import StudyForm from "@/pages/CompanyEdit/Forms/StudyForm";

const ActionsComponent = Vue.extend({
  template: `
		<div>
			<el-button size="mini" plain icon="fas fa-edit" @click="invokeEditStudy"></el-button>
			<el-button size="mini" type="danger" plain icon="fas fa-trash-alt" @click="invokeDeleteStudy"></el-button>
		</div>
	`,
  data: function () {
    return {
      studyId: null,
    };
  },
  mounted() {
    this.studyId = this.params.data.id;
  },
  methods: {
    invokeEditStudy() {
      this.params.context.studies.editStudy(this.studyId);
    },
    invokeDeleteStudy() {
      this.params.context.studies.deleteStudy(this.studyId);
    },
  },
});

export default {
  mixins: [agGridMixin],
  props: {
    companyId: Number,
    currentUser: Object
  },
  components: {
    AgGridVue,
    StudyForm,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      dialogCreateStudyVisible: false,
      editStudyId: null,
      dialogEditStudyVisible: false,
      studyTypes: []
    };
  },
  methods: {
    getStudyTypes() {
      this.$api
        .get("studies/module", {headers: { 'Accept': 'application/vnd.api+json' }})
        .then((response) => {
          const create = response.data.data.attributes.create;
          const study_type_obj = create.meta.form[1].form.find((attribute) => attribute.name === "studyType")

          this.studyTypes = study_type_obj.options
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.defaultColDef = {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      };
      this.columnDefs = [
        {
          headerName: this.$i18n.t("name"),
          field: "name",
          minWidth: 300,
          filter: "agTextColumnFilter",
          cellRenderer: this.linkRenderer,
        },
        {
          headerName: this.$i18n.t("url"),
          field: "url",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("study_type"),
          field: "studyType",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("country"),
          field: "country",
          filter: "agTextColumnFilter",
        },
      ];

      this.columnDefs.push({
        headerName: this.$i18n.t("actions"),
        minWidth: 130,
        maxWidth: 130,
        cellRendererFramework: ActionsComponent,
        filter: false,
        suppressMenu: true,
        sorting: false,
      });
    },
    fetchData() {
      CompaniesRepository.getCompanyStudies(this.companyId)
        .then((data) => {
          this.rowData = data.data.map((study) => ({
            id: study.id,
            name: study.attributes.name,
            url: study.attributes.url,
            studyType: this.$i18n.t(study.attributes.studyType),
            country: study.attributes.country,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createStudy(form) {
      CompaniesRepository.createCompanyStudy(this.companyId, form)
        .then((response) => {
          this.$message.success(this.$i18n.t("create_study_success"));
          this.fetchData();
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
            type: "error",
          });
        });
    },
    editStudy(studyId) {
      this.editStudyId = parseInt(studyId);
      this.dialogEditStudyVisible = true;
    },
    updateStudy(form) {
      StudiesRepository.updateStudy(this.editStudyId, form)
        .then((response) => {
          this.$message.success(this.$i18n.t("edit_study_success"));
          this.fetchData();
        })
        .catch((error) => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
            type: "error",
          });
        });
    },
    deleteStudy(studyId) {
      if (confirm(this.$i18n.t("delete_study_confirmation"))) {
        StudiesRepository.deleteStudy(studyId)
          .then((response) => {
            this.$message.success(this.$i18n.t("delete_study_success"));
            this.rowData = this.rowData.filter((row) => row.id !== studyId);
            setTimeout(() => {
              this.$emit("refresh");
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="/a/${params.data.id}">${params.value}</a>`;
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.fetchData();
    this.getStudyTypes();
    this.context = { studies: this };
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.actions {
  margin-bottom: 1rem;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "studies": "Studies",
    "name": "Name",
    "url": "URL",
    "country": "Country",
    "users": "Users",
    "actions": "Actions",
    "create_study": "Create study",
    "create_study_success": "Study was successfully created.",
    "edit_study": "Edit study",
    "edit_study_success": "Study was successfully edited.",
    "delete_study_confirmation": "Are you sure you want to delete this study ?",
    "delete_study_success": "Study was successfully deleted.",
    "study_type": "Study type",
    "main_study": "Main study",
    "secondary_study": "Secondary study",
    "oneshot_study": "Oneshot study"
  },
  "fr": {
    "studies": "Etudes",
    "name": "Nom",
    "url": "URL",
    "country": "Pays",
    "users": "Utilisateurs",
    "actions": "Actions",
    "create_study": "Créer une étude",
    "create_study_success": "L'étude a été créée avec succès.",
    "edit_study": "Editer l'étude",
    "edit_study_success": "L'étude a été édité avec succès.",
    "delete_study_confirmation": "Etes-vous sûr de vouloir supprimer cette étude ?",
    "delete_study_success": "L'étude a été supprimée avec succès.",
    "study_type": "Type d'étude",
    "main_study": "Etude principale",
    "secondary_study": "Etude secondaire",
    "oneshot_study": "Etude oneshot"
  },
  "de": {
    "studies": "Studien",
    "name": "Name",
    "url": "URL",
    "country": "Zahlt",
    "users": "Benutzer",
    "actions": "Aktionen",
    "create_study": "Erstellen Sie eine Studie",
    "create_study_success": "Die Studie wurde erfolgreich erstellt.",
    "edit_study": "Studie bearbeiten",
    "edit_study_success": "Die Studie wurde erfolgreich editiert.",
    "delete_study_confirmation": "Möchten Sie diese Studie wirklich löschen?",
    "delete_study_success": "Die Studie wurde erfolgreich gelöscht.",
    "study_type": "Studientyp",
    "main_study": "Hauptstudium",
    "secondary_study": "Zweitstudium",
    "oneshot_study": "Oneshot-Studie"
  }
}
</i18n>
