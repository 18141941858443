<template>
  <div>
    <el-select
      v-model="selectedItem"
      multiple
      collapse-tags
      filterable
      @change="addItem"
    >
      <el-option
        v-for="item in options"
        :key="item"
        :label="$t(camalize(item))"
        :value="item"
      >
      </el-option>
    </el-select>
    <ul>
      <li
        v-for="item in alreadySelectedItems"
        v-bind:key="item"
      >
        <el-tag
          closable
          @close="deleteItem(item)"
        >
          {{ $t(camalize(item)) }}
        </el-tag>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    options: Array,
  },
  data() {
    return {
      selectedItem: [],
    };
  },
  computed: {
    items: {
      get() {
        return this.value;
      },
      set(items) {
        this.$emit("input", items);
      },
    },
    alreadySelectedItems() {
      return this.options.filter((option) => this.items.includes(option));
    },
  },
  methods: {
    deleteItem(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
    addItem() {
      if (this.selectedItem) {
        this.selectedItem.forEach((element) => {
          if (!this.items.includes(element)) {
            this.items.push(element);
          }
        });
      }
    },
    camalize(str) {
      return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  margin-bottom: 0.35rem;
}
</style>

<i18n>
{
  "en": {
        "fastPublication": "Fast Publication",
        "highTraffic": "High Traffic",
        "eCommerceSite": "E-commerce Site",
        "lowCost": "Little expensive at the moment T",
        "highTf": "High TF",
        "highDa": "High DA",
        "positionedClientKeyword": "Positioned on client keyword",
        "positionedKeywordLongTail": "Positioned on keyword long tail close",
        "closeComplementarySemantics": "Close/complementary semantics",
        "relatedTopic": "Related topic",
        "concurrentSite": "Competitor site",
        "concurrentSpot": "Competitors have this spot"
  },
  "fr": {
        "fastPublication": "Publication rapide",
        "highTraffic": "Trafic élevé",
        "eCommerceSite": "Site E-commerce",
        "lowCost": "Peu cher à l'instant T",
        "highTf": "TF élevé",
        "highDa": "DA élevé",
        "positionedClientKeyword": "Positionné sur MC client",
        "positionedKeywordLongTail": "Positionné sur MC longue traîne proche",
        "closeComplementarySemantics": "Sémantique proche / complémentaire",
        "relatedTopic": "Thématique connexe",
        "concurrentSite": "Site concurrent",
        "concurrentSpot": "Concurrents ont ce spot"

  },
  "de": {
        "fastPublication": "Schneller Beitrag",
        "highTraffic": "Viel Verkehr",
        "eCommerceSite": "E-Commerce-Website",
        "lowCost": "Im Moment etwas teuer T",
        "highTf": "hoher TF",
        "highDa": "hohe AD",
        "positionedClientKeyword": "Positioniert auf Kunden-Keyword",
        "positionedKeywordLongTail": "Positioniert auf Keyword Long Tail Close",
        "closeComplementarySemantics": "Schließen/komplementäre Semantik",
        "relatedTopic": "Verwandtes Thema",
        "concurrentSite": "Konkurrenzseite",
        "concurrentSpot": "Konkurrenten haben diesen Platz"
  }
}
</i18n>
