<template>
  <div>
    <el-steps
      :active="activeStep"
      finish-status="success"
      align-center
    >
      <el-step
        :title="$t('dimensions')"
        :description="$t('select_dimension')"
      ></el-step>
      <el-step
        :title="$t('values')"
        :description="$t('edit_values')"
      ></el-step>
      <el-step
        :title="$t('rules')"
        :description="$t('edit_rules')"
      ></el-step>
      <el-step
        :title="$t('confirmation')"
        :description="$t('confirmation_description')"
      ></el-step>
    </el-steps>
    <div
      v-show="activeStep === 0"
      class="dimension-selector"
    >
      <el-table :data="dimensions">
        <el-table-column
          type="index"
          width="50"
        >
        </el-table-column>
        <el-table-column :label="$t('name')">
          <template slot-scope="scope">
            <template v-if="scope.row.permission === 'WRITE_FULL_ACCESS'">
              <el-input v-model="scope.row.displayName"></el-input>
            </template>
            <template v-else>
              {{ scope.row.displayName }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          property="values.length"
          :label="$t('values')"
          width="100"
        >
        </el-table-column>
        <el-table-column
          property="rules.length"
          :label="$t('rules')"
          width="100"
        >
        </el-table-column>
        <el-table-column width="200">
          <template slot-scope="scope">
            <div class="actions">
              <el-button
                v-if="
                  (scope.row.permission === 'WRITE_FULL_ACCESS' ||
                    scope.row.permission === 'WRITE_RULES_ACCESS') &&
                  scope.row.displayName.length > 0
                "
                @click="setActiveDimension(scope.row)"
                plain
                type="primary"
                size="small"
              >
                {{ $t("edit") }}
              </el-button>
              <el-button
                v-if="scope.row.permission === 'WRITE_FULL_ACCESS'"
                @click="removeDimension(scope.$index)"
                type="danger"
                icon="fas fa-trash-alt"
                size="small"
              >
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="actions">
        <el-tooltip
          :content="$t('maxNumberOfDimensionsReached')"
          placement="bottom"
          v-if="maxNumberOfDimensionsReached"
        >
          <div>
            <el-button
              :disabled="true"
              plain
              @click="addDimension"
              size="small"
              >{{ $t("add_dimension") }}</el-button
            >
          </div>
        </el-tooltip>
        <el-button
          v-else
          plain
          @click="addDimension"
          size="small"
          >{{ $t("add_dimension") }}</el-button
        >
      </div>
    </div>
    <div
      v-if="activeDimension && activeStep === 1"
      class="value-editor"
    >
      <div class="values">
        <template v-if="activeDimension.permission === 'WRITE_FULL_ACCESS'">
          <el-input
            v-for="(value, index) in activeDimension.values"
            :key="index"
            v-model="value.displayName"
            @input="handleDisplayNameChange($event, value)"
            class="value-input"
          >
            <template slot="prepend">{{ index + 1 }}</template>
            <el-button
              slot="append"
              v-if="activeDimension.permission === 'WRITE_FULL_ACCESS'"
              @click="removeValue(index)"
              icon="fas fa-trash-alt"
            >
            </el-button>
          </el-input>
          <el-button
            plain
            @click="addValue"
            size="small"
            >{{ $t("add_value") }}</el-button
          >
        </template>
        <template v-else>
          <el-input
            v-for="(value, index) in activeDimension.values"
            :key="index"
            v-model="value.displayName"
            readonly
            class="value-input"
          >
            <template slot="prepend">{{ index + 1 }}</template>
          </el-input>
        </template>
      </div>
      <div
        class="default-value"
        v-if="
          activeDimension.permission === 'WRITE_FULL_ACCESS' && valuesIsNotEmpty
        "
      >
        <span class="label">
          {{ $t("default_value") }}
          <el-tooltip
            :content="$t('default_value_tooltip')"
            placement="top"
          >
            <i class="fas fa-info-circle"></i>
          </el-tooltip>
        </span>
        <el-select v-model="activeDimension.defaultValueName">
          <el-option
            v-for="(item, index) in activeDimension.values"
            :key="index"
            :label="item.displayName"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="step-navigation">
        <el-button @click="activeStep--">{{ $t("previous_step") }}</el-button>
        <el-button
          plain
          type="primary"
          @click="activeStep++"
          :disabled="valuesNextStepIsDisabled"
          >{{ $t("next_step") }}</el-button
        >
      </div>
    </div>
    <div
      v-if="activeDimension && activeStep === 2"
      class="rules-editor"
    >
      <div
        v-for="(rule, index) in activeDimension.rules"
        :key="index"
        class="rule"
      >
        <el-select v-model="rule.input">
          <el-option
            v-for="item in ruleInputOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select v-model="rule.matchingType">
          <el-option
            v-for="item in ruleMatchingTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="assignments">
          <div
            v-for="(assignment, index) in rule.assignments"
            :key="index"
            class="assignment"
          >
            <el-input
              v-model="assignment.expression"
              class="expression"
            ></el-input>
            <el-select
              v-model="assignment.valueName"
              class="value-name"
            >
              <el-option
                v-for="item in activeDimension.values"
                :key="item.name"
                :label="item.displayName"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-button
              @click="removeAssignment(rule, index)"
              plain
              icon="fas fa-trash-alt"
            ></el-button>
          </div>
          <el-button
            @click="addAssignment(rule)"
            size="mini"
            >{{ $t("add_assignment") }}</el-button
          >
        </div>
        <el-button
          @click="removeRule(index)"
          plain
          icon="fas fa-trash-alt"
        ></el-button>
      </div>
      <div
        v-if="
          activeDimension.permission === 'WRITE_FULL_ACCESS' ||
          activeDimension.permission === 'WRITE_RULES_ACCESS'
        "
        class="actions"
      >
        <el-button
          plain
          @click="addRule"
          size="small"
          >{{ $t("add_rule") }}</el-button
        >
      </div>
      <div class="step-navigation">
        <el-button @click="activeStep--">{{ $t("previous_step") }}</el-button>
        <el-button
          plain
          type="primary"
          @click="activeStep++"
          :disabled="rulesNextStepIsDisabled"
          >{{ $t("next_step") }}</el-button
        >
      </div>
    </div>
    <div
      v-if="activeDimension && activeStep === 3"
      class="confirmation"
    >
      <div class="instruction">
        <i class="fas fa-info-circle"></i>
        {{ $t("confirmation_instruction") }}
      </div>
      <div class="step-navigation">
        <el-button @click="activeStep = 0">{{ $t("edit") }}</el-button>
        <el-button
          @click="submitDimensions"
          plain
          type="primary"
          >{{ $t("submit") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const ConfigurationsRepository = RepositoryFactory.get("configurations");

export default {
  props: {
    accountId: Number,
    dimensionsJson: Object,
  },
  data() {
    return {
      dimensions: this.dimensionsJson.dimensions,
      activeStep: 0,
      activeDimension: null,
      ruleInputOptions: [
        { value: "keyword", label: this.$i18n.t("keyword") },
        { value: "page", label: this.$i18n.t("page") },
      ],
      ruleMatchingTypeOptions: [
        { value: "CONTAINS", label: this.$i18n.t("contains") },
        { value: "STARTS_WITH", label: this.$i18n.t("starts_with") },
        { value: "ENDS_WITH", label: this.$i18n.t("ends_with") },
        { value: "EQUALS", label: this.$i18n.t("equals") },
      ],
    };
  },
  computed: {
    maxNumberOfDimensionsReached() {
      return this.dimensions.length >= 16;
    },
    valuesIsNotEmpty() {
      if (this.activeDimension) {
        return (
          this.activeDimension.values.length > 0 &&
          this.activeDimension.values.some((element) => element.name.length > 0)
        );
      }
    },
    valuesNextStepIsDisabled() {
      if (this.activeDimension) {
        let array = this.activeDimension.values;
        if (array.includes(undefined) || array.length == 0) return true;
        for (var i = 0; i < array.length; i++) {
          if (!(i in array) || array[i].name == "") {
            return true;
          }
        }

        return (
          this.activeDimension.defaultValueName === null ||
          this.activeDimension.defaultValueName === ""
        );
      }
    },
    rulesNextStepIsDisabled() {
      if (this.activeDimension) {
        let array = this.activeDimension.rules;
        if (array.includes(undefined) || array.length == 0) return true;
        for (var i = 0; i < array.length; i++) {
          if (
            !(i in array) ||
            array[i].input == "" ||
            array[i].matchingType == ""
          ) {
            return true;
          }
          let subArray = array[i].assignments;
          if (subArray.includes(undefined) || subArray.length == 0) return true;
          for (var j = 0; j < subArray.length; j++) {
            if (
              !(j in subArray) ||
              subArray[j].expression == "" ||
              subArray[j].valueName == ""
            ) {
              return true;
            }
          }
        }
        return false;
      }
    },
  },
  methods: {
    setActiveDimension(val) {
      this.activeDimension = val;
      this.activeStep++;
    },
    addDimension() {
      if (this.maxNumberOfDimensionsReached) {
        this.$message({
          message: this.$i18n.t("maxNumberOfDimensionsReached"),
          type: "error",
          duration: 6000,
        });
        return;
      }

      var index = this.dimensions.length;
      let dimensionName = "Dimension" + (index - 5);
      if (this.dimensions.length > 6) {
        const dimensionsId = this.dimensions
          .slice(6)
          .map((dimension) => parseInt(dimension.name.substring(9)));
        for (let i = 1; i < 11; i++) {
          if (dimensionsId.indexOf(i) == -1) {
            dimensionName = "Dimension" + i;
            break;
          }
        }
      }
      this.dimensions.push({
        name: dimensionName,
        displayName: "",
        permission: "WRITE_FULL_ACCESS",
        defaultValueName: null,
        values: [],
        rules: [],
      });
    },
    removeDimension(index) {
      if (confirm(this.$i18n.t("delete_confirmation"))) {
        if (index !== -1) {
          this.dimensions.splice(index, 1);
          this.submitDimensions();
        }
      }
    },
    addValue() {
      this.activeDimension.values.push({
        displayName: "",
        name: "",
      });
    },
    removeValue(index) {
      const valueName = this.activeDimension.values[index].name;
      if (index !== -1) this.activeDimension.values.splice(index, 1);
      if (this.activeDimension.defaultValueName === valueName)
        this.activeDimension.defaultValueName = null;
    },
    addAssignment(rule) {
      rule.assignments.push({
        expression: "",
        valueName: "",
      });
    },
    removeAssignment(rule, index) {
      rule.assignments.splice(index, 1);
    },
    addRule() {
      this.activeDimension.rules.push({
        input: "",
        matchingType: "",
        assignments: [],
        preconditions: [],
      });
    },
    removeRule(index) {
      if (index !== -1) this.activeDimension.rules.splice(index, 1);
    },
    handleDisplayNameChange(value, object) {
      // Convert display_name to name used in DB, lowercased, stripped non alphanumeric characters and replaced spaces by underscore
      object.name = formatStringForDB(value);
    },
    submitDimensions() {
      ConfigurationsRepository.updateDimensions(this.accountId, this.dimensions)
        .then((data) => {
          this.$message.success(this.$i18n.t("update_success"));
          this.activeStep = 0;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

let formatStringForDB = (string) => {
  return string.toLowerCase().replace(/\s+/g, "_");
};
</script>

<style scoped lang="scss">
.el-steps {
  margin-bottom: 3rem;
}

.dimension-selector {
  .el-table {
    width: 100%;
  }
}

.value-editor {
  .values {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    text-align: center;

    .value-input {
      margin-bottom: 1rem;
    }
  }

  .default-value {
    display: flex;
    justify-content: center;
    align-items: center;

    .label {
      margin-right: 1rem;
      font-weight: 500;
    }
  }
}

.rules-editor {
  .rule {
    display: flex;
    margin-bottom: 1rem;

    > div {
      margin-right: 0.5rem;
    }

    > .el-button {
      margin-left: auto;
    }

    .assignments {
      min-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .assignment {
        display: flex;
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        .expression {
          width: 300px;
          margin-right: 0.5rem;
        }

        .value-name {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.confirmation {
  .instruction {
    margin: 5rem 2rem;
    text-align: center;
  }
}

.dimension-selector,
.value-editor,
.rules-editor {
  > .actions {
    margin-top: 1rem;
    text-align: center;
  }
}

.step-navigation {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}
</style>

<i18n>
{
  "en": {
    "dimensions": "Dimensions",
    "select_dimension": "Select the dimension you want to customise.",
    "values": "Values",
    "edit_values": "Edit the possible values for your rules.",
    "rules": "Rules",
    "edit_rules": "Edit the rules that define your dimension.",
    "confirmation": "Confirmation",
    "confirmation_description": "Continue editing or confirm your changes.",
    "name": "Name",
    "edit": "Edit",
    "add_dimension": "Add dimension",
    "value_label": "Value {index}",
    "add_value": "Add value",
    "default_value": "Default value",
    "default_value_tooltip": "Value applied to entries that do not match any rules",
    "add_expression": "Add expression",
    "add_assignment": "Add assignment",
    "add_rule": "Add rule",
    "previous_step": "Previous step",
    "next_step": "Next step",
    "submit": "Submit",
    "confirmation_instruction": "If you want to keep editing dimensions, click edit and start the process over until you are ready to submit. If you are done, you can submit your changed to be saved.",
    "page": "Page",
    "keyword": "Keyword",
    "contains": "Contains",
    "starts_with": "Starts with",
    "ends_with": "Ends with",
    "equals": "Equals",
    "delete_confirmation": "Are you sure you want to delete this dimension ?",
    "update_success": "Your dimensions configuration was successfully updated.",
    "maxNumberOfDimensionsReached": "You can only add up to 10 dimensions"
  },
  "fr": {
    "dimensions": "Dimensions",
    "select_dimension": "Sélectionnez la dimension que vous voulez personnaliser.",
    "values": "Valeurs",
    "edit_values": "Editez les valeurs possibles pour vos règles.",
    "rules": "Règles",
    "edit_rules": "Editez les règles qui définissent votre dimension.",
    "confirmation": "Confirmation",
    "confirmation_description": "Continuer à éditer ou confirmer vos changements.",
    "name": "Nom",
    "edit": "Editer",
    "add_dimension": "Ajouter dimension",
    "value_label": "Valeur {index}",
    "add_value": "Ajouter valeur",
    "default_value": "Valeur par défaut",
    "default_value_tooltip": "Valeur appliquée aux entrées qui ne correspondent à aucune règle",
    "add_expression": "Ajouter expression",
    "add_assignment": "Ajouter attribution",
    "add_rule": "Ajouter règle",
    "previous_step": "Etape précédente",
    "next_step": "Etape suivante",
    "submit": "Confirmer",
    "confirmation_instruction": "Si vous voulez continuer à éditer vos dimensions, cliquez sur éditer et recommencer le processus jusqu'à ce que vous soyez prêt à confirmer. Si vous avez terminé, vous pouvez confirmer vos changements pour les sauvegarder.",
    "page": "Page",
    "keyword": "Mot clé",
    "contains": "Contient",
    "starts_with": "Commence par",
    "ends_with": "Finit par",
    "equals": "Est égal à",
    "delete_confirmation": "Etes-vous sûr de voulour supprimer cette dimension ?",
    "update_success": "La configuration de vos dimensions a été mise à jour avec succès.",
    "maxNumberOfDimensionsReached": "Vous ne pouvez ajouter que 10 dimensions"
  },
  "de": {
    "dimensions": "Maße",
    "select_dimension": "Wählen Sie die Dimension aus, die Sie anpassen möchten.",
    "values": "Werte",
    "edit_values": "Bearbeiten Sie die möglichen Werte für Ihre Regeln.",
    "rules": "Regeln",
    "edit_rules": "Bearbeiten Sie die Regeln, die Ihre Dimension definieren.",
    "confirmation": "Bestätigung",
    "confirmation_description": "Fahren Sie mit der Bearbeitung fort oder bestätigen Sie Ihre Änderungen.",
    "name": "Name",
    "edit": "Bearbeiten",
    "add_dimension": "Dimension hinzufügen",
    "value_label": "Wert {Index}",
    "add_value": "Mehrwert",
    "default_value": "Standardwert",
    "default_value_tooltip": "Auf Einträge angewendeter Wert, die keiner Regel entsprechen",
    "add_expression": "Satz hinzufügen",
    "add_assignment": "Zuordnung hinzufügen",
    "add_rule": "Regel hinzufügen",
    "previous_step": "Vorheriger Schritt",
    "next_step": "nächster Schritt",
    "submit": "Bestätigen",
    "confirmation_instruction": "Wenn Sie Ihre Abmessungen weiter bearbeiten möchten, klicken Sie auf Bearbeiten und wiederholen Sie den Vorgang, bis Sie zur Bestätigung bereit sind. Wenn Sie fertig sind, können Sie Ihre Änderungen bestätigen, um sie zu speichern.",
    "page": "Buchseite",
    "keyword": "Stichwort",
    "contains": "Enthält",
    "starts_with": "Beginnt mit",
    "ends_with": "Beendet von",
    "equals": "Ist gleich",
    "delete_confirmation": "Möchten Sie diese Dimension wirklich löschen?",
    "update_success": "Ihre Dimensionskonfiguration wurde erfolgreich aktualisiert.",
    "maxNumberOfDimensionsReached": "Sie können nur 10 Dimensionen hinzufügen"
  }
}
</i18n>
