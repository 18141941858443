<template>
  <el-tabs
    v-model="activeTab"
    :stretch="true"
  >
    <el-tab-pane name="newLost">
      <div slot="label">
        {{ $t("new_lost") }}
        <span v-if="numberNewBacklinks != null || numberLostBacklinks != null">
          ({{ formatNumberBacklinks(numberNewBacklinks)
          }}<i class="fas fa-arrow-up"></i>
          {{ formatNumberBacklinks(numberLostBacklinks)
          }}<i class="fas fa-arrow-down"></i>)
        </span>
        <span v-else>(<i class="fas fa-spinner fa-spin"></i>)</span>
      </div>
      <new-lost
        @new-backlinks="(count) => (numberNewBacklinks = count)"
        @lost-backlinks="(count) => (numberLostBacklinks = count)"
      >
      </new-lost>
    </el-tab-pane>
    <el-tab-pane name="topBacklinks">
      <div slot="label">{{ $t("top_backlinks") }}</div>
      <top-backlinks :current-user="currentUser"> </top-backlinks>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import TopBacklinks from "@/pages/Netlinking/Grids/TopBacklinks";
import NewLost from "@/pages/Netlinking/Grids/NewLost";

export default {
  components: {
    TopBacklinks,
    NewLost,
  },
  props: {
    currentUser: Object,
  },
  data() {
    return {
      activeTab: "newLost",
      numberNewBacklinks: null,
      numberLostBacklinks: null,
    };
  },
  methods: {
    formatNumberBacklinks(value) {
      if (value >= 1000) {
        return "1000+";
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.fa-arrow-up {
  color: $--color-success;
}

.fa-arrow-down {
  color: $--color-danger;
}
</style>

<i18n>
{
  "en": {
    "new_lost": "New / Lost",
    "top_backlinks": "Top Backlinks"
  },
  "fr": {
    "new_lost": "Nouveaux / Perdus",
    "top_backlinks": "Top Backlinks"
  },
  "de": {
    "new_lost": "Neu / Verloren",
    "top_backlinks": "Top-Backlinks"
  }
}
</i18n>
