<template>
  <div>
    <app-introduction :title="$t('addKeywords')"></app-introduction>

    <div class="container">
      <el-card>
        <el-form
          :model="form"
          :rules="rules"
          ref="keywordsForm"
          label-width="50%"
          label-position="left"
        >
          <el-form-item
            :label="$t('addTags')"
            prop="tags"
          >
            <el-select
              style="display: block"
              v-model="form.tags"
              multiple
              filterable
              default-first-option
              allow-create
            >
              <el-option
                v-for="tag in tagOptions"
                :key="tag[1].name"
                :label="tag[0]"
                :value="tag[1].name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-divider></el-divider>
          <small style="margin-left: 50%">{{ $t("hintMessage") }}</small>
          <el-form-item
            :label="$t('keywords')"
            prop="keywords"
          >
            <el-input
              type="textarea"
              :rows="6"
              :placeholder="$t('keywordPlaceholder')"
              v-model="form.keywords"
            >
            </el-input>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item
            :label="$t('updateFrequency')"
            prop="updateFrequency"
          >
            <el-select
              style="display: block"
              v-model="form.updateFrequency"
            >
              <el-option
                :label="$t('weekly')"
                :value="'WEEKLY'"
              />
              <el-option
                :label="$t('daily')"
                :value="'DAILY'"
              />
            </el-select>
          </el-form-item>
          <div class="form-actions">
            <div class="text-right">
              <el-button
                type="success"
                @click="validateFormAndSend('keywordsForm')"
                :loading="loading"
                >{{ $t("insertKeywords") }}</el-button
              >
            </div>
          </div>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import AppIntroduction from "@/components/AppIntroduction";

import { RepositoryFactory } from "@/services/repositoryFactory";
const ImportKeywordsRepository = RepositoryFactory.get(
  "importKeywordsRepository"
);

export default {
  components: {
    AppIntroduction,
  },
  props: {
    tagOptions: Array,
    urlForm: String,
  },
  data() {
    return {
      loading: false,
      form: {
        tags: [],
        keywords: "",
        updateFrequency: "WEEKLY",
      },
      faultyWords: [],
      rules: {
        keywords: [
          {
            required: true,
            message: "Please input at least one keyword",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    isCorrectWord(words) {
      "";
      return (
        /^[\p{L}\p{digit}':&*/.+_$€#\[\]"\n+\s-]*$/giu.test(words) &&
        words.length < 150
      );
    },
    validateFormAndSend(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) this.importKeywords();
        else {
          return false;
        }
      });
    },
    importKeywords() {
      try {
        let correctlists = "";
        this.faultyWords = [];
        let words = this.form.keywords.split("\n");
        words = words.map((word) => word.replace("’", "'"));
        words.forEach((word) => {
          if (!this.isCorrectWord(word)) this.faultyWords.push(word);
          else correctlists += word + "\n";
        });
        let request = {
          keywords_import: {
            tag_name: this.form.tags,
            keywords_text: correctlists,
            update_frequency: this.form.updateFrequency,
          },
          banned_keywords: this.faultyWords,
        };
        this.loading = true;
        ImportKeywordsRepository.importKeywords(request, this.urlForm)
          .then((response) => {
            this.loading = false;
            document.documentElement.innerHTML = response;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.error(e);
        this.$message.error(e);
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "addKeywords": "Add / Edit keywords manually",
    "addTags": "Add tags",
    "daily": "Daily (1 keyword counts for 7)",
    "insertKeywords": "Add these keywords",
    "keywords": "Keywords",
    "keywordPlaceholder": "Insert one keyword per line",
    "updateFrequency": "Update frequency",
    "weekly": "Weekly",
    "error_incorrect_keyword": "Your inputs contain a keyword with special caracters",
    "hintMessage": "Remember to avoid special characters such as \": ; , ® ! @ % ^ () = {} ~ ` <> ? \\ | ...\""
  },
  "fr": {
    "addKeywords": "Ajout / Édition de mots clés",
    "addTags": "Configurez des groupes de mots clés",
    "daily": "Quotidienne (1 mot clé compte pour 7)",
    "insertKeywords": "Insérer ces mots clés",
    "keywords": "Mots clés",
    "keywordPlaceholder": "Placez un mot clé par ligne",
    "updateFrequency": "Fréquence de mise à jour",
    "weekly": "Hebdomadaire",
    "error_incorrect_keyword": "Vos entrées contiennent un ou plusieurs mot-clés avec des caractères spéciaux",
    "hintMessage": "Pensez a éviter les caractères spéciaux tel que \": ; , ® ! @ % ^ () = {} ~ ` <> ? \\ | ...\""
  },
  "de": {
    "addKeywords": "Schlüsselwörter hinzufügen / bearbeiten",
    "addTags": "Keyword-Gruppen konfigurieren",
    "daily": "Täglich (1 Schlüsselwort zählt als 7)",
    "insertKeywords": "Fügen Sie diese Schlüsselwörter ein",
    "keywords": "Schlüsselwörter",
    "keywordPlaceholder": "Platzieren Sie ein Schlüsselwort pro Zeile",
    "updateFrequency": "Aktualisierungsfrequenz",
    "weekly": "Wöchentlich",
    "error_incorrect_keyword": "Ihre Einträge enthalten ein oder mehrere Schlüsselwörter mit Sonderzeichen",
    "hintMessage": "Denken Sie daran, Sonderzeichen wie \": ; , ® ! @ % ^ () = {} ~ ` <> ? \\ | ...\" zu vermeiden."
  }
}
</i18n>
