<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      top="5vh"
    >
      <div
        slot="title"
        class="flex-box"
      >
        <h2 class="title">
          <span style="font-weight: bold">{{ $t("textRedaction") }}</span> -
          {{ data.sourceDomain ? data.sourceDomain.url + " - " : "" }}
          {{ data.keyword.text }}
        </h2>
        <div class="actions">
          <el-button
            size="small"
            @click="openInNewTab()"
            ><i class="fas fa-external-link-alt"></i>
            {{ $t("openAsPage") }}</el-button
          >
          <el-tooltip
            v-if="data.actions.public"
            class="item"
            effect="dark"
            :content="$t('copyPublicLink')"
            placement="top-start"
          >
            <span>
              <el-button
                size="small"
                @click="copyToClipboard()"
                ><i class="fas fa-link"></i> {{ $t("copyLink") }}</el-button
              >
            </span>
          </el-tooltip>
        </div>
      </div>
      <backlink-text
        v-if="dialogVisible && backlinkText"
        :data="backlinkText"
        :links="backlinkTextLinks"
        :backlink-data="data"
        @update-text="updateText($event)"
        @update-backlink="$emit('update-backlink', $event)"
      >
      </backlink-text>
    </el-dialog>
  </span>
</template>

<script>
import BacklinkText from "@/components/CampaignManager/BacklinkText";

import { mapState } from "vuex";

export default {
  props: {
    value: Boolean,
    data: Object,
  },
  components: {
    BacklinkText,
  },
  data() {
    return {
      backlinkText: null,
      backlinkTextLinks: null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    ...mapState(["study"]),
  },
  methods: {
    updateText(details) {
      this.isLoadingText = true;
      const link = details.link;
      this.$api[link.method.toLowerCase()](link.href, details.entity)
        .then((response) => {
          this.isLoadingText = false;
          this.backlinkText = response.data.data.attributes;
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.log(error);
        });
    },
    openInNewTab() {
      window.open(
        window.location.origin +
          "/a/" +
          this.study.id +
          "/netlinking-campaigns/" +
          this.data.netlinkingCampaignId +
          "/backlinks/" +
          this.data.id +
          "/text"
      );
    },
    readText() {
      if (this.data.actions.text && this.data.actions.text.read) {
        this.isLoadingText = true;
        const link = this.data.actions.text.read;
        this.$api[link.method.toLowerCase()](link.href)
          .then((response) => {
            this.isLoadingText = false;
            this.backlinkText = response.data.data.attributes;
            this.backlinkTextLinks = response.data.links;
          })
          .catch((error) => {
            this.isLoadingText = false;
            console.error(error);
          });
      }
    },
    copyToClipboard() {
      const el = document.createElement("textarea");
      el.value = window.location.origin + this.data.actions.public.href;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message({
        message: this.$i18n.t("linkCopiedToClipBoard"),
        type: "success",
        duration: 2000,
        offset: 80,
      });
    },
  },
  mounted() {
    this.readText();
  },
  watch: {
    data() {
      this.readText();
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;

  .title {
    font-size: 1.5rem;
  }

  .actions {
    margin-left: auto;
    margin-right: 2rem;

    .dropdown-header {
      font-size: 1.3rem !important;
      padding-top: 0rem;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "textRedaction": "Text redaction",
    "openAsPage": "Open as page",
    "copyLink": "Public link",
    "linkCopiedToClipBoard": "Link copied to clipboard",
    "saveMessageDialog": "You haven't saved your recent changes, do you want to save them before closing ?",
    "copyPublicLink": "Copy article public link"
  },
  "fr": {
    "textRedaction": "Rédaction de l'article",
    "openAsPage": "Ouvrir dans un onglet",
    "copyLink": " Lien public",
    "linkCopiedToClipBoard": "Le lien a été copié",
    "saveMessageDialog": "Vous n'avez pas enregistré vos récentes modifications, voulez vous les enregistrer avant de fermer ?",
    "copyPublicLink": "Copier le lien public de l'article"
  },
  "de": {
    "textRedaction": "Schreiben des Artikels",
    "openAsPage": "In einem Tab öffnen",
    "copyLink": "öffentlicher Link",
    "linkCopiedToClipBoard": "Der Link wurde kopiert",
    "saveMessageDialog": "Sie haben Ihre letzten Änderungen nicht gespeichert. Möchten Sie sie vor dem Schließen speichern?",
    "copyPublicLink": "Kopieren Sie den öffentlichen Link des Artikels"
  }
}
</i18n>
