<template>
  <span>
    <el-tooltip
      placement="top"
      transition="el-fade-in"
      v-for="(richResult, index) in richResults"
      v-bind:key="index"
    >
      <div
        slot="content"
        class="center"
      >
        <span
          ><b>{{ richResultName(richResult.snippet) }}</b></span
        >
        <br />
        <span
          v-if="
            richResult.isPositionned &&
            isCompetitorPositionned(richResult.snippet)
          "
        >
          {{ $t("you_are_both_positionned_on_this_snippet") }}</span
        >
        <span v-else-if="isCompetitorPositionned(richResult.snippet)">
          {{ $t("your_competitor_is_positionned_on_this_snippet") }}</span
        >
        <span v-else-if="richResult.isPositionned">
          {{ $t("you_are_positionned_on_this_snippet") }}</span
        >
      </div>
      <i
        class="fas"
        v-if="
          !['featured_snippet', 'other', 'related'].includes(
            richResult.snippet.toLowerCase()
          )
        "
        v-bind:class="[
          richResultClass(richResult.snippet),
          isHostPositionnedClass(richResult),
        ]"
      ></i>
    </el-tooltip>
  </span>
</template>

<script>
export default {
  computed: {
    richResults() {
      return this.params.value;
    },
    richResultsCompetitor() {
      if (this.params.data.richResultsCompetitor)
        return this.params.data.richResultsCompetitor;
      else return [];
    },
  },
  methods: {
    isCompetitorPositionned(snippet) {
      if (this.params.data.richResultsCompetitor) {
        const richResult = this.richResultsCompetitor.find(
          (rr) => rr.snippet == snippet
        );
        return richResult && richResult.isPositionned;
      } else {
        return false;
      }
    },
    richResultName(richResult) {
      let string = richResult;
      string = string.replace(/_/g, " ").toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    richResultClass(richResult) {
      switch (richResult.toLowerCase()) {
        case "images":
          return "fa-images";
        case "answer_box":
          return "fa-bullhorn";
        case "carousel":
          return "fa-list";
        case "people_also_ask":
          return "fa-question-circle";
        case "knowledge_graph":
          return "fa-brain";
        case "video":
          return "fa-video";
        case "videos":
          return "fa-video";
        case "local_pack":
          return "fa-street-view";
        case "google_review":
          return "fa-star-half-alt";
        case "shopping":
          return "fa-shopping-cart";
        case "twitter":
          return "fa-twitter";
        case "top_stories":
          return "fa-newspaper";
        case "map":
          return "fa-globe-africa";
        case "google_flights":
          return "fa-plane";
        case "jobs":
          return "fa-user-tie";
        case "paid":
          return "fa-euro-sign";
      }
    },
    isHostPositionnedClass(richResult) {
      if (
        this.isCompetitorPositionned(richResult.snippet) &&
        richResult.isPositionned
      )
        return "both-present";
      if (this.isCompetitorPositionned(richResult.snippet))
        return "competitor-present";
      if (richResult.isPositionned) return "host-present";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.center {
  text-align: center;
}

.host-present {
  color: #32cd32;
}

.both-present {
  color: #ff8c00;
}

.competitor-present {
  color: #ff0000;
}

.fas {
  margin-right: 0.15rem;
}
</style>

<i18n>
{
  "en": {
    "you_are_both_positionned_on_this_snippet": "You are both positionned on this snippet",
    "your_competitor_is_positionned_on_this_snippet": "Your competitor is positionned on this snippet",
    "you_are_positionned_on_this_snippet": "You are positionned on this snippet"
  },
  "fr": {
    "you_are_positionned_on_this_snippet": "Vous êtes positionnés sur ce snippet",
    "you_are_both_positionned_on_this_snippet": "Vous êtes tous les deux positionnés sur ce snippet",
    "your_competitor_is_positionned_on_this_snippet": "Votre concurrent est positioné sur ce snippet"
  },
  "de": {
    "you_are_positionned_on_this_snippet": "Sie befinden sich auf diesem Snippet",
    "you_are_both_positionned_on_this_snippet": "Sie befinden sich beide auf diesem Snippet",
    "your_competitor_is_positionned_on_this_snippet": "Ihr Konkurrent ist auf diesem Snippet positioniert"
  }
}
</i18n>
