<template>
  <div>
    <div class="actions">
      <el-button
        plain
        type="primary"
        @click="excelExport"
        >{{ $t("excel_export") }}</el-button
      >
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      :suppressContextMenu="true"
      :context="context"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
import LinkRenderer from "@/components/gridRenderers/LinkRenderer";
const ConversionsRepository = RepositoryFactory.get("conversions");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

export default {
  mixins: [agGridMixin],
  props: {
    accountId: Number,
    dimensionsData: Array,
    filters: Array,
    period: Array,
    comparativePeriod: Array,
    goals: Array,
    conversionMonitoringType: String,
  },
  components: {
    AgGridVue,
    LinkRenderer,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      link: null,
      columnApi: null,
      context: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    loadGrid() {
      this.setColumnDefs();
      this.fetchData();
    },
    setColumnDefs() {
      let currencyFormatter = (params) => {
        if (params.value) {
          return this.currencyFormat(params.value);
        } else {
          if (this.$i18n.locale === "fr") return "0 €";
          else return "€0";
        }
      };
      this.columnDefs = [
        {
          headerName: this.$i18n.t("landing_page"),
          field: "landing_page",
          minWidth: 300,
          filter: "agTextColumnFilter",
          cellRendererFramework: "LinkRenderer",
        },
      ];

      let conversionsColumnDef = {
        field: "main_conversions",
        aggFunc: "sum",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        filterParams: {
          defaultOption: "greaterThan",
        },
        suppressSizeToFit: true,
      };

      if (this.conversionMonitoringType === "goals_completions_data") {
        conversionsColumnDef.headerName = this.$i18n.t("conversions");
        conversionsColumnDef.valueFormatter = numberFormatter;
      } else if (this.conversionMonitoringType === "e_commerce_data") {
        conversionsColumnDef.headerName = this.$i18n.t("revenue");
        conversionsColumnDef.valueFormatter = currencyFormatter;
      }

      this.columnDefs.push(conversionsColumnDef);

      if (this.comparativePeriod !== null && this.comparativePeriod.length) {
        let comparativeConversionsColumnDef = {
          field: "comparative_conversions",
          aggFunc: "sum",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        };

        let evolutionColumnDef = {
          field: "evolution",
          aggFunc: "sum",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        };

        if (this.conversionMonitoringType === "goals_completions_data") {
          comparativeConversionsColumnDef.headerName = this.$i18n.t(
            "comparative_conversions"
          );
          comparativeConversionsColumnDef.valueFormatter = numberFormatter;
          evolutionColumnDef.valueFormatter = numberFormatter;
        } else if (this.conversionMonitoringType === "e_commerce_data") {
          comparativeConversionsColumnDef.headerName = this.$i18n.t(
            "comparative_revenue"
          );
          comparativeConversionsColumnDef.valueFormatter = currencyFormatter;
          evolutionColumnDef.valueFormatter = currencyFormatter;
        }

        this.columnDefs.push(comparativeConversionsColumnDef);
        this.columnDefs.push(evolutionColumnDef);
      }

      if (
        this.comparativePeriod === null ||
        this.comparativePeriod.length === 0
      ) {
        let seaConversionsColumnDef = {
          field: "main_sea_conversions",
          valueFormatter: currencyFormatter,
          aggFunc: "sum",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        };

        if (this.conversionMonitoringType === "goals_completions_data") {
          seaConversionsColumnDef.headerName = this.$i18n.t("sea_conversions");
          seaConversionsColumnDef.valueFormatter = numberFormatter;
        } else if (this.conversionMonitoringType === "e_commerce_data") {
          seaConversionsColumnDef.headerName = this.$i18n.t("sea_revenue");
          seaConversionsColumnDef.valueFormatter = currencyFormatter;
        }

        this.columnDefs.push(seaConversionsColumnDef);

        this.columnDefs.push({
          headerName: this.$i18n.t("cost_sea"),
          field: "main_sea_cost",
          cellRenderer: (params) => {
            if (params.value) {
              return this.currencyFormat(params.value);
            } else {
              if (this.$i18n.locale === "fr") return "0 €";
              else return "€0";
            }
          },
          aggFunc: "sum",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        });
      }
    },
    currencyFormat(value) {
      return this.$options.filters.formatCurrency(value, this.$i18n.locale);
    },
    fetchData() {
      ConversionsRepository.createConversionGrid(
        this.accountId,
        this.filters,
        "LandingPage",
        this.period,
        this.comparativePeriod,
        this.goals
      )
        .then((data) => {
          this.rowData = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    excelExport() {
      const columnKeys = this.columnApi
        .getAllDisplayedColumnGroups()
        .map((columnGroup) => columnGroup.colId);
      const params = {
        skipHeader: false,
        columnKeys: columnKeys,
        onlySelected: false,
        fileName: "Conversion Pages Export - SmartKeyword",
        sheetName: "Conversion Pages",
      };

      this.gridApi.exportDataAsExcel(params);
    },
    showDialog(value) {
      this.$emit("show-brief", value);
    },
  },
  beforeMount() {
    this.loadGrid();
    this.context = {
      showDialog: this.showDialog,
    };
  },
  watch: {
    $props: {
      handler: "loadGrid",
      deep: true,
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.actions {
  text-align: right;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "excel_export": "Export Excel",
    "keyword": "Keyword",
    "landing_page": "Landing Page",
    "conversions": "SEO conversions",
    "revenue": "SEO Revenue",
    "comparative_conversions": "Comparative conversions",
    "comparative_revenue": "Comparative revenue",
    "sea_conversions": "SEA Conversions",
    "sea_revenue": "SEA Revenue",
    "cost_sea": "SEA Costs",
    "evolution": "Evolution"
  },
  "fr": {
    "excel_export": "Export Excel",
    "keyword": "Mots clé",
    "landing_page": "Page",
    "conversions": "Conversions SEO",
    "revenue": "Chiffre d'affaires SEO",
    "comparative_conversions": "Conversions comparatifs",
    "comparative_revenue": "Chiffre d'affaires comparatifs",
    "sea_conversions": "Conversions SEA",
    "sea_revenue": "Chiffre d'affaires SEA",
    "cost_sea": "Coûts SEA",
    "evolution": "Evolution"
  },
  "de": {
    "excel_export": "Excel exportieren",
    "keyword": "Schlüsselwörter",
    "landing_page": "Buchseite",
    "conversions": "Conversion-SEO",
    "revenue": "SEO-Einnahmen",
    "comparative_conversions": "Vergleichende Umrechnungen",
    "comparative_revenue": "Vergleichsweiser Umsatz",
    "sea_conversions": "Konvertierungen SEA",
    "sea_revenue": "SEA-Umsatz",
    "cost_sea": "SEA-Kosten",
    "evolution": "Evolution"
  }
}
</i18n>
