<template>
  <div>
    <el-select
      :disabled="!!studyId"
      v-model="selectedCountryLanguage"
      :placeholder="$t('country')"
    >
      <el-option
        v-for="countryLanguage in countriesLanguages"
        :key="searchEngineLabel(countryLanguage)"
        :label="searchEngineLabel(countryLanguage)"
        :value="searchEngineLabel(countryLanguage)"
      >
      </el-option>
    </el-select>
    <el-checkbox-group
      v-model="selectedDevices"
      :min="1"
    >
      <el-checkbox :label="'DESKTOP'">{{ $i18n.t("desktop") }}</el-checkbox>
      <el-checkbox :label="'MOBILE'">{{ $i18n.t("mobile") }}</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    searchEngines: Array,
    value: Array,
    studyId: Number,
  },
  computed: {
    countriesLanguages() {
      return this.searchEngines
        .map((searchEngine) => {
          return {
            countryName: searchEngine.countryName,
            languageName: searchEngine.languageName,
          };
        })
        .filter(
          (value, index, array) =>
            array.findIndex(
              (t) =>
                t.countryName === value.countryName &&
                t.languageName === value.languageName
            ) === index
        )
        .sort((a, b) => a.countryName.localeCompare(b.countryName));
    },
    selectedCountryLanguage: {
      get() {
        let countryLanguage = this.value.map((searchEngine) => {
          return {
            countryName: searchEngine.countryName,
            languageName: searchEngine.languageName,
          };
        })[0];
        return this.searchEngineLabel(countryLanguage);
      },
      set(countryLanguage) {
        this.$emit(
          "input",
          this.selectedSearchEngines(countryLanguage, this.selectedDevices)
        );
      },
    },
    selectedDevices: {
      get() {
        if (this.value.length === 0) {
          return ["MOBILE"];
        }
        return [
          ...new Set(
            this.value.map((searchEngine) => {
              return searchEngine.device;
            })
          ),
        ];
      },
      set(devices) {
        this.$emit(
          "input",
          this.selectedSearchEngines(this.selectedCountryLanguage, devices)
        );
      },
    },
  },
  methods: {
    selectedSearchEngines(countryLanguage, devices) {
      const countryAndLanguage = countryLanguage.split(" - ");
      return this.searchEngines
        .filter(
          (searchEngine) =>
            searchEngine.countryName === countryAndLanguage[0] &&
            searchEngine.languageName === countryAndLanguage[1]
        )
        .filter((searchEngine) => {
          return devices.includes(searchEngine.device);
        });
    },
    searchEngineLabel(searchEngine) {
      if (searchEngine)
        return searchEngine.countryName + " - " + searchEngine.languageName;
    },
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "desktop": "Desktop",
    "mobile": "Mobile",
    "country": "Country (Language)"
  },
  "fr": {
    "desktop": "Bureau",
    "mobile": "Mobile",
    "country": "Pays (Langue)"
  },
  "de": {
    "desktop": "Büro",
    "mobile": "Handy, Mobiltelefon",
    "country": "Zahlt (Sprache)"
  }
}
</i18n>
