<template>
  <div class="graph">
    <highcharts
      ref="highchart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import { epochDayToDateTime } from "@/javascripts/dateHelpers";

export default {
  props: {
    averageGrades: Object,
    crawl: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          polar: true,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          tickmarkPlacement: "on",
          lineWidth: 0,
        },
        yAxis: {
          gridLineInterpolation: "polygon",
          lineWidth: 0,
          min: 0,
          max: 100,
        },
        series: {
          data: [],
          pointPlacement: "on",
        },
        title: {
          text: "",
          useHTML: true,
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      if (this.averageGrades) {
        this.chartOptions.series.data = [];
        this.chartOptions.series.name = this.$i18n.t("average");
        this.chartOptions.xAxis.categories = [];

        this.chartOptions.xAxis.categories.push(this.$i18n.t("content"));
        if (this.crawl || this.averageGrades.avgContent) {
          if (this.averageGrades.avgContent || this.crawl.httpStatus == 200) {
            this.chartOptions.series.data.push({
              y: this.averageGrades.avgContent,
            });
          } else {
            this.chartOptions.series.data.push({
              y: NaN,
            });
          }
        } else {
          this.chartOptions.series.data.push({
            y: NaN,
          });
        }

        this.chartOptions.xAxis.categories.push(this.$i18n.t("technical"));
        if (this.crawl || this.averageGrades.avgTechnical) {
          if (this.averageGrades.avgTechnical || this.crawl.httpStatus == 200) {
            this.chartOptions.series.data.push({
              y: this.averageGrades.avgTechnical,
            });
          } else {
            this.chartOptions.series.data.push({
              y: NaN,
            });
          }
        } else {
          this.chartOptions.series.data.push({
            y: NaN,
          });
        }

        this.chartOptions.xAxis.categories.push(this.$i18n.t("netlinking"));
        this.chartOptions.series.data.push({
          y: this.averageGrades.avgNetlinking,
        });

        this.chartOptions.xAxis.categories.push(this.$i18n.t("serp"));
        if (this.crawl || this.averageGrades.avgTagsUrl) {
          if (this.averageGrades.avgTagsUrl || this.crawl.httpStatus == 200) {
            this.chartOptions.series.data.push({
              y: this.averageGrades.avgTagsUrl,
            });
          } else {
            this.chartOptions.series.data.push({
              y: NaN,
            });
          }
        } else {
          this.chartOptions.series.data.push({
            y: NaN,
          });
        }
      } else {
        this.chartOptions.series.data = [];
      }
    },
  },
  mounted() {
    this.updateSeries();
  },
  watch: {
    averageGrades() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.graph {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<i18n>
{
  "en": {
    "content": "Content",
    "technical": "Technical",
    "netlinking": "Netlinking",
    "serp": "SERP",
    "average": "Average"
  },
  "fr": {
    "content": "Contenu",
    "technical": "Technique",
    "netlinking": "Netlinking",
    "serp": "SERP",
    "average": "Moyenne"
  },
  "de": {
    "content": "Inhalt",
    "technical": "Technik",
    "netlinking": "Netzverlinkung",
    "serp": "SERP",
    "average": "Bedeuten"
  }
}
</i18n>
