<template>
  <el-card class="dashboard page-breaker no-border-on-print">
    <div class="flex-container">
      <h2>
        {{ $t("trafic") }}
        <span class="date-period">
          <span
            >{{ periodDatesShown[0] | formatDateFromLocale }} {{ $t("to") }}
            {{ periodDatesShown[1] | formatDateFromLocale }}</span
          >
          <span v-if="comparisonPeriodDates[0]"
            >vs {{ comparisonPeriodDatesShown[0] | formatDateFromLocale }}
            {{ $t("to") }}
            {{ comparisonPeriodDatesShown[1] | formatDateFromLocale }}</span
          >
        </span>
      </h2>
      <el-button
        v-on:click="linkToTrafic"
        class="no-print"
      >
        {{ $t("analyseDetail") }}
      </el-button>
    </div>
    <traffic-metrics
      :metrics="traficMetrics"
      v-loading="isLoading"
      :dimensionsData="dimensionsData"
    ></traffic-metrics>
    <el-card class="chart">
      <traffic-loader
        v-if="frequency"
        :dimensions-data="dimensionsData"
        :study-id="accountId"
        :period="periodDates"
        :comparativePeriod="comparisonPeriodDates"
        :frequency="frequency"
        #default="trafficLoaderProps"
      >
        <traffic-chart
          :frequency="trafficLoaderProps.frequency"
          :main-series="trafficLoaderProps.mainSeries"
          :traffic-groups="trafficLoaderProps.trafficGroups"
        ></traffic-chart>
      </traffic-loader>
    </el-card>
  </el-card>
</template>
<script>
import { subDays, subMonths, subYears, subWeeks } from "date-fns";

import { RepositoryFactory } from "@/services/repositoryFactory";
const TrafficsRepository = RepositoryFactory.get("traffics");
import TrafficMetrics from "@/pages/Traffic/Metrics";
import TrafficChart from "@/pages/Traffic/Evolution/Chart";

import TrafficLoader from "@/components/TrafficLoader";

export default {
  components: {
    TrafficLoader,
    TrafficMetrics,
    TrafficChart,
  },
  props: {
    accountId: Number,
    accountConfigurationJson: Object,
    dimensionsData: Array,
  },
  data() {
    return {
      periodDates: [],
      periodDatesShown: [],
      comparisonPeriodDates: [],
      comparisonPeriodDatesShown: [],
      frequency: null,
      traficMetrics: null,
      comparisonPeriod: null,
      isLoading: false,
      accountConfiguration: {},
    };
  },
  methods: {
    fetchTraficMetrics() {
      this.isLoading = true;
      TrafficsRepository.createTrafficMetrics(
        this.accountId,
        null,
        this.periodDates,
        this.comparisonPeriodDates
      )
        .then((data) => {
          this.traficMetrics = data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setPeriod() {
      const today = new Date();
      const endDate = new Date(today.setDate(today.getDate() - 3));
      let startDate = null;
      if (this.accountConfiguration.period == 7)
        startDate = subWeeks(endDate, 1);
      else if (this.accountConfiguration.period == 30)
        startDate = subMonths(endDate, 1);
      else if (this.accountConfiguration.period == 90)
        startDate = subMonths(endDate, 3);
      else if (this.accountConfiguration.period == 180)
        startDate = subMonths(endDate, 6);
      else if (this.accountConfiguration.period == 365)
        startDate = subYears(endDate, 1);

      this.periodDatesShown[0] =
        ("0" + startDate.getDate()).slice(-2) +
        "/" +
        ("0" + (startDate.getMonth() + 1)).slice(-2) +
        "/" +
        startDate.getFullYear();
      this.periodDatesShown[1] =
        ("0" + endDate.getDate()).slice(-2) +
        "/" +
        ("0" + (endDate.getMonth() + 1)).slice(-2) +
        "/" +
        endDate.getFullYear();

      return [startDate, endDate];
    },
    setComparisonPeriod() {
      let endDate = null;
      let startDate = null;
      if (
        this.accountConfiguration.cmpPeriod === "last_period" ||
        (this.accountConfiguration.period === 30 &&
          this.accountConfiguration.cmpPeriod === "month") ||
        (this.accountConfiguration.period === 365 &&
          this.accountConfiguration.cmpPeriod === "year")
      ) {
        const numberOfDays =
          (this.periodDates[1] - this.periodDates[0]) / (1000 * 60 * 60 * 24);
        endDate = new Date(this.periodDates[1]);
        endDate.setDate(this.periodDates[1].getDate() - numberOfDays - 1);
        startDate = new Date(this.periodDates[0]);
        startDate.setDate(this.periodDates[0].getDate() - numberOfDays - 1);
      } else if (this.accountConfiguration.cmpPeriod === "month") {
        endDate = subMonths(this.periodDates[1], 1);
        startDate = subMonths(this.periodDates[0], 1);
      } else if (this.accountConfiguration.cmpPeriod === "year") {
        endDate = subYears(this.periodDates[1], 1);
        startDate = subYears(this.periodDates[0], 1);
      } else if (this.accountConfiguration.cmpPeriod === "52weeks") {
        const mainPeriodEnd = this.periodDates[1];
        const mainPeriodStart = this.periodDates[0];
        let start_dow = new Date(mainPeriodStart).getDay();
        let end_dow = new Date(mainPeriodEnd).getDay();
        endDate = subYears(mainPeriodEnd, 1);
        startDate = subYears(mainPeriodStart, 1);
        while (endDate.getDay() != end_dow) {
          endDate = subDays(endDate, -1);
        }
        while (startDate.getDay() != start_dow) {
          startDate = subDays(startDate, -1);
        }
      }
      this.comparisonPeriodDatesShown[0] =
        ("0" + startDate.getDate()).slice(-2) +
        "/" +
        ("0" + (startDate.getMonth() + 1)).slice(-2) +
        "/" +
        startDate.getFullYear();
      this.comparisonPeriodDatesShown[1] =
        ("0" + endDate.getDate()).slice(-2) +
        "/" +
        ("0" + (endDate.getMonth() + 1)).slice(-2) +
        "/" +
        endDate.getFullYear();
      return [startDate, endDate];
    },
    setAccountConfiguration() {
      this.accountConfiguration = {};
      this.accountConfiguration.cmpPeriod =
        this.accountConfigurationJson.data.attributes.cmpPeriod;
      this.accountConfiguration.conversionMonitoringType =
        this.accountConfigurationJson.data.attributes.conversionMonitoringType;
      this.accountConfiguration.frequency =
        this.accountConfigurationJson.data.attributes.frequency;
      this.accountConfiguration.graphType =
        this.accountConfigurationJson.data.attributes.graphType;
      this.accountConfiguration.period =
        this.accountConfigurationJson.data.attributes.period;
    },
    linkToTrafic() {
      window.open(`/a/${this.accountId}/traffic`);
    },
  },
  created() {
    if (this.accountConfigurationJson) {
      this.setAccountConfiguration();
      this.periodDates = this.setPeriod();
      this.frequency = this.accountConfiguration.frequency;
      if (
        this.accountConfiguration.cmpPeriod &&
        this.accountConfiguration.cmpPeriod !== ""
      )
        this.comparisonPeriodDates = this.setComparisonPeriod();
      this.fetchTraficMetrics();
    }
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}

.metrics {
  margin-top: 1rem;

  .el-col {
    display: flex;
    flex-direction: column;
  }

  .el-card {
    display: flex;
    flex-grow: 1;

    ::v-deep .el-card__body {
      width: 100%;
    }
  }
}

.date-period {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.chart {
  margin-top: 1rem;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.dashboard {
  margin-bottom: 3rem;
}

@media print {
  .no-print {
    display: none !important;
  }
  .date-period {
    margin-left: 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .no-border-on-print {
    border-style: none;
  }
}
</style>
<i18n>
{
  "en": {
    "trafic": "Traffic",
    "analyseDetail": "Details",
    "to": "to"
  },
  "fr": {
    "analyseDetail": "Analyser en détail",
    "trafic": "Trafic",
    "to": "au"
  },
  "de": {
    "analyseDetail": "Analysieren Sie im Detail",
    "trafic": "Verkehr",
    "to": "Bei"
  }
}
</i18n>
