<template>
  <div
    class="d-flex flex-row align-items-center mt-2 mb-2"
    :class="{
      'justify-content-end': !isCreate,
      'justify-content-start': isCreate,
    }"
  >
    <div v-if="isCreate">
      <span class="mt-2 mb-2 mr-3">{{ $t("cantLogText") }}</span>
      <el-button
        plain
        type="primary"
        @click="showViewDialog()"
      >
        {{ $t("connectManually") }}
      </el-button>
    </div>
    <div v-else>
      <el-button
        class="mx-2 remove-outline"
        type="text"
        @click="showViewDialog()"
      >
        {{ $t("update") }}
      </el-button>
    </div>

    <add-view-dialog
      v-if="hasLoadedConfigurations"
      v-loading="isLoadingConfigurations"
      v-model="dialogVisible"
      :attributes="attributes"
      :type="type"
      :isCreate="isCreate"
      :isLoading="isLoading"
      :showError="showError"
      @close-view-dialog="closeDialog($event)"
      @send-ga-configuration="getEmittedGaData($event)"
    >
    </add-view-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AddViewDialog from "@/components/admin/GoogleAnalytics/AddViewDialog";

export default {
  components: {
    "add-view-dialog": AddViewDialog,
  },
  data() {
    return {
      dialogVisible: false,
      module: null,
      createForm: null,
      type: null,
      attributes: {},
      isFirstTime: null,
      isLoadingConfigurations: false,
      hasLoadedConfigurations: false,
      isLoading: false,
      showError: false,
    };
  },

  methods: {
    showViewDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    getEmittedGaData(emittedData) {
      if (emittedData) {
        if (this.isFirstTime) this.createConfigurations(emittedData);
        else this.updateConfigurations(emittedData);
      }
    },

    createConfigurations(requestBody) {
      this.isLoading = true;
      this.showError = false;
      this.$api[this.module.create.method.toLowerCase()](
        this.module.create.href,
        requestBody
      )
        .then((response) => {
          this.$message({
            message: this.$i18n.t("createSuccesful"),
            type: "success",
            duration: 6000,
          });
          location.reload(true);
          this.isLoading = false;
          this.closeDialog();
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            message: this.$i18n.t("AnErrorHappened"),
            type: "error",
            duration: 6000,
          });
          this.isLoading = false;
          this.showError = true;
        });
    },
    updateConfigurations(requestBody) {
      this.isLoading = true;
      this.showError = false;
      this.$api[this.module.update.method.toLowerCase()](
        this.module.update.href,
        requestBody
      )
        .then((response) => {
          this.attributes = response.data.data.attributes;
          this.$message({
            message: this.$i18n.t("updateSuccessful"),
            type: "success",
            duration: 6000,
          });
          location.reload(true);
          this.isLoading = false;
          this.closeDialog();
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            message: this.$i18n.t("AnErrorHappened"),
            type: "error",
            duration: 6000,
          });
          this.isLoading = false;
          this.showError = true;
        });
    },

    readConfigurations() {
      this.isLoadingConfigurations = true;
      this.showError = false;
      this.$api[this.module.read.method.toLowerCase()](this.module.read.href)
        .then((response) => {
          this.attributes = response.data.data.attributes;
          this.isLoadingConfigurations = false;
          this.hasLoadedConfigurations = true;

          if (this.attributes != null) {
            this.isFirstTime = false;
          } else this.isFirstTime = true;
        })
        .catch((error) => {
          this.isLoadingConfigurations = false;
          this.hasLoadedConfigurations = true;
          if (error.response.status == 404) this.isFirstTime = true;
          else {
            console.error(error);
            this.showError = true;
          }
        });
    },
    fetchModule() {
      this.isLoadingConfigurations = true;
      this.$api[this.gaConfigurationsModule.method.toLowerCase()](
        this.gaConfigurationsModule.href
      )
        .then((response) => {
          this.module = response.data.data.configurations;
          this.createForm = this.module.create.form[1].form;
          this.type = this.module.create.form[0];
          this.readConfigurations();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  computed: {
    ...mapState(["study", "studyLinks"]),

    isCreate() {
      return this.isFirstTime;
    },

    gaConfigurationsModule() {
      return this.studyLinks["gaConfigurations"];
    },
  },
  created() {
    this.fetchModule();
  },
};
</script>

<style scoped>
.remove-outline {
  outline: none;
}
</style>

<i18n>
{
  "en": {
    "connectManually": "Connect manually",
    "createSuccesful": "Connection done successfully",
    "updateSuccessful": "Update done successfully",
    "cantLogText": "Didn't find your Google Analytics view ?",
    "update": "Update",
    "AnErrorHappened": "An error happened"
  },
  "fr": {
    "connectManually": "Connectez manuellement",
    "createSuccesful": "Connexion avec succès",
    "updateSuccessful": "Mis à jour avec succès",
    "cantLogText": "Vous n'avez pas trouvé votre vue Google Analytics ?",
    "update": "Mettre à jour",
    "AnErrorHappened": "Une erreur est survenue"
  },
  "de": {
    "connectManually": "Manuell verbinden",
    "createSuccesful": "Anmeldung erfolgreich",
    "updateSuccessful": "Erfolgreich geupdated",
    "cantLogText": "Haben Sie Ihre Google Analytics-Ansicht nicht gefunden?",
    "update": "Aktualisieren",
    "AnErrorHappened": "Ein Fehler ist aufgetreten"
  }
}
</i18n>
