<template>
  <div>
    <div class="header">
      <div class="title">
        <h4>
          {{ $t("keptKeywords") }}: {{ nbKeywords }}/{{ nbGroupedKeywords }}
        </h4>
      </div>
      <div class="text-right">
        <el-button
          plain
          type="primary"
          @click="excelFocusedExport"
          >{{ $t("exportDeduplicatedKeywords") }}</el-button
        >
        <el-button
          plain
          type="primary"
          @click="excelExport"
          >{{ $t("exportKeywoards&Groups") }}</el-button
        >
      </div>
    </div>
    <ag-grid-vue
      style="height: 400px"
      class="ag-theme-material"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :groupMultiAutoColumn="true"
      :copyHeadersToClipboard="false"
      :autoGroupColumnDef="autoGroupColumnDef"
      :localeTextFunc="localeTextFunc"
      :suppressAggFuncInHeader="true"
      :getRowStyle="getRowStyle"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const PagesRepository = RepositoryFactory.get("pages");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

export default {
  mixins: [agGridMixin],
  props: {
    data: Object,
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: [],
      gridApi: null,
      columnApi: null,
      gridOptions: { onColumnVisible: () => this.adjustGrid() },
      autoGroupColumnDef: null,
      nbGroupedKeywords: 0,
      nbKeywords: 0,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    loadGrid() {
      this.setColumnDefs();
      this.mapData();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          filter: "agTextColumnFilter",
          cellRenderer: "agGroupCellRenderer",
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.$i18n.t("groupedKeywords"),
          field: "keywordGroup",
          filter: "agTextColumnFilter",
          cellRenderer: "agGroupCellRenderer",
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          valueFormatter: numberFormatter,
          aggFunc: "max",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("keyword"),
          field: "group",
          filter: "agTextColumnFilter",
          rowGroup: false,
          hide: true,
        },
      ];
      this.autoGroupColumnDef = { minWidth: 200, suppressSizeToFit: true };
    },
    mapData() {
      this.rowData = [];
      this.nbKeywords = 0;
      this.nbGroupedKeywords = 0;
      if (this.data) {
        this.data.data.map((elt) => {
          this.nbKeywords += 1;
          elt.clusteredKeywords.map((subelt) => {
            const finalobj = {};
            finalobj.keywordGroup = subelt.keyword;
            this.nbGroupedKeywords += 1;
            finalobj.volume = subelt.volume;
            finalobj.keyword = elt.representativeKeyword.text;
            this.rowData.push(finalobj);
          });
        });
      }
    },
    getRowStyle(params) {
      if (params.node.group) {
        return { "font-weight": "bold" };
      }
    },
    excelFocusedExport() {
      const params = {
        skipHeader: false,
        columnKeys: ["group", "volume"],
        onlySelected: false,
        fileName: "Keywords Export - SmartKeyword",
        sheetName: "Keywords",
        processCellCallback: (paramsNode) => {
          if (paramsNode.node.group) {
            if (paramsNode.column.colDef.field == "group") {
              return paramsNode.node.key;
            } else if (paramsNode.column.colDef.field == "volume") {
              return paramsNode.node.aggData.volume;
            }
          }
        },
        shouldRowBeSkipped: (paramsNode) => {
          return !paramsNode.node.group;
        },
      };

      this.gridApi.exportDataAsExcel(params);
    },
    excelExport() {
      const params = {
        skipHeader: false,
        columnKeys: ["keyword", "keywordGroup", "volume"],
        onlySelected: false,
        fileName: "Keywords Export - SmartKeyword",
        sheetName: "Keywords",
        processCellCallback: (paramsNode) => {
          if (paramsNode.node.group) {
            if (paramsNode.column.colDef.field == "keyword") {
              return paramsNode.node.key;
            } else if (paramsNode.column.colDef.field == "volume") {
              return paramsNode.node.aggData.volume;
            }
          } else {
            if (!paramsNode.column.rowGroupActive) {
              return paramsNode.value;
            }
          }
        },
      };

      this.gridApi.exportDataAsExcel(params);
    },
  },
  beforeMount() {
    this.loadGrid();
  },
  watch: {
    data() {
      this.mapData();
      this.adjustGrid();
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5rem;
  .text-right {
    flex: 1;
    text-align: right;
  }
  .title {
    text-align: left;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "keyword": "Keyword",
    "groupedKeywords": "Grouped Keywords",
    "volume": "Volume",
    "source": "Source",
    "exportKeywoards&Groups": "Export Keywoards & Groups",
    "exportDeduplicatedKeywords": "Export Deduplicated Keywords",
    "keptKeywords": "Kept Keywords"
  },
  "fr": {
    "keyword": "Mot-clé",
    "groupedKeywords": "Mots-clés groupés",
    "volume": "Volume",
    "source": "Source",
    "exportKeywoards&Groups": "Exporter les mots clés et groupes",
    "exportDeduplicatedKeywords": "Exporter les mots clés dédupliqués",
    "keptKeywords": "Mots-clés gardés"
  },
  "de": {
    "keyword": "Stichwort",
    "groupedKeywords": "Gruppierte Schlüsselwörter",
    "volume": "Volumen",
    "source": "Quelle",
    "exportKeywoards&Groups": "Schlüsselwörter und Gruppen exportieren",
    "exportDeduplicatedKeywords": "Exportieren Sie deduplizierte Keywords",
    "keptKeywords": "Schlüsselwörter beibehalten"
  }
}
</i18n>
