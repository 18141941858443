<template>
  <el-form-item :label=" isShowLabel ? $t('period') : ''">
    <el-date-picker
      v-model="period"
      :size="size"
      type="daterange"
      :range-separator="$t('to')"
      :format="datePickerFormat"
      align="center"
      :start-placeholder="$t('start_date')"
      :end-placeholder="$t('end_date')"
      :picker-options="periodPickerOptions"
    >
    </el-date-picker>
  </el-form-item>
</template>

<script>
import { DateTime } from "luxon";
import { dateToDateTimeUTC } from "@/javascripts/dateHelpers";

export default {
  props: {
    value: Array,
    frequency: String,
    size:{
      type:String,
      default: ()=> "small"
    },
    isShowLabel:{
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
    period: {
      get() {
        const month = this.value[0].month - 1;
        const day = this.value[0].day;
        const year = this.value[0].year;
        const monthEnd = this.value[1].month - 1;
        const dayEnd = this.value[1].day;
        const yearEnd = this.value[1].year;
        return [
          new Date(year, month, day),
          new Date(yearEnd, monthEnd, dayEnd),
        ];
      },
      set(period) {
        this.$emit("input", [
          dateToDateTimeUTC(period[0]),
          dateToDateTimeUTC(period[1]),
        ]);
      },
    },
    periodPickerOptions() {
      return ((frequency) => {
        return {
          disabledDate(time) {
            const dateTime = dateToDateTimeUTC(time);
            if (frequency === "DAY") {
              return dateTime.diffNow("days") > 0;
            } else {
              return dateTime.weekday != 1 || dateTime.diffNow("days") > 0;
            }
          },
          shortcuts: [
            {
              text: this.$i18n.t("last_week"),
              onClick(picker) {
                const end = DateTime.utc().startOf("week");
                const start = end.minus({ weeks: 1 });
                picker.$emit("pick", [start.toJSDate(), end.toJSDate()]);
              },
            },
            {
              text: this.$i18n.t("last_month"),
              onClick(picker) {
                const end = DateTime.utc().startOf("week");
                const start = end.minus({ weeks: 4 });
                picker.$emit("pick", [start.toJSDate(), end.toJSDate()]);
              },
            },
            {
              text: this.$i18n.t("last_3_months"),
              onClick(picker) {
                const end = DateTime.utc().startOf("week");
                const start = end.minus({ weeks: 12 });
                picker.$emit("pick", [start.toJSDate(), end.toJSDate()]);
              },
            },
            {
              text: this.$i18n.t("last_year"),
              onClick(picker) {
                const end = DateTime.utc().startOf("week");
                const start = end.minus({ years: 1 }).startOf("week");
                picker.$emit("pick", [start.toJSDate(), end.toJSDate()]);
              },
            },
          ],
        };
      })(this.frequency);
    },
  },
  mounted() {
    this.periodPickerOptions.firstDayOfWeek = 1;
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "period": "Period",
    "to": "To",
    "start_date": "Start date",
    "end_date": "End date",
    "last_week": "Last week",
    "last_month": "Last month",
    "last_3_months": "Last 3 months",
    "last_year": "Last year"
  },
  "fr": {
    "period": "Période",
    "to": "à",
    "start_date": "Date de début",
    "end_date": "Date de fin",
    "last_week": "Dernière semaine",
    "last_month": "Dernier mois",
    "last_3_months": "3 derniers mois",
    "last_year": "Dernière année"
  },
  "de": {
    "period": "Zeitraum",
    "to": "Zu",
    "start_date": "Startdatum",
    "end_date": "Endtermin",
    "last_week": "Letzte Woche",
    "last_month": "Im vergangenen Monat",
    "last_3_months": "letzten 3 Monate",
    "last_year": "Letztes Jahr"
  }
}
</i18n>
