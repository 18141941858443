<template>
  <div>
    <el-form
      :model="form"
      label-width="150px"
    >
      <el-form-item :label="$t('sellsy_company_name')">
        <el-select
          v-model="form.sellsyThirdId"
          filterable
          clearable
          :placeholder="$t('select_sellsy_company')"
        >
          <el-option
            v-for="item in sellsyCompaniesOptions"
            :key="item.thirdid"
            :label="item.name"
            :value="item.thirdid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('name')">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('max_users')">
        <el-input-number
          v-model="form.usersLimit"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('max_studies')">
        <el-input-number
          v-model="form.studiesLimit"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('max_keywords')">
        <el-input-number
          v-model="form.keywordsLimit"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('max_competitors')">
        <el-input-number
          v-model="form.competitorsLimit"
          :min="1"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button
        plain
        @click="closeDialog"
        >{{ $t("cancel") }}</el-button
      >
      <el-button
        plain
        type="primary"
        @click="submitForm"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompanyGroupsRepository = RepositoryFactory.get("companyGroups");
const SellsyRepository = RepositoryFactory.get("sellsyRepository");

export default {
  props: {
    companyGroupId: Number,
  },
  data() {
    return {
      form: {
        name: "",
        sellsyThirdId: null,
        usersLimit: 1,
        keywordsLimit: 1,
        competitorsLimit: 1,
        studiesLimit: 1,
      },
      sellsyCompaniesOptions: [],
    };
  },
  methods: {
    submitForm() {
      this.closeDialog();
      this.$emit("submit", this.form);
      if (!this.companyGroupId) {
        this.resetForm();
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    resetForm() {
      this.form.name = "";
      this.form.sellsyThirdId = null;
      this.form.usersLimit = 1;
      this.form.keywordsLimit = 1;
      this.form.competitorsLimit = 1;
      this.form.studiesLimit = 1;
    },
    setSellsyCompaniesOptions() {
      SellsyRepository.getCompanies()
        .then((data) => {
          this.sellsyCompaniesOptions = Object.values(data.response.result).map(
            (company) => ({
              name: company.fullName,
              thirdid: parseInt(company.id),
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadCompanyGroup() {
      CompanyGroupsRepository.getCompanyGroup(this.companyGroupId)
        .then((data) => {
          this.form = data.data.attributes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    if (this.companyGroupId) this.loadCompanyGroup();
    this.setSellsyCompaniesOptions();
  },
  watch: {
    companyGroupId() {
      if (this.companyGroupId) this.loadCompanyGroup();
    },
  },
};
</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "sellsy_company_name": "Sellsy company",
    "select_sellsy_company": "Select sellsy company",
    "name": "name",
    "max_users": "Max users",
    "max_studies": "Max studies",
    "max_keywords": "Max keywords",
    "max_competitors": "Max competitors",
    "cancel": "Cancel",
    "confirm": "Confirm"
  },
  "fr": {
    "sellsy_company_name": "Société sellsy",
    "select_sellsy_company": "Sélectionnez la société sellsy",
    "name": "Nom",
    "max_users": "Max utilisateurs",
    "max_studies": "Max études",
    "max_keywords": "Max mots clés",
    "max_competitors": "Max concurrents",
    "cancel": "Annuler",
    "confirm": "Confirmer"
  },
  "de": {
    "sellsy_company_name": "selly unternehmen",
    "select_sellsy_company": "Wählen Sie das Unternehmen sellsy aus",
    "name": "Name",
    "max_users": "Maximale Benutzer",
    "max_studies": "Max studiert",
    "max_keywords": "Maximale Schlüsselwörter",
    "max_competitors": "Maximale Konkurrenten",
    "cancel": "Stornieren",
    "confirm": "Bestätigen"
  }
}
</i18n>
