<template>
  <div class="container">
    <el-tabs
      v-model="activeName"
      type="border-card"
      :stretch="true"
    >
      <el-tab-pane
        name="summary"
        :label="$t('summary')"
        :disabled="page == null"
      >
        <action-plan-summary
          :keyword="keyword"
          :data="data"
          :content-optimization-data="contentOptimizationData"
          :is-loading="isLoading"
          :is-content-optimization-loading="isContentOptimizationLoading"
          :crawl="crawl"
        >
        </action-plan-summary>
      </el-tab-pane>
      <el-tab-pane
        name="serp"
        :label="$t('serp')"
        :disabled="page == null"
      >
        <div
          v-if="
            (crawl && crawl.httpStatus == 200) || (data && data.tagsUrlDetails)
          "
        >
          <div class="your-serp">
            <h4 class="serp-h4">{{ $t("yourSerp") }}</h4>
            <div class="serp">
              <div class="doc-plan-action">
                <el-link
                  href="https://docs.smartkeyword.io/fr/articles/4998295-plan-d-action-focus-serp"
                  target="_blank"
                  type="primary"
                  :underline="false"
                >
                  <i class="fas fa-question-circle"></i>
                  <span>{{ $t("documentationDesc") }}</span>
                </el-link>
              </div>
              <serp
                v-if="data"
                :keyword="keyword"
                :title="ownPageSerpInfo.title"
                :metaDescription="ownPageSerpInfo.metaDescription"
                :url="ownPageSerpInfo.url"
              >
              </serp>
            </div>
          </div>
          <el-divider class="fat-divider" />
          <serp-simulator
            v-if="data"
            :metaContentsData="metaContentsData"
            :page-info="ownPageSerpInfo"
            :keyword="keyword"
            :detailedRecommendations="changeRecommendationsFromArrayToObject"
          >
          </serp-simulator>
          <el-divider class="fat-divider" />
          <slot name="serp"></slot>
        </div>
        <div
          v-else
          class="text-center"
        >
          <i class="el-icon-warning"></i>{{ $t("crawlerProblem") }}
        </div>
      </el-tab-pane>
      <el-tab-pane
        name="content"
        :label="$t('content')"
      >
        <action-plan-content
          :is-admin="currentUser.is_admin"
          :country="country"
          :language="language"
          :keyword="keyword"
          :url="page ? page.url : page"
          :crawl="crawl"
          :seoScores="seoScores"
          :metaContentsData="metaContentsData"
          :defaultSearchEngine="defaultSearchEngine"
          :tagsUrlDetails="tagsUrlDetails"
          @contentOptimizationFetch="
            (newData) => handleContentOptimizationData(newData)
          "
          @isLoading="(isLoading) => (isContentOptimizationLoading = isLoading)"
        >
          <template v-slot:content-bot>
            <slot name="content-bot"></slot>
          </template>
          <template v-slot:content-top>
            <slot name="content-top"></slot>
          </template>
          <template v-slot:associate-page>
            <slot name="associate-page"></slot>
          </template>
        </action-plan-content>
      </el-tab-pane>
      <el-tab-pane
        name="technical"
        :label="$t('technical')"
        :disabled="page == null"
      >
        <div
          v-if="
            (crawl && crawl.httpStatus == 200) || (data && data.tagsUrlDetails)
          "
        >
          <div class="doc-plan-action">
            <el-link
              href="https://docs.smartkeyword.io/fr/articles/4998330-plan-d-action-focus-technique"
              target="_blank"
              type="primary"
              :underline="false"
            >
              <i class="fas fa-question-circle"></i>
              <span>{{ $t("documentationTech") }}</span>
            </el-link>
          </div>
          <slot name="technical"></slot>
        </div>
        <div
          v-else
          class="text-center"
        >
          <i class="el-icon-warning"></i>{{ $t("crawlerProblem") }}
        </div>
      </el-tab-pane>
      <el-tab-pane
        name="netlinking"
        :label="$t('netlinking')"
        :disabled="page == null"
      >
        <netlinking-tab
          v-loading="isLoading"
          :data="data"
          :keyword="keyword"
          :page="page"
          :current-user="currentUser"
        >
        </netlinking-tab>
      </el-tab-pane>
    </el-tabs>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ActionPlanContent from "@/pages/ActionPlan/Content";
import ActionPlanSummary from "@/pages/ActionPlan/Summary";
import NetlinkingTab from "@/pages/ActionPlan/Netlinking";
import Serp from "@/pages/ActionPlan/Simulator/Serp";
import SerpSimulator from "@/pages/ActionPlan/Simulator";
import base64url from "base64url";
import { contentOptimizationMixin } from "@/mixins/contentOptimizationMixin";

import { metaContentsMixin } from "@/mixins/metaContentsMixin";
import { RepositoryFactory } from "@/services/repositoryFactory";

const RecommendationsRepository = RepositoryFactory.get(
  "recommendationsRepository"
);

export default {
  mixins: [metaContentsMixin, contentOptimizationMixin],
  components: {
    ActionPlanContent,
    ActionPlanSummary,
    NetlinkingTab,
    Serp,
    SerpSimulator,
  },
  props: {
    currentUser: Object,
    country: String,
    language: String,
    keyword: Object,
    page: Object,
  },
  data() {
    return {
      activeName: this.page ? "summary" : "content",
      contentOptimizationData: null,
      crawl: null,
      data: null,
      isContentOptimizationLoading: false,
      isLoading: false,
      score: {},
      contentOptimizationDataChatGpt: null,
      seoScores: null,
    };
  },
  methods: {
    async fetchMetaContents() {
      const request = {
        text: this.keyword.text,
        locationId: this.keyword.loc_id,
        searchEngineParameters: {
          isoCountryCode: this.defaultSearchEngine.isoCountryCode,
          device: this.defaultSearchEngine.device,
          isoLanguageCode: this.defaultSearchEngine.isoLanguageCode,
        },
        rangeDate: {
          start: this.lastMonday,
          end: this.lastMonday,
        },
        frequency: this.keyword.update_frequency
          ? this.keyword.update_frequency
          : "WEEKLY",
      };
      this.metaContentsData = await this.fetchPositionAndPagesUrlsAsync(
        request
      );
      this.seoScores = await this.getMultipleContentOptimization(
        this.metaContents
      );
    },
    averageGrade(type) {
      if (this.data && this.data[type]) {
        const contentDetails = this.data[type];
        let numberOfGrade = 0;
        let sumScore = 0;
        contentDetails.forEach((element) => {
          sumScore = sumScore + element.score;
          numberOfGrade = numberOfGrade + 1;
        });
        return Math.round(sumScore / numberOfGrade);
      }
    },
    fetchRecommendations() {
      this.isLoading = true;
      RecommendationsRepository.fetchRecommendations(
        this.study.id,
        this.keyword.id
      )
        .then((response) => {
          if (Object.keys(response).length !== 0) {
            this.data = response;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    getCrawlMeta() {
      if (this.page && this.page["url"]) {
        const encodedUrl = base64url(this.page["url"]);
        this.$api
          .get(`/pages/url/${encodedUrl}`)
          .then((response) => {
            this.crawl = response.data.meta.crawl;
          })
          .catch(() => {
            this.$message({
              message: this.$i18n.t("failPage"),
              type: "error",
              duration: 2000,
            });
          });
      }
    },
    handleContentOptimizationData(newData) {
      this.contentOptimizationData = newData;
    },
  },
  computed: {
    ...mapState(["study"]),
    ownPageSerpInfo() {
      if (this.data && this.data.tagsUrlDetails) {
        return {
          url: this.data.tagsUrlDetails[0].url,
          title: this.data.tagsUrlDetails[0].title,
          metaDescription: this.data.tagsUrlDetails[0].metaDescription,
        };
      }
    },

    tagsUrlDetails() {
      if (this.data && this.data.tagsUrlDetails) {
        return this.data.tagsUrlDetails;
      }
    },
    defineScore() {
      if (this.titleScores && this.metaDescScores)
        return { title: this.titleScores, metaDesc: this.metaDescScores };
    },
    changeRecommendationsFromArrayToObject() {
      const detailedRecommendationsObj = {};

      detailedRecommendationsObj["title"] = this.data.tagsUrlDetails[0];
      detailedRecommendationsObj["metaDescription"] =
        this.data.tagsUrlDetails[1];
      detailedRecommendationsObj["url"] = this.data.tagsUrlDetails[2];
      detailedRecommendationsObj["structureHn"] = this.data.tagsUrlDetails[3];

      return detailedRecommendationsObj;
    },
  },
  watch: {
    study() {
      this.fetchRecommendations();
    },
  },
  created() {
    this.getCrawlMeta();
  },
  mounted() {
    if (this.study) {
      this.fetchMetaContents();
      this.fetchRecommendations();
    }
  },
};
</script>

<style lang="scss" scoped>
.doc-plan-action {
  margin-bottom: 1rem;
}

.fat-divider {
  height: 3px;
}

.your-serp {
  margin-top: 0.7rem;

  .serp-h4 {
    text-align: center;
  }

  .serp {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<i18n>
{
  "en": {
    "documentationDesc": "How to test your Title and Meta Description tags on the tool ?",
    "documentationTech": "Optimize your pages thanks to the technical recommendations of the tool",
    "summary": "Summary",
    "content": "Content",
    "netlinking": "Netlinking",
    "technical": "Technical",
    "serp": "SERP & Hn",
    "yourSerp": "Preview your page in Google",
    "failPage": "Get crawl information failed",
    "crawlerProblem": "A problem occurred during the crawl"
  },
  "fr": {
    "documentationDesc": "Comment tester vos balises Titre et Meta description sur l'outil ?",
    "documentationTech": "Optimisez vos pages grâce aux recommandations techniques de l'outil",
    "summary": "Résumé",
    "content": "Contenu",
    "netlinking": "Netlinking",
    "technical": "Technique",
    "serp": "SERP & Hn",
    "yourSerp": "Aperçu de votre page dans Google",
    "failPage": "Récupération des information du crawler échoué",
    "crawlerProblem": "Un problème est survenu lors du crawl"
  },
  "de": {
    "documentationDesc": "Wie können Sie Ihre Titel- und Meta-Beschreibungs-Tags im Tool testen?",
    "documentationTech": "Optimieren Sie Ihre Seiten dank der technischen Empfehlungen des Tools",
    "summary": "Fortsetzen",
    "content": "Inhalt",
    "netlinking": "Netzverlinkung",
    "technical": "Technik",
    "serp": "SERP & Hn",
    "yourSerp": "Vorschau Ihrer Seite in Google",
    "failPage": "Fehler beim Abrufen von Crawler-Informationen",
    "crawlerProblem": "Beim Crawlen ist ein Problem aufgetreten"
  }
}
</i18n>
