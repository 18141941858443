<template>
  <task
    v-loading="isLoadingTask"
    v-if="task"
    :task="task"
    :current-user="currentUser"
    :user-options="userOptions"
    :study-id="task.studyId"
    @on-update="update"
    @on-update-action="updateWithAction"
    @delete-task="deleteTask"
    @duplicate-task="duplicateTask"
    @archive-task="archive"
    @unarchive-task="unarchive"
    :is-stand-alone="isStandAlone"
  >
    <template v-slot:bottom-side>
      <el-divider />
      <el-button
        v-if="task && !task.archived"
        class="archive"
        plain
        @click="archive"
      >
        <i class="fas fa-archive"></i> {{ $t("archive") }}
      </el-button>
      <el-button
        v-else-if="task"
        class="archive"
        plain
        @click="unarchive"
      >
        <i class="fas fa-undo-alt"></i> {{ $t("unarchive") }}
      </el-button>
      <el-button
        class="delete"
        type="danger"
        plain
        @click="deleteTask"
      >
        <i class="fas fa-trash-alt"></i> {{ $t("delete") }}
      </el-button>
    </template>
  </task>
</template>

<script>
import Task from "./Task";

export default {
  components: {
    Task,
  },
  props: {
    value: Object,
    currentUser: Object,
    userOptions: Array,
    isStandAlone: Boolean,
  },
  data() {
    return {
      task: null,
      isLoadingTask: false,
    };
  },
  methods: {
    duplicateTask() {
      let duplicate = { ...this.value };
      duplicate.blocks = [];
      delete duplicate.id;

      this.$api
        .post(`/studies/${duplicate.studyId}/tasks`, duplicate)
        .then((response) => {
          const data = response.data.data;
          this.$emit("on-created-task", data);
          this.$message({
            message: this.$i18n.t("taskCreatedSuccess"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("taskCreatedFailure"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    archive() {
      this.$api
        .patch(`/studies/${this.value.studyId}/tasks/${this.value.id}`, {
          archived: true,
        })
        .then((response) => {
          this.$emit("on-updated-task", response.data.data);
          this.$emit("on-archived-task", this.value.id);
          this.task = response.data.data;
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    unarchive() {
      this.$api
        .patch(`/studies/${this.value.studyId}/tasks/${this.value.id}`, {
          archived: false,
        })
        .then((response) => {
          this.$emit("on-updated-task", response.data.data);
          this.task = response.data.data;
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    deleteTask() {
      this.$emit("delete-task", this.value);
    },
    update(partialTask) {
      this.$api
        .patch(
          `/studies/${this.value.studyId}/tasks/${this.value.id}`,
          partialTask
        )
        .then((response) => {
          this.$emit("on-updated-task", response.data.data);
          this.task = response.data.data;
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    updateWithAction(infos) {
      this.$api[infos.method.toLowerCase()](infos.href)
        .then((response) => {
          this.$emit("on-updated-task", response.data.data);
          this.task = response.data.data;
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
  },
  mounted() {
    this.isLoadingTask = true;
    this.$api
      .get(`/studies/${this.value.studyId}/tasks/${this.value.id}`)
      .then((response) => {
        this.task = response.data.data;
        this.isLoadingTask = false;
      })
      .catch((error) => {
        this.isLoadingTask = false;
        this.$message({
          message: this.$i18n.t("fail"),
          type: "error",
          duration: 6000,
        });
        console.log(error);
      });
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.archive {
  width: 100%;
}

.delete {
  width: 100%;
  margin-top: 1rem;
  margin-left: 0;
}
</style>

<i18n>
{
  "en": {
    "taskCreatedFailure": "Task creation failed",
    "taskCreatedSuccess": "Task created successfully",
    "archive": "Archive",
    "task_deleted_success": "Task deleted",
    "delete": "Delete",
    "unarchive": "Anarchive",
    "fail": "We encountered an error"
  },
  "fr": {
    "taskCreatedFailure": "La création de la tâche a échoué",
    "taskCreatedSuccess": "Tâche créée avec succès",
    "archive": "Archiver",
    "task_deleted_success": "Tâche supprimée",
    "delete": "Supprimer",
    "unarchive": "Désarchiver",
    "fail": "Nous avons rencontré une erreur"
  },
  "de": {
    "taskCreatedFailure": "Aufgabenerstellung fehlgeschlagen",
    "taskCreatedSuccess": "Aufgabe erfolgreich erstellt",
    "archive": "Archiv",
    "task_deleted_success": "Gelöschte Aufgabe",
    "delete": "LÖSCHEN",
    "unarchive": "Unarchivieren",
    "fail": "Es ist ein Fehler aufgetreten"
  }
}
</i18n>
