import Repository from "@/services/repository";

export default {
  importKeywords(request, url) {
    return Repository.post(url, {
      keywords_import: request.keywords_import,
      banned_keywords: request.banned_keywords,
    }).then((response) => response.data);
  },
};
