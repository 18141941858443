<template>
  <div>
    <div
      v-if="!isActionPlanDialog"
      class="d-flex justify-content-between mb-1 element"
    >
      <div>
        <span class="category-title"> {{ $t("titleH1") }}:</span>
        <span>
          {{ ownH1.length }}/{{ titleMaxCaracters }} {{ $t("caracters") }}</span
        >
      </div>
      <el-tooltip
        :content="$t('tooltipH1')"
        placement="top"
      >
        <i class="fas fa-question-circle my-auto"></i>
      </el-tooltip>
    </div>
    <div class="seo-scores">
      <div :class="scoreClass">
        <el-progress
          :class="progressClass"
          type="dashboard"
          :percentage="ownScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div :class="textClass">
          {{ isActionPlanDialog ? $t("yourScore") : $t("myScore") }}
        </div>
      </div>
      <el-divider
        direction="vertical"
        class="vertical-divider"
      />
      <div :class="scoreClass">
        <el-progress
          :class="progressClass"
          type="dashboard"
          :percentage="serpScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div :class="textClass">
          {{ isActionPlanDialog ? $t("serpScore") : $t("serp") }}
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ownScore: Number,
    serpScore: Number,
    ownH1: String,
    isActionPlanDialog: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      colors: [
        { color: "#FF0000", percentage: 20 },
        { color: "#FFCC00", percentage: 40 },
        { color: "#4ECDC4", percentage: 60 },
        { color: "#66FF00", percentage: 80 },
        { color: "#008000", percentage: 100 },
      ],
      titleMaxCaracters: 120,
    };
  },
  methods: {
    format(percentage) {
      return percentage;
    },
  },
  computed: {
    scoreClass() {
      if (this.isActionPlanDialog)
        return "d-flex flex-column-reverse align-items-center element seo-score text-dialog mx-5";
      else return "element seo-score";
    },
    textClass() {
      if (this.isActionPlanDialog) return "text-dialog mb-3";
      else return "text";
    },
    progressClass() {
      if (this.isActionPlanDialog) return "large-dashboard";
      else return "mini-dashboard";
    },
  },
};
</script>

<style lang="scss" scoped>
.seo-scores {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  white-space: nowrap;
  .vertical-divider {
    height: unset;
  }

  .element {
    flex: 1;
  }

  .seo-score {
    text-align: center;

    .text {
      text-align: center;
      font-size: 0.9rem;
      word-break: break-word;
    }

    .text-dialog {
      text-align: center;
      font-size: 1.2rem;
      word-break: break-word;
    }

    .mini-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 0.8rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .large-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 1.2rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 75px !important;
        height: 75px !important;
      }
    }
  }
}

.category-title {
  font-weight: bold;
}
</style>

<i18n>
{
    "en": {
        "titleH1": "H1 tag",
        "ownScoreH1": "My H1 score",
        "serpScoreH1": "SERP score",
        "serp": "SERP",
        "myScore": "My score",
        "yourScore": "Your score",
        "serpScore":"SERP Score",
        "caracters": "caracters",
        "tooltipH1": "The H1 score is based on the semantic proximity of your worked keyword. Try changing your keyword placement within the tag or your H1 content to try and improve your score."
    },
    "fr": {
        "titleH1": "Balise H1",
        "ownScoreH1": "Mon score H1",
        "serpScoreH1": "Score de la serp",
        "serp": "SERP",
        "serpScore":"Score de la SERP",
        "myScore": "Mon score",
        "yourScore": "Votre score",
        "caracters": "caractères",
        "tooltipH1": "Le score H1 est basé sur la proximité sémantique de votre mot clé travaillé. Essayez de modifier l'emplacement de votre mot clé au sein de la balise ou le contenu de votre H1 pour tenter d'améliorer votre score."
    },
    "it": {
        "titleH1": "Tag H1",
        "ownScoreH1": "Il mio punteggio H1",
        "serpScoreH1": "Punteggio SERP",
        "serp": "SERP",
        "myScore": "Il mio punteggio",
        "caracters": "caratteri",
        "tooltipH1": "Il punteggio H1 si basa sulla vicinanza semantica della parola chiave utilizzata. Prova a modificare la posizione della parola chiave all'interno del tag o il contenuto H1 per cercare di migliorare il punteggio."

    },
    "es": {
        "titleH1": "Etiqueta H1",
        "ownScoreH1": "Mi puntaje H1",
        "serpScoreH1": "Puntuación SERP",
        "serp": "SERP",
        "myScore": "Mi puntuación",
        "caracters": "caracteres",
        "tooltipH1": "La puntuación H1 se basa en la proximidad semántica de la palabra clave trabajada. Intente cambiar la ubicación de la palabra clave dentro de la etiqueta o el contenido H1 para tratar de mejorar su puntuación."
    },
    "de": {
        "titleH1": "H1-Tag",
        "ownScoreH1": "Mein H1-Ergebnis",
        "serpScoreH1": "SERP-Score",
        "serp": "SERP",
        "myScore": "Meine Punktzahl",
        "caracters": "Charaktere",
        "tooltipH1": "Der H1-Score basiert auf der semantischen Nähe Ihres bearbeiteten Keywords. Versuchen Sie, Ihre Keyword-Platzierung innerhalb des Tags oder Ihres H1-Inhalts zu ändern, um Ihren Score zu verbessern."
    },
    "nl": {
        "titleH1": "H1-tag",
        "ownScoreH1": "Mijn H1-score",
        "serpScoreH1": "SERP-score",
        "serp": "SERP",
        "myScore": "Mijn score",
        "caracters": "karakters",
        "tooltipH1": "De H1-score is gebaseerd op de semantische nabijheid van uw gebruikte zoekwoord. Probeer uw zoekwoordplaatsing binnen de tag of uw H1-inhoud te wijzigen om uw score te verbeteren."
    }
}
</i18n>
