<template>
  <div
    v-if="studyResponse !== null"
    class="page-break-before"
  >
    <el-card>
      <competitor-grid
        :websites-statistics="websitesStatistics"
        :study-id="studyId"
      >
      </competitor-grid>
    </el-card>
    <!-- <el-divider></el-divider> -->
    <el-card>
      <traffic-chart
        :data="trafficEvolutionData"
        selectedCompetitorType="test"
      >
      </traffic-chart>
    </el-card>
    <!-- <el-divider></el-divider> -->
    <el-card>
      <h4>{{ $t("best_competitors_keywords") }}</h4>
      <keywords-grid
        class="page-break-after"
        :studyId="studyId"
        :keywords-data="keywordsData"
      >
      </keywords-grid>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { subMonths, subYears, subWeeks } from "date-fns";
import { DateTime } from "luxon";
import TrafficChart from "@/pages/Competitors/Charts/EvolutionChart";
import CompetitorGrid from "@/pages/Report/CompetitorGrid";
import KeywordsGrid from "@/pages/Report/CompetitorsKeywordsGrid";

export default {
  components: {
    TrafficChart,
    CompetitorGrid,
    KeywordsGrid,
  },
  props: {
    studyId: Number,
    studyUrl: String,
    competitor: String,
    accountConfigurationJson: Object,
  },
  data() {
    return {
      periodDates: null,
      request: {
        fromStudyId: null,
        filterStudyTags: [],
        start: null,
        end: null,
        frequency: "WEEK",
        positionBuckets: [
          { positionMin: 1, positionMax: 1 },
          { positionMin: 1, positionMax: 3 },
          { positionMin: 1, positionMax: 10 },
        ],
      },
      studyResponse: null,
      websitesStatistics: null,
      trafficEvolutionData: null,
      keywordsData: null,
      trackedKeywords: null,
    };
  },
  computed: {
    ...mapState(["study"]),
  },
  methods: {
    getWebsitesStatistics(competitors) {
      let localRequest = {
        urls: [this.studyUrl, competitors].map((domain) => {
          return { url: domain, type: "DOMAIN" };
        }),
        keywords: this.trackedKeywords.map((trackedKeyword) => ({
          text: trackedKeyword.text,
          locationId: trackedKeyword.locationId,
        })),
        rangeDate: {
          start: this.request.start,
          end: this.request.end,
        },
        frequency: this.request.frequency == "WEEK" ? "WEEKLY" : "DAILY",
        positionsBuckets: [
          { min: 1, max: 1 },
          { min: 1, max: 3 },
          { min: 1, max: 10 },
        ],
        searchEngineParameters: {
          isoCountryCode: this.study.isoCountryCode,
          device:
            this.study.devices.length === 1 &&
            this.study.devices[0] === "MOBILE"
              ? "MOBILE"
              : "DESKTOP",
          isoLanguageCode: this.study.isoLanguageCode,
        },
      };
      this.$api
        .post("/positions/websites-statistics-history", localRequest)
        .then((websitesStatisticsResponse) => {
          const data = websitesStatisticsResponse.data.data;
          const indexOfHost = data
            .map((e) => e.url.url)
            .indexOf(this.study.url);
          if (data[indexOfHost]) {
            data[indexOfHost].isHost = true;
          }
          this.websitesStatistics = data;

          this.mapDataForChart();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mapDataForChart() {
      this.trafficEvolutionData = {
        series: [],
      };
      for (const website of this.websitesStatistics) {
        const websiteStatisticsByDates = Object.entries(
          website.websiteStatisticsByDates
        ).sort();
        const trafficEvolutionDataPoints = websiteStatisticsByDates.map(
          (statsByDate) => {
            return {
              x: new Date(statsByDate[0]).getTime(),
              y: parseInt(statsByDate[1].estimatedTraffic),
            };
          }
        );
        this.trafficEvolutionData.series.push({
          name: website.url.url,
          data: trafficEvolutionDataPoints,
        });
      }
      this.getKeywordsData();
    },
    getKeywordsData() {
      const competitors = [this.competitor];

      const date = this.request.end;
      const pastDate = this.request.start;
      const searchEngineParameters = {
        device:
          this.study.devices.length === 1 && this.study.devices[0] === "MOBILE"
            ? "MOBILE"
            : "DESKTOP",
        isoCountryCode: this.study.isoCountryCode,
        isoLanguageCode: this.study.isoLanguageCode,
      };

      const competitorUrl = {
        url: competitors[0],
        type: "DOMAIN",
      };

      const sortBy = {
        fieldName: "KEYWORD",
        order: "ASC",
      };
      const frequency = this.request.frequency == "WEEK" ? "WEEKLY" : "DAILY";

      const request = {
        date: date,
        pastDate: pastDate,
        searchEngineParameters: searchEngineParameters,
        competitorUrl: competitorUrl,
        sortBy: sortBy,
        frequency: frequency,
        limit: 30,
        offset: 0,
      };
      this.$api
        .post(`/studies/${this.studyId}/positions/index`, request)
        .then((response) => {
          this.keywordsData = response.data.data.map((keyword) => {
            return {
              keyword: keyword.text,
              page: keyword.position ? keyword.position.url : null,
              assignedPage: keyword.positionAssigned
                ? keyword.positionAssigned.url
                : null,
              volume: parseInt(keyword.volume),
              position: keyword.position ? keyword.position.position : null,
              pastPosition: keyword.pastPosition
                ? keyword.pastPosition.position
                : null,
              richResults: keyword.richResults,
              tags: keyword.tags,
              opportunityScore: keyword.opportunityScore,
              status: keyword.status,
              positionCompetitor:
                keyword.competitorPosition &&
                keyword.competitorPosition.position
                  ? keyword.competitorPosition.position.position
                  : null,
              pastPositionCompetitor:
                keyword.competitorPosition &&
                keyword.competitorPosition.pastPosition
                  ? keyword.competitorPosition.pastPosition.position
                  : null,
            };
          });
          this.keywordsData = this.keywordsData.filter(function (keyword) {
            return (
              keyword.positionCompetitor < 10 &&
              (keyword.position > 10 || keyword.position == null)
            );
          });
          this.keywordsData = this.keywordsData.slice(0, 10);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setPeriodDates() {
      let period = this.accountConfigurationJson.data.attributes.period;
      const today = new Date();
      let day = today.getDay(),
        diff = today.getDate() - day + (day == 0 ? -6 : 1);
      const end_date = new Date(today.setDate(diff));
      let start_date = null;
      if (period == 7) start_date = subWeeks(end_date, 1);
      else if (period == 30) start_date = subMonths(end_date, 1);
      else if (period == 90) start_date = subMonths(end_date, 3);
      else if (period == 180) start_date = subMonths(end_date, 6);
      else if (period == 365) start_date = subYears(end_date, 1);

      return [start_date, end_date];
    },
    getTrackedKeywords() {
      const linkTrackedKeywordSearch =
        this.studyResponse.links.searchTrackedKeywords;
      let requestTrackedKeywordsSearch = {};
      if (this.request.frequency === "DAY") {
        requestTrackedKeywordsSearch.updateFrequency = "DAILY";
      }
      if (
        this.request.filterStudyTags &&
        this.request.filterStudyTags.length > 0
      ) {
        requestTrackedKeywordsSearch.tags = this.request.filterStudyTags;
      }
      this.$api[linkTrackedKeywordSearch.method.toLowerCase()](
        linkTrackedKeywordSearch.href,
        requestTrackedKeywordsSearch
      ).then((responseTrackedKeywords) => {
        this.trackedKeywords = responseTrackedKeywords.data.data;
        this.getWebsitesStatistics(this.competitor);
      });
    },
  },
  created() {
    this.request.fromStudyId = this.studyId;
    this.periodDates = this.setPeriodDates();
    this.request.start = DateTime.utc(
      this.periodDates[0].getFullYear(),
      this.periodDates[0].getMonth() + 1,
      this.periodDates[0].getDate()
    )
      .startOf("week")
      .toString()
      .split("T")[0];
    this.request.end = DateTime.utc(
      this.periodDates[1].getFullYear(),
      this.periodDates[1].getMonth() + 1,
      this.periodDates[1].getDate()
    )
      .startOf("week")
      .toString()
      .split("T")[0];
    this.$api.get(`/studies/${this.studyId}`).then((response) => {
      this.studyResponse = response.data;
      this.getTrackedKeywords();
    });
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  margin-bottom: 1rem;
}

.page-break-after {
  page-break-after: always;
}
</style>

<i18n>
{
  "en": {
    "all_my_competitors": "All my competitors",
    "top_positioned_competitors": "Top websites",
    "competitors_by_keywords": "Competitors by keywords",
    "best_competitors_keywords": "Strengths compared to my website "
  },
  "fr": {
    "all_my_competitors": "Tous mes concurrents",
    "top_positioned_competitors": "Top sites positionnés",
    "competitors_by_keywords": "Concurrents par mots-clés",
    "best_competitors_keywords": "Points forts par rapport à mon site"
  },
  "de": {
    "all_my_competitors": "Alle meine Konkurrenten",
    "top_positioned_competitors": "Am besten bewertete Websites",
    "competitors_by_keywords": "Konkurrenten nach Schlüsselwörtern",
    "best_competitors_keywords": "Stärken in Bezug auf meine Website"
  }
}
</i18n>
