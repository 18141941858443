<template>
  <div>
    <h4><i class="fas fa-link"></i> {{ $t("pages") }}</h4>
    <ul>
      <li
        v-for="page in pagify(pages)"
        v-bind:key="page.url"
      >
        <el-tag
          closable
          @close="deletePage(page)"
        >
          <a
            :href="page.url"
            target="_blank"
            >{{ page.url.substring(0, 60) }}</a
          >
        </el-tag>
      </li>
    </ul>
    <el-select
      class="value"
      v-model="page"
      :value-key="'url'"
      :placeholder="$t('pages_placeholder')"
      filterable
      size="medium"
      remote
      :remote-method="fetchPageOptions"
      :loading="pageOptionsLoading"
      allow-create
      @input="addPage()"
      @click.native="$emit('click')"
    >
      <el-option
        v-for="page in pageOptionsWithPages"
        :key="page.url"
        :label="page.url"
        :value="page"
      />
    </el-select>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const PagesRepository = RepositoryFactory.get("pages");

export default {
  props: {
    value: Array,
    studyId: Number,
  },
  data() {
    return {
      pages: this.value,
      page: { id: null, url: null },
      pageOptionsLoading: false,
      pageOptions: [],
    };
  },
  computed: {
    pageOptionsWithPages() {
      // this is to hack pages display
      // because we are loading pageOptions from server,
      // selected pages are not always present in the list,
      // and so not well displayed
      return this.pageOptions.concat(
        this.pages.filter(
          (page) => !this.pageOptions.find((p) => p.url === page.url)
        )
      );
    },
  },
  methods: {
    fetchPageOptions(query) {
      if (query !== "") {
        this.pageOptionsLoading = true;
        PagesRepository.searchPages(this.studyId, query).then((data) => {
          this.pageOptionsLoading = false;
          this.pageOptions = data.data.map((page) => ({
            url: page.attributes.url,
            id: parseInt(page.id),
          }));
        });
      } else {
        this.pageOptions = [];
      }
    },
    addPage() {
      if (typeof this.page === "string") {
        this.page = { url: this.page };
      }
      if (this.page.url) {
        this.pages.push(this.page);
        this.$emit("input", this.pagify(this.pages));
        this.page = { id: null, url: null };
      }
    },
    pagify(pages) {
      return pages.map((page) => {
        if (typeof page === "string") {
          return { url: page };
        } else {
          return page;
        }
      });
    },
    deletePage(page) {
      this.pages.splice(this.pages.indexOf(page), 1);
    },
  },
};
</script>

<style scoped>
li {
  margin-bottom: 0.35rem;
}
</style>

<i18n>
{
  "en": {
    "pages": "Pages",
    "pages_placeholder": "Add pages.."
  },
  "fr": {
    "pages": "Pages",
    "pages_placeholder": "Ajouter des pages.."
  },
  "de": {
    "pages": "Seiten",
    "pages_placeholder": "Seiten hinzufügen..."
  }
}
</i18n>
