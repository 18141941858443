<template>
  <div v-if="contentProjectsModuleResponse">
    <h2>{{ $t("editorial_brief") }}</h2>
    <editorial-brief-form
      :readClientInfoLink="getReadClientInfoLink"
      :updateClientInfoLink="getUpdateClientInfoLink"
    >
    </editorial-brief-form>
  </div>
</template>

<script>
import EditorialBriefForm from "@/components/EditorialBriefForm";
import { mapState } from "vuex";
export default {
  components: {
    EditorialBriefForm,
  },
  data() {
    return {
      contentProjectsModuleResponse: null,
    };
  },
  methods: {
    callContentProjectsModuleAPI() {
      let module = this.contentProjectsModule;
      this.$api[module.method.toLowerCase()](module.href)
        .then((response) => {
          this.contentProjectsModuleResponse = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapState(["studyLinks"]),
    contentProjectsModule() {
      return this.studyLinks["contentProjects"];
    },
    getReadClientInfoLink() {
      return this.contentProjectsModuleResponse.clientInfo.read;
    },
    getUpdateClientInfoLink() {
      return this.contentProjectsModuleResponse.clientInfo.update;
    },
  },
  mounted() {
    this.callContentProjectsModuleAPI();
  },
};
</script>

<style></style>

<i18n>
{
  "en": {
    "editorial_brief": "Editorial brief"
  },
  "fr": {
    "editorial_brief": "Brief editorial"
  },
  "de": {
    "editorial_brief": "Kurzredaktion"
  }
}
</i18n>
