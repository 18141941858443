var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h4',[_vm._v(_vm._s(_vm.$t("simulator")))]),_vm._v(" "),_c('el-divider'),_vm._v(" "),_c('div',{staticClass:"simulator"},[_c('div',{staticClass:"left w-100"},[_c('serp',{attrs:{"title":_vm.inputTitleValue,"metaDescription":_vm.inputMetaValue,"url":_vm.inputUrlValue,"keyword":_vm.keyword}}),_vm._v(" "),_c('el-divider'),_vm._v(" "),_c('div',{staticClass:"simulation-form"},[_c('el-form',{attrs:{"model":_vm.simulation,"label-position":"right"}},[_c('div',{staticClass:"form-item"},[_c('div',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("title_tag"))+" - ")]),_vm._v(" "),(_vm.inputTitleValue)?_c('span',{class:_vm.inputTitleValue.length <= _vm.maxLimitTitleChars &&
                  _vm.inputTitleValue.length >= _vm.minLimitTitleChars
                    ? 'goodWidth'
                    : 'badWidth'},[_vm._v("\n                "+_vm._s(_vm.inputTitleValue.length)+" "+_vm._s(_vm.$t("characters"))+"\n              ")]):_c('span',{staticClass:"badWidth"},[_vm._v("\n                0 "+_vm._s(_vm.$t("characters")))]),_vm._v("\n              -\n              "),_c('span',{class:_vm.containKeyword(_vm.inputTitleValue) ? 'goodWidth' : 'badWidth'},[_vm._v("\n                "+_vm._s(_vm.$t("targeted_keyword"))+"\n              ")]),_vm._v("\n              -\n              "),_c('span',{class:_vm.widthTitle <= _vm.limitTitleWidth ? 'goodWidth' : 'badWidth'},[_vm._v("\n                "+_vm._s(_vm.widthTitle)+"/"+_vm._s(_vm.limitTitleWidth)+" pixels\n              ")])]),_vm._v(" "),_c('div',{staticClass:"reco-container"},[_c('div',{staticClass:"test"},[_c('el-input',{model:{value:(_vm.inputTitleValue),callback:function ($$v) {_vm.inputTitleValue=$$v},expression:"inputTitleValue"}}),_vm._v(" "),_c('el-button',{staticClass:"mt-3",attrs:{"type":"primary"},on:{"click":function($event){return _vm.changeVisibleTitlePopup()}}},[_vm._v(_vm._s(_vm.$t("chatgpt_button")))])],1),_vm._v(" "),_c('Recommandation',{attrs:{"recommendations":_vm.titleReco}})],1),_vm._v(" "),_c('PopUp',{attrs:{"pageInfo":_vm.simulation,"detailedRecommendations":_vm.detailedRecommendations.title,"competitorsPages":_vm.competitorsPages,"score":_vm.titleScores,"popupTitle":"title","keyword":_vm.keyword},on:{"textEvent":function($event){return _vm.handleTextTitleEvent($event)}},model:{value:(_vm.dialogTitleVisible),callback:function ($$v) {_vm.dialogTitleVisible=$$v},expression:"dialogTitleVisible"}})],1),_vm._v(" "),_c('div',{staticClass:"form-item"},[_c('div',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("meta_description_tag"))+" - ")]),_vm._v(" "),(_vm.inputMetaValue)?_c('span',{class:_vm.inputMetaValue.length <= _vm.maxLimitMetaDescChars &&
                  _vm.inputMetaValue.length >= _vm.minLimitMetaDescChars
                    ? 'goodWidth'
                    : 'badWidth'},[_vm._v("\n                "+_vm._s(_vm.inputMetaValue.length)+" "+_vm._s(_vm.$t("characters"))+"\n              ")]):_c('span',{staticClass:"badWidth"},[_vm._v("\n                0 "+_vm._s(_vm.$t("characters")))]),_vm._v("\n              -\n              "),_c('span',{class:_vm.containKeyword(_vm.inputMetaValue) ? 'goodWidth' : 'badWidth'},[_vm._v("\n                "+_vm._s(_vm.$t("targeted_keyword"))+"\n              ")]),_vm._v("\n              -\n              "),_c('span',{class:_vm.widthMetaDescription <= _vm.limitMetaDescWidth
                    ? 'goodWidth'
                    : 'badWidth'},[_vm._v("\n                "+_vm._s(_vm.widthMetaDescription)+"/"+_vm._s(_vm.limitMetaDescWidth)+" pixels\n              ")])]),_vm._v(" "),_c('div',{staticClass:"reco-container"},[_c('div',{staticClass:"test"},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 4 }},model:{value:(_vm.inputMetaValue),callback:function ($$v) {_vm.inputMetaValue=$$v},expression:"inputMetaValue"}}),_vm._v(" "),_c('el-button',{staticClass:"mt-3",attrs:{"type":"primary"},on:{"click":function($event){return _vm.changeVisibleMetaPopup()}}},[_vm._v(_vm._s(_vm.$t("chatgpt_button")))])],1),_vm._v(" "),_c('Recommandation',{attrs:{"recommendations":_vm.MetaDescReco}})],1),_vm._v(" "),_c('PopUp',{attrs:{"pageInfo":_vm.simulation,"detailedRecommendations":_vm.detailedRecommendations.metaDescription,"competitorsPages":_vm.competitorsPages,"score":_vm.metaDescScores,"popupTitle":"metaDescription","keyword":_vm.keyword},on:{"textEvent":function($event){return _vm.handleTextMetaDescEvent($event)}},model:{value:(_vm.dialogMetaVisible),callback:function ($$v) {_vm.dialogMetaVisible=$$v},expression:"dialogMetaVisible"}})],1),_vm._v(" "),_c('div',{staticClass:"form-item"},[_c('div',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("url"))+" - ")]),_vm._v(" "),(_vm.inputUrlValue)?_c('span',{class:_vm.inputUrlValue.length <= _vm.maxLimitUrlChars &&
                  _vm.inputUrlValue.length >= _vm.minLimitUrlChars
                    ? 'goodWidth'
                    : 'badWidth'},[_vm._v("\n                "+_vm._s(_vm.inputUrlValue.length)+" "+_vm._s(_vm.$t("characters"))+"\n              ")]):_c('span',{staticClass:"badWidth"},[_vm._v("\n                0 "+_vm._s(_vm.$t("characters")))])]),_vm._v(" "),_c('div',{staticClass:"reco-container"},[_c('div',{staticClass:"test"},[_c('el-input',{model:{value:(_vm.inputUrlValue),callback:function ($$v) {_vm.inputUrlValue=$$v},expression:"inputUrlValue"}}),_vm._v(" "),_c('el-button',{staticClass:"mt-3",attrs:{"type":"primary"},on:{"click":function($event){return _vm.changeVisibleUrlPopup()}}},[_vm._v(_vm._s(_vm.$t("urlButton")))])],1),_vm._v(" "),_c('Recommandation',{attrs:{"recommendations":_vm.UrlReco}})],1),_vm._v(" "),_c('PopUpUrl',{attrs:{"pageInfo":_vm.simulation,"detailedRecommendations":_vm.encodeUrlRecommendations(_vm.detailedRecommendations.url),"competitorsPages":_vm.competitorsPages},model:{value:(_vm.dialogUrlVisible),callback:function ($$v) {_vm.dialogUrlVisible=$$v},expression:"dialogUrlVisible"}})],1)]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":function($event){return _vm.putActualValues()}}},[_vm._v(_vm._s(_vm.$t("put_back_values")))])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }