import Repository from "@/services/repository";

export default {
  createConversionMetrics(studyId, filters, period, comparativePeriod, goals) {
    return Repository.post(`/a/${studyId}/conversion/metrics.json`, {
      facade: {
        filters,
        period,
        comparativePeriod,
        goals,
      },
    }).then((response) => response.data);
  },

  createConversionChart(
    studyId,
    filters,
    period,
    comparativePeriod,
    frequency,
    goals
  ) {
    return Repository.post(`/a/${studyId}/conversion/chart.json`, {
      facade: {
        filters,
        period,
        comparativePeriod,
        frequency,
        goals,
      },
    }).then((response) => response.data);
  },

  createConversionChartGroups(
    studyId,
    filters,
    group,
    period,
    frequency,
    goals
  ) {
    return Repository.post(`/a/${studyId}/conversion/chart_groups.json`, {
      facade: {
        filters,
        group,
        period,
        frequency,
        goals,
      },
    }).then((response) => response.data);
  },

  createConversionGrid(
    studyId,
    filters,
    group,
    period,
    comparativePeriod,
    goals
  ) {
    return Repository.post(`/a/${studyId}/conversion/grid.json`, {
      facade: {
        filters,
        group,
        period,
        comparativePeriod,
        goals,
      },
    }).then((response) => response.data);
  },
};
