<template>
  <div>
    <el-select
      v-model="selectedItem"
      filterable
      @change="addItem"
    >
      <el-option
        v-for="item in options"
        :key="item[valueKey]"
        :label="item.firstName + ' ' + item.lastName"
        :value="item[valueKey]"
      >
      </el-option>
    </el-select>
    <ul>
      <li
        v-for="item in alreadySelectedItems"
        v-bind:key="item[valueKey]"
      >
        <el-tag
          closable
          @close="deleteItem(item)"
        >
          {{ item.firstName + " " + item.lastName }}
        </el-tag>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    options: Array,
    valueKey: String,
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  computed: {
    items: {
      get() {
        return this.value;
      },
      set(items) {
        this.$emit("input", items);
      },
    },
    alreadySelectedItems() {
      return this.options.filter((option) =>
        this.items.includes(option[this.valueKey])
      );
    },
  },
  methods: {
    deleteItem(item) {
      this.items.splice(this.items.indexOf(item[this.valueKey]), 1);
    },
    addItem() {
      if (this.selectedItem) {
        if (!this.items.includes(this.selectedItem)) {
          this.items.push(this.selectedItem);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  margin-bottom: 0.35rem;
}
</style>

<i18n>
{
  "en": {},
  "fr": {},
  "de": {}
}
</i18n>
