<template>
  <div>
    <h3 style="display: inline">{{ $t("serpData") }}</h3>
    <el-tooltip
      placement="top"
      :content="$t('downloadAllBacklinksData')"
    >
      <el-button
        size="small"
        type="primary"
        plain
        style="margin-left: 1rem; margin-bottom: 1rem; float: right"
        @click="exportBacklinks(constructUrlsListForRequest(tableData))"
        >{{ $t("exportAllBacklinks") }}</el-button
      >
    </el-tooltip>

    <el-table
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column
        prop="position"
        :label="$t('position')"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="url"
        :label="$t('urls')"
      >
        <template slot-scope="scope">
          <a
            :href="scope.row.url"
            target="_blank"
            v-bind:class="{
              'similar-hostname': isSimilarHostname(scope.row.url),
              'study-url': isStudyUrl(scope.row.url),
            }"
            >{{ scope.row.url }}</a
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="da"
        :label="$t('domain_authority')"
        width="145"
      >
      </el-table-column>
      <el-table-column :label="$t('url_data')">
        <template slot="header">
          <div class="header-center">
            {{ $t("url_data") }}
          </div>
        </template>
        <el-table-column
          prop="tf"
          :label="$t('trust_flow')"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="cf"
          :label="$t('citation_flow')"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="ratioTfCf"
          :label="$t('ratio_tf_tf')"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="referringDomains"
          :label="$t('referring_domains')"
          width="145"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('actions')"
        width="145"
      >
        <template slot="header">
          <div class="header-center">
            {{ $t("actions") }}
          </div>
        </template>
        <template slot-scope="scope">
          <el-tooltip
            placement="top"
            :content="$t('download1000BestBacklinks')"
          >
            <el-button
              size="small"
              type="primary"
              plain
              @click="exportBacklinks(constructUrlForRequest(scope.row.url))"
              >{{ $t("exportBacklinks") }}</el-button
            >
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    page: Object,
  },
  methods: {
    isStudyUrl(url) {
      return url.includes(this.page.url);
    },
    isSimilarHostname(url) {
      return (
        this.getHostname(url) == this.getHostname(this.page.url) &&
        !this.isStudyUrl(url)
      );
    },
    isOrganic(url, type) {
      let selectedPage = this.data.serpValues.find((element) =>
        this.isStudyUrl(element.url)
      );
      if (this.isSimilarHostname(url) && selectedPage) {
        if (selectedPage.type == "ORGANIC") return selectedPage.type == type;
      }
      return false;
    },
    getHostname(url) {
      return new URL(url).hostname;
    },
    exportBacklinks(urlsList) {
      let requestAttributes = {};
      requestAttributes.urls = urlsList;
      requestAttributes.scope = "ONE_PER_DOMAIN";
      requestAttributes.limit = 1000;

      let requestData = {};
      requestData.type = "URL_BACKLINKS_REQUEST";
      requestData.attributes = requestAttributes;

      let requestExportBacklinks = {};
      requestExportBacklinks.data = requestData;

      this.$api
        .post(
          `/netlinking/urls/backlinks`,
          { ...requestExportBacklinks },
          {
            headers: {
              Accept: "application/vnd.ms-excel",
              "Content-Type": "application/vnd.api+json",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          if (urlsList.length === 1) {
            link.setAttribute(
              "download",
              "backlinks_" + urlsList[0].url + ".xlsx"
            );
          } else {
            link.setAttribute("download", "all_backlinks_data.xlsx");
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail_export"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
    constructUrlForRequest(url) {
      return [{ url: url, type: "URL" }];
    },
    constructUrlsListForRequest(tableData) {
      let urlsList = tableData.map((url) => {
        return { url: url.url, type: "URL" };
      });
      return urlsList;
    },
  },
  computed: {
    tableData() {
      if (this.data) {
        const serpValues = this.data.serpValues.sort((a, b) =>
          a.position > b.position ? 1 : -1
        );
        let tableData = serpValues.map((url) => {
          return {
            position: url.position,
            url: url.url,
            type: url.type,
            da: url.metrics.domainAuthority,
            tf: url.metrics.trustFlow,
            cf: url.metrics.citationFlow,
            ratioTfCf:
              url.metrics.citationFlow == 0
                ? "--"
                : Math.round(
                    (url.metrics.trustFlow / url.metrics.citationFlow) * 100
                  ) / 100,
            referringDomains: url.metrics.referringDomainsWithEquity,
          };
        });
        const urlValues = this.data.urlValues;
        if (urlValues.position > 10 || !urlValues.position) {
          tableData.push({
            position: urlValues.position ? urlValues.position : "> 100",
            url: urlValues.url,
            da: urlValues.metrics.domainAuthority,
            tf: urlValues.metrics.trustFlow,
            cf: urlValues.metrics.citationFlow,
            ratioTfCf:
              urlValues.metrics.citationFlow == 0
                ? "--"
                : Math.round(
                    (urlValues.metrics.trustFlow /
                      urlValues.metrics.citationFlow) *
                      100
                  ) / 100,
            referringDomains: urlValues.metrics.referringDomainsWithEquity,
          });
        }

        return tableData;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.header-center {
  text-align: center;
}

.study-url {
  color: $--color-success;
}
.similar-hostname {
  color: $--color-warning;
}

::v-deep .el-table th {
  color: black;
}

::v-deep .el-table td {
  color: black;
}
</style>

<i18n>
{
  "en": {
    "position": "Rank",
    "urls": "Url",
    "domain_authority": "Domain authority",
    "trust_flow": "Trust flow",
    "citation_flow": "Citation flow",
    "ratio_tf_tf": "TF/CF",
    "referring_domains": "Referring domains",
    "serpData": "Serp Data",
    "url_data": "URL Data",
    "actions": "Actions",
    "exportBacklinks": "Export backlinks",
    "fail_export": "We have encountered a problem",
    "download1000BestBacklinks": "Download the 1000 best backlinks",
    "noBacklinksToExport": "There are no backlinks to export",
    "downloadAllBacklinksData": "Download all backlinks data",
    "exportAllBacklinks": "Export all backlinks"
  },
  "fr": {
    "position": "Pos.",
    "urls": "Url",
    "domain_authority": "Domain authority",
    "trust_flow": "Trust flow",
    "citation_flow": "Citation flow",
    "ratio_tf_tf": "TF/CF",
    "referring_domains": "Domaines référents",
    "serpData": "Données de la SERP",
    "url_data": "Données d'URL",
    "actions": "Actions",
    "exportBacklinks": "Export backlinks",
    "fail_export": "Nous avons rencontré un problème",
    "download1000BestBacklinks": "Télécharger les 1000 meilleurs backlinks",
    "noBacklinksToExport": "Il n'y a aucun backlink à exporter",
    "downloadAllBacklinksData": "Télécharger les données de tous les backlinks",
    "exportAllBacklinks": "Export de tous les backlinks"
  },
  "de": {
    "position": "Pos.",
    "urls": "URL",
    "domain_authority": "Domänenautorität",
    "trust_flow": "Vertrauensfluss",
    "citation_flow": "Zitierfluss",
    "ratio_tf_tf": "TF/CF",
    "referring_domains": "Verweisende Domains",
    "serpData": "SERP-Daten",
    "url_data": "URL-Daten",
    "actions": "Aktionen",
    "exportBacklinks": "Backlinks exportieren",
    "fail_export": "Wir sind auf ein Problem gestoßen",
    "download1000BestBacklinks": "Laden Sie die Top 1000 Backlinks herunter",
    "noBacklinksToExport": "Es gibt keine Backlinks zum Exportieren",
    "downloadAllBacklinksData": "Daten aller Backlinks herunterladen",
    "exportAllBacklinks": "Export aller Backlinks"
  }
}
</i18n>
