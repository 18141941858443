<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="75%"
    top="5vh"
  >
    <div slot="title">
      <h4>
        Page :
        <a
          :href="url"
          target="_blank"
          >{{ url }} &nbsp;<i class="fas fa-external-link-alt"></i
        ></a>
      </h4>
    </div>
    <google-results-page
      :url="url"
      :initial-date="initialDate"
      :initial-past-date="initialPastDate"
      :current-user="currentUser"
      :initial-frequency="initialFrequency"
    ></google-results-page>
  </el-dialog>
</template>

<script>
import { DateTime } from "luxon";
import GoogleResultsPage from "@/pages/GoogleResults";

export default {
  props: {
    url: String,
    trackedKeywords: Array,
    initialDate: DateTime,
    initialPastDate: DateTime,
    currentUser: Object,
    initialFrequency: String,
    value: Boolean,
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
};
</script>
