<template>
  <el-card>
    <span class="category-title"> {{ $t("competitorsH1s") }}</span>
    <div class="serp-h1">
      <div
        v-for="h1 in h1sShown"
        v-bind:key="h1"
      >
        <el-tag v-if="h1">{{ h1 }}</el-tag>
      </div>
    </div>
    <div class="flex-box">
      <el-link
        style="margin-left: auto; font-size: 0.85rem"
        type="primary"
        @click="showAllH1s = !showAllH1s"
      >
        {{ showAllH1s ? $t("showLess") : $t("showMore") }}
      </el-link>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    serpH1s: Array,
  },
  data() {
    return {
      showAllH1s: false,
    };
  },
  computed: {
    h1sShown() {
      if (this.showAllH1s) return this.serpH1s;
      return this.serpH1s.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-title {
  font-weight: bold;
}

.serp-h1 {
  margin-top: 0.5rem;

  .el-tag {
    word-break: break-word;
    white-space: normal;
    height: auto;
    margin-bottom: 0.2rem;
  }
}

.flex-box {
  display: flex;
}
</style>
<i18n>
{
    "en": {
        "competitorsH1s": "Competitors H1 tags",
        "showLess": "- Show less",
        "showMore": "+ Show more"
    },
    "fr": {
        "competitorsH1s": "H1 concurrents",
        "showLess": "- Montrer moins",
        "showMore": "+ Montrer plus"
    },
    "it": {
        "competitorsH1s": "Tag H1 concorrenti",
        "showLess": "- Mostra meno",
        "showMore": "+ Mostra di più"
    },
    "es": {
        "competitorsH1s": "Etiquetas H1 de los competidores",
        "showLess": "- Mostrar menos",
        "showMore": "+ Mostrar más"
    },
    "de": {
        "competitorsH1s": "Konkurrenten H1-Tags",
        "showLess": "- Weniger anzeigen",
        "showMore": "+ Mehr anzeigen"
    },
    "nl": {
        "competitorsH1s": "Concurrenten H1-tags",
        "showLess": "- Minder tonen",
        "showMore": "+ Toon meer"
    }
}
</i18n>
