<template>
  <NpsSurveyDialog v-model="dialogVisible"> </NpsSurveyDialog>
</template>
<script>
import NpsSurveyDialog from "@/components/nps";

export default {
  components: {
    NpsSurveyDialog,
  },
  props: {
    NpsDialogVisible: Boolean,
  },
  data() {
    return {
      dialogVisible: this.NpsDialogVisible,
    };
  },
};
</script>
