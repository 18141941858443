<template>
  <div>
    <h3>{{ $t("google_results", { date: date }) }}</h3>
    <serp-statistics v-bind="serpStatistics"></serp-statistics>
    <el-card
      class="serp-result"
      shadow="never"
      v-for="(result, index) in enhancedResults"
      :key="result.position"
      :class="highlightPositionedResult(result)"
    >
      <div
        v-if="index < 10 || userStats(result)"
        class="stats"
      >
        <div
          v-if="result.domainAuthority"
          class="stat"
        >
          <el-tooltip
            effect="dark"
            :content="$t('domain_authority')"
            placement="top"
          >
            <div>
              <div>DA</div>
              <div>{{ result.domainAuthority }}</div>
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="result.externalBacklinks"
          class="stat"
        >
          <el-tooltip
            effect="dark"
            :content="$t('external_backlinks')"
            placement="top"
          >
            <div>
              <div>EB</div>
              <div>{{ result.externalBacklinks | formatNumber }}</div>
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="result.referringDomainsCount"
          class="stat"
        >
          <el-tooltip
            effect="dark"
            :content="$t('referring_domains')"
            placement="top"
          >
            <div>
              <div>RD</div>
              <div>{{ result.referringDomainsCount | formatNumber }}</div>
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="result.pageAuthority"
          class="stat"
        >
          <el-tooltip
            effect="dark"
            :content="$t('page_authority')"
            placement="top"
          >
            <div>
              <div>PA</div>
              <div>{{ result.pageAuthority }}</div>
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="result.referringLinksCount"
          class="stat"
        >
          <el-tooltip
            effect="dark"
            :content="$t('backlinks')"
            placement="top"
          >
            <div>
              <div>BL</div>
              <div>{{ result.referringLinksCount | formatNumber }}</div>
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="result.contentLength"
          class="stat"
        >
          <el-tooltip
            effect="dark"
            :content="$t('words_count')"
            placement="top"
          >
            <div>
              <div>W</div>
              <div>{{ result.contentLength }}</div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="position">
        <div>
          {{ result.position }}
          <span
            v-if="typeof result.positionChange == 'number'"
            class="change"
          >
            <span
              v-if="result.positionChange > 0"
              class="positive-change"
              ><i class="fas fa-arrow-up"></i>{{ result.positionChange }}</span
            >
            <span
              v-else-if="result.positionChange == 0"
              class="neutral-change"
              ><i class="fas fa-equals"></i
            ></span>
            <span
              v-else-if="result.positionChange < 0"
              class="negative-change"
              ><i class="fas fa-arrow-down"></i
              >{{ result.positionChange }}</span
            >
          </span>
        </div>
      </div>
      <div class="main">
        <a
          :href="result.url"
          target="_blank"
        >
          <div class="title">{{ result.title }}</div>
          <br />
          <div class="url">{{ prettifyUrl(result.url) }}</div>
        </a>
        <div class="meta-description">{{ result.description }}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import SerpStatistics from "@/pages/KeywordDialog/Positioning/Serp/SerpStatistics";
import { DateTime } from 'luxon';

export default {
  props: {
    position: Object,
    serpResults: Array,
    serpStatistics: Object,
    pastSerpResults: Array,
    serpDate: String,
  },
  components: {
    SerpStatistics,
  },

  computed: {
    organicSerpResults() {
      return this.serpResults.filter(
        (result) =>
          result.type == "ORGANIC" ||
          result.type == "FEATURED_SNIPPET" ||
          result.type == "ANSWER_BOX"
      );
    },
    organicPastSerpResults() {
      return this.pastSerpResults.filter(
        (result) =>
          result.type == "ORGANIC" ||
          result.type == "FEATURED_SNIPPET" ||
          result.type == "ANSWER_BOX"
      );
    },
    enhancedResults() {
      // Compute position change for each result by comparing with past result
      const hostNamesChecked = [];
      const results = this.organicSerpResults.map((result) => {
        const hostName = result.hostName;
        if (!hostNamesChecked.includes(hostName)) {
          const pastResult = this.organicPastSerpResults.find(
            (pastResult) => result.hostName == pastResult.hostName
          );
          if (pastResult) {
            result.positionChange = pastResult.position - result.position;
          }
        }

        return result;
      });
      return results;
    },
    date() {
      return DateTime.fromISO(this.serpDate).toLocaleString();
    },
  },
  methods: {
    highlightPositionedResult(result) {
      if (this.position && result.hostName.endsWith(this.position.domain)) {
        return "highlight";
      }
    },
    prettifyUrl(url) {
      const u = new URL(url.replace(/\/?$/, ""));
      const prettyPath =
        u.pathname.length > 1 ? u.pathname.replace("/", " › ") : "";
      const prettySearch = u.search.length > 0 ? " › ..." : "";
      return u.origin + prettyPath + prettySearch;
    },
    userStats(result) {
      return this.position && result.domain == this.position.domain;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

h3 {
  font-size: 1.25rem;
}

.serp-result {
  margin-bottom: 0.25rem;

  ::v-deep .el-card__body {
    position: relative;
    display: flex;

    .stats {
      position: absolute;
      top: 0;
      right: 20px;

      .stat {
        background-color: mix($--tag-info-color, $--color-white, 10%);
        color: mix($--tag-info-color, $--color-white, 100%);
        border-radius: 0 0 10px 10px;
        display: inline-block;
        font-size: 12px;
        height: 28px;
        line-height: 14px;
        padding: 0 10px;
        margin: 0 4px;
        text-align: center;
        white-space: nowrap;
      }
    }

    .position {
      flex: 0 0 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;

      .change {
        font-size: 0.75rem;

        .positive-change {
          color: $--color-success;
        }

        .neutral-change {
          color: $--color-info;
        }

        .negative-change {
          color: $--color-danger;
        }
      }
    }

    .main {
      font-family: arial, sans-serif;

      a {
        .title {
          display: inline-block;
          color: #1a0dab;
          white-space: nowrap;
          font-size: 18px;
          line-height: 1.3;
        }

        .url {
          display: inline-block;
          color: #006621;
          font-size: 14px;
          white-space: nowrap;
          height: 18px;
          line-height: 1.4;
        }

        &:hover {
          text-decoration: none;

          .title {
            text-decoration: underline;
          }
        }
      }

      .meta-description {
        color: #545454;
        word-wrap: break-word;
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }

  &.highlight {
    border-color: $--color-primary;
  }
}
</style>

<i18n>
{
  "en": {
    "google_results": "Google results on %{date}",
    "domain_authority": "Domain authority",
    "external_backlinks": "External domain backlinks",
    "referring_domains": "Referring domains to website",
    "page_authority": "Page Authority",
    "backlinks": "Backlinks to page",
    "words_count": "Words count",
    "position": "Rank",
    "title": "Title",
    "url": "URL"
  },
  "fr": {
    "google_results": "Résultats Google au %{date}",
    "domain_authority": "Domain Authority",
    "external_backlinks": "Backlinks externes du domaine",
    "referring_domains": "Domaines référents vers le site",
    "page_authority": "Page Authority",
    "backlinks": "Backlinks vers la page",
    "words_count": "Nombre de mots",
    "position": "Position",
    "title": "Titre",
    "url": "URL"
  },
  "de": {
    "google_results": "Google-Ergebnisse vom %{date}",
    "domain_authority": "Domänenautorität",
    "external_backlinks": "Backlinks externes du domaine",
    "referring_domains": "Domains auf die Website verweisen",
    "page_authority": "Seitenautorität",
    "backlinks": "Backlinks gegen die Seite",
    "words_count": "Anzahl der Wörter",
    "position": "Position",
    "title": "Titel",
    "url": "URL"
  }
}
</i18n>
