<template>
  <div>
    <el-popconfirm
      v-if="actions && actions.delete"
      class="button"
      :confirmButtonText="$t('yes')"
      :cancelButtonText="$t('no')"
      icon="el-icon-info"
      iconColor="red"
      :title="$t('areYouSure')"
      @onConfirm="$emit('delete', actions.delete)"
    >
      <el-button
        slot="reference"
        size="small"
        type="danger"
        plain
        v-on:click.stop=""
      >
        <i class="fas fa-trash-alt"></i> {{ $t("delete") }}
      </el-button>
    </el-popconfirm>
  </div>
</template>
<script>
export default {
  props: {
    actions: Object,
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 1rem;
}
</style>

<i18n>
{
  "en": {
    "yes": "Yes",
    "no": "No",
    "areYouSure": "Are you sure ?",
    "delete": "Delete"
  },
  "fr": {
    "yes": "Oui",
    "no": "Non",
    "areYouSure": "Etes-vous sûr ?",
    "delete": "Supprimer"
  },
  "de": {
    "yes": "Ja",
    "no": "Nicht",
    "areYouSure": "Bist du dir sicher ?",
    "delete": "LÖSCHEN"
  }
}
</i18n>
