<template>
  <div
    v-loading="isLoadingText && !isThreadUpdate"
    @keydown="saveContent($event)"
  >
    <slot name="title" />
    <div class="flex-box">
      <el-card class="left">
        <el-select
          v-if="adminStatusSelectLink"
          v-model="adminStatusSelectLink.form[0].value"
          size="medium"
          style="margin-bottom: 0rem"
          @change="handleAdminStatusSelectChange"
        >
          <el-option
            v-for="item in adminStatusSelectLink.form[0].options"
            :key="item"
            :label="item.label ? item.label : $t(item.toLowerCase())"
            :value="item"
            :disabled="item == 'BRIEF_VALIDATED'"
          >
          </el-option>
        </el-select>
        <div class="actions">
          <el-button
            class="button"
            :disabled="!isTextEditable"
            size="medium"
            type="primary"
            plain
            @click="cancelModifications"
          >
            <i class="fas fa-undo"></i>
          </el-button>
          <el-badge
            is-dot
            style="margin-left: 10px"
            :hidden="isOldValueEqualsActualValue"
          >
            <el-button
              id="saveButton"
              class="button"
              :disabled="!isTextEditable"
              size="medium"
              type="primary"
              plain
              @click="updateText(textLinks.update)"
            >
              <i class="fas fa-save"></i>
            </el-button>
          </el-badge>
          <div class="buttons">
            <el-button
              type="primary"
              style="margin-right: 0.5rem"
              plain
              @click="refreshScores()"
            >
              {{ $t("testContent") }}
            </el-button>
            <el-tooltip
              class="item"
              :disabled="isOldValueEqualsActualValue"
              effect="dark"
              :content="$t('saveChangeBeforeValiditing')"
              placement="top-start"
            >
              <span>
                <el-button
                  v-if="data.links.submit"
                  class="button"
                  :disabled="!isOldValueEqualsActualValue"
                  size="medium"
                  type="success"
                  @click="changeStatus(data.links.submit)"
                >
                  <i class="fas fa-check"></i> {{ $t("validate") }}
                </el-button>
              </span>
            </el-tooltip>
            <el-button
              v-if="data.links.launchWriting"
              class="button"
              size="medium"
              type="success"
              @click="changeStatus(data.links.launchWriting)"
            >
              <i class="fas fa-check"></i> {{ $t("launchWriting") }}
            </el-button>
            <el-button
              v-if="data.links.accept"
              class="button"
              :disabled="!isOldValueEqualsActualValue"
              size="medium"
              type="success"
              @click="changeStatus(data.links.accept)"
            >
              <i class="fas fa-check"></i> {{ $t("closeWriting") }}
            </el-button>
            <el-button
              v-if="data.links.clientSubmit"
              class="button"
              size="medium"
              type="success"
              @click="changeStatus(data.links.clientSubmit)"
            >
              <i class="fas fa-check"></i> {{ $t("submitToClient") }}
            </el-button>
            <el-button
              v-if="data.links.clientAccept"
              class="button"
              size="medium"
              type="success"
              @click="changeStatus(data.links.clientAccept)"
            >
              <i class="fas fa-check"></i> {{ $t("accept") }}
            </el-button>
            <el-button
              v-if="data.links.requestModifications"
              class="button"
              size="medium"
              type="danger"
              @click="changeStatus(data.links.requestModifications)"
            >
              <i class="fas fa-times"></i> {{ $t("requestModifications") }}
            </el-button>
            <el-button
              v-if="data.links.clientRequestModifications"
              class="button"
              size="medium"
              type="danger"
              @click="changeStatus(data.links.clientRequestModifications)"
            >
              <i class="fas fa-times"></i>
              {{ $t("requestModificationsToTheAdmin") }}
            </el-button>
            <el-button
              v-if="data.links.publish"
              class="button"
              :disabled="!isOldValueEqualsActualValue"
              size="medium"
              type="success"
              @click="showUrlDialog(data.links.publish)"
            >
              <i class="fas fa-check"></i> {{ $t("publish") }}
            </el-button>
          </div>
        </div>
        <div class="tags-inputs">
          <div class="input">
            <span class="input-title">{{ $t("tagTitle") }}</span>
            <el-input
              v-model="tagTitle"
              :readonly="!isTextEditable"
            >
            </el-input>
          </div>
          <div
            class="input"
            style="margin-left: 1rem"
          >
            <span class="input-title">{{ $t("tagMetaDescription") }}</span>
            <el-input
              v-model="tagMetaDescription"
              :readonly="!isTextEditable"
            >
            </el-input>
          </div>
        </div>
        <html-view-switch
          @html-view-changed="isShowHtmlView = !isShowHtmlView"
        />
        <el-card
          shadow="never"
          class="editor"
          style="width: 100%"
        >
          <TiptapEditor
            v-model="contentText"
            :is-editable="isTextEditable"
            :is-public="isPublic"
            :threads-endpoint="threads"
            :height="textEditorheight"
            :is-show-html-view="isShowHtmlView"
            @createOrDeleteThread="updateText(textLinks.update, $event)"
          >
          </TiptapEditor>
        </el-card>
      </el-card>
      <div
        v-loading="isLoadingContentOptimization"
        class="right"
      >
        <el-card style="margin-bottom: 1rem">
          <seo-scores
            :own-score="Math.round(seoScore)"
            :serp-score="Math.round(serpSeoScore)"
          >
          </seo-scores>
        </el-card>
        <el-card class="tabs">
          <el-tabs
            v-model="activeTab"
            :stretch="true"
          >
            <el-tab-pane
              name="performances"
              :label="$t('Performances')"
            >
              <seo-tags-scores
                :h1-scores="h1Scores"
                :title-scores="titleScores"
                :meta-desc-scores="metaDescScores"
                :own-h1="ownH1"
              >
              </seo-tags-scores>
              <el-divider class="divider" />
              <content-length-scores
                :own-score="Math.round(contentLengthScores.ownScore)"
                :serp-score="Math.round(contentLengthScores.serpScore)"
                :asked-content-length="data.data.contentLength"
                :content-length="contentLength"
              >
              </content-length-scores>
              <el-divider class="divider" />
              <content-relevance-scores
                :own-score="Math.round(contentRelevanceScores.ownScore)"
                :serp-score="Math.round(contentRelevanceScores.serpScore)"
              >
              </content-relevance-scores>
              <el-divider class="divider" />
              <keywords-to-use-collapse
                :recommended-keywords="recommendedKeywords"
                :people-also-ask-keywords="peopleAlsoAskKeywords"
                :related-keywords="relatedKeywords"
                @collapse-change-event="keywordsToUseCollapseEvent"
              >
              </keywords-to-use-collapse>
            </el-tab-pane>
            <!-- <el-tab-pane name="informations" :label="$t('Informations')">
                        </el-tab-pane> -->
            <el-tab-pane
              name="inspirations"
              :label="$t('inspirations')"
            >
              <serp-titles
                class="inspiration-category"
                :serp-titles="serpTitles"
              >
              </serp-titles>
              <serp-meta-descs
                class="inspiration-category"
                :serp-meta-descs="serpMetaDescs"
              >
              </serp-meta-descs>
              <serp-h1s
                class="inspiration-category"
                :serp-h1s="serpH1s"
              >
              </serp-h1s>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </div>
    <publish-dialog
      v-model="urlDialogVisible"
      @confirm="publish($event)"
    >
    </publish-dialog>
  </div>
</template>

<script>
import KeywordsToUseCollapse from "@/components/ContentOptimization/KeywordsToUseCollapse";
import SeoScores from "@/components/ContentOptimization/SeoScores";
import SeoTagsScores from "@/components/ContentOptimization/SeoTagsScores";
import ContentLengthScores from "@/components/ContentOptimization/ContentLengthScores";
import ContentRelevanceScores from "@/components/ContentOptimization/ContentRelevanceScores";
import SerpTitles from "@/components/ContentOptimization/SerpTitles";
import SerpH1s from "@/components/ContentOptimization/SerpH1s";
import SerpMetaDescs from "@/components/ContentOptimization/SerpMetaDescs";
import PublishDialog from "@/components/CampaignManager/PublishDialog";
import { contentOptimizationMixin } from "@/mixins/contentOptimizationMixin";
import TiptapEditor from "@/components/TiptapV2";
import HtmlViewSwitch from "@/components/CampaignManager/HtmlViewSwitch";

export default {
  components: {
    TiptapEditor,
    KeywordsToUseCollapse,
    SeoScores,
    SeoTagsScores,
    ContentLengthScores,
    ContentRelevanceScores,
    SerpTitles,
    SerpMetaDescs,
    SerpH1s,
    PublishDialog,
    HtmlViewSwitch
  },
  mixins: [contentOptimizationMixin],
  props: {
    data:{
      type:Object,
      default: () => null,
    },
    isLoadingText: Boolean,
    isStandAlone: Boolean,
    isPublic: Boolean,
    closeDialogCallback:{
      type:Function,
      default:() => null
    },
  },
  data() {
    return {
      isShowHtmlView: false,
      oldValue: null,
      contentText: this.data.data.text ? this.data.data.text.text : null,
      threads: {
        createCommentThread: this.data.links.text.createCommentThread
          ? this.data.links.text.createCommentThread
          : null,
        listCommentThreads: this.data.links.text.listCommentThreads
          ? this.data.links.text.listCommentThreads
          : null,
      },
      activeTab: "performances",
      urlDialogVisible: false,
      tagMetaDescription: this.data.data.text
        ? this.data.data.text.metaDescription
        : null,
      tagTitle: this.data.data.text ? this.data.data.text.title : null,
      adminStatusSelectLink: null,
      publishLink: null,
      isThreadUpdate: false,
      textEditorheight: 1250,
    };
  },
  computed: {
    isTextEditable() {
      if (this.data.links.text && this.data.links.text.update) {
        return true;
      }
      return false;
    },
    isOldValueEqualsActualValue() {
      if (!this.data.data.text) {
        if (
          this.tagMetaDescription != null ||
          this.tagTitle != null ||
          this.oldValue != this.contentText
        )
          return false;
        return true;
      }
      if (this.data.data.text) {
        return (
          this.oldValue == this.contentText &&
          this.data.data.text.metaDescription == this.tagMetaDescription &&
          this.data.data.text.title == this.tagTitle
        );
      }
      return true;
    },
    textLinks() {
      return this.data.links.text;
    },
  },
  watch: {
    data() {
      if (this.data.links.updateStatus) {
        this.adminStatusSelectLink = this.data.links.updateStatus;
        this.threads = {
          createCommentThread: this.data.links.text.createCommentThread
            ? this.data.links.text.createCommentThread
            : null,
          listCommentThreads: this.data.links.text.listCommentThreads
            ? this.data.links.text.listCommentThreads
            : null,
        };
      }
      this.isThreadUpdate = false;
    },
    closeDialogCallback(newVal, oldVal) {
      if (newVal) {
        newVal(
          this.isOldValueEqualsActualValue,
          this.updateText,
          this.textLinks.update
        );
      }
    },
  },
  mounted() {
    this.oldValue = this.data.data.text ? this.data.data.text.text : null;
    this.refreshScores();
    if (this.data.links.updateStatus) {
      this.adminStatusSelectLink = this.data.links.updateStatus;

      if (
        this.adminStatusSelectLink.form[0].value == "BRIEF_VALIDATED" &&
        !this.adminStatusSelectLink.form[0].options.find(
          (value) => value == "BRIEF_VALIDATED"
        )
      ) {
        this.adminStatusSelectLink.form[0].options.unshift(
          this.adminStatusSelectLink.form[0].value
        );
      }
    }
  },
  methods: {
    saveContent($event) {
      if ($event.ctrlKey && $event.key === "s") {
        $event.preventDefault();
        this.updateText(this.textLinks.update);
      }
    },
    refreshScores() {
      if (this.isStandAlone) {
        this.fetchPublicContentContentOptimization(
          this.tagMetaDescription,
          this.tagTitle,
          this.contentText,
          this.data.data.publicId
        );
      } else {
        this.fetchContentOptimization(
          this.data.data.id,
          this.tagMetaDescription,
          this.tagTitle,
          this.contentText
        );
      }
    },
    updateText(link, isThreadUpdate = false) {
      let details = {};
      details.entity = {
        text: this.contentText,
        metaDescription: this.tagMetaDescription,
        title: this.tagTitle,
      };
      details.link = link;
      if (isThreadUpdate) details.entity.isThreadUpdate = true;
      this.oldValue = this.contentText;
      this.refreshScores();
      this.$emit("update-text", details);
      this.isThreadUpdate = isThreadUpdate;
    },
    changeStatus(link) {
      let details = {};
      details.link = link;
      details.entity = { status: link.form[0].value };
      this.$emit("update-content", details);
    },
    cancelModifications() {
      this.contentText = this.oldValue;
      this.tagTitle = this.data.data.text ? this.data.data.text.title : null;
      this.tagMetaDescription = this.data.data.text
        ? this.data.data.text.metaDescription
        : null;
    },
    publish(form) {
      this.urlDialogVisible = false;
      let details = {};
      details.link = this.publishLink;
      details.entity = {
        status: this.publishLink.form[0].value,
        url: form.url,
        publishDate: form.publishDate,
      };
      this.$emit("update-content", details);
    },
    showUrlDialog(link) {
      this.urlDialogVisible = true;
      this.publishLink = link;
    },
    handleAdminStatusSelectChange() {
      if (this.adminStatusSelectLink.form[0].value == "PUBLISHED") {
        this.showUrlDialog(this.adminStatusSelectLink);
      } else {
        this.changeStatus(this.adminStatusSelectLink);
      }
    },
    keywordsToUseCollapseEvent(openeditems) {
      switch (openeditems.length) {
        case 0:
          this.textEditorheight = 750;
          break;
        default:
          this.textEditorheight = 1250;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-badge__content.is-fixed.is-dot {
  right: 5px;
}
::v-deep .el-badge__content.is-dot {
  height: 14px;
  width: 14px;
  padding: 0;
  right: 0;
  border-radius: 50%;
}
.flex-box {
  display: flex;
  flex-direction: horizontal;

  .right {
    flex: 2;

    .inspiration-category {
      margin-bottom: 0.7rem;
    }

    .el-divider {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .tabs {
      ::v-deep .el-card__body {
        padding: 5px 20px 20px 20px;
      }
    }
  }

  .left {
    margin-right: 1rem;
    flex: 5;

    .input-title {
      font-size: 1.05rem;
    }

    .actions {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 1rem;

      .buttons {
        margin-left: auto;
        align-self: flex-end;
      }
    }

    .tags-inputs {
      display: flex;
      flex-direction: "horizontal";
      margin-bottom: 1rem;

      .input {
        flex: 1;
        flex-grow: 1;
      }
    }
  }
}
</style>

<i18n>
{
    "en": {
        "switchHtmlView":"display the Html view",
        "modify": "Modify",
        "save": "Save",
        "validate": "Validate the text",
        "cancelModifications": "Cancel modifications",
        "testContent": "Refresh scores",
        "launchWriting": "Launch writing",
        "closeWriting": "Close writing",
        "requestModifications": "Request modifications",
        "requestModificationsToTheAdmin": "Request modifications to the admin",
        "submitToClient": "Submit to client",
        "accept": "Accept",
        "linkAdd": "Add link",
        "inspirations": "Inspirations",
        "publish": "Publish",
        "tagMetaDescription": "Meta Description tag",
        "tagTitle": "Title tag",
        "content": "Content",
        "draft": "Draft",
        "writing": "Being written",
        "accepted": "Text validated",
        "review": "Being proofread",
        "published": "Text published",
        "modificating": "Being modified",
        "finished_writing": "Writing completed",
        "brief_draft": "Brief in draft",
        "brief_submitted": "Brief to validate",
        "brief_validated": "Brief validated",
        "client_review": "Text to validate",
        "client_modificating": "Change requested",
        "client_finished_writing": "Text validated",
        "saveChangeBeforeValiditing": "You need to save your changes before sending them"
    },
    "fr": {
        "switchHtmlView":"Afficher la vue HTML",
        "modify": "Modifier",
        "save": "Sauvegarder",
        "validate": "Valider le texte",
        "textSaved": "Texte sauvegardé",
        "cancelModifications": "Annuler les modifications",
        "testContent": "Rafraîchir les scores",
        "launchWriting": "Lancer la rédaction",
        "closeWriting": "Fermer la rédaction",
        "requestModifications": "Demander des modifications",
        "requestModificationsToTheAdmin": "Demander des modifications à l'admin",
        "submitToClient": "Envoyer au client",
        "accept": "Accepter",
        "linkAdd": "Ajouter un lien",
        "inspirations": "Inspirations",
        "publish": "Publier",
        "tagMetaDescription": "Balise Méta-description",
        "tagTitle": "Balise Titre",
        "content": "Contenu",
        "draft": "Brouillon",
        "writing": "En cours de rédaction",
        "accepted": "Texte validé",
        "review": "En cours de relecture",
        "published": "Texte publié",
        "modificating": "En cours de modification",
        "finished_writing": "Écriture terminée",
        "brief_draft": "Brief en brouillon",
        "brief_submitted": "Brief à valider",
        "brief_validated": "Brief validé",
        "client_review": "Texte à valider",
        "client_modificating": "Demande de modification",
        "client_finished_writing": "Texte validé",
        "saveChangeBeforeValiditing": "Vous devez enregistrer vos modifications avant de les envoyer"
    },
    "de": {
        "switchHtmlView":"Zeigt die HTML-Ansicht an",
        "modify": "Ändern",
        "save": "Speichern",
        "validate": "Text validieren",
        "cancelModifications": "Änderungen verwerfen",
        "testContent": "Ergebnisse aktualisieren",
        "launchWriting": "Schreiben starten",
        "closeWriting": "Schreiben schließen",
        "requestModifications": "Änderungen anfordern",
        "requestModificationsToTheAdmin": "Änderungen beim Administrator anfordern",
        "submitToClient": "An Client senden",
        "accept": "Akzeptieren",
        "linkAdd": "Link hinzufügen",
        "inspirations": "Inspirationen",
        "publish": "Veröffentlichen",
        "tagMetaDescription": "Meta-Beschreibungs-Tag",
        "tagTitle": "Titel-Tag",
        "content": "Inhalt",
        "draft": "Entwurf",
        "writing": "Geschrieben werden",
        "accepted": "Akzeptiert",
        "review": "Wird Korrektur gelesen",
        "published": "Text veröffentlicht",
        "modificating": "Wird geändert",
        "finished_writing": "Schreiben abgeschlossen",
        "brief_draft": "Kurzfassung im Entwurf",
        "brief_submitted": "Brief eingereicht",
        "brief_validated": "Brief validiert",
        "client_review": "SMS gesendet",
        "client_modificating": "Änderung angefordert",
        "client_finished_writing": "Text validiert",
        "saveChangeBeforeValiditing": "Sie müssen Ihre Änderungen speichern, bevor Sie sie senden können"
    },
    "es": {
        "modify": "Modificar",
        "save": "Guardar",
        "validate": "Validar el texto",
        "cancelModifications": "Cancelar modificaciones",
        "testContent": "Actualizar puntajes",
        "launchWriting": "Iniciar escritura",
        "closeWriting": "Cerrar escritura",
        "requestModifications": "Solicitar modificaciones",
        "requestModificationsToTheAdmin": "Solicitar modificaciones al administrador",
        "submitToClient": "Enviar al cliente",
        "accept": "Aceptar",
        "linkAdd": "Agregar enlace",
        "inspirations": "Inspiraciones",
        "publish": "Publicar",
        "tagMetaDescription": "Etiqueta de meta descripción",
        "tagTitle": "Etiqueta de título",
        "content": "Contenido",
        "draft": "Borrador",
        "writing": "Siendo escrito",
        "accepted": "Aceptado",
        "review": "Siendo corregido",
        "published": "Texto publicado",
        "modificating": "Siendo modificado",
        "finished_writing": "Escritura completada",
        "brief_draft": "Resumen en borrador",
        "brief_submitted": "Resumen enviado",
        "brief_validated": "Resumen validado",
        "client_review": "Texto enviado",
        "client_modificating": "Cambio solicitado",
        "client_finished_writing": "Texto validado",
        "saveChangeBeforeValiditing": "Necesitas guardar tus cambios antes de enviarlos"
    },
    "nl": {
        "modify": "Wijzigen",
        "save": "Opslaan",
        "validate": "Bevestig de tekst",
        "cancelModifications": "Wijzigingen annuleren",
        "testContent": "Scores vernieuwen",
        "launchWriting": "Start schrijven",
        "closeWriting": "Schrijven sluiten",
        "requestModifications": "Wijzigingen aanvragen",
        "requestModificationsToTheAdmin": "Verzoek wijzigingen aan de beheerder",
        "submitToClient": "Indienen bij klant",
        "accept": "Accepteren",
        "linkAdd": "Link toevoegen",
        "inspirations": "Inspiraties",
        "publish": "Publiceren",
        "tagMetaDescription": "Metabeschrijvingstag",
        "tagTitle": "Titeltag",
        "content": "inhoud",
        "draft": "Concept",
        "writing": "Geschreven worden",
        "accepted": "Geaccepteerd",
        "review": "Beproefd worden",
        "published": "Tekst gepubliceerd",
        "modificating": "Wordt gewijzigd",
        "finished_writing": "Schrijven voltooid",
        "brief_draft": "Kort in concept",
        "brief_submitted": "Brief ingediend",
        "brief_validated": "Korte gevalideerd",
        "client_review": "Tekst verzonden",
        "client_modificating": "Wijziging aangevraagd",
        "client_finished_writing": "Tekst gevalideerd",
        "saveChangeBeforeValiditing": "U moet uw wijzigingen opslaan voordat u ze verzendt"
    },
    "it": {
        "modify": "Modifica",
        "save": "Salva",
        "validate": "Convalida il testo",
        "cancelModifications": "Annulla modifiche",
        "testContent": "Aggiorna punteggi",
        "launchWriting": "Avvia la scrittura",
        "closeWriting": "Chiudi scrittura",
        "requestModifications": "Richiedi modifiche",
        "requestModificationsToTheAdmin": "Richiedi modifiche all'amministratore",
        "submitToClient": "Invia al cliente",
        "accept": "Accettare",
        "linkAdd": "Aggiungi collegamento",
        "inspirations": "Ispirazioni",
        "publish": "Pubblica",
        "tagMetaDescription": "Meta descrizione tag",
        "tagTitle": "Tag del titolo",
        "content": "Contenuto",
        "draft": "Bozza",
        "writing": "Essere scritto",
        "accepted": "Accettato",
        "review": "Essere corretto",
        "published": "Testo pubblicato",
        "modificating": "In corso di modifica",
        "finished_writing": "Scrittura completata",
        "brief_draft": "Breve in bozza",
        "brief_submitted": "Breve inviato",
        "brief_validated": "Breve convalidato",
        "client_review": "Testo inviato",
        "client_modificating": "Modifica richiesta",
        "client_finished_writing": "Testo convalidato",
        "saveChangeBeforeValiditing": "Devi salvare le modifiche prima di inviarle"
    }
}
</i18n>
