<template>
  <div>
    <div v-if="keywordReady">
      <span>{{ params.valueFormatted }}</span>
      <el-tag
        v-if="progression != null"
        :type="type"
        size="mini"
        disable-transitions
      >
        <i
          class="fas"
          :class="caretDirection"
        ></i>
        <span v-if="this.progression != 0">{{ progression }}</span>
      </el-tag>
    </div>
    <div v-else-if="keywordNoData">
      <span>
        {{ $t("no_data") }}
      </span>
    </div>
    <div v-else>
      <span>
        {{ $t("calculation_in_progress") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      progression: null,
    };
  },
  computed: {
    type() {
      if (this.progression > 0) {
        return "success";
      } else if (this.progression < 0) {
        return "danger";
      } else {
        return "info";
      }
    },
    caretDirection() {
      if (this.progression > 0) {
        return "fa-arrow-up";
      } else if (this.progression < 0) {
        return "fa-arrow-down";
      } else {
        return "fa-equals";
      }
    },
    keywordReady() {
      return this.params.data.status == "READY" || !this.params.data.status;
    },
    keywordNoData() {
      return this.params.data.status == "NO_DATA";
    },
  },
  mounted() {
    if (this.params.data.pastPosition && this.params.data.position) {
      this.progression =
        this.params.data.pastPosition - this.params.data.position;
    }
  },
};
</script>

<i18n>
{
  "en": {
    "calculation_in_progress": "Calculation in progress",
    "no_data": "No data as of this date"
  },
  "fr": {
    "calculation_in_progress": "Calcul en cours",
    "no_data": "Pas de donnée à cette date"
  },
  "de": {
    "calculation_in_progress": "Berechnung läuft",
    "no_data": "Keine Daten an diesem Datum"
  }
}
</i18n>
