<template>
  <div
    v-loading="isLoadingText"
    @keydown="saveBacklinkText($event)"
  >
    <slot name="title" />
    <div class="flex-box">
      <el-card class="left">
        <el-select
          v-if="adminStatusSelectLink"
          v-model="adminStatusSelectLink.form[0].value"
          size="medium"
          style="margin-bottom: 0rem"
          @change="handleAdminStatusSelectChange"
        >
          <el-option
            v-for="item in adminStatusSelectLink.form[0].options"
            :key="item"
            :label="item.label ? item.label : $t(item.toLowerCase())"
            :value="item"
          >
          </el-option>
        </el-select>
        <div class="actions">
          <el-button
            class="button"
            :disabled="!isTextEditable"
            size="medium"
            type="primary"
            plain
            @click="cancelModifications"
          >
            <i class="fas fa-undo"></i>
          </el-button>
          <el-badge
            is-dot
            style="margin-left: 10px"
            :hidden="isOldValueEqualsActualValue"
          >
            <el-button
              class="button"
              :disabled="!isTextEditable"
              size="medium"
              type="primary"
              plain
              @click="updateText(textLinks.update)"
            >
              <i class="fas fa-save"></i>
            </el-button>
          </el-badge>
          <div class="buttons">
            <div v-if="submitToClientAction">
              <el-tooltip
                v-if="!areAllFieldsFilled"
                :content="$t('allFieldsNeedToBeFilled')"
                placement="top"
              >
                <div>
                  <el-button
                    class="button"
                    :disabled="true"
                    size="medium"
                    type="success"
                    @click="changeStatus(submitToClientAction)"
                  >
                    <i class="fas fa-check"></i> {{ $t("submitToClient") }}
                  </el-button>
                </div>
              </el-tooltip>
              <el-button
                v-else-if="submitToClientAction"
                class="button"
                size="medium"
                type="success"
                @click="changeStatus(submitToClientAction)"
              >
                <i class="fas fa-check"></i> {{ $t("submitToClient") }}
              </el-button>
            </div>
            <el-button
              v-if="backlinkData.actions && backlinkData.actions.validateText"
              class="button"
              size="medium"
              type="success"
              @click="changeStatus(backlinkData.actions.validateText)"
            >
              <i class="fas fa-check"></i> {{ $t("validateText") }}
            </el-button>
            <el-button
              v-if="backlinkData.actions && backlinkData.actions.refuseText"
              class="button"
              size="medium"
              type="danger"
              @click="changeStatus(backlinkData.actions.refuseText)"
            >
              <i class="fas fa-times"></i> {{ $t("requestModifications") }}
            </el-button>
          </div>
        </div>
        <div class="tags-inputs">
          <div class="input">
            <span class="input-title">{{ $t("tagTitle") }}</span>
            <el-input
              v-model="tagTitle"
              :readonly="!isTextEditable"
            >
            </el-input>
          </div>
          <div
            class="input"
            style="margin-left: 1rem"
          >
            <span class="input-title">{{ $t("tagMetaDescription") }}</span>
            <el-input
              v-model="tagMetaDescription"
              :readonly="!isTextEditable"
            >
            </el-input>
          </div>
        </div>
        <html-view-switch 
          @html-view-changed="isShowHtmlView = !isShowHtmlView" 
        />
        <el-card
          shadow="never"
          class="editor"
          style="width: 100%"
        >
          <TiptapEditor
            v-model="backlinkText"
            :height="500"
            :is-editable="isTextEditable"
            :threads-endpoint="threads"
            :is-public="isPublic"
            :is-show-html-view="isShowHtmlView"
            @createOrDeleteThread="updateText(textLinks.update)"
          >
          </TiptapEditor>
        </el-card>
      </el-card>
    </div>
    <publication-pending-form-dialog
      v-model="isDialogPublicationPendingVisible"
      @submit-form="changeStatusToPublicationPending($event)"
    >
    </publication-pending-form-dialog>
  </div>
</template>

<script>
import TiptapEditor from "@/components/TiptapV2";
import PublicationPendingFormDialog from "@/components/CampaignManager/PublicationPendingFormDialog";
import HtmlViewSwitch from "@/components/CampaignManager/HtmlViewSwitch";

export default {
  components: {
    TiptapEditor,
    PublicationPendingFormDialog,
    HtmlViewSwitch
  },
  props: {
    data: {
      type:Object,
      default:()=> null
    },
    links: {
      type:Object,
      default:()=> null
    },
    backlinkData: {
      type:Object,
      default:()=> null
    },
    isLoadingText: Boolean,
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowHtmlView: false,
      oldValue: null,
      backlinkText: this.data ? this.data.text : "",
      tagMetaDescription: this.data ? this.data.metaDescription : "",
      tagTitle: this.data ? this.data.title : "",
      adminStatusSelectLink: null,
      isDialogPublicationPendingVisible: false,
      threads: {
        createCommentThread: this.links.createCommentThread
          ? this.links.createCommentThread
          : null,
        listCommentThreads: this.links.listCommentThreads
          ? this.links.listCommentThreads
          : null,
      },
    };
  },
  computed: {
    submitToClientAction() {
      if (
        this.backlinkData.actions &&
        this.backlinkData.actions.submitToClient
      ) {
        return this.backlinkData.actions.submitToClient;
      } else if (
        this.backlinkData.links &&
        this.backlinkData.links.submitToClient
      ) {
        return this.backlinkData.links.submitToClient;
      }
      return null;
    },
    isTextEditable() {
      if (this.links && this.links.update) {
        return true;
      }
      return false;
    },
    isOldValueEqualsActualValue() {
      if (!this.data.text) {
        if (
          this.tagMetaDescription != "" ||
          this.tagTitle != "" ||
          this.oldValue != this.backlinkText
        )
          return false;
        return true;
      }
      if (this.data.text) {
        return (
          this.oldValue == this.backlinkText &&
          this.data.metaDescription == this.tagMetaDescription &&
          this.data.title == this.tagTitle
        );
      }
      return true;
    },
    textLinks() {
      return this.links;
    },
    areAllFieldsFilled() {
      return (
        this.backlinkText &&
        this.backlinkText != "" &&
        this.tagMetaDescription &&
        this.tagMetaDescription != "" &&
        this.tagTitle &&
        this.tagTitle != ""
      );
    },
  },
  watch: {
    data() {
      if (this.backlinkData && this.backlinkData.actions) {
        this.adminStatusSelectLink = this.backlinkData.actions.updateStatus;
      }
    },
    links() {
      this.threads = {
        createCommentThread: this.links.createCommentThread
          ? this.links.createCommentThread
          : null,
        listCommentThreads: this.links.listCommentThreads
          ? this.links.listCommentThreads
          : null,
      };
    },
  },
  mounted() {
    this.oldValue = this.data ? this.data.text : "";
    if (this.backlinkData && this.backlinkData.actions) {
      this.adminStatusSelectLink = this.backlinkData.actions.updateStatus;
    }
  },
  methods: {
    saveBacklinkText($event) {
      if ($event.ctrlKey && $event.key === "s") {
        $event.preventDefault();
        this.updateText(this.textLinks.update);
      }
    },
    updateText(link) {
      let details = {};
      details.entity = {
        text: this.backlinkText,
        metaDescription: this.tagMetaDescription,
        title: this.tagTitle,
      };
      details.link = {
        href: link.href,
        method: link.meta ? link.meta.method : link.method,
      };
      this.oldValue = this.backlinkText;
      this.$emit("update-text", details);
    },
    cancelModifications() {
      this.backlinkText = this.oldValue;
      this.tagTitle = this.data.title ? this.data.title : "";
      this.tagMetaDescription = this.data.metaDescription
        ? this.data.metaDescription
        : "";
    },
    handleAdminStatusSelectChange() {
      const link = this.adminStatusSelectLink;
      const status = link.form[0].value;
      if (status == "PUBLICATION_PENDING") {
        this.isDialogPublicationPendingVisible = true;
      } else {
        let details = {};
        details.link = link;
        details.entity = { status: link.form[0].value };
        this.$emit("update-backlink", details);
      }
    },
    changeStatusToPublicationPending(form) {
      this.isRefuseBacklinkDialogVisible = false;
      let details = {};
      details.link = this.adminStatusSelectLink;
      details.entity = {
        reasonOfDelay: form.reasonOfDelay,
        detailsOfDelay: form.detailsOfDelay,
        status: "PUBLICATION_PENDING",
      };
      this.$emit("update-backlink", details);
    },
    changeStatus(link) {
      this.updateText(this.textLinks.update);
      let details = {};
      details.link = link;
      details.entity = {
        status: link.form.status ? link.form.status : link.form[0].value,
      };
      this.$emit("update-backlink", details);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-badge__content.is-fixed.is-dot {
  right: 5px;
}
::v-deep .el-badge__content.is-dot {
  height: 14px;
  width: 14px;
  padding: 0;
  right: 0;
  border-radius: 50%;
}
.flex-box {
  display: flex;
  flex-direction: horizontal;

  .right {
    flex: 2;

    .inspiration-category {
      margin-bottom: 0.7rem;
    }

    .el-divider {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .tabs {
      ::v-deep .el-card__body {
        padding: 5px 20px 20px 20px;
      }
    }
  }

  .left {
    margin-right: 1rem;
    flex: 5;

    .input-title {
      font-size: 1.05rem;
    }

    .actions {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 1rem;

      .buttons {
        margin-left: auto;
        align-self: flex-end;
      }
    }

    .tags-inputs {
      display: flex;
      flex-direction: "horizontal";
      margin-bottom: 1rem;

      .input {
        flex: 1;
        flex-grow: 1;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "switchHtmlView":"display the Html view",
    "modify": "Article being modified",
    "save": "Save",
    "textSaved": "Text saved",
    "linkAdd": "Add link",
    "tagMetaDescription": "Meta Description tag",
    "tagTitle": "Title tag",
    "content": "Content",
    "saveChangeBeforeValiditing": "You need to save your changes before sending them",
    "draft": "Draft",
    "to_validate": "Domain to validate",
    "canceled": "Backlink canceled",
    "refused": "Domain refused",
    "validated": "Domain validated",
    "redaction": "Article in writing",
    "article_to_validate": "Article to validate",
    "article_validated": "Article validated",
    "published": "Backlink published",
    "validateText": "Validate the article",
    "requestModifications": "Request modifications",
    "submitToClient": "Submit to client",
    "allFieldsNeedToBeFilled": "All the fields need to be filled",
    "publication_pending": "Pending publication"
  },
  "fr": {
    "switchHtmlView":"Afficher la vue HTML",
    "modify": "Article en cours de modification",
    "save": "Sauvegarder",
    "textSaved": "Texte sauvegardé",
    "linkAdd": "Ajouter un lien",
    "tagMetaDescription": "Balise Méta-description",
    "tagTitle": "Balise Titre",
    "content": "Contenu",
    "saveChangeBeforeValiditing": "Vous devez enregistrer vos modifications avant de les envoyer",
    "draft": "Brouillon",
    "to_validate": "Domaine à valider",
    "canceled": "Backlink annulé",
    "refused": "Domaine refusé",
    "validated": "Domaine validé",
    "redaction": "Article en rédaction",
    "article_to_validate": "Article à valider",
    "article_validated": "Article validé",
    "published": "Backlink publié",
    "validateText": "Valider l'article",
    "requestModifications": "Demander des modifications",
    "submitToClient": "Envoyer au client",
    "allFieldsNeedToBeFilled": "Les balises Méta-description et Title ainsi que le contenu doivent être remplis.",
    "publication_pending": "Publication en attente"
  },
  "de": {
    "switchHtmlView":"Zeigt die HTML-Ansicht an",
    "modify": "Artikel wird geändert",
    "save": "Zu schützen",
    "textSaved": "Gespeicherter Text",
    "linkAdd": "Link hinzufügen",
    "tagMetaDescription": "Meta-Beschreibungs-Tag",
    "tagTitle": "Titel-Tag",
    "content": "Inhalt",
    "saveChangeBeforeValiditing": "Sie müssen Ihre Änderungen speichern, bevor Sie sie senden",
    "draft": "Desorganisiert",
    "to_validate": "Zu validierende Domäne",
    "canceled": "Backlink abgebrochen",
    "refused": "Domäne abgelehnt",
    "validated": "Validierte Domäne",
    "redaction": "Artikel schriftlich",
    "article_to_validate": "Zu validierender Artikel",
    "article_validated": "Artikel validiert",
    "published": "Backlink veröffentlicht",
    "validateText": "Bestätigen Sie den Artikel",
    "requestModifications": "Änderungen anfordern",
    "submitToClient": "an den Kunden senden",
    "allFieldsNeedToBeFilled": "Die Meta-Beschreibung und Titel-Tags sowie der Inhalt müssen ausgefüllt werden.",
    "publication_pending": "Veröffentlichung steht bevor"
  }
}
</i18n>
