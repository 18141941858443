<template>
  <div
    class="item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <el-card
      @click.native="$emit('show-task', task)"
      class="task-card"
      :class="task.priority"
      shadow="hover"
    >
      <div class="task-header-and-actions">
        <div class="left-part">
          <el-tooltip
            v-if="task.priority"
            :content="$t('priority') + ' : ' + $t(task.priority)"
            placement="right"
          >
            <div
              v-if="task.priority"
              class="priority"
              :class="task.priority.toLowerCase()"
            ></div>
          </el-tooltip>
          <div class="task-card-title">
            <div style="display: flex; justify-content: space-between">
              <div>{{ task.title }}</div>
              <div
                v-if="task.companyName"
                style="float: right"
              >
                {{ task.companyName }}
              </div>
            </div>
          </div>
        </div>
        <div class="right-part">
          <task-action-dropdown
            :task="task"
            :is-hover="hover"
            :isTaskCard="true"
            v-on="$listeners"
          >
          </task-action-dropdown>
        </div>
      </div>
      <div class="task-card-details">
        <el-tag
          v-if="task.kind"
          type="primary"
          size="mini"
          ><i :class="taskKindIcon(task.kind)"></i>
          {{ $t(task.kind.toLowerCase()) }}</el-tag
        >
        <el-tag
          v-if="task.keywords.length > 0"
          type="info"
          size="mini"
          ><i class="fas fa-crosshairs"></i>
          {{ taskKeywordsString(task) }}</el-tag
        >
        <el-tag
          v-if="task.page"
          type="info"
          size="mini"
          ><i class="fas fa-link"></i> {{ task.page.url | formatUrl }}</el-tag
        >
        <el-tag
          v-if="task.dueDate"
          :type="setDueDateType(task)"
          size="mini"
          ><i class="fas fa-clock"></i> {{ task.dueDate | formatDate }}</el-tag
        >
        <el-tooltip
          v-if="task.assignedToUser"
          :content="assignedUserFullName(task)"
          placement="bottom"
        >
          <div class="user">{{ assignedUserInitials(task) }}</div>
        </el-tooltip>
      </div>
    </el-card>
  </div>
</template>

<script>
import { formatDate } from "@/javascripts/formatDate";
import TaskActionDropdown from "./TaskActionDropdown";

export default {
  components: {
    TaskActionDropdown,
  },
  props: {
    task: Object,
    userOptions: Array,
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    taskKindIcon(kind) {
      return {
        CONTENT: "fas fa-align-left",
        TECHNICAL: "fas fa-cogs",
        NETLINKING: "fas fa-project-diagram",
        DESIGN: "fas fa-drafting-compass",
        GLOBAL: "fas fa-globe",
      }[kind];
    },
    taskKeywordsString(task) {
      let keywordsString = "";
      task.keywords.map((keyword) => {
        keywordsString = keywordsString + ", " + keyword.text;
      });
      return keywordsString.substring(1);
    },
    assignedUserFullName(task) {
      return task.assignedToUser.firstName + " " + task.assignedToUser.lastName;
    },
    assignedUserInitials(task) {
      return (
        task.assignedToUser.firstName[0] + "." + task.assignedToUser.lastName[0]
      );
    },
    setDueDateType(task) {
      if (task.status === "COMPLETED") return "info";
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const taskDate = new Date(task.dueDate);
      taskDate.setHours(0, 0, 0, 0);
      if (today > taskDate) {
        return "danger";
      } else if (today.getTime() === taskDate.getTime()) {
        return "warning";
      } else {
        return "info";
      }
    },
  },
  filters: {
    formatUrl(value) {
      const result = value.match(/[^\/]+(?=\/$|$)/)[0];
      if (result.length > 30) {
        return result.substring(0, 30) + "...";
      } else {
        return result;
      }
    },
    formatDate(value) {
      return formatDate(value, "D MMMM");
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/variables";

.task-card {
  min-height: 40px;
  cursor: pointer;
  border: 2px solid;
  border-color: #c0c0c0;
  border-radius: 5px;

  .task-header-and-actions {
    margin-bottom: 1rem;
    display: flex;

    .left-part {
      margin-right: auto;

      .priority {
        border-radius: 25em;
        height: 8px;
        width: 60px;
        margin-bottom: 3px;
      }
    }

    .right-part {
      margin-left: auto;
    }
  }

  &:hover {
    border-color: #3c54a5;
  }

  ::v-deep .el-card__body {
    padding: 0.5rem;
  }

  .medium {
    background-color: #00ace6;
  }
  .high {
    background-color: #ff8c00;
  }
  .low {
    background-color: #59b300;
  }
  .critical {
    background-color: #ff1a1a;
  }

  .task-card-details {
    display: flex;
    flex-wrap: wrap;

    .el-tag {
      margin: 0rem 0.25rem 0.25rem 0rem;
    }

    .user {
      flex: 0 0 auto;
      margin-left: auto;
      margin-right: 5px;
      background-color: $--color-info-light;
      border-radius: 25em;
      height: 28px;
      width: 28px;
      line-height: 28px;
      text-align: center;
      font-weight: 500;
    }
  }
}
</style>
