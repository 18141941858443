<template>
  <div>
    <search-form @submit="updateRequest"></search-form>
    <metrics
      v-if="data"
      :data="data"
    ></metrics>
    <el-card
      v-if="data"
      shadow="never"
    >
      <grid
        :data="data.keywordsBroad"
        :account="account"
      ></grid>
    </el-card>
  </div>
</template>

<script>
import { keywordsSearchMixin } from "@/mixins/keywordsSearchMixin";
import SearchForm from "@/pages/KeywordsSearch/AdwordsDimension/SearchForm";
import Metrics from "@/pages/KeywordsSearch/Shared/Metrics";
import Grid from "@/pages/KeywordsSearch/Shared/Grid";

export default {
  mixins: [keywordsSearchMixin],
  components: {
    SearchForm,
    Metrics,
    Grid,
  },
};
</script>

<i18n>
{
  "en": {
    "loading": "Loading",
    "create_search_fail": "An error occurred"
  },
  "fr": {
    "loading": "Chargement en cours",
    "create_search_fail": "An error occurred"
  },
  "de": {
    "loading": "Wird geladen",
    "create_search_fail": "Ein Fehler ist aufgetreten"
  }
}
</i18n>
