<template>
  <div>
    <div class="chart-title">
      <span class="chart-name">{{ $t("netlinking") }}</span>
    </div>
    <el-row
      type="flex"
      justify="space-around"
      :gutter="20"
    >
      <el-col :span="6">
        <el-card
          class="kpi"
          shadow="never"
        >
          <div v-if="competitor.domain">
            <strong
              ><div class="domain-name">
                {{ $t("referring_domains") }}
              </div></strong
            >
            <div class="data">
              {{ competitor.domain }} :
              {{
                setNumberFormat(competitor.averageReferrinDomainsValue)
                  | formatNumber
              }}
            </div>
            <div class="data">
              {{ self.domain }} :
              {{
                setNumberFormat(self.averageReferrinDomainsValue) | formatNumber
              }}
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card
          class="kpi"
          shadow="never"
        >
          <div v-if="self.domain">
            <strong
              ><div class="domain-name">
                {{ $t("domain_authority") }}
              </div></strong
            >
            <div class="data">
              {{ competitor.domain }} :
              {{
                setNumberFormat(competitor.averageDomainAuthorityValue)
                  | formatNumber
              }}
            </div>
            <div class="data">
              {{ self.domain }} :
              {{
                setNumberFormat(self.averageDomainAuthorityValue) | formatNumber
              }}
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <highcharts
      ref="highchart"
      v-if="isMounted"
      v-loading="isLoading"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

import { epochDayToDateTime } from "@/javascripts/dateHelpers";

export default {
  props: {
    data: Array,
    isLoading: Boolean,
    studyUrl: String,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      isMounted: false,
      competitor: {
        domain: null,
      },
      self: {
        domain: null,
        averageBacklinksValue: null,
      },
      chartOptions: {
        chart: {
          type: "line",
        },
        title: {
          text: "",
        },
        xAxis: {
          type: "datetime",
          labels: {
            formatter: function () {
              return new Date(this.value)
                .toLocaleString({ month: "2-digit", day: "2-digit" })
                .substring(0, 10);
            },
          },
        },
        yAxis: [
          {
            title: {
              text: this.$i18n.t("referring_domains"),
            },
          },
          {
            title: {
              text: this.$i18n.t("domain_authority"),
            },
            opposite: true,
            min: 0,
            max: 100,
          },
        ],
        series: [],
        tooltip: {
          formatter: function () {
            return (
              new Date(this.x)
                .toLocaleString({ month: "2-digit", day: "2-digit" })
                .substring(0, 10) +
              "<br>" +
              "<b>" +
              this.series.name +
              " : " +
              this.y +
              "</b>"
            );
          },
        },
        legend: {
          maxHeight: 100,
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    setChart() {
      this.mapData();
      this.setSeries();
    },
    mapData() {
      const studyUrl = this.studyUrl;
      const indexCompetitor = this.data.indexOf(
        this.data.find(function (element) {
          return element.url.url != studyUrl;
        })
      );
      this.competitor.domain = this.data[indexCompetitor].url.url;
      const lastDateCompetitor = Object.keys(
        this.data[indexCompetitor].history
      )[Object.keys(this.data[indexCompetitor].history).length - 1];
      this.competitor.averageDomainAuthorityValue =
        this.data[indexCompetitor].history[lastDateCompetitor].domainAuthority;
      this.competitor.averageReferrinDomainsValue =
        this.data[indexCompetitor].history[
          lastDateCompetitor
        ].referringDomainsWithEquity;

      this.self.domain = this.data[1 - indexCompetitor].url.url;
      const lastDateSelf = Object.keys(this.data[1 - indexCompetitor].history)[
        Object.keys(this.data[1 - indexCompetitor].history).length - 1
      ];
      this.self.averageDomainAuthorityValue =
        this.data[1 - indexCompetitor].history[lastDateSelf].domainAuthority;
      this.self.averageReferrinDomainsValue =
        this.data[1 - indexCompetitor].history[
          lastDateSelf
        ].referringDomainsWithEquity;
    },
    setSeries() {
      this.chartOptions.series = [];
      this.data.map((urlHistory) => {
        const domainName = urlHistory.url.url;
        const dataDa = [];
        const dataReferringDomains = [];

        for (const [key, value] of Object.entries(urlHistory.history)) {
          dataDa.push({
            x: new Date(key).getTime(),
            y: value.domainAuthority,
          });

          dataReferringDomains.push({
            x: new Date(key).getTime(),
            y: value.referringDomainsWithEquity,
          });
        }

        this.chartOptions.series.push({
          name: domainName + " (domains)",
          data: dataReferringDomains,
          yAxis: 0,
          visible: false,
        });
        this.chartOptions.series.push({
          name: domainName + " (DA)",
          data: dataDa,
          yAxis: 1,
        });
      });
    },
    setNumberFormat(number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
  watch: {
    data() {
      if (this.data) this.setChart();
    },
  },
  mounted() {
    this.isMounted = true;
    if (this.data) this.setChart();
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";
.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;

  .chart-name {
    font-size: 1.5rem;
  }
}

::v-deep .kpi {
  text-align: center;
  margin-bottom: 1rem;
}

::v-deep .domain-name {
  font-size: 1rem;
  margin-bottom: 0.65rem;
}

::v-deep .data {
  font-size: 0.9rem;
}
</style>

<i18n>
{
  "en": {
    "number_of_backlinks": "Number of backlinks",
    "backlinks": "Backlinks",
    "referring_domains": "Referring domains",
    "domain_authority": "Domain authority",
    "netlinking": "Netlinking"
  },
  "fr": {
    "number_of_backlinks": "Nombre de backlinks",
    "referring_domains": "Domaines référents",
    "backlinks": "Backlinks",
    "domain_authority": "Authorité de domaine",
    "netlinking": "Netlinking"
  },
  "de": {
    "number_of_backlinks": "Backlink-Name",
    "referring_domains": "Verweisende Domains",
    "backlinks": "Backlinks",
    "domain_authority": "Domänenautorität",
    "netlinking": "Netzverlinkung"
  }
}
</i18n>
