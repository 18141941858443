<template>
  <div class="action">
    <div class="icons">
      <div
        class="icon"
        v-if="params.data.actions.validate"
      >
        <el-button
          type="success"
          icon="el-icon-success"
          circle
          size="mini"
          @click.stop="validateBacklink()"
        >
        </el-button>
      </div>
      <div
        class="icon"
        v-if="params.data.actions.refuse"
      >
        <el-button
          type="danger"
          icon="el-icon-error"
          circle
          size="mini"
          @click.stop="refuseBacklink()"
        >
        </el-button>
      </div>
      <div class="icon clipboard">
        <el-tooltip
          effect="dark"
          placement="top"
          :content="$t('showBrief')"
        >
          <el-button
            type="primary"
            plain
            icon="el-icon-notebook-2"
            circle
            size="mini"
            @click.stop="showBrief()"
          ></el-button>
        </el-tooltip>
      </div>
      <div
        class="icon clipboard"
        v-if="params.data.actions.text"
      >
        <el-tooltip
          effect="dark"
          placement="top"
          :content="$t('consultText')"
        >
          <el-button
            type="primary"
            plain
            icon="el-icon-tickets"
            circle
            size="mini"
            @click.stop="showTextDialog()"
          ></el-button>
        </el-tooltip>
      </div>
      <div
        class="icon edit"
        v-if="params.data.actions.update"
      >
        <el-tooltip
          effect="dark"
          placement="top"
          :content="$t('modify')"
        >
          <el-button
            type="primary"
            plain
            icon="el-icon-edit"
            circle
            size="mini"
            @click.stop="editBacklink()"
          ></el-button>
        </el-tooltip>
      </div>
      <el-popconfirm
        class="icon"
        v-if="params.data.actions.delete"
        :confirmButtonText="$t('yes')"
        :cancelButtonText="$t('no')"
        icon="el-icon-info"
        iconColor="red"
        :title="$t('areYouSure')"
        @onConfirm="deleteBacklink()"
      >
        <el-tooltip
          slot="reference"
          effect="dark"
          placement="top"
          :content="$t('delete')"
        >
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            circle
            size="mini"
            @click.stop=""
          ></el-button>
        </el-tooltip>
      </el-popconfirm>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      admin: true,
    };
  },
  methods: {
    showBrief() {
      this.params.context.showBriefDialog(this.params.data);
    },
    editBacklink() {
      this.params.context.editBacklink(this.params.data.actions);
    },
    showTextDialog() {
      this.params.context.showTextDialog(this.params.data);
    },
    validateBacklink() {
      let details = {};
      details.link = this.params.data.actions.validate;
      details.entity = { status: "VALIDATED" };
      this.params.context.changeStatus(details);
    },
    refuseBacklink() {
      this.params.context.showDeleteBacklinkDialog(this.params.data);
    },
    deleteBacklink() {
      let details = {};
      details.link = this.params.data.actions.delete;
      this.params.context.deleteBacklink(details);
    },
  },
};
</script>

<style lang="scss" scoped>
.icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .fas {
    cursor: pointer;
  }

  .far {
    cursor: pointer;
  }
}

.icon {
  margin-right: 1px;
}
</style>
<i18n>
{
  "en": {
    "yes": "Yes",
    "no": "No",
    "areYouSure": "Are you sure ?",
    "showBrief": "Consult the brief",
    "consultText": "Consult the text",
    "modify": "Modify",
    "delete": "Delete"
  },
  "fr": {
    "yes": "Oui",
    "no": "Non",
    "areYouSure": "Etes-vous sûr ?",
    "showBrief": "Consulter le brief",
    "consultText": "Consulter le texte",
    "modify": "Modifier",
    "delete": "Supprimer"
  },
  "de": {
    "yes": "Ja",
    "no": "Nicht",
    "areYouSure": "Bist du dir sicher ?",
    "showBrief": "Konsultieren Sie die Kurzbeschreibung",
    "consultText": "Konsultieren Sie den Text",
    "modify": "Modifikator",
    "delete": "LÖSCHEN"
  }
}
</i18n>
