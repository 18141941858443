<template>
  <div>
    <el-popover
      v-model="addTagsPopoverVisible"
      ref="popover"
      class="popover-button"
      popper-class="action-popover"
      placement="bottom-start"
      width="auto"
    >
      <div>
        <el-select
          v-model="form.tags"
          multiple
          filterable
          allow-create
          default-first-option
          :placeholder="$t('select_tags')"
          size="mini"
        >
          <el-option
            v-for="item in tagsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          size="mini"
          @click="keywordsImport"
          >{{ $t("import") }}</el-button
        >
      </div>
    </el-popover>
    <el-button
      v-if="canImportKeywords"
      plain
      type="primary"
      v-popover:popover
      >{{ $t("keywords_import") }}</el-button
    >
    <el-tooltip
      v-else
      :content="$t('selectionnedCountryDiffersFromStudyDefaultSearchEngine')"
      placement="top"
    >
      <div style="display: inline-block">
        <el-button
          v-popover:popover
          :disabled="true"
          plain
          type="primary"
          >{{ $t("keywords_import") }}</el-button
        >
      </div>
    </el-tooltip>
    <el-button
      plain
      type="primary"
      @click="excelExport"
      >{{ $t("excel_export") }}</el-button
    >
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const KeywordsRepository = RepositoryFactory.get("keywords");

export default {
  props: {
    tags: Array,
    canImportKeywords: Boolean,
    columnApi: Object,
    gridApi: Object,
    account: Object,
  },
  data() {
    return {
      addTagsPopoverVisible: false,
      tagsOptions: [],
      form: {
        tags: [],
      },
    };
  },
  methods: {
    setTagOptions() {
      this.tagsOptions = this.tags.map((tag) => {
        return { value: tag.name, label: tag.name };
      });
    },
    excelExport() {
      const columnKeys = this.columnApi
        .getAllDisplayedColumnGroups()
        .map((columnGroup) => {
          if (columnGroup.colId !== "actions") {
            return columnGroup.colId;
          }
        });
      const params = {
        skipHeader: false,
        columnKeys: columnKeys,
        onlySelected: false,
        fileName: "Keywords Search Export - SmartKeyword",
        sheetName: "Keywords Search",
      };
      params.shouldRowBeSkipped = function (params) {
        return params.node.group == true;
      };
      this.gridApi.exportDataAsExcel(params);
    },
    keywordsImport() {
      this.addTagsPopoverVisible = false;
      const selectedNodes = this.gridApi.getSelectedNodes();
      const texts = selectedNodes.map((node) => node.data.keyword);
      this.$emit("importKeywords", { keywords: texts, tags: this.form.tags });
    },
  },
  beforeMount() {
    this.setTagOptions();
  },
};
</script>

<i18n>
{
  "en": {
    "excel_export": "Export Excel",
    "keywords_import": "Import selected keywords",
    "selection": "Selection",
    "select_tags": "Select tags",
    "import": "Import",
    "selectionnedCountryDiffersFromStudyDefaultSearchEngine": "The selected country is different from the study country"
  },
  "fr": {
    "excel_export": "Export excel",
    "keywords_import": "Importer les mots clés sélectionnés",
    "selection": "Selection",
    "select_tags": "Seléctionnez les groupes de mots clés",
    "import": "Importer",
    "selectionnedCountryDiffersFromStudyDefaultSearchEngine": "Le pays sélectionné est différent du pays de l'étude"
  },
  "de": {
    "excel_export": "Excel exportieren",
    "keywords_import": "Importieren Sie ausgewählte Schlüsselwörter",
    "selection": "Auswahl",
    "select_tags": "Wählen Sie Keyword-Gruppen aus",
    "import": "Importeur",
    "selectionnedCountryDiffersFromStudyDefaultSearchEngine": "Das ausgewählte Land unterscheidet sich vom Studienland"
  }
}
</i18n>
