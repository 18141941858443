<template>
  <div>
    <div
      v-if="!isActionPlanDialog"
      class="d-flex justify-content-between element mb-2"
    >
      <div>
        <span class="category-title"> {{ $t("contentLength") }}:</span>
        <span>
          {{ contentLength }}/<span v-loading="!askedContentLength"
            >{{ askedContentLength }} {{ $t("words") }}</span
          ></span
        >
      </div>
      <el-tooltip
        :content="$t('tooltipContentLength')"
        placement="top"
      >
        <i class="fas fa-question-circle my-auto"></i>
      </el-tooltip>
    </div>
    <div class="seo-scores">
      <div :class="scoreClass">
        <el-progress
          :class="progressClass"
          type="dashboard"
          :percentage="ownScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div :class="textClass">
          {{ isActionPlanDialog ? $t("yourScore") : $t("myScore") }}
        </div>
      </div>
      <el-divider
        direction="vertical"
        class="vertical-divider"
      />
      <div :class="scoreClass">
        <el-progress
          :class="progressClass"
          type="dashboard"
          :percentage="serpScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div :class="textClass">
          {{ isActionPlanDialog ? $t("serpScore") : $t("serp") }}
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ownScore: Number,
    serpScore: Number,
    askedContentLength: Number,
    contentLength: Number,
    isActionPlanDialog: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      colors: [
        { color: "#FF0000", percentage: 20 },
        { color: "#FFCC00", percentage: 40 },
        { color: "#4ECDC4", percentage: 60 },
        { color: "#66FF00", percentage: 80 },
        { color: "#008000", percentage: 100 },
      ],
    };
  },
  methods: {
    format(percentage) {
      return percentage;
    },
  },
  computed: {
    scoreClass() {
      if (this.isActionPlanDialog)
        return "d-flex flex-column-reverse align-items-center element seo-score text-dialog mx-5";
      else return "element seo-score";
    },
    textClass() {
      if (this.isActionPlanDialog) return "text-dialog mb-3";
      else return "text";
    },
    progressClass() {
      if (this.isActionPlanDialog) return "large-dashboard";
      else return "mini-dashboard";
    },
  },
};
</script>

<style lang="scss" scoped>
.seo-scores {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  white-space: nowrap;
  .vertical-divider {
    height: unset;
  }
  .text-dialog {
    text-align: center;
    font-size: 1.2rem;
    word-break: break-word;
  }
  .element {
    flex: 1;
  }

  .seo-score {
    text-align: center;

    .text {
      text-align: center;
      font-size: 0.9rem;
      word-break: break-word;
    }

    .mini-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 0.8rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .large-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 1.2rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 75px !important;
        height: 75px !important;
      }
    }
  }
}
.category-title {
  font-weight: bold;
}
</style>

<i18n>
{
    "en": {
        "contentLength": "Content length",
        "ownContentLength": "My content length",
        "serpContentLength": "SERP content length",
        "words": "words",
        "serp": "SERP",
        "serpScore":"SERP Score",
        "yourScore": "Your score",
        "myScore": "My score",
        "tooltipContentLength":"This score compares your content size to the median text of the top SERP results"
    },
    "fr": {
        "contentLength": "Longueur de contenu",
        "ownContentLength": "Ma longueur de contenu",
        "serpContentLength": "Longueur de contenu de la serp",
        "words": "mots",
        "serp": "SERP",
        "serpScore":"Score de la SERP",
        "yourScore": "Votre score",
        "myScore": "Mon score",
        "tooltipContentLength":"Ce score compare votre taille de contenu à la médiane des textes des meilleurs résultats de la SERP"
    },
    "it": {
        "contentLength": "Lunghezza del contenuto",
        "ownContentLength": "Lunghezza del mio contenuto",
        "serpContentLength": "Lunghezza contenuto SERP",
        "words": "parole",
        "serp": "SERP",
        "myScore": "Il mio punteggio",
        "tooltipContentLength":"Questo punteggio confronta la dimensione del tuo contenuto con il testo mediano dei primi risultati SERP"
    },
    "es": {
        "contentLength": "Longitud del contenido",
        "ownContentLength": "La longitud de mi contenido",
        "serpContentLength": "Longitud del contenido SERP",
        "words": "palabras",
        "serp": "SERP",
        "myScore": "Mi puntuación",
        "tooltipContentLength":"Esta puntuación compara el tamaño de su contenido con el texto medio de los principales resultados SERP"
    },
    "de": {
        "contentLength": "Inhaltslänge",
        "ownContentLength": "Meine Inhaltslänge",
        "serpContentLength": "SERP-Inhaltslänge",
        "words": "wörter",
        "serp": "SERP",
        "myScore": "Meine Punktzahl",
        "tooltipContentLength":"Diese Punktzahl vergleicht Ihre Inhaltsgröße mit dem mittleren Text der besten SERP-Ergebnisse"
    },
    "nl": {
        "contentLength": "Inhoudslengte",
        "ownContentLength": "Mijn inhoudslengte",
        "serpContentLength": "SERP-inhoudslengte",
        "words": "woorden",
        "serp": "SERP",
        "myScore": "Mijn score",
        "tooltipContentLength":"Deze score vergelijkt uw inhoudsgrootte met de mediane tekst van de beste SERP-resultaten"
    }
}
</i18n>
