export const gridPagination = {
  props: {},
  components: {},
  data() {
    return {
      firstPageDisabled: true,
      nextPageDisabled: true,
      lastPageDisabled: true,
      firstIndex: 0,
      lastIndex: 0,
      pageNumber: 1,
    };
  },
  methods: {
    nextPage() {
      this.gridApi.paginationGoToNextPage();
    },
    previousPage() {
      this.gridApi.paginationGoToPreviousPage();
    },
    firstPage() {
      this.gridApi.paginationGoToFirstPage();
    },
    lastPage() {
      this.gridApi.paginationGoToLastPage();
    },
    onPaginationChanged() {
      if (this.gridApi) {
        this.updatePagination();
      }
    },
    updatePagination() {
      this.pageNumber = this.gridApi.paginationGetCurrentPage() + 1;
      this.firstIndex =
        1 +
        this.gridApi.paginationGetPageSize() *
          this.gridApi.paginationGetCurrentPage();
      this.lastIndex = this.getLastIndex();
      this.setFirstPageDisabled(this.gridApi.paginationGetCurrentPage() == 0);
      if (this.gridApi.paginationGetTotalPages())
        this.setNextPageDisabled(
          this.gridApi.paginationGetTotalPages() ==
            this.gridApi.paginationGetCurrentPage() + 1
        );
      this.setLastPageDisabled(!this.gridApi.paginationIsLastPageFound());
    },
    setFirstPageDisabled(disabled) {
      this.firstPageDisabled = disabled;
    },
    setLastPageDisabled(disabled) {
      this.lastPageDisabled = disabled;
    },
    setNextPageDisabled(disabled) {
      this.nextPageDisabled = disabled;
    },
    getLastIndex() {
      if (
        this.gridApi.paginationIsLastPageFound() &&
        this.gridApi.paginationGetTotalPages() ==
          this.gridApi.paginationGetCurrentPage() + 1
      ) {
        return this.gridApi.paginationGetRowCount();
      } else {
        return (
          this.gridApi.paginationGetPageSize() *
          (this.gridApi.paginationGetCurrentPage() + 1)
        );
      }
    },
    getFirstIndex() {
      return (
        this.gridApi.paginationGetPageSize() *
        this.gridApi.paginationGetCurrentPage()
      );
    },
    setNewPaginationSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
  },
};
