<template>
  <el-select
    v-model="searchEnginesInput"
    :placeholder="$t('search engine')"
  >
    <template slot="prefix">
      <div class="flex-box">
        <span v-if="value.device === 'MOBILE'"
          ><i class="fas fa-mobile-alt"></i
        ></span>
        <span v-else><i class="fas fa-desktop"></i></span>
      </div>
    </template>
    <el-option
      v-for="item in study.searchEngines"
      :key="item.id"
      :label="searchEngineDisplayName(value)"
      :value="item"
    >
      <span
        v-if="item.device === 'MOBILE'"
        class="align-center"
        ><i class="fas fa-mobile-alt"></i
      ></span>
      <span
        v-else
        class="align-center"
        ><i class="fas fa-desktop"></i
      ></span>
      <span>{{ searchEngineDisplayName(item) }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: Object,
  },
  computed: {
    ...mapState(["study"]),
    searchEnginesInput: {
      get() {
        return this.value;
      },
      set(searchEnginesInput) {
        this.$emit("input", searchEnginesInput);
      },
    },
  },
  methods: {
    searchEngineDisplayName(searchEngine) {
      return (
        "Google (" +
        (searchEngine.device === "MOBILE"
          ? this.$t("mobile")
          : this.$t("desktop")) +
        ")"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  align-items: center;
  height: 100%;
}

.align-center {
  text-align: center;
}

.fas {
  width: 20px;
  color: #000000;
}
</style>

<i18n>
{
  "en": {
    "desktop": "Desktop",
    "mobile": "Mobile",
    "mode": "Frequency",
    "daily": "Daily",
    "weekly": "Weekly"
  },
  "fr": {
    "desktop": "Bureau",
    "mobile": "Mobile",
    "mode": "Périodicité",
    "daily": "Journalier",
    "weekly": "Hebdomadaire"
  },
  "de": {
    "desktop": "Büro",
    "mobile": "Handy, Mobiltelefon",
    "mode": "Periodizität",
    "daily": "Täglich",
    "weekly": "Wöchentlich"
  }
}
</i18n>
