<template>
  <div>
    <h4><i class="fas fa-crosshairs"></i> {{ $t("keywords") }}</h4>
    <ul>
      <li
        v-for="keyword in keywordify(keywords)"
        v-bind:key="keyword.text"
      >
        <el-tag
          closable
          @close="deleteKeyword(keyword)"
          v-bind:class="{ 'is-in-database': keyword.id }"
        >
          <span
            v-if="keyword.id"
            @click="$emit('show-keyword', keyword.id)"
          >
            {{ keyword.text }}
            <i class="far fa-eye"></i>
          </span>
          <span v-else> {{ keyword.text }} </span>
        </el-tag>
      </li>
    </ul>
    <el-select
      class="value"
      v-model="keyword"
      :value-key="'text'"
      :placeholder="$t('keywordsPlaceholder')"
      filterable
      size="medium"
      remote
      :remote-method="fetchKeywordOptions"
      :loading="keywordOptionsLoading"
      allow-create
      @input="addKeyword()"
      @click.native="$emit('click')"
    >
      <el-option
        v-for="keyword in keywordOptionsWithKeywords"
        :key="keyword.text"
        :label="keyword.text"
        :value="keyword"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const KeywordsRepository = RepositoryFactory.get("keywords");

export default {
  props: {
    value: Array,
    studyId: Number,
  },
  data() {
    return {
      keywords: this.value, // this is to hack keywords display
      keyword: { id: null, text: null },
      keywordOptionsLoading: false,
      keywordOptions: [],
    };
  },
  computed: {
    keywordOptionsWithKeywords() {
      // this is to hack keywords display
      // because we are loading keywordOptions from server,
      // selected keywords are not always present in the list,
      // and so not well displayed
      return this.keywordOptions.concat(
        this.keywords.filter(
          (keyword) => !this.keywordOptions.find((k) => k.text === keyword.text)
        )
      );
    },
  },
  methods: {
    fetchKeywordOptions(query) {
      if (query !== "") {
        this.keywordOptionsLoading = true;
        KeywordsRepository.searchKeywords(this.studyId, query).then((data) => {
          this.keywordOptionsLoading = false;
          this.keywordOptions = data.map((keyword) => ({
            id: parseInt(keyword.id),
            text: keyword.text,
            locationId: keyword.locationId,
          }));
        });
      } else {
        this.keywordOptions = [];
      }
    },
    addKeyword() {
      if (typeof this.keyword === "string") {
        this.keyword = { text: this.keyword };
      }
      if (this.keyword.text) {
        this.keywords.push(this.keyword);
        this.$emit("input", this.keywordify(this.keywords));
        this.keyword = { id: null, text: null, locationId: null };
      }
    },
    keywordify(keywords) {
      return keywords.map((keyword) => {
        if (typeof keyword === "string") {
          return { text: keyword };
        } else {
          return keyword;
        }
      });
    },
    deleteKeyword(keyword) {
      this.keywords.splice(this.keywords.indexOf(keyword), 1);
    },
  },
};
</script>

<style scoped>
li {
  margin-bottom: 0.35rem;
}

.is-in-database {
  cursor: pointer;
}
</style>

<i18n>
{
  "en": {
    "keywords": "Keywords",
    "keywordsPlaceholder": "Add keywords.."
  },
  "fr": {
    "keywords": "Mots-clés",
    "keywordsPlaceholder": "Ajouter des mots-clés.."
  },
  "de": {
    "keywords": "Schlüsselwörter",
    "keywordsPlaceholder": "Schlüsselwörter hinzufügen.."
  }
}
</i18n>
