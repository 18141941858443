var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("header"),_vm._v(" "),_c('div',{staticClass:"account"},[_c('div',{staticClass:"traffic"},[_c('div',{staticClass:"container"},[_vm._t("body"),_vm._v(" "),_c('div',[(_vm.accountConfiguration)?_c('div',[(
                _vm.accountConfiguration.data.attributes.graphType == 'traffic' ||
                _vm.accountConfiguration.data.attributes.graphType == 'both'
              )?_c('traffic',{attrs:{"account-configuration-json":_vm.accountConfiguration,"accountId":_vm.accountId,"dimensionsData":_vm.dimensionsData}}):_vm._e(),_vm._v(" "),(
                _vm.accountConfiguration.data.attributes.graphType ==
                  'conversion' ||
                _vm.accountConfiguration.data.attributes.graphType == 'both'
              )?_c('conversion',{attrs:{"account-configuration-json":_vm.accountConfiguration,"accountId":_vm.accountId,"dimensionsData":_vm.dimensionsData}}):_vm._e(),_vm._v(" "),_c('positions',{attrs:{"account-configuration-json":_vm.accountConfiguration,"accountId":_vm.accountId,"companyId":_vm.companyId,"currentUser":_vm.currentUser}})],1):_vm._e()])],2)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }