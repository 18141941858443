<template>
  <div>
    <div class="title">
      {{ $t("domain_authority") }}
    </div>
    <div>
      <highcharts
        ref="highchart"
        :options="chartOptions"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import { epochDayToDateTime } from "@/javascripts/dateHelpers";

export default {
  props: {
    data: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 300,
          zoomType: "x",
        },
        title: {
          text: "",
          useHTML: true,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          title: {
            text: this.$i18n.t("domain_authority"),
          },
          min: 0,
          max: 100,
        },
        tooltip: {
          pointFormatter: function () {
            return "<b>" + this.y + "</b>";
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      if (this.data && Object.keys(this.data.history).length > 0) {
        this.chartOptions.series = [];
        const domainAuthorityData = [];
        for (const [key, value] of Object.entries(this.data.history)) {
          if (value.domainAuthority) {
            domainAuthorityData.push({
              x: new Date(key).getTime(),
              y: value.domainAuthority,
            });
          }
        }

        this.chartOptions.series.push({
          name: this.$i18n.t("domain_authority"),
          data: domainAuthorityData,
        });
      } else {
        this.chartOptions.series = [];
      }
    },
  },
  mounted() {
    this.updateSeries();
  },
  watch: {
    data() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>

<i18n>
{
  "en": {
    "domain_authority": "Domain Authority"
  },
  "fr": {
    "domain_authority": "Domain Authority"
  },
  "de": {
    "domain_authority": "Domänenautorität"
  }
}
</i18n>
