<template>
  <div>
    <chart
      v-loading="isLoading"
      :websitesPositions="websitesPositions"
      :selected-date.sync="selectedDate"
      :date="date"
      :past-date="pastDate"
      :tasks="tasks"
      :backlinks="backlinks"
      :contents="contents"
      :globalNotes="globalNotes"
      :studyNotes="studyNotes"
    >
    </chart>
    <serp
      v-if="initialSerpsResult"
      v-loading="serpResultsIsLoading"
      :position="position"
      :serp-results="serpResults"
      :serp-statistics="serpStatistics"
      :past-serp-results="pastSerpResults"
      :serpDate="serpDate"
    >
    </serp>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
import Chart from "@/pages/KeywordDialog/Positioning/Chart";
import Competitors from "@/pages/KeywordDialog/Positioning/Competitors";
import Serp from "@/pages/KeywordDialog/Positioning/Serp";
import { formatDateWithDashes } from "@/javascripts/formatDate";

export default {
  props: {
    keywordId: Number,
    initialSerpsResult: Array,
    initialPosition: Object,
    initialSerpResults: Array,
    initialSerpStatistics: Object,
    initialPastSerpResults: Array,
    searchEngine: Object,
    date: DateTime,
    pastDate: DateTime,
    frequency: String,
    closestEndDate: String,
    trackedKeyword: Object,
    studyId: Number,
  },
  components: {
    Chart,
    Competitors,
    Serp,
  },
  data() {
    return {
      tasks: [],
      contents: [],
      backlinks: [],
      serpsResults: this.initialSerpsResult,
      serpResults: this.initialSerpResults,
      pastSerpResults: this.initialPastSerpResults,
      serpStatistics: this.initialSerpStatistics,
      position: this.initialPosition,
      websitesPositions: [],
      selectedDate: this.date,
      isLoading: false,
      serpResultsIsLoading: false,
      serpDate: this.closestEndDate,
      studyNotes: [],
      globalNotes: [],
      noteModule: null,
    };
  },
  computed: {
    period() {
      return [this.pastDate, this.date];
    },
    top10positions() {
      return this.serpResults.filter(
        (serpResult) =>
          (serpResult.type == "ORGANIC" ||
            serpResult.type == "FEATURED_SNIPPET" ||
            serpResult.type == "ANSWER_BOX") &&
          serpResult.position <= 10
      );
    },
    top10HostNames() {
      return this.top10positions.map((serpResult) => serpResult.hostName);
    },
    ...mapState(["study"]),
  },
  methods: {
    getPositionsHistory() {
      this.isLoading = true;

      let topHostNames = [...new Set(this.top10HostNames)];
      if (!this.top10HostNames.some((hostName) => hostName == this.study.url)) {
        topHostNames = this.top10HostNames.concat(this.study.url);
      }
      let serpsResultFiltred = this.serpsResults.map(([key, value]) => {
        let a = value.left.filter((website) => {
          return topHostNames.some((hostName) =>
            website.url.includes(hostName)
          );
        });
        return [key, { ...value, left: a }];
      });

      let websitesPositions = [];
      topHostNames.forEach((hostName) => {
        let websitePositions = {};
        websitePositions.domain = hostName;
        websitePositions.positionnedUrls = {};
        serpsResultFiltred.forEach(([key, value]) => {
          const hostPositions = value.left.filter((urlPosition) => {
            try {
              let subdomdains = urlPosition.url;
              if (
                urlPosition.url.startsWith("http") ||
                urlPosition.url.startsWith("https")
              )
                subdomdains = new URL(urlPosition.url).hostname;
              subdomdains = subdomdains.split(".").reverse();
              let hostNameList = hostName.split(".").reverse();
              if (subdomdains.length < hostNameList.length) return false;
              for (let i = 0; i < hostNameList.length; i++) {
                if (hostNameList[i] != subdomdains[i]) return false;
              }
              return true;
            } catch (_) {
              return false;
            }
          });
          hostPositions.forEach((urlPosition) => {
            if (!websitePositions.positionnedUrls[urlPosition.url]) {
              websitePositions.positionnedUrls[urlPosition.url] = {
                positionsByDates: [],
              };
            }
          });
        });
        if (Object.keys(websitePositions.positionnedUrls).length == 0) {
          websitePositions.positionnedUrls.notPositionnedUrl = {
            positionsByDates: [],
          };
        }
        Object.keys(websitePositions.positionnedUrls).forEach((url) => {
          serpsResultFiltred.forEach(([key, value]) => {
            const urlPositionned = value.left.find((website) =>
              website.url.endsWith(url)
            );
            if (urlPositionned) {
              let positionsByDate = {
                date: key,
                position: urlPositionned.position,
                url: url,
              };
              websitePositions.positionnedUrls[url].positionsByDates.push(
                positionsByDate
              );
            } else {
              let positionsByDate = {
                date: key,
                position: 101,
                url: this.$i18n.t("not_positioned"),
              };
              websitePositions.positionnedUrls[url].positionsByDates.push(
                positionsByDate
              );
            }
          });
        });
        websitesPositions.push(websitePositions);
      });

      this.websitesPositions = websitesPositions;
      this.isLoading = false;
    },
    getSerpResults() {
      this.serpResultsIsLoading = true;

      let date = this.selectedDate.toString().split("T")[0];
      let pastDate = this.pastDate.toString().split("T")[0];
      let serpResult = this.initialSerpsResult.filter(([key, value]) => {
        if (key === date) {
          return value;
        }
      })[0][1];
      let pastSerpResult = this.initialSerpsResult.filter(([key, value]) => {
        if (key === pastDate) {
          return value;
        }
      });

      if (pastSerpResult.length == 0) {
        pastSerpResult = this.initialSerpsResult[0][1];
      } else {
        pastSerpResult = pastSerpResult[0][1];
      }

      let requestUrlMetrics = {};
      requestUrlMetrics.urls = this.tenFirstUrlsOfSerp(serpResult);
      let pagesNumberOfWords = new Map();

      this.$api
        .post("/pages/urls", {
          urls: requestUrlMetrics.urls
            .map((url) => url.url)
            .filter((url) => url != null),
        })
        .then((response) => {
          response.data.data.forEach((hash) => {
            pagesNumberOfWords.set(hash.url, hash.contentLength);
          });

          requestUrlMetrics.minDate = formatDateWithDashes(this.pastDate);
          requestUrlMetrics.isoCountryCode = this.searchEngine.isoCountryCode;

          this.$api
            .post(`/netlinking/urls/metrics`, { ...requestUrlMetrics })
            .then((response) => {
              const urlsMetricsData = response.data;
              serpResult.left.map((website) => {
                urlsMetricsData.data.map((urlMetrics) => {
                  if (website.url.includes(urlMetrics.url.url)) {
                    if (urlMetrics.metrics) {
                      website.domain = urlMetrics.url.url;
                      website.domainAuthority =
                        urlMetrics.metrics.domainAuthority;
                      website.externalBacklinks =
                        urlMetrics.metrics.externalBacklinks;
                    }
                  }
                  if (pagesNumberOfWords.has(website.url)) {
                    website.contentLength = pagesNumberOfWords.get(website.url);
                  }
                });
                website.hostName = new URL(website.url).hostname;
              });
              pastSerpResult.left.map((website) => {
                website.hostName = new URL(website.url).hostname;
              });
              this.serpResults = serpResult.left;
              this.pastSerpResults = pastSerpResult.left;
              this.position = this.getPosition(serpResult);
              this.serpStatistics = this.getSerpStatistics(serpResult);
              this.serpDate = this.selectedDate.toString().split("T")[0];

              this.serpResultsIsLoading = false;
            });
        })
        .catch((error) => console.error(error));
    },
    fetchTasks() {
      this.$api
        .post(`/studies/${this.study.id}/tasks/search`, {
          keywordIds: [this.keywordId],
        })
        .then((response) => {
          this.tasks = response.data.data;
        });
    },
    fetchBacklinks() {
      const request = {
        keywordText: this.trackedKeyword.text,
        keywordLocationId: this.trackedKeyword.locationId,
        status: "PUBLISHED",
      };
      this.$api
        .post(`/studies/${this.study.id}/backlinks/search`, request)
        .then((response) => {
          this.backlinks = response.data.data;
        });
    },
    fetchContents() {
      const request = {
        keywordText: this.trackedKeyword.text,
        keywordLocationId: this.trackedKeyword.locationId,
        status: "PUBLISHED",
      };
      this.$api
        .post(`/studies/${this.study.id}/contents/search`, request)
        .then((response) => {
          this.contents = response.data.data;
        });
    },
    tenFirstUrlsOfSerp(serpResult) {
      if (serpResult && serpResult.left.length > 0) {
        return serpResult.left
          .filter((website) => {
            return website.position <= 10;
          })
          .map((website) => {
            return { url: website.url, type: "DOMAIN" };
          });
      }
    },
    getSerpStatistics(serpResult) {
      let top10Position = serpResult.left
        .filter((website) => {
          return website.position <= 10;
        })
        .map((website) => {
          return {
            domainAuthority: website.domainAuthority,
            contentLength: website.contentLength,
          };
        });
      top10Position.sort((a, b) => {
        return a.domainAuthority - b.domainAuthority;
      });
      const minDomainAuthority = top10Position[0].domainAuthority;

      let top10PositionFiltered = top10Position.filter((a) => {
        if (
          a.hasOwnProperty("contentLength") &&
          a.contentLength != null &&
          a.contentLength != undefined
        )
          return a;
      });

      top10PositionFiltered.sort((a, b) => {
        return a.contentLength - b.contentLength;
      });
      const minNumberOfKeywords = top10PositionFiltered[0].contentLength;

      let median = 0;
      const size = top10PositionFiltered.length;
      if (size % 2 == 0) {
        median =
          (top10PositionFiltered[Math.floor((size - 1) / 2)].contentLength +
            top10PositionFiltered[size / 2].contentLength) /
          2;
      } else {
        median = top10PositionFiltered[Math.floor(size / 2)].contentLength;
      }
      const domainAuthority = { min: minDomainAuthority };
      const wordsCount = { min: minNumberOfKeywords, median: median };
      return { domainAuthority: domainAuthority, wordsCount: wordsCount };
    },
    getPosition(serpResult) {
      let position = serpResult.left.filter((website) => {
        return website.url.includes(this.study.url);
      });
      if (position.length == 0) {
        return { domain: this.study.url, position: 101 };
      } else {
        return position.map((website) => {
          return { domain: this.study.url, position: website.position };
        })[0];
      }
    },
    getStudiesNoteModule() {
      this.$api.get(`/studies/${this.studyId}/notes/module`, { headers: { 'Accept': 'application/vnd.api+json' }})
        .then((response) => {
          this.noteModule = response.data.data.attributes

          this.getNotes()
        })
        .catch((error) => {
          console.error(error);
      });
    },
    getGlobalNoteModule() {
      this.$api.get("/notes/global/module", { headers: { 'Accept': 'application/vnd.api+json' }})
        .then((response) => {
          this.globalNotesModule = response.data.data.attributes

          this.getGlobalNotes()
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getGlobalNotes() {
      if (!this.globalNotesModule)
       return
      this.$api[this.globalNotesModule.list.meta.method.toLowerCase()](this.globalNotesModule.list.href, { headers: { 'Accept': 'application/vnd.api+json' } })
        .then((response) => {
          this.globalNotes = (
            response.data.data.map((note) => ({
              id: note.attributes.id,
              title: note.attributes.title,
              comment: note.attributes.comment,
              date: note.attributes.date
            }))
          )
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getNotes(message) {
      if (!this.noteModule)
        return
      const response = await this.$api[this.noteModule.list.meta.method.toLowerCase()](this.noteModule.list.href, { headers: { 'Accept': 'application/vnd.api+json' } })
        .catch((error) => {
          console.error(error);
        });

      if (!response)
        return
      this.studyNotes = response.data.data.map((note) => ({
        id: note.attributes.id,
        title: note.attributes.title,
        comment: note.attributes.comment,
        date: note.attributes.date
      }))
      if (message)
        this.$message.success(this.$i18n.t(message));
    },
    fetchNotes() {
      this.getGlobalNoteModule()
      this.getStudiesNoteModule()
    },
  },
  mounted() {
    this.getPositionsHistory();
    this.fetchTasks();
    this.fetchBacklinks();
    this.fetchContents();
    this.fetchNotes();
  },
  watch: {
    selectedDate() {
      this.getSerpResults();
    },

    searchEngine() {
      this.getPositionsHistory();
      this.getSerpResults();
    },
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "fetch_keyword_data_failure": "Fetching keyword data has failed.",
    "not_positioned": "page not positioned"
  },
  "fr": {
    "fetch_keyword_data_failure": "La récupération des données du mot clé a échoué.",
    "not_positioned": "page non positionnée"
  },
  "de": {
    "fetch_keyword_data_failure": "Keyword-Daten konnten nicht abgerufen werden.",
    "not_positioned": "Seite nicht positioniert"
  }
}
</i18n>
