<template>
  <div v-loading="isLoading">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="170px"
    >
      <el-form-item
        :label="$t('company')"
        prop="companyId"
      >
        <el-select
          v-model="form.companyId"
          filterable
          :placeholder="$t('select_company')"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('url')">
        <el-input v-model="form.url"></el-input>
      </el-form-item>
      <el-form-item :label="$t('custom_name')">
        <el-input v-model="form.customName"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('search_engines')"
        prop="searchEngines"
      >
        <search-engine-selector
          v-model="form.searchEngines"
          :search-engines="availableSearchEngines"
          :studyId="studyId"
        >
        </search-engine-selector>
      </el-form-item>
      <el-form-item :label="$t('study_type')">
        <el-select
          v-if="studyTypes"
          v-model="form.studyType"
        >
          <el-option
            v-for="(studyType, index) in studyTypes"
            :key="index"
            :label="$t(studyType)"
            :value="studyType"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button
        plain
        @click="closeDialog"
        >{{ $t("cancel") }}</el-button
      >
      <el-button
        plain
        type="primary"
        :disabled="!isFormSubmitable"
        @click="submitForm"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { availableSearchEnginesMixin } from "@/mixins/availableSearchEnginesMixin";
import SearchEngineSelector from "@/components/formItems/SearchEngineSelector";
import { RepositoryFactory } from "@/services/repositoryFactory";

const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  mixins: [availableSearchEnginesMixin],
  components: {
    SearchEngineSelector,
  },
  props: {
    studyId: Number,
    studyTypes: Array,
    isCreating: Boolean,
  },
  data() {
    return {
      form: {
        companyId: null,
        url: "",
        customName: "",
        searchEngines: [],
        studyType: "secondary_study"
      },
      companyOptions: [],
      rules: {
        companyId: [
          {
            required: true,
            message: "Please select company",
            trigger: "change",
          },
        ],
        searchEngines: [
          {
            required: true,
            message: "Please select atleast one search engine",
            trigger: "change",
          },
        ],
      },
      isLoading: false,
    };
  },
  computed: {
    defaultSearchEngine() {
      if (this.form.searchEngines.length === 0) {
        return null;
      } else if (this.form.searchEngines.length === 1) {
        return this.form.searchEngines[0];
      } else {
        return this.form.searchEngines.sort((a, b) => {
          if (a.device === b.device) {
            return 0;
          } else if (a.device === "DESKTOP" && b.device === "MOBILE") {
            return -1;
          } else {
            return 1;
          }
        })[0];
      }
    },
    isFormSubmitable() {
      if (this.form.companyId && this.form.searchEngines.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.closeDialog();
          const country = this.form.searchEngines.map(
            (searchEngine) =>
              searchEngine.isoLanguageCode.toLowerCase() +
              "-" +
              searchEngine.isoCountryCode.toLowerCase()
          )[0];
          this.$emit("submit", {
            ...this.form,
            country,
            defaultSearchEngine: this.defaultSearchEngine,
          });
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    setCompanyOptions() {
      CompaniesRepository.getCompanies()
        .then((data) => {
          this.companyOptions = data.data.map((company) => ({
            label: company.attributes.name,
            value: parseInt(company.id),
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadStudy() {
      this.isLoading = true;
      this.$api
        .get("/studies/" + this.studyId)
        .then((response) => {
          const data = response.data.data;
          this.form.companyId = data.companyId;
          this.form.url = data.url;
          this.form.studyType = data.studyType;
          if (data.name) {
            this.form.customName = data.name;
          }
          if (data.searchEngines) {
            this.form.searchEngines = data.searchEngines;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    if (this.studyId) this.loadStudy();
    this.setCompanyOptions();
  },
  watch: {
    studyId() {
      if (this.studyId) this.loadStudy();
    },
  },
};
</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "company": "Company",
    "select_company": "Select company",
    "url": "URL",
    "custom_name": "Custom name",
    "no": "No",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "search_engines": "Search engines",
    "main_study": "Main study",
    "study_type": "Study type",
    "secondary_study": "Secondary study",
    "oneshot_study": "Oneshot study"
  },
  "fr": {
    "company": "Société",
    "select_company": "Sélectionnez la société",
    "url": "URL",
    "custom_name": "Nom personnalisé",
    "no": "Non",
    "cancel": "Annuler",
    "confirm": "Confirmer",
    "search_engines": "Moteurs de recherche",
    "main_study": "Etude principale",
    "study_type": "Type d'étude",
    "secondary_study": "Etude secondaire",
    "oneshot_study": "Etude oneshot"
  },
  "de": {
    "company": "Unternehmen",
    "select_company": "Unternehmen auswählen",
    "url": "URL",
    "custom_name": "benutzerdefinierter Name",
    "no": "Nicht",
    "cancel": "Stornieren",
    "confirm": "Bestätigen",
    "search_engines": "Suchmaschine",
    "main_study": "Hauptstudium",
    "study_type": "Studientyp",
    "secondary_study": "Zweitstudium",
    "oneshot_study": "Oneshot-Studie"
  }
}
</i18n>
