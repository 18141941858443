<template>
  <div>
    <h4><i class="fas fa-comments"></i> {{ $t("comment") }}</h4>
    <comment
      v-for="comment in blocksTypedAsComment"
      :key="comment.id"
      :value="comment"
      :current-user="currentUser"
      :users="userOptions"
      @on-save-comment="saveComments"
      @delete-comment="deleteComment"
    ></comment>
    <comment
      v-model="newComment"
      :current-user="currentUser"
      creating-comment
      @on-save-comment="addNewComment"
      :key="newCommentState"
    ></comment>
  </div>
</template>

<script>
import Comment from "./Comment";
import { DateTime } from "luxon";
import { dateToDateTimeUTC } from "@/javascripts/dateHelpers";

export default {
  components: {
    Comment,
  },
  props: {
    value: Array,
    currentUser: Object,
    userOptions: Array,
  },
  data() {
    return {
      newComment: null,
      newCommentState: true,
    };
  },
  methods: {
    addNewComment(comment) {
      comment.createdAt = new Date();
      comment.updatedAt = comment.createdAt;
      this.$emit("input", this.value.concat(comment));
      this.newComment = null;
      this.newCommentState = !this.newCommentState;
    },
    saveComments() {
      this.$emit("input", this.value);
    },
    deleteComment(comment) {
      this.value.splice(this.value.indexOf(comment), 1);
      this.saveComments();
    },
  },
  computed: {
    blocksTypedAsComment() {
      return this.value.filter((block) => {
        return !block.type || block.type == "comment";
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>

<i18n>
{
  "en": {
    "comments": "Comments"
  },
  "fr": {
    "comment": "Commentaires"
  },
  "de": {
    "comment": "Kommentare"
  }
}
</i18n>
