<template>
  <div>
    <el-row
      type="flex"
      :gutter="20"
    >
      <el-col :span="24">
        <el-card shadow="never">
          <div class="chart">
            <div class="text">{{ $t("seasonality_chart") }}</div>
            <div class="chart">
              <highcharts
                ref="highchart"
                :options="chartOptions"
                :updateArgs="updateArgs"
              ></highcharts>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      maxVolume: null,
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          zoomType: "xy",
          height: 150,
        },
        title: {
          text: "",
        },
        yAxis: {
          title: {
            text: this.$i18n.t("monthly_searches"),
          },
          min: 0,
          max: 100,
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Volume",
            data: [],
          },
        ],
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "%",
        },
      },
    };
  },
  methods: {
    setChartData() {
      this.chartOptions.series[0].data = [];
      let volumes = [];
      volumes = [
        this.data.totalVolume01,
        this.data.totalVolume02,
        this.data.totalVolume03,
        this.data.totalVolume04,
        this.data.totalVolume05,
        this.data.totalVolume06,
        this.data.totalVolume07,
        this.data.totalVolume08,
        this.data.totalVolume09,
        this.data.totalVolume10,
        this.data.totalVolume11,
        this.data.totalVolume12,
      ];

      this.maxVolume = Math.max.apply(Math, volumes);

      this.chartOptions.series[0].data = [
        (this.data.totalVolume01 / this.maxVolume) * 100,
        (this.data.totalVolume02 / this.maxVolume) * 100,
        (this.data.totalVolume03 / this.maxVolume) * 100,
        (this.data.totalVolume04 / this.maxVolume) * 100,
        (this.data.totalVolume05 / this.maxVolume) * 100,
        (this.data.totalVolume06 / this.maxVolume) * 100,
        (this.data.totalVolume07 / this.maxVolume) * 100,
        (this.data.totalVolume08 / this.maxVolume) * 100,
        (this.data.totalVolume09 / this.maxVolume) * 100,
        (this.data.totalVolume10 / this.maxVolume) * 100,
        (this.data.totalVolume11 / this.maxVolume) * 100,
        (this.data.totalVolume12 / this.maxVolume) * 100,
      ];
    },
  },
  watch: {
    data() {
      this.setChartData();
    },
  },
  mounted() {
    this.setChartData();
    this.$refs.highchart.chart.reflow();
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}

.chart {
  max-width: 100%;
  height: 150px;
}

.chart,
.kpi {
  .text {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-align: center;
  }
}

.el-col:nth-child(2) {
  display: flex;
  flex-direction: column;

  .el-card {
    flex-grow: 1;
  }
}

.kpi {
  .value {
    margin-bottom: 0.5rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
  }
}
</style>

<i18n>
{
  "en": {
    "estimated_traffic": "Estimated traffic",
    "seasonality_chart": "Seasonality chart",
    "monthly_searches": "Monthly searches (%)",
    "searches": "Searches"
  },
  "fr": {
    "estimated_traffic": "Traffic estimé",
    "seasonality_chart": "Graphe de saisonnalité",
    "monthly_searches": "Recherches mensuelles (%)",
    "searches": "Recherches"
  },
  "de": {
    "estimated_traffic": "Geschätzter Verkehr",
    "seasonality_chart": "Diagramm zur Saisonalität",
    "monthly_searches": "Monatliche Suchanfragen (%)",
    "searches": "Forschung"
  }
}
</i18n>
