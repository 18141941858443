<template>
  <div class="full-container">
    <h2>{{ $t("conversionMonitoring") }}</h2>
    <p>
      <small>
        <a :href="$t('conversionSetting')">
          <i class="fas fa-question-circle"></i>
          {{ $t("conversionMonitoringDocumentationDesc") }}
        </a>
      </small>
    </p>
    <div class="radio-buttons">
      <el-radio-group v-model="currentMonitoringType">
        <el-radio-button
          v-for="monitoringType in monitoringTypes"
          :label="monitoringType"
          :value="monitoringType"
          >{{ $t(snakeToCamel(monitoringType)) }}
        </el-radio-button>
      </el-radio-group>
      <p>
        <small style="font-style: italic">
          {{ $t("conversionMonitoringTypeHelp") }}
        </small>
      </p>
    </div>
    <div
      class="goal-selection"
      v-model="selectedGoals"
    >
      <el-card
        v-model="selectedGoals"
        :header="$t('goalSelection')"
        v-if="
          currentMonitoringType !== null &&
          currentMonitoringType === 'goals_completions_data'
        "
      >
        <div style="display: flex; justify-content: center">
          <div style="text-align: left">
            <el-checkbox-group
              v-model="selectedGoals"
              class="el-checkbox-group"
              v-for="goal in goals"
              size="mini"
            >
              <el-checkbox
                v-bind:key="goal.id"
                :label="goal.id"
                :checked="goal.selected"
              >
                {{ goal.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-card>
      <el-divider></el-divider>
      <div class="submit-button">
        <el-button
          :loading="isLoading"
          :disabled="canSubmit"
          @click="onGoalsSubmit()"
          >{{ $t("saveModifications") }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/services/repositoryFactory";

const ConfigurationRepository = RepositoryFactory.get("configurations");

export default {
  props: {
    configuration: Object,
    goals: Array,
  },
  data() {
    return {
      currentMonitoringType: null,
      monitoringTypes: ["e_commerce_data", "goals_completions_data", "none"],
      selectedGoals: [],
      isLoading: false,
    };
  },
  mounted() {
    this.currentMonitoringType =
      this.configuration["conversion_monitoring_type"] === null
        ? "none"
        : this.configuration["conversion_monitoring_type"];
  },
  methods: {
    onGoalsSubmit() {
      this.isLoading = true;
      this.configuration["conversion_monitoring_type"] =
        this.currentMonitoringType === "none"
          ? null
          : this.currentMonitoringType;
      const goalsData = {};
      this.goals.forEach((goal) => {
        goal.selected = false;
        goalsData[goal.id] = goal;
      });

      this.selectedGoals.forEach((e) => (goalsData[e].selected = true));
      this.configuration["goals"] = goalsData;
      const data = {
        account_configuration: this.configuration,
        goals: goalsData,
      };
      this.updateMonitoryingTypeGoals(this.configuration["account_id"], data);
    },
    updateMonitoryingTypeGoals(studyId, data) {
      ConfigurationRepository.updateMonitoringTypeGoals(studyId, data)
        .then(() => {
          this.$message.success({
            message: this.$i18n.t("monitoringSettingsSaved"),
            duration: 3000,
            showClose: true,
            offset: 80,
          });
          this.isLoading = false;
          this.$emit("goals-submitted");
        })
        .catch((error) => {
          this.$message.error({
            message: this.$i18n.t("monitoringSettingsErrorDuringSaving"),
            duration: 3000,
            showClose: true,
            offset: 80,
          });
          console.log(error);
          this.isLoading = false;
        });
    },
    snakeToCamel(str) {
      return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
    },
  },
  watch: {
    currentMonitoringType() {
      this.selectedGoals = [];
    },
  },
  computed: {
    canSubmit() {
      return this.currentMonitoringType != "goals_completions_data" ||
        this.selectedGoals.length > 0
        ? false
        : true;
    },
  },
};
</script>

<style scoped lang="scss">
el-checkbox {
  position: relative;
  text-align: left;
  vertical-align: text-bottom;
}

.goal-selection {
  text-align: center;
}

.text-right {
  text-align: right;
}

.radio-buttons {
  text-align: center;
}

.submit-button {
  text-align: center;
}
</style>

<i18n>
{
  "fr": {
    "conversionMonitoring": "Suivi de conversion",
    "conversionSetting": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-conversion/comment-parametrer-le-suivi-de-conversion",
    "conversionMonitoringDocumentationDesc": "Comment paramétrer le suivi de conversion ?",
    "eCommerceData": "Données de commerce électronique",
    "goalsCompletionsData": "Données liées aux complétions d'objectifs",
    "none": "Aucun",
    "goalSelection": "Sélection d'objectifs",
    "conversionMonitoringTypeHelp": "Choisissez comment vous souhaitez calculer les mesures de conversion globales et spécifiques au référencement qui seront montrés au travers de l’application (Conversion, Pages, Mot clés..)",
    "saveModifications": "Enregistrer",
    "monitoringSettingsSaved": "Modifications sauvegardées",
    "monitoringSettingsErrorDuringSaving": "Une erreur est survenue lors de l'enregistrement"
  },
  "en": {
    "conversionMonitoring": "Conversion monitoring",
    "conversionSetting": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-conversion/comment-parametrer-le-suivi-de-conversion",
    "conversionMonitoringDocumentationDesc": "How to configure conversion monitoring ?",
    "eCommerceData": "E-commerce data",
    "goalsCompletionsData": "Goals completions data",
    "none": "None",
    "goalSelection": "Goal selection",
    "conversionMonitoringTypeHelp": "Choose how you want to compute your global and ranking specific conversion metrics that will be showed throughout the application (Conversion, Pages, Keywords...)",
    "saveModifications": "Save modifications",
    "monitoringSettingsSaved": "Settings saved successfully",
    "monitoringSettingsErrorDuringSaving": "An error occured during the saving of the settings"
  },
  "de": {
    "conversionMonitoring": "Conversion-Tracking",
    "conversionSetting": "https://docs.smartkeyword.io/guide-de-smartkeyword/rapports-conversion/comment-parametrer-le-suivi-de-conversion",
    "conversionMonitoringDocumentationDesc": "Wie richte ich Conversion-Tracking ein?",
    "eCommerceData": "E-Commerce-Daten",
    "goalsCompletionsData": "Daten im Zusammenhang mit objektiven Abschlüssen",
    "none": "Keiner",
    "goalSelection": "Objektivauswahl",
    "conversionMonitoringTypeHelp": "Wählen Sie aus, wie Sie die allgemeinen und SEO-spezifischen Conversion-Metriken berechnen möchten, die über die App angezeigt werden (Conversion, Seiten, Keywords usw.).",
    "saveModifications": "Registrieren",
    "monitoringSettingsSaved": "Gespeicherte Änderungen",
    "monitoringSettingsErrorDuringSaving": "Beim Speichern ist ein Fehler aufgetreten"
  }
}
</i18n>
