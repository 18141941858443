<template>
  <div>
    <el-dropdown
      :id="'optimizeKeyword-' + params.data.id"
      split-button
      @click="linkToKeyword"
      trigger="click"
      size="mini"
      v-loading="loadingDocx"
    >
      {{ $t("optimize") }}
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="linkToPdfExport">{{
          $t("editorial_brief_pdf")
        }}</el-dropdown-item>
        <el-dropdown-item @click.native="docxExport">{{
          $t("editorial_brief_docx")
        }}</el-dropdown-item>
        <el-dropdown-item @click.native="linkToAssociatePage"
          >{{ $t("associate_other_page") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="addToAction">{{
          $t("add_to_action")
        }}</el-dropdown-item>
        <el-dropdown-item @click.native="deleteKeyword">{{
          $t("delete")
        }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";

const EditorialBriefRepository = RepositoryFactory.get("editorialBrief");

import docxtemplater from "docxtemplater";
import JSzip from "jszip";
import JSzipUtils from "jszip-utils";
import { saveAs } from "file-saver";

export default {
  components: {},
  data() {
    return {
      keywordId: null,
      studyId: null,
      currentUser: null,
      loadingDocx: false,
    };
  },
  methods: {
    linkToKeyword() {
      window.open(`/a/${this.studyId}/keywords/${this.keywordId}`, "_blank");
    },
    linkToPdfExport() {
      window.open(
        `/a/${this.studyId}/keywords/${this.keywordId}/export.pdf`,
        "_blank"
      );
    },
    loadFile(url, callback) {
      JSzipUtils.getBinaryContent(url, callback);
    },
    docxExport() {
      this.loadingDocx = true;
      const keywordText = this.params.data.keyword;
      EditorialBriefRepository.getDataForEditorialBrief(
        this.studyId,
        this.keywordId
      )
        .then((response) => {
          this.loadingDocx = false;
          const data = response;
          this.loadFile(data.templateUrl, function (error, content) {
            if (error) {
              throw error;
            }
            let zip = new JSzip(content);
            let doc = new docxtemplater().loadZip(zip).setOptions({linebreaks: true});
            doc.setData(data);

            try {
              doc.render();
            } catch (error) {
              let e = {
                message: error.message,
                name: error.name,
                stack: error.stack,
                properties: error.properties,
              };
              console.log(JSON.stringify({ error: e }));
              // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
              throw error;
            }

            let out = doc.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            saveAs(out, keywordText + "_editorial_brief.docx");
          });
        })
        .catch((error) => {
          console.log(error);
          this.loadingDocx = false;
        });
    },
    linkToAssociatePage() {
      this.$prompt(
        this.$i18n.t("input_url"),
        this.$i18n.t("associate_other_page"),
        {
          confirmButtonText: this.$i18n.t("confirm"),
          cancelButtonText: this.$i18n.t("cancel"),
          inputPattern:
            /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
          inputErrorMessage: this.$i18n.t("invald_url"),
        }
      )
        .then(({ value }) => {
          this.params.context.updateKeywordPage(
            this.keywordId,
            value,
            this.params.node
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteKeyword() {
      this.params.context.deleteKeyword(this.keywordId);
    },
    addToAction() {
      this.params.context.showTaskDialog(
        this.params.data.id,
        this.params.data.keyword
      );
    },
  },
  created() {
    this.keywordId = this.params.data.id;
    this.studyId = this.params.context.studyId;
    this.currentUser = this.params.context.currentUser;
  },
};
</script>

<i18n>
{
  "en": {
    "editorial_brief_docx": "DOCX editorial brief ",
    "editorial_brief_pdf": "PDF editorial brief ",
    "optimize": "Optimize",
    "associate_other_page": "Associate another page",
    "input_url": "Input URL",
    "invald_url": "Invalid URL",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "delete": "Delete",
    "add_to_action": "Add a task"
  },
  "fr": {
    "editorial_brief_docx": "Brief éditorial DOCX",
    "editorial_brief_pdf": "Brief éditorial PDF",
    "optimize": "Optimiser",
    "associate_other_page": "Associer autre page",
    "input_url": "Entrez l'URL",
    "invald_url": "URL non valide",
    "confirm": "Confirmer",
    "cancel": "Annuler",
    "delete": "Supprimer",
    "add_to_action": "Ajouter une tâche"
  },
  "de": {
    "editorial_brief_docx": "DOCX-Redaktionsbrief",
    "editorial_brief_pdf": "Kurzes redaktionelles PDF",
    "optimize": "Zu optimieren",
    "associate_other_page": "Andere Seite verknüpfen",
    "input_url": "URL eingeben",
    "invald_url": "ungültige URL",
    "confirm": "Bestätigen",
    "cancel": "Stornieren",
    "delete": "LÖSCHEN",
    "add_to_action": "Aufgabe hinzufügen"
  }
}
</i18n>
