<template>
  <div
    v-on:click.stop
    v-bind:class="{ hidden: !(isHover || isDropdownOpen) }"
  >
    <el-dropdown
      @command="handleCommand"
      @visible-change="(change) => (isDropdownOpen = change)"
    >
      <div
        v-bind:class="{ 'big-font': !isTaskCard }"
        class="dropdown-header"
        shadow="hover"
      >
        <i class="fas fa-ellipsis-h"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-if="!task.archived"
          command="archive"
        >
          <i class="fas fa-archive"></i> {{ $t("archive") }}
        </el-dropdown-item>
        <el-dropdown-item
          v-else
          command="unarchive"
        >
          <i class="fas fa-undo-alt"></i> {{ $t("unarchive") }}
        </el-dropdown-item>
        <el-dropdown-item command="duplicate">
          <i class="far fa-clone"></i> {{ $t("duplicate") }}
        </el-dropdown-item>
        <el-dropdown-item command="delete">
          <i class="fas fa-trash-alt"></i> {{ $t("delete") }}
        </el-dropdown-item>
        <el-dropdown-item
          divided
          command="openNewPage"
        >
          <i class="fas fa-external-link-alt"></i> {{ $t("openNewPage") }}
        </el-dropdown-item>
        <el-dropdown-item command="copyLink">
          <i class="fas fa-link"></i> {{ $t("copyLink") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    isHover: Boolean,
    task: Object,
    isTaskCard: Boolean,
    isStandAlone: Boolean,
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "copyLink":
          this.copyToClipboard();
          break;
        case "openNewPage":
          this.openTaskInNewTab();
          break;
        case "delete":
          this.$emit("delete-task", this.task);
          break;
        case "duplicate":
          this.$emit("duplicate-task", this.task);
          break;
        case "archive":
          this.$emit("archive-task", this.task);
          break;
        case "unarchive":
          this.$emit("unarchive-task", this.task);
          break;
        default:
          break;
      }
    },
    openTaskInNewTab() {
      window.open(
        window.location.origin +
          `/studies/${this.task.studyId}/tasks/${this.task.id}`
      );
    },
    copyToClipboard() {
      const el = document.createElement("textarea");
      el.value =
        window.location.origin +
        `/studies/${this.task.studyId}/tasks/${this.task.id}`;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message({
        message: this.$i18n.t("linkCopiedToClipBoard"),
        type: "success",
        duration: 2000,
        offset: 80,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}

.card-dropdown-header {
  &:hover {
    background-color: #e0e0e0;
  }
}

.big-font {
  font-size: 1.3rem !important;
}

.dropdown-header {
  font-size: 1rem;
  padding: 0.4rem 0.8rem;
  &:hover {
    background-color: #e0e0e0;
  }
}

.fa-ellipsis-h {
  color: #707070;
}
</style>

<i18n>
{
  "en": {
    "archive": "Archive",
    "duplicate": "Duplicate",
    "delete": "Delete",
    "openNewPage": "Open in new page",
    "copyLink": "Copy the link",
    "linkCopiedToClipBoard": "Link copied to clipboard",
    "fail": "We encountered an error",
    "unarchive": "Anarchive"
  },
  "fr": {
    "archive": "Archiver",
    "duplicate": "Dupliquer",
    "delete": "Supprimer",
    "openNewPage": "Ouvrir dans un onglet",
    "copyLink": "Copier le lien",
    "linkCopiedToClipBoard": "Le lien a été copié",
    "fail": "Nous avons rencontré une erreur",
    "unarchive": "Désarchiver"
  },
  "de": {
    "archive": "Archiv",
    "duplicate": "Duplikat",
    "delete": "LÖSCHEN",
    "openNewPage": "In einem Tab öffnen",
    "copyLink": "Link kopieren",
    "linkCopiedToClipBoard": "Der Link wurde kopiert",
    "fail": "Es ist ein Fehler aufgetreten",
    "unarchive": "Unarchivieren"
  }
}
</i18n>
