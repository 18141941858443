<template>
  <div>
    <el-popover
      v-model="addTagsPopoverVisible"
      ref="popover"
      class="popover-button"
      popper-class="action-popover"
      placement="bottom-start"
      width="auto"
    >
      <div>
        <el-select
          v-model="form.tags"
          multiple
          filterable
          allow-create
          default-first-option
          :placeholder="$t('selectTags')"
          size="mini"
        >
          <el-option
            v-for="item in tagsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          size="mini"
          @click="keywordsImport"
          >{{ $t("import") }}</el-button
        >
      </div>
    </el-popover>

    <el-tooltip
      :disabled="isRowsSelected"
      :content="$t('selectKeywordTooltip')"
      placement="top"
    >
      <div style="display: inline">
        <el-button
          plain
          :disabled="!isRowsSelected"
          :size="size"
          :type="type"
          v-popover:popover
        >
          {{ $t("keywords_import") }}
        </el-button>
      </div>
    </el-tooltip>

    <el-button
      plain
      :size="size"
      :type="type"
      @click="excelExport"
    >
      <i class="fas fa-file-excel"></i> {{ $t("export") }}
    </el-button>
  </div>
</template>
<script>
export default {
  props: {
    columnApi: Object,
    gridApi: Object,
    accountId: Number,
    exportMetaInfo: Object,
    size: {
      default: () => "medium",
    },
    type: {
      default: () => "primary",
    },
    isRowsSelected: Boolean,
  },
  data() {
    return {
      addTagsPopoverVisible: false,
      tagsOptions: [],
      form: {
        tags: [],
      },
      tags: null,
    };
  },
  methods: {
    setTagOptions() {
      this.tagsOptions = this.tags.map((tag) => {
        return { value: tag.name, label: tag.name };
      });
    },
    excelExport() {
      this.$emit("exportExcel");
    },
    keywordsImport() {
      this.addTagsPopoverVisible = false;
      const selectedNodes = this.gridApi.getSelectedNodes();
      const texts = selectedNodes.map((node) => node.data.keyword);
      this.$emit("importKeywords", { keywords: texts, tags: this.form.tags });
      this.gridApi.deselectAll();
    },
    getTags() {
      this.$api
        .get(`/studies/${this.accountId}/tags`)
        .then((response) => {
          this.tags = response.data.data;
          this.setTagOptions();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    this.getTags();
  },
};
</script>

<i18n>
{
  "en": {
    "keywords_import": "Import selected keywords",
    "selection": "Selection",
    "selectTags": "Select tags",
    "import": "Import",
    "excelExport": "Export to Excel",
    "selectionnedCountryDiffersFromStudyDefaultSearchEngine": "The selected country is different from the study country",
    "export": "Export",
    "selectKeywordTooltip": "You need to select at least one keyword"
  },
  "fr": {
    "keywords_import": "Importer les mots clés sélectionnés",
    "selection": "Selection",
    "selectTags": "Seléctionnez les groupes de mots clés",
    "import": "Importer",
    "excelExport": "Export Excel",
    "selectionnedCountryDiffersFromStudyDefaultSearchEngine": "Le pays sélectionné est différent du pays de l'étude",
    "export": "Exporter",
    "selectKeywordTooltip": "Vous avez besoin de séléctionner au moins un mot clé"
  },
  "de": {
    "keywords_import": "Importieren Sie ausgewählte Schlüsselwörter",
    "selection": "Auswahl",
    "selectTags": "Wählen Sie Keyword-Gruppen aus",
    "import": "Importeur",
    "excelExport": "Excel exportieren",
    "selectionnedCountryDiffersFromStudyDefaultSearchEngine": "Das ausgewählte Land unterscheidet sich vom Studienland",
    "export": "Exporteur",
    "selectKeywordTooltip": "Sie müssen mindestens ein Schlüsselwort auswählen"
  }
}
</i18n>
