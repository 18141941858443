<template>
  <div>
    <div class="actions">
      <el-button
        plain
        type="primary"
        @click="excelExport"
        >{{ $t("excel_export") }}</el-button
      >
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      resizable
      filter
      floatingFilter
      suppressContextMenu
      @gridReady="onGridReady"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    AgGridVue,
  },
  props: {
    mainSeries: Object,
    trafficGroups: Object,
    comparativeTrafficGroups: Object,
    comparativePeriod: Array,
  },
  data() {
    return {
      gridApi: null,
      columnApi: null,
      defaultColDef: { sortable: true, resizable: true },
      gridOptions: { onColumnVisible: () => this.adjustGrid() },
    };
  },
  computed: {
    ...mapState(["study"]),
    columnDefs() {
      const columnDef = [
        {
          headerName: this.$i18n.t("category"),
          field: "category",
          minWidth: 300,
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("seo_traffic"),
          field: "seoTraffic",
          width: 170,
          valueFormatter: this.seoTrafficFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("seo_traffic_share"),
          field: "seoTrafficShare",
          width: 180,
          valueFormatter: percentageFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
      ];
      if (this.comparativePeriod) {
        columnDef.push({
          headerName: this.$i18n.t("comparative_period"),
          field: "comparative",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
        columnDef.push({
          headerName: this.$i18n.t("variation"),
          field: "variation",
          valueFormatter: percentageFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
      }

      columnDef.push({
        headerName: this.$i18n.t("impressions"),
        field: "impressions",
        width: 170,
        valueFormatter: this.impressionsFormatter,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        filterParams: {
          defaultOption: "greaterThan",
        },
        suppressSizeToFit: true,
      });
      if (this.comparativePeriod) {
        columnDef.push({
          headerName: this.$i18n.t("comparative_period"),
          field: "comparativeImpressions",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
        columnDef.push({
          headerName: this.$i18n.t("variation"),
          field: "impressionsVariation",
          valueFormatter: percentageFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
      }

      columnDef.push({
        headerName: this.$i18n.t("average_position"),
        field: "averagePosition",
        width: 170,
        valueFormatter: this.positionFormatter,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        filterParams: {
          defaultOption: "greaterThan",
        },
        suppressSizeToFit: true,
      });
      if (this.comparativePeriod) {
        columnDef.push({
          headerName: this.$i18n.t("comparative_period"),
          field: "comparativePosition",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
        columnDef.push({
          headerName: this.$i18n.t("variation"),
          field: "positionVariation",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
      }

      columnDef.push({
        headerName: this.$i18n.t("ctr"),
        field: "ctr",
        valueFormatter: this.ctrFormatter,
        width: 130,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        filterParams: {
          defaultOption: "greaterThan",
        },
        suppressSizeToFit: true,
      });
      if (this.comparativePeriod) {
        columnDef.push({
          headerName: this.$i18n.t("comparative_period"),
          field: "comparativeCtr",
          valueFormatter: percentageFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
        columnDef.push({
          headerName: this.$i18n.t("variation"),
          field: "ctrVariation",
          valueFormatter: percentageFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
      }

      return columnDef;
    },
    rowData() {
      const totalSeoTraffic =
        this.mainSerieData.length > 0 ? this.mainSerieData[0].seoTraffic : 1;
      let rowData = this.mainSerieData
        .concat(
          this.trafficGroupsData.sort((a, b) => b.seoTraffic - a.seoTraffic)
        )
        .map((category) => ({
          ...category,
          seoTrafficShare: roundNumber(
            category.seoTraffic / totalSeoTraffic,
            3
          ),
        }));
      if (this.comparativePeriod) {
        rowData = rowData.map((category) => {
          return {
            ...category,
            variation: roundNumber(
              (category.seoTraffic - category.comparative) /
                category.comparative,
              3
            ),
            impressionsVariation: roundNumber(
              (category.impressions - category.comparativeImpressions) /
                category.comparativeImpressions,
              3
            ),
            positionVariation:
              category.comparativePosition === "-" ||
              category.averagePosition === "-"
                ? "-"
                : category.comparativePosition - category.averagePosition,
            ctrVariation: roundNumber(
              category.ctr - category.comparativeCtr,
              3
            ),
          };
        });
      }
      return rowData;
    },
    mainSerieData() {
      if (this.mainSeries) {
        let mainSeriesData = {
          category: this.$t("total"),
          seoTraffic: roundNumber(
            this.mainSeries.main.reduce(
              (sum, trafficPoint) => sum + trafficPoint.value,
              0
            )
          ),
          impressions: roundNumber(
            this.mainSeries.main.reduce(
              (sum, trafficPoint) => sum + trafficPoint.impressions,
              0
            )
          ),
          averagePosition: roundNumber(
            this.mainSeries.main.reduce(
              (sum, trafficPoint) =>
                sum + trafficPoint.average_position * trafficPoint.impressions,
              0
            ) /
              this.mainSeries.main.reduce(
                (sum, trafficPoint) => sum + trafficPoint.impressions,
                0
              )
          ),
          ctr: roundNumber(
            this.mainSeries.main.reduce(
              (sum, trafficPoint) =>
                sum + trafficPoint.ctr * trafficPoint.impressions,
              0
            ) /
              this.mainSeries.main.reduce(
                (sum, trafficPoint) => sum + trafficPoint.impressions,
                0
              ),
            3
          ),
        };
        if (this.mainSeries.comparative) {
          mainSeriesData = {
            ...mainSeriesData,
            comparative: roundNumber(
              this.mainSeries.comparative.reduce(
                (sum, trafficPoint) => sum + trafficPoint.value,
                0
              )
            ),
            comparativeImpressions: roundNumber(
              this.mainSeries.comparative.reduce(
                (sum, trafficPoint) => sum + trafficPoint.impressions,
                0
              )
            ),
            comparativePosition: roundNumber(
              this.mainSeries.comparative.reduce(
                (sum, trafficPoint) =>
                  sum +
                  trafficPoint.average_position * trafficPoint.impressions,
                0
              ) /
                this.mainSeries.comparative.reduce(
                  (sum, trafficPoint) => sum + trafficPoint.impressions,
                  0
                )
            ),
            comparativeCtr: roundNumber(
              this.mainSeries.comparative.reduce(
                (sum, trafficPoint) =>
                  sum + trafficPoint.ctr * trafficPoint.impressions,
                0
              ) /
                this.mainSeries.comparative.reduce(
                  (sum, trafficPoint) => sum + trafficPoint.impressions,
                  0
                ),
              3
            ),
          };
        }
        return [mainSeriesData];
      } else {
        return [];
      }
    },
    trafficGroupsData() {
      if (this.trafficGroups) {
        return Object.entries(this.trafficGroups)
          .filter(([name]) => name)
          .map(([name, trafficGroup]) => {
            let trafficGroupsData = {
              category: name,
              seoTraffic: roundNumber(
                trafficGroup.reduce(
                  (sum, trafficPoint) => sum + trafficPoint.value,
                  0
                )
              ),
              impressions: roundNumber(
                trafficGroup.reduce(
                  (sum, trafficPoint) => sum + trafficPoint.impressions,
                  0
                )
              ),
              averagePosition: roundNumber(
                trafficGroup.reduce(
                  (sum, trafficPoint) =>
                    sum +
                    trafficPoint.average_position * trafficPoint.impressions,
                  0
                ) /
                  trafficGroup.reduce(
                    (sum, trafficPoint) => sum + trafficPoint.impressions,
                    0
                  )
              ),
              ctr: roundNumber(
                trafficGroup.reduce(
                  (sum, trafficPoint) =>
                    sum + trafficPoint.ctr * trafficPoint.impressions,
                  0
                ) /
                  trafficGroup.reduce(
                    (sum, trafficPoint) => sum + trafficPoint.impressions,
                    0
                  ),
                3
              ),
            };
            if (
              this.comparativeTrafficGroups &&
              this.comparativeTrafficGroups.hasOwnProperty(name)
            ) {
              trafficGroupsData = {
                ...trafficGroupsData,
                comparative: roundNumber(
                  this.comparativeTrafficGroups[name].reduce(
                    (sum, trafficPoint) => sum + trafficPoint.value,
                    0
                  )
                ),
                comparativeImpressions: roundNumber(
                  this.comparativeTrafficGroups[name].reduce(
                    (sum, trafficPoint) => sum + trafficPoint.impressions,
                    0
                  )
                ),
                comparativePosition: roundNumber(
                  this.comparativeTrafficGroups[name].reduce(
                    (sum, trafficPoint) =>
                      sum +
                      trafficPoint.average_position * trafficPoint.impressions,
                    0
                  ) /
                    this.comparativeTrafficGroups[name].reduce(
                      (sum, trafficPoint) => sum + trafficPoint.impressions,
                      0
                    )
                ),
                comparativeCtr: roundNumber(
                  this.comparativeTrafficGroups[name].reduce(
                    (sum, trafficPoint) =>
                      sum + trafficPoint.ctr * trafficPoint.impressions,
                    0
                  ) /
                    this.comparativeTrafficGroups[name].reduce(
                      (sum, trafficPoint) => sum + trafficPoint.impressions,
                      0
                    ),
                  3
                ),
              };
            }
            return trafficGroupsData;
          });
      } else {
        return [];
      }
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    seoTrafficFormatter(node) {
      if (this.comparativePeriod) {
        return (
          formatNumber(node.data.seoTraffic) +
          " " +
          formatPercentageVariation(node.data.variation)
        );
      } else {
        return formatNumber(node.data.seoTraffic);
      }
    },
    impressionsFormatter(node) {
      if (this.comparativePeriod) {
        return (
          formatNumber(node.data.impressions) +
          (node.data.impressionsVariation === "-"
            ? ""
            : " " + formatPercentageVariation(node.data.impressionsVariation))
        );
      } else {
        return formatNumber(node.data.impressions);
      }
    },
    positionFormatter(node) {
      if (node.data.averagePosition === "-") {
        return "-";
      }
      if (this.comparativePeriod) {
        return (
          formatNumber(node.data.averagePosition) +
          " " +
          formatNumberVariation(node.data.positionVariation)
        );
      } else {
        return formatNumber(node.data.averagePosition);
      }
    },
    ctrFormatter(node) {
      if (node.data.ctr === "-") {
        return "-";
      }
      if (this.comparativePeriod) {
        return (
          formatPercentage(node.data.ctr) +
          " " +
          formatPercentageVariation(node.data.ctrVariation)
        );
      } else {
        return formatPercentage(node.data.ctr);
      }
    },
    excelExport() {
      const params = {
        skipHeader: false,
        allColumns: true,
        onlySelected: this.gridApi.getSelectedRows().length !== 0,
        fileName: "Traffic Export - " + this.study.url + ".xlsx",
        sheetName: "Traffic",
      };
      this.gridApi.exportDataAsExcel(params);
    },
  },
};

const formatNumber = (number) => {
  if (Number.isNaN(number)) return "-";
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

const roundNumber = (number, numberOfDecimals) => {
  if (Number.isNaN(number)) return "-";
  if (!numberOfDecimals) {
    numberOfDecimals = 0;
  }
  return (
    Math.round(number * Math.pow(10, numberOfDecimals)) /
    Math.pow(10, numberOfDecimals)
  );
};

const numberFormatter = (params) => formatNumber(params.value);

const formatPercentage = (number) => {
  if (Number.isNaN(number)) return "-";
  return roundNumber(number * 100).toString() + "%";
};

const percentageFormatter = (params) => formatPercentage(params.value);

const formatNumberVariation = (params) =>
  Number.isNaN(params) ? "-" : "(" + (params > 0 ? "+" : "") + params + ")";
const formatPercentageVariation = (params) =>
  Number.isNaN(params)
    ? "-"
    : "(" + (params > 0 ? "+" : "") + formatPercentage(params) + ")";
</script>

<style scoped lang="scss">
.actions {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "average_position": "Average position",
    "category": "Category",
    "comparative_period": "Comparative period",
    "ctr": "CTR",
    "excel_export": "Export Excel",
    "impressions": "SEO impressions",
    "seo_traffic": "SEO traffic",
    "seo_traffic_share": "SEO traffic share",
    "total": "Total",
    "variation": "Variation"
  },
  "fr": {
    "average_position": "Position moyenne",
    "category": "Catégorie",
    "comparative_period": "Période comparative",
    "ctr": "CTR",
    "excel_export": "Export excel",
    "impressions": "Impressions SEO",
    "seo_traffic": "Trafic SEO",
    "seo_traffic_share": "Part de trafic SEO",
    "total": "Total",
    "variation": "Variation"
  },
  "de": {
    "average_position": "Durchschnittliche Position",
    "category": "Kategorie",
    "comparative_period": "Vergleichszeitraum",
    "ctr": "CTR",
    "excel_export": "Excel exportieren",
    "impressions": "Impressionen SEO",
    "seo_traffic": "SEO-Traffic",
    "seo_traffic_share": "Teil des SEO-Traffics",
    "total": "Gesamt",
    "variation": "Variation"
  }
}
</i18n>
