<template>
  <el-card>
    <h2>{{ $t("users") }}</h2>
    <div class="actions">
      <div class="text-right">
        <el-button
          plain
          type="primary"
          @click="dialogInviteUserVisible = true"
          >{{ $t("invite_user") }}</el-button
        >
      </div>
      <el-dialog
        :title="$t('invite_user')"
        :visible.sync="dialogInviteUserVisible"
      >
        <user-form
          @submit="inviteUser($event)"
          @close-dialog="dialogInviteUserVisible = false"
        ></user-form>
      </el-dialog>
    </div>
    <div class="grid">
      <ag-grid-vue
        :domLayout="'autoHeight'"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :resizable="true"
        :filter="true"
        :localeTextFunc="localeTextFunc"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        @gridReady="onGridReady"
        @firstDataRendered="adjustGrid"
        @rowDataChanged="adjustGrid"
        :context="context"
      >
      </ag-grid-vue>
    </div>
  </el-card>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompaniesRepository = RepositoryFactory.get("companies");
const UsersRepository = RepositoryFactory.get("users");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import UserForm from "@/pages/CompanyEdit/Forms/UserForm";

const EmailComponent = Vue.extend({
  template: `
		<div>
			{{ params.value }}
			<el-tooltip effect="dark" :content="$t('tooltip_content')" placement="right">
				<i class="fas fa-envelope" v-if="params.data.isInvitationPending"></i>
			</el-tooltip>
		</div>
	`,
  i18n: {
    messages: {
      en: {
        tooltip_content: "Invitation pending",
      },
      fr: {
        tooltip_content: "Invitation en attente",
      },
    },
  },
});

const ActionsComponent = Vue.extend({
  template: `
		<div>
			<a :href="'/u/' + userId + '/edit'"><el-button size="mini" plain icon="fas fa-user-edit"></el-button></a>
			<el-button size="mini" type="danger" plain icon="fas fa-trash-alt" @click="invokeDeleteUser"></el-button>
		</div>
	`,
  data: function () {
    return {
      userId: null,
    };
  },
  mounted() {
    this.userId = this.params.data.id;
  },
  methods: {
    invokeDeleteUser() {
      this.params.context.users.deleteUser(this.userId);
    },
  },
});

export default {
  mixins: [agGridMixin],
  props: {
    companyId: Number,
  },
  components: {
    AgGridVue,
    UserForm,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      dialogInviteUserVisible: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.defaultColDef = {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      };
      this.columnDefs = [
        {
          headerName: this.$i18n.t("email"),
          field: "email",
          minWidth: 200,
          cellRendererFramework: EmailComponent,
        },
        {
          headerName: this.$i18n.t("first_name"),
          field: "firstName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("last_name"),
          field: "lastName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("owner"),
          field: "owner",
          cellRenderer: this.checkRenderer,
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
        {
          headerName: this.$i18n.t("actions"),
          minWidth: 130,
          maxWidth: 130,
          cellRendererFramework: ActionsComponent,
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ];
    },
    fetchData() {
      CompaniesRepository.getCompanyUsers(this.companyId)
        .then((data) => {
          this.rowData = data.data.map((user) => ({
            id: user.id,
            ...user.attributes,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    inviteUser(form) {
      CompaniesRepository.createUser(this.companyId, form)
        .then((response) => {
          this.$message.success(this.$i18n.t("invite_user_success"));
          this.fetchData();
          this.$emit("refresh");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$message({
              message: this.$i18n.t("maximum_users_exceeded"),
              type: "error",
              duration: 6000,
            });
          }
          if (error.response.status === 422) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: error.response.data.map((e) => `<p>${e}</p>`).join(""),
              type: "error",
              duration: 6000,
            });
          } else {
            this.$message({
              message: this.$i18n.t("invite_user_failed"),
              type: "error",
              duration: 6000,
            });
          }
        });
    },
    deleteUser(userId) {
      if (confirm(this.$i18n.t("deletion_confirmation"))) {
        UsersRepository.deleteUser(userId)
          .then((response) => {
            this.$message.success(this.$i18n.t("delete_user_success"));
            this.fetchData();
            setTimeout(() => {
              this.$emit("refresh");
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.fetchData();
    this.context = { users: this };
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.actions {
  margin-bottom: 1rem;
}

.grid {
  ::v-deep a + .el-button {
    margin-left: 10px;
  }

  .text-center {
    text-align: center;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "users": "Users",
    "email": "Email",
    "first_name": "First name",
    "last_name": "Last name",
    "owner": "Owner",
    "actions": "Actions",
    "invite_user": "Invite user",
    "invite_user_success": "An email has been sent to invite the user to join your company.",
    "invite_user_failed": "An error occurred",
    "maximum_users_exceeded": "You have exceeded your maxmimum users limit, please contact us to discuss this.",
    "deletion_confirmation": "Are you sure you want to delete this user ?",
    "delete_user_success": "User was successfully deleted."
  },
  "fr": {
    "users": "Utilisateurs",
    "email": "Email",
    "first_name": "Prénom",
    "last_name": "Nom",
    "owner": "Propriétaire",
    "actions": "Actions",
    "invite_user": "Inviter un utilisateur",
    "invite_user_success": "Un email a été envoyé pour inviter l'utilisateur à rejoindre votre société.",
    "invite_user_failed": "Une erreur est survenue",
    "maximum_users_exceeded": "Vous avez dépassé votre limite maximum d'utilisateurs, veuillez nous contacter pour en discuter.",
    "deletion_confirmation": "Etes-vous sûr de vouloir supprimer cet utilisateur ?",
    "delete_user_success": "L'utilisateur a été supprimé avec succès."
  },
  "de": {
    "users": "Benutzer",
    "email": "Email",
    "first_name": "Vorname",
    "last_name": "Name",
    "owner": "Eigentümer",
    "actions": "Aktionen",
    "invite_user": "Laden Sie einen Benutzer ein",
    "invite_user_success": "Eine E-Mail wurde gesendet, um den Benutzer einzuladen, Ihrem Unternehmen beizutreten.",
    "invite_user_failed": "Ein Fehler ist aufgetreten",
    "maximum_users_exceeded": "Sie haben Ihr maximales Benutzerlimit überschritten. Bitte kontaktieren Sie uns, um dies zu besprechen.",
    "deletion_confirmation": "Möchten Sie diesen Benutzer wirklich löschen?",
    "delete_user_success": "Der Benutzer wurde erfolgreich gelöscht."
  }
}
</i18n>
