import { render, staticRenderFns } from "./ProjectResponsiblesRenderer.vue?vue&type=template&id=8545058e&scoped=true&"
import script from "./ProjectResponsiblesRenderer.vue?vue&type=script&lang=js&"
export * from "./ProjectResponsiblesRenderer.vue?vue&type=script&lang=js&"
import style0 from "./ProjectResponsiblesRenderer.vue?vue&type=style&index=0&id=8545058e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8545058e",
  null
  
)

/* custom blocks */
import block0 from "./ProjectResponsiblesRenderer.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports