import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ed9ec9e4&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=ed9ec9e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed9ec9e4",
  null
  
)

/* custom blocks */
import block0 from "@/javascripts/grid.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fsmartkeyword%2Fapp%2Fapp%2Fjavascript%2Fpages%2FKeywords%2FAllKeywords%2FGrid%2Findex.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./index.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports