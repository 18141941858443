<template>
  <div v-if="form && formModelMapped">
    <el-form
      ref="dynamicFormRef"
      :model="formModel"
      label-width="200px"
      label-position="right"
    >
      <el-form-item
        v-for="field in form"
        v-bind:key="field.name"
        v-show="field.show"
        :label="field.label ? field.label : $t(field.name)"
        :prop="field.name"
        :rules="rulesAssigner(field)"
      >
        <component
          v-bind:is="inputTypeAssigner(field)"
          v-model="formModel[field.name]"
          :options="field.options"
          :value-key="field.valueKey"
          :default-search-engine="defaultSearchEngine"
          @sourceDomainMetrics="handleSourceDomainMetrics($event)"
          @isDomainSourceCorrectlyFormatted="
            handleDomainSourceIncorrectlyFormatted($event)
          "
          @isTargetUrlCorrectlyFormattedOrEmpty="
            handleTargetUrlIncorrectlyFormatted($event)
          "
          @buyingPrice="handleBuyingPrice($event)"
          @selectedStudyKeyword="selectedStudyKeyword = $event"
          :selected-study-keyword="selectedStudyKeyword"
        >
        </component>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import RangeDatePicker from "@/pages/CampaignManager/FormItems/RangeDatePicker";
import DatePicker from "@/pages/CampaignManager/FormItems/DatePicker";
import TextArea from "@/pages/CampaignManager/FormItems/TextArea";
import Tags from "@/pages/CampaignManager/FormItems/Tags";
import Selector from "@/pages/CampaignManager/FormItems/Select";
import WatchersRenderer from "@/pages/CampaignManager/FormItems/WatchersRenderer";
import ProjectResponsiblesRenderer from "@/pages/CampaignManager/FormItems/ProjectResponsiblesRenderer";
import SourcingReasonsRenderer from "@/pages/CampaignManager/FormItems/SourcingReasonsRenderer";
import SourceDomain from "@/pages/CampaignManager/FormItems/SourceDomain";
import TargetUrl from "@/pages/CampaignManager/FormItems/TargetUrl";
import BuyingPrice from "@/pages/CampaignManager/FormItems/BuyingPrice";
import BuyingPriceCents from "@/pages/CampaignManager/FormItems/BuyingPriceCents";
import SelectStudyKeyword from "@/components/formItems/StudyKeywordsSelect";
import ContentLength from "@/components/formItems/ContentLength";
import SelectUser from "@/components/formItems/SelectUser";
import SelectAllowCreate from "@/components/formItems/SelectAllowCreate";

export default {
  components: {
    DatePicker,
    RangeDatePicker,
    TextArea,
    Tags,
    Selector,
    SourceDomain,
    TargetUrl,
    WatchersRenderer,
    ProjectResponsiblesRenderer,
    SourcingReasonsRenderer,
    BuyingPrice,
    BuyingPriceCents,
    SelectStudyKeyword,
    ContentLength,
    SelectUser,
    SelectAllowCreate,
  },
  props: {
    form: Array,
    value: Object,
    defaultSearchEngine: Object,
  },
  data() {
    return {
      selectedStudyKeyword: null,
      backlinkPriceMargin: 0.35,
      formModelMapped: false,
      domainAuthorityRatings: [
        { min: 0, max: 9, rating: 1 },
        { min: 10, max: 11, rating: 2 },
        { min: 12, max: 14, rating: 3 },
        { min: 15, max: 18, rating: 4 },
        { min: 19, max: 21, rating: 5 },
        { min: 22, max: 24, rating: 6 },
        { min: 25, max: 27, rating: 7 },
        { min: 28, max: 31, rating: 8 },
        { min: 32, max: 33, rating: 9 },
        { min: 34, max: 100, rating: 10 },
      ],
      trustFlowRatings: [
        { min: 0, max: 10, rating: 1 },
        { min: 11, max: 15, rating: 2 },
        { min: 16, max: 20, rating: 3 },
        { min: 21, max: 25, rating: 4 },
        { min: 26, max: 30, rating: 5 },
        { min: 31, max: 35, rating: 6 },
        { min: 36, max: 40, rating: 7 },
        { min: 41, max: 45, rating: 8 },
        { min: 46, max: 50, rating: 9 },
        { min: 50, max: 100, rating: 10 },
      ],
      ratioTfCfRatings: [
        { min: 0, max: 0.5, rating: 1 },
        { min: 0.51, max: 0.6, rating: 2 },
        { min: 0.61, max: 0.65, rating: 3 },
        { min: 0.66, max: 0.7, rating: 4 },
        { min: 0.71, max: 0.8, rating: 5 },
        { min: 0.81, max: 0.9, rating: 6 },
        { min: 0.91, max: 1, rating: 7 },
        { min: 1.01, max: 1.2, rating: 8 },
        { min: 1.21, max: 1.5, rating: 9 },
        { min: 1.51, max: null, rating: 10 },
      ],
      trafficRatings: [
        { min: 0, max: 50, rating: 1 },
        { min: 51, max: 200, rating: 2 },
        { min: 201, max: 350, rating: 3 },
        { min: 351, max: 500, rating: 4 },
        { min: 501, max: 1000, rating: 5 },
        { min: 1001, max: 3000, rating: 6 },
        { min: 3001, max: 5000, rating: 7 },
        { min: 5001, max: 7500, rating: 8 },
        { min: 7501, max: 10000, rating: 9 },
        { min: 10001, max: null, rating: 10 },
      ],
      referringDomainsRatings: [
        { min: 0, max: 50, rating: 1 },
        { min: 51, max: 100, rating: 2 },
        { min: 101, max: 150, rating: 3 },
        { min: 151, max: 200, rating: 4 },
        { min: 201, max: 250, rating: 5 },
        { min: 251, max: 300, rating: 6 },
        { min: 301, max: 500, rating: 7 },
        { min: 501, max: 700, rating: 8 },
        { min: 701, max: 1000, rating: 9 },
        { min: 1001, max: null, rating: 10 },
      ],
      backlinksMapping: [
        { type: "BRONZE", minRate: 0, maxRate: 3, price: 120 },
        { type: "SILVER", minRate: 3, maxRate: 5, price: 280 },
        { type: "GOLD", minRate: 5, maxRate: 7, price: 370 },
        { type: "PLATINUM", minRate: 7, maxRate: 9, price: 520 },
        { type: "DIAMOND", minRate: 9, maxRate: 10, price: 700 },
      ],
    };
  },
  computed: {
    formModel: {
      get() {
        return this.value;
      },
      set(formModel) {
        this.$emit("input", formModel);
      },
    },
  },
  methods: {
    rulesAssigner(field) {
      if (field.mandatory !== undefined && field.mandatory === true) {
        var translatedName = this.$i18n.t(field.name);
        return [
          {
            required: true,
            message: this.$t("required", { fieldName: translatedName }),
            trigger: "change",
          },
        ];
      } else {
        return [];
      }
    },
    inputTypeAssigner(field) {
      if (field.hasOwnProperty("fieldRenderer")) {
        return field.fieldRenderer;
      }

      const type = field.type;
      switch (type) {
        case "INTEGER":
        case "integer":
          return "el-input-number";
          break;
        case "ARRAY":
        case "array":
          return "tags";
          break;
        case "DATE":
        case "date":
          return "date-picker";
          break;
        case "RangeDate":
        case "RANGE_DATE":
        case "rangeDate":
          return "range-date-picker";
          break;
        case "STRING":
        case "string":
          return "el-input";
          break;
        case "TEXT":
        case "text":
          return "text-area";
          break;
        case "SELECT":
        case "select":
          return "selector";
          break;
        case "MULTIPLE_SELECT":
        case "multipleSelect":
          return "selector-array-objects";
        case "BOOLEAN":
        case "boolean":
          return "el-switch";
        default:
          console.log(`Type ${type} not handled. Default to el-input.`);
          return "el-input";
          break;
      }
    },
    mapFormModel() {
      if (this.form) {
        this.form.forEach((field) => {
          field.show = true;

          if (field.name == "platform" && field.type == "select-allow-create")
            field.fieldRenderer = "SelectAllowCreate";

          if (field.name == "watcherIds") {
            field.fieldRenderer = "WatchersRenderer";
          }
          if (field.name == "sourceDomain") {
            field.fieldRenderer = "SourceDomain";
          }
          if (field.name == "targetUrl") {
            field.fieldRenderer = "TargetUrl";
          }
          if (field.name == "buyingPrice") {
            field.fieldRenderer = "BuyingPrice";
          }
          if (
            field.name == "costPerWord" ||
            field.name == "costPerWordWithoutMargin"
          ) {
            field.fieldRenderer = "BuyingPriceCents";
          }
          if (field.name == "keyword" && field.type == "OBJECT") {
            field.fieldRenderer = "SelectStudyKeyword";
          }
          if (field.name == "contentLength") {
            field.fieldRenderer = "ContentLength";
          }
          if (field.name == "projectResponsibleIds") {
            field.fieldRenderer = "ProjectResponsiblesRenderer";
          }
          if (field.name == "sourcingReasons") {
            field.fieldRenderer = "SourcingReasonsRenderer";
          }
          if (field.name == "backlinksIds" || field.name == "contentsIds")
            field.show = false;
          this.$set(this.formModel, field.name, field.value, field.show);
        });
      }
      this.formModelMapped = true;
    },
    handleSourceDomainMetrics(data) {
      const ratingDA = this.domainAuthorityRatings.find(
        (rating) =>
          data.metrics.domainAuthority >= rating.min &&
          data.metrics.domainAuthority <= rating.max
      );
      const ratingTF = this.trustFlowRatings.find(
        (rating) =>
          data.metrics.trustFlow >= rating.min &&
          data.metrics.trustFlow <= rating.max
      );
      const ratingRatioTfCf = this.ratioTfCfRatings.find(
        (rating) =>
          (data.metrics.ratioTfCf >= rating.min &&
            data.metrics.ratioTfCf <= rating.max) ||
          (rating.max == null && data.metrics.ratioTfCf >= rating.min)
      );
      const ratingTraffic = this.trafficRatings.find(
        (rating) =>
          (data.metrics.organicTraffic >= rating.min &&
            data.metrics.organicTraffic <= rating.max) ||
          (rating.max == null && data.metrics.organicTraffic >= rating.min)
      );
      const ratingRefDomains = this.referringDomainsRatings.find(
        (rating) =>
          (data.metrics.referringDomainsWithEquity >= rating.min &&
            data.metrics.referringDomainsWithEquity <= rating.max) ||
          (rating.max == null &&
            data.metrics.referringDomainsWithEquity >= rating.min)
      );

      const averageRating =
        ((ratingDA ? ratingDA.rating : 0) +
          (ratingTF ? ratingTF.rating : 0) +
          (ratingRatioTfCf ? ratingRatioTfCf.rating : 0) +
          (ratingTraffic ? ratingTraffic.rating : 0) +
          (ratingRefDomains ? ratingRefDomains.rating : 0)) /
        5;

      const matchingBacklinkType = this.backlinksMapping.find(
        (backlink) =>
          averageRating >= backlink.minRate && averageRating < backlink.maxRate
      );

      if (this.formModel.hasOwnProperty("backlinkType")) {
        this.formModel.backlinkType = matchingBacklinkType.type;
      }
      if (this.formModel.hasOwnProperty("price")) {
        if (this.formModel.hasOwnProperty("buyingPrice")) {
          if (
            matchingBacklinkType.price >
            this.formModel.buyingPrice +
              matchingBacklinkType.price * this.backlinkPriceMargin
          ) {
            this.formModel.price = matchingBacklinkType.price;
          } else {
            this.formModel.price = Math.round(
              this.formModel.buyingPrice +
                this.formModel.price * this.backlinkPriceMargin
            );
          }
        } else {
          this.formModel.price = matchingBacklinkType.price;
        }
      }
    },
    handleBuyingPrice(buyingPrice) {
      if (buyingPrice) {
        if (this.formModel.hasOwnProperty("price")) {
          if (
            this.formModel.price <
            this.formModel.price * this.backlinkPriceMargin + buyingPrice
          ) {
            this.formModel.price = Math.round(
              buyingPrice * (1 / (1 - this.backlinkPriceMargin))
            );
          }
        }
      }
    },
    handleDomainSourceIncorrectlyFormatted(isBadUrl) {
      this.$emit("isDomainSourceCorrectlyFormatted", isBadUrl);
    },
    handleTargetUrlIncorrectlyFormatted(isBadUrl) {
      this.$emit("isTargetUrlCorrectlyFormattedOrEmpty", isBadUrl);
    },
  },
  mounted() {
    this.mapFormModel();
  },
  watch: {
    form() {
      this.mapFormModel();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "name": "Name",
    "companyName": "Company Name",
    "activitySector": "Theme",
    "companyDescription": "Company Description",
    "highlights": "Values to highlight",
    "links": "Additional Links (image, vidéo, etc)",
    "targetUrl": "Target Page",
    "platform": "Platform",
    "sourceUrl": "Url Source",
    "sourceDomain": "Domain Source",
    "price": "Price",
    "redactionPrice": "Redaction price",
    "buyingPrice": "Purchasing price",
    "backlinkType": "Backlink Type",
    "status": "Status",
    "keyword": "Keyword",
    "anchors": "Anchors",
    "numberOfWords": "Number of words",
    "comments": "Comments",
    "backlinkExample": "Example Of Backlinks",
    "articleType": "Article Type",
    "anchorExamples": "Anchor Examples",
    "budget": "Budget",
    "description": "Description",
    "period": "Period",
    "publishedDate": "Publication Date",
    "additionalLinks": "Additional links",
    "validationDate": "Validation date",
    "concept": "Concept",
    "catalog": "Catalog",
    "budgetWithoutMargin": "Budget without margin",
    "backlinkTypes": "Backlink types",
    "watcherIds": "Followed by",
    "contentLength": "Content length (words)",
    "costPerWord": "Cost/Word",
    "pricing": "Pricing",
    "costPerWordWithoutMargin": "Cost/Word without margin",
    "pageType": "Type of page",
    "writingType": "Type of writing",
    "url": "URL of existing text",
    "sourceLanguage": "Source Language",
    "targetLanguage": "Targeted Language",
    "existingTextUrl": "URL du texte existant",
    "secondaryKeywordAnchor": "Anchor of secondary keyword",
    "secondaryKeywordUrl": "URL of secondary keywor",
    "outgoingLinks": "Outgoing links",
    "sameTopicUrl": "Example of article",
    "projectResponsibleIds": "Followed by",
    "contentsIds": "Contents",
    "backlinksIds": "Backlinks",
    "publishDate": "Publish date",
    "deliveryDate": "Delivery date",
    "dateStatusDomainToValidate": "Month",
    "isTest": "Test campaign",
    "creationDate": "Month",
    "required": "The field {fieldName} is mandatory",
    "productSiteService": "Product / Site service",
    "targetExample": "Exemple de cible",
    "contentObjective": "Objectif du contenu",
    "contentType": "Type du contenu",
    "contentSubject": "Thème / sujet du contenu",
    "subjectsCoveredByContent": "Sujets couverts par le contenu",
    "typographyExample": "Exemple de typographie",
    "toneExample": "Exemple de ton",
    "dontMentionLegalSensitivity": "Ne pas mentionner / Sensibilité juridique",
    "globalGuidelines": "Guidelines globales",
    "valueProposition": "Proposition de valeur",
    "sourcingReasons": "Sourcing Reasons"
  },
  "fr": {
    "name": "Nom",
    "companyName": "Nom de l'entreprise",
    "activitySector": "Thématique",
    "companyDescription": "Description de l'entreprise",
    "highlights": "Proposition de valeurs",
    "links": "Liens complémentaires (image, vidéo, etc)",
    "targetUrl": "Page visée",
    "platform": "Plateforme",
    "sourceUrl": "Url source",
    "sourceDomain": "Domaine source",
    "price": "Prix",
    "redactionPrice": "Prix de rédaction",
    "buyingPrice": "Prix d'achat",
    "backlinkType": "Type de Backlink",
    "status": "Statut",
    "keyword": "Mot clé",
    "anchors": "Ancres",
    "numberOfWords": "Nombre de mots total",
    "comments": "Commentaires",
    "backlinkExample": "Exemple de Backlink",
    "articleType": "Type d'article",
    "anchorExamples": "Exemple d'ancre",
    "budget": "Budget",
    "description": "Description",
    "period": "Période",
    "publishedDate": "Date de publication",
    "additionalLinks": "Liens additionnels",
    "validationDate": "Date de validation",
    "concept": "Concept",
    "catalog": "Catalogue",
    "budgetWithoutMargin": "Budget sans les marges",
    "backlinkTypes": "Types de backlinks",
    "watcherIds": "Suivi par",
    "contentLength": "Taille du contenu (mots)",
    "costPerWord": "Prix/Mot",
    "pricing": "Tarification",
    "costPerWordWithoutMargin": "Prix/Mot sans marge",
    "pageType": "Type de page",
    "writingType": "Type de rédaction",
    "url": "URL du texte existant",
    "sourceLanguage": "Langue source",
    "targetLanguage": "Langue ciblé",
    "existingTextUrl": "URL du texte existant",
    "secondaryKeywordAnchor": "Ancre du mot clé secondaire",
    "secondaryKeywordUrl": "Url du mot lé secondaire",
    "outgoingLinks": "Liens sortants",
    "sameTopicUrl": "Exemple d'article",
    "projectResponsibleIds": "Responsables du projet",
    "contentsIds": "Contenus",
    "backlinksIds": "Backlinks",
    "publishDate": "Date de publication",
    "deliveryDate": "Date de livraison",
    "dateStatusDomainToValidate": "Mois",
    "isTest": "Campagne test",
    "creationDate": "Mois",
    "required": "Le champs {fieldName} est obligatoire",
    "productSiteService": "Product / Site service",
    "targetExample": "Target example",
    "contentObjective": "Content objective",
    "contentType": "Content type",
    "contentSubject": "Content subject",
    "subjectsCoveredByContent": "Subjects covered by content",
    "typographyExample": "Typography example",
    "toneExample": "Tone example",
    "dontMentionLegalSensitivity": "Don't mention / Legal Sensitivity",
    "globalGuidelines": "Global guidelines",
    "valueProposition": "Value Proposition",
    "sourcingReasons": "Raison du sourcing"
  },
  "de": {
    "name": "Name",
    "companyName": "Name der Firma",
    "activitySector": "Thematisch",
    "companyDescription": "Firmen Beschreibung",
    "highlights": "Wertversprechen",
    "links": "Weiterführende Links (Bild, Video etc.)",
    "targetUrl": "Zielseite",
    "platform": "Plattform",
    "sourceUrl": "URL-Quelle",
    "sourceDomain": "Domain-Quelle",
    "price": "Preis",
    "redactionPrice": "Schreibpreis",
    "buyingPrice": "Kaufpreis",
    "backlinkType": "Geben Sie den Backlink ein",
    "status": "Status",
    "keyword": "Stichwort",
    "anchors": "Anker",
    "numberOfWords": "Gesamtzahl der Wörter",
    "comments": "Kommentare",
    "backlinkExample": "Beispiel für Backlinks",
    "articleType": "Geben Sie d'Artikel ein",
    "anchorExamples": "Beispiel Anker",
    "budget": "Budget",
    "description": "Beschreibung",
    "period": "Zeitraum",
    "publishedDate": "Veröffentlichungsdatum",
    "additionalLinks": "Zusätzliche Links",
    "validationDate": "Validierungsdatum",
    "concept": "Konzept",
    "catalog": "Katalog",
    "budgetWithoutMargin": "Budget ohne Margen",
    "backlinkTypes": "Arten von Backlinks",
    "watcherIds": "gefolgt von",
    "contentLength": "Inhaltsgröße (Wörter)",
    "costPerWord": "Preis/Wort",
    "pricing": "Preisgestaltung",
    "costPerWordWithoutMargin": "Preis/Wort ohne Marge",
    "pageType": "Seitentyp",
    "writingType": "Art des Schreibens",
    "url": "URL du texte existant",
    "sourceLanguage": "Ausgangssprache",
    "targetLanguage": "Zielsprache",
    "existingTextUrl": "URL du texte existant",
    "secondaryKeywordAnchor": "Sekundärer Keyword-Anker",
    "secondaryKeywordUrl": "Sekundäre Keyword-URL",
    "outgoingLinks": "Ausgehende Links",
    "sameTopicUrl": "Beispielartikel",
    "projectResponsibleIds": "Projektmanager",
    "contentsIds": "Inhalt",
    "backlinksIds": "Backlinks",
    "publishDate": "Veröffentlichungsdatum",
    "deliveryDate": "Liefertermin",
    "dateStatusDomainToValidate": "Monat",
    "isTest": "Testkampagne",
    "creationDate": "Monat",
    "required": "Das Feld {fieldName} ist obligatorisch",
    "productSiteService": "Produkt-/Site-Service",
    "targetExample": "Zielbeispiel",
    "contentObjective": "Inhaltliches Ziel",
    "contentType": "Inhaltstyp",
    "contentSubject": "Inhaltliches Thema",
    "subjectsCoveredByContent": "Inhaltlich abgedeckte Themen",
    "typographyExample": "Typografisches Beispiel",
    "toneExample": "Tonbeispiel",
    "dontMentionLegalSensitivity": "Nicht erwähnen / Legal Sensitivity",
    "globalGuidelines": "Globale Richtlinien",
    "valueProposition": "Wertversprechen",
    "sourcingReasons": "Grund für die Beschaffung"
  }
}
</i18n>
