<template>
  <div v-if="competitors !== null">
    <ag-grid-vue
      style="height: 650px"
      class="ag-theme-material competitor-grid"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      :suppressCellSelection="true"
      :context="context"
      @gridReady="onGridReady"
      @gridSizeChanged="adjustGrid"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
    <create-competitor-dialog
      :dialogVisible="dialogVisible"
      :study-links="studyLinks"
      :competitorUrl="competitorUrl"
      @create-competitor="createCompetitor($event)"
      @close-dialog="closeCreateCompetitorDialog()"
    >
    </create-competitor-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import CompetitorsActionsRenderer from "@/components/gridRenderers/CompetitorsActionsRenderer";
import CreateCompetitorDialog from "@/components/CreateCompetitorDialog";
import WebsiteRenderer from "@/components/gridRenderers/WebsiteRenderer";

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
    CompetitorsActionsRenderer,
    CreateCompetitorDialog,
    WebsiteRenderer,
  },
  props: {
    request: Object,
    metrics: Object,
    studyId: Number,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
      gridOptions: { onColumnVisible: () => this.adjustGrid() },
      dialogVisible: false,
      competitorUrl: null,
      competitors: null,
      dataCompetitor: null,
    };
  },
  computed: {
    context() {
      return {
        competitors: this.competitors,
        showDialog: this.showCreateCompetitorDialog,
        studyLinks: this.studyLinks,
      };
    },
    ...mapState(["study", "studyLinks"]),
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      if (this.columnApi != null) {
        if (this.columnApi.columnController.scrollWidth > 0) {
          this.gridApi.sizeColumnsToFit();
        }
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("competitor"),
          field: "url",
          filter: "agTextColumnFilter",
          minWidth: 200,
          cellRendererFramework: "WebsiteRenderer",
        },
        {
          headerName: this.$i18n.t("levelOfCompetition"),
          field: "competition",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          sort: "desc",
        },
        {
          headerName: this.$i18n.t("traffic"),
          field: "estimatedTraffic",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
        },
        {
          headerName: this.$i18n.t("ratioVs"),
          field: "ratioVs",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: ratioFormatter,
        },
        {
          headerName: this.$i18n.t("numberOfKeywords"),
          field: "numberOfKeywords",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
          hide: true,
        },
        {
          headerName: this.$i18n.t("numberOfCommonKeywords"),
          field: "numberOfCommonKeywords",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
        },
        {
          headerName: this.$i18n.t("actions"),
          headerClass: "ag-centered-header",
          minWidth: 225,
          maxWidth: 225,
          cellRendererFramework: "CompetitorsActionsRenderer",
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ];
    },
    mapData() {
      if (!this.dataCompetitor || !this.dataCompetitor.data) {
        this.rowData = [];
      } else {
        if (this.dataCompetitor.data.length > 0) {
          this.rowData = this.dataCompetitor.data.map((competitor) => {
            return {
              url: competitor.url.url,
              competition: Math.round(competitor.competitionLevel * 100),
              estimatedTraffic: competitor.organicTraffic,
              numberOfKeywords: competitor.numberOfKeywords,
              numberOfCommonKeywords: competitor.numberOfCommonKeywords,
              ratioVs: Math.round(
                (competitor.organicTraffic / this.metrics.organicTraffic) * 100
              ),
            };
          });
        } else {
          this.rowData = [];
        }
      }
    },
    showCreateCompetitorDialog(url) {
      this.dialogVisible = true;
      this.competitorUrl = url;
    },
    createCompetitor(form) {
      const create = this.studyLinks.competitors.create;
      this.$api[create.method.toLowerCase()](create.href, form)
        .then(() => {
          this.fetchCompetitors();
          const params = {
            force: true,
            suppressFlash: false,
          };
          this.gridApi.refreshCells(params);
          this.$message({
            message: this.$i18n.t("competitorCreationSuccess"),
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            message: this.$i18n.t("competitorCreationFailure"),
            type: "error",
            duration: 3000,
          });
        });
      this.dialogVisible = false;
    },
    closeCreateCompetitorDialog() {
      this.dialogVisible = false;
    },
    fetchCompetitors() {
      const list = this.studyLinks.competitors.list;
      this.$api[list.method.toLowerCase()](list.href)
        .then((response) => {
          this.competitors = response.data.data.map((e) => {
            return {
              url: e.url,
            };
          });
          if (isTypeOptionSupported(this.studyLinks, this.request)) {
            this.fetchCompetitorData();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchCompetitorData() {
      let requestCompetitor = {
        url: this.request.url,
        isoCountryCode: this.request.isoCountryCode,
      };

      this.$api
        .post(`/netlinking/competitors`, requestCompetitor)
        .then((response) => {
          this.dataCompetitor = response.data;
        })
        .catch((error) => {
          this.dataCompetitor = {};
          console.log(error);
        });
    },
  },
  created() {
    this.fetchCompetitors();
  },
  beforeMount() {
    this.setColumnDefs();
  },
  watch: {
    dataCompetitor() {
      this.mapData();
    },
    request(newRequest) {
      if (isTypeOptionSupported(this.studyLinks, newRequest)) {
        this.fetchCompetitorData();
      }
    },
  },
  mounted() {
    this.mapData();
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);

let ratioFormatter = (number) => {
  return numberFormatter(number) + "%";
};

const isTypeOptionSupported = (studyLinks, request) => {
  return studyLinks.netlinking.competitors.form
    .find((field) => (field.name = "url"))
    .form.find((field) => field.name === "type")
    .options.includes(request.url.type);
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";

::v-deep .ag-centered-header {
  .ag-header-cell-label {
    justify-content: center;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "actions": "Actions",
    "competitor": "Competitor",
    "competitorCreationSuccess": "The competitor has been added succesfully",
    "competitorCreationFailure": "We encountered an error in adding the competitor",
    "levelOfCompetition": "Competiton",
    "numberOfCommonKeywords": "Keywords in common",
    "numberOfKeywords": "Keywords",
    "traffic": "Traffic",
    "ratioVs": "Ratio vs "
  },
  "fr": {
    "actions": "Actions",
    "competitor": "Concurrent",
    "competitorCreationSuccess": "Le concurrent a été ajouté avec succès",
    "competitorCreationFailure": "Nous avons rencontré une erreur dans l'ajout du concurrent",
    "levelOfCompetition": "Concurrence",
    "numberOfCommonKeywords": "Mots-clés en commun",
    "numberOfKeywords": "Mots-clés",
    "traffic": "Trafic",
    "ratioVs": "Ratio vs "
  },
  "de": {
    "actions": "Aktionen",
    "competitor": "Gleichzeitig",
    "competitorCreationSuccess": "Der Mitbewerber wurde erfolgreich hinzugefügt",
    "competitorCreationFailure": "Beim Hinzufügen des Mitbewerbers ist ein Fehler aufgetreten",
    "levelOfCompetition": "Übereinstimmung",
    "numberOfCommonKeywords": "Stichworte gemeinsam",
    "numberOfKeywords": "Schlüsselwörter",
    "traffic": "Verkehr",
    "ratioVs": "Verhältnis vs"
  }
}
</i18n>
