var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('el-tooltip',{attrs:{"placement":"top","transition":"el-fade-in","disabled":!_vm.params.data.assignedPage ||
      _vm.params.data.assignedPage == _vm.params.value ||
      _vm.params.data.position == 101}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[(
          _vm.params.value &&
          _vm.params.data.position < 101 &&
          _vm.params.data.assignedPage != _vm.params.value &&
          _vm.params.data.assignedPage
        )?_c('div',[_vm._v("\n        "+_vm._s(_vm.$t("positioned_page"))+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.params.value &&
          _vm.params.data.position < 101 &&
          _vm.params.data.assignedPage != _vm.params.value &&
          _vm.params.data.assignedPage
        )?_c('div',[_vm._v("\n        "+_vm._s(_vm.$t("assigned_page_is"))+"\n        "),_c('a',{attrs:{"href":_vm.params.data.assignedPage,"target":"_blank"}},[_vm._v(_vm._s(_vm.assignedPath))])]):_vm._e()]),_vm._v(" "),(_vm.params.value && _vm.params.data.position < 101)?_c('span',[(_vm.params.data.assignedPage && _vm.assignedPath != _vm.positionedPath)?_c('i',{staticClass:"fas fa-circle"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"page",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.showPageDialog(_vm.params.value)}}},[_vm._v(_vm._s(_vm.positionedPath))])]):(_vm.params.data.assignedPage)?_c('span',{staticClass:"page",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.showPageDialog(_vm.params.data.assignedPage)}}},[_vm._v(_vm._s(_vm.assignedPath))]):_c('span',[_vm._v(_vm._s(_vm.$t("no_positioned_page")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }