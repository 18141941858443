import Repository from "@/services/repository";

function formToJsonRequest(form) {
  return {
    company: {
      companyGroupId: form.companyGroupId,
      sellsyThirdId: form.sellsyThirdId,
      name: form.name,
      usersAllowedCount: form.usersAllowedCount,
      maxUrlsToCrawl: form.maxUrlsToCrawl,
      status: form.status,
      autoAlerting: form.autoAlerting === null ? false : form.autoAlerting,
      autoChangeStatus:
        form.autoChangeStatus === null ? false : form.autoChangeStatus,
      companySettingAttributes: {
        companyCategory: form.companySetting.companyCategory,
        healthTrackingMode: form.companySetting.healthTrackingMode,
        subscriptionFrequency: form.companySetting.subscriptionFrequency,
        consultingDaysPerMonth: form.companySetting.consultingDaysPerMonth,
        subscriptionAmount: form.companySetting.subscriptionAmount,
        subscriptionStartDate: form.companySetting.subscriptionStartDate,
        subscriptionEndDate: form.companySetting.subscriptionEndDate,
        lastContactedDate: form.companySetting.lastContactedDate,
        comment: form.companySetting.comment,
        keywordsLimit: form.companySetting.keywordsLimit,
        competitorsLimit: form.companySetting.competitorsLimit,
        information: form.companySetting.information,
      },
      accountManagers: form.companySetting.accountManagers,
    },
  };
}

export default {
  getCompany(companyId) {
    return Repository.get(`/c/${companyId}.json`).then(
      (response) => response.data
    );
  },

  getCompanies(params) {
    return Repository.get(`/companies.json`, { params }).then(
      (response) => response.data
    );
  },

  deleteCompany(companyId) {
    return Repository.delete(`/c/${companyId}.json`).then(
      (response) => response
    );
  },

  createCompany(form) {
    return Repository.post(`/companies.json`, formToJsonRequest(form)).then(
      (response) => response.data
    );
  },

  updateCompany(companyId, form) {
    return Repository.patch(
      `/c/${companyId}.json`,
      formToJsonRequest(form)
    ).then((response) => response.data);
  },

  getCompanyParameters(companyId) {
    return Repository.get(`/c/${companyId}/parameters.json`).then(
      (response) => response.data
    );
  },

  getCompanyMetrics(companyId) {
    return Repository.get(`/c/${companyId}/metrics.json`).then(
      (response) => response.data
    );
  },

  getCompanyInvoices(companyId) {
    return Repository.get(`/c/${companyId}/company_invoices.json`).then(
      (response) => response.data
    );
  },

  getCompanyStudies(companyId) {
    return Repository.get(`/c/${companyId}/accounts.json`).then(
      (response) => response.data
    );
  },

  createCompanyStudy(companyId, form) {
    return Repository.post(`/c/${companyId}/accounts.json`, {
      account: {
        url: form.url,
        accountEngines: form.searchEngines.map(
          (searchEngine) => searchEngine.id
        ),
        defaultSearchEngine: form.defaultSearchEngine.id,
        customName: form.customName,
        country: form.country,
        studyType: form.studyType,
      },
    }).then((response) => response);
  },

  createUser(companyId, form) {
    return Repository.post(`/c/${companyId}/users.json`, {
      user: {
        owner: form.owner,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        phone: form.phone,
      },
    }).then((response) => response);
  },

  getCompanyUsers(companyId) {
    return Repository.get(`/c/${companyId}/users.json`).then(
      (response) => response.data
    );
  },

  getCompanyUsersForTasks(companyId) {
    return Repository.get(`/c/${companyId}/users_for_tasks.json`).then(
      (response) => response.data
    );
  },
};
