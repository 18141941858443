<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h3 class="title">{{ $t("editContent") }}</h3>
      </div>
      <div class="form">
        <ContentForm
          v-model="updatedContent"
          :form="data.form"
          :additional-fields="data.additionalFields"
        />
      </div>
      <div class="submit">
        <el-button
          type="success"
          plain
          @click="submit()"
        >
          {{ $t("validate") }}
        </el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import ContentForm from "@/pages/ContentManager/Contents/ContentForm";

export default {
  props: {
    value: Boolean,
    data: Object,
  },
  components: {
    ContentForm,
  },
  data() {
    return {
      updatedContent: {},
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    submit() {
      let details = {};
      details.entity = this.updatedContent;
      details.link = this.data;
      this.$emit("update-content", details);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  max-height: 700px;
}

.title {
  text-align: center;
}

.submit {
  margin-top: 1rem;
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "editContent": "Edit content",
    "validate": "Validate"
  },
  "fr": {
    "editContent": "Contenu",
    "validate": "Valider"
  },
  "de": {
    "editContent": "Inhalt",
    "validate": "Bestätigen"
  }
}
</i18n>
