<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="75%"
    top="5vh"
  >
    <slot />
    <div v-if="metaContentsData.metaContents && metaContentsData.contentMedian">
      <div slot="title">
        <h3>
          {{ $t("optimizationSeo") }} <strong>{{ keyword.text }}</strong>
        </h3>
      </div>
    </div>
    <div
      v-else
      class="text-center"
      style="height: 75vh"
      v-loading="true"
    />
    <el-table
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column
        prop="url"
        label="URL"
        class-name="wrap-text"
      >
        <template slot-scope="scope">
          <a
            :href="scope.row.url"
            target="_blank"
            >{{ scope.row.url }}</a
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="score"
        :label="$t('scoreOptimizationSeo')"
        class-name="text-center"
        width="200"
      >
        <template slot-scope="scope">
          <h5 :class="scope.row.color">
            <strong> {{ scope.row.score }}</strong>
          </h5>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    keyword: Object,
    metaContentsData: Object,
    contentOptimizationData: Object,
    defaultSearchEngine: Object,
    seoScores: Array,
  },
  methods: {
    color(score) {
      if (isNaN(score)) return;
      if (score < 20) return "very-low";
      if (score < 40) return "low";
      if (score < 60) return "medium";
      if (score < 80) return "good";
      return "very-good";
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    tableData() {
      if (this.seoScores) {
        return this.seoScores.map((object) => {
          return {
            url: object.url,
            score: Math.round(object.ownScore),
            color: this.color(object.ownScore),
          };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.very-low {
  color: #ff0000;
}

.low {
  color: #ffcc00;
}

.medium {
  color: #4ecdc4;
}

.good {
  color: #66ff00;
}

.very-good {
  color: #008000;
}
</style>

<i18n>
{
    "en": {
        "wordsLength": "{length} words",
        "contentLength": "Content length",
        "numberOfWords": "Number of words",
        "yourPage": "Your page",
        "medianOfFirstTenResults": "Median of the first 10 results",
        "maximumOfFirstTenResults": "Maximum of the first 10 results",
        "minimumOfFirstTenResults": "Minimum of the first 10 results",
        "scoreCalculatedAt":"Score calculated on: {date}",
        "tagH1": "H1 tag",
        "h1sOfCompetitors": "Competitors' H1s",
        "yourH1": "Your H1",
        "fetchedAt": "Fetched on",
        "scoreOptimizationSeo":"Score SEO Optimization",
        "optimizationSeo": "SEO Optimization"
    },
    "fr": {
        "wordsLength": "{length} mots",
        "contentLength": "Taille du contenu",
        "numberOfWords": "Nombre de mots",
        "yourPage": "Votre page",
        "scoreCalculatedAt":"Score calculé le: {date}",
        "medianOfFirstTenResults": "Mediane des 10 premiers résultats",
        "maximumOfFirstTenResults": "Maximum des 10 premiers résultats",
        "minimumOfFirstTenResults": "Minimum des 10 premiers résultats",
        "tagH1": "Balise H1",
        "h1sOfCompetitors": "Balises H1 de vos concurrents : inspirez-vous !",
        "yourH1": "Your H1 tag",
        "fetchedAt": "Récupérée le",
        "scoreOptimizationSeo": "Score Optimisation SEO",
        "optimizationSeo": "Optimisation SEO"
    }
}
</i18n>
