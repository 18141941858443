<template>
  <div class="container">
    <div class="container-select">
      <button
        type="button"
        class="recommendation-btn"
        :class="{ clicked: genAiTab === 'h1' }"
        @click="selectTab('h1')"
      >
        H1
      </button>
      <button
        type="button"
        class="recommendation-btn"
        :class="{ clicked: genAiTab === 'plan' }"
        @click="selectTab('plan')"
      >
        {{ $t("plan") }}
      </button>
      <button v-if="isAdmin"
        type="button"
        class="recommendation-btn"
        :class="{ clicked: genAiTab === 'content' }"
        @click="selectTab('content')"
      >
        {{ $t("content") }}
      </button>
    </div>
    <SuggestionsCard
      :aiResponseArray="selectDisplayableDataAi"
      :genAiTab="genAiTab"
      @radioEvent="handleRadioEvent"
      @genAiEvent="handleGenAIEvent"
    />
  </div>
</template>

<script>
import SuggestionsCard from "@/pages/ActionPlan/Content/chatGptTable/SuggestionsCard.vue";

export default {
  props: {
    responseAI: Object,
    isAdmin: Boolean
  },
  components: {
    SuggestionsCard,
  },
  data() {
    return {
      genAiTab: "h1",
      aiResponseDisplayable: null,
      arrayOfTitleTags: [],
      splitedArray: [],
    };
  },
  computed: {
    selectDisplayableDataAi() {
      if (this.genAiTab === 'h1') this.aiResponseDisplayable = this.responseAI.h1s;
      else this.aiResponseDisplayable = this.splitedArray;
      return this.aiResponseDisplayable;
    },
  },
  methods: {
    selectTab(value) {
      this.genAiTab = value;
    },
    recurse(plan, incrTag) {
      for (const element of plan) {
        for (const property in element) {
          if (property === "text") {
            this.arrayOfTitleTags.push(
              "<h" + incrTag + "> " + element[property]
            );
          } else {
            let nextTitleTag = incrTag + 1;
            this.recurse(element[property], nextTitleTag);
          }
        }
      }
    },
    splitArray() {
      const found = this.arrayOfTitleTags.filter(
        (element) => element[2] === "1"
      );
      for (let i = 1; i < found.length; i++) {
        this.splitedArray.push(
          this.arrayOfTitleTags.splice(
            0,
            this.arrayOfTitleTags.indexOf(found[i])
          )
        );
      }
      this.splitedArray.push(this.arrayOfTitleTags);
    },
    handleRadioEvent(radioValue, isH1Clicked) {
      this.$emit("radioEvent", radioValue, isH1Clicked);
    },
    handleGenAIEvent(clickValue) {
      this.$emit("genAiEvent", clickValue);
    },
  },
  mounted() {
    this.recurse(this.responseAI.plan.h1s, 1);
    this.splitArray();
  },
};
</script>

<style scoped>
.container {
  padding-left: 0px;
  padding-right: 0px;
}

.container-select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  align-items: center;
  padding-bottom: 1em;
}

.recommendation-btn {
  margin-right: 1em;
  background-color: white;
  color: #606266;
  border-width: 0;
  height: 40px;
  width: 5em;
}

.clicked {
  color: #3c54a5;
  border-width: 0 0 2px 0;
  border-color: #3c54a5;
}

:focus {
  outline: none !important;
}
</style>

<i18n>
  {
    "en": {
      "plan" : "Plan",
      "content": "Content"
    },
    "fr": {
      "plan" : "Plan",
      "content": "Contenu"
    },
    "de": {
      "plan" : "Planen",
      "content": "Inhalt"
    }
  }
</i18n>
