<template>
  <div>
    <h4><i class="fas fa-briefcase"></i> {{ $t("kind") }}</h4>
    <el-select
      class="value"
      :value="value"
      size="medium"
      @click.native="$emit('click')"
      @input="(kind) => $emit('input', kind)"
      clearable
    >
      <el-option
        v-for="kind in kindOptions"
        :key="kind.value"
        :label="kind.label"
        :value="kind.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      // In a perfect world, kindOptions should actually be returned by the tasks service for more flexibility
      // and better maintenance
      kindOptions: [
        { value: "GLOBAL", label: this.$i18n.t("global") },
        { value: "CONTENT", label: this.$i18n.t("content") },
        { value: "TECHNICAL", label: this.$i18n.t("technical") },
        { value: "NETLINKING", label: this.$i18n.t("netlinking") },
        { value: "DESIGN", label: this.$i18n.t("design") },
      ],
    };
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "content": "Content",
    "design": "Design",
    "global": "Global",
    "kind": "Type",
    "netlinking": "Netlinking",
    "technical": "Technical"
  },
  "fr": {
    "content": "Contenu",
    "design": "Design",
    "global": "Global",
    "kind": "Genre",
    "netlinking": "Netlinking",
    "technical": "Technique"
  },
  "de": {
    "content": "Inhalt",
    "design": "Design",
    "global": "Global",
    "kind": "Genre",
    "netlinking": "Netzverlinkung",
    "technical": "Technik"
  }
}
</i18n>
