<template>
  <el-card>
    <el-tabs
      v-model="activeTab"
      tab-position="left"
    >
      <el-tab-pane
        name="trafficEvolution"
        :label="$t('trafficEvolution')"
      >
        <el-card shadow="never">
          <evolution-chart
            :data="websitesStatistics"
            :is-loading="isLoading"
          ></evolution-chart>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="visiblity"
        :label="$t('visibility')"
        lazy
      >
        <el-card shadow="never">
          <visibility-chart
            :data="websitesStatistics"
            :user="user"
            :is-loading="isLoading"
          ></visibility-chart>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="averagePosition"
        :label="$t('averagePosition')"
        lazy
      >
        <el-card shadow="never">
          <average-position-chart
            :data="websitesStatistics"
            :user="user"
            :is-loading="isLoading"
          ></average-position-chart>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="netlinkingMetrics"
        :label="$t('netlinking')"
        lazy
      >
        <el-card shadow="never">
          <netlinking-chart
            :data="netlinkingMetricHistory"
            :is-loading="isLoading"
            :studyUrl="studyResponse.data.url"
          ></netlinking-chart>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import EvolutionChart from "@/pages/Competitor/Charts/EvolutionChart";
import VisibilityChart from "@/pages/Competitor/Charts/VisibilityChart";
import AveragePositionChart from "@/pages/Competitor/Charts/AveragePositionChart";
import NetlinkingChart from "@/pages/Competitor/Charts/NetlinkingChart";

import { RepositoryFactory } from "@/services/repositoryFactory";
import { formatDateWithDashes } from "@/javascripts/formatDate";

const WebsitesStatisticsHistoriesRepository = RepositoryFactory.get(
  "websitesStatisticsHistories"
);

export default {
  props: {
    params: Object,
    studyResponse: Object,
    trackedKeywords: Array,
    websites: Object,
    user: Object,
  },
  components: {
    EvolutionChart,
    VisibilityChart,
    AveragePositionChart,
    NetlinkingChart,
  },
  data() {
    return {
      activeTab: "trafficEvolution",
      websitesStatistics: null,
      netlinkingMetricHistory: null,
      isLoading: false,
    };
  },
  computed: {
    defaultSearchEngine() {
      return this.studyResponse.data.searchEngines.find(
        (searchEngine) =>
          (searchEngine.id = this.studyResponse.data.defaultSearchEngineId)
      );
    },
  },
  methods: {
    getWebsitesStatistics() {
      this.isLoading = true;
      let request = {
        urls: Object.values(this.websites).map((w) => ({
          url: w.domain,
          type: "DOMAIN",
        })),
        keywords: this.trackedKeywords.map((trackedKeyword) => ({
          text: trackedKeyword.text,
          locationId: trackedKeyword.locationId,
        })),
        rangeDate: {
          start: this.params.startDate.toString().split("T")[0],
          end: this.params.endDate.toString().split("T")[0],
        },
        frequency: this.params.frequency === "WEEK" ? "WEEKLY" : "DAILY",
        positionsBuckets: [{ min: 1, max: 10 }],
        searchEngineParameters: {
          isoCountryCode: this.params.searchEngine.isoCountryCode,
          device: this.params.searchEngine.device,
          isoLanguageCode: this.params.searchEngine.isoLanguageCode,
        },
      };

      this.$api
        .post("/positions/websites-statistics-history", request)
        .then((response) => {
          const websitesStatistics = {};

          websitesStatistics.self = response.data.data.find(
            (ws) => ws.url.url === this.studyResponse.data.url
          );
          websitesStatistics.competitor = response.data.data.find(
            (ws) => ws.url.url !== this.studyResponse.data.url
          );

          this.websitesStatistics = websitesStatistics;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNetlinkingMetricsHistory() {
      this.isLoading = true;

      let requestMetricsHistory = {};
      requestMetricsHistory.urls = [
        { url: this.studyResponse.data.url, type: "DOMAIN" },
        { url: this.websites.competitor.domain, type: "DOMAIN" },
      ];

      requestMetricsHistory.startDate = formatDateWithDashes(
        this.params.startDate
      );
      requestMetricsHistory.endDate = formatDateWithDashes(this.params.endDate);
      requestMetricsHistory.isoCountryCode =
        this.defaultSearchEngine.countryIsoCode;
      this.$api
        .post(
          `/studies/${this.studyResponse.data.id}/netlinking/urls/metrics/history`,
          { ...requestMetricsHistory }
        )
        .then((response) => {
          this.netlinkingMetricHistory = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.netlinkingMetricHistory = {};
          console.log(error);
          this.$message({
            message: this.$i18n.t("failFetch"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
  },
  watch: {
    params() {
      this.getWebsitesStatistics();
      this.getNetlinkingMetricsHistory();
    },
    websites() {
      this.getWebsitesStatistics();
      this.getNetlinkingMetricsHistory();
    },
  },
  mounted() {
    this.getWebsitesStatistics();
    this.getNetlinkingMetricsHistory();
  },
};
</script>

<i18n>
{
  "en": {
    "averagePosition": "Average position",
    "failFetch": "Error encountered",
    "netlinking": "Netlinking",
    "trafficEvolution": "Evolution of estimated traffic",
    "visibility": "Visibility"
  },
  "fr": {
    "averagePosition": "Position moyenne",
    "failFetch": "Nous avons rencontré une erreur",
    "netlinking": "Netlinking",
    "trafficEvolution": "Evolution du trafic estimé",
    "visibility": "Visibilité"
  },
  "de": {
    "averagePosition": "Durchschnittliche Position",
    "failFetch": "Es ist ein Fehler aufgetreten",
    "netlinking": "Netzverlinkung",
    "trafficEvolution": "Entwicklung des geschätzten Verkehrs",
    "visibility": "Sichtweite"
  }
}
</i18n>
