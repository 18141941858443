
<template>
    <li class="nav-item pl-4">
      <a
        :href="urlNormalizationLink"
        class="nav-link"
      >
        {{$t('urlText')}}
      </a>
    </li>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    data() {
      return {
        urlNormalizationLink: null,
        googleGscAnchor: "#googleGSC",
      };
    },
    computed: {
      ...mapState(["study", "studyLinks"]),
    },
    mounted() {
      this.urlNormalizationLink = `/spa-app/studies/${this.study["id"]}/configuration?tab=urlNormalization`;
    },
  };
  </script>
  
<i18n>
    {
      "en": {
        "urlText": "Url Cleaning"
      },
      "fr": {
        "urlText": "Nettoyage de vos URLs"
      },
      "de": {
        "urlText": "Url-Reinigung"
      }
    }
    </i18n>
    