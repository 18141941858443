export default {
  backlinks: {
    domainRefused: {
      metrics_too_low: "Metrics too low",
      wrong_thematic: "Wrong thematic",
      da_too_low: "DA too low",
      tf_too_low: "TF too low",
      traffic_too_low_or_null: "Traffic too low or null",
      price_too_high: "Price too high",
      site_not_relevant: "Site not relevant",
      keywords_not_relevant: "Keywords not relevant",
      link_already_exists: "Link already exists",
      other: "Other",
    },
    gridHeader: {
      target_url: "Target page",
    }
  },
};
