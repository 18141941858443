<template>
  <div>
    <!-- <span v-if="params.value">
            <i class="fas fa-exclamation-triangle warning"></i>
            {{ $t("yes")}}
        </span> -->
    <el-tag
      v-if="!params.value"
      effect="dark"
      size="mini"
      type="success"
    >
      {{ $t("follow") }}
    </el-tag>
    <el-tag
      v-else
      effect="dark"
      size="mini"
      type="danger"
    >
      {{ $t("no_follow") }}
    </el-tag>
  </div>
</template>

<script>
export default {
  methods: {
    customColor(score) {
      if (score < 33) {
        return "#FF0000";
      } else if (score < 66) {
        return "#e6a23c";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.warning {
  color: $--color-warning;
}
</style>

<i18n>
{
  "en": {
    "yes": "Yes",
    "follow": "Follow",
    "no_follow": "No Follow"
  },
  "fr": {
    "yes": "Oui",
    "follow": "Follow",
    "no_follow": "No Follow"
  },
  "de": {
    "yes": "Ja",
    "follow": "Folgen",
    "no_follow": "Kein Folgen"
  }
}
</i18n>
