<template>
  <el-form-item :label="$t('competitor_type')">
    <el-select
      v-model="competitorInput"
      filterable
      :placeholder="$t('select_competitor_type')"
    >
      <el-option
        v-for="item in competitorTypeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  props: {
    value: String,
    competitorTypeOptions: Array,
  },
  computed: {
    competitorInput: {
      get() {
        return this.value;
      },
      set(competitorInput) {
        this.$emit("input", competitorInput);
      },
    },
  },
};
</script>

<style lang="scss">
.competitor-input-popover {
  ::v-deep .el-select .el-input__inner {
    width: 300px;
  }
}
</style>

<i18n>
{
  "en": {
    "competitor_type": "Competitor type",
    "select_competitor_type": "Select competitor type",
    "add_competitor_type": "Add",
    "none": "None"
  },
  "fr": {
    "competitor_type": "Type de concurrents",
    "select_competitor_type": "Sélectionnez un groupe de concurrents",
    "add_competitor_type": "Ajouter",
    "none": "Aucun"
  },
  "de": {
    "competitor_type": "Art der Konkurrenten",
    "select_competitor_type": "Wählen Sie eine Gruppe von Konkurrenten aus",
    "add_competitor_type": "Hinzufügen",
    "none": "Keiner"
  }
}
</i18n>
