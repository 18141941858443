<template>
  <div
    class="list-group pointer-cursor"
    v-loading="isLoadingConfigurations"
  >
    <a
      @click="deleteConfigurations()"
      class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
    >
      <div v-if="gaConfiguration">
        {{ study.url }} {{ $t("googleAnalyticsConnected") }}
        {{ gaConfiguration.view }}
        <span class="action-tip text-danger">{{ $t("unlink") }} </span>
      </div>
      <i class="fas fa-link text-success"></i>
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isLoadingConfigurations: false,
      module: null,
      gaConfiguration: null,
    };
  },

  methods: {
    readConfigurations() {
      this.isLoadingConfigurations = true;
      this.$api[this.module.read.method.toLowerCase()](this.module.read.href)
        .then((response) => {
          this.isLoadingConfigurations = false;
          this.gaConfiguration = response.data.data.attributes;
        })
        .catch((error) => {
          this.isLoadingConfigurations = false;
          console.error(error);
        });
    },
    deleteConfigurations() {
      this.isLoadingConfigurations = true;
      this.$api[this.module.delete.method.toLowerCase()](
        this.module.delete.href
      )
        .then((response) => {
          this.isLoadingConfigurations = false;

          this.$message({
            message: this.$i18n.t("deleteSuccessful"),
            type: "success",
            duration: 6000,
          });

          location.reload(true);
        })
        .catch((error) => {
          this.isLoadingConfigurations = false;
          console.error(error);
          this.$message({
            message: this.$i18n.t("AnErrorHappened"),
            type: "error",
            duration: 6000,
          });
        });
    },
    fetchModule() {
      this.isLoadingConfigurations = true;
      this.$api[this.gaConfigurationsModule.method.toLowerCase()](
        this.gaConfigurationsModule.href
      )
        .then((response) => {
          this.module = response.data.data.configurations;
          this.isLoadingConfigurations = false;
          this.readConfigurations();
        })
        .catch((error) => {
          this.isLoadingConfigurations = false;
          console.error(error);
          this.$message({
            message: this.$i18n.t("AnErrorHappened"),
            type: "error",
            duration: 6000,
          });
        });
    },
  },
  computed: {
    ...mapState(["study", "studyLinks"]),

    gaConfigurationsModule() {
      return this.studyLinks["gaConfigurations"];
    },
  },
  created() {
    this.fetchModule();
  },
};
</script>

<style scoped>
.pointer-cursor {
  cursor: pointer;
}
</style>

<i18n>
{
  "en": {
    "unlink": "Unlink",
    "googleAnalyticsConnected": "connected with the view",
    "deleteSuccessful": "Google Analytics configuration is deleted successfully",
    "AnErrorHappened": "An error happened"
  },
  "fr": {
    "unlink": "Unlink",
    "googleAnalyticsConnected": "connecté avec la vue",
    "deleteSuccessful": "La configuration de Google Analytics est supprimée avec succès",
    "AnErrorHappened": "Une erreur est survenue"
  },
  "de": {
    "unlink": "Verknüpfung aufheben",
    "googleAnalyticsConnected": "mit dem Sehen verbunden",
    "deleteSuccessful": "Die Google Analytics-Konfiguration wurde erfolgreich gelöscht",
    "AnErrorHappened": "Ein Fehler ist aufgetreten"
  }
}
</i18n>
