<template>
  <el-form
    v-if="form"
    :model="form"
    label-width="210px"
  >
    <h4>{{ $t("description") }}</h4>
    <el-form-item
      :label="$t('companyDescription')"
      required
    >
      <el-input
        type="textarea"
        rows="3"
        v-model="form.companyDescription"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item
      :label="$t('valueProposition')"
      required
    >
      <el-input
        type="textarea"
        rows="3"
        v-model="form.valueProposition"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item
      :label="$t('product')"
      required
    >
      <el-input
        type="textarea"
        rows="3"
        v-model="form.product"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <h4>{{ $t("target") }}</h4>
    <el-form-item :label="$t('targetExample')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.target"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <h4>{{ $t("content") }}</h4>
    <el-form-item :label="$t('contentLength')">
      <el-input-number
        v-model="form.contentLength"
        :disabled="disabled"
      ></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('contentObjective')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.contentObjective"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item :label="$t('contentType')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.contentType"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item :label="$t('contentSubject')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.contentSubject"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item :label="$t('subjectsCoveredByContent')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.subjectsCoveredByContent"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <h4>{{ $t("typography") }}</h4>
    <el-form-item :label="$t('typographyExample')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.typography"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <h4>{{ $t("tone") }}</h4>
    <el-form-item :label="$t('toneExample')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.tone"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <h4>{{ $t("legalSensitivity") }}</h4>
    <el-form-item :label="$t('legalSensitivityDontMention')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.legalSensitivityDontMention"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item :label="$t('globalGuidelines')">
      <el-input
        type="textarea"
        rows="3"
        v-model="form.globalGuidelines"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item
      v-if="updateClientInfoLink"
      class="button"
    >
      <el-button
        type="primary"
        @click="onSubmit"
        >{{ $t("save") }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    readClientInfoLink: Object,
    updateClientInfoLink: Object,
    disabled: Boolean,
  },
  data() {
    return {
      form: null,
    };
  },
  methods: {
    onSubmit() {
      if (
        this.form.valueProposition.length != 0 &&
        this.form.product.length != 0 &&
        this.form.companyDescription.length != 0
      ) {
        this.$api[this.updateClientInfoLink.method.toLowerCase()](
          this.updateClientInfoLink.href,
          this.form
        )
          .then((response) => {
            this.form = response.data.data;
            this.$message({
              message: this.$i18n.t("clientInfoUpdated"),
              type: "succes",
              duration: 6000,
            });
          })
          .catch((error) => {
            this.$message({
              message: this.$i18n.t("clientInfoUpdateFailed"),
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      } else {
        this.$message({
          message: this.$i18n.t("emptyField"),
          type: "error",
          duration: 6000,
        });
      }
    },
  },
  created() {
    if (this.readClientInfoLink) {
      this.$api[this.readClientInfoLink.method.toLowerCase()](
        this.readClientInfoLink.href
      )
        .then((response) => {
          this.form = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style>
.button {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "clientInfoUpdated": "Editorial brief was updated successfully",
    "clientInfoUpdateFailed": "the update of the editorial brief failed",
    "companyDescription": "Company description",
    "content": "Content",
    "contentLength": "Content Length :",
    "contentObjective": "Content Objective :",
    "contentSubject": "Content Subject :",
    "contentType": "Content Type :",
    "description": "Site description",
    "emptyField": "Company description, value proposition and product / Site service must not be empty.",
    "globalGuidelines": "Global Guidelines :",
    "legalSensitivity": "Legal Sensitivity",
    "legalSensitivityDontMention": "Don't Mention :",
    "product": "Product / Site service :",
    "save": "Save modifications",
    "subjectsCoveredByContent": "Subjects Covered By Content :",
    "target": "Target",
    "targetExample": "Target example :",
    "tone": "Tone",
    "toneExample": "Tone example :",
    "typography": "Typography",
    "typographyExample": "Typography example :",
    "valueProposition": "Value Proposition :"
  },
  "fr": {
    "clientInfoUpdated": "Brief editorial a été modifiée avec succès",
    "clientInfoUpdateFailed": "La modification du brief editorial a échoué",
    "companyDescription": "Description de la société",
    "content": "Contenu",
    "contentLength": "Taille du contenu :",
    "contentObjective": "Objectif du contenu :",
    "contentSubject": "Thème / Sujet du contenu :",
    "contentType": "Type du contenu :",
    "description": "Description du site",
    "emptyField": "Description de la société, la proposition de valeur et produit / service du site ne doivent pas être vides",
    "globalGuidelines": "Guidelines Globales :",
    "legalSensitivity": "Sensibilité juridique",
    "legalSensitivityDontMention": "Ne pas mentionner :",
    "product": "Produit / service du site :",
    "save": "Enregistrer les modifications",
    "subjectsCoveredByContent": "Sujets couverts par le contenu :",
    "target": "Cible",
    "targetExample": "Exemple de cible :",
    "tone": "Ton",
    "toneExample": "Exemple de ton :",
    "typography": "Typographie",
    "typographyExample": "Exemple de typographie:",
    "valueProposition": "Proposition de valeur :"
  },
  "de": {
    "clientInfoUpdated": "Kurzes Editorial wurde erfolgreich geändert",
    "clientInfoUpdateFailed": "Die Bearbeitung des redaktionellen Briefings ist fehlgeschlagen",
    "companyDescription": "Beschreibung des Unternehmens",
    "content": "Inhalt",
    "contentLength": "Inhaltsgröße:",
    "contentObjective": "Inhaltliches Ziel:",
    "contentSubject": "Thema / Inhaltliches Thema:",
    "contentType": "Inhaltstyp:",
    "description": "Seitenbeschreibung",
    "emptyField": "Die Beschreibung des Unternehmens, des Wertversprechens und des Produkts / der Dienstleistung der Website darf nicht leer sein",
    "globalGuidelines": "Guidelines Globales :",
    "legalSensitivity": "Rechtliche Sensibilität",
    "legalSensitivityDontMention": "Nicht erwähnen :",
    "product": "Produkt / Dienstleistung der Website:",
    "save": "Änderungen speichern",
    "subjectsCoveredByContent": "Inhaltlich behandelte Themen:",
    "target": "Ziel",
    "targetExample": "Zielbeispiel:",
    "tone": "Tonne",
    "toneExample": "Beispiel Ton:",
    "typography": "Typographie",
    "typographyExample": "Beispiel für Typografie:",
    "valueProposition": "Wertversprechen :"
  }
}
</i18n>
