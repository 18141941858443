<template>
  <el-card>
    <el-row 
      type="flex" 
      justify="end" 
      style="margin-bottom: 8px"
    >
      <el-button
        size="small"
        type="primary"
        @click="showDialog"
      >
        {{ $t("manageNotes") }}
      </el-button>
    </el-row>
    <el-tabs
      v-model="activeTab"
      tab-position="left"
    >
      <el-tab-pane
        name="positionsDistributionChart"
        :label="$t('positionsDistribution')"
      >
        <el-card shadow="never">
          <positions-distribution-chart
            :data="dataRanking"
            :is-loading="isLoadingRankingMetrics || isLoadingTrackedKeywords"
            :globalNotes="globalNotes"
            :studyNotes="studyNotes"
            :request="request"
          ></positions-distribution-chart>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="visibilityChart"
        :label="$t('visibility')"
        lazy
      >
        <el-card shadow="never">
          <visibility-chart
            :data="dataRanking"
            :user="user"
            :is-loading="isLoadingRankingMetrics || isLoadingTrackedKeywords"
          ></visibility-chart>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="averagePositionChart"
        :label="$t('averagePosition')"
        lazy
      >
        <el-card shadow="never">
          <average-position-chart
            :data="dataRanking"
            :user="user"
            :is-loading="isLoadingRankingMetrics || isLoadingTrackedKeywords"
          ></average-position-chart>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="seasonalityChart"
        :label="$t('seasonality')"
        lazy
      >
        <el-card shadow="never">
          <SeasonalityChart
            :data="dataSeasonality"
            :is-loading="isLoadingRankingMetrics || isLoadingTrackedKeywords"
          ></SeasonalityChart>
        </el-card>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :visible.sync="showManageNotesModal"
      :close-on-click-modal="false"
      width="62%"
      @toggleDialog="updateNoteForm"
    >
      <span slot="title" class="align-center">
        <h2>{{ $t("manageNotes") }}</h2>
      </span>
      <div style="display: flex; justify-content: end;">
        <el-button 
          size="small" 
          type="primary" 
          @click="showNoteForm = !showNoteForm"
        >
          {{ $t("addNote") }}
        </el-button>
      </div>
      <div v-if="showNoteForm">
        <note-form 
          :noteForm="formModel" 
          :studyId="studyId"
          @refreshNotes="refreshNotes"
          @resetForm="resetForm"
        />
      </div>
        <ag-grid-vue 
          style="height: 500px" 
          class="ag-theme-material" 
          :columnDefs="columnDefs" 
          :rowData="studyNotes"
          :resizable="true" 
          :sorting="true" 
          :filter="true" 
          :floatingFilter="true"
          :context="context"
          :overlayNoRowsTemplate="$t('noRow')"
          @gridReady="onGridReady"
        />
    </el-dialog>
  </el-card>
</template>

<script>
import PositionsDistributionChart from "@/pages/Keywords/Charts/PositionsDistributionChart";
import AveragePositionChart from "@/pages/Keywords/Charts/AveragePositionChart";
import VisibilityChart from "@/pages/Keywords/Charts/VisibilityChart";
import SeasonalityChart from "@/pages/Keywords/Charts/SeasonalityChart";
import NoteActionsRenderer from '@/components/gridRenderers/NoteActionsRenderer';
import NoteForm from '@/pages/Keywords/Charts/Forms/NoteForm';

import { AgGridVue } from "ag-grid-vue";

export default {
  props: {
    request: Object,
    studyId: Number,
    studyUrl: String,
    trackedKeywords: Array,
    isLoadingTrackedKeywords: Boolean,
    user: Object,
  },
  components: {
    PositionsDistributionChart,
    AveragePositionChart,
    VisibilityChart,
    SeasonalityChart,
    AgGridVue,
    NoteActionsRenderer,
    NoteForm,
  },
  data() {
    return {
      activeTab: "positionsDistributionChart",
      dataRanking: [],
      dataSeasonality: {},
      isLoadingRankingMetrics: false,
      isLoadingSeasonality: false,
      showManageNotesModal: false,
      columnDefs: null,
      studyNotes: [],
      globalNotes: [],
      showNoteForm: false,
      formModel: {
        id: '',
        title: '',
        comment: '',
        date: '',
      },
      context: {},
      noteModule: null,
      globalNotesModule: null,
    };
  },
  methods: {
    resetForm() {
      this.formModel = {
        id: '',
        title: '',
        comment: '',
        date: '',
      }
    },
    refreshNotes(message) {
      this.getNotes(message)
      this.formModel = {
        id: '',
        title: '',
        comment: '',
        date: '',
      }
    },
    updateNoteForm(noteData) {
      this.formModel = noteData
      this.showNoteForm = true;
    },
    deleteNote(noteId) {
      this.$api.delete(`studies/${this.studyId}/notes/${noteId}`, 
        {headers: { 'Accept': 'application/vnd.api+json' }}
      ).then(() => {
        this.getNotes("delete_success")
      }).catch(() => {
        this.$message.error(this.$i18n.t("delete_failure"));
        console.error("Failed to delete note");
      })
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          field: "id",
          hide: true,
          sortable: true,
        },
        {
          headerName: this.$i18n.t("title"),
          field: "title",
          minWidth: 250,
          flex: 3,
          filter: "agTextColumnFilter",
          cellClass: "no-left-padding-cell",
          filterParams: {
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          resizable: true,
          sortable: true,
        },
        {
          headerName: this.$i18n.t("comment"),
          field: "comment",
          minWidth: 150,
          flex: 2,
          filter: "agTextColumnFilter",
          cellClass: "no-left-padding-cell",
          filterParams: {
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          resizable: true,
          sortable: true,
        },
        {
          headerName: this.$i18n.t("date"),
          field: "date",
          minWidth: 150,
          maxWidth: 150,
          flex: 1,
          filter: "agTextColumnFilter",
          cellClass: "no-left-padding-cell",
          filterParams: {
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          sortable: true,
        },
        {
          headerName: this.$i18n.t("actions"),
          headerClass: "ag-centered-header",
          minWidth: 130,
          maxWidth: 150,
          flex: 1,
          cellRendererFramework: "NoteActionsRenderer",
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ]
    },
    showDialog() {
      this.formModel = {}
      this.showNoteForm = false;
      this.showManageNotesModal = true
    },
    fetchRankingMetrics() {
      if (!this.trackedKeywords) return;

      this.isLoadingRankingMetrics = true;
      const requestLocal = {
        urls: [{ url: this.studyUrl, type: "DOMAIN" }],
        rangeDate: {
          start: this.request.rangeDate.start,
          end: this.request.rangeDate.end,
        },
        searchEngineParameters: {
          isoCountryCode: this.request.searchEngine.isoCountryCode,
          device: this.request.searchEngine.device,
          isoLanguageCode: this.request.searchEngine.isoLanguageCode,
        },
        keywords: this.trackedKeywords.map((trackedKeyword) => {
          return {
            text: trackedKeyword.text,
            locationId: trackedKeyword.locationId,
          };
        }),
        positionsBuckets: [
          { min: 1, max: 3 },
          { min: 4, max: 10 },
          { min: 11, max: 30 },
          { min: 31, max: 100 },
        ],
      };

      switch (this.request.frequency) {
        case "MONTH":
          requestLocal.frequency = "MONTHLY";
          break;
        case "DAY":
          requestLocal.frequency = "DAILY";
          break;
        default:
          requestLocal.frequency = "WEEKLY";
      }

      this.$api
        .post("/positions/websites-statistics-history", requestLocal)
        .then((response) => {
          const websiteStatistics = response.data.data.find(
            (ws) => ws.url.url === this.studyUrl
          );
          if (websiteStatistics.websiteStatisticsByDates == null) {
            this.dataRanking = [];
          } else {
            this.dataRanking = Object.keys(
              websiteStatistics.websiteStatisticsByDates
            )
              .map((date) => {
                const values = websiteStatistics.websiteStatisticsByDates[date];
                return {
                  avgPosition: values.averagePosition,
                  estimatedTraffic: values.estimatedTraffic,
                  nbKeywords: values.numberOfKeywords,
                  top13: values.positionBucketsStatistics.find(
                    (bucket) =>
                      bucket.positionMin == 1 && bucket.positionMax == 3
                  ).numberOfKeywords,
                  top410: values.positionBucketsStatistics.find(
                    (bucket) =>
                      bucket.positionMin == 4 && bucket.positionMax == 10
                  ).numberOfKeywords,
                  top1130: values.positionBucketsStatistics.find(
                    (bucket) =>
                      bucket.positionMin == 11 && bucket.positionMax == 30
                  ).numberOfKeywords,
                  top31100: values.positionBucketsStatistics.find(
                    (bucket) =>
                      bucket.positionMin == 31 && bucket.positionMax == 100
                  ).numberOfKeywords,
                  date: new Date(date),
                };
              })
              .sort((a, b) => a.date - b.date);
          }

          this.isLoadingRankingMetrics = false;
        })
        .catch((error) => {
          this.dataRanking = [];
          console.error(error);
          this.isLoadingRankingMetrics = false;
        });
    },
    fetchSeasonality() {
      this.isLoadingSeasonality = true;
      let requestSeasonality = {};

      if (this.request.frequency === "DAY") {
        requestSeasonality.updateFrequency = "DAILY";
      }

      if (this.request.tagsGroups && this.request.tagsGroups.length > 0) {
        requestSeasonality.tagsGroups = this.request.tagsGroups;
        requestSeasonality.tagsGroups.forEach((tagsGroup, index, object) => {
          if (tagsGroup.length == 0) {
            object.splice(index, 1);
          }
        });
      }

      if (
        this.request.filterStudyTags &&
        this.request.filterStudyTags.length > 0
      ) {
        requestSeasonality.tags = this.request.filterStudyTags;
      }

      this.$api
        .post(
          "/studies/" + this.studyId + "/tracked-keywords/seasonality",
          requestSeasonality
        )
        .then((response) => {
          this.dataSeasonality = response.data.data;
          this.isLoadingSeasonality = false;
        })
        .catch((error) => {
          this.dataSeasonality = [];
          console.error(error);
          this.isLoadingSeasonality = false;
        });
    },
    fetchData() {
      this.fetchRankingMetrics();
      this.fetchSeasonality();
    },
    getStudiesNoteModule() {
      this.$api.get(`/studies/${this.studyId}/notes/module`, { headers: { 'Accept': 'application/vnd.api+json' }})
        .then((response) => {
          this.noteModule = response.data.data.attributes

          this.getNotes()
        })
        .catch((error) => {
          console.error(error);
      });
    },
    getGlobalNoteModule() {
      this.$api.get("/notes/global/module", { headers: { 'Accept': 'application/vnd.api+json' }})
        .then((response) => {
          this.globalNotesModule = response.data.data.attributes

          this.getGlobalNotes()
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getGlobalNotes() {
      if (!this.globalNotesModule)
       return
      this.$api[this.globalNotesModule.list.meta.method.toLowerCase()](this.globalNotesModule.list.href, { headers: { 'Accept': 'application/vnd.api+json' } })
        .then((response) => {
          this.globalNotes = (
            response.data.data.map((note) => ({
              id: note.attributes.id,
              title: note.attributes.title,
              comment: note.attributes.comment,
              date: note.attributes.date
            }))
          )
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getNotes(message) {
      if (!this.noteModule)
        return
      const response = await this.$api[this.noteModule.list.meta.method.toLowerCase()](this.noteModule.list.href, { headers: { 'Accept': 'application/vnd.api+json' } })
        .catch((error) => {
          console.error(error);
        });

      if (!response)
        return
      this.studyNotes = response.data.data.map((note) => ({
        id: note.attributes.id,
        title: note.attributes.title,
        comment: note.attributes.comment,
        date: note.attributes.date
      }))
      if (message)
        this.$message.success(this.$i18n.t(message));
    },
    fetchNotes() {
      this.getGlobalNoteModule()
      this.getStudiesNoteModule()
    },
    onGridReady(params) {
      const sortModel = [
        {colId: 'id', sort: 'desc'}
      ];
      params.api.setSortModel(sortModel);
    }
  },
  computed: {
    isLoading() {
      return this.isLoadingRankingMetrics || this.isLoadingSeasonality;
    },
  },
  watch: {
    request: {
      handler: "fetchData",
      deep: true,
    },
    trackedKeywords() {
      this.fetchData();
    },
    isLoading() {
      this.$emit("is-loading", this.isLoading);
    },
  },
  beforeMount() {
    this.context = {
      updateNoteForm: this.updateNoteForm,
      deleteNote: this.deleteNote,
      noteModule: this.noteModule,
    };
    this.setColumnDefs()
    this.fetchNotes()
  }
};
</script>

<style lang="scss" scoped>
.align-center {
  display: flex;
  justify-content: center;
}
</style>

<i18n>
{
  "en": {
    "positionsDistribution": "Ranking distribution",
    "visibility": "Visibility",
    "averagePosition": "Average ranking",
    "seasonality": "Seasonality",
    "manageNotes": "Manage my notes",
    "addNote": "Add a new note",
    "title": "Title",
    "comment": "Comment",
    "date": "Date",
    "actions": "Actions",
    "pickDate": "Pick a date",
    "delete_success": "Note deleted successfully",
    "delete_failure": "There was a issue while deleting the note",
    "update_success": "Note updated successfully",
    "create_success": "Note created successfully",
    "update_failure": "There was an error while updating the note",
    "create_failure": "There was an error while creating the note",
    "noRow": "No row to show"

  },
  "fr": {
    "positionsDistribution": "Répartition des positions",
    "visibility": "Visibilité",
    "averagePosition": "Position moyenne",
    "seasonality": "Saisonnalité",
    "manageNotes": "Gérer mes notes",
    "addNote": "Ajouter une note",
    "title": "Titre",
    "comment": "Commentaire",
    "date": "Date",
    "actions": "Actions",
    "pickDate": "Choisissez une date",
    "delete_success": "Note supprimée avec succès",
    "delete_failure": "Une erreur s'est produite lors de la suppression de la note",
    "update_success": "Note mise à jour avec succès",
    "create_success": "Note créée avec succès",
    "update_failure": "Une erreur s'est produite lors de la mise à jour de la note",
    "create_failure": "Une erreur s'est produite lors de la création de la note",
    "noRow": "Aucune ligne à afficher"
  },
  "de": {
    "positionsDistribution": "Aufschlüsselung der Positionen",
    "visibility": "Sichtweite",
    "averagePosition": "Durchschnittliche Position",
    "seasonality": "Saisonalität",
    "manageNotes": "Meine Notizen verwalten",
    "addNote": "Fügen Sie eine neue Notiz hinzu",
    "title": "Titel",
    "comment": "Kommentar",
    "date": "Datum",
    "actions": "Aktionen",
    "pickDate": "Suche dir einen Termin aus",
    "delete_success": "Notiz gelöscht erfolgreich",
    "delete_failure": "Beim Löschen der Notiz ist ein Problem aufgetreten",
    "create_success": "Notiz erfolgreich erstellt",
    "update_failure": "Beim Aktualisieren der Notiz ist ein Fehler aufgetreten",
    "create_failure": "Beim Erstellen der Notiz ist ein Fehler aufgetreten",
    "noRow": "Keine Zeile zum Anzeigen"
  }
}
</i18n>
