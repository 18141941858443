import Repository from "@/services/repository";

export default {
  getCompanyGroup(companyGroupId) {
    return Repository.get(`/company_groups/${companyGroupId}.json`).then(
      (response) => response.data
    );
  },

  getCompanyGroups() {
    return Repository.get(`/company_groups.json`).then(
      (response) => response.data
    );
  },

  createCompanyGroup(form) {
    return Repository.post(`/company_groups.json`, {
      companyGroup: {
        name: form.name,
        sellsyThirdId: form.sellsyThirdId,
        usersLimit: form.usersLimit,
        keywordsLimit: form.keywordsLimit,
        competitorsLimit: form.competitorsLimit,
        studiesLimit: form.studiesLimit,
      },
    }).then((response) => response);
  },

  deleteCompanyGroup(companyGroupId) {
    return Repository.delete(`/company_groups/${companyGroupId}.json`).then(
      (response) => response
    );
  },

  updateCompanyGroup(companyGroupId, form) {
    return Repository.patch(`/company_groups/${companyGroupId}.json`, {
      companyGroup: {
        name: form.name,
        sellsyThirdId: form.sellsyThirdId,
        usersLimit: form.usersLimit,
        keywordsLimit: form.keywordsLimit,
        competitorsLimit: form.competitorsLimit,
        studiesLimit: form.studiesLimit,
      },
    }).then((response) => response);
  },

  getCompanyGroupMetrics(companyGroupId) {
    return Repository.get(
      `/company_groups/${companyGroupId}/metrics.json`
    ).then((response) => response.data);
  },
  getCompanyGroupCompanies(companyGroupId) {
    return Repository.get(
      `/company_groups/${companyGroupId}/companies.json`
    ).then((response) => response.data);
  },
  getCompanyGroupInvoices(companyGroupId) {
    return Repository.get(
      `/company_groups/${companyGroupId}/company_group_invoices.json`
    ).then((response) => response.data);
  },

  createCompanyGroupCompany(companyGroupId, form) {
    return Repository.post(`/company_groups/${companyGroupId}/companies.json`, {
      company: {
        name: form.name,
      },
    }).then((response) => response);
  },

  createManager(companyGroupId, form) {
    return Repository.post(`/company_groups/${companyGroupId}/managers.json`, {
      manager: {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        phone: form.phone,
      },
    }).then((response) => response);
  },

  getCompanyGroupManagers(companyGroupId) {
    return Repository.get(
      `/company_groups/${companyGroupId}/managers.json`
    ).then((response) => response.data);
  },
};
