<template>
  <div>
    <div class="container">
      <keywords-grid
        v-loading="isLoading"
        :data="data"
      >
      </keywords-grid>
      <tweaks
        :granularity="body.granularity"
        @update-keywords="callAPI($event)"
        @cancel-search="cancelSearch($event)"
      ></tweaks>
    </div>
  </div>
</template>

<script>
import Tweaks from "@/pages/KeywordSearch/Tweaks";
import KeywordsGrid from "@/pages/KeywordSearch/KeywordsGrid";

export default {
  props: {
    body: Object,
  },
  components: {
    Tweaks,
    KeywordsGrid,
  },
  data() {
    return {
      data: null,
      isLoading: false,
    };
  },
  methods: {
    callAPI(value) {
      this.isLoading = true;
      this.body.granularity = value;
      this.$api
        .post("/semantic/deduplication", this.body)
        .then((data) => {
          this.data = data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    cancelSearch(value) {
      this.$emit("cancel-search", value);
    },
  },
  beforeMount() {
    this.callAPI(this.body.granularity);
  },
  watch: {
    body() {
      this.callAPI(this.body.granularity);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "keywordSearch": "Keywords Search"
  },
  "fr": {
    "keywordSearch": "Recherche de mots clés"
  },
  "de": {
    "keywordSearch": "Stichwortforschung"
  }
}
</i18n>
