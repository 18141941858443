<template>
  <div>
    <el-card>
      <el-collapse>
        <el-collapse-item>
          <div
            slot="title"
            class="flex-box"
          >
            <span>
              <span class="campaign-name">
                {{ data.name }}
              </span>
              <span class="campaign-info">
                - {{ currencyFormat(data.budget) }}
              </span>
              <span
                class="campaign-info"
                v-if="campaignDetails"
              >
                {{ campaignDate }} -
                {{
                  isStatusTerminated()
                    ? $t("terminated")
                    : data.status == "IN_PROGRESS"
                    ? $t("in_progress")
                    : $t(
                        campaignDetails.meta.netlinkingCampaignStatus.toLowerCase()
                      )
                }}
              </span>
              <el-tooltip
                v-if="campaignDetails && clientInfoIsEmpty"
                placement="top"
                :content="$t('you_have_to_fill_up_client_info')"
              >
                <span class="alert-notif">
                  - <i class="fas fa-exclamation-triangle"></i>
                </span>
              </el-tooltip>
            </span>
            <div
              class="delete-update-buttons"
              v-if="campaignDetails"
            >
              <edit-campaign-button
                :actions="campaignDetails.actions"
                @edit="showEditCampaignDialog($event)"
              >
              </edit-campaign-button>
              <terminate-campaign-button
                :actions="campaignDetails.actions"
                @terminate="terminateCampaign($event)"
              >
              </terminate-campaign-button>
              <delete-campaign-button
                :actions="campaignDetails.actions"
                @delete="deleteCampaign($event)"
              >
              </delete-campaign-button>
            </div>
          </div>

          <el-divider />
          <div
            class="client"
            v-if="campaignDetails"
          >
            <h4
              class="mini-title"
              v-if="
                campaignDetails.actions &&
                campaignDetails.actions.updateClientInfo
              "
            >
              <strong>{{ $t("client_infos") }}</strong>
            </h4>
            <div>
              <el-button
                v-if="
                  campaignDetails.actions &&
                  campaignDetails.actions.updateClientInfo
                "
                v-on:click.stop="
                  showEditClientDialog(campaignDetails.actions.updateClientInfo)
                "
                class="button"
                size="small"
                type="primary"
                plain
              >
                <i class="fas fa-edit"></i> {{ $t("modify") }}
              </el-button>
            </div>
            <el-button
              v-if="
                campaignDetails.actions &&
                campaignDetails.actions.launchCampaign
              "
              class="launch-button"
              size="small"
              type="success"
              @click="launchCampaign(campaignDetails.actions.launchCampaign)"
            >
              {{ $t("launch_campaign") }}
            </el-button>
          </div>
          <div
            class="description"
            v-if="campaignDetails"
          >
            <h4 class="mini-title">
              <strong>{{ $t("description") }}</strong>
            </h4>
            <div
              class="desc-data"
              :key="data.description"
            >
              {{ data.description }}
            </div>
          </div>
          <div
            class="watchers"
            v-if="campaignDetails"
          >
            <h4 class="mini-title">
              <strong>{{ $t("watchers") }}</strong>
            </h4>
            <div>
              <el-tag
                class="watcher"
                v-for="watcher in campaignDetails.watchers"
                v-bind:key="watcher.id"
              >
                {{ watcher.firstName + " " + watcher.lastName }}
              </el-tag>
            </div>
          </div>
          <el-divider />
          <backlinks
            v-if="campaignDetails"
            :data="campaignDetails"
            :bulkUpdate="bulkUpdateLink"
            :admin="admin"
            :study-url="studyUrl"
            :default-search-engine="defaultSearchEngine"
            :account="account"
            :readCampaign="this.data.actions.read"
            @refresh-meta="(newMeta) => (campaignDetails.meta = newMeta)"
            @backlinks="assignBacklinks($event)"
            @refresh-campaign="fetchCampaignDetails()"
          >
          </backlinks>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <edit-campaign-dialog
      v-if="data.actions && data.actions.update"
      v-model="editCampaignDialogVisible"
      :data="data.actions.update"
      @update-campaign="updateCompaign($event)"
      :isCampaign="true"
    >
    </edit-campaign-dialog>
    <edit-client-dialog
      v-if="
        campaignDetails &&
        campaignDetails.actions &&
        campaignDetails.actions.updateClientInfo
      "
      v-model="editClientDialogVisible"
      :data="campaignDetails.actions.updateClientInfo"
      @update-client="updateClientInfo($event)"
    >
    </edit-client-dialog>
  </div>
</template>

<script>
import Backlinks from "@/pages/CampaignManager/Backlinks";
import EditCampaignDialog from "@/components/CampaignManager/EditCampaignDialog";
import EditClientDialog from "@/pages/CampaignManager/Campaign/EditClientDialog";
import DeleteCampaignButton from "@/components/CampaignManager/DeleteCampaignButton";
import EditCampaignButton from "@/components/CampaignManager/EditCampaignButton";
import TerminateCampaignButton from "@/components/CampaignManager/TerminateCampaignButton";

export default {
  components: {
    Backlinks,
    EditCampaignDialog,
    EditClientDialog,
    DeleteCampaignButton,
    EditCampaignButton,
    TerminateCampaignButton,
  },
  props: {
    data: Object,
    studyUrl: String,
    admin: Boolean,
    defaultSearchEngine: Object,
    account: Object,
  },
  data() {
    return {
      backlinks: null,
      campaignDetails: null,
      isLoadingBacklinks: false,
      editClientDialogVisible: false,
      editCampaignDialogVisible: false,
      backlinks: null,
      bulkUpdateLink: null,
    };
  },
  methods: {
    fetchCampaignDetails() {
      if (this.data.actions && this.data.actions.read) {
        this.$api[this.data.actions.read.method.toLowerCase()](
          this.data.actions.read.href
        )
          .then((response) => {
            this.campaignDetails = response.data.data;
            this.bulkUpdateLink = response.data.data.links.backlinks.bulkUpdate;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    updateCompaign(details) {
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.campaign
      )
        .then((response) => {
          this.campaignDetails = response.data.data;
          this.$emit("update-campaign", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateClientInfo(details) {
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.client
      )
        .then((response) => {
          this.fetchCampaignDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showEditCampaignDialog(details) {
      this.editCampaignDialogVisible = true;
    },
    showEditClientDialog(details) {
      this.editClientDialogVisible = true;
    },
    deleteCampaign(deleteAction) {
      let details = {};
      details.link = deleteAction;
      this.$emit("delete-campaign", details);
    },
    launchCampaign(launchAction) {
      let details = {};
      details.link = launchAction;
      this.$emit("launch-campaign", details);
    },
    terminateCampaign(terminateAction) {
      let details = {};
      details.link = terminateAction;
      details.campaign = { isTerminated: true };
      this.$emit("terminate-campaign", details);
    },
    isStatusTerminated() {
      return this.data.isTerminated;
    },
    assignBacklinks(backlinks) {
      this.backlinks = backlinks;
    },
    formatNumber(number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
    currencyFormat(value) {
      return this.$options.filters.formatCurrency(value, this.$i18n.locale);
    },
  },
  computed: {
    campaignDate() {
      return "- ".concat(
        this.$t("from"),
        " ",
        new Date(this.data.period.start).toLocaleDateString(
          this.$i18n.locale === "en" ? "en-US" : "fr-FR",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }
        ),
        " ",
        this.$t("to"),
        " ",
        new Date(this.data.period.end).toLocaleDateString(
          this.$i18n.locale === "en" ? "en-US" : "fr-FR",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }
        )
      );
    },
    clientInfoIsEmpty() {
      if (this.campaignDetails) {
        const clientInfos = this.campaignDetails.clientInfo;
        for (const field in this.campaignDetails.clientInfo) {
          if (clientInfos[field] && clientInfos[field].length > 0) {
            return false;
          }
        }
      }
      return true;
    },
    budgetSpent() {
      return this.backlinks
        .filter(
          (backlink) =>
            !["refused", "draft", "canceled", "to_validate"].includes(
              backlink.status.toLowerCase()
            )
        )
        .map((backlink) => backlink.price)
        .reduce((a, b) => a + b, 0);
    },
    numberOfBacklinksPublished() {
      return this.backlinks.filter(
        (backlink) => backlink.status.toLowerCase() == "published"
      ).length;
    },
    numberOfBacklinksOnGoing() {
      return this.backlinks.filter((backlink) =>
        ["validated", "redaction", "modify"].includes(
          backlink.status.toLowerCase()
        )
      ).length;
    },
    numberOfBacklinksToValidate() {
      return this.backlinks.filter((backlink) =>
        ["to_validate"].includes(backlink.status.toLowerCase())
      ).length;
    },
    percentageCompletionCampaign() {
      const budgetPublishedBacklink = this.backlinks
        .filter((backlink) => backlink.status.toLowerCase() == "published")
        .map((backlink) => backlink.price)
        .reduce((a, b) => a + b, 0);
      return Math.round((budgetPublishedBacklink / this.data.budget) * 100);
    },
  },
  mounted() {
    this.fetchCampaignDetails();
  },
  watch: {
    data() {
      this.fetchCampaignDetails();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

.flex-box {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;

  .delete-update-buttons {
    margin-left: auto;
    display: flex;
  }

  .campaign-name {
    font-size: 1.35rem;
    font-weight: bold;
  }

  .campaign-info {
    font-size: 1.15rem;
  }

  .alert-notif {
    font-size: 1.15rem;

    .fas {
      color: $--color-danger;
    }
  }
}

.kpis {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  width: 100%;

  .kpi {
    text-align: center;
    font-size: 1.05rem;

    .fa-question-circle {
      font-size: 0.9rem;
    }
  }
}

.client {
  display: flex;
  vertical-align: middle;
  margin-bottom: 1rem;

  .launch-button {
    margin-left: auto;
    margin-right: 1rem;
  }

  .mini-title {
    flex-basis: 25%;
  }
}

.description {
  display: flex;
  vertical-align: middle;
  margin-bottom: 1rem;

  .mini-title {
    flex-basis: 25%;
  }
}

.watchers {
  display: flex;
  vertical-align: middle;
  margin-bottom: 1rem;

  .mini-title {
    flex-basis: 25%;
  }

  .watcher {
    margin-right: 1rem;
  }
}

.info {
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;

  .info-name {
    font-weight: bold;
  }

  .edit-button {
    margin-left: auto;
  }
}

div.desc-data {
  word-break: break-word;
  font-size: 1rem;
  width: 600px;
}
.desc {
  display: flex;
  float: left;
  vertical-align: middle;
}
</style>

<i18n>
{
  "en": {
    "campaign_manager": "Netlinking campaign manager",
    "add_backlink": "Add a backlink",
    "description": "Description",
    "client_infos": "Client Information",
    "modify": "Edit",
    "launch_campaign": "Launch campaign",
    "in_progress": "In progress",
    "launched": "Launched",
    "terminated": "Terminated",
    "on_track": "On Track",
    "delay": "Behind schedule",
    "you_have_to_fill_up_client_info": "You need to fill in the client information form",
    "summary": "Summary",
    "budgetSpent": "Budget spent",
    "numberOfBacklinksPublished": "# Published backlinks",
    "numberOfBacklinksOnGoing": "# On going backlinks",
    "numberOfBacklinksToValidate": "# Backlinks to validate",
    "percentageCompletionCampaign": "% Completion",
    "budgetSpentExplanation": "Sum of the prices of backlinks whose status is not 'to validate', 'canceled', 'draft' and 'refused'",
    "numberOfBacklinksOnGoingExplanation": "Number of backlinks with the status 'Validated', 'Redaction', 'Modification'",
    "percentageCompletionCampaignExplanation": "Percentage of published backlinks budget compared to campaign budget",
    "watchers": "Followed by",
    "from": "from",
    "to": "to"
  },
  "fr": {
    "campaign_manager": "Gestion de campagne netlinking",
    "description": "Description",
    "add_backlink": "Ajouter un backlink",
    "client_infos": "Informations Client",
    "modify": "Modifier",
    "launch_campaign": "Lancer la campagne",
    "in_progress": "Brouillon",
    "launched": "En cours",
    "terminated": "Terminée",
    "on_track": "On track",
    "delay": "En retard",
    "you_have_to_fill_up_client_info": "Vous devez remplir le formulaire des informations client",
    "summary": "Résumé",
    "budgetSpent": "Budget dépensé",
    "numberOfBacklinksPublished": "# Backlinks publiés",
    "numberOfBacklinksOnGoing": "# Backlinks en cours",
    "numberOfBacklinksToValidate": "# Backlinks à valider",
    "percentageCompletionCampaign": "% Completion",
    "budgetSpentExplanation": "Somme des prix des backlinks dont le statut n'est pas 'à valider', 'annulé', 'brouillon' et 'refusé'",
    "numberOfBacklinksOnGoingExplanation": "Nombre de backlinks avec le statut 'Validé', 'Rédaction', 'Modification'",
    "percentageCompletionCampaignExplanation": "Pourcentage du budget des backlinks publiés par rapport au budget de la campagne",
    "watchers": "Suivi par",
    "from": "du",
    "to": "au"
  },
  "de": {
    "campaign_manager": "Verwaltung von Netlinking-Kampagnen",
    "description": "Beschreibung",
    "add_backlink": "Füge einen Backlink hinzu",
    "client_infos": "Kundeninformation",
    "modify": "Modifikator",
    "launch_campaign": "Starten Sie die Kampagne",
    "in_progress": "Desorganisiert",
    "launched": "Im Gange",
    "terminated": "Vollendet",
    "on_track": "Auf der Strecke",
    "delay": "in Verzug",
    "you_have_to_fill_up_client_info": "Sie müssen das Kundeninformationsformular ausfüllen",
    "summary": "Fortsetzen",
    "budgetSpent": "Budget ausgegeben",
    "numberOfBacklinksPublished": "# Veröffentlichte Backlinks",
    "numberOfBacklinksOnGoing": "# Backlinks en cours",
    "numberOfBacklinksToValidate": "# Backlinks zu validieren",
    "percentageCompletionCampaign": "% Fertigstellung",
    "budgetSpentExplanation": "Summe der Preise der Backlinks, deren Status nicht 'zu validieren', 'storniert', 'Entwurf' und 'abgelehnt' ist",
    "numberOfBacklinksOnGoingExplanation": "Anzahl Backlinks mit Status 'Validiert', 'Schreiben', 'Änderung'",
    "percentageCompletionCampaignExplanation": "Prozentsatz des Budgets für veröffentlichte Backlinks im Vergleich zum Kampagnenbudget",
    "watchers": "gefolgt von",
    "from": "von",
    "to": "Bei"
  }
}
</i18n>
