<template>
  <div v-if="dataTopKeywords !== null">
    <div class="export">
      <import-and-export-grid-actions
        v-if="accountId"
        :grid-api="gridApi"
        :column-api="columnApi"
        :account-id="accountId"
        type="plain"
        size="mini"
        :isRowsSelected="isRowsSelected"
        @importKeywords="importKeywords($event)"
        @exportExcel="exportExcel()"
      >
      </import-and-export-grid-actions>
    </div>
    <ag-grid-vue
      v-loading="isLoading"
      style="height: 650px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      :isRowSelectable="isRowSelectable"
      rowSelection="multiple"
      @selection-changed="selectionChanged"
      @gridReady="onGridReady"
      @gridSizeChanged="adjustGrid"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import WebsiteRenderer from "@/components/gridRenderers/WebsiteRenderer";
import { listAndImportKeywordsMixin } from "@/mixins/listAndImportKeywordsMixin";
import KeywordImportedRenderer from "@/components/gridRenderers/KeywordImportedRenderer";
import ImportAndExportGridActions from "@/components/ImportAndExportGridActions";

export default {
  mixins: [agGridMixin, listAndImportKeywordsMixin],
  components: {
    AgGridVue,
    WebsiteRenderer,
    KeywordImportedRenderer,
    ImportAndExportGridActions,
  },
  props: {
    dataTopKeywords: Array,
    request: Object,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
      isLoading: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
    },
    adjustGrid() {
      if (this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          filter: "agTextColumnFilter",
          minWidth: 250,
          cellRendererFramework: "KeywordImportedRenderer",
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: this.$i18n.t("page"),
          field: "page",
          filter: "agTextColumnFilter",
          minWidth: 350,
          cellRendererFramework: "WebsiteRenderer",
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
        },
        {
          headerName: this.$i18n.t("position"),
          field: "position",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: this.$i18n.t("estimatedTraffic"),
          field: "estimatedTraffic",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
        },
        {
          headerName: this.$i18n.t("bid"),
          field: "bid",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: function (params) {
            return Math.round(params.value * 100) / 100;
          },
        },
      ];
    },
    mapData() {
      this.isLoading = true;
      this.rowData = this.dataTopKeywords.map((keyword) => {
        return {
          keyword: keyword.keyword,
          page: keyword.url,
          volume: keyword.volume,
          position: keyword.rank,
          estimatedTraffic: keyword.estimatedTraffic,
          bid: keyword.cpc,
          isImportable: true,
        };
      });
      this.getStudyKeywords(this.rowData);
    },
    exportExcel() {
      const keywordsRequest = {
        url: {
          url: this.request.url.url,
          type: this.request.url.type,
        },
        isoCountryCode: this.request.isoCountryCode,
        limit: 1000,
      };
      this.isLoading = true;
      this.$api
        .post(`/netlinking/keywords`, keywordsRequest)
        .then((response) => {
          this.constructExcel(response.data.data);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    constructExcel(data) {
      var XLSX = require("xlsx");
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Keyword Export - SmartKeyword",
        Subject: "Keywords",
        Author: "Smartkeyword",
      };
      wb.SheetNames.push("Keywords");
      var ws_data = [
        [
          "Mot clé",
          "Page",
          "Volume",
          "Position",
          "Estimation du trafic",
          "Enchère",
        ],
      ];
      data.forEach((keyword) => {
        let arr = [
          keyword.keyword,
          keyword.url,
          keyword.volume,
          keyword.rank,
          keyword.estimatedTraffic,
          keyword.cpc,
        ];
        ws_data.push(arr);
      });
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      ws["!cols"] = this.fitToColumn(ws_data);
      wb.Sheets["Keywords"] = ws;
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        "Keyword Export - SmartKeyword.xlsx"
      );
    },
    fitToColumn(arrayOfArray) {
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(...arrayOfArray.map((a2) => a2[i].toString().length)),
      }));
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
  },
  beforeMount() {
    this.setColumnDefs();
  },
  mounted() {
    this.mapData();
  },
  watch: {
    dataTopKeywords() {
      this.mapData();
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.export {
  text-align: right;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "keyword": "Keyword",
    "volume": "Volume",
    "position": "Rank",
    "competition": "Competition",
    "bid": "Bid",
    "estimatedTraffic": "Estimated Traffic",
    "page": "Positionned Page",
    "export": "Export",
    "keywords_import": "Import selected keywords",
    "add_keywords_success": "Keywords were added successfully.",
    "add_keywords_fail": "An error occurred."
  },
  "fr": {
    "keyword": "Mot clé",
    "volume": "Volume",
    "position": "Position",
    "competition": "Compétition",
    "bid": "Enchère",
    "estimatedTraffic": "Trafic estimé",
    "page": "Page positionnée",
    "export": "Exporter",
    "keywords_import": "Importer les mots clés sélectionnés",
    "add_keywords_success": "Les mots clés ont été importés avec succès.",
    "add_keywords_fail": "Une erreur est survenue."
  },
  "de": {
    "keyword": "Stichwort",
    "volume": "Volumen",
    "position": "Position",
    "competition": "Wettbewerb",
    "bid": "Gebot",
    "estimatedTraffic": "Geschätzter Verkehr",
    "page": "Positionierte Seite",
    "export": "Exporteur",
    "keywords_import": "Importieren Sie ausgewählte Schlüsselwörter",
    "add_keywords_success": "Die Keywords wurden erfolgreich importiert.",
    "add_keywords_fail": "Ein Fehler ist aufgetreten."
  }
}
</i18n>
