<template>
  <div>
    <el-card>
      <el-collapse>
        <el-collapse-item>
          <div
            slot="title"
            class="flex-box"
            :id="'campaign' + data.id"
          >
            <span>
              <span class="campaign-name">
                {{ data.name }}
              </span>
              <span class="campaign-detail">
                <span>
                  {{ keywordsTotalCost }}
                </span>
                <span v-if="data.startDate && data.endDate">
                  {{ campaignDate }}
                </span>
                <span>
                  -
                  {{
                    data.isTerminated
                      ? $t("terminated")
                      : $t(meta.contentCampaignStatus.toLowerCase())
                  }}
                </span>
              </span>
            </span>
            <div
              class="delete-update-buttons"
              v-if="links"
            >
              <edit-campaign-button
                :actions="links"
                @edit="showEditCampaignDialog($event)"
              >
              </edit-campaign-button>
              <terminate-campaign-button
                :actions="links"
                @terminate="terminateCampaign($event)"
              >
              </terminate-campaign-button>
              <delete-campaign-button
                :actions="links"
                @delete="deleteCampaign($event)"
              >
              </delete-campaign-button>
            </div>
          </div>
          <el-divider
            v-if="links && links.clientInfo && links.clientInfo.read"
          />
          <div
            class="client"
            v-if="links && links.clientInfo && links.clientInfo.read"
          >
            <h4 class="mini-title">
              <strong>{{ $t("client_infos") }}</strong>
            </h4>
            <div>
              <el-button
                v-if="readClientInfoLinks"
                class="button"
                size="small"
                type="primary"
                plain
                v-on:click.stop="showEditClientInfoDialog()"
              >
                <i class="fas fa-edit"></i> {{ $t("display") }}
              </el-button>
            </div>
          </div>
          <div class="projectResponsibles">
            <h4 class="mini-title">
              <strong>{{ $t("projectResponsibles") }}</strong>
            </h4>
            <div>
              <el-tag
                class="projectResponsible"
                v-for="projectResponsible in data.projectResponsibles"
                v-bind:key="projectResponsible.id"
              >
                {{
                  projectResponsible.firstName +
                  " " +
                  projectResponsible.lastName
                }}
              </el-tag>
            </div>
          </div>
          <el-divider />
          <contents
            :links="links.contents"
            :bulkUpdate="links.bulkUpdate"
            :readCampaign="links.read"
            :meta="meta"
            :campaign="data"
            :is-admin="isAdmin"
            @refresh-meta="$emit('refresh-meta', $event)"
          >
          </contents>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <edit-campaign-dialog
      v-if="links && links.update"
      v-model="editCampaignDialogVisible"
      :data="links.update"
      @update-campaign="$emit('update-campaign', $event)"
      :isCampaign="false"
    >
    </edit-campaign-dialog>
    <edit-client-info-dialog
      v-if="links && links.clientInfo"
      v-model="editClientInfoDialogVisible"
      :data="links.clientInfo"
      @update-client-info="$emit('update-client-info', $event)"
    >
    </edit-client-info-dialog>
  </div>
</template>

<script>
import EditCampaignDialog from "@/components/CampaignManager/EditCampaignDialog";
import EditClientInfoDialog from "@/pages/ContentManager/Campaign/EditClientInfoDialog";
import DeleteCampaignButton from "@/components/CampaignManager/DeleteCampaignButton";
import EditCampaignButton from "@/components/CampaignManager/EditCampaignButton";
import Contents from "@/pages/ContentManager/Contents";
import TerminateCampaignButton from "@/components/CampaignManager/TerminateCampaignButton";

export default {
  components: {
    EditCampaignDialog,
    EditClientInfoDialog,
    DeleteCampaignButton,
    EditCampaignButton,
    Contents,
    TerminateCampaignButton,
  },
  props: {
    data: Object,
    links: Object,
    meta: Object,
    isAdmin: Boolean,
  },
  data() {
    return {
      isLoadingContents: false,
      editCampaignDialogVisible: false,
      editClientInfoDialogVisible: false,
      readClientInfoLinks: null,
      updateClientInfoLink: null,
    };
  },
  methods: {
    fetchClientInfoLinks() {
      if (this.links.clientInfo) {
        if (this.links.clientInfo.read) {
          this.readClientInfoLinks = this.links.clientInfo.read;
        }
        if (this.links.clientInfo.update) {
          this.updateClientInfoLink = this.links.clientInfo.update;
        }
      }
    },
    deleteCampaign(deleteAction) {
      let details = {};
      details.link = deleteAction;
      this.$emit("delete-campaign", details);
    },
    terminateCampaign(terminateAction) {
      let details = {};
      details.link = terminateAction;
      details.campaign = { isTerminated: true };
      this.$emit("terminate-campaign", details);
    },
    showEditCampaignDialog(details) {
      this.editCampaignDialogVisible = true;
    },
    showEditClientInfoDialog() {
      this.editClientInfoDialogVisible = true;
    },
    formatNumber(number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
    currencyFormat(value) {
      return this.$options.filters.formatCurrency(value, this.$i18n.locale);
    },
  },
  mounted() {
    this.fetchClientInfoLinks();
  },
  computed: {
    keywordsTotalCost() {
      return "- ".concat(
        this.currencyFormat(this.data.numberOfWords * this.data.costPerWord),
        "(",
        this.formatNumber(this.data.numberOfWords),
        " ",
        this.$t("words"),
        ")"
      );
    },
    campaignDate() {
      return "- ".concat(
        this.$t("from"),
        " ",
        new Date(this.data.startDate).toLocaleDateString(
          this.$i18n.locale === "en" ? "en-US" : "fr-FR",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }
        ),
        " ",
        this.$t("to"),
        " ",
        new Date(this.data.endDate).toLocaleDateString(
          this.$i18n.locale === "en" ? "en-US" : "fr-FR",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }
        )
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.flex-box {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;

  .campaign-name {
    font-size: 1.35rem;
    font-weight: bold;
  }
  .campaign-detail {
    font-size: 1.15rem;
  }

  .delete-update-buttons {
    margin-left: auto;
    display: flex;
  }
}

.client {
  display: flex;
  vertical-align: middle;
  margin-bottom: 1rem;

  .mini-title {
    flex-basis: 25%;
  }
}

.projectResponsibles {
  display: flex;
  vertical-align: middle;
  margin-bottom: 1rem;

  .mini-title {
    flex-basis: 25%;
  }

  .projectResponsible {
    margin-right: 1rem;
  }
}
</style>

<i18n>
{
  "en": {
    "client_infos": "Client Information",
    "display": "Show",
    "words": "words",
    "from": "from",
    "to": "to",
    "terminated": "Terminated",
    "on_track": "On Track",
    "delay": "Behind schedule",
    "projectResponsibles": "Followed by"
  },
  "fr": {
    "client_infos": "Informations Client",
    "display": "Voir",
    "words": "mots",
    "from": "du",
    "to": "au",
    "terminated": "Terminé",
    "on_track": "On track",
    "delay": "En retard",
    "projectResponsibles": "Responsables du projet"
  },
  "de": {
    "client_infos": "Kundeninformation",
    "display": "Sehen",
    "words": "Wörter",
    "from": "von",
    "to": "Bei",
    "terminated": "Beendet",
    "on_track": "Auf der Strecke",
    "delay": "in Verzug",
    "projectResponsibles": "Projektmanager"
  }
}
</i18n>
