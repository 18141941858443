<template>
  <div>
    <h4><i :class="className"></i> {{ $t(name) }}</h4>
    <el-date-picker
      class="value"
      :value="value"
      size="medium"
      type="date"
      :format="datePickerFormat"
      clearable
      @input="(dueDate) => emitUpdateWitNullHandler(dueDate)"
      :picker-options="periodPickerOptions"
    />
  </div>
</template>

<script>
import { dateToDateTimeUTC } from "@/javascripts/dateHelpers";

export default {
  props: {
    value: String,
    name: String,
    className: String,
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
    periodPickerOptions() {
      if (this.name === "remind_date")
        return {
          firstDayOfWeek: 1,
          disabledDate(time) {
            const dateTime = dateToDateTimeUTC(time);
            return dateTime.diffNow("days") < -1;
          },
        };
      else
        return {
          firstDayOfWeek: 1,
        };
    },
  },
  methods: {
    emitUpdateWitNullHandler(dueDate) {
      if (dueDate) {
        this.$emit("input", dueDate.toDateString());
      } else {
        this.$emit("input", null);
      }
    },
  },
  // data(){
  //     return{
  //         pickerOptions: {
  //             firstDayOfWeek: 1
  //         }
  //     }
  // }
};
</script>

<style scoped></style>

<i18n>
{
"en": {
  "completion_date": "Completion Date",
  "due_date": "Due Date",
  "remind_date": "Reminder date"
},
"fr": {
  "completion_date": "Date de complétion",
  "due_date": "Date d'échéance",
  "remind_date": "Date de rappel"
},
"de": {
  "completion_date": "Fertigstellungstermin",
  "due_date": "Geburtstermin",
  "remind_date": "Erinnerungsdatum"
}
}
</i18n>
