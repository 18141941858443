<template>
  <div>
    <div class="actions">
      <el-popover
        v-model="addTagsPopoverVisible"
        class="popover-button"
        popper-class="action-popover"
        placement="bottom-start"
        width="auto"
      >
        <div>
          <el-select
            v-model="form.tags"
            multiple
            filterable
            allow-create
            default-first-option
            :placeholder="$t('select_tags')"
            size="mini"
          >
            <el-option
              v-for="item in tagsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            size="mini"
            @click="keywordsImport"
            >{{ $t("import") }}</el-button
          >
        </div>
        <el-button
          plain
          type="primary"
          slot="reference"
          >{{ $t("keywords_import") }}</el-button
        >
      </el-popover>
      <el-button
        plain
        type="primary"
        @click="excelExport"
        >{{ $t("excel_export") }}</el-button
      >
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :gridOptions="gridOptions"
      :rowData="rowData"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :suppressContextMenu="true"
      rowSelection="multiple"
      :suppressAggFuncInHeader="true"
      :rowMultiSelectWithClick="true"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import { RepositoryFactory } from "@/services/repositoryFactory";
const KeywordsRepository = RepositoryFactory.get("keywords");
import ScoreRenderer from "@/pages/KeywordsSearch/Shared/ScoreRenderer";
import GoogleSearchRenderer from "@/components/gridRenderers/GoogleSearchRenderer";

export default {
  props: {
    data: Array,
    account: Object,
    tags: Array,
  },
  components: {
    AgGridVue,
    ScoreRenderer,
    GoogleSearchRenderer,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: { defaultColDef: { sortable: true, resizable: true } },
      gridApi: null,
      columnApi: null,
      form: {
        tags: [],
      },
      tagsOptions: [],
      addTagsPopoverVisible: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setData();
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keywords"),
          field: "keyword",
          width: 200,
          minWidth: 150,
          filter: "agTextColumnFilter",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
        },
        {
          headerName: this.$i18n.t("intersections"),
          field: "matchingWebsites",
          width: 140,
          type: "numericColumn",
          aggFunc: "max",
          sort: { direction: "desc", priority: 0 },
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          width: 115,
          type: "numericColumn",
          aggFunc: "max",
          sort: { direction: "desc", priority: 1 },
          valueGetter: (params) => roundNumber(params.data["volume"]),
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRenderer: spaceThousandRenderer,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("position_mine"),
          field: "rankMine",
          width: 110,
          type: "numericColumn",
          valueGetter: function (params) {
            if (params.data.rankMine) {
              return params.data.rankMine;
            } else {
              return 101;
            }
          },
          valueFormatter: positionFormatter,
          aggFunc: "min",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("bid"),
          field: "bid",
          valueGetter: (params) => roundNumber(params.data["bid"]),
          width: 110,
          type: "numericColumn",
          aggFunc: "avg",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRenderer: spaceThousandRenderer,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("opportunity_score"),
          field: "competition",
          valueGetter: (params) => roundNumber(params.data["competition"]),
          width: 185,
          type: "numericColumn",
          aggFunc: "avg",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "ScoreRenderer",
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("actions"),
          field: "actions",
          width: 110,
          aggFunc: "first",
          cellStyle: { textAlign: "center" },
          cellRendererFramework: "GoogleSearchRenderer",
          suppressSizeToFit: true,
          sorting: false,
          suppressMenu: true,
        },
      ];
    },
    setData() {
      let tempUrl = this.account.url;
      if (!tempUrl.toLowerCase().match("^\\w+://.*")) {
        tempUrl = "http://" + tempUrl;
      }
      let url_mine = new URL(tempUrl).hostname;

      this.rowData = [];
      for (let i = 0; i < this.data.length; i++) {
        let result = {};
        let keyword = this.data[i];
        let pos = keyword.positions;
        for (let k = 0; k < pos.length; k++) {
          (result.keyword = keyword.text),
            (result.actions = keyword.text),
            (result.volume = keyword.volume),
            (result.bid = keyword.bid),
            (result.competition = Math.round(keyword.competition * 100)),
            (result.estimatedTraffic = keyword.estimatedTraffic);
          result.matchingWebsites = keyword.matchingWebsites;
          if (pos[k].url.includes(url_mine)) {
            result.rankMine = pos[k].rank;
            break;
          }
        }
        this.rowData.push(result);
      }
    },
    excelExport() {
      const columnKeys = this.columnApi
        .getAllDisplayedColumnGroups()
        .map((columnGroup) => {
          if (columnGroup.colId !== "actions") {
            return columnGroup.colId;
          }
        });
      const params = {
        skipHeader: false,
        columnKeys: columnKeys,
        onlySelected: false,
        fileName: "Keywords Search Export - SmartKeyword",
        sheetName: "Keywords Search",
      };
      params.shouldRowBeSkipped = function (params) {
        return params.node.group == true;
      };

      this.gridApi.exportDataAsExcel(params);
    },

    keywordsImport() {
      this.addTagsPopoverVisible = false;
      const selectedNodes = this.gridApi.getSelectedNodes();
      const texts = selectedNodes.map((node) => node.data.keyword);
      if (texts.length > 0) {
        KeywordsRepository.createKeywords(
          this.account.id,
          texts,
          this.form.tags
        )
          .then((data) => {
            this.$message.success(this.$i18n.t("add_keywords_success"));
          })
          .catch((error) => {
            this.$message.error(this.$i18n.t("add_keywords_fail"));
          });
      } else {
        this.$message.warning(this.$i18n.t("no_keywords_selected"));
      }
    },
    setTagOptions() {
      this.tagsOptions = this.tags.map((tag) => {
        return { value: tag.name, label: tag.name };
      });
    },
  },
  watch: {
    data() {
      this.setData();
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.gridOptions = {};
    this.setTagOptions();
  },
};

let positionFormatter = (params) => {
  if (params.value < 101) {
    return params.value;
  } else {
    return "> 100";
  }
};

var spaceThousandRenderer = function (params) {
  var roundedNumber = roundNumber(params.value);
  return roundedNumber.toLocaleString("fr");
};

let roundNumber = (number) => Math.round(number * 100) / 100;
</script>

<style scoped lang="scss">
.actions {
  text-align: right;
}

::v-deep .google-link {
  margin-left: 10px;
}

::v-deep .ag-header-cell-label {
  justify-content: center;
}
</style>

<i18n>
{
  "en": {
    "excel_export": "Export Excel",
    "keywords_import": "Import selected keywords",
    "selection": "Selection",
    "keywords": "Keywords",
    "position_mine": "My position",
    "volume": "Volume",
    "domain_name": "Domain Name",
    "bid": "Bid",
    "opportunity_score": "Opportunity score",
    "actions": "Actions",
    "intersections": "Intersections",
    "add_keywords_success": "Keywords were added successfully.",
    "add_keywords_fail": "An error occurred.",
    "no_keywords_selected": "No keywords were selected ! Select keywords first in order to add them to your keywords.",
    "select_tags": "Select tags",
    "import": "Import"
  },
  "fr": {
    "excel_export": "Export excel",
    "keywords_import": "Importer les mots clés sélectionnés",
    "selection": "Selection",
    "keywords": "Mots Clés",
    "position_mine": "Ma pos.",
    "volume": "Volume",
    "domain_name": "Nom de domaine",
    "bid": "Enchère",
    "opportunity_score": "Score d'opportunité",
    "actions": "Actions",
    "intersections": "Intersections",
    "add_keywords_success": "Les mots clés ont été importés avec succès.",
    "add_keywords_fail": "Une erreur est survenue.",
    "no_keywords_selected": "Aucun mot clé sélectionné ! Sélectionnez d'abord des mots clés pour pouvoir les ajouter à vos mots clés.",
    "select_tags": "Seléctionnez les groupes de mots clés",
    "import": "Importer"
  },
  "de": {
    "excel_export": "Excel exportieren",
    "keywords_import": "Importieren Sie ausgewählte Schlüsselwörter",
    "selection": "Auswahl",
    "keywords": "Schlüsselwörter",
    "position_mine": "hat eine Pos.",
    "volume": "Volumen",
    "domain_name": "Domänenname",
    "bid": "Gebot",
    "opportunity_score": "Opportunity-Score",
    "actions": "Aktionen",
    "intersections": "Kreuzungen",
    "add_keywords_success": "Die Keywords wurden erfolgreich importiert.",
    "add_keywords_fail": "Ein Fehler ist aufgetreten.",
    "no_keywords_selected": "Kein Schlüsselwort ausgewählt! Wählen Sie zuerst Schlüsselwörter aus, damit Sie sie zu Ihren Schlüsselwörtern hinzufügen können.",
    "select_tags": "Wählen Sie Keyword-Gruppen aus",
    "import": "Importeur"
  }
}
</i18n>
