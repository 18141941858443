<template></template>

<script>
export default {
  mounted() {
    let redirectionParams = new URLSearchParams(window.location.search);
    let tabpaneId = null;
    if (redirectionParams.get("tabpaneId"))
      tabpaneId = redirectionParams.get("tabpaneId");
    else tabpaneId = "homePage";

    let firstTabEl = document.querySelector("#" + tabpaneId + "Link");
    let tabTrigger = new bootstrap.Tab(firstTabEl);
    tabTrigger.show();
  },
};
</script>

<style></style>
