<template>
  <div class="container">
    <el-card>
      <highcharts
        ref="highchart"
        :options="chartOptions"
      ></highcharts>
    </el-card>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Array,
    dates: Object,
    backlinks: Array,
    contents: Array,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          zoomType: "x",
          height: 250,
        },
        title: {
          text: this.$i18n.t("sessions_evolution"),
        },
        yAxis: [
          {
            title: {
              text: this.$i18n.t("sessions"),
            },
            min: 0,
          },
          {
            visible: false,
          },
        ],
        xAxis: [
          {
            type: "datetime",
          },
        ],
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "Number of sessions" + ": {point.y}",
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        credits: {
          enabled: false,
        },
        series: this.mainSeriesChart(),
      };
    },
  },
  methods: {
    mainSeriesChart() {
      if (this.data) {
        let series = [];
        this.addBacklinksSeries(series);
        this.addContentsSeries(series);

        const dataPointsFormated = this.data.map((dataPoint) => {
          return {
            x: new Date(dataPoint.Date),
            y: Math.round(dataPoint.SumSessions),
            name: new Date(dataPoint.Date),
          };
        });
        series.push({
          name: "Nb. sessions",
          data: dataPointsFormated,
        });
        return series;
      } else {
        return [];
      }
    },
    addBacklinksSeries(series) {
      const backlinksPoints = this.backlinks
        .map((backlink) => {
          return {
            x: new Date(backlink.publishedDate).getTime(),
            y: 0,
            backlink: backlink,
          };
        })
        .filter(
          (point) =>
            point.x >= this.dates.start_date.toJSDate() &&
            point.x <= this.dates.end_date.toJSDate()
        );

      series.push({
        type: "scatter",
        name: this.$i18n.t("backlinks"),
        marker: {
          fillColor: "blue",
          symbol: "triangle",
        },
        data: backlinksPoints,
        yAxis: 1,
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat:
            "{point.x:%d/%m/%Y}<br><br>" +
            "<b>" +
            this.$t("publicationOfBacklink") +
            " - {point.backlink.sourceDomain}</b><br>" +
            this.$t("keyword") +
            ": <b>{point.backlink.keyword.text}</b><br>" +
            this.$t("urlTarget") +
            ": <b>{point.backlink.targetUrl}</b><br>",
        },
      });
    },
    addContentsSeries(series) {
      const contentsPoints = this.contents
        .map((content) => {
          return {
            x: new Date(content.data.publishDate).getTime(),
            y: 0,
            content: content.data,
          };
        })
        .filter(
          (point) =>
            point.x >= this.dates.start_date.toJSDate() &&
            point.x <= this.dates.end_date.toJSDate()
        );
      series.push({
        type: "scatter",
        name: this.$i18n.t("contents"),
        marker: {
          fillColor: "green",
          symbol: "triangle",
        },
        data: contentsPoints,
        yAxis: 1,
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat:
            "{point.x:%d/%m/%Y}<br><br>" +
            "<b>" +
            this.$t("publicationOfContent") +
            "</b><br>" +
            this.$t("keyword") +
            ": <b>{point.content.keyword.text}</b><br>" +
            this.$t("url") +
            ": <b>{point.content.url}</b>",
        },
      });
    },
  },
  watch: {
    data() {
      this.mainSeriesChart();
    },
    dates() {
      this.mainSeriesChart();
    },
  },
};
</script>

<style scoped lang="scss">
.highcharts {
  height: 100px;
}
</style>

<i18n>
{
  "en": {
    "netlinking": "Netlinking",
    "content": "Content",
    "comparative_organic_sessions": "Comparative organic sessions",
    "incomplete_week": " incomplete week",
    "none": "None",
    "organic_sessions": "Organic sessions",
    "sessions_evolution": "Sessions evolution",
    "sessions": "Sessions",
    "contents": "Contents",
    "backlinks": "Backlinks",
    "keyword": "Keyword",
    "urlTarget": "Targetted Url",
    "urlSource": "Source Url",
    "url": "Url",
    "name": "Name",
    "publicationOfBacklink": "Backlink published",
    "publicationOfContent": "Content published"
  },
  "fr": {
    "content": "Contenu",
    "netlinking": "Netlinking",
    "comparative_organic_sessions": "Sessions organiques comparatives",
    "incomplete_week": "semaine incomplète",
    "none": "Aucun",
    "organic_sessions": "Sessions organiques",
    "sessions_evolution": "Evolution des sessions",
    "sessions": "Sessions",
    "contents": "Contenus",
    "backlinks": "Backlinks",
    "keyword": "Mot clé",
    "urlTarget": "Page visée",
    "urlSource": "Url source",
    "url": "Url",
    "name": "Nom",
    "publicationOfBacklink": "Backlink publié",
    "publicationOfContent": "Contenu publié"
  },
  "de": {
    "content": "Inhalt",
    "netlinking": "Netzverlinkung",
    "comparative_organic_sessions": "Vergleichende organische Sitzungen",
    "incomplete_week": "unvollständige Woche",
    "none": "Keiner",
    "organic_sessions": "Organische Sitzungen",
    "sessions_evolution": "Evolution des sessions",
    "sessions": "Sitzungen",
    "contents": "Inhalt",
    "backlinks": "Backlinks",
    "keyword": "Stichwort",
    "urlTarget": "Zielseite",
    "urlSource": "URL-Quelle",
    "url": "URL",
    "name": "Name",
    "publicationOfBacklink": "Backlink veröffentlicht",
    "publicationOfContent": "Veröffentlichte Inhalte"
  }
}
</i18n>
