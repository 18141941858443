import { getMarkRange } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

/*
 * Plugin (For ProseMirror) to control the editor over the threads
 */
export default function (extensionInstance) {
  /**Change in proseMirror the color of commentMark by removing and adding a new one */
  const updateThreadColor = (range, commentId, color, view) => {
    const { schema } = view.state;
    view.dispatch(
      view.state.tr
        .setMeta("thread", true)
        .removeMark(range.from, range.to, schema.marks.commentMark)
    );
    view.dispatch(
      view.state.tr.setMeta("thread", true).addMark(
        range.from,
        range.to,
        schema.marks.commentMark.create({
          color: color,
          commentId: commentId,
        })
      )
    );
  };

  /* Highlight the selected Thread
   * get the  id of the selected thread
   * Iterate through the nodes, if it passes by the wanted mark, highlights all its occurences
   */
  const highlightSelectedThread = (view, selectionPos) => {
    const { doc, schema } = view.state;
    let marks = doc.resolve(selectionPos).marks();
    let commentID;
    if (marks) {
      let commentMarkIndex = marks.findIndex(
        (mark) => mark.type.name == "commentMark"
      );
      if (marks[commentMarkIndex])
        commentID = marks[commentMarkIndex].attrs.commentId;
    }
    doc.descendants((node, pos) => {
      node.marks.forEach((mark) => {
        if (mark.type.name == "highlightSelection") {
          let range = getMarkRange(
            doc.resolve(pos),
            schema.marks.highlightSelection
          );
          view.dispatch(
            view.state.tr
              .setMeta("thread", true)
              .removeMark(range.from, range.to, schema.marks.highlightSelection)
          );
        }
        if (mark.type.name == "commentMark") {
          let range = getMarkRange(doc.resolve(pos), schema.marks.commentMark);
          if (commentID == mark.attrs.commentId)
            updateThreadColor(range, mark.attrs.commentId, "chartreuse", view);
          else updateThreadColor(range, mark.attrs.commentId, "yellow", view);
        }
      });
    });
  };

  return new Plugin({
    key: new PluginKey("commentMark"),
    props: {
      /** When the user clicks on the editor if the threads are enabled and the user clicks on a
       * thread highlight its.
       */
      handleClick(view, selectionPos, event) {
        if (extensionInstance.storage.enable)
          highlightSelectedThread(view, selectionPos);
      },
    },
  });
}
