<template>
  <div>
    <app-introduction
      :title="$t('tasks')"
      video-link="https://www.youtube.com/watch?v=Fvb0b-vxb3Y"
      :documentations="[
        { description: $t('documentationDesc'), link: $t('documentationLink') },
        {
          description: $t('documentationSEOActionsMonitoring'),
          link: $t('documentationSEOActionsMonitoringLink'),
        },
      ]"
      :user="currentUser"
    >
      <template v-slot:right>
        <el-switch
          v-if="company"
          v-model="globalScope"
          :active-text="$t('company', { name: company.name })"
          :inactive-text="$t('study', { url: study.url })"
        >
          Test Laurent
        </el-switch>
      </template>
    </app-introduction>

    <div class="container">
      <tasks-board
        v-loading="loadingTasks"
        :tasks="tasks"
        :filters="activeFilters"
        :current-user="currentUser"
        :user-options="userOptions"
        :study-id="study.id"
        ref="taskBoard"
        @on-updated-task="updatedTask"
        @on-deleted-task="deletedTask"
        @on-show-creation-task="showCreationTask"
        @on-created-task="createdTask"
      >
        <template v-slot:filters>
          <div>
            <el-form inline>
              <due-date-filter v-model="tasksFilters.dueDate"></due-date-filter>
              <el-popover
                placement="bottom"
                width="400"
                trigger="click"
                popper-class="filter-tasks-popover"
              >
                <div
                  v-for="kind in kindOptions"
                  :key="kind.value"
                  class="filter-item"
                  :class="{ active: filterIsActive('kind', kind.value) }"
                  @click="
                    filterIsActive('kind', kind.value)
                      ? removeFilter('kind', kind.value)
                      : addFilter('kind', kind.value)
                  "
                >
                  <i :class="taskKindIcon(kind.value)"></i> {{ kind.label }}
                </div>
                <el-button
                  plain
                  slot="reference"
                  >{{ $t("filterByKind") }}</el-button
                >
              </el-popover>
              <el-popover
                placement="bottom"
                width="400"
                trigger="click"
                popper-class="filter-tasks-popover"
              >
                <div
                  v-for="user in filterUsersOptions"
                  :key="user.id"
                  class="filter-item"
                  :class="{
                    active: filterIsActive('assignedToUserId', user.id),
                  }"
                  @click="
                    filterIsActive('assignedToUserId', user.id)
                      ? removeFilter('assignedToUserId', user.id)
                      : addFilter('assignedToUserId', user.id)
                  "
                >
                  <span class="user">{{
                    user.firstName[0] + "." + user.lastName[0]
                  }}</span>
                  {{ user.firstName + " " + user.lastName }}
                </div>
                <el-button
                  plain
                  slot="reference"
                  >{{ $t("filterByUser") }}</el-button
                >
              </el-popover>
              <el-popover
                placement="bottom"
                width="400"
                trigger="click"
                popper-class="filter-tasks-popover"
              >
                <div
                  v-for="user in filterUsersOptions"
                  :key="user.id"
                  class="filter-item"
                  :class="{ active: filterIsActive('watcherIds', user.id) }"
                  @click="
                    filterIsActive('watcherIds', user.id)
                      ? removeFilter('watcherIds', user.id)
                      : addFilter('watcherIds', user.id)
                  "
                >
                  <span class="user">{{
                    user.firstName[0] + "." + user.lastName[0]
                  }}</span>
                  {{ user.firstName + " " + user.lastName }}
                </div>
                <el-button
                  plain
                  slot="reference"
                  >{{ $t("followedBy") }}</el-button
                >
              </el-popover>
            </el-form>
          </div>
        </template>

        <template v-slot:actions>
          <div class="actions">
            <el-popover
              placement="bottom"
              width="450"
              trigger="click"
              popper-class="archived-tasks-popover"
            >
              <div
                v-if="archivedTasks.length > 0"
                class="archived-cards"
              >
                <div
                  v-for="task in archivedTasks"
                  :key="task.id"
                  class="archived-card"
                >
                  <task
                    :task="task"
                    :userOptions="userOptions"
                    @show-task="showTask(task)"
                    @delete-task="(task) => deleteTask(task)"
                    @archive-task="(task) => archiveTask(task)"
                    @duplicate-task="(task) => duplicateTask(task)"
                    @unarchive-task="(task) => putBackTask(task)"
                  />
                </div>
              </div>
              <div
                v-else
                class="no-archived-tasks-container"
              >
                <span>{{ $t("noArchivedTasks") }}</span>
              </div>
              <el-button
                plain
                slot="reference"
                >{{ $t("archivedTasks") }}</el-button
              >
            </el-popover>
            <a
              class="el-button"
              :href="`/a/${accountId}/tasks_export.xls`"
              ><i class="far fa-file-excel"></i> {{ $t("export_tasks") }}</a
            >
          </div>
        </template>
      </tasks-board>

      <el-dialog
        :visible.sync="creatingTask"
        :close-on-click-modal="false"
        width="62%"
      >
        <task-creation-form
          :key="selectedStatus"
          :study-id="study.id"
          :currentUser="currentUser"
          :assigned-to-user-id="currentUser.id"
          :user-options="userOptions"
          :status="selectedStatus"
          @on-created-task="createdTask"
        />
      </el-dialog>
      <keyword-dialog
        v-if="keywordDialogVisible"
        v-model="keywordDialogVisible"
        :keyword-id="selectedKeywordId"
        :studyId="study.id"
      ></keyword-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import axios from "axios";
import { RepositoryFactory } from "@/services/repositoryFactory";
import AppIntroduction from "@/components/AppIntroduction";
import TaskCreationForm from "@/components/task/TaskCreationForm";
import TasksBoard from "@/components/task/TasksBoard";
import Task from "@/components/task/TaskCard";
import KeywordDialog from "@/pages/KeywordDialog";
import { format } from "date-fns";
import { isEqual, unionWith } from "lodash";

const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  components: {
    AppIntroduction,
    KeywordDialog,
    TaskCreationForm,
    TasksBoard,
    Task,
  },
  props: {
    accountId: Number,
    companyId: Number,
    currentUser: Object,
  },
  data() {
    return {
      company: {},
      loadingTasks: false,
      tasks: [],
      creatingTask: false,
      activeFilters: {},
      // actually kindOptions should be returned by the tasks service
      kindOptions: [
        { value: "CONTENT", label: this.$i18n.t("content") },
        { value: "TECHNICAL", label: this.$i18n.t("technical") },
        { value: "NETLINKING", label: this.$i18n.t("netlinking") },
        { value: "DESIGN", label: this.$i18n.t("design") },
        { value: "GLOBAL", label: this.$i18n.t("global") },
      ],
      userOptions: [],
      keywordDialogVisible: false,
      selectedKeywordId: null,
      creatingTask: false,
      selectedStatus: "",
      showTitleField: false,
      globalScope: false,
      tasksFilters: {
        dueDate: null,
      },
    };
  },
  computed: {
    ...mapState(["study"]),
    filterUsersOptions() {
      const existingUsers = this.tasks
        .filter((task) => task.userId && task.user)
        .map((task) => ({
          id: task.userId,
          firstName: task.user.firstName,
          lastName: task.user.lastName,
        }));
      return unionWith(existingUsers, this.userOptions, isEqual);
    },
    sortedTasks() {
      return this.tasks.sort((a, b) => a.position - b.position);
    },
    filteredTasks() {
      return this.sortedTasks.filter((task) => {
        return Object.keys(this.activeFilters).every((type) => {
          return (
            this.activeFilters[type].length === 0 ||
            (task.hasOwnProperty(type) &&
              this.activeFilters[type].includes(task[type]))
          );
        });
      });
    },
    archivedTasks() {
      return this.filteredTasks.filter((task) => task.archived);
    },
  },
  methods: {
    fetchCompany() {
      CompaniesRepository.getCompany(this.companyId)
        .then((data) => {
          this.company = {
            name: data.data.attributes.name,
            studies: data.data.attributes.studies,
          };
        })
        .catch((error) => console.log(error));
    },
    showCreationTask(status) {
      this.selectedStatus = status;
      this.creatingTask = true;
    },
    createdTask(task) {
      this.selectedStatus = "";
      this.creatingTask = false;
      this.tasks.push(task);
    },
    putBackTask(task) {
      this.updateTask(task, { archived: false });
    },
    archiveTask(task) {
      this.updateTask(task, { archived: true });
    },
    duplicateTask(task) {
      let duplicate = { ...task };
      duplicate.blocks = [];
      delete duplicate.id;

      this.$api
        .post(`/studies/${task.studyId}/tasks`, duplicate)
        .then((response) => {
          const data = response.data.data;
          this.createdTask(data);
          this.$message({
            message: this.$i18n.t("taskCreatedSuccess"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("taskCreatedFailure"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    updateTask(task, partialTask) {
      this.$api
        .patch(`/studies/${task.studyId}/tasks/${task.id}`, partialTask)
        .then((response) => {
          this.updatedTask(response.data.data);
        })
        .catch(() => {
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
        });
    },
    updatedTask(task) {
      this.tasks.splice(
        this.tasks.findIndex((t) => t.id === task.id),
        1,
        task
      );
    },
    deleteTask(task) {
      this.$api
        .delete(`/studies/${task.studyId}/tasks/${task.id}`)
        .then(() => {
          this.deletedTask(task.id);
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
        });
    },
    deletedTask(taskId) {
      const taskIndex = this.tasks.findIndex((task) => task.id === taskId);
      this.tasks.splice(taskIndex, 1);
    },
    addFilter(type, value) {
      const item = this.activeFilters[type] || [];
      item.push(value);
      this.$set(this.activeFilters, type, item);
    },
    removeFilter(type, value) {
      this.activeFilters[type].splice(
        this.activeFilters[type].indexOf(value),
        1
      );
    },
    filterIsActive(type, value) {
      if (this.activeFilters[type]) {
        return this.activeFilters[type].includes(value);
      }
    },
    fetchTasks() {
      this.loadingTasks = true;
      if (this.globalScope) {
        const requestSearch = {
          dueDate: this.tasksFilters.dueDate
            ? this.tasksFilters.dueDate
            : undefined,
        };
        this.$api
          .post(`/companies/${this.companyId}/tasks/search`, requestSearch)
          .then((response) => {
            this.tasks = response.data.data;
            this.loadingTasks = false;
          })
          .catch((error) => {
            console.log(error);
            this.loadingTasks = false;
          });
      } else {
        const requestSearch = {
          dueDate: this.tasksFilters.dueDate
            ? {
                start: this.tasksFilters.dueDate[0],
                end: this.tasksFilters.dueDate[1],
              }
            : undefined,
        };
        this.$api
          .post(`/studies/${this.accountId}/tasks/search`, requestSearch)
          .then((response) => {
            this.tasks = response.data.data;
            this.loadingTasks = false;
          })
          .catch((error) => {
            console.log(error);
            this.loadingTasks = false;
          });
      }
    },
    fetchUsers() {
      CompaniesRepository.getCompanyUsersForTasks(this.companyId).then(
        (data) => {
          this.userOptions = data.data.map((user) => ({
            id: parseInt(user.id),
            firstName: user.attributes.firstName,
            lastName: user.attributes.lastName,
          }));
        }
      );
    },
    taskKindIcon(kind) {
      if (kind === "CONTENT") {
        return "fas fa-align-left";
      } else if (kind === "TECHNICAL") {
        return "fas fa-cogs";
      } else if (kind === "NETLINKING") {
        return "fas fa-project-diagram";
      } else if (kind === "DESIGN") {
        return "fas fa-drafting-compass";
      } else if (kind === "GLOBAL") {
        return "fas fa-globe";
      }
    },
    showTask(task) {
      this.$refs.taskBoard.showTask(task);
    },
  },
  watch: {
    globalScope() {
      this.fetchTasks();
    },
    tasksFilters: {
      handler: "fetchTasks",
      deep: true,
    },
  },
  created() {
    this.fetchCompany();
    this.fetchTasks();
    this.fetchUsers();
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.filter-tasks-popover {
  width: 300px;
  height: auto;
  max-height: 600px;
  overflow: scroll;

  .filter-item {
    padding: 10px;
    margin-bottom: 0.5rem;
    border-radius: $--border-radius-base;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: $--color-primary;
    }

    &.active {
      color: #fff;
      background-color: $--color-primary;
    }

    .user {
      display: inline-block;
      flex: 0 0 auto;
      margin-left: auto;
      color: $--color-text-primary;
      background-color: $--color-info-light;
      border-radius: 25em;
      height: 28px;
      width: 28px;
      line-height: 28px;
      text-align: center;
      font-weight: 500;
    }
  }
}

.archived-tasks-popover {
  height: 600px;
  overflow: scroll;

  .no-archived-tasks-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: $--color-info;
    }
  }

  .archived-card {
    margin-bottom: 10px;

    .el-card {
      .el-card__body {
        padding: 0.5rem;
      }
    }

    .divider-archived {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
<style scoped lang="scss">
@import "@/styles/variables";

.actions {
  margin-bottom: 1rem;
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "byKind": "By kind",
    "byUser": "By user",
    "company": "Company (%{name})",
    "filter_tasks": "Filter tasks",
    "export_tasks": "Export tasks",
    "content": "Content",
    "technical": "Technical",
    "netlinking": "Netlinking",
    "design": "Design",
    "putBack": "Reset",
    "noArchivedTasks": "No archived tasks to display",
    "delete": "Delete",
    "archivedTasks": "Archived tasks",
    "global": "Global",
    "inTheList": "In the list",
    "study": "Study (%{url})",
    "tasks": "Tasks",
    "filterByUser": "Filter by user",
    "filterByKind": "Filter by type",
    "followedBy": "Monitored by",
    "documentationLink": "https://docs.smartkeyword.io/fr/articles/2385829-organiser-vos-actions-priorisees",
    "documentationDesc": "How to monitor your actions ?",
    "documentationSEOActionsMonitoring": "How to use SEO's tasks actions monitoring ?",
    "documentationSEOActionsMonitoringLink": "http://docs.smartkeyword.io/fr/articles/3641193-comment-organiser-et-suivre-l-impact-de-mes-taches-seo",
    "taskCreatedFailure": "Task creation failed",
    "taskCreatedSuccess": "Task created successfully"
  },
  "fr": {
    "byKind": "Par genre",
    "byUser": "Par utilisateur",
    "company": "Société (%{name})",
    "kind": "Genre",
    "filter_tasks": "Filtrer les tâches",
    "export_tasks": "Exporter les tâches",
    "content": "Contenu",
    "technical": "Technique",
    "netlinking": "Netlinking",
    "design": "Design",
    "putBack": "Remettre",
    "noArchivedTasks": "Pas de tâches archivées à afficher",
    "delete": "Supprimer",
    "archivedTasks": "Tâches archivées",
    "global": "Global",
    "inTheList": "Dans la liste",
    "study": "Étude (%{url})",
    "tasks": "Tâches",
    "filterByUser": "Filtrer par utilisateur",
    "filterByKind": "Filtrer par type",
    "followedBy": "Suivi par",
    "documentationDesc": "Comment organiser vos actions priorisées ?",
    "documentationLink": "https://docs.smartkeyword.io/fr/articles/2385829-organiser-vos-actions-priorisees",
    "documentationSEOActionsMonitoring": "Comment utiliser les tâches pour le suivi de vos actions SEO",
    "documentationSEOActionsMonitoringLink": "http://docs.smartkeyword.io/fr/articles/3641193-comment-organiser-et-suivre-l-impact-de-mes-taches-seo",
    "taskCreatedSuccess": "Tâche crée avec succès",
    "taskCreatedFailure": "La création de la tâche a échoué"
  },
  "de": {
    "byKind": "Nach Geschlecht",
    "byUser": "Pro Benutzer",
    "company": "Name der Firma})",
    "kind": "Genre",
    "filter_tasks": "Aufgaben filtern",
    "export_tasks": "Aufgaben exportieren",
    "content": "Inhalt",
    "technical": "Technik",
    "netlinking": "Netzverlinkung",
    "design": "Design",
    "putBack": "Zurücklegen",
    "noArchivedTasks": "Keine archivierten Aufgaben zum Anzeigen",
    "delete": "LÖSCHEN",
    "archivedTasks": "Archivierte Aufgaben",
    "global": "Global",
    "inTheList": "In der Liste",
    "study": "Studie (%{url})",
    "tasks": "Aufgaben",
    "filterByUser": "Nach Benutzer filtern",
    "filterByKind": "Filtern Sie nach Typ",
    "followedBy": "gefolgt von",
    "documentationDesc": "Wie organisieren Sie Ihre priorisierten Aktionen?",
    "documentationLink": "https://docs.smartkeyword.io/fr/articles/2385829-organiser-vos-actions-priorisees",
    "documentationSEOActionsMonitoring": "So verwenden Sie Aufgaben, um Ihre SEO-Aktionen zu verfolgen",
    "documentationSEOActionsMonitoringLink": "http://docs.smartkeyword.io/fr/articles/3641193-comment-organiser-et-suivre-l-impact-de-mes-taches-seo",
    "taskCreatedSuccess": "Aufgabe erfolgreich erstellt",
    "taskCreatedFailure": "Aufgabenerstellung fehlgeschlagen"
  }
}
</i18n>
