<template>
  <div v-loading="isLoading">
    <el-form
      :model="form"
      label-width="150px"
    >
      <el-form-item
        v-if="!studyId"
        :label="$t('search_engines')"
      >
        <search-engine-selector
          v-model="form.searchEngines"
          :search-engines="availableSearchEngines"
        >
        </search-engine-selector>
      </el-form-item>
      <el-form-item :label="$t('url')">
        <el-input
          v-model="form.url"
          :placeholder="$t('url_placeholder')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('custom_name')">
        <el-input v-model="form.customName"></el-input>
      </el-form-item>
      <el-form-item v-if="currentUser.is_admin" :label="$t('study_type')">
        <el-select
          v-if="studyTypes"
          v-model="form.studyType"
        >
          <el-option
            v-for="(studyType, index) in studyTypes"
            :key="index"
            :label="$t(studyType)"
            :value="studyType"
          >
        </el-option>
      </el-select>
    </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button
        plain
        @click="closeDialog"
        >{{ $t("cancel") }}</el-button
      >
      <el-button
        plain
        type="primary"
        @click="submitForm"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { availableSearchEnginesMixin } from "@/mixins/availableSearchEnginesMixin";
import SearchEngineSelector from "@/components/formItems/SearchEngineSelector";
import { RepositoryFactory } from "@/services/repositoryFactory";

export default {
  mixins: [availableSearchEnginesMixin],
  components: {
    SearchEngineSelector,
  },
  props: {
    studyId: Number,
    studyTypes: Array,
    currentUser: Object,
  },
  data() {
    return {
      form: {
        searchEngines: [],
        url: "",
        customName: "",
        studyType: "secondary_study",
      },
      isLoading: false,
    };
  },
  computed: {
    defaultSearchEngine() {
      if (this.form.searchEngines.length === 0) {
        return null;
      } else if (this.form.searchEngines.length === 1) {
        return this.form.searchEngines[0];
      } else {
        return this.form.searchEngines.sort((a, b) => {
          if (a.device === b.device) {
            return 0;
          } else if (a.device === "DESKTOP" && b.device === "MOBILE") {
            return -1;
          } else {
            return 1;
          }
        })[0];
      }
    },
  },
  methods: {
    submitForm() {
      this.closeDialog();
      const country = this.form.searchEngines.map(
        (searchEngine) =>
          searchEngine.isoLanguageCode.toLowerCase() +
          "-" +
          searchEngine.isoCountryCode.toLowerCase()
      )[0];
      this.$emit("submit", {
        ...this.form,
        country,
        defaultSearchEngine: this.defaultSearchEngine,
      });
      if (!this.studyId) {
        this.resetForm();
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    resetForm() {
      this.form.url = "";
      this.form.customName = "";
      this.form.searchEngines = [];
      this.form.studyType = "secondary_study";
    },
    loadStudy() {
      this.isLoading = true;
      this.$api
        .get("/studies/" + this.studyId)
        .then((response) => {
          const data = response.data.data;
          this.form.url = data.url;
          this.form.studyType = data.studyType;
          if (data.name) {
            this.form.customName = data.name;
          }
          if (data.searchEngines) {
            this.form.searchEngines = data.searchEngines;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },
  },
  created() {
    if (this.studyId) this.loadStudy();
  },
  watch: {
    studyId() {
      if (this.studyId) this.loadStudy();
    },
  },
};
</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "url": "URL",
    "url_placeholder": "Example: www.mywebsite.com",
    "custom_name": "Custom name",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "search_engines": "Search engines",
    "main_study": "Main study",
    "study_type": "Study type",
    "secondary_study": "Secondary study",
    "oneshot_study": "Oneshot study"
  },
  "fr": {
    "url": "URL",
    "url_placeholder": "Exemple: www.monsite.com",
    "custom_name": "Nom personnalisé",
    "cancel": "Annuler",
    "confirm": "Confirmer",
    "search_engines": "Moteurs de recherche",
    "main_study": "Etude principale",
    "study_type": "Type d'étude",
    "secondary_study": "Etude secondaire",
    "oneshot_study": "Etude oneshot"
  },
  "de": {
    "url": "URL",
    "url_placeholder": "Beispiel: www.mysite.com",
    "custom_name": "benutzerdefinierter Name",
    "cancel": "Stornieren",
    "confirm": "Bestätigen",
    "search_engines": "Suchmaschine",
    "main_study": "Hauptstudium",
    "study_type": "Studientyp",
    "secondary_study": "Zweitstudium",
    "oneshot_study": "Oneshot-Studie"
  }
}
</i18n>
