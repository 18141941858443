<template>
  <div>
    <div class="chart-title">
      <span class="chart-name">
        <el-tooltip
          placement="bottom"
          transition="el-fade-in"
        >
          <div slot="content">
            {{ $t("traffic_evolution_explication") }}
          </div>
          <span
            >{{ $t("traffic_evolution") }}
            <i class="fas fa-question-circle"></i>
          </span>
        </el-tooltip>
      </span>
      <div v-if="competitor.domain">
        <span class="change">
          <strong>
            <span class="competitor"
              >{{ competitor.domain }} :
              <span v-if="competitor.averageTrafficValue">
                {{ setNumberFormat(competitor.averageTrafficValue) }}
              </span></span
            >
            <span
              v-if="competitor.averageTrafficChange > 0"
              class="positive-change"
              ><i class="fas fa-arrow-up"></i
              >{{ setNumberFormat(competitor.averageTrafficChange) }}</span
            >
            <span
              v-else-if="competitor.averageTrafficChange == 0"
              class="neutral-change"
              ><i class="fas fa-equals"></i
            ></span>
            <span
              v-else-if="competitor.averageTrafficChange < 0"
              class="negative-change"
              ><i class="fas fa-arrow-down"></i
              >{{ setNumberFormat(competitor.averageTrafficChange) }}</span
            >
          </strong>
        </span>
      </div>
      <div v-if="self.domain">
        <span class="change">
          <span
            >{{ self.domain }} :
            <span v-if="competitor.averageTrafficValue">
              {{ setNumberFormat(self.averageTrafficValue) }}
            </span></span
          >
          <span
            v-if="self.averageTrafficChange > 0"
            class="positive-change"
            ><i class="fas fa-arrow-up"></i
            >{{ setNumberFormat(self.averageTrafficChange) }}</span
          >
          <span
            v-else-if="self.averageTrafficChange == 0"
            class="neutral-change"
            ><i class="fas fa-equals"></i
          ></span>
          <span
            v-else-if="self.averageTrafficChange < 0"
            class="negative-change"
            ><i class="fas fa-arrow-down"></i
            >{{ setNumberFormat(self.averageTrafficChange) }}</span
          >
        </span>
      </div>
    </div>
    <highcharts
      v-if="isMounted"
      v-loading="isLoading"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
    isLoading: Boolean,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      isMounted: false,
      competitor: {
        domain: null,
        averageTrafficData: null,
        averageTrafficValue: null,
        averageTrafficChange: null,
      },
      self: {
        domain: null,
        averageTrafficData: null,
      },
      chartOptions: {
        chart: {
          type: "line",
        },
        title: {
          text: "",
        },
        xAxis: [
          {
            type: "datetime",
          },
          {
            labels: {
              rotation: -45,
            },
            linkedTo: 0,
            opposite: true,
            type: "datetime",
          },
        ],
        yAxis: {
          title: "",
          min: 0,
        },
        series: [],
        tooltip: {
          shared: true,
        },
        legend: {
          maxHeight: 100,
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    setChart() {
      this.mapData();
      this.setSeries([this.data.competitor, this.data.self]);
    },
    mapData() {
      this.competitor.domain = this.data.competitor.url.url;
      const averageTrafficData = this.computeAverageTrafficData(
        this.data.competitor
      );
      this.competitor.averageTrafficData = averageTrafficData;
      this.competitor.averageTrafficValue =
        averageTrafficData[averageTrafficData.length - 1];
      this.competitor.averageTrafficChange =
        averageTrafficData[averageTrafficData.length - 1] -
        averageTrafficData[0];

      this.self.domain = this.data.self.url.url;
      this.self.averageTrafficData = this.computeAverageTrafficData(
        this.data.self
      );
      this.self.averageTrafficValue =
        this.self.averageTrafficData[this.self.averageTrafficData.length - 1];
      this.self.averageTrafficChange =
        this.self.averageTrafficData[this.self.averageTrafficData.length - 1] -
        this.self.averageTrafficData[0];
    },
    computeAverageTrafficData(website) {
      return Object.entries(website.websiteStatisticsByDates)
        .sort()
        .map((dataPoint) => Math.round(dataPoint[1].estimatedTraffic));
    },
    setSeries(websites) {
      const series = [];
      const nbNewKeywordsPerDate = new Map();
      let lastNbKeywords = 0;

      for (const [i, websitePositions] of websites.entries()) {
        const dataPoints = Object.entries(
          websitePositions.websiteStatisticsByDates
        )
          .sort()
          .map((websiteStatisticsByDate, index) => {
            let date = websiteStatisticsByDate[0];
            date = new Date(date).getTime();

            if (
              websiteStatisticsByDate[1].numberOfKeywords - lastNbKeywords !==
                0 &&
              i == 0 &&
              index > 0
            ) {
              let diff =
                websiteStatisticsByDate[1].numberOfKeywords - lastNbKeywords;
              let prefix = diff > 0 ? "+" : "";
              nbNewKeywordsPerDate.set(
                date,
                prefix + diff + " " + this.$i18n.t("keywords")
              );
            }
            lastNbKeywords = websiteStatisticsByDate[1].numberOfKeywords;

            return {
              x: date,
              y: parseInt(websiteStatisticsByDate[1].estimatedTraffic),
              url: websitePositions.url.url,
            };
          });

        if (websitePositions.url.url.includes(this.study.url)) {
          series.push({
            type: "spline",
            name: websitePositions.url.url,
            data: dataPoints,
            color: Highcharts.Color(Highcharts.getOptions().colors[0]).get(),
            visible: true,
            cursor: "pointer",
          });
        } else {
          series.push({
            type: "spline",
            name: websitePositions.url.url,
            data: dataPoints,
            color: Highcharts.Color(
              Highcharts.getOptions().colors.slice(1)[i]
            ).get(),
            visible: true,
          });
        }
      }
      this.chartOptions.series = series;
      this.chartOptions.xAxis[1].tickPositions = Array.from(
        nbNewKeywordsPerDate.keys()
      );
      this.chartOptions.xAxis[1].labels.formatter = function () {
        return nbNewKeywordsPerDate.get(this.value);
      };
    },
    setNumberFormat(number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
  watch: {
    data() {
      if (this.data) this.setChart();
    },
  },
  mounted() {
    this.isMounted = true;
    if (this.data) this.setChart();
  },
  computed: {
    ...mapState(["study"]),
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;

  .value {
    font-size: 1.2rem;
    margin-left: 0.35rem;
  }

  .chart-name {
    font-size: 1.5rem;
  }

  .fa-question-circle {
    font-size: 1.1rem;
  }

  .change {
    font-size: 0.9rem;

    .positive-change {
      color: $--color-success;
    }

    .neutral-change {
      color: $--color-warning;
    }

    .negative-change {
      color: $--color-danger;
    }

    .competitor {
      font-style: bold;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "traffic_evolution_explication": "This traffic is estimated based on the positions on each keyword, search volumes for each keyword, and click estimates based on each position on each keyword.",
    "traffic_evolution": "Evolution of estimated traffic",
    "keywords": "Keywords"
  },
  "fr": {
    "traffic_evolution_explication": "Ce trafic est estimé en fonction des positions sur chacun des mots clés, des volumes de recherches de chacun de ceux-ci, et d'estimation de clics en fonction de chaque position sur chaque mot clé.",
    "traffic_evolution": "Evolution du trafic estimé",
    "keywords": "mots clés"
  },
  "de": {
    "traffic_evolution_explication": "Dieser Verkehr wird anhand der Positionen für jedes der Schlüsselwörter, des Suchvolumens für jedes dieser Schlüsselwörter und der Schätzung der Klicks gemäß jeder Position für jedes Schlüsselwort geschätzt.",
    "traffic_evolution": "Entwicklung des geschätzten Verkehrs",
    "keywords": "Schlüsselwörter"
  }
}
</i18n>
