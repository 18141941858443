import Repository from "@/services/repository";

export default {
  createTagsRemoval(studyId, keywordIds, tags) {
    return Repository.post(`/a/${studyId}/keywords/remove_tags.json`, {
      keywordIds,
      tags,
    }).then((response) => response.data);
  },
};
