<template>
  <div>
    <el-popover
      placement="bottom-end"
      :title="this.title()"
      width="700"
      close-delay="0"
      trigger="hover"
    >
      <div v-if="this.params.value.category == 'page_monitoring'">
        <div v-if="this.message()">{{ this.message() }}</div>
        <div v-if="this.statusCode()">{{ this.statusCode() }}</div>
        <div v-if="this.pageRedirect()">{{ this.pageRedirect() }}</div>
        <div v-if="this.targetUrl()">{{ this.targetUrl() }}</div>
        <div v-if="this.robotsAllowed()">{{ this.robotsAllowed() }}</div>
        <div v-if="this.robotsVisible()">{{ this.robotsVisible() }}</div>
      </div>
      <div v-if="this.params.value.category == 'robots_monitoring'">
        <div v-if="this.message()">{{ this.message() }}</div>
        <vue-code-diff
          :old-string="oldStr"
          :new-string="newStr"
          :context="10"
          outputFormat="side-by-side"
        />
      </div>
      <el-button
        size="small"
        icon="el-icon-view"
        slot="reference"
      ></el-button>
    </el-popover>
  </div>
</template>

<script>
import vueCodeDiff from "vue-code-diff";

export default {
  components: { vueCodeDiff },
  computed: {
    oldStr: {
      get() {
        return this.params.value.data.old_file;
      },
    },
    newStr: {
      get() {
        return this.params.value.data.new_file;
      },
    },
  },
  methods: {
    message() {
      if (!this.params.value.data.message) return null;
      return this.params.value.data.message;
    },
    statusCode() {
      if (!this.params.value.data.status_code) return null;
      return (
        this.$i18n.t("status_code") + " : " + this.params.value.data.status_code
      );
    },
    pageRedirect() {
      if (!this.params.value.data.page_redirect) return null;
      return (
        this.$i18n.t("page_redirect") +
        " : " +
        this.params.value.data.page_redirect
      );
    },
    targetUrl() {
      if (!this.params.value.data.target_url) return null;
      return (
        this.$i18n.t("target_url") + " : " + this.params.value.data.target_url
      );
    },
    robotsAllowed() {
      return (
        this.$i18n.t("robots_allowed") +
        " : " +
        (this.params.value.data.robots_allowed
          ? this.$i18n.t("yes")
          : this.$i18n.t("no"))
      );
    },
    robotsVisible() {
      return (
        this.$i18n.t("robots_visible") +
        " : " +
        (this.params.value.data.robots_visible
          ? this.$i18n.t("yes")
          : this.$i18n.t("no"))
      );
    },
    title() {
      return this.$i18n.t("alert_details");
    },
  },
};
</script>

<style lang="scss" scoped>
.el-progress {
  line-height: inherit;
}

@media print {
  .print-background {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>

<i18n>
{
  "en": {
    "alert_details": "Alert details",
    "page_redirect": "Redirect",
    "robots_allowed": "Google allowed to visit page",
    "robots_visible": "Page can be indexed by Google",
    "status_code": "Status code",
    "target_url": "Expected redirect url",
    "yes": "Yes",
    "no": "No"
  },
  "fr": {
    "alert_details": "Détails de l'alerte",
    "page_redirect": "Redirect",
    "robots_allowed": "Google authorisé à crawler la page",
    "robots_visible": "Page indexable par Google",
    "status_code": "Status code",
    "target_url": "Url attendue après redirect",
    "yes": "Oui",
    "no": "Non"
  },
  "de": {
    "alert_details": "Benachrichtigungsdetails",
    "page_redirect": "Umleiten",
    "robots_allowed": "Google darf die Seite crawlen",
    "robots_visible": "Seite indexierbar durch Google",
    "status_code": "Statuscode",
    "target_url": "URL nach Weiterleitung erwartet",
    "yes": "Ja",
    "no": "Nicht"
  }
}
</i18n>
