<script>
import GoogleResultDialog from "@/components/GoogleResultDialog";

export default {
  components: {
    GoogleResultDialog,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>
