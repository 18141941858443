<template>
  <div v-if="params.data.topicalTF">
    <el-tooltip placement="top">
      <div slot="content">
        <div
          v-for="classification in params.data.topicalTF"
          v-bind:key="classification.categoryMajestic"
        >
          {{ classification.categoryMajestic }} - {{ classification.score }}
        </div>
      </div>
      <div>
        {{ params.data.topicalTF[0].categoryMajestic }} -
        {{ params.data.topicalTF[0].score }}
      </div>
    </el-tooltip>
  </div>
</template>
<script>
export default {};
</script>
