<template>
  <div>
    <el-progress
      class="print-background"
      :text-inside="true"
      :stroke-width="15"
      :percentage="params.value"
      :color="customColor"
    >
    </el-progress>
  </div>
</template>

<script>
export default {
  methods: {
    customColor(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-progress {
  line-height: inherit;
}

@media print {
  .print-background {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>

<i18n>
{
  "en": {},
  "fr": {},
  "de": {}
}
</i18n>
