<template>
  <highcharts
    ref="highchart"
    :options="chartOptions"
    :updateArgs="updateArgs"
  ></highcharts>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    frequency: String,
    mainSeries: Object,
    trafficGroups: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          zoomType: "x",
        },
        title: {
          text: this.$i18n.t("organicTrafficEvolution"),
        },
        yAxis: {
          title: {
            text: this.$i18n.t("sessions"),
          },
          min: 0,
        },
        xAxis: [
          {
            type: "datetime",
          },
        ],
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat:
            (this.frequency === "day"
              ? "{point.name}"
              : "{point.startDate:%d-%m-%Y} - {point.endDate:%d-%m-%Y}") +
            ": {point.y} {point.incompleteWeek}",
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        credits: {
          enabled: false,
        },
        series: this.series,
      };
    },
    trafficChartGroups() {
      if (this.trafficGroups) {
        return Object.entries(this.trafficGroups)
          .filter(([name]) => name)
          .map(([name, dataPoints], index) => {
            const dataPointsFormatted = dataPoints.map((dataPoint) => {
              return {
                x: new Date(dataPoint.date),
                y: Math.round(dataPoint.value),
              };
            });
            return {
              type: "column",
              name: name,
              data: dataPointsFormatted,
              color: Highcharts.Color(
                Highcharts.getOptions().colors.slice(4, 1)[index]
              ).get(),
              tooltip: {
                headerFormat: "<br><b>{series.name}</b><br>",
                pointFormatter: function () {
                  if (this.percentage) {
                    return (
                      this.x.toLocaleDateString().replace("/", "-") +
                      ": " +
                      this.y +
                      " (" +
                      Math.round(this.percentage) +
                      "%)"
                    );
                  }
                  return (
                    this.x.toLocaleDateString().replace("/", "-") +
                    ": " +
                    this.y
                  );
                },
              },
            };
          });
      } else {
        return [];
      }
    },
    mainSeriesChart() {
      if (this.mainSeries) {
        return Object.values(this.mainSeries).map((dataPoints, index) => {
          const dataPointsFormatted = dataPoints.map((dataPoint) => {
            return {
              x:
                (this.frequency === "week" || this.frequency === "month") &&
                index === 0
                  ? new Date(dataPoint.start_date)
                  : new Date(dataPoint.date),
              y: dataPoint.value,
              name: new Date(dataPoint.display_date)
                .toLocaleDateString()
                .replace(/\//g, "-"),
              startDate: new Date(dataPoint.start_date),
              endDate: new Date(dataPoint.end_date),
              incompleteWeek:
                this.frequency === "week" &&
                dataPoint.start_date &&
                dataPoint.end_date &&
                new Date(dataPoint.end_date) -
                  new Date(dataPoint.start_date) !==
                  6 * 24 * 60 * 60 * 1000
                  ? "(" + this.$t("incompleteWeek") + ")"
                  : "",
            };
          });
          if (index == 0) {
            return {
              type: "spline",
              name: this.$i18n.t("organicSessions"),
              data: dataPointsFormatted,
              color: Highcharts.Color(Highcharts.getOptions().colors[4]).get(),
            };
          } else if (index == 1) {
            return {
              type: "spline",
              name: this.$i18n.t("comparativeOrganicSessions"),
              data: dataPointsFormatted,
              dashStyle: "ShortDot",
              color: Highcharts.Color(Highcharts.getOptions().colors[4])
                .setOpacity(0.7)
                .get(),
            };
          } else {
            return {
              type: "spline",
              name: this.$i18n.t("noData"),
              data: dataPointsFormatted,
              dashStyle: "ShortDot",
              color: Highcharts.Color(Highcharts.getOptions().colors[3])
                .setOpacity(0.7)
                .get(),
            };
          }
        });
      } else {
        return [];
      }
    },
    series() {
      return this.trafficChartGroups.concat(this.mainSeriesChart);
    },
  },
};
</script>

<style scoped lang="scss"></style>

<i18n>
{
  "en": {
    "comparativeOrganicSessions": "Comparative organic sessions",
    "incompleteWeek": " incomplete week",
    "none": "None",
    "organicSessions": "Organic sessions",
    "organicTrafficEvolution": "Organic traffic evolution",
    "sessions": "Sessions",
    "noData": "No data"
  },
  "fr": {
    "comparativeOrganicSessions": "Sessions organiques comparatives",
    "incompleteWeek": "semaine incomplète",
    "none": "Aucun",
    "organicSessions": "Sessions organiques",
    "organicTrafficEvolution": "Evolution du trafic organique",
    "sessions": "Sessions",
    "noData": "Aucune données"
  },
  "de": {
    "comparativeOrganicSessions": "Vergleichende organische Sitzungen",
    "incompleteWeek": "unvollständige Woche",
    "none": "Keiner",
    "organicSessions": "Organische Sitzungen",
    "organicTrafficEvolution": "Entwicklung des organischen Verkehrs",
    "sessions": "Sitzungen",
    "noData": "Keine Daten"
  }
}
</i18n>
