<template>
  <div class="column">
    <div class="column-title">
      {{ title }}
    </div>

    <div class="actions">
      <slot name="actions"></slot>
    </div>

    <div class="tasks">
      <draggable
        :list="tasksList"
        draggable=".item"
        group="tasks"
        @change="moveTask"
        class="draggable"
      >
        <task
          v-for="task in tasksList"
          :key="task.id"
          class="task"
          @show-task="$emit('show-task', $event)"
          v-on="$listeners"
          :task="task"
          :userOptions="userOptions"
        >
        </task>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { formatDate } from "@/javascripts/formatDate";
import Task from "@/components/task/TaskCard";

export default {
  props: {
    tasksList: Array,
    title: String,
    status: String,
    userOptions: Array,
  },
  components: {
    draggable,
    Task,
  },
  data() {
    return {};
  },
  methods: {
    moveTask(event) {
      if (event.added) {
        const task = event.added.element;
        this.insertTask(task, event.added.newIndex, this.status);
      }

      if (event.moved) {
        this.insertTask(event.moved.element, event.moved.newIndex);
      }
    },
    insertTask(task, newIndex, newStatus) {
      let taskBeforeId = undefined;
      let taskAfterId = undefined;
      if (newIndex - 1 >= 0) {
        taskBeforeId = this.tasksList[newIndex - 1].id;
      }
      if (newIndex + 1 < this.tasksList.length) {
        taskAfterId = this.tasksList[newIndex + 1].id;
      }
      this.$emit("on-move-task", {
        task,
        taskBeforeId,
        taskAfterId,
        newStatus,
      });
    },
    taskKindIcon(kind) {
      return {
        CONTENT: "fas fa-align-left",
        TECHNICAL: "fas fa-cogs",
        NETLINKING: "fas fa-project-diagram",
        DESIGN: "fas fa-drafting-compass",
        GLOBAL: "fas fa-globe",
      }[kind];
    },
    taskKeywordsString(task) {
      let keywordsString = "";
      task.keywords.map((keyword) => {
        keywordsString = keywordsString + ", " + keyword.text;
      });
      return keywordsString.substring(1);
    },
    setDueDateType(task) {
      if (task.status === "COMPLETED") return "info";
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const taskDate = new Date(task.dueDate);
      taskDate.setHours(0, 0, 0, 0);
      if (today > taskDate) {
        return "danger";
      } else if (today.getTime() === taskDate.getTime()) {
        return "warning";
      } else {
        return "info";
      }
    },
  },
  filters: {
    formatUrl(value) {
      const result = value.match(/[^\/]+(?=\/$|$)/)[0];
      if (result.length > 30) {
        return result.substring(0, 30) + "...";
      } else {
        return result;
      }
    },
    formatDate(value) {
      return formatDate(value, "D MMMM");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem;
  border: 1px solid #a8a8a8;
  border-radius: $--border-radius-base;

  .column-title {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }

  .tasks {
    flex: auto;
    display: flex;
    flex-direction: column;

    .draggable {
      height: 100%;

      .task {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }

    .sortable-ghost {
      background-color: #e8e8e8;
      border: 2px solid #3c54a5;
      border-radius: 5px;
    }
  }
}

.actions {
  margin-top: 0.6rem;
  margin-bottom: 1rem;
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "global": "Global",
    "content": "Content",
    "technical": "Technical",
    "netlinking": "Netlinking",
    "design": "Design",
    "priority": "Priority",
    "critical": "Critical",
    "low": "Low",
    "high": "High",
    "medium": "Medium",
    "task_title_placeholder": "Input a title for your task"
  },
  "fr": {
    "global": "Global",
    "content": "Contenu",
    "technical": "Technique",
    "netlinking": "Netlinking",
    "design": "Design",
    "priority": "Priorité",
    "critical": "Critique",
    "low": "Basse",
    "high": "Elevée",
    "medium": "Moyenne",
    "task_title_placeholder": "Saisissez un titre pour votre tâche"
  },
  "de": {
    "global": "Global",
    "content": "Inhalt",
    "technical": "Technik",
    "netlinking": "Netzverlinkung",
    "design": "Design",
    "priority": "Priorität",
    "critical": "Kritik",
    "low": "Niedrig",
    "high": "Hoch",
    "medium": "Bedeuten",
    "task_title_placeholder": "Geben Sie einen Titel für Ihre Aufgabe ein"
  }
}
</i18n>
