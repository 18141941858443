<template>
  <li
    class="nav-item pl-4"
  >
    <a
      :href="dimensionsByPagesSpaLink"
      class="nav-link"
    >
      {{ $t("segmentationByPages") }}
    </a>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dimensionsByPagesSpaLink: null,
    };
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
    dimensionByPagesLinkExists() {
      console.log(this.studyLinks)
      return this.studyLinks["urlSegmentation"];
    },
  },
  mounted() {
    this.dimensionsByPagesSpaLink = `https://staging.app.smartkeyword.io/spa-app/studies/${this.study["id"]}/configuration?tab=dimensionsByPages`;
  },
};
</script>

<i18n>
{
  "en": {
    "segmentationByPages": "Segmentation by pages"
  },
  "fr": {
    "segmentationByPages": "Segmentation par pages"
  },
  "de": {
    "segmentationByPages": "Segmentierung nach Seiten"
  }
}
</i18n>
