<template>
  <div>
    <el-input
      v-model="anchorText"
      v-on:keyup.enter.stop.prevent.native="addItem()"
    >
      <el-button
        type="primary"
        size="small"
        slot="prepend"
        @click="addItem()"
        >Ajouter</el-button
      >
    </el-input>
    <ul>
      <li
        v-for="item in items"
        v-bind:key="item"
      >
        <el-tag
          closable
          @close="deleteItem(item)"
        >
          {{ item }}
        </el-tag>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      anchorText: "",
    };
  },
  computed: {
    items: {
      get() {
        return this.value;
      },
      set(items) {
        this.$emit("input", items);
      },
    },
  },
  methods: {
    deleteItem(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
    addItem() {
      if (this.anchorText.length > 0) {
        this.items.push(this.anchorText);
        this.anchorText = "";
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-input-group__prepend {
  background-color: #3c54a5;
  color: #fff;
}

li {
  margin-bottom: 0.35rem;
}

.is-in-database {
  cursor: pointer;
}
</style>

<i18n>
{
  "en": {
    "keywords": "Keywords",
    "keywords_placeholder": "Add keywords.."
  },
  "fr": {
    "keywords": "Mots-clés",
    "keywords_placeholder": "Ajouter des mots-clés.."
  },
  "de": {
    "keywords": "Schlüsselwörter",
    "keywords_placeholder": "Schlüsselwörter hinzufügen.."
  }
}
</i18n>
