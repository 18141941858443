<template>
  <div>
    <div class="flex-box-1">
      <div class="buttons">
        <el-button
          plain
          type="primary"
          @click="updateKeywords('flat')"
          >{{ $t("classificationFirstLevel") }}</el-button
        >
        <el-button
          plain
          type="primary"
          @click="updateKeywords('hierarchical')"
          >{{ $t("classificationHierarchy") }}</el-button
        >
        <el-button
          plain
          type="primary"
          @click="updateKeywords('adwords')"
          >{{ $t("classificationAdWords") }}</el-button
        >
        <el-button
          plain
          type="primary"
          @click="popupClassification"
          >{{ $t("classificationFollowingExistingClassification") }}</el-button
        >
      </div>
    </div>
    <div class="flex-box-2">
      <div class="block">
        <span>{{ $t("granularity") }}</span>
        <el-slider
          v-model="granularityScale"
          :format-tooltip="formatTooltip"
        ></el-slider>
      </div>
      <div class="apply-button">
        <el-button
          plain
          type="danger"
          @click="cancelSearch"
          >{{ $t("cancel") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deduplication: Boolean,
  },
  data() {
    return {
      granularity: 0.95,
    };
  },
  computed: {
    granularityScale: {
      get() {
        return this.granularity * 100;
      },
      set(granularityScale) {
        this.granularity = granularityScale / 100;
      },
    },
  },
  methods: {
    formatTooltip(val) {
      return val / 100;
    },
    updateKeywords(type) {
      this.$emit("update-granularity", this.granularity);
      this.$emit("update-type", type);
    },
    cancelSearch() {
      this.$emit("cancel-search", false);
    },
    popupClassification() {
      this.$emit("show-dialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  .block {
    width: 10%;
    text-align: center;
    margin-top: 2rem;
  }
  .apply-button {
    margin-left: auto;
  }
}
.flex-box-1 {
  align-items: center;
  .buttons {
    margin-top: 0.5rem;
    justify-content: space-between;
    text-align: center;
  }
}
</style>
<i18n>
{
  "en": {
    "apply": "Apply",
    "cancel": "Cancel",
    "granularity": "Granularity",
    "classificationFollowingExistingClassification": "Classification (Following Existing Classification)",
    "classificationFirstLevel": "Classification (1st Level)",
    "classificationAdWords": "Classification (AdWords)",
    "classificationHierarchy": "Classification (Hierarchy)"
  },
  "fr": {
    "apply": "Appliquer",
    "cancel": "Annuler",
    "granularity": "Granularité",
    "classificationFollowingExistingClassification": "Classification (Suivant une classification existante)",
    "classificationFirstLevel": "Classification (1er Niveau)",
    "classificationAdWords": "Classification (AdWords)",
    "classificationHierarchy": "Classification (Hiérarchie)"
  },
  "de": {
    "apply": "Anwenden",
    "cancel": "Stornieren",
    "granularity": "Die Granularität",
    "classificationFollowingExistingClassification": "Klassifizierung (nach einer bestehenden Klassifizierung)",
    "classificationFirstLevel": "Classification (1er Niveau)",
    "classificationAdWords": "Klassifizierung (AdWords)",
    "classificationHierarchy": "Klassifikation (Hierarchie)"
  }
}
</i18n>
