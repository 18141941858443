<template>
  <el-tooltip
    placement="top"
    :content="params.value"
  >
    <div>
      <a
        target="_blank"
        :href="`${params.value}`"
        >{{ path }}</a
      >
    </div>
  </el-tooltip>
</template>

<script>
export default {
  computed: {
    path() {
      return new URL(this.params.value).pathname;
    },
  },
};
</script>

<style lang="scss" scoped></style>
