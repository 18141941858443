<template>
  <el-card>
    <span class="category-title"> {{ $t("competitorsTitles") }}</span>
    <div class="serp-titles">
      <div
        v-for="title in titlesShown"
        v-bind:key="title"
      >
        <el-tag v-if="title">{{ title }}</el-tag>
      </div>
    </div>
    <div class="flex-box">
      <el-link
        style="margin-left: auto; font-size: 0.85rem"
        type="primary"
        @click="showAllTitles = !showAllTitles"
      >
        {{ showAllTitles ? $t("showLessTitles") : $t("showMoreTitles") }}
      </el-link>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    serpTitles: Array,
  },
  data() {
    return {
      showAllTitles: false,
    };
  },
  computed: {
    titlesShown() {
      if (this.showAllTitles) return this.serpTitles;
      return this.serpTitles.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-title {
  font-weight: bold;
}

.serp-titles {
  margin-top: 0.5rem;

  .el-tag {
    word-break: break-word;
    white-space: normal;
    height: auto;
    margin-bottom: 0.2rem;
  }
}

.flex-box {
  display: flex;
}
</style>
<i18n>
{
    "en": {
        "competitorsTitles": "Competitors titles",
        "showLessTitles": "- Show less",
        "showMoreTitles": "+ Show more"
    },
    "fr": {
        "competitorsTitles": "Titres concurrents",
        "showLessTitles": "- Montrer moins",
        "showMoreTitles": "+ Montrer plus"
    },
    "it": {
        "competitorsTitles": "Titoli dei concorrenti",
        "showLessTitles": "- Mostra meno",
        "showMoreTitles": "+ Mostra di più"
    },
    "es": {
        "competitorsTitles": "Títulos de los competidores",
        "showLessTitles": "- Mostrar menos",
        "showMoreTitles": "+ Mostrar más"
    },
    "de": {
        "competitorsTitles": "Titel der Wettbewerber",
        "showLessTitles": "- Weniger anzeigen",
        "showMoreTitles": "+ Mehr anzeigen"
    },
    "nl": {
        "competitorsTitles": "Concurrententitels",
        "showLessTitles": "- Minder tonen",
        "showMoreTitles": "+ Toon meer"
    }
}
</i18n>
