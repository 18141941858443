<template>
  <div>
    <app-introduction
      :title="$t('tasks_admin_view')"
      :user="currentUser"
    ></app-introduction>

    <div class="container">
      <el-card v-loading="loadingTasks">
        <tasks-board
          :tasks="enrichedTasks"
          :filters="{}"
          :current-user="currentUser"
          :user-options="userOptions"
          @on-updated-task="updatedTask"
          @on-archived-task="deletedTask"
          @on-deleted-task="deletedTask"
          @on-created-task="createdTask"
        >
          <template v-slot:filters>
            <el-form
              inline
              size="medium"
            >
              <el-form-item :label="$t('assigned_users')">
                <el-select
                  v-model="tasksFilters.users"
                  :value-key="'id'"
                  :loading="loadingUsers"
                  multiple
                  filterable
                  default-first-option
                  clearable
                  collapse-tags
                >
                  <el-option-group :label="$t('account_managers')">
                    <el-option
                      v-for="user in accountManagerOptions"
                      :key="user.id"
                      :label="user | fullName"
                      :value="user"
                    ></el-option>
                  </el-option-group>

                  <el-option-group :label="$t('normal_users')">
                    <el-option
                      v-for="user in normalUserOptions"
                      :key="user.id"
                      :label="user | fullName"
                      :value="user"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>

              <el-form-item :label="$t('followedBy')">
                <el-select
                  v-model="tasksFilters.watchers"
                  :value-key="'id'"
                  :loading="loadingUsers"
                  multiple
                  filterable
                  default-first-option
                  clearable
                  collapse-tags
                >
                  <el-option-group :label="$t('account_managers')">
                    <el-option
                      v-for="user in accountManagerOptions"
                      :key="user.id"
                      :label="user | fullName"
                      :value="user"
                    ></el-option>
                  </el-option-group>

                  <el-option-group :label="$t('normal_users')">
                    <el-option
                      v-for="user in normalUserOptions"
                      :key="user.id"
                      :label="user | fullName"
                      :value="user"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>

              <el-form-item :label="$t('kind')">
                <el-select
                  v-model="tasksFilters.kinds"
                  :value-key="'id'"
                  filterable
                  multiple
                  default-first-option
                  clearable
                >
                  <el-option
                    v-for="kind in kindOptions"
                    :key="kind.value"
                    :label="kind.label"
                    :value="kind.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="$t('companies')">
                <el-select
                  v-model="tasksFilters.companies"
                  :value-key="'attributes.name'"
                  :loading="loadingCompanies"
                  multiple
                  filterable
                  default-first-option
                >
                  <el-option
                    v-for="company in companyOptions"
                    :key="company.id"
                    :label="company.attributes.name"
                    :value="company"
                  ></el-option>
                </el-select>
              </el-form-item>

              <due-date-filter v-model="tasksFilters.dueDate"></due-date-filter>
            </el-form>
          </template>
        </tasks-board>
      </el-card>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompaniesRepository = RepositoryFactory.get("companies");
const UsersRepository = RepositoryFactory.get("users");

import AppIntroduction from "@/components/AppIntroduction";
import DueDateFilter from "@/components/task/filter/DueDate";

export default {
  components: {
    AppIntroduction,
    DueDateFilter,
  },
  props: {
    currentUser: Object,
  },
  data() {
    return {
      companyOptions: [],
      selectedCompany: null,
      tasksFilters: {
        companies: [],
        dueDate: null,
        kinds: [],
        users: [],
        watchers: [],
      },
      kindOptions: [
        { value: "CONTENT", label: this.$i18n.t("content") },
        { value: "TECHNICAL", label: this.$i18n.t("technical") },
        { value: "NETLINKING", label: this.$i18n.t("netlinking") },
        { value: "DESIGN", label: this.$i18n.t("design") },
        { value: "GLOBAL", label: this.$i18n.t("global") },
      ],
      loadingCompanies: false,
      userOptions: [],
      loadingUsers: false,
      loadingTasks: false,
      tasks: [],
    };
  },
  computed: {
    studies() {
      if (this.selectedCompany) {
        return this.selectedCompany.attributes.studies;
      } else {
        return [];
      }
    },
    accountManagerOptions() {
      return this.userOptions.filter((user) => user.isAdmin);
    },
    normalUserOptions() {
      return this.userOptions.filter((user) => !user.isAdmin);
    },
    enrichedTasks() {
      return this.tasks.map((task) => {
        const company = this.companyOptions.find((company) =>
          company.attributes.studies.some((study) => study.id === task.studyId)
        );
        if (company) {
          return {
            ...task,
            companyName: company.attributes.name,
          };
        } else {
          return task;
        }
      });
    },
  },
  methods: {
    fetchCompanies() {
      this.loadingCompanies = true;
      CompaniesRepository.getCompanies()
        .then((data) => {
          this.companyOptions = data.data.sort((a, b) =>
            a.attributes.name.localeCompare(b.attributes.name)
          );
          this.loadingCompanies = false;
        })
        .catch((error) => {
          console.error(error);
          this.loadingCompanies = false;
        });
    },
    fetchUsers() {
      this.loadingUsers = true;
      UsersRepository.getUsers()
        .then((data) => {
          this.userOptions = data.data
            .map((user) => {
              return {
                id: parseInt(user.id),
                firstName: user.attributes.firstName,
                lastName: user.attributes.lastName,
                isAdmin: user.attributes.isAdmin,
              };
            })
            .filter((user) => user.firstName !== "-" && user.lastName !== "-")
            .sort((a, b) =>
              (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName)
            );
          this.loadingUsers = false;
          setTimeout(() => {
            this.tasksFilters.users = this.userOptions.filter(
              (user) => user.isAdmin
            );
          }, 0);
        })
        .catch((error) => {
          console.log(error);
          this.loadingUsers = false;
        });
    },
    fetchTasks() {
      if (
        this.tasksFilters.users.length > 0 ||
        this.tasksFilters.companies.length > 0 ||
        this.tasksFilters.watchers.length > 0
      ) {
        this.loadingTasks = true;
        const requestSearch = {
          archived: false,
          assignedToUserIds:
            this.tasksFilters.users.length > 0
              ? this.tasksFilters.users.map((user) => user.id)
              : undefined,
          watcherIds:
            this.tasksFilters.watchers.length > 0
              ? this.tasksFilters.watchers.map((user) => user.id)
              : undefined,
          dueDate: this.tasksFilters.dueDate
            ? {
                start: this.tasksFilters.dueDate[0],
                end: this.tasksFilters.dueDate[1],
              }
            : undefined,
          kinds:
            this.tasksFilters.kinds.length > 0
              ? this.tasksFilters.kinds
              : undefined,
          studyIds:
            this.tasksFilters.companies.length > 0
              ? this.tasksFilters.companies.reduce(
                  (studyIds, company) =>
                    studyIds.concat(
                      company.attributes.studies.map((study) => study.id)
                    ),
                  []
                )
              : undefined,
        };
        this.$api
          .post(`/tasks/search`, requestSearch)
          .then((response) => {
            this.tasks = response.data.data;
            this.loadingTasks = false;
          })
          .catch((error) => {
            console.log(error);
            this.loadingTasks = false;
          });
      } else {
        this.tasks = [];
      }
    },
    createdTask(task) {
      this.tasks.push(task);
    },
    updatedTask(task) {
      this.tasks.splice(
        this.tasks.findIndex((t) => t.id === task.id),
        1,
        task
      );
    },
    deletedTask(taskId) {
      const taskIndex = this.tasks.findIndex((task) => task.id === taskId);
      this.tasks.splice(taskIndex, 1);
    },
  },
  filters: {
    fullName(user) {
      return user.firstName + " " + user.lastName;
    },
  },
  mounted() {
    this.fetchCompanies();
    this.fetchUsers();
    this.fetchTasks();
  },
  watch: {
    tasksFilters: {
      handler: "fetchTasks",
      deep: true,
    },
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "account_managers": "Account Managers",
    "assigned_users": "Assigned users",
    "companies": "Companies",
    "due_date": "Due date",
    "end_date": "End date",
    "normal_users": "Normal users",
    "start_date": "Start date",
    "tasks_admin_view": "Tasks (admin view)",
    "to": "To",
    "kind": "Kind",
    "content": "Content",
    "technical": "Technical",
    "netlinking": "Netlinking",
    "design": "Design",
    "global": "Global"
  },
  "fr": {
    "account_managers": "Account Managers",
    "assigned_users": "Utilisateurs assignés",
    "companies": "Sociétés",
    "due_date": "Date d'échéance",
    "end_date": "Date de fin",
    "normal_users": "Utilisateurs normaux",
    "start_date": "Date de début",
    "tasks_admin_view": "Tâches (vue admin)",
    "to": "à",
    "kind": "Type",
    "content": "Contenu",
    "technical": "Technique",
    "netlinking": "Netlinking",
    "design": "Design",
    "global": "Global"
  },
  "de": {
    "account_managers": "Kundenbetreuer",
    "assigned_users": "Zugewiesene Benutzer",
    "companies": "Firmen",
    "due_date": "Geburtstermin",
    "end_date": "Endtermin",
    "normal_users": "Normale Benutzer",
    "start_date": "Startdatum",
    "tasks_admin_view": "Aufgaben (Admin-Ansicht)",
    "to": "Zu",
    "kind": "Typ",
    "content": "Inhalt",
    "technical": "Technik",
    "netlinking": "Netzverlinkung",
    "design": "Design",
    "global": "Global"
  }
}
</i18n>
