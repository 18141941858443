<template>
  <div>
    <div
      class="element d-flex flex-row justify-content-between align-items-center category-title"
    >
      <span> {{ $t("seoOptimization") }}</span>
      <el-tooltip
        :content="$t('tooltipOptimisation')"
        placement="top"
      >
        <i class="fas fa-question-circle"></i>
      </el-tooltip>
    </div>
    <div class="seo-scores">
      <div class="element seo-score">
        <el-progress
          :class="progressClass"
          type="dashboard"
          :percentage="ownScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div :class="textClass">
          {{ isActionPlanDialog ? $t("yourScore") : $t("myScore") }}
        </div>
      </div>
      <el-divider
        direction="vertical"
        class="vertical-divider"
      />
      <div class="element seo-score">
        <el-progress
          :class="progressClass"
          type="dashboard"
          :percentage="serpScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div :class="textClass">
          {{ isActionPlanDialog ? $t("serpScore") : $t("serp") }}
        </div>
        <div class="mt-2">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ownScore: Number,
    serpScore: Number,
    isActionPlanDialog: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      colors: [
        { color: "#FF0000", percentage: 20 },
        { color: "#FFCC00", percentage: 40 },
        { color: "#4ECDC4", percentage: 60 },
        { color: "#66FF00", percentage: 80 },
        { color: "#008000", percentage: 100 },
      ],
    };
  },
  methods: {
    format(percentage) {
      if (isNaN(percentage)) return 0;
      return percentage;
    },
  },
  computed: {
    scoreClass() {
      if (this.isActionPlanDialog)
        return "d-flex flex-column-reverse align-items-center element seo-score text-dialog mx-5";
      else return "element seo-score";
    },
    textClass() {
      if (this.isActionPlanDialog) return "text-dialog mb-3";
      else return "text";
    },
    progressClass() {
      if (this.isActionPlanDialog) return "large-dashboard";
      else return "mini-dashboard";
    },
  },
};
</script>

<style lang="scss" scoped>
.seo-scores {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  white-space: nowrap;
  .vertical-divider {
    height: unset;
  }
  .text-dialog {
    text-align: center;
    font-size: 1.2rem;
    word-break: break-word;
  }
  .element {
    flex: 1;
  }

  .seo-score {
    text-align: center;

    .text {
      text-align: center;
      font-size: 0.9rem;
      word-break: break-word;
    }

    .mini-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 0.8rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .large-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 1.2rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 75px !important;
        height: 75px !important;
      }
    }
  }
}

.category-title {
  margin-bottom: 0.6rem;
  font-weight: bold;
}
</style>

<i18n>
{
    "en": {
        "ownContentRelevance": "Relevance of my content",
        "serpContentRelevance": "Relevance of SERP content",
        "serp": "SERP",
        "myScore": "My score",
        "serpScore":"SERP Score",
        "yourScore": "Your score",
        "seoOptimization": "SEO Optimization",
        "tooltipOptimisation":"Score which is calculated on the number of occurrences or co-occurences close to the recommended keywords. These keywords are the ones that are most present in the contents of the best results of the SERP"
    },
    "fr": {
        "ownContentRelevance": "Pertinence de mon contenu",
        "serpContentRelevance": "Pertinence du contenu de la SERP",
        "serp": "SERP",
        "myScore": "Mon score",
        "serpScore":"Score de la SERP",
        "yourScore": "Votre score",
        "seoOptimization": "Optimisation SEO",
        "tooltipOptimisation":"Score qui est calculé sur le nombre d'occurences ou co-occurences proches des mots clés recommandés. Ces mots clés sont ceux qui sont le plus présents dans les contenus des meilleurs résultats de la SERP"
    },
    "it": {
        "ownContentRelevance": "Pertinenza dei miei contenuti",
        "serpContentRelevance": "Pertinenza dei contenuti SERP",
        "serp": "SERP",
        "myScore": "Il mio punteggio",
        "seoOptimization": "Ottimizzazione SEO",
        "tooltipOptimisation":"Punteggio che viene calcolato sul numero di occorrenze o co-occorrenze vicine alle parole chiave consigliate. Queste parole chiave sono quelle maggiormente presenti nei contenuti dei migliori risultati della SERP"
    },
    "es": {
        "ownContentRelevance": "Relevancia de mi contenido",
        "serpContentRelevance": "Relevancia del contenido SERP",
        "serp": "SERP",
        "myScore": "Mi puntuación",
        "seoOptimization": "Optimización SEO",
        "tooltipOptimisation":"Puntuación que se calcula sobre el número de ocurrencias o co-ocurrencias cercanas a las palabras clave recomendadas. Estas palabras clave son las que están más presentes en los contenidos de los mejores resultados de la SERP"
    },
    "de": {
        "ownContentRelevance": "Relevanz meiner Inhalte",
        "serpContentRelevance": "Relevanz des SERP-Inhalts",
        "serp": "SERP",
        "myScore": "Meine Punktzahl",
        "seoOptimization": "SEO-Optimierung",
        "tooltipOptimisation":"Score, der anhand der Anzahl der Vorkommen oder gemeinsamen Vorkommen in der Nähe der empfohlenen Schlüsselwörter berechnet wird. Diese Schlüsselwörter sind diejenigen, die in den Inhalten der besten Ergebnisse der SERP am häufigsten vorkommen."
    },
    "nl": {
        "ownContentRelevance": "Relevantie van mijn inhoud",
        "serpContentRelevance": "Relevantie van SERP-inhoud",
        "serp": "SERP",
        "myScore": "Mijn score",
        "seoOptimization": "SEO-optimalisatie",
        "tooltipOptimisation":"Score die wordt berekend op basis van het aantal keren of gelijktijdig voorkomend in de buurt van de aanbevolen zoekwoorden. Deze zoekwoorden zijn het meest aanwezig in de inhoud van de beste resultaten van de SERP"
    }
}
</i18n>
