<template>
  <el-tabs
    v-model="activeEvolutionTab"
    tab-position="left"
  >
    <el-tab-pane
      name="evolutionGraph"
      :label="$t('graph')"
    >
      <chart
        v-if="activeEvolutionTab === 'evolutionGraph'"
        :frequency="frequency"
        :main-series="mainSeries"
        :traffic-groups="trafficGroups"
      ></chart>
    </el-tab-pane>

    <el-tab-pane
      name="evolutionNumbers"
      :label="$t('in_numbers')"
    >
      <grid
        v-if="activeEvolutionTab === 'evolutionNumbers'"
        :main-series="mainSeries"
        :traffic-groups="trafficGroups"
        :comparative-traffic-groups="comparativeTrafficGroups"
        :comparative-period="comparativePeriod"
      ></grid>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Chart from "./Chart";
import Grid from "./Grid";

export default {
  components: {
    Chart,
    Grid,
  },
  props: {
    frequency: String,
    mainSeries: Object,
    trafficGroups: Object,
    comparativeTrafficGroups: Object,
    comparativePeriod: Array,
  },
  data() {
    return {
      activeEvolutionTab: null,
    };
  },
  mounted() {
    this.activeEvolutionTab = "evolutionGraph";
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "graph": "Chart",
    "in_numbers": "Table"
  },
  "fr": {
    "graph": "Graphe",
    "in_numbers": "En chiffres"
  },
  "de": {
    "graph": "Graph",
    "in_numbers": "In Zahlen"
  }
}
</i18n>
