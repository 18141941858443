<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    serpContentSizes: Array,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 500,
        },
        title: {
          text: this.$i18n.t("serp_nb_words_by_page"),
        },
        xAxis: {
          allowDecimals: false,
          categories: this.serpContentSizes.map((member) => member["rank"]),
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          title: {
            text: this.$i18n.t("nbWords"),
            align: "high",
          },
          labels: {
            overflow: "justify",
          },
        },
        series: [
          {
            name: this.$i18n.t("serp_nb_words_by_page"),
            data: this.serpContentSizes.map((member) => {
              return {
                y: member["contentLength"],
                position: member["rank"],
                url: member["url"],
                lastCrawlDate: member["lastCrawlDate"],
              };
            }),
          },
        ],
        tooltip: {
          useHTML: true,
          style: {
            pointerEvents: "auto",
          },
          formatter() {
            return `Position : <b>${this.x}</b><br />Url : <a href="${
              this.point.url
            }" target=_blank>${
              this.point.url
            }</a><br /> Nombre de mots : <b>${this.point.y.toLocaleString(
              "fr-FR",
              { minimumFractionDigits: 0 }
            )}</b> <p> Récupérée le :  <b>${this.point.lastCrawlDate}<b/> </p>`;
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
            cursor: "pointer",
            events: {
              click: function (event) {
                window.open(event.point.url);
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>

<i18n>
{
  "en": {
    "position": "Position",
    "serp_nb_words_by_page": "# words of each of the first 10 results",
    "nb_words": "Number of words : ",
    "nbWords": "Number of words : ",
    "dataFetchedAt": "data fetched at : "
  },
  "fr": {
    "position": "Position",
    "serp_nb_words_by_page": "Nombre de mots de chacun des 10 premiers résultats de Google",
    "nb_words": "Nombre de mots : ",
    "nbWords": "Nombre de mots : ",
    "dataFetchedAt": "données récupérées le :"
  },
  "de": {
    "position": "Position",
    "serp_nb_words_by_page": "Wortzahl von jedem der 10 besten Google-Ergebnisse",
    "nb_words": "Anzahl der Wörter :",
    "nbWords": "Anzahl der Wörter :",
    "dataFetchedAt": "Daten abgerufen am:"
  }
}
</i18n>
