<template>
  <div>
    <div v-click-outside="mouseDetector">
      <div class="title edit-save-links">
        <div>
          <h4>
            <i class="fas fa-align-left"></i>
            {{ $t("description") }}
          </h4>
        </div>
        <el-link
          class="cancel"
          v-show="editing"
          @click="cancelChanges"
        >
          <i class="fas fa-times"></i>
          {{ $t("cancel") }}
        </el-link>
        <el-link
          class="save"
          icon="el-icon-check"
          v-show="editing"
          @click="saveEdit"
          >{{ $t("save") }}</el-link
        >
        <el-link
          class="edit"
          icon="el-icon-edit"
          v-show="!editing"
          @click="startEditing"
          >{{ $t("edit") }}</el-link
        >
      </div>

      <div
        class="editor"
        @click="startEditing"
      >
        <TiptapEditor
          v-model="description"
          :isEditable="true"
          :isEditing="editing"
          :height="350"
        >
        </TiptapEditor>
      </div>
    </div>
  </div>
</template>

<script>
import TiptapEditor from "@/components/TiptapV2";
import Vue from "vue";

Vue.directive("click-outside", {
  bind() {
    this.event = (event) => this.vm.$emit(this.expression, event);
    this.el.addEventListener("click", this.stopProp);
    document.body.addEventListener("click", this.event);
  },
  unbind() {
    this.el.removeEventListener("click", this.stopProp);
    document.body.removeEventListener("click", this.event);
  },

  stopProp(event) {
    event.stopPropagation();
  },
});

export default {
  props: {
    value: [String, Object],
    creatingTask: Boolean,
  },
  components: {
    TiptapEditor,
  },
  data() {
    return {
      editing: false,
      oldValue: this.value,
      description: this.value,
    };
  },
  methods: {
    mouseDetector(event) {
      if (this.editing == true) {
        this.saveEdit();
      }
    },
    outsideClick() {
      if (this.editing == true) {
        this.saveEdit();
      }
    },
    startEditing() {
      this.editing = true;
      this.oldValue = this.description;
    },
    saveEdit() {
      this.editing = false;
      this.oldValue = this.description;
      this.$emit("input", this.description);
    },
    cancelChanges() {
      this.description = this.oldValue;
      this.editing = false;
    },
  },
  watch: {
    value() {
      this.description = this.value;
      this.editing = false;
    },
  },
  mounted() {
    if (this.creatingTask) {
      this.editing = true;
    }
  },
};
</script>
<style scoped lang="scss">
.title {
  margin-bottom: 0.75rem;
}

.file-title {
  margin-top: 1rem;
}

.edit-save-links {
  display: flex;

  .save {
    margin-left: 5px;
  }

  .edit {
    margin-left: auto;
  }

  .cancel {
    margin-left: auto;
    color: #ff6666;
  }
}

.editor {
  height: 100%;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  padding: 12px;
}
</style>

<i18n>
{
  "en": {
    "description": "Description",
    "edit": "Edit",
    "save": "Save",
    "cancel": "Cancel"
  },
  "fr": {
    "description": "Description",
    "edit": "Modifier",
    "save": "Sauvegarder",
    "cancel": "Annuler"
  },
  "de": {
    "description": "Beschreibung",
    "edit": "Modifikator",
    "save": "Zu schützen",
    "cancel": "Stornieren"
  }
}
</i18n>
