<template>
  <div class="action">
    <div class="icons">
      <div>
        <el-tooltip
          effect="dark"
          placement="top"
          :content="$t('consultBrief')"
        >
          <el-button
            :id="'consultBrief' + params.data.id"
            :disabled="!params.data.actions.brief"
            type="primary"
            plain
            icon="el-icon-notebook-2"
            circle
            size="mini"
            @click.stop="showBriefDialog()"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="clipboard">
        <el-tooltip
          effect="dark"
          placement="top"
          :content="$t('consultText')"
        >
          <el-button
            :id="'consultText' + params.data.id"
            :disabled="!params.data.actions.text"
            type="primary"
            plain
            icon="el-icon-tickets"
            circle
            size="mini"
            @click.stop="showTextDialog()"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="edit">
        <el-tooltip
          effect="dark"
          placement="top"
          :content="$t('modify')"
        >
          <el-button
            :id="'modifyContent' + params.data.id"
            :disabled="!params.data.actions.update"
            type="primary"
            plain
            icon="el-icon-edit"
            circle
            size="mini"
            @click.stop="editContent()"
          ></el-button>
        </el-tooltip>
      </div>
      <el-popconfirm
        :confirmButtonText="$t('yes')"
        :cancelButtonText="$t('no')"
        icon="el-icon-info"
        iconColor="red"
        :title="$t('areYouSure')"
        @onConfirm="deleteContent()"
      >
        <el-tooltip
          slot="reference"
          effect="dark"
          placement="top"
          :content="$t('delete')"
        >
          <el-button
            :id="'deleteContent' + params.data.id"
            :disabled="!params.data.actions.delete"
            type="danger"
            plain
            icon="el-icon-delete"
            circle
            size="mini"
          ></el-button>
        </el-tooltip>
      </el-popconfirm>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    editContent() {
      let details = {};
      details.link = this.params.data.actions.update;
      this.params.context.editContent(details);
    },
    deleteContent() {
      let details = {};
      details.link = this.params.data.actions.delete;
      this.params.context.deleteContent(details);
    },
    showTextDialog() {
      this.params.context.showTextDialog(this.params.data);
    },
    showBriefDialog() {
      this.params.context.showBriefDialog(this.params.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .fas {
    cursor: pointer;
  }

  .far {
    cursor: pointer;
  }
}
</style>
<i18n>
{
  "en": {
    "yes": "Yes",
    "no": "No",
    "areYouSure": "Are you sure ?",
    "consultText": "Consult the text",
    "modify": "Modify",
    "delete": "Delete",
    "consultBrief": "Consult the brief"
  },
  "fr": {
    "yes": "Oui",
    "no": "Non",
    "areYouSure": "Etes-vous sûr ?",
    "consultText": "Consulter le texte",
    "modify": "Modifier",
    "delete": "Supprimer",
    "consultBrief": "Consulter le brief"
  },
  "de": {
    "yes": "Ja",
    "no": "Nicht",
    "areYouSure": "Bist du dir sicher ?",
    "consultText": "Konsultieren Sie den Text",
    "modify": "Modifikator",
    "delete": "LÖSCHEN",
    "consultBrief": "Konsultieren Sie die Kurzbeschreibung"
  }
}
</i18n>
