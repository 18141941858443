<template>
  <div class="containerPlanButton">
    <div v-if="seeMoreButtonText === 'less'">
      <div
        v-for="(item, index) in aiResponseArray"
        :key="index"
        class="containerPlan"
      >
        <span
          v-if="item[2] === '1'"
          class="titleh1"
          >{{ item }}</span
        >
        <span
          v-else-if="item[2] === '2'"
          class="titleh2"
          >{{ item }}</span
        >
        <span
          v-else
          class="titleh3"
          >{{ item }}</span
        >
      </div>
    </div>
    <div v-else>
      <div
        v-for="(item, index) in aiResponseArray"
        :key="index"
        class="containerPlan"
      >
        <template v-if="index <= 2">
          <span
            v-if="item[2] === '1'"
            class="titleh1"
            >{{ item }}</span
          >
          <span
            v-else-if="item[2] === '2'"
            class="titleh2"
            >{{ item }}</span
          >
          <span
            v-else
            class="titleh3"
            >{{ item }}</span
          >
        </template>
      </div>
    </div>
    <div class="buttonMore">
      <i
        :class="{
          'el-collapse-item__arrow': true,
          'el-icon-arrow-down': !isExpanded,
          'el-icon-arrow-up': isExpanded,
        }"
      >
      </i>
      <span
        class="buttonMore"
        @click="defineMoreOrLessButton()"
        >{{ $t(seeMoreButtonText) }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aiResponseArray: Array,
  },
  data() {
    return {
      seeMoreButtonText: "plus",
      isExpanded: false,
    };
  },
  methods: {
    defineMoreOrLessButton() {
      this.seeMoreButtonText =
        this.seeMoreButtonText === "plus"
          ? (this.seeMoreButtonText = "less")
          : (this.seeMoreButtonText = "plus");
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped>
.el-collapse-item__arrow {
  margin: 0;
  transition: transform 0.3s;
}

.buttonMore {
  margin-left: auto;
  margin-top: 1em;
  color: #3c54a5;
}

.containerPlanButton {
  display: flex;
  flex-direction: column;
}

.containerPlan {
  display: flex;
}

.titleh1 {
  font-weight: bold;
  font-size: 16px;
}

.titleh2 {
  font-size: 14px;
  padding-left: 15px;
}
.titleh3 {
  font-size: 14px;
  padding-left: 30px;
}
</style>

<i18n>
  {
    "en": {
      "plus" : "view more",
      "less" : "view less"
    },
    "fr": {
      "plus" : "voir plus",
      "less" : "voir moins"
    },
    "de": {
      "plus" : "mehr sehen",
      "less" : "weniger sehen"
    }
  }
</i18n>
