<template>
  <div>
    <app-introduction
      :title="$t('campaignManager')"
      :user="currentUser"
      :documentations="[
        {
          link: $t('understandCampaignManagerLink'),
          description: $t('understandCampaignManagerDescription'),
        },
      ]"
    >
      <template
        v-slot:right
        v-if="currentUser.is_admin || currentUser.company_group_id == 8"
      >
        <el-switch
          v-model="isAdmin"
          inactive-text="Admin"
        >
        </el-switch>
      </template>
    </app-introduction>
    <div class="container">
      <add-campaign-button
        v-if="campaignLinks && campaignLinks.create"
        @click="showAddCampaignDialog()"
        :isCampaign="true"
      >
      </add-campaign-button>
      <div
        class="campaigns"
        v-loading="isLoading"
      >
        <campaign
          class="campaign"
          v-for="campaign in campaigns"
          v-bind:key="campaign.id"
          :data="campaign"
          :study-id="account.id"
          :admin="isAdmin"
          :study-url="account.url"
          :default-search-engine="defaultSearchEngine"
          :account="account"
          @delete-campaign="callAPI($event)"
          @update-campaign="
            (refreshedCampaign) =>
              (campaign = Object.assign(campaign, refreshedCampaign))
          "
          @launch-campaign="callAPI($event)"
          @terminate-campaign="callAPI($event)"
        >
        </campaign>
      </div>
    </div>
    <add-campaign-dialog
      v-if="campaignCreateLinks"
      :link="campaignCreateLinks"
      v-model="addCampaignDialogVisible"
      @add-campaign="callAPI($event)"
      :study-id="account.id"
      :isCampaign="true"
    >
    </add-campaign-dialog>
  </div>
</template>

<script>
import AppIntroduction from "@/components/AppIntroduction";
import Campaign from "@/pages/CampaignManager/Campaign";
import AddCampaignDialog from "@/components/CampaignManager/AddCampaignDialog";
import AddCampaignButton from "@/components/CampaignManager/AddCampaignButton";
import { mapState } from "vuex";

export default {
  components: {
    AppIntroduction,
    Campaign,
    AddCampaignDialog,
    AddCampaignButton,
  },
  props: {
    account: Object,
    currentUser: Object,
  },
  data() {
    return {
      addCampaignDialogVisible: false,
      campaigns: [],
      campaignLinks: null,
      campaignCreateLinks: null,
      isLoading: false,
      isAdmin: false,
      defaultSearchEngine: null,
      netlinkingCampaignsModule: null,
    };
  },
  methods: {
    showAddCampaignDialog() {
      this.addCampaignDialogVisible = true;
    },
    fetchCampaigns() {
      this.isLoading = true;
      const list = this.campaignLinks.list;
      this.campaigns = [];
      this.$api[list.meta.method.toLowerCase()](list.href)
        .then((response) => {
          this.campaigns = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    setConfigurations() {
      this.netlinkingCampaignsModule = this.studyLinks["netlinkingCampaigns"];
      this.defaultSearchEngine = this.study["searchEngines"].find(
        (searchEngine) => searchEngine.id == this.study["defaultSearchEngineId"]
      );
      this.callNetlinkingCampaignsModuleAPI();
    },
    callAPI(details) {
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.campaign
      )
        .then((response) => {
          this.fetchCampaigns();
        })
        .catch((error) => {
          if (error.response.data.hasOwnProperty("errors")) {
            const missingFieldError = error.response.data.errors.find(
              (error) => error.code == "MISSING_FIELDS"
            );

            if (missingFieldError && missingFieldError.meta) {
              let htmlMessageMissingFields =
                "<p>" + this.$i18n.t("missingFields") + " :" + "</p>" + "<ul>";
              missingFieldError.meta.forEach((field) => {
                htmlMessageMissingFields =
                  htmlMessageMissingFields +
                  "<li>" +
                  this.$i18n.t(field) +
                  "</li>";
              });
              htmlMessageMissingFields = htmlMessageMissingFields + "</ul>";

              this.$alert(
                htmlMessageMissingFields,
                this.$i18n.t("missingFieldsTitle"),
                {
                  confirmButtonText: "OK",
                  dangerouslyUseHTMLString: true,
                  type: "error",
                }
              );
            }
          }
          console.log(error);
        });
    },
    callNetlinkingCampaignsModuleAPI() {
      let module = this.netlinkingCampaignsModule;
      this.$api[module.method.toLowerCase()](module.href, {
        headers: {
          Accept: "application/vnd.api+json",
          "Accept-Language": this.$i18n.locale,
        },
      })
        .then((response) => {
          this.campaignLinks = response.data.data.attributes;
          this.mapToCampaignCreateLinks(this.campaignLinks.create);
          this.fetchCampaigns();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    mapToCampaignCreateLinks(createLink) {
      this.campaignCreateLinks = {
        form: createLink.meta.form,
        method: createLink.meta.method,
        href: createLink.href,
      };
    },
  },
  created() {
    this.setConfigurations();
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
  },
};
</script>
<style lang="scss" scoped>
.container {
  .campaigns {
    min-height: 100px;

    .campaign {
      margin-bottom: 1rem;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "campaignManager": "Netlinking campaign manager",
    "missingFields": "Thoses field are required",
    "missingFieldsTitle": "There are empty fields",
    "name": "Name",
    "companyName": "Company Name",
    "activitySector": "Theme",
    "companyDescription": "Company Description",
    "highlights": "Values to highlight",
    "links": "Additional Links (image, vidéo, etc)",
    "status": "Status",
    "budget": "Budget",
    "description": "Description",
    "period": "Period",
    "budgetWithoutMargin": "Budget without margin",
    "backlinkTypes": "Backlink types",
    "understandCampaignManagerLink": "http://docs.smartkeyword.io/fr/articles/5126760-comprendre-le-campaign-manager",
    "understandCampaignManagerDescription": "Know more about our backlinks quality"
  },
  "fr": {
    "campaignManager": "Gestion de campagne netlinking",
    "missingFields": "Ces champs sont obligatoires",
    "missingFieldsTitle": "Certains champs ne sont pas renseignés",
    "name": "Nom",
    "companyName": "Nom de l'entreprise",
    "activitySector": "Thématique",
    "companyDescription": "Description de l'entreprise",
    "highlights": "Proposition de valeurs",
    "links": "Liens complémentaires (image, vidéo, etc)",
    "status": "Statut",
    "budget": "Budget",
    "description": "Description",
    "period": "Période",
    "budgetWithoutMargin": "Budget sans les marges",
    "backlinkTypes": "Types de backlinks",
    "understandCampaignManagerLink": "http://docs.smartkeyword.io/fr/articles/5126760-comprendre-le-campaign-manager",
    "understandCampaignManagerDescription": "Comprendre le Campaign Manager"
  },
  "de": {
    "campaignManager": "Verwaltung von Netlinking-Kampagnen",
    "missingFields": "Diese Felder sind erforderlich",
    "missingFieldsTitle": "Einige Felder sind nicht ausgefüllt",
    "name": "Name",
    "companyName": "Name der Firma",
    "activitySector": "Thematisch",
    "companyDescription": "Firmen Beschreibung",
    "highlights": "Wertversprechen",
    "links": "Weiterführende Links (Bild, Video etc.)",
    "status": "Status",
    "budget": "Budget",
    "description": "Beschreibung",
    "period": "Zeitraum",
    "budgetWithoutMargin": "Budget ohne Margen",
    "backlinkTypes": "Arten von Backlinks",
    "understandCampaignManagerLink": "http://docs.smartkeyword.io/fr/articles/5126760-comprendre-le-campaign-manager",
    "understandCampaignManagerDescription": "Campaign Manager verstehen"
  }
}
</i18n>
