<template>
  <div>
    <!-- <div class="title">
			{{ $t("new_lost_backlinks")}}
		</div> -->
    <div>
      <highcharts
        ref="highchart"
        :options="chartOptions"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import { epochDayToDateTime } from "@/javascripts/dateHelpers";

export default {
  props: {
    data: Object,
    dates: Array,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 300,
          zoomType: "x",
          type: "column",
        },
        title: {
          text: "",
          useHTML: true,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          title: {
            text: this.$i18n.t("number_of_backlinks"),
          },
        },
        tooltip: {
          pointFormatter: function () {
            return this.series.name + " : " + "<b>" + this.y + "</b>";
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      if (this.data && this.data.backlinksNewLostCount.length > 0) {
        this.chartOptions.series = [];
        const newBacklinksData = [];
        const lostBacklinksData = [];

        this.data.backlinksNewLostCount.forEach((element) => {
          const date = new Date(element.date);
          if (date >= this.dates[0] && date <= this.dates[1]) {
            newBacklinksData.push({
              x: date.getTime(),
              y: element.newCount,
            });

            lostBacklinksData.push({
              x: date.getTime(),
              y: 0 - element.lostCount,
            });
          }
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("new"),
          data: newBacklinksData,
          color: "#28a745",
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("lost"),
          data: lostBacklinksData,
          color: "#dc3545",
        });
      } else {
        this.chartOptions.series = [];
      }
    },
  },
  mounted() {
    this.updateSeries();
  },
  watch: {
    data() {
      this.updateSeries();
    },
    dates() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>

<i18n>
{
  "en": {
    "new_lost_backlinks": "New and Lost Backlinks",
    "number_of_backlinks": "Number of backlinks",
    "new": "New backlinks",
    "lost": "Lost backlinks"
  },
  "fr": {
    "new_lost_backlinks": "Suivi des Backlinks",
    "number_of_backlinks": "Nombre de backlinks",
    "new": "Nouveaux",
    "lost": "Perdus"
  },
  "de": {
    "new_lost_backlinks": "Suivi des Backlinks",
    "number_of_backlinks": "Backlink-Name",
    "new": "Neu",
    "lost": "Verloren"
  }
}
</i18n>
