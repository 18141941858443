<template>
  <div v-loading="isLoading">
    <el-form
      v-if="!isLoading"
      :model="form"
      label-width="200px"
    >
      <el-form-item :label="$t('sellsy_company_name')">
        <el-select
          v-model="form.sellsyThirdId"
          filterable
          clearable
          :placeholder="$t('select_sellsy_company')"
        >
          <el-option
            v-for="item in sellsyCompaniesOptions"
            :key="item.thirdid"
            :label="item.name"
            :value="item.thirdid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('company_group')">
        <el-select
          v-model="form.companyGroupId"
          filterable
          clearable
          :placeholder="$t('select_company_group')"
        >
          <el-option
            v-for="item in companyGroupOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('name')">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('category')">
        <el-select
          v-model="form.companySetting.companyCategory"
          placeholder="Select"
        >
          <el-option
            v-for="companyCategoryOption in companyCategoryOptions"
            :key="companyCategoryOption.value"
            :label="companyCategoryOption.label"
            :value="companyCategoryOption.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('health_tracking_mode')">
        <el-select v-model="form.companySetting.healthTrackingMode">
          <el-option
            v-for="healthTrackingModeOption in healthTrackingModeOptions"
            :key="healthTrackingModeOption.value"
            :label="healthTrackingModeOption.label"
            :value="healthTrackingModeOption.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="'Account Managers'">
        <el-select
          v-model="form.companySetting.accountManagers"
          multiple
          placeholder="Select"
        >
          <el-option
            v-for="accountManager in accountManagerOptions"
            :key="accountManager.id"
            :label="accountManager.firstName + ' ' + accountManager.lastName"
            :value="accountManager.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('crawler_max_urls')">
        <el-input-number
          v-model="form.maxUrlsToCrawl"
          :min="1"
        ></el-input-number>
      </el-form-item>

      <el-form-item :label="$t('max_users')">
        <el-input-number
          v-model="form.usersAllowedCount"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('max_keywords')">
        <el-input-number
          v-model="form.companySetting.keywordsLimit"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('max_competitors')">
        <el-input-number
          v-model="form.companySetting.competitorsLimit"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('status')">
        <el-select
          v-model="form.status"
          :placeholder="$t('select_status')"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <h5>{{ $t("alertingMarketplace") }}</h5>
      <el-form-item>
        <div class="alerting">
          <el-switch v-model="form.autoChangeStatus"></el-switch>
          <p>{{ $t("autoChangeStatus") }}</p>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="alerting">
          <el-switch v-model="form.autoAlerting"></el-switch>
          <p>{{ $t("autoAlerting") }}</p>
        </div>
      </el-form-item>
      <el-form-item :label="$t('subscription_frequency')">
        <el-input-number
          v-model="form.companySetting.subscriptionFrequency"
          :min="1"
          :max="12"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('subscription_days')">
        <el-input-number
          :min="0"
          :step="0.5"
          v-model="form.companySetting.consultingDaysPerMonth"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('subscription_amount')">
        <el-input-number
          :min="0"
          :step="100"
          v-model="form.companySetting.subscriptionAmount"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('subscription_period')">
        <el-date-picker
          v-model="period"
          type="daterange"
          :range-separator="$t('to')"
          :format="datePickerFormat"
          valueFormat="yyyy-MM-dd"
          align="center"
          :start-placeholder="$t('start_date')"
          :end-placeholder="$t('end_date')"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('last_contacted_date')">
        <el-date-picker
          v-model="lastContactedDate"
          type="date"
          :format="datePickerFormat"
          valueFormat="yyyy-MM-dd"
          align="center"
          :placeholder="$t('last_contacted_date')"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('comment') + ' (Intercom)'">
        <el-input
          type="textarea"
          v-model="form.companySetting.comment"
        ></el-input>
      </el-form-item>
      <el-form-item :label="'Information (SmartKeyword)'">
        <el-input
          type="textarea"
          v-model="form.companySetting.information"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button
        plain
        @click="closeDialog"
        >{{ $t("cancel") }}</el-button
      >
      <el-button
        plain
        type="primary"
        @click="submitForm"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { RepositoryFactory } from "@/services/repositoryFactory";
const CompanyGroupsRepository = RepositoryFactory.get("companyGroups");
const CompaniesRepository = RepositoryFactory.get("companies");
const UsersRepository = RepositoryFactory.get("users");
const SellsyRepository = RepositoryFactory.get("sellsyRepository");

export default {
  props: {
    companyId: Number,
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      period: [],
      lastContactedDate: null,
      form: {
        companyGroupId: null,
        sellsyThirdId: null,
        name: "",
        usersAllowedCount: 0,
        maxUrlsToCrawl:5000,
        status: null,
        autoAlerting: true,
        autoChangeStatus: false,
        companySetting: {
          companyCategory: "",
          healthTrackingMode: "tracking",
          consultingDaysPerMonth: null,
          subscriptionAmount: null,
          subscriptionFrequency: 1,
          subscriptionStartDate: null,
          subscriptionEndDate: null,
          lastContactedDate: null,
          keywordsLimit: null,
          competitorsLimit: null,
          comment: null,
          accountManagers: [],
          information: null,
        },
      },
      companyGroupOptions: [],
      sellsyCompaniesOptions: [],
      statusOptions: [
        { value: "subscribed", label: this.$i18n.t("subscribed") },
        { value: "suspended", label: this.$i18n.t("suspended") },
        { value: "inactive", label: this.$i18n.t("inactive") },
      ],
      companyCategoryOptions: [
        {
          value: "Sans accompagnement",
          label: this.$i18n.t("without_support"),
        },
        { value: "Avec accompagnement", label: this.$i18n.t("with_support") },
        { value: "Agence", label: this.$i18n.t("agency") },
        { value: "Demo", label: this.$i18n.t("demo") },
        { value: "Essai gratuit", label: this.$i18n.t("free_trial") },
        { value: "Partenariat", label: this.$i18n.t("partnership") },
        { value: "Relations", label: this.$i18n.t("relations") },
      ],
      healthTrackingModeOptions: [
        { value: "tracking", label: "Tracking" },
        { value: "no_tracking", label: "No tracking" },
      ],
      accountManagerOptions: [],
      isLoading: false,
    };
  },
  computed: {
    datePickerFormat() {
      return this.$options.filters.formatDatePicker();
    },
  },
  methods: {
    submitForm() {
      this.closeDialog();
      if (this.form.companySetting.healthTrackingMode == null) {
        this.form.companySetting.healthTrackingMode = "tracking";
      }
      this.form.companySetting.lastContactedDate = DateTime.fromISO(
        this.lastContactedDate,
        { zone: "utc" }
      ).toRFC2822();
      this.form.companySetting.subscriptionStartDate = DateTime.fromISO(
        this.period[0],
        { zone: "utc" }
      ).toRFC2822();
      this.form.companySetting.subscriptionEndDate = DateTime.fromISO(
        this.period[1],
        { zone: "utc" }
      ).toRFC2822();

      this.$emit("submit", this.form);
      if (!this.companyId) {
        this.resetForm();
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    resetForm() {
      this.form.companyGroupId = null;
      this.form.sellsyThirdId = null;
      this.form.name = "";
      this.form.usersAllowedCount = 0;
      (this.form.status = null), (this.form.autoAlerting = false);
      this.form.autoChangeStatus = false;
      this.form.companySetting.companyCategory = "";
      this.form.companySetting.healthTrackingMode = "tracking";
      this.form.companySetting.keywordsLimit = null;
      this.form.companySetting.competitorsLimit = null;
      this.form.subscriptionAmount = null;
      this.form.consultingDaysPerMonth = null;
      this.form.subscriptionFrequency = 1;
      this.form.companySetting.subscriptionStartDate = null;
      this.form.companySetting.subscriptionEndDate = null;
      this.form.companySetting.comment = null;
      this.period = [];
      this.lastContactedDate = null;
    },
    async setCompanyGroupOptions() {
      try {
        const data = await CompanyGroupsRepository.getCompanyGroups();
        this.companyGroupOptions = data.data.map((companyGroup) => ({
          label: companyGroup.attributes.name,
          value: parseInt(companyGroup.id),
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async setSellsyCompaniesOptions() {
      try {
        const data = await SellsyRepository.getCompanies();
        this.sellsyCompaniesOptions = Object.values(data.response.result).map(
          (company) => ({
            name: company.fullName,
            thirdid: parseInt(company.id),
          })
        );
      } catch (error) {
        console.error(error);
      }
    },
    async setAccountManagersOptions() {
      try {
        const data = await UsersRepository.getAccountManagers();
        this.accountManagerOptions = data.data.map((user) => {
          return {
            id: parseInt(user.id),
            firstName: user.attributes.firstName,
            lastName: user.attributes.lastName,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    async loadCompany() {
      try {
        this.isLoading = true;
        const [data,] = await Promise.all([
          CompaniesRepository.getCompany(this.companyId),
          this.setCompanyGroupOptions(),
          this.setSellsyCompaniesOptions(),
          this.setAccountManagersOptions(),
        ]);

        const companySettingId = data.data.relationships.companySetting.data.id;
        const companySettingAttributes = data.included.find(
          (companySetting) => companySetting.id === companySettingId
        ).attributes;
        this.form = data.data.attributes;
        this.$set(this.form, "companySetting", {
          ...companySettingAttributes,
          accountManagers: data.data.attributes.accountManagers,
        });
        if (
          companySettingAttributes.subscriptionStartDate &&
          companySettingAttributes.subscriptionEndDate
        ) {
          this.period = [
            companySettingAttributes.subscriptionStartDate,
            companySettingAttributes.subscriptionEndDate,
          ];
        }
        this.lastContactedDate = companySettingAttributes.lastContactedDate;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    if (this.companyId) await this.loadCompany();
    else {
      this.isLoading = true;
      await Promise.all([
        this.setCompanyGroupOptions(),
        this.setSellsyCompaniesOptions(),
        this.setAccountManagersOptions(),
      ]);
      this.isLoading = false;
    }
  },
  watch: {
    companyId() {
      if (this.companyId) this.loadCompany();
    },
  },
};
</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
}

.alerting {
  display: flex;
  height: 40px;
  p {
    width: 350px;
    padding-left: 10px;
  }
}
.el-switch {
  margin-top: 10px;
}
</style>

<i18n>
{
  "en": {
    "crawler_max_urls": "Max urls to crawl",
    "sellsy_company_name": "Sellsy company",
    "period": "Period",
    "to": "To",
    "start_date": "Start date",
    "end_date": "End date",
    "company_group": "Company group",
    "select_sellsy_company": "Select sellsy company",
    "select_company_group": "Select company group",
    "name": "Name",
    "category": "Category",
    "health_tracking_mode": "Health tracking mode",
    "max_users": "Max users",
    "max_keywords": "Max keywords",
    "max_competitors": "Max competitors",
    "status": "Status",
    "alertingMarketplace": "Alerting marketplace",
    "autoAlerting": "Alerting activation",
    "autoChangeStatus": "Automatic validation activation",
    "select_status": "Select status",
    "subscribed": "Subscribed",
    "inactive": "Inactive",
    "suspended": "Suspended",
    "subscription_frequency": "Payment freq.",
    "subscription_amount": "Subscription amount",
    "subscription_period": "Subscription period",
    "subscription_days": "Nb days / month",
    "last_contacted_date": "Last contacted date",
    "comment": "Comment",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "without_support": "Without support",
    "with_support": "With support",
    "agency": "Agency",
    "demo": "Demonstration",
    "free_trial": "Free trial",
    "partnership": "Partnership",
    "relations": "Relations"
  },
  "fr": {
    "crawler_max_urls": "Max URLs à crawler",
    "sellsy_company_name": "Société sellsy",
    "period": "Période",
    "to": "à",
    "start_date": "Date de début",
    "end_date": "Date de fin",
    "company_group": "Groupe de sociétés",
    "select_sellsy_company": "Sélectionnez la société sellsy",
    "select_company_group": "Sélectionnez le groupe de sociétés",
    "name": "Nom",
    "category": "Catégorie",
    "health_tracking_mode": "Tracking santé client",
    "max_users": "Max utilisateurs",
    "max_keywords": "Max mots clés",
    "max_competitors": "Max concurrents",
    "status": "Statut",
    "alertingMarketplace": "Alertes marketplace",
    "autoAlerting": "Activation des alertes",
    "autoChangeStatus": "Activation validation automatique",
    "select_status": "Sélectionnez le statut",
    "subscribed": "Souscrit",
    "inactive": "Inactif",
    "suspended": "Suspendu",
    "subscription_frequency": "Freq. de paiements",
    "subscription_amount": "Montant de l'abonnement",
    "subscription_period": "Période de l'abonnement",
    "subscription_days": "Nb jours/mois",
    "last_contacted_date": "Dernière date de contact",
    "comment": "Commentaire",
    "cancel": "Annuler",
    "confirm": "Confirmer",
    "without_support": "Sans accompagnement",
    "with_support": "Avec accompagnement",
    "agency": "Agence",
    "demo": "Démo",
    "free_trial": "Période d'essai",
    "partnership": "Partenariat",
    "relations": "Relations"
  },
  "de": {
    "crawler_max_urls":"Maximale Anzahl von URLs zum Crawlen",
    "sellsy_company_name": "selly unternehmen",
    "period": "Zeitraum",
    "to": "Zu",
    "start_date": "Startdatum",
    "end_date": "Endtermin",
    "company_group": "Gruppe von Firmen",
    "select_sellsy_company": "Wählen Sie das Unternehmen sellsy aus",
    "select_company_group": "Wählen Sie die Unternehmensgruppe aus",
    "name": "Name",
    "category": "Kategorie",
    "health_tracking_mode": "Verfolgung der Kundengesundheit",
    "max_users": "Maximale Benutzer",
    "max_keywords": "Maximale Schlüsselwörter",
    "max_competitors": "Maximale Konkurrenten",
    "status": "Status",
    "alertingMarketplace": "Marktplatz-Benachrichtigungen",
    "autoAlerting": "Warnungen aktivieren",
    "autoChangeStatus": "Aktivierung der automatischen Validierung",
    "select_status": "Status auswählen",
    "subscribed": "Gezeichnet",
    "inactive": "Leerlauf",
    "suspended": "Ausgesetzt",
    "subscription_frequency": "Freq. Zahlungen",
    "subscription_amount": "Zeichnungsbetrag",
    "subscription_period": "Abonnementzeitraum",
    "subscription_days": "Nb Tage/Monat",
    "last_contacted_date": "Letztes Kontaktdatum",
    "comment": "Kommentar",
    "cancel": "Stornieren",
    "confirm": "Bestätigen",
    "without_support": "Ohne Begleitung",
    "with_support": "Mit Begleitung",
    "agency": "Agentur",
    "demo": "Demo",
    "free_trial": "Probezeit",
    "partnership": "Partnerschaft",
    "relations": "Beziehungen"
  }
}
</i18n>
