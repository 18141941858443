<template>
  <div>
    <el-input
      v-model="sourceDomain"
      v-on:keyup.enter.stop.prevent.native="fetchMetrics(true)"
      @input="debounceHandle()"
    >
    </el-input>
    <div>
      <span
        class="error"
        v-if="isBadUrl"
      >
        {{ $t("badlyFormattedUrl") }}
      </span>
      <span
        class="error"
        v-else-if="fetchError"
      >
        {{ $t("errorFetchingMetrics") }}
      </span>
      <div
        class="metrics"
        v-else-if="data"
        v-loading="isLoading"
      >
        <span v-if="sourceDomainAvailability">
          {{ sourceDomainAvailability.warningMessage
          }}<span class="source-orange">{{
            sourceDomainAvailability.statusMessage
          }}</span>
        </span>
        <el-table
          :data="[data.metrics]"
          style="width: 100%"
        >
          <el-table-column
            prop="trustFlow"
            :label="$t('trustFlow')"
          >
          </el-table-column>
          <el-table-column
            prop="ratioTfCf"
            :label="$t('ratioTFCF')"
          >
          </el-table-column>
          <el-table-column
            prop="organicTraffic"
            :label="$t('traffic')"
            :formatter="trafficFormatter"
          >
          </el-table-column>
          <el-table-column
            prop="referringDomainsWithEquity"
            :label="$t('referringDomains')"
            :formatter="referringDomainsFormatter"
          >
          </el-table-column>
          <el-table-column
            prop="domainAuthority"
            :label="$t('domainAuthority')"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDateWithDashes } from "@/javascripts/formatDate";
import {DateTime} from 'luxon';
export default {
  props: {
    value: String,
    defaultSearchEngine: Object,
  },
  data() {
    return {
      prefix: "https://",
      data: null,
      fetchError: false,
      isBadUrl: false,
      isLoading: false,
      sourceDomainAvailability: null,
    };
  },
  computed: {
    sourceDomain: {
      get() {
        return this.value;
      },
      set(sourceDomain) {
        this.$emit("input", sourceDomain);
      },
    },
    ...mapState(["study", "userLocale"]),
  },
  methods: {
    debounceHandle() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.fetchMetrics(true);
      }, 1000); // delay
    },
    checkSourceDomainAvailability() {
      this.$api
        .post(
          `/studies/${this.study.id}/backlinks/source-domain-availability`,
          { sourceDomain: this.sourceDomain },
          { headers: { "accept-language": this.userLocale } }
        )
        .then((response) => {
          this.sourceDomainAvailability = {
            statusMessage: response.data.data.attributes.statusMessage,
            warningMessage: response.data.data.attributes.warningMessage,
          };
        })
        .catch((error) => {
          this.fetchError = true;
          this.isLoading = false;
          console.error(error);
        });
    },
    fetchMetrics(shouldEmitMetrics) {
      const inputUrl = this.sourceDomain
        .replace("https://", "")
        .replace("http://", "");
      if (this.checkIfInputIsUrl(this.prefix + inputUrl)) {
        this.isBadUrl = false;
        this.$emit("isDomainSourceCorrectlyFormatted", !this.isBadUrl);
        this.isLoading = true;
        let requestUrlMetrics = {
          urls: [{ url: this.prefix + inputUrl, type: "DOMAIN" }],
          minDate: lastMonthDate(),
          isoCountryCode: this.defaultSearchEngine.isoCountryCode,
        };
        this.$api
          .post(`/netlinking/urls/metrics`, { ...requestUrlMetrics })
          .then((response) => {
            this.fetchError = false;
            this.isLoading = false;
            this.data = response.data.data[0];
            this.data.metrics.ratioTfCf =
              Math.round(
                (this.data.metrics.trustFlow / this.data.metrics.citationFlow) *
                  100
              ) / 100;
            if (shouldEmitMetrics) {
              this.$emit("sourceDomainMetrics", this.data);
              this.checkSourceDomainAvailability();
            }
          })
          .catch((error) => {
            this.fetchError = true;
            this.isLoading = false;
            console.error(error);
          });
      } else {
        this.isBadUrl = true;
        this.$emit("isDomainSourceCorrectlyFormatted", !this.isBadUrl);
      }
    },
    checkIfInputIsUrl(input) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(input);
    },
    trafficFormatter(row, column) {
      return this.formatNumber(row.organicTraffic);
    },
    referringDomainsFormatter(row, column) {
      return this.formatNumber(row.referringDomainsWithEquity);
    },
    formatNumber(number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
  mounted() {
    this.fetchMetrics(false);
  },
};
function lastMonthDate() {
  const date = DateTime.utc().minus({ month: 1 });
  return formatDateWithDashes(date);
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-group__prepend {
  background-color: #3c54a5;
  color: #fff;
}
.error {
  color: red;
}

.source-orange {
  color: rgb(241, 97, 8);
  font-weight: bold;
}
</style>

<i18n>
{
  "en": {
    "apply": "Apply",
    "badlyFormattedUrl": "The url you typed is not in the correct format",
    "errorFetchingMetrics": "We encountered an error while fetching metrics",
    "trustFlow": "Trust Flow",
    "ratioTFCF": "TF/CF",
    "traffic": "Traffic",
    "referringDomains": "Referring domains",
    "domainAuthority": "Domain authority"
  },
  "fr": {
    "apply": "Appliquer",
    "badlyFormattedUrl": "L'url que vous avez rentrée n'est pas au bon format",
    "errorFetchingMetrics": "Nous avons rencontré une erreur lors du chargement des données",
    "trustFlow": "Trust Flow",
    "ratioTFCF": "TF/CF",
    "traffic": "Trafic",
    "referringDomains": "Domaines reférents",
    "domainAuthority": "Domain authority"
  },
  "de": {
    "apply": "Anwenden",
    "badlyFormattedUrl": "Die eingegebene URL hat nicht das richtige Format.",
    "errorFetchingMetrics": "Beim Laden der Daten ist ein Fehler aufgetreten",
    "trustFlow": "Vertrauensfluss",
    "ratioTFCF": "TF/CF",
    "traffic": "Verkehr",
    "referringDomains": "Verweisende Domains",
    "domainAuthority": "Domänenautorität"
  }
}
</i18n>
