<template>
  <div id="tipTapEditor">
    <div
      v-if="editor && isEditable && isEditing"
      class="menu-bar"
    >
      <el-tooltip
        :content="$t('bold')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <i class="fas fa-bold"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('italic')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <i class="fas fa-italic"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('strike')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <i class="fas fa-strikethrough"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('underline')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <i class="fas fa-underline"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('code')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('code') }"
          @click="editor.chain().focus().toggleCode().run()"
        >
          <i class="fas fa-code"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('addLink')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('link') }"
          @click="promptAddLink()"
        >
          <i class="fas fa-link"></i>
        </button>
      </el-tooltip>
      <el-divider direction="vertical" />
      <button
        class="menu-button"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        @click="editor.chain().focus().setParagraph().run()"
      >
        <i class="fas fa-paragraph"></i>
      </button>
      <button
        class="menu-button"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      >
        H1
      </button>
      <button
        class="menu-button"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      >
        H2
      </button>
      <button
        class="menu-button"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
      >
        H3
      </button>
      <button
        class="menu-button"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
      >
        H4
      </button>
      <el-divider direction="vertical" />
      <el-tooltip
        :content="$t('alignLeft')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          @click="editor.chain().focus().setTextAlign('left').run()"
        >
          <i class="fas fa-align-left"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('alignCenter')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          @click="editor.chain().focus().setTextAlign('center').run()"
        >
          <i class="fas fa-align-center"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('alignRight')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          @click="editor.chain().focus().setTextAlign('right').run()"
        >
          <i class="fas fa-align-right"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('alignJustify')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
          @click="editor.chain().focus().setTextAlign('justify').run()"
        >
          <i class="fas fa-align-justify"></i>
        </button>
      </el-tooltip>
      <el-divider direction="vertical" />
      <el-tooltip
        :content="$t('bulletList')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          <i class="fas fa-list"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('orderedList')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <i class="fas fa-list-ol"></i>
        </button>
      </el-tooltip>
      <el-tooltip
        :content="$t('todoList')"
        placement="top"
      >
        <button
          class="menu-button"
          :class="{ 'is-active': editor.isActive('taskList') }"
          @click="editor.chain().focus().toggleTaskList().run()"
        >
          <i class="fas fa-tasks"></i>
        </button>
      </el-tooltip>
      <el-divider direction="vertical" />
      <el-tooltip
        :content="$t('createTable')"
        placement="top"
      >
        <button
          class="menu-button"
          @click="
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          "
        >
          <AddTable />
        </button>
      </el-tooltip>
      <el-tooltip
        v-if="editor.can().addColumnBefore()"
        :content="$t('addColumnBefore')"
        placement="top"
      >
        <button
          class="menu-button"
          @click="editor.chain().focus().addColumnBefore().run()"
        >
          <AddColumnBefore />
        </button>
      </el-tooltip>
      <el-tooltip
        v-if="editor.can().addColumnAfter()"
        :content="$t('addColumnAfter')"
        placement="top"
      >
        <button
          class="menu-button"
          @click="editor.chain().focus().addColumnAfter().run()"
        >
          <AddColumnAfter />
        </button>
      </el-tooltip>
      <el-tooltip
        v-if="editor.can().deleteColumn()"
        :content="$t('deleteColumn')"
        placement="top"
      >
        <button
          class="menu-button"
          @click="editor.chain().focus().deleteColumn().run()"
        >
          <RemoveColumn />
        </button>
      </el-tooltip>
      <el-tooltip
        v-if="editor.can().addRowBefore()"
        :content="$t('addRowBefore')"
        placement="top"
      >
        <button
          class="menu-button"
          @click="editor.chain().focus().addRowBefore().run()"
        >
          <AddRowBefore />
        </button>
      </el-tooltip>
      <el-tooltip
        v-if="editor.can().addRowAfter()"
        :content="$t('addRowAfter')"
        placement="top"
      >
        <button
          class="menu-button"
          @click="editor.chain().focus().addRowAfter().run()"
        >
          <AddRowAfter />
        </button>
      </el-tooltip>
      <el-tooltip
        v-if="editor.can().deleteRow()"
        :content="$t('deleteRow')"
        placement="top"
      >
        <button
          class="menu-button"
          @click="editor.chain().focus().deleteRow().run()"
        >
          <RemoveRow />
        </button>
      </el-tooltip>
      <el-tooltip
        v-if="editor.can().deleteTable()"
        :content="$t('deleteTable')"
        placement="top"
      >
        <button
          class="menu-button"
          @click="editor.chain().focus().deleteTable().run()"
        >
          <RemoveTable />
        </button>
      </el-tooltip>
      <el-divider direction="vertical" />
      <button
        class="menu-button"
        @click="editor.chain().focus().undo().run()"
      >
        <i class="fas fa-undo"></i>
      </button>
      <button
        class="menu-button"
        @click="editor.chain().focus().redo().run()"
      >
        <i class="fas fa-redo"></i>
      </button>
      <el-divider></el-divider>
    </div>
    <bubble-menu
      v-if="editor && isEditable && isEditing"
      class="bubble-menu"
      :tippy-options="{ duration: 100, placement: 'top' }"
      :editor="editor"
    >
      <button
        :class="{ 'is-active': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <i class="fas fa-bold"></i>
      </button>
      <button
        :class="{ 'is-active': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <i class="fas fa-italic"></i>
      </button>
      <button
        :class="{ 'is-active': editor.isActive('strike') }"
        @click="editor.chain().focus().toggleStrike().run()"
      >
        <i class="fas fa-strikethrough"></i>
      </button>
    </bubble-menu>

    <floating-menu
      v-if="editor && isEditable && isEditing"
      class="floating-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
    >
      <button
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      >
        H1
      </button>
      <button
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      >
        H2
      </button>
      <button
        :class="{ 'is-active': editor.isActive('bulletList') }"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <i class="fas fa-list"></i>
      </button>
      <button
        :class="{ 'is-active': editor.isActive('link') }"
        @click="promptAddLink()"
      >
        <i class="fas fa-link"></i>
      </button>
    </floating-menu>

    <editor-content
      id="editorContent"
      :style="'height:' + height + 'px; overflow-y: scroll; word-break: break-word'"
      :editor="editor"
    />

    <el-dialog
      :title="$t('addUrl')"
      :visible.sync="addLinkDialogVisible"
      width="30%"
      append-to-body
    >
      <el-form>
        <el-form-item>
          <el-input
            v-model="link.text"
            :placeholder="$t('text')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="link.href"
            :placeholder="$t('href')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addLinkDialogVisible = false">{{
          $t("cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="addLink()"
          >{{ $t("confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <div
      v-if="threadsEndpoint"
      class="commentBubble"
    >
      <comment-component
        :is-public="isPublic"
        :thread="selectedThread"
        :open-bubble="openBubble"
        :api-call-when-not-public="apiCallWhenNotPublic"
        @delete-thread="deleteThread()"
        @color-selection="colorSelection()"
        @save-thread="createThread($event)"
      >
      </comment-component>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from "@tiptap/extension-table-cell";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import { Color } from "@tiptap/extension-color";
import CommentView from "./TipTapExtension/CommentExtension.js";
import highlightSelection from "./TipTapExtension/HighlightSelectionExtension.js";

import AddColumnAfter from "vue-material-design-icons/TableColumnPlusAfter.vue";
import AddColumnBefore from "vue-material-design-icons/TableColumnPlusBefore.vue";
import RemoveColumn from "vue-material-design-icons/TableColumnRemove.vue";
import AddTable from "vue-material-design-icons/TablePlus.vue";
import RemoveTable from "vue-material-design-icons/TableRemove.vue";
import RemoveRow from "vue-material-design-icons/TableRowRemove.vue";
import AddRowBefore from "vue-material-design-icons/TableRowPlusBefore.vue";
import AddRowAfter from "vue-material-design-icons/TableRowPlusAfter.vue";
import CommentComponent from "@/components/TipTapExtension/Comment";
import BubbleMenuExtension from "@tiptap/extension-bubble-menu";
import axios from "axios";
import avoidDoubleBrOnPaste from "./TipTapExtension/DoubleBrExtension";

export default {
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    AddColumnAfter,
    AddColumnBefore,
    RemoveColumn,
    AddTable,
    RemoveTable,
    RemoveRow,
    AddRowBefore,
    AddRowAfter,
    CommentComponent,
  },
  props: {
    value: String,
    radioContentToDisplay: Object,
    generateContent: String,
    height: Number,
    isEditable: Boolean,
    isEditing: {
      default: true,
      type: Boolean,
    },
    isPublic: Boolean,
    threadsEndpoint: Object,
    isShowHtmlView: Boolean
  },
  data() {
    return {
      editor: null,
      textColor: "#000000",
      addLinkDialogVisible: false,
      link: {
        text: null,
        href: null,
      },
      isTextSelected: false,
      threads: [],
      selectedThread: null,
      openBubble: false,
      isLoadingBubbleMenu: false,
      listCommentThreads: null,
      createCommentThread: null,
    };
  },
  computed: {
    editorHeight() {
      return {
        height: this.height,
      };
    },
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
    isEditable(value) {
      if(this.isShowHtmlView)
        this.editor.setOptions({ editable: false });
      else 
        this.editor.setOptions({ editable: value });
    },
    threadsEndpoint(newVal) {
      if (newVal.createCommentThread && newVal.listCommentThreads) {
        this.listCommentThreads = this.threadsEndpoint.listCommentThreads
          ? this.threadsEndpoint.listCommentThreads
          : null;
        this.createCommentThread = this.threadsEndpoint.createCommentThread
          ? this.threadsEndpoint.createCommentThread
          : null;
        this.getThreads();
        this.editor.commands.enableThreads({ enable: true });
      } else this.editor.commands.enableThreads({ enable: false });
    },
    radioContentToDisplay(newValue) {
      if (newValue.key) this.editor.commands.insertContent(newValue.value);
      else {
        for (let inc = newValue.value.length; inc >= 0; inc--) {
          this.editor.commands.insertContentAt(0, newValue.value[inc]);
        }
      }
    },
    generateContent(value) {
      this.editor.commands.clearContent();
      this.editor.commands.insertContent(value);
    },
    isShowHtmlView(value){
        this.showHtmlContent(value);
    }
  },
  mounted() {
    if (this.threadsEndpoint) {
      this.listCommentThreads = this.threadsEndpoint.listCommentThreads
        ? this.threadsEndpoint.listCommentThreads
        : null;
      this.createCommentThread = this.threadsEndpoint.createCommentThread
        ? this.threadsEndpoint.createCommentThread
        : null;
    }
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Underline,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        TextStyle,
        Color,
        Link.configure({
          HTMLAttributes: {
            rel: "noopener",
          },
        }),

        CommentView,
        highlightSelection,
        avoidDoubleBrOnPaste,
        BubbleMenuExtension.configure({
          element: document.querySelector(".commentBubble"),
          pluginKey: "commentBubble",
          editor: this.editor,
          tippyOptions: { duration: 100, placement: "bottom", zIndex: 50 },
          shouldShow: ({ editor }) => {
            return (
              !this.isShowHtmlView &&
              this.isReviewState() &&
              ((this.openBubble &&
                !!this.editor.state.selection.content().size) ||
                editor.isActive("commentMark"))
            );
          },
        }),
      ],
      content: this.value,
      onUpdate: ({ transaction }) => {
        if (!transaction.meta.thread)
          this.$emit("input", this.editor.getHTML());
      },
      onSelectionUpdate: () => {
        if (this.isReviewState()) {
          this.openSelectedThreadBubble();
          this.getSelectedThread();
        }
      },
      onCreate: () => {
        if (this.isReviewState()) {
          this.editor.commands.enableThreads({ enable: true });
          this.getThreads();
        } else this.editor.commands.enableThreads({ enable: false });
      },
      editable: this.isEditable,
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    /** to format html in editor */
    format(html) {
      let tab = '\t';
      let result = '';
      let indent= '';
      let cleanedUpHtml = html.replace(/<span data-comment-id[^>]*>|<\/span>/g, '')
                              .replace(/<span style[^>]*>|<\/span>/g, "")
                              .replace(/ style="[^>]*"/g, "");

      cleanedUpHtml.split(/>\s*</).forEach(function(element) {
        if (element.match( /^\/\w/ )) {
            indent = indent.substring(tab.length);
        }
        result += indent + '<' + element + '>\r\n';
        if (element.match( /^<?\w[^>]*[^\/]$/ ) && !element.startsWith("input")  ) { 
            indent += tab;              
        }
    });
      return result.substring(1, result.length-3);
    },
    showHtmlContent(isShowContentvalue){
        this.editor.setOptions({
            editable: !isShowContentvalue
            });
        if(isShowContentvalue){
            let htmlContent = {
                "type": "doc",
                "content": [
                    {
                    "type": "paragraph",
                    "content": [
                        {
                        "type": "text",
                        "text": this.format(this.editor.getHTML())
                        }
                    ]
                    }
                ]
            };
            this.editor.commands.setContent(htmlContent);
        }else {
            this.editor.commands.setContent(this.value);
        }
    },
    promptAddLink() {
      this.addLinkDialogVisible = true;
    },
    addLink() {
      this.addLinkDialogVisible = false;

      this.editor.commands.insertContent(
        '<a href="' + this.link.href + '">' + this.link.text + "</a>"
      );
      this.link = {
        href: null,
        text: null,
      };
    },
    createThread(data) {
      const { createComment, toggleLoading } = data;
      toggleLoading(true);
      this.$api[this.createCommentThread.method.toLowerCase()](
        this.createCommentThread.href
      ).then(async (response) => {
        try {
          let thread = response.data;
          this.editor.commands.insertThread({
            commentId: thread.data.id,
            color: "chartreuse",
          });
          createComment(thread.links);
          this.threads.push(thread);
          this.selectedThread = thread;
          this.$emit("createOrDeleteThread", true);
          toggleLoading(false);
        } catch (error) {
          console.error(error);
          toggleLoading(false);
        }
      });
    },
    colorSelection() {
      this.editor.commands.insertHighlightSelection({ color: "LightGrey" });
    },
    deleteThread() {
      const deleteAction = this.selectedThread.links.delete;
      const id = this.selectedThread.data.id;
      this.$api[deleteAction.meta.method.toLowerCase()](deleteAction.href)
        .then(() => {
          this.threads = this.threads.filter((thread) => thread.data.id != id);
          this.selectedThread = null;
          this.editor.commands.deleteThread({ commentID: id });
          this.$emit("createOrDeleteThread", true);
        })
        .catch((error) => console.error(error));
    },
    apiCallWhenNotPublic(method, url, data) {
      let headers = {
        "Content-type": "application/vnd.api+json",
      };
      if (!this.isPublic)
        headers.Authorization =
          "Bearer " +
          JSON.parse(sessionStorage.acccessTokenResponse).access_token;
      return {
        method: method,
        baseURL: "https://" + process.env.API_HOST,
        url: url,
        headers: headers,
        data: data,
      };
    },
    getThreads() {
      axios(
        this.apiCallWhenNotPublic(
          this.listCommentThreads.method.toLowerCase(),
          this.listCommentThreads.href
        )
      )
        .then((response) => {
          this.threads = response.data.data;
        })
        .catch((error) => console.error(error));
    },
    getSelectedThread() {
      if (this.editor.isActive("commentMark")) {
        let threadId = this.editor.getAttributes("commentMark").commentId;
        this.threads.forEach((element) => {
          if (Number(element.data.id) === Number(threadId)) {
            this.selectedThread = element;
            this.openBubble = true;
          }
        });
      } else {
        this.selectedThread = null;
      }
    },
    isReviewState() {
      if (this.threadsEndpoint)
        return (
          !!this.threadsEndpoint.createCommentThread ||
          !!this.threadsEndpoint.listCommentThreads
        );
      else return false;
    },
    openSelectedThreadBubble() {
      this.isTextSelected = !!this.editor.state.selection.content().size;
      if (this.isTextSelected && !this.isPublic) this.openBubble = true;
      else {
        this.openBubble = false;
        this.editor.commands.enableThreads({ enable: true });
      }
    },
  },
};
</script>

<style lang="scss">
.menu-bar {
  margin-bottom: 1rem;

  .el-divider {
    margin: unset;
  }
}

.menu-button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menu-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.menu-button.is-active {
  background-color: rgba(0, 0, 0, 0.1);
}
/* Basic editor styles */
.ProseMirror {
  height: 100%;
  // > * + * {
  //     margin-top: 0.75em;
  // }
  p {
    margin-bottom: 0rem;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    p {
      margin: 0;
    }

    li {
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;

        input {
          font-size: inherit;
          font-family: inherit;
          color: #000;
          margin: 0.1rem;
          border: 1px solid black;
          border-radius: 0.3rem;
          padding: 0.1rem 0.4rem;
          background: white;
          accent-color: black;
        }
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  a:hover {
    cursor: pointer;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.ProseMirror:focus {
  outline: none;
}

.bubble-menu {
  display: flex;
  background-color: #0d0d0d;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #fff;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0d0d0d;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #fff;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

::v-deep .material-design-icon__svg {
  height: 19px;
  width: 22px;
}
</style>

<i18n>
{
  "en": {
    "bold": "Bold",
    "italic": "Italic",
    "underline": "Underline",
    "code": "Code",
    "strike": "Strikethrough",
    "bulletList": "Bulleted list",
    "orderedList": "Ordered list",
    "todoList": "To do list",
    "createTable": "Create a table",
    "deleteTable": "Delete table",
    "addColumnBefore": "Add column before",
    "addColumnAfter": "Add column after",
    "deleteColumn": "Delete column",
    "addRowBefore": "Add row before",
    "addRowAfter": "Add row after",
    "deleteRow": "Delete row",
    "alignLeft": "Align left",
    "alignRight": "Align right",
    "alignCenter": "Center",
    "alignJustify": "Justified",
    "addUrl": "Add URL",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "text": "Text to display",
    "href": "Url"
  },
  "fr": {
    "bold": "Gras",
    "italic": "Italique",
    "underline": "Souligné",
    "code": "Code",
    "strike": "Barré",
    "bulletList": "Bullet liste",
    "orderedList": "Liste ordonnée",
    "todoList": "To do liste",
    "createTable": "Créer une table",
    "deleteTable": "Supprimer la table",
    "addColumnBefore": "Ajouter une colonne avant",
    "addColumnAfter": "Ajouter une colonne après",
    "deleteColumn": "Supprimer la colonne",
    "addRowBefore": "Ajouter une ligne avant",
    "addRowAfter": "Ajouter une ligne après",
    "deleteRow": "Supprimer la ligne",
    "alignLeft": "Aligner à gauche",
    "alignRight": "Aligner à droite",
    "alignCenter": "Centrer horizontalement",
    "alignJustify": "Justifié",
    "addUrl": "Ajouter une URL",
    "confirm": "Confirmer",
    "cancel": "Annuler",
    "text": "Texte à afficher",
    "href": "Url"
  },
  "it": {
    "bold": "Grassetto",
    "italic": "Corsivo",
    "underline": "Sottolineato",
    "code": "Codice",
    "strike": "Strikethrough",
    "bulletList": "Elenco puntato",
    "orderedList": "Lista ordinata",
    "todoList": "Lista delle cose da fare",
    "createTable": "Crea una tabella",
    "deleteTable": "Elimina tabella",
    "addColumnBefore": "Aggiungi colonna prima",
    "addColumnAfter": "Aggiungi colonna dopo",
    "deleteColumn": "Elimina colonna",
    "addRowBefore": "Aggiungi riga prima",
    "addRowAfter": "Aggiungi riga dopo",
    "deleteRow": "Elimina riga",
    "alignLeft": "Allinea a sinistra",
    "alignRight": "Allineatore a destra",
    "alignCenter": "Centra orizzontalmente",
    "alignJustify": "Giustifica",
    "addUrl": "Aggiungi URL",
    "confirm": "Conferma",
    "cancel": "Annulla",
    "text": "Testo da visualizzare",
    "href": "Url"
  },
  "es": {
    "bold": "Negrita",
    "italic": "cursiva",
    "underline": "Subrayado",
    "code": "Código",
    "strike": "Tachado",
    "bulletList": "Lista de viñetas",
    "orderedList": "Lista ordenada",
    "todoList": "Lista de tareas pendientes",
    "createTable": "Crear una tabla",
    "deleteTable": "Borrar tabla",
    "addColumnBefore": "Añadir columna antes",
    "addColumnAfter": "Agregar columna después",
    "deleteColumn": "Borrar columna",
    "addRowBefore": "Agregar fila antes",
    "addRowAfter": "Agregar fila después",
    "deleteRow": "Borrar fila",
    "alignLeft": "Alinear a la izquierda",
    "alignRight": "Alineador derecho",
    "alignCenter": "Centrar horizontalmente",
    "alignJustify": "Justificar",
    "addUrl": "Agregar URL",
    "confirm": "Confirmar",
    "cancel": "Cancelar",
    "text": "Texto a mostrar",
    "href": "URL"
  },
  "de": {
    "bold": "Gras",
    "italic": "Kursivschrift",
    "underline": "Unterstreichen",
    "code": "Code",
    "strike": "Stange",
    "bulletList": "Bullet liste",
    "orderedList": "Bestellliste",
    "todoList": "Löse es",
    "createTable": "Erstellen Sie eine Tabelle",
    "deleteTable": "Tabelle löschen",
    "addColumnBefore": "Fügen Sie vorher eine Spalte hinzu",
    "addColumnAfter": "Spalte danach hinzufügen",
    "deleteColumn": "Spalte löschen",
    "addRowBefore": "Zeile davor hinzufügen",
    "addRowAfter": "Zeile danach hinzufügen",
    "deleteRow": "Zeile löschen",
    "alignLeft": "Linksbündig",
    "alignRight": "Rechts ausrichten",
    "alignCenter": "Waagerecht zentrieren",
    "alignJustify": "Gerechtfertigt",
    "addUrl": "URL hinzufügen",
    "confirm": "Bestätigen",
    "cancel": "Stornieren",
    "text": "Anzuzeigender Text",
    "href": "URL"
  },
  "nl": {
    "bold": "Vet",
    "italic": "Cursief",
    "underline": "Onderstrepen",
    "code": "Code",
    "strike": "Doorhalen",
    "bulletList": "Bulletlijst",
    "orderedList": "Geordende lijst",
    "todoList": "Takenlijst",
    "createTable": "Maak een tabel",
    "deleteTable": "Tabel verwijderen",
    "addColumnBefore": "Kolom toevoegen voor",
    "addColumnAfter": "Kolom toevoegen na",
    "deleteColumn": "Kolom verwijderen",
    "addRowBefore": "Voeg rij toe voor",
    "addRowAfter": "Voeg rij toe na",
    "deleteRow": "Rij verwijderen",
    "alignLeft": "Links uitlijnen",
    "alignRight": "Rechts uitlijnen",
    "alignCenter": "Horizontaal centreren",
    "alignJustify": "Justify",
    "addUrl": "URL toevoegen",
    "confirm": "Bevestigen",
    "cancel": "Annuleren",
    "tekst": "Tekst om weer te geven",
    "href": "Url"
  }
}
</i18n>
