<template>
  <el-input-number
    v-model="buyingPrice"
    v-on:keyup.enter.stop.prevent.native="emitBuyingPrice()"
    @input="emitBuyingPrice()"
    :precision="3"
    :step="0.01"
  >
  </el-input-number>
</template>

<script>
export default {
  props: {
    value: Number,
  },
  computed: {
    buyingPrice: {
      get() {
        return this.value;
      },
      set(buyingPrice) {
        this.$emit("input", buyingPrice);
      },
    },
  },
  methods: {
    emitBuyingPrice() {
      this.$emit("buyingPrice", this.buyingPrice);
    },
  },
};
</script>

<style lang="scss" scoped></style>
