import Repository from "@/services/repository";

export default {
  getUsers() {
    return Repository.get(`/u.json`).then((response) => response.data);
  },

  deleteUser(userId) {
    return Repository.delete(`/u/${userId}.json`).then((response) => response);
  },

  getAccountManagers() {
    return Repository.get(`/u/account_managers`).then(
      (response) => response.data
    );
  },

  logoutUser() {
    return Repository.delete(`/users/sign_out`)
      .then((response) => {
        response.data;
      })
      .catch((error) => {
        console.log("logoutUser error");
        console.log(error);
      });
  },
};
