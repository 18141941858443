<template>
  <div>
    <div class="flex-container">
      <div>
        <slot name="filters"></slot>
      </div>
      <div>
        <slot name="actions"></slot>
      </div>
    </div>

    <div class="board">
      <el-row
        :gutter="10"
        type="flex"
      >
        <el-col :span="8">
          <tasks-column
            :tasks-list="pendingTasks"
            status="PENDING"
            :title="$t('to_do')"
            :user-options="userOptions"
            @on-move-task="moveTask"
            @show-task="showTask"
            @delete-task="(task) => deleteTask(task)"
            @archive-task="(task) => archiveTask(task)"
            @duplicate-task="(task) => duplicateTask(task)"
          >
            <template
              v-if="studyId"
              v-slot:actions
            >
              <el-button
                plain
                type="primary"
                size="small"
                @click="$emit('on-show-creation-task', 'PENDING')"
              >
                {{ $t("add_task") }}
              </el-button>
            </template>
          </tasks-column>
        </el-col>
        <el-col :span="8">
          <tasks-column
            :tasks-list="currentTasks"
            status="ONGOING"
            :title="$t('doing')"
            :user-options="userOptions"
            @on-move-task="moveTask"
            @show-task="showTask"
            @delete-task="(task) => deleteTask(task)"
            @archive-task="(task) => archiveTask(task)"
            @duplicate-task="(task) => duplicateTask(task)"
          >
            <template
              v-if="studyId"
              v-slot:actions
            >
              <el-button
                plain
                type="primary"
                size="small"
                @click="$emit('on-show-creation-task', 'ONGOING')"
              >
                {{ $t("add_task") }}
              </el-button>
            </template>
          </tasks-column>
        </el-col>
        <el-col :span="8">
          <tasks-column
            :tasks-list="completedTasks"
            status="COMPLETED"
            :title="$t('done')"
            :user-options="userOptions"
            @on-move-task="moveTask"
            @show-task="showTask"
            @delete-task="(task) => deleteTask(task)"
            @archive-task="(task) => archiveTask(task)"
            @duplicate-task="(task) => duplicateTask(task)"
          >
            <template
              v-if="studyId"
              v-slot:actions
            >
              <el-button
                plain
                type="primary"
                size="small"
                @click="$emit('on-show-creation-task', 'COMPLETED')"
              >
                {{ $t("add_task") }}
              </el-button>
            </template>
          </tasks-column>
        </el-col>
      </el-row>

      <el-dialog
        :visible.sync="taskDialogVisible"
        :close-on-click-modal="false"
        width="62%"
      >
        <task-update-form
          v-if="selectedTask"
          :value="selectedTask"
          :key="selectedTask.id"
          :currentUser="currentUser"
          :user-options="userOptions"
          @on-updated-task="updatedTask"
          @delete-task="deleteTask"
          @on-archived-task="archivedTask"
          @duplicate-task="(task) => duplicateTask(task)"
          @on-created-task="(task) => $emit('on-created-task', task)"
        ></task-update-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import TasksColumn from "./TasksColumn";
import TaskUpdateForm from "./TaskUpdateForm";

export default {
  components: {
    TasksColumn,
    TaskUpdateForm,
  },
  props: {
    tasks: Array,
    filters: Object,
    currentUser: Object,
    userOptions: Array,
    studyId: Number,
  },
  data() {
    return {
      taskDialogVisible: false,
      selectedTask: null,
    };
  },
  computed: {
    filteredTasks() {
      return this.tasks.filter((task) => {
        return Object.keys(this.filters).every((type) => {
          return (
            this.filters[type].length === 0 ||
            (task.hasOwnProperty(type) &&
              (this.filters[type].includes(task[type]) ||
                (Array.isArray(task[type]) &&
                  this.filters[type].some((r) => task[type].indexOf(r) >= 0))))
          );
        });
      });
    },
    pendingTasks() {
      return this.filteredTasks
        .filter((task) => task.status === "PENDING" && !task.archived)
        .sort((a, b) => a.position - b.position);
    },
    currentTasks() {
      return this.filteredTasks
        .filter((task) => task.status === "ONGOING" && !task.archived)
        .sort((a, b) => a.position - b.position);
    },
    completedTasks() {
      return this.filteredTasks
        .filter((task) => task.status === "COMPLETED" && !task.archived)
        .sort((a, b) => a.position - b.position);
    },
  },
  methods: {
    showTask(task) {
      this.selectedTask = task;
      this.taskDialogVisible = true;
    },
    moveTask({ task, taskBeforeId, taskAfterId, newStatus }) {
      const partialTask = { taskBeforeId, taskAfterId };
      if (newStatus) {
        partialTask.status = newStatus;
      }
      this.updateTask(task, partialTask);
    },
    updateTask(task, partialTask) {
      this.$api
        .patch(`/studies/${task.studyId}/tasks/${task.id}`, partialTask)
        .then((response) => {
          this.updatedTask(response.data.data);
        })
        .catch(() => {
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
        });
    },
    updatedTask(task) {
      this.selectedTask = task;
      this.$emit("on-updated-task", task);
    },
    archiveTask(task) {
      this.$api
        .patch(`/studies/${task.studyId}/tasks/${task.id}`, { archived: true })
        .then((response) => {
          this.$emit("on-updated-task", response.data.data);
          this.$emit("on-archived-task", task.id);
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    archivedTask(taskId) {
      this.$emit("on-archived-task", taskId);
      this.taskDialogVisible = false;
      this.selectedTask = null;
    },
    deleteTask(task) {
      this.$api
        .delete(`/studies/${task.studyId}/tasks/${task.id}`)
        .then(() => {
          this.$emit("on-deleted-task", task.id);
          this.taskDialogVisible = false;
          this.selectedTask = null;
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
        });
    },
    duplicateTask(task) {
      let duplicate = { ...task };
      duplicate.blocks = [];
      delete duplicate.id;

      this.$api
        .post(`/studies/${task.studyId}/tasks`, duplicate)
        .then((response) => {
          const data = response.data.data;
          this.$emit("on-created-task", data);
          this.$message({
            message: this.$i18n.t("task_created_success"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("task_created_failure"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  justify-content: space-between;
}

.board {
  margin-bottom: 2rem;
}
</style>

<i18n>
{
  "en": {
    "add_task": "Add task",
    "doing": "Pending",
    "done": "Completed",
    "to_do": "To do",
    "fail": "We encountered an error",
    "task_created_failure": "Task creation failed",
    "task_created_success": "Task created successfully"
  },
  "fr": {
    "task_created_failure": "La création de la tâche a échoué",
    "task_created_success": "Tâche créée avec succès",
    "add_task": "Ajouter une tâche",
    "doing": "En cours",
    "done": "Réalisées",
    "to_do": "A faire"
  },
  "de": {
    "task_created_failure": "Aufgabenerstellung fehlgeschlagen",
    "task_created_success": "Aufgabe erfolgreich erstellt",
    "add_task": "Aufgabe hinzufügen",
    "doing": "Im Gange",
    "done": "Vollendet",
    "to_do": "Machen"
  }
}
</i18n>
