<template>
  <div>
    <h4><i class="fas fa-user"></i> {{ $t("assignedTo") }}</h4>
    <el-select
      class="value"
      :value="value"
      size="medium"
      @click.native="$emit('click')"
      @input="(user) => $emit('input', user ? user : null)"
      clearable
      filterable
    >
      <el-option
        v-for="user in userOptions"
        :key="user.id"
        :label="user.firstName + ' ' + user.lastName"
        :value="user.id"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    userOptions: Array,
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "assignedTo": "Assigned to"
  },
  "fr": {
    "assignedTo": "Assigné à"
  },
  "de": {
    "assignedTo": "Zugewiesen an"
  }
}
</i18n>
