<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    score: Number,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "solidgauge",
          width: 100,
          height: 75,
        },
        title: {
          text: "",
        },
        pane: {
          center: ["50%", "85%"],
          size: "140%",
          startAngle: -90,
          endAngle: 90,
          background: {
            innerRadius: "60%",
            outerRadius: "100%",
            shape: "arc",
          },
        },
        yAxis: {
          stops: [
            [0.1, "red"],
            [0.3, "orange"],
            [1, "green"],
          ],
          lineWidth: 0,
          tickAmount: 2,
          min: 0,
          max: 100,
          showFirstLabel: false,
          showLastLabel: false,
        },
        series: [
          {
            name: this.$i18n.t("score"),
            data: [this.score],
            dataLabels: {
              format: `<div style="text-align:center">{y}%</div>`,
            },
            tooltip: {
              valueSuffix: " %",
            },
          },
        ],
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>

<i18n>
{
  "en": {
    "domain_score": "Domain score",
    "score": "Score"
  },
  "fr": {
    "domain_score": "Score de domaine",
    "score": "Score"
  },
  "de": {
    "domain_score": "Domain-Score",
    "score": "Punktzahl"
  }
}
</i18n>
