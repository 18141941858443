<template>
  <div>
    <div class="actions">
      <el-button
        plain
        type="primary"
        @click="excelExport"
        >{{ $t("excel_export") }}</el-button
      >
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      resizable
      filter
      floatingFilter
      suppressContextMenu
      @gridReady="onGridReady"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    AgGridVue,
  },
  props: {
    mainSeries: Object,
    conversionGroups: Object,
    comparativeConversionGroups: Object,
    comparativePeriod: Array,
    gridOptions: { onColumnVisible: () => this.adjustGrid() },
  },
  data() {
    return {
      gridApi: null,
      columnApi: null,
      defaultColDef: { sortable: true, resizable: true },
    };
  },
  computed: {
    ...mapState(["study"]),
    columnDefs() {
      const columnDef = [
        {
          headerName: this.$i18n.t("category"),
          field: "category",
          minWidth: 300,
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("seo_conversion"),
          field: "seoConversion",
          width: 170,
          valueFormatter: this.seoConversionFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("seo_conversion_share"),
          field: "seoConversionShare",
          width: 180,
          valueFormatter: percentageFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
      ];
      if (this.comparativePeriod) {
        columnDef.push({
          headerName: this.$i18n.t("comparative_period"),
          field: "comparative",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
        columnDef.push({
          headerName: this.$i18n.t("variation"),
          field: "variation",
          valueFormatter: percentageFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: true,
        });
      }

      return columnDef;
    },
    rowData() {
      const totalSeoConversion =
        this.mainSerieData.length > 0 ? this.mainSerieData[0].seoConversion : 1;
      let rowData = this.mainSerieData
        .concat(
          this.conversionGroupsData.sort(
            (a, b) => b.seoConversion - a.seoConversion
          )
        )
        .map((category) => ({
          ...category,
          seoConversionShare: roundNumber(
            category.seoConversion / totalSeoConversion,
            3
          ),
        }));
      if (this.comparativePeriod) {
        rowData = rowData.map((category) => {
          return {
            ...category,
            variation: roundNumber(
              (category.seoConversion - category.comparative) /
                category.comparative,
              3
            ),
          };
        });
      }
      return rowData;
    },
    mainSerieData() {
      if (this.mainSeries) {
        let mainSeriesData = {
          category: this.$t("total"),
          seoConversion: roundNumber(
            this.mainSeries.main.reduce(
              (sum, conversionPoint) => sum + conversionPoint.value,
              0
            )
          ),
        };
        if (this.mainSeries.comparative) {
          mainSeriesData = {
            ...mainSeriesData,
            comparative: roundNumber(
              this.mainSeries.comparative.reduce(
                (sum, conversionPoint) => sum + conversionPoint.value,
                0
              )
            ),
          };
        }
        return [mainSeriesData];
      } else {
        return [];
      }
    },
    conversionGroupsData() {
      if (this.conversionGroups) {
        return Object.entries(this.conversionGroups)
          .filter(([name]) => name)
          .map(([name, conversionGroup]) => {
            let conversionGroupsData = {
              category: name,
              seoConversion: roundNumber(
                conversionGroup.reduce(
                  (sum, conversionPoint) => sum + conversionPoint.value,
                  0
                )
              ),
            };
            if (
              this.comparativeConversionGroups &&
              this.comparativeConversionGroups.hasOwnProperty(name)
            ) {
              conversionGroupsData = {
                ...conversionGroupsData,
                comparative: roundNumber(
                  this.comparativeConversionGroups[name].reduce(
                    (sum, conversionPoint) => sum + conversionPoint.value,
                    0
                  )
                ),
              };
            }
            return conversionGroupsData;
          });
      } else {
        return [];
      }
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    seoConversionFormatter(node) {
      if (this.comparativePeriod) {
        return (
          formatNumber(node.data.seoConversion) +
          " " +
          formatPercentageVariation(node.data.variation)
        );
      } else {
        return formatNumber(node.data.seoConversion);
      }
    },
    excelExport() {
      const params = {
        skipHeader: false,
        allColumns: true,
        onlySelected: this.gridApi.getSelectedRows().length !== 0,
        fileName: "Conversion Export - " + this.study.url + ".xlsx",
        sheetName: "Conversion",
      };
      this.gridApi.exportDataAsExcel(params);
    },
  },
};

const formatNumber = (number) => {
  if (Number.isNaN(number)) return "-";
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

const roundNumber = (number, numberOfDecimals) => {
  if (Number.isNaN(number)) return "-";
  if (!numberOfDecimals) {
    numberOfDecimals = 0;
  }
  return (
    Math.round(number * Math.pow(10, numberOfDecimals)) /
    Math.pow(10, numberOfDecimals)
  );
};

const numberFormatter = (params) => formatNumber(params.value);

const formatPercentage = (number) => {
  if (Number.isNaN(number)) return "-";
  return roundNumber(number * 100).toString() + "%";
};

const percentageFormatter = (params) => formatPercentage(params.value);

const formatPercentageVariation = (params) =>
  Number.isNaN(params)
    ? "-"
    : "(" + (params > 0 ? "+" : "") + formatPercentage(params) + ")";
</script>

<style scoped lang="scss">
.actions {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "category": "Category",
    "comparative_period": "Comparative period",
    "excel_export": "Export Excel",
    "seo_conversion": "SEO conversion",
    "seo_conversion_share": "SEO conversion share",
    "total": "Total",
    "variation": "Variation"
  },
  "fr": {
    "category": "Catégorie",
    "comparative_period": "Période comparative",
    "excel_export": "Export excel",
    "seo_conversion": "Conversion SEO",
    "seo_conversion_share": "Part de conversion SEO",
    "total": "Total",
    "variation": "Variation"
  },
  "de": {
    "category": "Kategorie",
    "comparative_period": "Vergleichszeitraum",
    "excel_export": "Excel exportieren",
    "seo_conversion": "Conversion-SEO",
    "seo_conversion_share": "Teil der SEO-Konvertierung",
    "total": "Gesamt",
    "variation": "Variation"
  }
}
</i18n>
