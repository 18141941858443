<template>
  <el-tabs
    v-model="activeTab"
    :stretch="true"
    tab-position="left"
  >
    <el-tab-pane name="domainAuthority">
      <div slot="label">{{ $t("domain_authority") }}</div>
      <domain-authority
        v-if="isMounted"
        v-loading="isLoading"
        :data="firstUrlMetricsHistory"
      >
      </domain-authority>
    </el-tab-pane>
    <el-tab-pane name="referringDomains">
      <div slot="label">{{ $t("referring_domains") }}</div>
      <referring-domains
        v-if="isMounted"
        v-loading="isLoading"
        :data="firstUrlMetricsHistory"
      >
      </referring-domains>
    </el-tab-pane>
    <el-tab-pane name="flows">
      <div slot="label">{{ $t("trust_flow_and_citation_flow") }}</div>
      <flows
        v-if="isMounted"
        v-loading="isLoading"
        :data="firstUrlMetricsHistory"
      >
      </flows>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import axios from "axios";
import Flows from "@/pages/Netlinking/Graphs/Flows";
import DomainAuthority from "@/pages/Netlinking/Graphs/DomainAuthority";
import ReferringDomains from "@/pages/Netlinking/Graphs/ReferringDomains";

export default {
  components: {
    Flows,
    DomainAuthority,
    ReferringDomains,
  },
  data() {
    return {
      activeTab: "domainAuthority",
      isMounted: false,
      isLoading: false,
      dataMetricsHistory: null,
      dataNewLostBacklinks: null,
    };
  },
  computed: {
    firstUrlMetricsHistory() {
      if (this.dataMetricsHistory) {
        return this.dataMetricsHistory[0];
      } else {
        return { url: "", history: {}, classification: {} };
      }
    },
    ...mapState(["study"]),
  },
  methods: {
    fetchMetricsHistory() {
      let requestMetricsHistory = {};
      requestMetricsHistory.urls = [{ url: this.study.url, type: "DOMAIN" }];
      requestMetricsHistory.isoCountryCode = this.study.isoCountryCode;
      this.isLoading = true;
      this.$api
        .post(`/studies/${this.study.id}/netlinking/urls/metrics/history`, {
          ...requestMetricsHistory,
        })
        .then((response) => {
          this.dataMetricsHistory = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.$message({
            message: this.$i18n.t("fail_fetch"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
        });
    },
  },
  mounted() {
    this.isMounted = true;
    this.fetchMetricsHistory();
  },
};
</script>

<i18n>
{
  "en": {
    "new_lost_backlinks": "New and Lost Backlinks",
    "domain_authority": "Domain Authority",
    "referring_domains": "Referring domains",
    "trust_flow_and_citation_flow": "Trust Flow / Citation Flow",
    "fail_fetch": "Graphs : Error encountered"
  },
  "fr": {
    "new_lost_backlinks": "Suivi des Backlinks",
    "domain_authority": "Domain Authority",
    "referring_domains": "Domaines référents",
    "trust_flow_and_citation_flow": "Trust Flow / Citation Flow",
    "fail_fetch": "Graphs : Nous avons rencontré une erreur"
  },
  "de": {
    "new_lost_backlinks": "Suivi des Backlinks",
    "domain_authority": "Domänenautorität",
    "referring_domains": "Verweisende Domains",
    "trust_flow_and_citation_flow": "Vertrauensfluss / Zitationsfluss",
    "fail_fetch": "Grafiken: Es ist ein Fehler aufgetreten"
  }
}
</i18n>
