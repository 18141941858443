<template>
  <el-select v-model="user">
    <el-option
      v-for="userOption in options"
      :key="userOption.id"
      :label="fullName(userOption)"
      :value="userOption.id"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: Number,
    options: Array,
  },
  methods: {
    fullName(userOptions) {
      let formattedLastName = userOptions.lastName.toLowerCase();
      formattedLastName = formattedLastName.split("");
      formattedLastName[0] = formattedLastName[0].toUpperCase();
      formattedLastName = formattedLastName.join("");
      return userOptions.firstName + " " + formattedLastName;
    },
  },
  computed: {
    user: {
      get() {
        return this.value;
      },
      set(user) {
        this.$emit("input", user);
      },
    },
  },
};
</script>
