<template>
  <ag-grid-vue
    style="height: 500px"
    class="ag-theme-material"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :rowData="rowData"
    :resizable="true"
    :filter="true"
    :floatingFilter="true"
    :localeTextFunc="localeTextFunc"
    :suppressCellSelection="true"
    @gridReady="onGridReady"
    @firstDataRendered="adjustGrid"
    @rowDataChanged="adjustGrid"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keywords",
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
        },
        {
          headerName: this.$i18n.t("position"),
          field: "position",
        },
      ];
    },
  },
  beforeMount() {
    this.setColumnDefs();
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss"></style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "keyword": "Keyword",
    "volume": "Volume",
    "position": "Position"
  },
  "fr": {
    "keyword": "Mot clé",
    "volume": "Volume",
    "position": "Position"
  },
  "de": {
    "keyword": "Stichwort",
    "volume": "Volumen",
    "position": "Position"
  }
}
</i18n>
