<template>
  <div v-if="params.data.status == 'PUBLISHED'">
    <div class="metrics">
      <el-card v-if="params.context.admin">
        <span class="url-span"
          >{{ $t("backlinkUrl") }} :
          <a
            v-if="params.data.sourceUrl"
            target="blank"
            :href="params.data.sourceUrl"
            class="url"
            >{{ params.data.sourceUrl }}</a
          >
          <i v-else>{{ $t("noUrlWereProvided") }}</i>
        </span>
        <el-row
          v-if="params.context.admin"
          :gutter="2"
        >
          <el-col :span="5">
            <el-card>
              <h5 class="titles">{{ $t("plateform") }}</h5>
              <div
                class="values"
                v-if="params.data.platform"
              >
                {{ params.data.platform }}
              </div>
              <div
                class="values"
                v-else
              >
                -
              </div>
            </el-card>
          </el-col>
          <el-col :span="5">
            <el-card>
              <h5 class="titles">{{ $t("buyPrice") }}</h5>
              <div
                class="values"
                v-if="params.data.buyingPrice"
              >
                {{ formatNumber(params.data.buyingPrice) }} €
              </div>
              <div
                class="values"
                v-else
              >
                -
              </div>
            </el-card>
          </el-col>
          <el-col :span="5">
            <el-card>
              <h5 class="titles">{{ $t("nbWords") }}</h5>
              <div
                class="values"
                v-if="params.data.numberOfWords"
              >
                {{ formatNumber(params.data.numberOfWords) }}
              </div>
              <div
                class="values"
                v-else
              >
                -
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="data">
        <span class="url-span"
          >{{ $t("urlAssociatedToGraphs") }} :
          <a
            target="blank"
            :href="params.data.targetUrl.url"
            class="url"
            >{{ params.data.targetUrl.url }}</a
          ></span
        >
        <el-row :gutter="2">
          <el-col :span="8">
            <el-card>
              <graph-renderer
                v-if="da.length > 0"
                :key="da[0].value"
                :title="'Domain Authority'"
                :main-series="da"
              />
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <graph-renderer
                v-if="cf.length > 0"
                :key="cf[0].value"
                :title="'Citation Flow'"
                :main-series="cf"
              />
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <graph-renderer
                v-if="tf.length > 0"
                :key="tf[0].value"
                :title="'Trust Flow'"
                :main-series="tf"
              />
            </el-card>
          </el-col>
        </el-row>
      </el-card>
      <el-row v-else>
        <el-card class="error-loading">
          <div style="line-height: 25">
            {{ $t("errorLoading") }}
          </div>
        </el-card>
      </el-row>
    </div>
  </div>
  <div v-else>
    <el-card>
      <div class="notes">
        <h4>{{ $t("notYetPublished") }}</h4>
      </div>
    </el-card>
  </div>
</template>

<script>
import GraphRenderer from "@/pages/CampaignManager/Backlinks/Renderer/GraphRenderer";

export default {
  components: {
    GraphRenderer,
  },
  data() {
    return {
      data: null,
      tf: [],
      cf: [],
      da: [],
    };
  },
  methods: {
    formatNumber(number) {
      if (Number.isInteger(number)) {
        return Math.floor(number)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
      } else {
        return number;
      }
    },
    dueDate() {
      const validationDate = new Date(this.params.data.validationDate);
      return this.formattedDate(
        new Date(validationDate.setMonth(validationDate.getMonth() + 1))
      );
    },
    formattedDate(dateToFormat) {
      const date = new Date(dateToFormat);
      return date.toLocaleDateString();
    },
    fetchMetrics() {
      if ((this.data = this.params.data.targetUrl.history) != null) {
        for (const elt in this.data) {
          for (const value in this.data[elt]) {
            if (value == "citationFlow") {
              this.cf.push({ date: elt, value: this.data[elt][value] });
            } else if (value == "domainAuthority") {
              this.da.push({ date: elt, value: this.data[elt][value] });
            } else if (value == "trustFlow") {
              this.tf.push({ date: elt, value: this.data[elt][value] });
            }
          }
        }
      }
    },
  },
  computed: {
    ratioTfCf() {
      if (
        this.params.data &&
        Number.isInteger(this.params.data.CF) &&
        Number.isInteger(this.params.data.TF)
      ) {
        if (this.params.data.CF != 0) {
          return (
            Math.round((this.params.data.TF / this.params.data.CF) * 100) / 100
          );
        }
      } else {
        return "--";
      }
    },
  },
  beforeMount() {
    this.fetchMetrics();
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  margin: 1rem;
}

.values {
  text-align: center;
  overflow-wrap: break-word;
}

.titles {
  text-align: center;
  overflow-wrap: break-word;
}

.error-loading {
  height: 400px;
  text-align: center;
}

.url-span {
  font-size: 1.05rem;

  .url {
    font-weight: bold;
  }
}
</style>

<i18n>
{
  "en": {
    "notYetPublished": "No data because the backlink is not published yet",
    "price": "Price",
    "publishedDate": "Published date",
    "dueDate": "Due date",
    "tfCfRatio": "TF/CF Ratio",
    "buyPrice": "Buying Price",
    "nbWords": "Number of words",
    "notes": "Notes",
    "plateform": "Plateform",
    "keyword": "Keyword",
    "errorLoading": "An error occured while loading the data",
    "urlAssociatedToGraphs": "URL associated with graphs",
    "backlinkUrl": "Backlink URL",
    "noUrlWereProvided": "No URL were provided"
  },
  "fr": {
    "notYetPublished": "Aucune donnée car le backlink n'est pas encore publié",
    "price": "Prix",
    "publishedDate": "Date de publication",
    "dueDate": "Date d'échéance",
    "tfCfRatio": "Ratio TF/CF",
    "buyPrice": "Prix d'achat",
    "nbWords": "Nombre de mots",
    "notes": "Notes",
    "plateform": "Plateforme",
    "keyword": "Mot clé",
    "errorLoading": "Une erreur est survenue lors du chargement des données",
    "urlAssociatedToGraphs": "URL associée aux graphiques",
    "backlinkUrl": "URL du backlink",
    "noUrlWereProvided": "Aucune URL fournie"
  },
  "de": {
    "notYetPublished": "Keine Daten, da der Backlink noch nicht veröffentlicht ist",
    "price": "Preis",
    "publishedDate": "Veröffentlichungsdatum",
    "dueDate": "Geburtstermin",
    "tfCfRatio": "Verhältnis TF/CF",
    "buyPrice": "Kaufpreis",
    "nbWords": "Anzahl der Wörter",
    "notes": "Anmerkungen",
    "plateform": "Plattform",
    "keyword": "Stichwort",
    "errorLoading": "Beim Laden der Daten ist ein Fehler aufgetreten",
    "urlAssociatedToGraphs": "Mit Diagrammen verknüpfte URL",
    "backlinkUrl": "URL, die Sie backlinken",
    "noUrlWereProvided": "Keine URL angegeben"
  }
}
</i18n>
