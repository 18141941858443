<template>
  <div>
    <span>
      {{ params.value }}
    </span>
    <el-tooltip :content="$t('alreadyImported')">
      <span
        class="isImported"
        v-if="!params.data.isImportable"
      >
        <i class="fas fa-check"></i>
      </span>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.isImported {
  color: $--color-success;
}
</style>

<i18n>
{
  "en": {
    "alreadyImported": "Already imported"
  },
  "fr": {
    "alreadyImported": "Déjà importé"
  },
  "de": {
    "alreadyImported": "Bereits importiert"
  }
}
</i18n>
