<template>
  <div>
    <div class="chart-title">
      <span class="chart-name"
        >{{ $t("average_position") }}
        <a
          v-if="user && user.locale === 'fr'"
          target="blank"
          href="https://docs.smartkeyword.io/fr/articles/900423-position-moyenne"
        >
          <i class="fas fa-question-circle"></i>
        </a>
      </span>

      <div>
        <span class="change">
          <strong>
            <span class="competitor"
              >{{ competitor.domain }} :
              {{ competitor.averagePositionValue }}</span
            >
            <span
              v-if="competitor.averagePositionChange > 0"
              class="negative-change"
              ><i class="fas fa-arrow-down"></i
              >{{ competitor.averagePositionChange }}</span
            >
            <span
              v-else-if="competitor.averagePositionChange == 0"
              class="neutral-change"
              ><i class="fas fa-equals"></i
            ></span>
            <span
              v-else-if="competitor.averagePositionChange < 0"
              class="positive-change"
              ><i class="fas fa-arrow-up"></i
              >{{ 0 - competitor.averagePositionChange }}</span
            >
          </strong>
        </span>
      </div>
      <div>
        <span class="change">
          <span>{{ self.domain }} : {{ self.averagePositionValue }}</span>
          <span
            v-if="self.averagePositionChange > 0"
            class="negative-change"
            ><i class="fas fa-arrow-down"></i
            >{{ self.averagePositionChange }}</span
          >
          <span
            v-else-if="self.averagePositionChange == 0"
            class="neutral-change"
            ><i class="fas fa-equals"></i
          ></span>
          <span
            v-else-if="self.averagePositionChange < 0"
            class="positive-change"
            ><i class="fas fa-arrow-up"></i
            >{{ 0 - self.averagePositionChange }}</span
          >
        </span>
      </div>
    </div>
    <highcharts
      v-if="isMounted"
      v-loading="isLoading"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
    isLoading: Boolean,
    user: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      isMounted: true,
      competitor: {
        domain: null,
        averagePositionData: null,
        averagePositionValue: null,
        averagePositionChange: null,
      },
      self: {
        domain: null,
        averagePositionData: null,
      },
      chartOptions: {
        chart: {
          height: 300,
          zoomType: "x",
          ignoreHiddenSeries: false,
        },
        title: {
          text: "",
          useHTML: true,
        },
        xAxis: [
          {
            type: "datetime",
          },
          {
            labels: {
              rotation: -45,
            },
            linkedTo: 0,
            opposite: true,
            type: "datetime",
          },
        ],
        yAxis: {
          title: {
            text: this.$i18n.t("average_position"),
          },
          endOnTick: false,
          reversed: true,
          minTickInterval: 1,
        },
        series: [],
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
        },
      },
    };
  },
  methods: {
    initialize() {
      this.mapData();
      this.setChart();
    },
    mapData() {
      this.competitor.domain = this.data.competitor.url.url;
      const averagePositionData = this.computeAveragePositionData(
        this.data.competitor
      );
      this.competitor.averagePositionData = averagePositionData;
      this.competitor.averagePositionValue =
        averagePositionData[averagePositionData.length - 1];
      this.competitor.averagePositionChange =
        averagePositionData[averagePositionData.length - 1] -
        averagePositionData[0];

      this.self.domain = this.data.self.url.url;
      this.self.averagePositionData = this.computeAveragePositionData(
        this.data.self
      );
      this.self.averagePositionValue =
        this.self.averagePositionData[this.self.averagePositionData.length - 1];
      this.self.averagePositionChange =
        this.self.averagePositionData[
          this.self.averagePositionData.length - 1
        ] - this.self.averagePositionData[0];
    },
    computeAveragePositionData(website) {
      return Object.entries(website.websiteStatisticsByDates)
        .sort()
        .map((dataPoint) => Math.round(dataPoint[1].averagePosition));
    },
    setChart() {
      this.setSeries([this.data.competitor, this.data.self]);
    },
    setSeries(array) {
      const series = [];
      const nbNewKeywordsPerDate = new Map();
      let lastNbKeywords = 0;
      for (const [i, websitePositions] of array.entries()) {
        const dataPoints = Object.entries(
          websitePositions.websiteStatisticsByDates
        )
          .sort()
          .map((websiteStatisticsByDate, index) => {
            let date = websiteStatisticsByDate[0];
            date = new Date(date).getTime();

            if (
              websiteStatisticsByDate[1].numberOfKeywords - lastNbKeywords !==
                0 &&
              i == 0 &&
              index > 0
            ) {
              let diff =
                websiteStatisticsByDate[1].numberOfKeywords - lastNbKeywords;
              let prefix = diff > 0 ? "+" : "";
              nbNewKeywordsPerDate.set(
                date,
                prefix + diff + " " + this.$i18n.t("keywords")
              );
            }
            lastNbKeywords = websiteStatisticsByDate[1].numberOfKeywords;

            return {
              x: date,
              y: Math.round(websiteStatisticsByDate[1].averagePosition),
              url: websitePositions.url.url,
            };
          });

        if (websitePositions.url.url.includes(this.study.url)) {
          series.push({
            type: "spline",
            name: websitePositions.url.url,
            data: dataPoints,
            color: Highcharts.Color(Highcharts.getOptions().colors[0]).get(),
            visible: true,
            cursor: "pointer",
          });
        } else {
          series.push({
            type: "spline",
            name: websitePositions.url.url,
            data: dataPoints,
            color: Highcharts.Color(
              Highcharts.getOptions().colors.slice(1)[i]
            ).get(),
            visible: true,
          });
        }
      }
      this.chartOptions.series = series;
      this.chartOptions.xAxis[1].tickPositions = Array.from(
        nbNewKeywordsPerDate.keys()
      );
      this.chartOptions.xAxis[1].labels.formatter = function () {
        return nbNewKeywordsPerDate.get(this.value);
      };
    },
  },
  mounted() {
    this.isMounted = true;
    if (this.data) this.initialize();
  },
  watch: {
    data() {
      if (this.data) this.initialize();
    },
  },
  computed: {
    ...mapState(["study"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;

  .value {
    font-size: 1.2rem;
    margin-left: 0.35rem;
  }

  .chart-name {
    font-size: 1.5rem;
  }

  .fa-question-circle {
    font-size: 1.1rem;
  }

  .change {
    font-size: 0.9rem;

    .positive-change {
      color: $--color-success;
    }

    .neutral-change {
      color: $--color-warning;
    }

    .negative-change {
      color: $--color-danger;
    }

    .competitor {
      font-style: bold;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "keywords": "keywords",
    "average_position": "Average ranking",
    "average_position_domain": "Average Position - %{domain}"
  },
  "fr": {
    "keywords": "mots clés",
    "average_position": "Position Moyenne",
    "average_position_domain": "Position Moyenne - %{domain}"
  },
  "de": {
    "keywords": "Schlüsselwörter",
    "average_position": "Durchschnittliche Position",
    "average_position_domain": "Durchschnittliche Position - %{domain}"
  }
}
</i18n>
