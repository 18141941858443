<template>
  <div class="actions">
    <div>
      <el-button
        class="select-all"
        @click="emitSelectAllKeywords()"
        size="mini"
        type="primary"
        plain
      >
        <i class="fas fa-list"></i>
        {{ $t("select_all") }}
      </el-button>
      <el-button-group>
        <el-popover
          v-model="addTagsPopoverVisible"
          class="popover-button"
          popper-class="action-popover"
          placement="bottom-start"
          width="auto"
        >
          <div>
            <el-select
              v-model="selectedTags"
              multiple
              filterable
              allow-create
              default-first-option
              :placeholder="$t('select_tags_to_add')"
              size="mini"
            >
              <el-option
                v-for="item in tagOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              size="mini"
              @click="emitAddTags()"
              >{{ $t("add_tags") }}</el-button
            >
          </div>
          <el-button
            size="mini"
            :disabled="!selectedKeywordsPresent"
            slot="reference"
          >
            <i class="fas fa-tag"></i> {{ $t("tag") }}
          </el-button>
        </el-popover>
        <el-popover
          v-model="removeTagsPopoverVisible"
          class="popover-button"
          popper-class="action-popover"
          placement="bottom-start"
          width="auto"
        >
          <div>
            <el-select
              v-model="selectedTags"
              multiple
              filterable
              allow-create
              default-first-option
              :placeholder="$t('select_tags_to_remove')"
              size="mini"
            >
              <el-option
                v-for="item in tagOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              size="mini"
              @click="emitRemoveTags()"
              >{{ $t("remove_tags") }}</el-button
            >
          </div>
          <el-button
            size="mini"
            :disabled="!selectedKeywordsPresent"
            slot="reference"
          >
            <i class="fas fa-tag"></i> {{ $t("untag") }}
          </el-button>
        </el-popover>
        <el-button
          size="mini"
          :disabled="!selectedKeywordsPresent"
          @click="$emit('export-selected-keywords')"
        >
          <i class="fas fa-file-excel"></i> {{ $t("export") }}
        </el-button>
        <el-button
          v-if="currentUser.is_admin"
          size="mini"
          :disabled="!selectedKeywordsPresent"
          @click="$emit('export-semantic-selected-keywords')"
        >
          <i class="fas fa-envelope"></i> {{ $t("export_semantic") }}
        </el-button>
        <el-button
          size="mini"
          :disabled="!selectedKeywordsPresent"
          @click="$emit('delete-keywords')"
        >
          <i class="fas fa-trash-alt"></i> {{ $t("delete") }}
        </el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentUser: Object,
    selectedKeywordsPresent: Boolean,
    tags: Array,
  },
  data() {
    return {
      selectedTags: [],
      addTagsPopoverVisible: false,
      removeTagsPopoverVisible: false,
    };
  },
  computed: {
    tagOptions() {
      return this.tags.map((tag) => ({
        value: tag["name"],
        label: `${tag["name"]} (${tag["count"]})`,
      }));
    },
  },
  methods: {
    emitAddTags() {
      this.$emit("add-tags", this.selectedTags);
      this.selectedTags = [];
      this.addTagsPopoverVisible = false;
    },
    emitRemoveTags() {
      this.$emit("remove-tags", this.selectedTags);
      this.selectedTags = [];
      this.removeTagsPopoverVisible = false;
    },
    emitSelectAllKeywords() {
      this.$emit("select-all-rows");
    },
  },
};
</script>

<style lang="scss">
.action-popover {
  ::v-deep .el-select .el-input__inner {
    width: 300px;
  }
}
</style>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: space-between;

  .select-all {
    margin-right: 1rem;
  }

  .el-button-group {
    > .popover-button:not(:last-child) {
      margin-right: -1px;
    }

    > .popover-button + .popover-button {
      margin-left: 0;
    }

    > .popover-button:first-child > .el-button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > .popover-button:not(:first-child):not(:last-child) > .el-button {
      border-radius: 0;
    }

    > .popover-button {
      float: left;
      position: relative;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "select_tags_to_add": "Select tags to add",
    "add_tags": "Add tags",
    "select_tags_to_remove": "Select tags to remove",
    "remove_tags": "Remove tags",
    "tag": "Tag",
    "untag": "Untag",
    "delete": "Delete",
    "csv_export": "CSV export",
    "excel_export": "Export to Excel",
    "export": "Export",
    "select_all": "Select all displayed keywords",
    "export_semantic": "Semantic export"
  },
  "fr": {
    "select_tags_to_add": "Sélectionner les groupes de mots clés",
    "add_tags": "Ajouter les tags",
    "select_tags_to_remove": "Sélectionner les groupes de mots clés",
    "remove_tags": "Retirer les tags",
    "tag": "Taguer",
    "untag": "Détaguer",
    "delete": "Supprimer",
    "csv_export": "Export CSV",
    "excel_export": "Export Excel",
    "export": "Export",
    "select_all": "Sélectionner tous les mots clés de la page",
    "export_semantic": "Export semantique"
  },
  "de": {
    "select_tags_to_add": "Wählen Sie Keyword-Gruppen aus",
    "add_tags": "Tags hinzufügen",
    "select_tags_to_remove": "Wählen Sie Keyword-Gruppen aus",
    "remove_tags": "Markierungen entfernen",
    "tag": "Zu markieren",
    "untag": "Markierung aufheben",
    "delete": "LÖSCHEN",
    "csv_export": "CSV-Datei exportieren",
    "excel_export": "Excel exportieren",
    "export": "Export",
    "select_all": "Wählen Sie alle Keywords auf der Seite aus",
    "export_semantic": "Semantik exportieren"
  }
}
</i18n>
