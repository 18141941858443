<template>
  <ag-grid-vue
    style="height: 650px"
    class="ag-theme-material"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :rowData="rowData"
    :resizable="true"
    :filter="true"
    :floatingFilter="true"
    :rowClassRules="rowClassRules"
    :localeTextFunc="localeTextFunc"
    :suppressCellSelection="true"
    @gridReady="onGridReady"
    @gridSizeChanged="adjustGrid"
    @firstDataRendered="adjustGrid"
    @rowDataChanged="adjustGrid"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import UrlRenderer from "@/components/gridRenderers/UrlRenderer";
import NoFollowRenderer from "@/components/gridRenderers/NoFollowRenderer";
import BacklinkStatusRenderer from "@/components/gridRenderers/BacklinkStatusRenderer";
import ProgressRenderer from "@/components/gridRenderers/ProgressRenderer";

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
    UrlRenderer,
    NoFollowRenderer,
    BacklinkStatusRenderer,
    ProgressRenderer,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowClassRules: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      if (this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    setColumnDefs() {
      (this.columnDefs = [
        {
          headerName: this.$i18n.t("url"),
          field: "urlSource",
          filter: "agTextColumnFilter",
          width: 400,
          cellRendererFramework: "UrlRenderer",
        },
        {
          headerName: this.$i18n.t("status"),
          field: "status",
          cellRendererFramework: "BacklinkStatusRenderer",
          filter: "agSetColumnFilter",
          keyCreator: (params) => {
            if (params.value == null) {
              return "";
            } else {
              return this.$i18n.t(params.value);
            }
          },
          filterParams: {
            values: [this.$i18n.t("new"), this.$i18n.t("lost")],
          },
        },
        {
          headerName: this.$i18n.t("anchor"),
          field: "anchorText",
          filter: "agTextColumnFilter",
          width: 300,
        },
        {
          headerName: this.$i18n.t("citation_flow"),
          field: "citationFlow",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "ProgressRenderer",
        },
        {
          headerName: this.$i18n.t("trust_flow"),
          field: "trustFlow",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "ProgressRenderer",
        },
        {
          headerName: this.$i18n.t("ratio_tf_cf"),
          type: "numericColumn",
          valueFormatter: ratioTrustFlowCitationFlow,
        },
        {
          headerName: this.$i18n.t("no_follow"),
          field: "noFollow",
          type: "numericColumn",
          width: 220,
          cellRendererFramework: "NoFollowRenderer",
        },
      ]),
        (this.rowClassRules = {
          "new-backlink": (params) => {
            var status = params.data.status;
            return status == "new";
          },
          "lost-backlink": (params) => {
            var status = params.data.status;
            return status == "lost";
          },
        });
    },
    mapData() {
      this.rowData = [];
      if (this.data && this.data.backlinksNewLost) {
        if (this.data.backlinksNewLost.newBacklinks.length > 0) {
          this.data.backlinksNewLost.newBacklinks.map((backlink) => {
            const obj = {
              urlSource: backlink.sourceUrl.url,
              status: "new",
              domainAuthority: backlink.sourceUrl.metrics.domainAuthority,
              citationFlow: backlink.sourceUrl.metrics.citationFlow,
              trustFlow: backlink.sourceUrl.metrics.trustFlow,
              anchorText: backlink.anchorText,
              noFollow: backlink.noFollow ? true : false,
            };
            this.rowData.push(obj);
          });
        }
        if (this.data.backlinksNewLost.lostBacklinks.length > 0) {
          this.data.backlinksNewLost.lostBacklinks.map((backlink) => {
            const obj = {
              urlSource: backlink.sourceUrl.url,
              status: "lost",
              domainAuthority: backlink.sourceUrl.metrics.domainAuthority,
              citationFlow: backlink.sourceUrl.metrics.citationFlow,
              trustFlow: backlink.sourceUrl.metrics.trustFlow,
              anchorText: backlink.anchorText,
              noFollow: backlink.noFollow ? true : false,
            };
            this.rowData.push(obj);
          });
        }
      }
    },
  },
  beforeMount() {
    this.setColumnDefs();
  },
  mounted() {
    if (this.data) {
      this.mapData();
    }
  },
  watch: {
    data() {
      this.mapData();
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);

let ratioTrustFlowCitationFlow = (params) => {
  if (params.data.citationFlow != 0) {
    return (
      formatNumber((params.data.trustFlow / params.data.citationFlow) * 100) +
      "%"
    );
  } else {
    return "--";
  }
};
</script>

<style scoped lang="scss" scoped>
@import "@/styles/variables";

::v-deep .ag-centered-header {
  .ag-header-group-cell-label {
    justify-content: center;
  }
}

// ::v-deep .new-backlink {
// 	background-color: #e6ffe6;
// }

// ::v-deep .lost-backlink {
// 	background-color: #ffe6e6;
// }
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "url": "URL",
    "status": "Status",
    "anchor": "Anchor",
    "no_follow": "No follow ?",
    "tf": "TF",
    "cf": "CF",
    "citation_flow": "Citation Flow",
    "trust_flow": "Trust Flow",
    "ratio_tf_cf": "Ratio TF/CF",
    "url_data": "Flow metrics of the URL",
    "domain_data": "Flow metrics of the domain",
    "new": "New",
    "lost": "Lost"
  },
  "fr": {
    "url": "URL",
    "status": "Statut",
    "anchor": "Ancre",
    "no_follow": "No follow ?",
    "tf": "TF",
    "cf": "CF",
    "citation_flow": "Citation Flow",
    "trust_flow": "Trust Flow",
    "ratio_tf_cf": "Ratio TF/CF",
    "url_data": "Données flow metrics de l'url",
    "domain_data": "Données flow metrics du domaine",
    "new": "Nouveau",
    "lost": "Perdu"
  },
  "de": {
    "url": "URL",
    "status": "Status",
    "anchor": "Anker",
    "no_follow": "Nein folgen?",
    "tf": "TF",
    "cf": "CF",
    "citation_flow": "Zitierfluss",
    "trust_flow": "Vertrauensfluss",
    "ratio_tf_cf": "Verhältnis TF/CF",
    "url_data": "URL-Flow-Metrikdaten",
    "domain_data": "Daten zu Domänenflussmetriken",
    "new": "Neu",
    "lost": "Verloren"
  }
}
</i18n>
