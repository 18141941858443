<template>
  <div class="seo-scores">
    <div class="element seo-score">
      <el-progress
        class="small-dashboard"
        type="dashboard"
        :percentage="ownScore"
        :stroke-width="10"
        :format="format"
        :color="colors"
      >
      </el-progress>
      <div class="text">
        {{ $t("ownseoScore") }}
      </div>
    </div>
    <el-divider
      direction="vertical"
      class="vertical-divider"
    />
    <div class="element seo-score">
      <el-progress
        class="small-dashboard"
        type="dashboard"
        :percentage="serpScore"
        :stroke-width="10"
        :format="format"
        :color="colors"
      >
      </el-progress>
      <div class="text">
        {{ $t("serpSeoScore") }}
      </div>
    </div>
    <el-tooltip
      :content="$t('tooltipScoreGlobal')"
      placement="top"
    >
      <i class="fas fa-question-circle"></i>
    </el-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    ownScore: Number,
    serpScore: Number,
  },
  data() {
    return {
      colors: [
        { color: "#FF0000", percentage: 20 },
        { color: "#FFCC00", percentage: 40 },
        { color: "#4ECDC4", percentage: 60 },
        { color: "#66FF00", percentage: 80 },
        { color: "#008000", percentage: 100 },
      ],
    };
  },
  methods: {
    format(percentage) {
      return percentage;
    },
  },
};
</script>

<style lang="scss" scoped>
.seo-scores {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;

  .vertical-divider {
    height: unset;
  }

  .element {
    flex: 1;
  }

  .seo-score {
    text-align: center;

    .text {
      text-align: center;
      font-size: 1.05rem;
      word-break: break-word;
    }

    .small-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 1.1rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
}
</style>

<i18n>
{
    "en": {
        "ownseoScore": "My content optimization score",
        "serpSeoScore": "SERP content optimization score",
        "tooltipScoreGlobal":"This score is calculated based on the scores of each tag (H1, Title, Meta description) as well as the scores on content length and keyword optimization"
    },
    "fr": {
        "ownseoScore": "Mon score d'optimisation global",
        "serpSeoScore": "Score d'optimisation global de la serp",
        "tooltipScoreGlobal":"Ce score est calculé en fonction des scores de chaque balise (H1, Titre, Meta description) ainsi que les scores sur la longueur de contenu et l'optimisation des mots clés"
    },
    "it": {
        "ownseoScore": "Il mio punteggio di ottimizzazione dei contenuti",
        "serpSeoScore": "Punteggio di ottimizzazione dei contenuti SERP",
        "tooltipScoreGlobal":"Questo punteggio viene calcolato in base ai punteggi di ciascun tag (H1, Titolo, Meta descrizione) nonché ai punteggi sulla lunghezza del contenuto e sull'ottimizzazione delle parole chiave"
    },
    "es": {
        "ownseoScore": "Mi puntaje de optimización de contenido",
        "serpSeoScore": "Puntuación de optimización de contenido SERP",
        "tooltipScoreGlobal":"Esta puntuación se calcula en función de las puntuaciones de cada etiqueta (H1, Título, Meta descripción), así como las puntuaciones sobre la longitud del contenido y la optimización de palabras clave"
    },
    "de": {
        "ownseoScore": "Mein Inhaltsoptimierungs-Score",
        "serpSeoScore": "SERP-Content-Optimierungspunktzahl",
        "tooltipScoreGlobal":"Dieser Score wird basierend auf den Scores jedes Tags (H1, Titel, Meta-Beschreibung) sowie den Scores zu Inhaltslänge und Keyword-Optimierung berechnet"
    },
    "nl": {
        "ownseoScore": "Mijn score voor contentoptimalisatie",
        "serpSeoScore": "SERP content optimalisatie score",
        "tooltipScoreGlobal":"Deze score wordt berekend op basis van de scores van elke tag (H1, titel, metabeschrijving) en de scores op inhoudslengte en zoekwoordoptimalisatie"
    }
}
</i18n>
