<template>
  <div>
    <h5>{{ $t("tagSEO") }}</h5>
    <title-scores
      v-if="!isActionPlanContentPage"
      :ownScore="Math.round(titleScores.ownScore)"
      :serpScore="Math.round(titleScores.serpScore)"
    >
    </title-scores>
    <meta-desc-scores
      v-if="!isActionPlanContentPage"
      :ownScore="Math.round(metaDescScores.ownScore)"
      :serpScore="Math.round(metaDescScores.serpScore)"
    >
    </meta-desc-scores>
    <h-1-scores
      :ownScore="Math.round(h1Scores.ownScore)"
      :serpScore="Math.round(h1Scores.serpScore)"
      :ownH1="ownH1"
    >
    </h-1-scores>
  </div>
</template>

<script>
import H1Scores from "@/components/ContentOptimization/H1Scores";
import MetaDescScores from "@/components/ContentOptimization/MetaDescScores";
import TitleScores from "@/components/ContentOptimization/TitleScores";
export default {
  components: {
    H1Scores,
    MetaDescScores,
    TitleScores,
  },
  props: {
    h1Scores: Object,
    ownH1: String,
    titleScores: Object,
    metaDescScores: Object,
    isActionPlanContentPage: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<i18n>
{
    "en": {
        "tagSEO": "SEO Tags"
    },
    "fr": {
        "tagSEO": "Balises SEO"
    },
    "it": {
        "tagSEO": "Tag SEO"
    },
    "es": {
        "tagSEO": "Etiquetas SEO"
    },
    "de": {
        "tagSEO": "SEO-Tags"
    },
    "nl": {
        "tagSEO": "SEO-tags"
    }
}
</i18n>
