<template>
  <div>
    <el-card shadow="never">
      <grid
        v-loading="isLoading"
        :position-history-data="positionHistoryData"
        :study-id="studyId"
        :first-date="startDate"
        :last-date="endDate"
        :frequency="frequency"
        @show-keyword="$emit('show-keyword', $event)"
      >
      </grid>
    </el-card>
  </div>
</template>

<script>
import Grid from "@/pages/Keywords/PositionsHistory/Grid";

export default {
  props: {
    studyId: Number,
    studyResponse: Object,
    trackedKeywords: Array,
    url: String,
    frequency: String,
    startDate: Object,
    endDate: Object,
    searchEngine: Object,
  },
  components: {
    Grid,
  },
  data() {
    return {
      positionHistoryData: [],
      keywords: [],
      isLoading: false,
    };
  },
  methods: {
    constructRequest() {
      return {
        keywords: this.trackedKeywords.map((trackedKeyword) => {
          return {
            text: trackedKeyword.text,
            locationId: trackedKeyword.locationId,
          };
        }),
        url: {
          url: this.url,
          type: "DOMAIN",
        },
        frequency: this.frequency === "DAY" ? "DAILY" : "WEEKLY",
        rangeDate: {
          start: this.startDate.toString().split("T")[0],
          end: this.endDate.toString().split("T")[0],
        },
        searchEngineParameters: {
          isoCountryCode: this.searchEngine.isoCountryCode,
          device: this.searchEngine.device,
          isoLanguageCode: this.searchEngine.isoLanguageCode,
        },
      };
    },
    fetchPositionsHistoryData() {
      const request = this.constructRequest();
      this.isLoading = true;
      this.$api
        .post(`/positions/history`, request, { timeout: 300000 })
        .then((response) => {
          this.positionHistoryData = response.data.data.map(
            (keywordHistory) => ({
              keyword: keywordHistory.keyword.text,
              volume: keywordHistory.volume,
              history: keywordHistory.history,
            })
          );
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.fetchPositionsHistoryData();
  },
  watch: {
    trackedKeywords() {
      this.fetchPositionsHistoryData();
    },
    frequency() {
      this.fetchPositionsHistoryData();
    },
    startDate() {
      this.fetchPositionsHistoryData();
    },
    endDate() {
      this.fetchPositionsHistoryData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

::v-deep .fa-spinner {
  font-size: 75px;
  position: absolute;
  top: 25%;
}

::v-deep .loading-icon {
  text-align: center;
  height: 800px;
  position: relative;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "export": "Export",
    "keyword": "Keyword",
    "page": "Page",
    "volume": "Volume",
    "position": "Rank",
    "actions": "Actions"
  },
  "fr": {
    "export": "Export",
    "keyword": "Mot clé",
    "page": "Page",
    "volume": "Volume",
    "position": "Position",
    "actions": "Actions"
  },
  "de": {
    "export": "Export",
    "keyword": "Stichwort",
    "page": "Buchseite",
    "volume": "Volumen",
    "position": "Position",
    "actions": "Aktionen"
  }
}
</i18n>
