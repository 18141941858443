import Repository from "@/services/repository";

export default {
  createKeywordMetrics(params, studyId) {
    return Repository.get(`/a/${studyId}/google_results_page`, {
      params: {
        page_facade: params,
      },
    }).then((response) => response.data);
  },
};
