<template></template>

<script>
export default {
  created() {
    let refreshToken = window.location.href.substring(
      window.location.href.indexOf("#") + 1
    );
    const params = new URLSearchParams(window.location.search);
    window.localStorage.setItem("refreshToken", refreshToken);

    this.$api
      .post(`/rails/oauth/sign-in`, null, {
        baseURL: null,
        headers: { Accept: "application/json" },
      })
      .then(() => (window.location.href = params.get("redirect_uri")))
      .catch((error) => console.log(error));
  },
};
</script>
