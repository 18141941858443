<template>
  <div>
    <el-card shadow="never">
      <center class="title">
        <span v-if="start != null && topType === 'ENTRY' && end == 3"
          ><b>{{ $t("entry") }} - Top {{ end }} ranking</b></span
        >
        <span v-else-if="start != null && topType === 'ENTRY'"
          ><b
            >{{ $t("entry") }} - Top {{ start }} {{ $t("to") }}
            {{ end }} ranking</b
          ></span
        >
        <span v-else-if="start != null && topType === 'EXIT' && end == 3"
          ><b>{{ $t("exit") }} - Top {{ end }} ranking</b></span
        >
        <span v-else-if="start != null && topType === 'EXIT'"
          ><b
            >{{ $t("exit") }} - Top {{ start }} {{ $t("to") }}
            {{ end }} ranking</b
          ></span
        >
        <span v-else-if="start == null && topType === 'ENTRY'"
          ><b>{{ $t("top_entries") }}</b></span
        >
        <span v-else-if="start == null && topType === 'EXIT'"
          ><b>{{ $t("top_exits") }}</b></span
        >
      </center>
      <div class="kpis">
        <el-card
          shadow="never"
          class="kpi"
        >
          {{ $t("totalVariation") }}
          <span class="focus"
            >: <span v-if="totalTrafficVariation < 0">-</span
            >{{ Math.abs(totalTrafficVariation) | formatNumber }}</span
          >
        </el-card>
      </div>
      <grid
        :keywords-data="keywordsData"
        :study-id="studyId"
        :start="start"
        :end="end"
        :exportableData="exportableData"
        :top-type="topType"
        :start-date="startDate"
        :end-date="endDate"
        :height="height"
        @show-keyword="$emit('show-keyword', $event)"
      >
      </grid>
    </el-card>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import Grid from "@/pages/Keywords/TopMovements/Grid";

export default {
  props: {
    studyId: Number,
    startDate: DateTime,
    endDate: DateTime,
    start: Number,
    end: Number,
    topType: String,
    keywordsData: Array,
    totalTrafficVariation: Number,
    exportableData: Boolean,
    height: Number,
  },
  components: {
    Grid,
  },
};
</script>

<style lang="scss" scoped>
.export-button {
  text-align: right;
}

.title {
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
}

.kpis {
  .kpi {
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 0.3rem;

    .focus {
      text-align: center;
      font-weight: 500;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "excel_export": "Export to Excel",
    "entry": "Entries",
    "exit": "Exits",
    "top_entries": "Top Entries",
    "top_exits": "Top Exits",
    "to": "to",
    "totalTraffic": "Total traffic",
    "totalVariation": "Total traffic variation"
  },
  "fr": {
    "excel_export": "Export Excel",
    "entry": "Entrée",
    "exit": "Sortie",
    "top_entries": "Les plus fortes hausses",
    "top_exits": "Les plus fortes baisses",
    "to": "à",
    "totalTraffic": "Trafic total",
    "totalVariation": "Variation totale de trafic"
  },
  "de": {
    "excel_export": "Excel exportieren",
    "entry": "Eingang",
    "exit": "Ausfahrt",
    "top_entries": "Die größten Anstiege",
    "top_exits": "Die größten Tropfen",
    "to": "Zu",
    "totalTraffic": "Gesamtverkehr",
    "totalVariation": "Totale Verkehrsänderung"
  }
}
</i18n>
