<template>
  <div class="positioned-page">
    <h3>{{ $t("positionedPage") }}</h3>
    <el-collapse>
      <el-collapse-item
        v-bind:key="serp.url"
        v-for="serp in positionedSerpResults"
      >
        <div slot="title">
          <el-tooltip
            v-if="serp == positionAssigned"
            :content="$t('assignedPage')"
            placement="bottom"
            ><i class="fas fa-thumbtack fa-xs"></i
          ></el-tooltip>
          {{ serp.url }}
        </div>
        <div class="flex-container">
          <table
            cellspacing="0"
            cellpadding="0"
            border="0"
          >
            <tbody>
              <tr>
                <td>URL</td>
                <td>
                  <a
                    :href="serp.url"
                    target="_blank"
                    >{{ serp.url }}</a
                  >
                </td>
              </tr>
              <tr v-if="serp.hasOwnProperty('title') && serp.title.length > 0">
                <td>{{ $t("title") }}</td>
                <td>{{ serp.title }}</td>
              </tr>
              <tr
                v-if="
                  serp.hasOwnProperty('metaDescription') &&
                  serp.metaDescription &&
                  serp.metaDescription.length > 0
                "
              >
                <td>{{ $t("metaDescription") }}</td>
                <td>{{ serp.metaDescription }}</td>
              </tr>
              <tr>
                <td>{{ $t("position") }}</td>
                <td>{{ serp.position }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { isSameDomainNameMixin } from "@/mixins/isSameDomainNameMixin";
export default {
  mixins: [isSameDomainNameMixin],
  props: {
    position: Object,
    positionAssigned: Object,
    isAssigned: Boolean,
    serpResults: Array,
  },
  computed: {
    positionedSerpResults() {
      let serpResultsFiltered = this.serpResults.filter((serp) =>
        this.isSameDomainName(this.position.domain, serp.hostName)
      );

      if (serpResultsFiltered.length > 0) {
        serpResultsFiltered.forEach((serp) => {
          Object.defineProperty(serp, "metaDescription", {
            value: serp.description,
            writable: false,
          });
        });
      }

      if (this.positionAssigned) {
        if (this.positionAssigned.position == 101)
          serpResultsFiltered.push(this.positionAssigned);
      }
      return serpResultsFiltered;
    },
    positionnedPageIsAssigned() {
      if (this.positionAssigned) {
        return this.position.position == this.positionAssigned.position;
      }
      return false;
    },
    urlToShow() {
      if (this.positionAssigned) {
        if (this.position.position == 101) {
          return this.positionAssigned;
        } else {
          if (this.position.position < this.positionAssigned.position) {
            return this.position;
          }
        }
      } else {
        return this.position;
      }
      return this.positionAssigned;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.positioned-page {
  margin-bottom: 2rem;

  h3 {
    font-size: 1.25rem;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap-reverse;

    table {
      flex: 1;

      td {
        padding: 0.5rem;
        text-overflow: ellipsis;
        vertical-align: middle;
        text-align: left;

        &:first-child,
        .muted {
          color: $--color-text-secondary;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "positionedPage": "Ranked page(s)",
    "assignedPage": "This page was manually assigned.",
    "title": "Title",
    "metaDescription": "Meta description",
    "position": "Rank"
  },
  "fr": {
    "positionedPage": "Page(s) positionnée(s)",
    "assignedPage": "Cette page a été assignée manuellement.",
    "title": "Titre",
    "metaDescription": "Meta description",
    "position": "Position"
  },
  "de": {
    "positionedPage": "Positionierte Seite(n)",
    "assignedPage": "Diese Seite wurde manuell zugewiesen.",
    "title": "Titel",
    "metaDescription": "Meta-Beschreibung",
    "position": "Position"
  }
}
</i18n>
