import Repository from "@/services/repository";

export default {
  getPageStatus(studyId, url) {
    return Repository.post(`/a/${studyId}/configuration/get_page_status.json`, {
      url: url,
    }).then((response) => response.data);
  },
  updateAlerts(studyId, alerts) {
    return Repository.post(`/a/${studyId}/configuration/update_alerts.json`, {
      alerts,
    }).then((response) => response.data);
  },
  updateDimensions(studyId, dimensions) {
    return Repository.post(
      `/a/${studyId}/configuration/update_dimensions.json`,
      {
        dimensions,
      }
    ).then((response) => response.data);
  },
  getConfiguration(studyId) {
    return Repository.get(`/a/${studyId}/configuration.json`).then(
      (response) => response.data
    );
  },
  updateMonitoringTypeGoals(studyId, conversionMonitoringSettings) {
    return Repository.post(
      `/a/${studyId}/configuration/update_monitoring_type_goals.json`,
      {
        conversionMonitoringSettings,
      }
    ).then((response) => response.data);
  },
  updateFavorites(studyId, configuration) {
    return Repository.put(
      `/a/${studyId}/configuration.json`,
      configuration
    ).then((response) => response.data);
  },
};
