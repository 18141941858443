<template>
  <div>
    <div class="chart-title">
      <span class="chart-name">{{ $t("visibility") }} </span>
      <a
        v-if="user && user.locale === 'fr'"
        target="blank"
        href="http://docs.smartkeyword.io/guide-smartkeyword/donnees-smartkeyword/visibilite-definition-et-exemples"
      >
        <i
          :title="$t('visibility_definition')"
          class="fas fa-question-circle"
        ></i>
      </a>
      <div>
        <span class="change">
          <strong>
            <span class="competitor"
              >{{ competitor.domain }} : {{ competitor.visibilityValue }}%
            </span>
            <span
              v-if="competitor.visibilityChange > 0"
              class="positive-change"
              ><i class="fas fa-arrow-up"></i
              >{{ competitor.visibilityChange }}%</span
            >
            <span
              v-else-if="competitor.visibilityChange == 0"
              class="neutral-change"
              ><i class="fas fa-equals"></i
            ></span>
            <span
              v-else-if="competitor.visibilityChange < 0"
              class="negative-change"
              ><i class="fas fa-arrow-down"></i
              >{{ competitor.visibilityChange }}%</span
            >
          </strong>
        </span>
      </div>
      <div>
        <span class="change">
          <span>{{ self.domain }} : {{ self.visibilityValue }}%</span>
          <span
            v-if="self.visibilityChange > 0"
            class="positive-change"
            ><i class="fas fa-arrow-up"></i>{{ self.visibilityChange }}%</span
          >
          <span
            v-else-if="self.visibilityChange == 0"
            class="neutral-change"
            ><i class="fas fa-equals"></i
          ></span>
          <span
            v-else-if="self.visibilityChange < 0"
            class="negative-change"
            ><i class="fas fa-arrow-down"></i>{{ self.visibilityChange }}%</span
          >
        </span>
      </div>
    </div>
    <highcharts
      v-if="isMounted"
      v-loading="isLoading"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
    isLoading: Boolean,
    user: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      isMounted: false,
      competitor: {
        domain: null,
        visibilityData: null,
        visibilityValue: null,
        visibilityChange: null,
      },
      self: {
        domain: null,
        visibilityData: null,
      },
      chartOptions: {
        chart: {
          type: "area",
          height: 300,
          zoomType: "x",
          ignoreHiddenSeries: false,
        },
        title: {
          text: "",
          useHTML: true,
        },
        xAxis: [
          {
            type: "datetime",
          },
          {
            labels: {
              rotation: -45,
            },
            linkedTo: 0,
            opposite: true,
            type: "datetime",
          },
        ],
        yAxis: {
          title: {
            text: this.$i18n.t("visibility") + " (%)",
          },
          endOnTick: false,
        },
        tooltip: {
          pointFormatter: function () {
            return "<b>" + this.y + "%</b>";
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
        },
      },
    };
  },
  methods: {
    initialize() {
      this.mapData();
      this.setChart();
    },
    mapData() {
      this.competitor.domain = this.data.competitor.url.url;
      const visibilityData = this.computeVisiblityData(this.data.competitor);
      this.competitor.visibilityData = visibilityData;
      this.competitor.visibilityValue =
        visibilityData[visibilityData.length - 1];
      this.competitor.visibilityChange =
        visibilityData[visibilityData.length - 1] - visibilityData[0];

      this.self.domain = this.data.self.url.url;
      this.self.visibilityData = this.computeVisiblityData(this.data.self);
      this.self.visibilityValue =
        this.self.visibilityData[this.self.visibilityData.length - 1];
      this.self.visibilityChange =
        this.self.visibilityData[this.self.visibilityData.length - 1] -
        this.self.visibilityData[0];
    },
    computeVisiblityData(website) {
      return Object.entries(website.websiteStatisticsByDates)
        .sort()
        .map((dataPoint) => {
          const nbTop10Keywords = dataPoint[1].positionBucketsStatistics.find(
            (bucket) => bucket.positionMin == 1 && bucket.positionMax == 10
          ).numberOfKeywords;
          return Math.round(
            (nbTop10Keywords / dataPoint[1].numberOfKeywords) * 100
          );
        });
    },
    setChart() {
      this.setSeries([this.data.competitor, this.data.self]);
    },
    setSeries(array) {
      this.chartOptions.series = [];
      let seriesCompetitor = {};
      let seriesSelf = {};
      const nbNewKeywordsPerDate = new Map();
      let lastNbKeywords = 0;
      for (const [i, websitePositions] of array.entries()) {
        const dataPoints = Object.entries(
          websitePositions.websiteStatisticsByDates
        )
          .sort()
          .map((websiteStatisticsByDate, index) => {
            let date = websiteStatisticsByDate[0];
            date = new Date(date).getTime();

            if (
              websiteStatisticsByDate[1].numberOfKeywords - lastNbKeywords !==
                0 &&
              i == 0 &&
              index > 0
            ) {
              let diff =
                websiteStatisticsByDate[1].numberOfKeywords - lastNbKeywords;
              let prefix = diff > 0 ? "+" : "";
              nbNewKeywordsPerDate.set(
                date,
                prefix + diff + " " + this.$i18n.t("keywords")
              );
            }
            lastNbKeywords = websiteStatisticsByDate[1].numberOfKeywords;

            const nbKeywordsTop10 =
              websiteStatisticsByDate[1].positionBucketsStatistics.find(
                (bucket) => bucket.positionMin == 1 && bucket.positionMax == 10
              ).numberOfKeywords;
            return {
              x: date,
              y: Math.round(
                (nbKeywordsTop10 /
                  websiteStatisticsByDate[1].numberOfKeywords) *
                  100
              ),
              url: websitePositions.url.url,
            };
          });

        if (websitePositions.url.url.includes(this.study.url)) {
          seriesSelf = {
            type: "spline",
            name: websitePositions.url.url,
            data: dataPoints,
            color: Highcharts.Color(Highcharts.getOptions().colors[0]).get(),
            visible: true,
            cursor: "pointer",
          };
        } else {
          seriesCompetitor = {
            type: "spline",
            name: websitePositions.url.url,
            data: dataPoints,
            color: Highcharts.Color(
              Highcharts.getOptions().colors.slice(1)[i]
            ).get(),
            visible: true,
          };
        }
      }
      this.chartOptions.series.push(seriesSelf);
      this.chartOptions.series.push(seriesCompetitor);
      this.chartOptions.xAxis[1].tickPositions = Array.from(
        nbNewKeywordsPerDate.keys()
      );
      this.chartOptions.xAxis[1].labels.formatter = function () {
        return nbNewKeywordsPerDate.get(this.value);
      };
    },
  },
  mounted() {
    this.isMounted = true;
    if (this.data) this.initialize();
  },
  watch: {
    data() {
      if (this.data) this.initialize();
    },
  },
  computed: {
    ...mapState(["study"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;

  .value {
    font-size: 1.2rem;
    margin-left: 0.35rem;
  }

  .chart-name {
    font-size: 1.5rem;
  }

  .fa-question-circle {
    font-size: 1.1rem;
  }

  .change {
    font-size: 0.9rem;

    .positive-change {
      color: $--color-success;
    }

    .neutral-change {
      color: $--color-warning;
    }

    .negative-change {
      color: $--color-danger;
    }

    .competitor {
      font-style: bold;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "visibility": "Visibility",
    "visibility_definition": "Percentage of keywords present on the first result page",
    "keywords": "keywords",
    "percentage_keywords_in_first_page": "% of keywords in the first search result page - %{domain}"
  },
  "fr": {
    "visibility": "Visibilité",
    "visibility_definition": "Pourcentage de mots clés sur la première page de résultat Google",
    "keywords": "mots clés",
    "percentage_keywords_in_first_page": "% de mots clés en 1ère page - %{domain}"
  },
  "de": {
    "visibility": "Sichtweite",
    "visibility_definition": "Prozentsatz der Keywords auf der ersten Seite der Google-Ergebnisse",
    "keywords": "Schlüsselwörter",
    "percentage_keywords_in_first_page": "% der Keywords auf der 1. Seite – %{domain}"
  }
}
</i18n>
