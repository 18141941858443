<template>
  <div :class="['row-container', { 'remove-border': !isLast }]">
    <span class="text-style">{{ text }}</span>
    <button
      type="button"
      class="btn btn-outline-primary btn-sm"
      @click="selectText()"
    >
      {{ $t("button") }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    isLast: Boolean,
  },
  methods: {
    selectText() {
      this.$emit("textEvent", this.text, false);
      this.$message({
        message: this.$i18n.t("selected"),
        type: "success",
        duration: 3000,
      });
    },
  },
};
</script>

<style scoped>
.row-container {
  width: auto;
  height: 50px;
  border: solid lightgray;
  border-width: 0.1em;
  padding: 10px;

  display: flex;
  justify-content: space-between;
}

.remove-border {
  border-bottom: none;
}
.row-container-btn {
  background-color: #fff;
}

.text-style {
  display: inline-flex;
  align-items: center;
  max-width: 80%;
  word-break: break-word;
}
</style>

<i18n>
    {
    "en": {
      "button": "Select",
      "selected": "text selected"
    },
    "fr": {
      "button": "Sélectionner",
      "selected": "texte sélectionné"
    },
    "de": {
      "button": "Wählen",
      "selected": "ausgewählten Text"
    }
  }
</i18n>
