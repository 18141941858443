<template>
  <el-card shadow="never">
    <div class="kpis">
      <el-tooltip
        placement="top"
        :content="$t('potential_traffic_explanation')"
      >
        <el-card
          shadow="never"
          class="kpi"
          v-loading="isLoadingMetrics"
        >
          {{ $t("potential_traffic") }}
          <span class="focus">: {{ potentialTraffic | formatNumber }}</span>
        </el-card>
      </el-tooltip>
    </div>
    <div v-loading="isLoadingGrid">
      <grid
        :study-id="studyId"
        :keywords-data="keywordsData"
        :tags="tags"
        :request="request"
        :current-user="currentUser"
        @refresh-data="refreshData"
        @show-keyword="$emit('show-keyword', $event)"
        @show-task="$emit('show-task', $event)"
        @show-page="$emit('show-page', $event)"
        @is-loading="(value) => (isLoadingGrid = value)"
      >
      </grid>
    </div>
  </el-card>
</template>

<script>
import Grid from "@/pages/Keywords/AllKeywords/Grid";

export default {
  props: {
    trackedKeywordsMetrics: Object,
    currentUser: Object,
    studyId: Number,
    request: Object,
    companyId: Number,
    tags: Array,
    isLoadingMetrics: Boolean,
  },
  components: {
    Grid,
  },
  data() {
    return {
      keywordsData: null,
      monthlyVolume: null,
      potentialTraffic: null,
      averageOpportunityScore: null,
      isLoadingGrid: false,
    };
  },
  methods: {
    refreshData() {
      this.$emit("refresh-tags");
      this.getMetrics();
    },
    getMetrics() {
      this.averageOpportunityScore = this.trackedKeywordsMetrics
        ? this.trackedKeywordsMetrics.averageOpportunityScore
        : 0;
      this.potentialTraffic = this.trackedKeywordsMetrics
        ? this.trackedKeywordsMetrics.potentialTraffic
        : 0;
    },
    emitIsLoading(loading) {
      this.$emit("is-loading", loading);
    },
  },
  mounted() {
    this.getMetrics();
  },
  watch: {
    trackedKeywordsMetrics() {
      this.getMetrics();
    },
    request() {
      this.getMetrics();
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 1rem;

  .focus {
    text-align: center;
    font-weight: 500;
  }
}
</style>

<i18n>
{
  "en": {
    "monthly_search_volume": "Median monthly search volume",
    "average_opportunity_score": "Average opportunity score",
    "potential_traffic": "Potential traffic",
    "potential_traffic_explanation": "Traffic if you were in first position on all your keywords"
  },
  "fr": {
    "monthly_search_volume": "Volume de recherche mensuel médian",
    "average_opportunity_score": "Score d'opportunité moyen",
    "potential_traffic": "Trafic potentiel",
    "potential_traffic_explanation": "Trafic si vous étiez placés en première position sur tous vos mots clés"
  },
  "de": {
    "monthly_search_volume": "Mittleres monatliches Suchvolumen",
    "average_opportunity_score": "Durchschnittliche Opportunity-Punktzahl",
    "potential_traffic": "Potenzieller Verkehr",
    "potential_traffic_explanation": "Traffic, wenn Sie bei all Ihren Keywords an erster Stelle platziert wurden"
  }
}
</i18n>
