<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      top="5vh"
      :before-close="beforeClose"
    >
      <div
        slot="title"
        class="flex-box"
      >
        <h2 class="title">
          <span style="font-weight: bold">{{ $t("textRedaction") }}</span> -
          {{ text.data.keyword.text }} - {{ text.data.name }}
        </h2>
        <div class="actions">
          <el-button
            size="small"
            @click="openInNewTab()"
            ><i class="fas fa-external-link-alt"></i>
            {{ $t("openAsPage") }}</el-button
          >
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('copyPublicLink')"
            placement="top-start"
          >
            <span>
              <el-button
                size="small"
                @click="copyToClipboard()"
                ><i class="fas fa-link"></i> {{ $t("copyLink") }}</el-button
              >
            </span>
          </el-tooltip>
        </div>
      </div>
      <content-text
        v-if="dialogVisible"
        :data="text"
        :is-stand-alone="false"
        :isPublic="false"
        :is-loading-text="isLoadingText"
        :closeDialogCallback="closeDialogCallback"
        @update-text="$emit('update-text', $event)"
        @update-content="$emit('update-content', $event)"
      >
      </content-text>
    </el-dialog>
  </span>
</template>

<script>
import ContentText from "@/components/CampaignManager/ContentText";

import { mapState } from "vuex";

export default {
  props: {
    value: Boolean,
    text: Object,
    isLoadingText: Boolean,
  },
  components: {
    ContentText,
  },
  data() {
    return {
      closeDialogCallback: null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    ...mapState(["study"]),
  },
  methods: {
    beforeClose(done) {
      this.closeDialogCallback = (
        isOldValueEqualsActualValue,
        updateText,
        updateLink
      ) => {
        if (isOldValueEqualsActualValue) {
          done();
        } else {
          this.$confirm(this.$t("saveMessageDialog"))
            .then((_) => {
              updateText(updateLink);
              done();
            })
            .catch((_) => done());
        }
      };
    },
    openInNewTab() {
      window.open(
        window.location.origin +
          "/a/" +
          this.study.id +
          "/contents/" +
          this.text.data.id +
          "/text"
      );
    },
    copyToClipboard() {
      const el = document.createElement("textarea");
      el.value = window.location.origin + this.text.links.public.href;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message({
        message: this.$i18n.t("linkCopiedToClipBoard"),
        type: "success",
        duration: 2000,
        offset: 80,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;

  .title {
    font-size: 1.5rem;
  }

  .actions {
    margin-left: auto;
    margin-right: 2rem;

    .dropdown-header {
      font-size: 1.3rem !important;
      padding-top: 0rem;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "textRedaction": "Text redaction",
    "openAsPage": "Open as page",
    "copyLink": "Public link",
    "linkCopiedToClipBoard": "Link copied to clipboard",
    "saveMessageDialog": "You haven't saved your recent changes, do you want to save them before closing ?",
    "copyPublicLink": "Copy content public link"
  },
  "fr": {
    "textRedaction": "Rédaction de contenu",
    "openAsPage": "Ouvrir dans un onglet",
    "copyLink": " Lien public",
    "linkCopiedToClipBoard": "Le lien a été copié",
    "saveMessageDialog": "Vous n'avez pas enregistré vos récentes modifications, voulez vous les enregistrer avant de fermer ?",
    "copyPublicLink": "Copier le lien public du contenu"
  },
  "de": {
    "textRedaction": "Inhalt schreiben",
    "openAsPage": "In einem Tab öffnen",
    "copyLink": "öffentlicher Link",
    "linkCopiedToClipBoard": "Der Link wurde kopiert",
    "saveMessageDialog": "Sie haben Ihre letzten Änderungen nicht gespeichert. Möchten Sie sie vor dem Schließen speichern?",
    "copyPublicLink": "Inhalt kopieren öffentlicher Link"
  }
}
</i18n>
