<template>
  <div>
    <el-tooltip
      v-if="disableCreateCompetitor && !isAlreadyCompetitor"
      :content="$t('competitorsLimitReached')"
      placement="left-start"
      transition="el-fade-in"
    >
      <span class="wrapper el-button">
        <el-button
          :disabled="disableCreateCompetitor"
          v-on:click="params.context.showDialog(params.data.url)"
          size="mini"
        >
          {{ $t("addAsCompetitor") }}
        </el-button>
      </span>
    </el-tooltip>
    <el-button
      v-else-if="!isAlreadyCompetitor"
      v-on:click="params.context.showDialog(params.data.url)"
      size="mini"
    >
      {{ $t("addAsCompetitor") }}
    </el-button>
    <el-button
      v-else
      type="info"
      plain
      disabled
      size="mini"
      >{{ $t("alreadyCompetitor") }}</el-button
    >
  </div>
</template>

<script>
export default {
  computed: {
    isAlreadyCompetitor() {
      const competitors = this.params.context.competitors;
      if (
        competitors.filter((e) => this.params.data.url.includes(e.url)).length >
        0
      ) {
        return true;
      } else {
        return false;
      }
    },
    disableCreateCompetitor() {
      return !this.params.context.studyLinks.competitors.hasOwnProperty(
        "create"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper.el-button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
}
</style>

<i18n>
{
  "en": {
    "addAsCompetitor": "Add this competitor",
    "alreadyCompetitor": "Already added",
    "competitorsLimitReached": "You have reached your maximum number of competitors, please contact us to discuss."
  },
  "fr": {
    "addAsCompetitor": "Ajouter ce concurrent",
    "alreadyCompetitor": "Concurrent déjà ajouté",
    "competitorsLimitReached": "Vous avez atteint votre nombre maximum de concurrents, veuillez nous contacter pour en discuter."
  },
  "de": {
    "addAsCompetitor": "Fügen Sie diesen Konkurrenten hinzu",
    "alreadyCompetitor": "Wettbewerber bereits hinzugefügt",
    "competitorsLimitReached": "Sie haben Ihre maximale Teilnehmerzahl erreicht, bitte kontaktieren Sie uns, um dies zu besprechen."
  }
}
</i18n>
