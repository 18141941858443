<template>
  <div>
    <div class="title">
      {{ $t(dataKey) }}
    </div>
    <div>
      <highcharts
        ref="highchart"
        :options="chartOptions"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Array,
    dataKey: String,
    request: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          useHTML: true,
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        tooltip: {
          pointFormat:
            "{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)",
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      if (this.data) {
        this.chartOptions.series = [];

        let formattedData = [];
        this.data.forEach((point) => {
          formattedData.push({
            name: point.countryName,
            y: point[this.dataKey],
            sliced:
              this.request.isoCountryCode.toLowerCase() == point.isoCountryCode,
          });
        });

        this.chartOptions.series.push({
          name: this.$i18n.t(this.dataKey),
          data: formattedData,
        });
      } else {
        this.chartOptions.series = [];
      }
    },
  },
  mounted() {
    this.updateSeries();
  },
  watch: {
    data() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>

<i18n>
{
  "en": {
    "domain_authority": "Domain Authority",
    "organicTraffic": "Organic traffic",
    "organicKeywords": "Organic keywords"
  },
  "fr": {
    "domain_authority": "Domain Authority",
    "organicTraffic": "Trafic organique",
    "organicKeywords": "Mots-clés organiques"
  },
  "de": {
    "domain_authority": "Domänenautorität",
    "organicTraffic": "Organischer Verkehr",
    "organicKeywords": "Organische Schlüsselwörter"
  }
}
</i18n>
