import Repository from "@/services/repository";

export default {
  getNetlinkingMetrics(studyId, request) {
    return Repository.get(`/a/${studyId}/netlinking_metrics.json`, {
      params: {
        request,
      },
    }).then((response) => response.data);
  },

  getNetlinkingMetricsHistory(studyId, request) {
    return Repository.get(`/a/${studyId}/netlinking_metrics_history.json`, {
      params: {
        request: JSON.stringify(request),
      },
    }).then((response) => response.data);
  },
};
