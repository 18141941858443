<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="75%"
    top="5vh"
  >
    <div v-if="metaContentsData.metaContents && metaContentsData.contentMedian">
      <div slot="title">
        <h3>
          {{ $t("contentLength") }} <strong>{{ keyword.text }}</strong>
        </h3>
      </div>
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div>
          <div v-if="metaContentsData.urlContentLength">
            {{ $t("yourPage") }} :
            <b>{{ metaContentsData.urlContentLength.contentLength }}</b>
          </div>
          <div>
            {{ $t("medianOfFirstTenResults") }} :
            <b>{{ metaContentsData.contentMedian }}</b>
          </div>
          <div>
            {{ $t("minimumOfFirstTenResults") }} :
            <b>{{ minimumOfFirstTenResults }}</b>
          </div>
          <div>
            {{ $t("maximumOfFirstTenResults") }} :
            <b>{{ maximumOfFirstTenResults }}</b>
          </div>
          <h5 class="mt-2">
            {{ $t("scoreCalculatedAt", { date: formattedDateLocale }) }}
          </h5>
        </div>
        <slot />
      </div>

      <div class="m-3">
        <div
          v-for="(reco, index) in contentLengthRecommendations"
          v-bind:key="index"
        >
          {{ reco }}
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          prop="contentLength"
          :label="$t('contentLength')"
          width="200"
          class-name="text-center"
        >
          <template slot-scope="scope">
            <span :class="isTopThree(scope.row.contentLength)">
              {{ scope.row.contentLength }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="url"
          label="URL"
          class-name="wrap-text"
        >
          <template slot-scope="scope">
            <a
              :href="scope.row.url"
              target="_blank"
              >{{ scope.row.url }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="lastCrawlDate"
          :label="$t('fetchedAt')"
          width="150"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.lastCrawlDate }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      v-else
      class="text-center"
      style="height: 75vh"
      v-loading="true"
    />
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    keyword: Object,
    metaContentsData: Object,
  },

  methods: {
    isTopThree(contentLength) {
      if (this.metaContentsData && this.metaContentsData.metaContents) {
        let arr =
          this.metaContentsData &&
          this.metaContentsData.metaContents.map(
            (serpDetail) => serpDetail.contentLength
          );
        arr.sort(function (a, b) {
          return a - b;
        });
        let top3Content = arr.slice(-3);
        if (top3Content.includes(contentLength)) return "font-weight-bold";
        else return "";
      }
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    minimumOfFirstTenResults() {
      if (this.metaContentsData.metaContents) {
        return Math.min(
          ...this.metaContentsData.metaContents.map(
            (element) => element.contentLength
          )
        );
      }
    },
    maximumOfFirstTenResults() {
      if (this.metaContentsData.metaContents) {
        return Math.max(
          ...this.metaContentsData.metaContents.map(
            (element) => element.contentLength
          )
        );
      }
    },
    formattedDateLocale() {
      if (this.metaContentsData && this.metaContentsData.crawlDate) {
        return new Date(this.metaContentsData.crawlDate).toLocaleDateString(
          this.$i18n.locale
        );
      }
    },
    contentLengthRecommendations() {
      let recommendations = [];
      if (this.metaContentsData && this.metaContentsData.urlContentLength) {
        const contentLength =
          this.metaContentsData.urlContentLength.contentLength;
        if (contentLength >= this.metaContentsData.contentMedian) {
          recommendations.push(
            this.$i18n.t("contentLengthIsGreaterThanMedian")
          );
        } else {
          recommendations.push(this.$i18n.t("contentLengthIsLesserThanMedian"));
        }
      }
      return recommendations;
    },
    tableData() {
      if (this.metaContentsData && this.metaContentsData.metaContents) {
        return this.metaContentsData.metaContents.map((serpDetail) => {
          if (serpDetail.contentLength) {
            return {
              url: serpDetail.url,
              contentLength: serpDetail.contentLength,
              lastCrawlDate: serpDetail.lastCrawlDate,
            };
          } else {
            return {
              url: serpDetail.url,
              contentLength: 0,
              lastCrawlDate: serpDetail.lastCrawlDate,
            };
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
    "en": { 
        "contentLengthIsGreaterThanMedian": "👍 Your content is larger than the content of the 1st page, it's very good!",
        "contentLengthIsLesserThanMedian": "🔧 The size of your content is inferior to the median size of the first page, it would be nice to enrich your content.",
        "wordsLength": "{length} words",
        "contentLength": "Content length (words)",
        "numberOfWords": "Number of words",
        "yourPage": "Your page",
        "medianOfFirstTenResults": "Median of the first 10 results",
        "maximumOfFirstTenResults": "Maximum of the first 10 results",
        "minimumOfFirstTenResults": "Minimum of the first 10 results",
        "scoreCalculatedAt":"Score calculated on: {date}",
        "tagH1": "H1 tag",
        "h1sOfCompetitors": "Competitors' H1s",
        "yourH1": "Your H1",
        "fetchedAt": "Fetched on"
    },
    "fr": { 
        "contentLengthIsGreaterThanMedian": "👍 Votre contenu a une taille supérieure au contenu de la 1ère page, c'est très bien!",
        "contentLengthIsLesserThanMedian": "🔧 Votre contenu a une taille inférieure à la médiane de la 1ère page, il serait bon de l’enrichir.",
        "wordsLength": "{length} mots",
        "contentLength": "Taille du contenu (mots)",
        "numberOfWords": "Nombre de mots",
        "yourPage": "Votre page",
        "scoreCalculatedAt":"Score calculé le: {date}",
        "medianOfFirstTenResults": "Mediane des 10 premiers résultats",
        "maximumOfFirstTenResults": "Maximum des 10 premiers résultats",
        "minimumOfFirstTenResults": "Minimum des 10 premiers résultats",
        "tagH1": "Balise H1",
        "h1sOfCompetitors": "Balises H1 de vos concurrents : inspirez-vous !",
        "yourH1": "Your H1 tag",
        "fetchedAt": "Récupérée le "
  },
  "de": {
    "wordsLength": "{length} Wörter",
    "contentLength": "Länge des Inhalts (Wörter)",
    "numberOfWords": "Anzahl der Wörter",
    "yourPage": "Deine Seite",
    "scoreCalculatedAt": "Ergebnis berechnet am: {date}",
    "medianOfFirstTenResults": "Median der ersten 10 Ergebnisse",
    "maximumOfFirstTenResults": "Maximum der ersten 10 Ergebnisse",
    "minimumOfFirstTenResults": "Minimum der ersten 10 Ergebnisse",
    "tagH1": "H1-Tag",
    "h1sOfCompetitors": "Konkurrenten H1-Tags",
    "yourH1": "Dein H1-Tag",
    "fetchedAt": "Erholte sich am"
  }
}
</i18n>
