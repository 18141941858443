<template>
  <div>
    <h2>Meilleurs mots-clés</h2>

    <el-form label-width="120px">
      <el-form-item
        label="Étude"
        class="study"
      >
        <el-select
          v-model="bestKeywordsStudyId"
          filterable
        >
          <el-option
            v-for="item in studyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <ag-grid-vue
      v-loading="isLoading"
      style="height: 300px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="bestKeywords"
      @gridReady="onGridReady"
    ></ag-grid-vue>

    <el-button
      plain
      type="primary"
      :disabled="!(bestKeywords.length > 0)"
      @click="exportBestKeywords"
      ><i class="fas fa-file-excel"></i>Exporter</el-button
    >
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    AgGridVue,
  },
  props: {
    studyOptions: Array,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "Urls",
          field: "LandingPage",
        },
        {
          headerName: "Meilleur mot-clé",
          field: "BestKeyword",
        },
      ],
      defaultColDef: { sortable: true, resizable: true },
      gridApi: null,
      bestKeywordsStudyId: null,
      bestKeywords: [],
      isLoading: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    fetchBestKeywords(studyId) {
      this.isLoading = true;
      this.$api
        .get(`/studies/${studyId}/traffic/best-keywords`)
        .then((response) => {
          this.bestKeywords = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    exportBestKeywords() {
      const params = {
        fileName: "Meilleurs mots-clés - SmartKeyword",
        sheetName: "Meilleurs mots-cles",
      };
      this.gridApi.exportDataAsExcel(params);
    },
  },
  watch: {
    bestKeywordsStudyId(value) {
      this.fetchBestKeywords(value);
    },
  },
};
</script>
