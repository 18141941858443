<template>
  <el-dialog
    :title="title"
    width="50%"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
  >
    <dynamic-form
      v-model="formModel"
      :form="bulkUpdate.form"
    />
    <backlink-change-to-domain-refused-form
      v-if="isDomainRefused()"
      :bulkUpdate="bulkUpdate"
      ref="domainRefusedComponent"
    />
    <div class="d-flex justify-content-end">
      <el-button
        type="success"
        plain
        @click="submitForm()"
      >
        {{ $t("validate") }}
      </el-button>
    </div>
    <publication-pending-form-dialog
      v-model="isDialogPublicationPendingVisible"
      @submit-form="submitFormWithPublicationPending($event)"
    >
    </publication-pending-form-dialog>
  </el-dialog>
</template>

<script>
import DynamicForm from "@/components/DynamicForm";
import PublicationPendingFormDialog from "@/components/CampaignManager/PublicationPendingFormDialog";
import BacklinkChangeToDomainRefusedForm from "@/components/CampaignManager/BacklinkChangeToDomainRefusedForm.vue";
import DatePicker from "@/pages/CampaignManager/FormItems/DatePicker.vue";

export default {
  components: {
    DatePicker,
    DynamicForm,
    PublicationPendingFormDialog,
    BacklinkChangeToDomainRefusedForm,
  },
  props: {
    bulkUpdate: Object,
    value: Boolean,
    isContent: Boolean,
  },
  data() {
    return {
      formModel: {},
      pickerDateOptions: {
        disabledDate: (date) => date.getTime() < Date.now(),
      },
      isDialogPublicationPendingVisible: false,
      formRef: undefined,
    };
  },
  methods: {
    submitForm() {
      if (
        this.formModel.status &&
        this.formModel.status === "PUBLICATION_PENDING" &&
        this.formModel.reasonOfDelay == null
      ) {
        this.isDialogPublicationPendingVisible = true;
      } else if (this.isDomainRefused()) {
        this.handleDomainRefusedSubmission();
      } else {
        this.sendForm();
      }
    },
    getChildForm() {
      return this.$refs.domainRefusedComponent.formModel;
    },
    submitFormWithPublicationPending(publicationPendingForm) {
      this.isDialogPublicationPendingVisible = false;
      this.formModel.reasonOfDelay = publicationPendingForm.reasonOfDelay;
      this.formModel.detailsOfDelay = publicationPendingForm.detailsOfDelay;
      this.submitForm();
    },
    isDomainRefused() {
      return this.formModel.status === "REFUSED";
    },
    addDomainRefusedFieldsToForm() {
      this.formModel.detailsOfRefusal = this.getChildForm().detailsOfRefusal;
      this.formModel.reasonOfRefusal = this.getChildForm().reasonOfRefusal;
    },
    sendForm() {
      this.$emit("bulk-update-call", this.formModel);
      this.formModel = {};
      this.dialogVisible = false;
    },
    handleDomainRefusedSubmission() {
      if (this.$refs.domainRefusedComponent.$refs.formRef) {
        this.$refs.domainRefusedComponent.$refs.formRef.validate((valid) => {
          if (valid) {
            this.addDomainRefusedFieldsToForm();
            this.sendForm();
          }
        });
      } else {
        return false;
      }
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    title() {
      return (
        this.$t("bulkChange") +
        " " +
        (this.isContent ? this.$t("contents") : this.$t("backlinks"))
      );
    },
    statusOptions() {
      return this.bulkUpdate.form.find((element) => element.name === "status")
        .options;
    },
  },
};
</script>

<style></style>

<i18n>
{
  "en": {
    "bulkChange": "Bulk Change",
    "validate": "Validate",
    "contents": "Contents",
    "backlinks": "Backlinks"
  },
  "fr": {
    "bulkChange": "Changement Multiple",
    "validate": "Valider",
    "contents": "Contenus",
    "backlinks": "Backlinks"
  },
  "de": {
    "bulkChange": "Mehrfache Änderung",
    "validate": "Bestätigen",
    "contents": "Inhalt",
    "backlinks": "Backlinks"
  }
}
</i18n>
