export const errorHandlerMixin = {
  methods: {
    handleError(error) {
      const locale = this.$i18n.locale;
      if (error.response.data.hasOwnProperty("errors")) {
        error.response.data.errors.forEach((error) => {
          switch (error.code) {
            case "MISSING_FIELDS":
              if (error && error.meta) {
                let htmlMessageMissingFields =
                  "<p>" + error.title + " :" + "</p>" + "<ul>";
                error.meta.forEach((field) => {
                  htmlMessageMissingFields =
                    htmlMessageMissingFields + "<li>" + field + "</li>";
                });
                htmlMessageMissingFields = htmlMessageMissingFields + "</ul>";

                const alertTitle =
                  locale == "fr"
                    ? "Des champs sont manquants"
                    : "There are missing fields";

                this.$alert(htmlMessageMissingFields, alertTitle, {
                  confirmButtonText: "OK",
                  dangerouslyUseHTMLString: true,
                  type: "error",
                });
              }
              break;
            default:
              console.log(error);
              this.$message({
                message:
                  locale == "fr"
                    ? "Nous avons rencontré une erreur"
                    : "We've encountered an error",
                type: "error",
                duration: 6000,
              });
              break;
          }
        });
      } else {
        console.log(error);
        this.$message({
          message:
            locale == "fr"
              ? "Nous avons rencontré une erreur"
              : "We've encountered an error",
          type: "error",
          duration: 6000,
        });
      }
    },
  },
};
