<template>
  <div>
    <highcharts
      ref="highchart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import { DateTime } from "luxon";

export default {
  props: {
    mainSeries: Array,
    title: String,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "area",
          height: 300,
          width: 350,
        },
        title: {
          text: this.title,
        },
        yAxis: {
          title: {
            text: null,
          },
          min: 0,
          max: 100,
        },
        xAxis: [
          {
            type: "datetime",
          },
        ],
        legend: {
          enabled: false,
        },
        series: [
          {
            data: this.mainSeries.map((member) => {
              return {
                x: new Date(member.date),
                y: member.value,
                date: member.date,
              };
            }),
          },
        ],
        tooltip: {
          headerFormat: "",
          pointFormatter: function () {
            return this.date + ": " + this.y;
          },
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2,
                },
              },
            },
            fillOpacity: 0.25,
          },
          column: {
            negativeColor: "#910000",
            borderColor: "silver",
          },
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>
