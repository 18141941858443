<template>
  <div>
    <el-button
      v-if="actions && actions.update"
      v-on:click.stop="$emit('edit', actions.update)"
      class="button"
      size="small"
      type="primary"
      plain
    >
      <i class="fas fa-edit"></i> {{ $t("modify") }}
    </el-button>
  </div>
</template>
<script>
export default {
  props: {
    actions: Object,
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 1rem;
}
</style>

<i18n>
{
  "en": {
    "modify": "Edit"
  },
  "fr": {
    "modify": "Modifier"
  },
  "de": {
    "modify": "Modifikator"
  }
}
</i18n>
