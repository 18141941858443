<template>
  <div>
    <app-introduction :title="$t('textRedaction')"></app-introduction>
    <div class="container">
      <el-card>
        <content-text
          v-if="content"
          :is-stand-alone="true"
          :isPublic="true"
          :is-loading-text="isLoadingText"
          :data="content"
          @update-text="updateText($event)"
          @update-content="updateContent($event)"
        >
          <template v-slot:title>
            <div class="title">
              <span style="font-weight: bold">{{ $t("contentText") }}</span> -
              {{ content.data.keyword.text }}
              <el-button
                type="primary"
                plain
                style="margin-left: auto"
                @click="openBriefPage()"
                >{{ $t("openBrief") }}</el-button
              >
            </div>
          </template>
        </content-text>
      </el-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import AppIntroduction from "@/components/AppIntroduction";
import ContentText from "@/components/CampaignManager/ContentText";

export default {
  components: {
    AppIntroduction,
    ContentText,
  },
  data() {
    return {
      content: null,
      isLoadingText: false,
      translatedLanguages: ["fr", "en", "it", "de", "nl", "es"],
    };
  },
  methods: {
    fetchContent() {
      const contentId = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      );
      if (contentId) {
        axios
          .get(
            "https://" + process.env.API_HOST + "/contents/public/" + contentId
          )
          .then((response) => {
            this.content = response.data;
          });
      }
    },
    updateText(details) {
      this.isLoadingText = true;
      axios[details.link.method.toLowerCase()](
        "https://" + process.env.API_HOST + details.link.href,
        details.entity
      )
        .then((response) => {
          this.content.data.text = { ...response.data.data };
          if (details.link.method.toLowerCase() == "put") {
            this.$message({
              message: this.$i18n.t("textSaved"),
              type: "success",
              duration: 6000,
            });
          }
          this.isLoadingText = false;
        })
        .catch((error) => {
          this.isLoadingText = false;
          console.error(error);
        });
    },
    updateContent(details) {
      this.isLoadingText = true;
      axios[details.link.method.toLowerCase()](
        "https://" + process.env.API_HOST + details.link.href,
        details.entity
      )
        .then((response) => {
          this.content = response.data;
          this.isLoadingText = false;
          this.$message({
            message: this.$i18n.t("contentUpdated"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoadingText = false;
        });
    },
    openBriefPage() {
      const contentId = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      );
      window.open(
        "https://" +
          "app.smartkeyword.io/contents/public/" +
          contentId +
          "/brief"
      );
    },
  },
  beforeMount() {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang) {
      if (
        this.translatedLanguages.includes(
          userLang.substring(0, 2).toLowerCase()
        )
      ) {
        this.$root.$i18n.locale = userLang;
      } else {
        this.$root.$i18n.locale = "en";
      }
    } else {
      this.$root.$i18n.locale = "en";
    }
  },
  mounted() {
    this.fetchContent();
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
</style>

<i18n>
{
    "en": {
        "textRedaction": "Text redaction",
        "contentText": "Content text",
        "textSaved": "Text has been saved",
        "contentUpdated":"Content updated",
        "openBrief": "See the brief"
    },
    "fr": {
        "textRedaction": "Rédaction de contenu",
        "contentText": "Texte du contenu",
        "textSaved": "Texte sauvegardé",
        "contentUpdated":"Contenu mis à jour",
        "openBrief": "Ouvrir le brief"
    },
    "it": {
        "textRedaction": "Scrittura di contenuti",
        "contentText": "Testo contenuto",
        "textSaved": "Testo salvato",
        "contentUpdated":"Contenuto aggiornato",
        "openBrief": "Vedi il riassunto"
    },
    "de": {
        "textRedaction": "Textschwärzung",
        "contentText": "Inhaltstext",
        "textSaved": "Text wurde gespeichert",
        "contentUpdated":"Inhalt aktualisiert",
        "openBrief": "Siehe die kurzbeschreibung"
    },
    "nl": {
        "textRedaction": "Tekstredactie",
        "contentText": "Inhoud tekst",
        "textSaved": "Tekst is opgeslagen",
        "contentUpdated":"Inhoud bijgewerkt",
        "openBrief": "Zie de brief"
    },
    "es": {
        "textRedaction": "Redacción de texto",
        "contentText": "Texto de contenido",
        "textSaved": "El texto ha sido guardado",
        "contentUpdated":"Contenido actualizado",
        "openBrief": "Ver el resumen"
    }
}
</i18n>
