<template>
  <div class="center">
    <el-tooltip
      v-if="params.value == 'new'"
      placement="top"
    >
      <div slot="content">
        {{ $t("new_backlink") }}
      </div>
      <span>
        <i
          class="fa fa-plus new"
          aria-hidden="true"
        ></i>
      </span>
    </el-tooltip>
    <el-tooltip
      v-else
      placement="top"
    >
      <div slot="content">
        {{ $t("lost_backlink") }}
      </div>
      <span>
        <i
          class="fa fa-times lost"
          aria-hidden="true"
        ></i>
      </span>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.center {
  text-align: center;

  .lost {
    color: $--color-danger;
  }

  .new {
    color: $--color-success;
  }
}
</style>

<i18n>
{
  "en": {
    "new_backlink": "New backlink",
    "lost_backlink": "Lost backlink"
  },
  "fr": {
    "new_backlink": "Nouveau backlink",
    "lost_backlink": "Backlink perdu"
  },
  "de": {
    "new_backlink": "Nouveau-Backlink",
    "lost_backlink": "Backlink perdu"
  }
}
</i18n>
