<template>
  <div class="d-flex flex-row justify-content-between align-items-center p-2">
    <span class="input-title">{{ $t("content") }}</span>
    <el-switch
      v-model="switchValue"
      :active-text="$t('switchHtmlView')"
      :inactive-text="$t('switchMarkdownView')"
      @input="$emit('html-view-changed', $event)">
    </el-switch>
  </div>
</template>
<script>
export default {
  data(){
    return {
      switchValue:false,
    }
  }
};
</script>
<i18n>
{
  "en": {
    "switchHtmlView": "Display the Html view",
    "switchMarkdownView": "Display the Markdown view"
  },
  "fr": {
    "switchHtmlView": "Afficher la vue HTML",
    "switchMarkdownView":  "Afficher la vue Markdown"
  },
  "de": {
    "switchHtmlView": "Zeigt die HTML-Ansicht an",
    "switchMarkdownView":  "die Markdown-Ansicht anzeigen"
  }
}
</i18n>