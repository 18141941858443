<template>
  <el-form-item :label="$t('keywords')">
    <el-tag
      v-for="keyword in value"
      :key="keyword"
      size="small"
      closable
      @close="removeKeyword(keyword)"
    >
      {{ keyword }}
    </el-tag>
    <el-input
      v-if="keywordTextInputVisible"
      v-model="keywordText"
      ref="keywordTextInput"
      size="mini"
      @keyup.enter.stop.prevent.native="addKeyword"
      @blur="addKeyword"
    >
    </el-input>
    <el-button
      v-else
      class="add-value-button"
      @click="showKeywordTextInput"
      >+ {{ $t("add_keyword") }}</el-button
    >
  </el-form-item>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      keywordText: "",
      keywordTextInputVisible: false,
    };
  },
  methods: {
    addKeyword() {
      if (this.keywordText) {
        const keyword = this.keywordText;
        this.value.push(keyword);
      }
      this.keywordTextInputVisible = false;
      this.keywordText = "";
    },
    removeKeyword(keyword) {
      this.value.splice(this.value.indexOf(keyword), 1);
    },
    showKeywordTextInput() {
      this.keywordTextInputVisible = true;
      this.$nextTick((_) => {
        this.$refs.keywordTextInput.$refs.input.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin: 0 0.25rem 0.25rem 0;
}

.el-input {
  width: 150px;

  ::v-deep .el-input__inner {
    height: 24px;
    line-height: 22px;
  }
}

.add-value-button {
  font-size: 12px;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
}
</style>

<i18n>
{
  "en": {
    "keywords": "Keywords",
    "add_keyword": "Add keyword"
  },
  "fr": {
    "keywords": "Mots clés",
    "add_keyword": "Ajouter mot clé"
  },
  "de": {
    "keywords": "Schlüsselwörter",
    "add_keyword": "Stichwort hinzufügen"
  }
}
</i18n>
