<template>
  <div v-loading="isLoading">
    <div class="chart-title">
      <span class="chart-name"
        >{{ $t("average_position") }}
        <a
          v-if="user && user.locale === 'fr'"
          target="blank"
          href="https://docs.smartkeyword.io/fr/articles/900423-position-moyenne"
        >
          <i class="fas fa-question-circle"></i>
        </a>
      </span>
      <span
        class="value"
        v-if="averagePositionValue"
      >
        {{ averagePositionValue }}
      </span>
      <span
        class="change"
        v-if="averagePositionChange"
      >
        <span
          v-if="averagePositionChange > 0"
          class="negative-change"
          ><i class="fas fa-arrow-down"></i>-{{ averagePositionChange }}</span
        >
        <span
          v-else-if="averagePositionChange == 0"
          class="neutral-change"
          ><i class="fas fa-equals"></i
        ></span>
        <span
          v-else-if="averagePositionChange < 0"
          class="positive-change"
          ><i class="fas fa-arrow-up"></i>{{ 0 - averagePositionChange }}</span
        >
      </span>
    </div>
    <highcharts
      ref="highchart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Array,
    isLoading: Boolean,
    user: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      averagePositionValue: null,
      averagePositionChange: null,
      chartOptions: {
        chart: {
          height: 300,
          zoomType: "x",
        },
        title: {
          text: "",
          useHTML: true,
        },
        xAxis: [
          {
            type: "datetime",
          },
          {
            labels: {
              rotation: -45,
            },
            linkedTo: 0,
            opposite: true,
            type: "datetime",
          },
        ],
        yAxis: {
          title: {
            text: this.$i18n.t("average_position"),
          },
          endOnTick: false,
          reversed: true,
          minTickInterval: 1,
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      if (this.data && this.data.length > 0) {
        this.chartOptions.series = [];
        let nbKeywordsPerDate = [];
        let nbNewKeywordsPerDate = new Map();
        let lastNbKeywords = 0;

        const avgPositionData = [];
        this.data.forEach((element) => {
          avgPositionData.push({
            x: element.date.getTime(),
            y: Math.round(element.avgPosition),
          });

          nbKeywordsPerDate.push(element.nbKeywords);
          if (
            nbKeywordsPerDate.length > 1 &&
            element.nbKeywords - lastNbKeywords !== 0
          ) {
            let diff = element.nbKeywords - lastNbKeywords;
            let prefix = diff > 0 ? "+" : "";
            nbNewKeywordsPerDate.set(
              element.date.getTime(),
              prefix + diff + " " + this.$i18n.t("keywords")
            );
          }
          lastNbKeywords = element.nbKeywords;
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("average_position"),
          data: avgPositionData,
        });
        this.chartOptions.xAxis[1].tickPositions = Array.from(
          nbNewKeywordsPerDate.keys()
        );
        this.chartOptions.xAxis[1].labels.formatter = function () {
          return nbNewKeywordsPerDate.get(this.value);
        };

        this.averagePositionValue =
          avgPositionData[avgPositionData.length - 1].y;
        this.averagePositionChange =
          avgPositionData[avgPositionData.length - 1].y - avgPositionData[0].y;
      } else {
        this.chartOptions.series = [];
        this.averagePositionValue = null;
        this.averagePositionChange = null;
      }
    },
  },
  mounted() {
    this.updateSeries();
  },
  watch: {
    data() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.chart-title {
  text-align: center;
  margin-bottom: 1.2rem;

  .value {
    font-size: 1.2rem;
    margin-left: 0.35rem;
  }

  .chart-name {
    font-size: 1.5rem;
  }

  .fa-question-circle {
    font-size: 1.1rem;
  }

  .change {
    font-size: 0.9rem;

    .positive-change {
      color: $--color-success;
    }

    .neutral-change {
      color: $--color-warning;
    }

    .negative-change {
      color: $--color-danger;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "keywords": "keywords",
    "average_position": "Average ranking"
  },
  "fr": {
    "keywords": "mots clés",
    "average_position": "Position Moyenne"
  },
  "de": {
    "keywords": "Schlüsselwörter",
    "average_position": "Durchschnittliche Position"
  }
}
</i18n>
