<template>
  <el-card>
    <h2>{{ $t("invoices") }}</h2>
    <div class="grid">
      <ag-grid-vue
        :domLayout="'autoHeight'"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="invoices"
        :resizable="true"
        :sorting="true"
        :filter="true"
        :localeTextFunc="localeTextFunc"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        @gridSizeChanged="adjustGrid"
        @gridReady="onGridReady"
        @firstDataRendered="adjustGrid"
        @rowDataChanged="adjustGrid"
      >
      </ag-grid-vue>
    </div>
  </el-card>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { RepositoryFactory } from "@/services/repositoryFactory";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

export default {
  mixins: [agGridMixin],
  props: {
    invoices: Array,
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      defaultColDef: {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      },
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("invoice_date"),
          field: "displayDate",
          minWidth: 50,
        },
        {
          headerName: this.$i18n.t("invoice_name"),
          field: "name",
          filter: "agTextColumnFilter",
          cellRenderer: function (params) {
            return `<a href="${params.data.link}" target="_blank">${params.data.name}</a>`;
          },
          minWidth: 200,
        },
        {
          headerName: this.$i18n.t("invoice_subject"),
          field: "subject",
          filter: "agTextColumnFilter",
          minWidth: 400,
        },
        {
          headerName: this.$i18n.t("invoice_amount"),
          field: "totalAmount",
          cellRenderer: function (params) {
            return `${params.data.displayTotalAmount}`;
          },
          filter: "agNumberColumnFilter",
          type: "numericColumn",
          minWidth: 50,
        },
        {
          headerName: this.$i18n.t("invoice_status"),
          field: "status",
          filter: "agTextColumnFilter",
          cellRenderer: this.statusRenderer,
          minWidth: 50,
        },
      ];
    },
    statusRenderer(params) {
      switch (params.value) {
        case "due":
          return `<span class="status_due">${this.$i18n.t(
            "status_due"
          )}</span>`;
        case "payinprogress":
          return `<span class="status_payinprogress">${this.$i18n.t(
            "status_payinprogress"
          )}</span>`;
        case "paid":
          return `<span class="status_paid">${this.$i18n.t(
            "status_paid"
          )}</span>`;
        case "late":
          return `<span class="status_late">${this.$i18n.t(
            "status_late"
          )}</span>`;
      }
    },
  },
  beforeMount() {
    this.setColumnDefs();
  },
};
</script>

<style scoped lang="scss">
.actions {
  margin-bottom: 1rem;
}

::v-deep .status_due {
  font-weight: bold;
  color: orange;
}
::v-deep .status_payinprogress {
  font-weight: bold;
  color: orange;
}
::v-deep .status_paid {
  font-weight: bold;
  color: green;
}
::v-deep .status_late {
  font-weight: bold;
  color: red;
}

.grid {
  ::v-deep a + .el-button {
    margin-left: 10px;
  }

  .text-center {
    text-align: center;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "invoices": "Invoices",
    "invoice_date": "Date",
    "invoice_name": "Reference",
    "invoice_subject": "Description",
    "invoice_amount": "Amount",
    "invoice_status": "Status",
    "status_due": "To be paid",
    "status_payinprogress": "Partial payment",
    "status_paid": "Paid",
    "status_late": "Overdue"
  },
  "fr": {
    "invoices": "Factures",
    "invoice_date": "Date",
    "invoice_name": "Référence",
    "invoice_subject": "Description",
    "invoice_amount": "Montant TTC",
    "invoice_status": "Statut",
    "status_due": "A payer",
    "status_payinprogress": "Paiement partiel",
    "status_paid": "Payée",
    "status_late": "En retard"
  },
  "de": {
    "invoices": "Rechnungen",
    "invoice_date": "Datum",
    "invoice_name": "Referenz",
    "invoice_subject": "Beschreibung",
    "invoice_amount": "Betrag inkl. MwSt",
    "invoice_status": "Status",
    "status_due": "Ein Zahler",
    "status_payinprogress": "Teilzahlung",
    "status_paid": "Bezahlt",
    "status_late": "in Verzug"
  }
}
</i18n>
