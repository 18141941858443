<template>
  <el-card shadow="never">
    <el-row>
      <el-col :span="20">
        <el-form
          size="mini"
          label-width="30px"
          @submit.native.prevent
        >
          <urls v-model="urls"></urls>
        </el-form>
        <el-form
          size="mini"
          @submit.native.prevent
        >
          <filters
            v-model="keywordFilters"
            class="filters-form-item"
          ></filters>
        </el-form>
        <el-form
          size="mini"
          @submit.native.prevent
        >
          <classAdwordsTree
            v-model="classAdwordFilters"
            class="filters-form-item"
          ></classAdwordsTree>
        </el-form>
      </el-col>
      <el-col :span="4">
        <div class="action">
          <el-button
            plain
            type="primary"
            @click="submit"
            >{{ $t("search") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import Urls from "@/pages/KeywordsSearch/Shared/FormItems/Urls";
import Filters from "@/pages/KeywordsSearch/Shared/FormItems/Filters";
import ClassAdwordsTree from "@/pages/KeywordsSearch/Shared/FormItems/ClassAdwordsTree";

export default {
  components: {
    Urls,
    Filters,
    ClassAdwordsTree,
  },
  data() {
    return {
      urls: [],
      keywordFilters: [],
      classAdwordFilters: [],
    };
  },
  methods: {
    submit() {
      const request = {
        computeKeywordsBroad: true,
        fromUrls: this.urls,
        keywordsSortType: "MATCHING_DOMAINS",
        excludeKeywords: this.keywordFilters,
        fromClassAdwords: this.classAdwordFilters,
      };
      this.$emit("submit", request);
    },
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}

.action {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "search": "Search"
  },
  "fr": {
    "search": "Rechercher"
  },
  "de": {
    "search": "Forschen"
  }
}
</i18n>
