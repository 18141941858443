<template>
  <div class="backlink">
    <div class="flex-box">
      <h4 class="title"><strong>Backlinks</strong></h4>
      <div class="flex-box margin-left-auto">
        <slot name="entityButton"></slot>
        <slot name="exportButton"></slot>
      </div>
    </div>
    <div>
      <el-button
        style="margin: 20px 0 0 5px"
        class="select-all"
        @click="selectAllRows"
        size="mini"
        type="primary"
        plain
      >
        <i class="fas fa-list"></i>
        {{ $t("selectAll") }}
      </el-button>
    </div>
    <div class="grid">
      <ag-grid-vue
        domLayout="autoHeight"
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :context="context"
        rowSelection="multiple"
        :enableRangeSelection="true"
        :copyHeadersToClipboard="false"
        :suppressCopyRowsToClipboard="true"
        :suppressRowClickSelection="true"
        :masterDetail="true"
        :detailCellRendererFramework="'DetailedBacklinkRenderer'"
        :getRowHeight="getRowHeight"
        :resizable="true"
        :sorting="true"
        :filter="true"
        :floatingFilter="true"
        :rowClassRules="rowClassRules"
        :localeTextFunc="localeTextFunc"
        :suppressCellSelection="true"
        :suppressContextMenu="true"
        :pagination="true"
        :paginationPageSize="100"
        @pagination-changed="onPaginationChanged"
        :suppressPaginationPanel="true"
        @rowClicked="rowClicked"
        @gridReady="onGridReady"
        @firstDataRendered="adjustGrid"
        @gridSizeChanged="adjustGrid"
        @rowDataChanged="onRowDataUpdated"
        @selection-changed="selectionChanged"
        @filterChanged="onFilterChanged"
      >
      </ag-grid-vue>
    </div>
    <div class="grid-pagination mt-4">
      <span>{{ $t("numberOfBacklinksPerPage") }}</span>
      <el-select
        v-model="paginationSize"
        @change="(value) => setNewPaginationSize(value)"
        size="mini"
        style="width: 70px"
      >
        <el-option
          v-for="item in paginationSizeOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-divider
        direction="vertical"
        class="divider"
      ></el-divider>
      <span id="numberEntities">{{ firstIndex }} - {{ lastIndex }}</span>
      <el-divider
        direction="vertical"
        class="divider"
      ></el-divider>
      <el-button
        size="mini"
        @click="firstPage()"
        :disabled="firstPageDisabled"
        ><i class="fas fa-angle-double-left"></i
      ></el-button>
      <el-button
        size="mini"
        @click="previousPage()"
        :disabled="firstPageDisabled"
        ><i class="fas fa-angle-left"></i
      ></el-button>
      <span
        class="value-page"
        id="lbCurrentPage"
        >Page {{ pageNumber }}</span
      >
      <el-button
        size="mini"
        @click="nextPage()"
        :disabled="nextPageDisabled"
        ><i class="fas fa-angle-right"></i>
      </el-button>
      <el-button
        id="lastPage"
        @click="lastPage()"
        size="mini"
        :disabled="lastPageDisabled || nextPageDisabled"
        ><i class="fas fa-angle-double-right"></i
      ></el-button>
    </div>
    <refuse-backlink-dialog
      v-model="isRefuseBacklinkDialogVisible"
      @refuseBacklink="refuseBacklink($event)"
    >
    </refuse-backlink-dialog>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import { gridPagination } from "@/mixins/gridPagination";
import DetailedBacklinkRenderer from "@/pages/CampaignManager/Backlinks/Renderer/DetailedBacklinkRenderer";
import ActionsSelector from "@/pages/CampaignManager/Backlinks/Renderer/ActionsSelector";
import BacklinkStatus from "@/pages/CampaignManager/Backlinks/Renderer/BacklinkStatus";
import QualityRenderer from "@/pages/CampaignManager/Backlinks/Renderer/QualityRenderer";
import TopicalTFRenderer from "@/pages/CampaignManager/Backlinks/Renderer/TopicalTf";
import LinkRenderer from "@/pages/CampaignManager/Backlinks/Renderer/LinkRenderer";
import SourceUrlLinkRenderer from "@/pages/CampaignManager/Backlinks/Renderer/SourceUrlLinkRenderer";
import RefuseBacklinkDialog from "@/components/CampaignManager/RefuseBacklinkDialog";
import { mapState } from "vuex";

export default {
  mixins: [agGridMixin, gridPagination],
  components: {
    AgGridVue,
    DetailedBacklinkRenderer,
    ActionsSelector,
    BacklinkStatus,
    QualityRenderer,
    LinkRenderer,
    TopicalTFRenderer,
    SourceUrlLinkRenderer,
    RefuseBacklinkDialog,
  },
  props: {
    companyId: Number,
    data: Array,
    admin: Boolean,
    selectedFilter: String
  },

  data() {
    return {
      dialogVisible: false,
      rowData: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      rowClassRules: null,
      context: null,
      defaultColDef: { sortable: true, resizable: true },
      paginationSize: 100,
      paginationSizeOptions: [50, 100, 150],
      isRefuseBacklinkDialogVisible: false,
      backlinkToRefuse: null,
    };
  },
  computed: {
    ...mapState(["userLocale"]),
  },
  methods: {
    onFilterChanged($event) {
      if (this.$parent.isSettingFilter) {
        return;
      }
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel && Object.keys(this.gridApi.getFilterModel(filterModel).length > 0)) {
        this.$emit("save-selected-fitlers", filterModel)
      }
      if (this.gridApi.getFilterModel() && (Object.keys(this.gridApi.getFilterModel()).length === 0)){
        this.$emit("reset-selected-filter");
      }
    },
    onRowDataUpdated() {
      if (this.$parent.savedFilterModel) {
        this.$parent.isSettingFilter = true;
        this.gridApi.setFilterModel(this.$parent.savedFilterModel);
        this.$parent.isSettingFilter = false;
      }
    },
    setFilter(filterValue) {
      let filters = {
        status: {
          filterType: "set",
          values: [this.$t(filterValue.toLowerCase())],
        },
      };
      this.gridApi.setFilterModel(filters);
    },
    reset() {
      this.gridApi.setFilterModel(null);
    },
    getRowHeight(params) {
      var isDetailRow = params.node.detail;
      var statusCheck = params.node.data.status == "PUBLISHED";
      params.node.data.display_graph = statusCheck;
      if (isDetailRow) {
        if (this.admin && statusCheck) return 650;
        else if (statusCheck) return 450;
        else return 150;
      } else return 45;
    },
    rowClicked(params) {
      params.node.setExpanded(!params.node.expanded);
    },
    showDialog() {
      this.dialogVisible = true;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
      this.$emit('grid-ready', this.gridApi);
    },
    adjustGrid() {
      if (this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
        this.$emit("grid-columns-controller", this.columnApi.columnController);
      }
    },
    mapData() {
      if (this.data && this.data.length > 0) {
        const userDateFormat = this.userLocale == "fr" ? "fr-FR" : "en-GB";
        this.rowData = this.data.map((backlink) => {
          return {
            selector: "",
            id: backlink.id,
            name: backlink.name,
            keyword: backlink.keyword.text,
            sourceDomain: backlink.sourceDomain.url,
            sourceUrl: backlink.sourceUrl,
            targetUrl: backlink.targetUrl,
            platform: backlink.platform,
            price: backlink.price,
            buyingPrice: backlink.buyingPrice,
            backlinkType: backlink.backlinkType,
            numberOfWords: backlink.numberOfWords,
            comments: backlink.comments,
            DA:
              backlink.sourceDomain.metrics &&
              backlink.sourceDomain.metrics.domainAuthority
                ? backlink.sourceDomain.metrics.domainAuthority
                : "-",
            TF:
              backlink.sourceDomain.metrics &&
              backlink.sourceDomain.metrics.trustFlow
                ? backlink.sourceDomain.metrics.trustFlow
                : "-",
            CF:
              backlink.sourceDomain.metrics &&
              backlink.sourceDomain.metrics.citationFlow
                ? backlink.sourceDomain.metrics.citationFlow
                : "-",
            topicalTF:
              backlink.sourceDomain.classification &&
              backlink.sourceDomain.classification.majestic
                ? backlink.sourceDomain.classification.majestic
                : "-",
            DR:
              backlink.sourceDomain.metrics &&
              backlink.sourceDomain.metrics.referringDomainsWithEquity
                ? backlink.sourceDomain.metrics.referringDomainsWithEquity
                : "-",
            trafic:
              backlink.sourceDomain.metrics &&
              backlink.sourceDomain.metrics.organicTraffic
                ? backlink.sourceDomain.metrics.organicTraffic
                : "-",
            status: backlink.status,
            publishedDate: backlink.publishedDate,
            validationDate: backlink.validationDate,
            dateStatusDomainToValidate: backlink.dateStatusDomainToValidate
              ? new Date(backlink.dateStatusDomainToValidate).toLocaleString(
                  userDateFormat,
                  { month: "long" }
                )
              : "-",
            expectedDeliveryDate: backlink.expectedValidationDate,
            responsible: backlink.responsible ? backlink.responsible : "-",
            actions: backlink.actions,
            companyName: backlink.companyName,
            activitySector: backlink.activitySector,
            companyDescription: backlink.companyDescription,
            highlights: backlink.highlights,
            links: backlink.links,
            anchorExamples: backlink.anchorExamples,
            backlinkExample: backlink.backlinkExample,
            articleType: backlink.articleType,
            additionalLinks: backlink.additionalLinks,
            catalog: backlink.catalog,
            concept: backlink.concept,
            reasonOfRefusal: backlink.reasonOfRefusal,
            detailsOfRefusal: backlink.detailsOfRefusal,
            reasonOfDelay: backlink.reasonOfDelay,
            detailsOfDelay: backlink.detailsOfDelay,
            sourcingReasons: backlink.sourcingReasons,
          };
        });
      } else {
        this.rowData = [];
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: "",
          field: "selector",
          minWidth: 35,
          width: 35,
          suppressNavigable: true,
          lockPosition: true,
          resizable: false,
          filter: "agTextColumnFilter",
          cellRenderer: "agGroupCellRenderer",
          cellStyle: { padding: 0, margin: "0 !important" },
        },
        {
          headerName: this.$i18n.t("dateStatusDomainToValidate"),
          field: "dateStatusDomainToValidate",
          width: 120,
          minWidth: 120,
          hide: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("name"),
          field: "name",
          minWidth: 140,
          width: 140,
          hide: true,
          suppressSizeToFit: true,
          cellClass: "no-left-padding-cell",
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 170,
          width: 170,
          cellClass: "no-left-padding-cell",
          filter: "agTextColumnFilter",
          checkboxSelection: true,
          filterParams: {
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("quality"),
          field: "backlinkType",
          width: 85,
          hide: true,
          suppressSizeToFit: true,
          cellRendererFramework: "QualityRenderer",
          filter: "agSetColumnFilter",
          keyCreator: (params) => {
            return this.$i18n.t(params.value.toLowerCase());
          },
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("domain"),
          field: "sourceDomain",
          minWidth: 150,
          cellRendererFramework: "LinkRenderer",
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("backlinks.gridHeader.target_url"),
          field: "targetUrl.url",
          minWidth: 200,
          filter: "agTextColumnFilter",
          hide: true
        },
        {
          headerName: this.$i18n.t("DA"),
          field: "DA",
          minWidth: 70,
          filter: "agNumberColumnFilter",
        },
        {
          headerName: this.$i18n.t("TF"),
          field: "TF",
          minWidth: 70,
          filter: "agNumberColumnFilter",
        },
        {
          headerName: this.$i18n.t("CF"),
          field: "CF",
          minWidth: 60,
          filter: "agNumberColumnFilter",
        },
        {
          headerName: this.$i18n.t("topical"),
          field: "topicalTF",
          minWidth: 70,
          witdh: 70,
          hide: true,
          filter: "agNumberColumnFilter",
          cellRendererFramework: "TopicalTFRenderer",
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("DR"),
          field: "DR",
          minWidth: 85,
          maxWidth: 85,
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("trafic"),
          field: "trafic",
          minWidth: 90,
          width: 90,
          suppressSizeToFit: true,
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("status"),
          field: "status",
          minWidth: 145,
          cellClass: "small-padding-cell",
          filter: "agSetColumnFilter",
          floatingFilter: true,
          cellRendererFramework: "BacklinkStatus",
          keyCreator: (params) => {
            return this.$i18n.t(params.value.toLowerCase());
          },
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("price"),
          field: "price",
          minWidth: 95,
          width: 95,
          suppressSizeToFit: true,
          filter: "agNumberColumnFilter",
          cellRenderer: (params) => {
            if (params.value) {
              return this.currencyFormat(params.value);
            } else {
              if (this.$i18n.locale === "fr") return "0 €";
              else return "€0";
            }
          },
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("sourceUrl"),
          field: "sourceUrl",
          width: 150,
          minWidth: 150,
          hide: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: "SourceUrlLinkRenderer",
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("responsible"),
          field: "responsible",
          minWidth: 120,
          width: 140,
          hide: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cellRenderer: (params) => {
            return this.$i18n.t(params.value.toLowerCase());
          },
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("validationDate"),
          field: "validationDate",
          minWidth: 140,
          width: 140,
          hide: true,
          suppressSizeToFit: true,
          filter: "agNumberColumnFilter",
          cellRenderer: (params) => {
            if (params.value) {
              return new Date(params.value).toLocaleDateString();
            } else {
              return "-";
            }
          },
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("expectedDeliveryDate"),
          field: "expectedDeliveryDate",
          minWidth: 140,
          width: 140,
          suppressSizeToFit: true,
          filter: "agNumberColumnFilter",
          cellRenderer: (params) => {
            if (params.value) {
              return new Date(params.value).toLocaleDateString();
            } else {
              return "-";
            }
          },
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("publishedDate"),
          field: "publishedDate",
          minWidth: 140,
          width: 140,
          hide: true,
          suppressSizeToFit: true,
          filter: "agNumberColumnFilter",
          cellRenderer: (params) => {
            if (params.value) {
              return new Date(params.value).toLocaleDateString();
            } else {
              return "-";
            }
          },
          filterParams: {
            newRowsAction: "keep",
          }
        },
        {
          headerName: this.$i18n.t("actions"),
          field: "actions",
          width: 140,
          minWidth: 140,
          suppressSizeToFit: true,
          cellRendererFramework: "ActionsSelector",
          sortable: false,
          filter: false,
        },
      ];
    },
    currencyFormat(value) {
      return this.$options.filters.formatCurrency(value, this.$i18n.locale);
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="/a/${params.data.id}">${params.value}</a>`;
    },
    changeStatus(details) {
      this.$emit("update-status-backlink", details);
    },
    editBacklink(details) {
      this.$emit("show-edit-backlink-dialog", details);
    },
    showDeleteBacklinkDialog(backlink) {
      this.isRefuseBacklinkDialogVisible = true;
      this.backlinkToRefuse = backlink;
    },
    refuseBacklink(form) {
      this.isRefuseBacklinkDialogVisible = false;
      let details = {};
      details.link = this.backlinkToRefuse.actions.refuse;
      details.entity = {
        reasonOfRefusal: form.reasonOfRefusal,
        detailsOfRefusal: form.detailsOfRefusal,
        status: "REFUSED",
      };
      this.$emit("update-status-backlink", details);
    },
    deleteBacklink(details) {
      this.$emit("delete-backlink", details);
    },
    validateBacklink(details) {
      this.$emit("validate-backlink", details);
    },
    showBriefDialog(details) {
      this.$emit("show-brief", details);
    },
    showTextDialog(details) {
      this.$emit("show-text", details);
    },
    updateShow() {
      this.context.admin = this.admin;
    },
    selectionChanged() {
      let selectedRowsFiltered;
      if (
        this.gridApi.getFilterModel() &&
        this.gridApi.getFilterModel().status &&
        this.gridApi.getFilterModel().status.values
      )
        selectedRowsFiltered = this.gridApi
          .getSelectedRows()
          .filter((row) =>
            this.gridApi
              .getFilterModel()
              .status.values.includes(this.$t(row.status.toLowerCase()))
          );
      else selectedRowsFiltered = this.gridApi.getSelectedRows();
      this.$emit("selection-changed-grid", selectedRowsFiltered);
    },
  },
  beforeMount() {
    this.context = {
      changeStatus: this.changeStatus,
      editBacklink: this.editBacklink,
      showDeleteBacklinkDialog: this.showDeleteBacklinkDialog,
      deleteBacklink: this.deleteBacklink,
      validateBacklink: this.validateBacklink,
      showBriefDialog: this.showBriefDialog,
      showTextDialog: this.showTextDialog,
      admin: this.admin,
    };
  },
  mounted() {
    this.mapData();
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.mapData();
      },
      deep: true,
    },
    admin() {
      this.updateShow();
    },
  },
};
let formatNumber = (number) => {
  if (Number.isInteger(number)) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  } else {
    return number;
  }
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
.backlink {
  padding-top: 20px;

  .backlink-name {
    font-size: 1.2rem;
  }

  .backlink-info {
    font-size: 1.05rem;
  }

  .fa-eye {
    margin-left: auto;
    margin-right: 1rem;
  }
}

::v-deep .validated {
  background-color: #b3ff99 !important;
}

::v-deep .refused {
  background-color: #ff9999 !important;
}

::v-deep .small-padding-cell {
  padding: 0;
}

::v-deep .no-left-padding-cell {
  padding-left: 0;
  text-align: left;
}
::v-deep .ag-group-expanded {
  margin: 0 !important;
}
::v-deep .ag-group-contracted {
  margin: 0 !important;
}

::v-deep .ag-header-cell {
  padding-right: 0px;
}

::v-deep .ag-selection-checkbox {
  margin-right: 5px !important;
}

//min height so we can display fully the filters when there are 0 rows
::v-deep .ag-layout-auto-height {
  min-height: 350px;
}

.flex-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.margin-left-auto {
  margin-left: auto;
}

.title {
  margin-top: auto;
  margin-bottom: auto;
}

.grid-pagination {
  margin-top: 1rem;
  text-align: right;

  .divider {
    width: 2px;
  }

  .value-page {
    margin-right: 0.7rem;
    margin-left: 0.7rem;
  }
}
</style>
<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "smartkeyword": "Smartkeyword",
    "client": "Client",
    "responsible": "Owner",
    "validationDate": "Validation date (domain)",
    "expectedDeliveryDate": "Expected date of delivery",
    "dateStatusDomainToValidate": "Month",
    "publishedDate": "Published date",
    "price": "Price",
    "keyword": "Keyword",
    "name": "Name",
    "quality": "Quality",
    "DA": "DA",
    "TF": "TF",
    "CF": "CF",
    "domain": "Domain",
    "topical": "Topical TF",
    "DR": "DR",
    "trafic": "Traffic",
    "actions": "Actions",
    "bronze": "Bronze",
    "silver": "Silver",
    "gold": "Gold",
    "platinum": "Platinum",
    "diamond": "Diamond",
    "media": "Media",
    "status": "Status",
    "numberOfBacklinksPerPage": "Number of backlinks per page",
    "sourceUrl": "Backlink",
    "selectAll": "Select all displayed backlinks",
    "draft": "Draft",
    "to_validate": "Domain to validate",
    "canceled": "Backlink canceled",
    "refused": "Domain refused",
    "validated": "Domain validated",
    "redaction": "Article in writing",
    "article_to_validate": "Article to validate",
    "modify": "Article being modified",
    "article_validated": "Article validated",
    "published": "Backlink published",
    "validateText": "Validate the article",
    "requestModifications": "Request modifications",
    "submitToClient": "Submit to client",
    "publication_pending": "Pending publication"
  },
  "fr": {
    "smartkeyword": "Smartkeyword",
    "client": "Client",
    "responsible": "Responsable",
    "validationDate": "Date de validation (domaine)",
    "expectedDeliveryDate": "Date prévue de livraison",
    "dateStatusDomainToValidate": "Mois",
    "publishedDate": "Date de publication",
    "price": "Prix",
    "name": "Nom",
    "keyword": "Mot clé",
    "quality": "Qualité",
    "DA": "DA",
    "TF": "TF",
    "CF": "CF",
    "domain": "Domaine",
    "topical": "Topical TF",
    "DR": "DR",
    "trafic": "Trafic",
    "actions": "Actions",
    "bronze": "Bronze",
    "silver": "Argent",
    "gold": "Or",
    "platinum": "Platine",
    "diamond": "Diamant",
    "media": "Média",
    "status": "Statut",
    "numberOfBacklinksPerPage": "Nombre de backlinks par page",
    "sourceUrl": "Lien du backlink",
    "selectAll": "Sélectionner tous les backlinks de la page",
    "draft": "Brouillon",
    "to_validate": "Domaine à valider",
    "canceled": "Backlink annulé",
    "refused": "Domaine refusé",
    "validated": "Domaine validé",
    "redaction": "Article en rédaction",
    "article_to_validate": "Article à valider",
    "modify": "Article en cours de modification",
    "article_validated": "Article validé",
    "published": "Backlink publié",
    "validateText": "Valider l'article",
    "requestModifications": "Demander des modifications",
    "submitToClient": "Envoyer au client",
    "publication_pending": "Publication en attente"
  },
  "de": {
    "smartkeyword": "Intelligentes Schlüsselwort",
    "client": "Klient",
    "responsible": "Verantwortlich",
    "validationDate": "Validierungsdatum (Domäne)",
    "expectedDeliveryDate": "Voraussichtliches Lieferdatum",
    "dateStatusDomainToValidate": "Monat",
    "publishedDate": "Veröffentlichungsdatum",
    "price": "Preis",
    "name": "Name",
    "keyword": "Stichwort",
    "quality": "Qualität",
    "DA": "UND",
    "TF": "TF",
    "CF": "CF",
    "domain": "Domain",
    "topical": "Aktuelle TF",
    "DR": "DR",
    "trafic": "Verkehr",
    "actions": "Aktionen",
    "bronze": "Bronze",
    "silver": "Geld",
    "gold": "Oder",
    "platinum": "Platine",
    "diamond": "Diamant",
    "media": "Durchschnitt",
    "status": "Status",
    "numberOfBacklinksPerPage": "Anzahl der Backlinks pro Seite",
    "sourceUrl": "Lien Sie Ihren Backlink",
    "selectAll": "Wählen Sie alle Seiten-Backlinks aus",
    "draft": "Desorganisiert",
    "to_validate": "Zu validierende Domäne",
    "canceled": "Backlink abgebrochen",
    "refused": "Domäne abgelehnt",
    "validated": "Validierte Domäne",
    "redaction": "Artikel schriftlich",
    "article_to_validate": "Zu validierender Artikel",
    "modify": "Artikel wird geändert",
    "article_validated": "Artikel validiert",
    "published": "Backlink veröffentlicht",
    "validateText": "Bestätigen Sie den Artikel",
    "requestModifications": "Änderungen anfordern",
    "submitToClient": "an den Kunden senden",
    "publication_pending": "Veröffentlichung steht bevor"
  }
}
</i18n>