<template>
  <div>
    <ag-grid-vue
      style="height: 160px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :localeTextFunc="localeTextFunc"
      :suppressRowClickSelection="true"
      :suppressCellSelection="true"
      :suppressContextMenu="true"
      :context="context"
      @grid-ready="onGridReady"
      @first-data-rendered="adjustGrid"
      @row-data-changed="adjustGrid"
      @grid-size-changed="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

import TrafficRenderer from "@/components/gridRenderers/TrafficRenderer";
import CompetitorWebsiteRenderer from "@/pages/Competitors/Grids/Shared/CompetitorWebsiteRenderer";

import { RepositoryFactory } from "@/services/repositoryFactory";
const CompetitorsRepository = RepositoryFactory.get("competitorsRepository");

export default {
  mixins: [agGridMixin],
  props: {
    websitesStatistics: Array,
    studyId: Number,
  },
  components: {
    AgGridVue,
    TrafficRenderer,
    CompetitorWebsiteRenderer,
  },
  data() {
    return {
      rowData: null,
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      gridApi: null,
      columnApi: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
      if (this.websitesStatistics) this.mapData();
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("website"),
          field: "domain",
          minWidth: 140,
          filter: "agTextColumnFilter",
          cellRendererFramework: "CompetitorWebsiteRenderer",
        },
        {
          headerName: this.$i18n.t("traffic"),
          field: "estimatedTraffic",
          width: 130,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "TrafficRenderer",
          suppressSizeToFit: true,
          sort: "desc",
        },
        {
          headerName: this.$i18n.t("best_traffic"),
          field: "bestTraffic",
          width: 150,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("number_of_keywords_on_first_page"),
          field: "nbKeywordTop10",
          width: 120,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("number_of_keywords_on_first_position"),
          field: "nbKeywordTop1",
          width: 105,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
      ];
    },
    mapData() {
      this.rowData = this.websitesStatistics.map((website) => {
        const websiteStatisticsByDates = Object.entries(
          website.websiteStatisticsByDates
        ).sort();

        const lastKeywordDate =
          websiteStatisticsByDates[websiteStatisticsByDates.length - 1];
        const trafficByDates = websiteStatisticsByDates.map(
          (statsByDate) => statsByDate[1].estimatedTraffic
        );
        const bestTraffic = Math.max.apply(Math, trafficByDates);
        return {
          id: website.id,
          domain: website.url.url,
          avgPosition: lastKeywordDate[1].averagePosition,
          estimatedTraffic: parseInt(lastKeywordDate[1].estimatedTraffic),
          pastEstimatedTraffic: trafficByDates[0],
          bestTraffic: bestTraffic,
          nbKeywords: lastKeywordDate[1].numberOfKeywords
            ? lastKeywordDate[1].numberOfKeywords
            : 0,
          nbKeywordTop1: lastKeywordDate[1].positionBucketsStatistics.find(
            (bucket) => bucket.positionMin == 1 && bucket.positionMax == 1
          ).numberOfKeywords,
          nbKeywordTop10: lastKeywordDate[1].positionBucketsStatistics.find(
            (bucket) => bucket.positionMin == 1 && bucket.positionMax == 10
          ).numberOfKeywords,
          domainAuthority: website.domainAuthority
            ? website.domainAuthority
            : "--",
          referringDomainsCount: website.referringDomainsCount
            ? website.referringDomainsCount
            : "--",
          date: lastKeywordDate[0],
          isHost: website.isHost,
          competitorGroup: website.competitorGroup,
        };
      });
    },
  },
  watch: {
    websitesStatistics() {
      if (this.websitesStatistics) this.mapData();
    },
  },
  beforeMount() {
    this.context = {
      studyId: this.studyId,
    };
  },
};

let formatNumber = (number) => {
  if (number == "--") return number;
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

::v-deep .cell-centered {
  text-align: center;
}

// ::v-deep .cell-left-aligned {
// 	text-align: center;
// }

::v-deep .ag-centered-header {
  .ag-header-cell-label {
    justify-content: center;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "website": "Website",
    "traffic": "Traffic",
    "vs_j_7_/_vs_j_28": "vs J-7 / vs J-28",
    "best_traffic": "Best traffic",
    "number_of_keywords_on_first_page": "# Top10",
    "number_of_keywords_on_first_position": "# 1st"
  },
  "fr": {
    "website": "Site",
    "traffic": "Trafic",
    "vs_j_7_/_vs_j_28": "vs J-7 / vs J-28",
    "best_traffic": "Meilleur trafic",
    "number_of_keywords_on_first_page": "# Top10",
    "number_of_keywords_on_first_position": "# 1er"
  },
  "de": {
    "website": "Grundstück",
    "traffic": "Verkehr",
    "vs_j_7_/_vs_j_28": "gegen J-7 / gegen J-28",
    "best_traffic": "Besserer Verkehr",
    "number_of_keywords_on_first_page": "#Top 10",
    "number_of_keywords_on_first_position": "#1er"
  }
}
</i18n>
