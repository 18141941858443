<template>
  <div>
    <h4><i class="el-icon-menu"></i> {{ $t("status") }}</h4>
    <el-select
      class="value"
      :value="value"
      size="medium"
      @input="(status) => $emit('input', status)"
      @click.native="$emit('click')"
      clearable
    >
      <el-option
        v-for="status in statusOptions"
        :key="status.value"
        :label="status.label"
        :value="status.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      statusOptions: [
        { value: "PENDING", label: this.$i18n.t("pending") },
        { value: "ONGOING", label: this.$i18n.t("ongoing") },
        { value: "COMPLETED", label: this.$i18n.t("completed") },
      ],
    };
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "status": "Status",
    "ongoing": "Pending",
    "completed": "Completed",
    "pending": "To do"
  },
  "fr": {
    "status": "Statut",
    "ongoing": "En cours",
    "completed": "Réalisées",
    "pending": "A faire"
  },
  "de": {
    "status": "Status",
    "ongoing": "Im Gange",
    "completed": "Vollendet",
    "pending": "Machen"
  }
}
</i18n>
