<template>
  <div>
    <h4 class="margin-bottom">{{ $t("mode") }}</h4>
    <el-radio-group
      :value="value"
      @input="(value) => $emit('input', value)"
      size="medium"
    >
      <el-radio-button label="DAY">{{ $t("daily") }}</el-radio-button>
      <el-radio-button label="WEEK">{{ $t("weekly") }}</el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
};
</script>

<style lang="scss" scoped>
.mode {
  height: 100%;
  text-align: center;
  font-size: 1rem;

  .margin-bottom {
    margin-bottom: 1rem;
  }
}
</style>

<i18n>
{
  "en": {
    "mode": "Frequency",
    "daily": "Daily",
    "weekly": "Weekly"
  },
  "fr": {
    "mode": "Périodicité",
    "daily": "Journalier",
    "weekly": "Hebdomadaire"
  },
  "de": {
    "mode": "Periodizität",
    "daily": "Täglich",
    "weekly": "Wöchentlich"
  }
}
</i18n>
