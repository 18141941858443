<template>
  <div v-loading="!metrics">
    <div
      v-if="metrics"
      class="page"
    >
      <span v-if="url && metrics.best_keywords && mainSeries">
        <dates-form
          :dates="dates"
          @pick="updateDates($event)"
        >
        </dates-form>
        <metrics
          class="data"
          v-loading="isLoading"
          :data="metrics"
        >
        </metrics>
        <seo-graph
          class="data"
          v-loading="isLoading"
          :data="mainSeries"
          :dates="dates"
          :backlinks="backlinks"
          :contents="contents"
        >
        </seo-graph>

        <div
          class="data"
          v-loading="isLoadingTopMovements"
        >
          <div class="container">
            <el-card class="data">
              <el-row v-if="study.id">
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements.highest.keywordsData"
                    :total-traffic-variation="
                      topMovements.highest.metrics.totalTrafficVariation
                    "
                    top-type="ENTRY"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements.lowest.keywordsData"
                    :total-traffic-variation="
                      topMovements.lowest.metrics.totalTrafficVariation
                    "
                    top-type="EXIT"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </div>

        <keyword-grid
          class="data"
          v-loading="isLoading"
          :data="metrics.best_keywords"
          :studyId="study.id"
        >
        </keyword-grid>
      </span>
      <span v-else>
        <el-card class="empty">
          <p>{{ $t("noDataToDisplay") }}</p>
        </el-card>
      </span>
    </div>
    <keyword-dialog
      v-if="keywordDialogVisible"
      v-model="keywordDialogVisible"
      :keyword-id="selectedKeywordId"
      :initial-date="endDate"
      :initial-past-date="startDate"
      :initial-search-engine="searchEngine"
      :initial-frequency="frequency"
      :currentUser="currentUser"
      :studyId="study.id"
    >
    </keyword-dialog>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { dateToDateTimeUTC } from "@/javascripts/dateHelpers";
import { mapState } from "vuex";
import Metrics from "@/pages/GoogleResults/Metrics";
import DatesForm from "@/pages/GoogleResults/DatesForm";
import SeoGraph from "@/pages/GoogleResults/SeoGraph";
import KeywordGrid from "@/pages/GoogleResults/KeywordGrid";
import TopMovements from "@/pages/Keywords/TopMovements";
import KeywordDialog from "@/pages/KeywordDialog";
import { RepositoryFactory } from "@/services/repositoryFactory";
import { formatDateWithDashes } from "@/javascripts/formatDate";

const GoogleResultsRepository = RepositoryFactory.get(
  "googleResultsRepository"
);
const KeywordsRepository = RepositoryFactory.get("keywords");

export default {
  props: {
    url: String,
    trackedKeywords: Array,
    initialSearchEngine: Object,
    initialDate: DateTime,
    initialPastDate: DateTime,
    currentUser: Object,
    initialFrequency: String,
  },
  components: {
    Metrics,
    SeoGraph,
    KeywordGrid,
    DatesForm,
    TopMovements,
    KeywordDialog,
  },
  data() {
    return {
      metrics: null,
      backlinks: [],
      contents: [],
      mainSeries: {},
      isLoading: false,
      endDate: this.initialDate
        ? this.initialDate
        : DateTime.utc().startOf("week"),
      startDate: this.initialPastDate
        ? this.initialPastDate
        : DateTime.utc().startOf("week").minus({ weeks: 12 }),
      selectedKeywordId: null,
      searchEngine: null,
      frequency: null,
      keywordDialogVisible: false,
      isLoadingTopMovements: false,
      keywordsTopMovements: [],
      topMovements: {
        highest: {
          count: null,
          keywordsData: null,
          metrics: {
            totalTrafficVariation: null,
            numberOfKeywords: null,
          },
        },
        lowest: {
          count: null,
          keywordsData: null,
          metrics: {
            totalTrafficVariation: null,
            numberOfKeywords: null,
          },
        },
      },
    };
  },
  methods: {
    getMetrics() {
      this.isLoading = true;
      const request = {
        url: this.url,
        start_date: new Date(this.startDate),
        end_date: new Date(this.endDate),
      };
      GoogleResultsRepository.createKeywordMetrics(request, this.study.id)
        .then((data) => {
          this.isLoading = false;
          this.metrics = data;
          this.mainSeries = data.page_metrics;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    getTrackedKeywords() {
      this.isLoadingTopMovements = true;
      if (this.keywordsTopMovements.length > 0) {
        this.fetchTopMovements();
      } else {
        this.$api
          .get(`/studies/${this.study.id}`)
          .then((response) => {
            const linkTrackedKeywordSearch =
              response.data.links.searchTrackedKeywords;
            this.form = linkTrackedKeywordSearch.form;
            let requestTrackedKeywordsSearch = {};
            requestTrackedKeywordsSearch.frequency = this.frequency;
            this.$api[linkTrackedKeywordSearch.method](
              linkTrackedKeywordSearch.href,
              requestTrackedKeywordsSearch
            ).then((responseTrackedKeywords) => {
              this.keywordsTopMovements = responseTrackedKeywords.data.data;
              this.fetchTopMovements();
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    fetchTopMovements() {
      this.isLoadingTopMovements = true;
      const request = {
        url: {
          url: this.url,
          type: "URL",
        },
        searchEngineParameters: {
          isoCountryCode: this.searchEngine.isoCountryCode,
          device: this.searchEngine.device,
          isoLanguageCode: this.searchEngine.isoLanguageCode,
        },
        frequency: this.frequency === "WEEK" ? "WEEKLY" : "DAILY",
        positionsBuckets: [
          { min: 1, max: 3 },
          { min: 4, max: 10 },
          { min: 11, max: 30 },
        ],
        rangeDate: {
          start: formatDateWithDashes(this.startDate),
          end: formatDateWithDashes(this.endDate),
        },
      };
      request.keywords = this.keywordsTopMovements.map((trackedKeyword) => {
        return {
          text: trackedKeyword.text,
          locationId: trackedKeyword.locationId,
        };
      });

      this.$api
        .post(`/positions/top-movements`, request)
        .then((response) => {
          const data = response.data.data;

          for (const topType of ["highest", "lowest"]) {
            if (data.estimatedTrafficVariations[topType]) {
              const keywordsData = data.estimatedTrafficVariations[topType].map(
                (keyword) => {
                  const trackedKeyword = this.keywordsTopMovements.find(
                    (tk) => tk.text === keyword.text
                  );
                  return {
                    keyword: keyword.text,
                    volume: parseInt(keyword.volume),
                    position: keyword.endPosition ? keyword.endPosition : null,
                    pastPosition: keyword.startPosition
                      ? keyword.startPosition
                      : null,
                    estimatedTrafficVariation: keyword.estimatedTrafficVariation
                      ? parseInt(keyword.estimatedTrafficVariation)
                      : 0,
                    id: trackedKeyword.id,
                    tags: trackedKeyword.tags,
                  };
                }
              );
              this.topMovements[topType].count = keywordsData.length;
              this.topMovements[topType].keywordsData = keywordsData;
            }
          }
          if (data.estimatedTrafficVariations.positiveMovements) {
            this.topMovements.highest.metrics =
              data.estimatedTrafficVariations.positiveMovements;
          }
          if (data.estimatedTrafficVariations.negativeMovements) {
            this.topMovements.lowest.metrics =
              data.estimatedTrafficVariations.negativeMovements;
          }
          this.isLoadingTopMovements = false;
        })
        .catch((error) => {
          this.isLoadingTopMovements = false;
          console.log(error);
        });
    },
    fetchBacklinks() {
      const request = {
        url: this.url,
        status: "PUBLISHED",
      };
      this.$api
        .post(`/studies/${this.study.id}/backlinks/search`, request)
        .then((response) => {
          this.backlinks = response.data.data;
        });
    },
    fetchContents() {
      const request = {
        url: this.url,
        status: "PUBLISHED",
      };
      this.$api
        .post(`/studies/${this.study.id}/contents/search`, request)
        .then((response) => {
          this.contents = response.data.data;
        });
    },
    showKeyword(keywordId) {
      this.selectedKeywordId = keywordId;
      this.keywordDialogVisible = true;
    },
    updateDates(value) {
      this.startDate = dateToDateTimeUTC(new Date(value[0]));
      this.endDate = dateToDateTimeUTC(new Date(value[1]));
      this.getMetrics();
      this.getTrackedKeywords();
    },
  },
  computed: {
    ...mapState(["study"]),
    dates() {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
      };
    },
  },
  watch: {
    study() {
      this.getMetrics();
    },
    initialDate() {
      this.endDate = this.initialDate
        ? this.initialDate
        : DateTime.utc().startOf("week");
    },
    initialPastDate() {
      this.startDate = this.initialPastDate
        ? this.initialPastDate
        : DateTime.utc().startOf("week").minus({ weeks: 12 });
    },
  },
  mounted() {
    if (this.study) {
      if (!this.initialSearchEngine) {
        this.searchEngine = this.study.searchEngines.find(
          (searchEngine) => searchEngine.id === this.study.defaultSearchEngineId
        );
      } else {
        this.searchEngine = this.initialSearchEngine;
      }
      this.frequency = this.initialFrequency ? this.initialFrequency : "WEEK";

      this.getMetrics();
      if (this.trackedKeywords) {
        this.keywordsTopMovements = this.trackedKeywords;
      }
      this.getTrackedKeywords();
      this.fetchBacklinks();
      this.fetchContents();
    }
  },
};
</script>

<style lang="scss" scoped>
.empty {
  text-align: center;
  vertical-align: middle;
}

.data {
  margin-bottom: 1rem;
}

.title {
  padding-bottom: 1rem;
}
</style>

<i18n>
{
  "en": {
    "noDataToDisplay": "No data to display"
  },
  "fr": {
    "noDataToDisplay": "Aucune donnée à afficher"
  },
  "de": {
    "noDataToDisplay": "Keine Daten zum Anzeigen"
  }
}
</i18n>
