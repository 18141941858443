<template>
  <el-collapse
    v-model="activeCollapseSection"
    @change="changed($event)"
  >
    <el-collapse-item name="recommendedKeywords">
      <div slot="title">
        <h5>
          {{ $t("recommendedKeywords") }} ({{ recommendedKeywords.length }})
        </h5>
      </div>
      <span>{{ $t("recommendedKeywordsExplanation") }}</span>
      <div class="keywords">
        <div
          class="keyword"
          v-for="kwrd in recommendedKeywords"
          :key="kwrd.text"
        >
          <el-tooltip placement="top">
            <div slot="content">
              {{ $t("nbOccurences") }} : {{ kwrd.currentOccurences }} (min
              {{ kwrd.minTargetOccurences }}, max
              {{ kwrd.maxTargetOccurences }})
              <div
                v-if="isOverOptimized(kwrd)"
                style="color: orange"
              >
                {{ $t("overOptimized") }}
              </div>
            </div>
            <el-tag
              v-bind:type="tagType(kwrd)"
              class="keyword-tag"
            >
              <span
                v-if="isOverOptimized(kwrd)"
                style="color: red"
                >⚠</span
              >
              {{ kwrd.text }}
            </el-tag>
          </el-tooltip>
        </div>
      </div>
    </el-collapse-item>
    <el-collapse-item name="peopleAlsoAskKeywords">
      <div slot="title">
        <h5>
          {{ $t("peopleAlsoAskKeywords") }} ({{ peopleAlsoAskKeywords.length }})
        </h5>
      </div>
      <span>{{ $t("peopleAlsoAskKeywordsExplanation") }}</span>
      <div>
        <div class="keywords">
          <div
            class="keyword"
            v-for="kwrd in peopleAlsoAskKeywords"
            :key="kwrd"
          >
            <el-tag class="keyword-tag">
              {{ kwrd }}
            </el-tag>
          </div>
        </div>
      </div>
    </el-collapse-item>
    <el-collapse-item name="relatedKeywords">
      <div slot="title">
        <h5>{{ $t("relatedKeywords") }} ({{ relatedKeywords.length }})</h5>
      </div>
      <span>{{ $t("relatedKeywordsExplanation") }}</span>
      <div>
        <div class="keywords">
          <div
            class="keyword"
            v-for="kwrd in relatedKeywords"
            :key="kwrd"
          >
            <el-tag class="keyword-tag">
              {{ kwrd }}
            </el-tag>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  props: {
    recommendedKeywords: Array,
    peopleAlsoAskKeywords: Array,
    relatedKeywords: Array,
  },
  data() {
    return {
      activeCollapseSection: ["recommendedKeywords"],
    };
  },
  methods: {
    isOverOptimized(keyword) {
      return keyword.currentOccurences > keyword.maxTargetOccurences;
    },
    tagType(keyword) {
      if (keyword.currentOccurences >= keyword.minTargetOccurences)
        return "success";
      else return "info";
    },
    changed(event) {
      this.$emit("collapse-change-event", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.keywords {
  display: flex;
  column-gap: 0.5em;
  grid-row-gap: 0.3em;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  .keyword {
    .el-tag {
      word-break: break-word;
      white-space: normal;
      height: auto;
    }
  }
}
</style>
<i18n>
{
    "en": {
        "recommendedKeywordsExplanation": "Enrich your text with these keywords to get better SEO results.",
        "relatedKeywordsExplanation": "These keywords have a similar thematic",
        "peopleAlsoAskKeywordsExplanation": "Questions linked to the thematic",
        "recommendedKeywords": "Recommended Keywords",
        "relatedKeywords": "Related searches",
        "overOptimized": "Careful not to over-optimize",
        "nbOccurences" : "Nb. occurences",
        "peopleAlsoAskKeywords": "People Also Ask"
   },
    "fr": {
        "recommendedKeywordsExplanation": "Enrichissez votre texte avec ces mots-clés pour obtenir un meilleur résultat SEO.",
        "relatedKeywordsExplanation": "Mots-clés ayant une thématique proche",
        "peopleAlsoAskKeywordsExplanation": "Questions liées à la thématique",
        "recommendedKeywords": "Mots-clés recommandés",
        "relatedKeywords": "Recherches associées",
        "overOptimized": "Attention à la suroptimisation",
        "nbOccurences" : "Nb. occurences",
        "peopleAlsoAskKeywords": "People Also Ask"
    },
    "it": {
        "recommendedKeywordsExplanation": "Arricchisci il tuo testo con queste parole chiave per ottenere risultati SEO migliori.",
        "relatedKeywordsExplanation": "Queste parole chiave hanno una tematica simile",
        "peopleAlsoAskKeywordsExplanation": "Domande legate alla tematica",
        "recommendedKeywords": "Parole chiave consigliate",
        "relatedKeywords": "Ricerche correlate",
        "overOptimized": "Attenzione a non ottimizzare eccessivamente",
        "nbOccurences" : "Nb. occorrenze",
        "peopleAlsoAskKeywords": "People Also Ask"
    },
    "es": {
        "recommendedKeywordsExplanation": "Enriquece tu texto con estas palabras clave para obtener mejores resultados de SEO.",
        "relatedKeywordsExplanation": "Estas palabras clave tienen una temática similar",
        "peopleAlsoAskKeywordsExplanation": "Preguntas vinculadas a la temática",
        "recommendedKeywords": "Palabras clave recomendadas",
        "relatedKeywords": "Búsquedas relacionadas",
        "overOptimized": "Cuidado de no sobreoptimizar",
        "nbOccurences" : "Nb. ocurrencias",
        "peopleAlsoAskKeywords": "People Also Ask"
    },
    "de": {
        "recommendedKeywordsExplanation": "Bereichern Sie Ihren Text mit diesen Schlüsselwörtern, um bessere SEO-Ergebnisse zu erzielen.",
        "relatedKeywordsExplanation": "Diese Keywords haben eine ähnliche Thematik",
        "peopleAlsoAskKeywordsExplanation": "Fragen zum Thema",
        "recommendedKeywords": "Empfohlene Keywords",
        "relatedKeywords": "Ähnliche Suchanfragen",
        "overOptimized": "Achten Sie darauf, nicht zu überoptimieren",
        "nbOccurences" : "Anz. Vorkommen",
        "peopleAlsoAskKeywords": "People Also Ask"
    },
    "nl": {
        "recommendedKeywordsExplanation": "Verrijk uw tekst met deze zoekwoorden om betere SEO-resultaten te krijgen.",
        "relatedKeywordsExplanation": "Deze zoekwoorden hebben een vergelijkbaar thema",
        "peopleAlsoAskKeywordsExplanation": "Vragen gekoppeld aan het thema",
        "recommendedKeywords": "Aanbevolen zoekwoorden",
        "relatedKeywords": "Gerelateerde zoekopdrachten",
        "overOptimized": "Pas op dat u niet te veel optimaliseert",
        "nbOccurences" : "Aantal gevallen",
        "peopleAlsoAskKeywords": "People Also Ask"
    }
}
</i18n>
