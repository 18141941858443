<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="88%"
      :destroy-on-close="false"
    >
      <h3>{{ `${$t("url")} ${pageInfo.url}` }}</h3>
      <p>{{ $t("important") }}</p>
      <h3>{{ $t("recommendation") }}</h3>
      <div class="recommendation">
        <div class="recommendation-text">
          <div
            v-for="(item, index) in detailedRecommendations.statements"
            :key="index"
          >
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <h3 class="competitorsTitle">{{ $t("competitors") }}</h3>
      <el-table :data="competitorsPages">
        <el-table-column
          prop="url"
          label="Url"
          :min-width="90"
        >
          <template slot-scope="scope">
            <a
              :href="scope.row.url"
              target="_blank"
              >{{ scope.row.url }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="crawlDate"
          :label="$t('date')"
          :min-width="10"
        >
          <template slot-scope="scope">
            <span>
              {{
                new Date(scope.row.crawlDate).toLocaleDateString($i18n.locale)
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    pageInfo: Object,
    detailedRecommendations: Object,
    competitorsPages: Array,
    value: Boolean,
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
};
</script>

<style scoped>
.container-select {
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
}
.recommendation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.recommendation-text {
  display: flex;
  flex-direction: column;
  padding: 2em 0 0 0;
}

.competitorsTitle {
  padding: 1em 0;
}
</style>

<i18n>
  {
    "en": {
      "url": "Your URL :",
      "recommendation": "Recommendations :",
      "competitors": "Urls of your competitors : get inspired !",
      "date": "Retrieved on",
      "important" : "⚠️ If you change your URL, be sure to set up a 301 redirect and use redirects sparingly."
    },
    "fr": {
      "url": "Votre URL :",
      "recommendation": "Recommandations :",
      "competitors": "Urls de vos concurrents : inspirez-vous !",
      "date": "Récupérée le",
      "important" : "⚠️ Si vous modifiez votre URL, assurez-vous de mettre en place une redirection 301 et d'utiliser les redirections avec parcimonie."
    },
    "de": {
      "url": "Ihre URL :",
      "recommendation": "Empfehlungen :",
      "competitors": "URLs ihrer konkurrenten: lassen sie sich inspirieren !",
      "date": "Erholte sich weiter",
      "important" : "⚠️ Wenn Sie Ihre URL ändern, stellen Sie sicher, dass Sie eine 301-Weiterleitung einrichten und Weiterleitungen sparsam verwenden."
    }
  }
</i18n>
