<template>
  <div>
    <h3>{{ $t("metrics_date", { date }) }}</h3>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="8"
        :md="6"
      >
        <el-card class="kpi">
          <div class="value">{{ keywordData.volume | formatNumber }}</div>
          {{ $t("searches_per_month") }}
        </el-card>
      </el-col>
      <el-col
        :xs="24"
        :sm="8"
        :md="{ span: 6, offset: 3 }"
      >
        <el-card class="kpi">
          <template v-if="position">
            <div class="value">
              <span v-if="position < 101">{{ position }}</span>
              <span v-if="position > 100">>100</span>
              <span class="change">
                <span
                  v-if="positionDifference > 0"
                  class="positive-change"
                  ><i class="fas fa-arrow-up"></i>{{ positionDifference }}</span
                >
                <span
                  v-else-if="positionDifference == 0"
                  class="neutral-change"
                  ><i class="fas fa-equals"></i
                ></span>
                <span
                  v-else-if="positionDifference < 0"
                  class="negative-change"
                  ><i class="fas fa-arrow-down"></i
                  >{{ positionDifference }}</span
                >
              </span>
            </div>
            {{ $t("position") }}
          </template>
          <div
            v-else
            class="muted-value"
          >
            {{ $t("no_position") }}
          </div>
        </el-card>
      </el-col>
      <el-col
        :xs="34"
        :sm="8"
        :md="{ span: 6, offset: 3 }"
      >
        <el-card class="kpi">
          <div
            class="value"
            :class="oppportunityScoreColorClass"
          >
            {{ keywordData.opportunityScore }}%
          </div>
          {{ $t("opportunity_score") }}
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
export default {
  props: {
    keywordData: Object,
    dateDifference: Number,
  },
  computed: {
    position() {
      if (
        this.keywordData.position == null &&
        this.keywordData.positionAssigned == null
      )
        return null;
      if (
        this.keywordData.position &&
        this.keywordData.position.position < 101
      ) {
        return this.keywordData.position.position;
      } else if (
        this.keywordData.positionAssigned &&
        this.keywordData.positionAssigned.position < 101
      ) {
        return this.keywordData.positionAssigned.position;
      } else {
        return 101;
      }
    },
    pastPosition() {
      if (this.keywordData.pastPosition) {
        return this.keywordData.pastPosition.position;
      } else if (this.keywordData.pastPositionAssigned) {
        return this.keywordData.pastPositionAssigned.position;
      }
    },
    positionDifference() {
      if (this.position && this.pastPosition)
        return this.pastPosition - this.position;
    },
    oppportunityScoreColorClass() {
      if (this.keywordData.opportunityScore) {
        const score = this.keywordData.opportunityScore;
        if (score < 30) {
          return "low-score";
        } else if (score < 70) {
          return "medium-score";
        } else {
          return "high-score";
        }
      }
    },
    date() {
      return DateTime.fromISO(this.keywordData.date).toLocaleString()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

h3 {
  font-size: 1.25rem;
}

.kpi {
  text-align: center;

  .value {
    font-size: 1.5rem;
    font-weight: 500;

    &.low-score {
      color: $--color-info;
    }
    &.medium-score {
      color: $--color-warning;
    }
    &.high-score {
      color: $--color-success;
    }
  }

  .muted-value {
    color: $--color-text-secondary;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .change {
    font-size: 0.9rem;

    .positive-change {
      color: $--color-success;
    }

    .negative-change {
      color: $--color-info;
    }

    .negative-change {
      color: $--color-danger;
    }
  }

  .date-difference {
    color: $--color-text-secondary;
    font-size: 0.75rem;
  }
}
</style>

<i18n>
{
  "en": {
    "searches_per_month": "Searches/month",
    "vs_days": "vs %{days_difference} days",
    "no_position": "No Rank",
    "position": "Rank",
    "opportunity_score": "Opportunity score",
    "metrics_date": "Metrics on %{date}"
  },
  "fr": {
    "searches_per_month": "Recherches/mois",
    "vs_days": "vs %{days_difference} jours",
    "no_position": "Pas de position",
    "position": "Position",
    "opportunity_score": "Score d'opportunité",
    "metrics_date": "Métriques au %{date}"
  },
  "de": {
    "searches_per_month": "Suchen/Monat",
    "vs_days": "gegenüber %{days_difference} Stunden",
    "no_position": "keine Haltung",
    "position": "Position",
    "opportunity_score": "Opportunity-Score",
    "metrics_date": "Messwerte am %{date}"
  }
}
</i18n>
