<template>
  <div v-if="changePasswordEndpoint">
    <el-form
      ref="passwordForm"
      status-icon
      :model="passwordData"
      :rules="validationRules"
      label-width="200px"
    >
      <el-form-item
        :label="$t('currentPassword')"
        prop="oldPassword"
      >
        <el-input
          type="password"
          :placeholder="$t('currentPasswordPlaceHolder')"
          v-model="passwordData.oldPassword"
          :show-password="true"
          prefix-icon="el-icon-lock"
          required
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('newPassword')"
        prop="newPassword"
      >
        <el-input
          type="password"
          :placeholder="$t('passwordPlaceHolder')"
          v-model="passwordData.newPassword"
          :show-password="true"
          prefix-icon="el-icon-lock"
          required
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('confirmNewPassword')"
        prop="confirmNewPassword"
      >
        <el-input
          type="password"
          :placeholder="$t('confirmNewPasswordPlaceHolder')"
          v-model="passwordData.confirmNewPassword"
          :show-password="true"
          prefix-icon="el-icon-lock"
          required
        ></el-input>
      </el-form-item>
      <small>{{ $t("hintMessage") }}</small>
      <el-form-item>
        <div class="d-flex flex-row justify-content-end mt-3">
          <el-button
            type="primary"
            @click="onSubmit('passwordForm')"
          >
            <span> {{ $t("sendPassword") }} </span>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    changePasswordEndpoint: Object,
    currentUser: Object,
  },
  data() {
    return {
      passwordData: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      validationRules: {
        oldPassword: [
          {
            required: true,
            message: this.$t("oldPasswordRequired"),
            trigger: "change",
          },
        ],
        newPassword: [
          {
            required: true,
            message: this.$t("newPasswordRequired"),
            trigger: "change",
          },
          {
            min: 8,
            message: this.$t("passwordLengthRequired"),
            trigger: "blur",
          },
        ],
        confirmNewPassword: [
          {
            required: true,
            message: this.$t("confirmNewPasswordRequired"),
            trigger: "change",
          },
          {
            min: 8,
            message: this.$t("passwordLengthRequired"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            data: {
              type: "users",
              id: this.currentUser.id,
              attributes: this.passwordData,
            },
          };
          this.$api[this.changePasswordEndpoint.method.toLowerCase()](
            this.changePasswordEndpoint.href,
            data,
            { headers: { Accept: "application/vnd.api+json" } }
          )
            .then(() => {
              this.$message.success({
                message: this.$t("updatedSuccesfully"),
                duration: 2000,
                showClose: true,
                offset: 80,
              });
            })
            .catch((error) => {
              if (error.response) this.showError(error.response.data);
              console.error(error);
            });
        } else {
          return false;
        }
      });
    },
    showError(data) {
      if (data.errors) {
        data.errors.forEach((error) => {
          let message = "";
          switch (error.code) {
            case "WRONG_OLD_PASSWORD":
              message = this.$t("wrongOldPassword");
              break;
            case "WRONG_CONFIRMATION_PASSWORD":
              message = this.$t("wrongConfirmationPassword");
              break;
            case "PASSWORD_COMPLEXITY_REQUIRED":
              message = this.$t("passwordComplexityRequired");
              break;
            case "PARAMETERS_NOT_FOUND":
              message = this.$t("parametersNotFound");
              break;
          }
          this.$message.error({
            message: message,
            duration: 3000,
            showClose: true,
            offset: 80,
          });
        });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "currentPassword": "Current Password",
    "newPassword": "New password",
    "confirmNewPassword": "Confirm Password",
    "currentPasswordPlaceHolder": "Write your current Password",
    "passwordPlaceHolder": "Write your new password",
    "confirmNewPasswordPlaceHolder": "Confirm your new password",
    "sendPassword": "Send password",
    "wrongOldPassword": "The password you entered does not match your current password",
    "wrongConfirmationPassword": "the password and confirm password must match",
    "passwordComplexityRequired": "Password requires higher complexity",
    "parametersNotFound": "Some fields are missing",
    "updatedSuccesfully": "Password updated succesfully",
    "hintMessage": "Remember to add at least 8 alphanumeric characters, and special characters",
    "oldPasswordRequired": "Current password is required",
    "newPasswordRequired": "New password is required",
    "confirmNewPasswordRequired": "Confirm password is required",
    "passwordLengthRequired": "Password length must be at least 8 characters"
  },
  "fr": {
    "currentPassword": "Mot de passe Actuel",
    "newPassword": "Nouveau mot de passe",
    "confirmNewPassword": "Mot de passe confirmation",
    "currentPasswordPlaceHolder": "Ecrivez votre mot de passe actuel",
    "passwordPlaceHolder": "Ecrivez votre nouveau mot de passe",
    "confirmNewPasswordPlaceHolder": "Confirmer votre nouveau mot de passe",
    "sendPassword": "Envoyer le mot de passe",
    "wrongOldPassword": "Le mot de passe que vous avez entré ne correspond pas à votre mot de passe actuel",
    "wrongConfirmationPassword": "le mot de passe et le mot de passe de confirmation doivent correspondre",
    "passwordComplexityRequired": "Le mot de passe nécessite une plus grande complexité",
    "parametersNotFound": "Certains champs sont manquants",
    "updatedSuccesfully": "Mot de passe mis à jour avec succès",
    "hintMessage": "Pensez a rajouter au moins 8 caractères alphanuémriques, et des caractères spéciaux",
    "oldPasswordRequired": "Le mot de passe actuel est requis",
    "newPasswordRequired": "Le nouveau mot de passe est requis",
    "confirmNewPasswordRequired": "La confirmation du mot de passe est requise",
    "passwordLengthRequired": "La longueur du mot de passe doit être d'au moins 8 caractères"
  },
  "de": {
    "currentPassword": "Aktuelles Passwort",
    "newPassword": "Neues Kennwort",
    "confirmNewPassword": "Bestätige das Passwort",
    "currentPasswordPlaceHolder": "Schreiben Sie Ihr aktuelles Passwort",
    "passwordPlaceHolder": "Schreiben Sie Ihr neues Passwort",
    "confirmNewPasswordPlaceHolder": "Bestätigen Sie Ihr neues Passwort",
    "sendPassword": "Passwort senden",
    "wrongOldPassword": "Das eingegebene Passwort stimmt nicht mit Ihrem aktuellen Passwort überein",
    "wrongConfirmationPassword": "Passwort und Passwort bestätigen müssen übereinstimmen",
    "passwordComplexityRequired": "Passwort erfordert mehr Komplexität",
    "parametersNotFound": "Einige Felder fehlen",
    "updatedSuccesfully": "Passwort erfolgreich aktualisiert",
    "hintMessage": "Denken Sie daran, mindestens 8 alphanumerische Zeichen und Sonderzeichen hinzuzufügen",
    "oldPasswordRequired": "Aktuelles Passwort ist erforderlich",
    "newPasswordRequired": "Neues Passwort erforderlich",
    "confirmNewPasswordRequired": "Passwortbestätigung ist erforderlich",
    "passwordLengthRequired": "Die Passwortlänge muss mindestens 8 Zeichen betragen"
  }
}
</i18n>

<style></style>
