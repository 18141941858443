<template>
  <div v-loading="isLoadingBrief">
    <slot name="title" />
    <div class="flex-box">
      <el-card class="left">
        <div class="actions">
          <el-button
            class="button"
            :disabled="!isTextEditable"
            size="medium"
            type="primary"
            plain
            @click="cancelModifications"
          >
            <i class="fas fa-undo"></i>
          </el-button>
          <el-button
            class="button"
            :disabled="!isTextEditable"
            size="medium"
            type="primary"
            plain
            @click="updateBrief(briefLinks.update)"
          >
            <i class="fas fa-save"></i>
          </el-button>
          <div
            class="buttons"
            v-if="content"
          >
            <el-button
              class="button"
              :disabled="!content.links.submitBrief"
              size="medium"
              type="success"
              @click="changeStatus(content.links.submitBrief)"
            >
              <i class="fas fa-check"></i> {{ $t("submitBrief") }}
            </el-button>
            <el-button
              class="button"
              :disabled="!isOldValueEqualsActualValue"
              v-if="content.links.validateBrief"
              size="medium"
              type="success"
              @click="changeStatus(content.links.validateBrief)"
            >
              <i class="fas fa-check"></i> {{ $t("validateBrief") }}
            </el-button>
          </div>
        </div>
        <el-card
          shadow="never"
          class="editor"
          style="width: 100%"
        >
          <TiptapEditor
            v-model="briefText"
            :isEditable="isTextEditable"
            :height="550"
          >
          </TiptapEditor>
        </el-card>
      </el-card>
    </div>
  </div>
</template>

<script>
import AppIntroduction from "@/components/AppIntroduction";
import TiptapEditor from "@/components/TiptapV2";

export default {
  components: {
    AppIntroduction,
    TiptapEditor,
  },
  props: {
    brief: Object,
    content: Object,
    isLoadingBrief: Boolean,
    isStandAlone: Boolean,
    isBriefGenerated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      linkUrl: null,
      linkMenuIsActive: false,
      oldValue: "",
      briefText: this.brief.data.text,
    };
  },
  computed: {
    isTextEditable() {
      if (this.brief.links.update) {
        return true;
      }
      return false;
    },
    isOldValueEqualsActualValue() {
      if (this.isBriefGenerated) {
        return false;
      }
      if (!this.brief.data.text && this.oldValue != this.briefText)
        return false;
      if (this.brief.data.text) {
        return this.oldValue == this.briefText;
      }
      return true;
    },
    briefLinks() {
      return this.brief.links;
    },
  },
  methods: {
    updateBrief(link) {
      let details = {};
      details.entity = { text: this.briefText };
      details.link = link;

      this.oldValue = this.briefText;
      this.$emit("update-brief", details);
    },
    changeStatus(link) {
      let details = {};
      details.link = link;
      details.entity = { status: link.form[0].value };
      this.$emit("update-content", details);
    },
    cancelModifications() {
      this.briefText = this.oldValue;
    },
  },
  mounted() {
    this.oldValue = this.brief.data.text ? this.brief.data.text : "<p></p>";
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  flex-direction: horizontal;

  .left {
    margin-right: 1rem;
    flex: 5;

    .input-title {
      font-size: 1.05rem;
    }

    .actions {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 1rem;

      .buttons {
        margin-left: auto;
        align-self: flex-end;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "save": "Save",
    "submitBrief": "Submit brief",
    "cancelModifications": "Cancel modifications",
    "brief": "Brief",
    "validateBrief": "Validate brief"
  },
  "fr": {
    "save": "Sauvegarder",
    "submitBrief": "Envoyer le brief",
    "cancelModifications": "Annuler les modifications",
    "brief": "Brief",
    "validateBrief": "Valider le brief"
  },
  "de": {
    "save": "Zu schützen",
    "submitBrief": "Kurz senden",
    "cancelModifications": "Änderungen verwerfen",
    "brief": "Knapp",
    "validateBrief": "Bestätigen Sie den Auftrag"
  }
}
</i18n>
