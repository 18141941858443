<template>
  <div>
    <highcharts
      ref="highchart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: {
    data: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 300,
        },
        title: {
          text: this.$i18n.t("my_DA_vs_serp"),
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: "",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    mapData() {
      if (this.data) {
        this.chartOptions.series = [];
        this.chartOptions.xAxis.categories = [];

        this.chartOptions.xAxis.categories.push(this.$i18n.t("median_DA"));
        this.chartOptions.xAxis.categories.push(this.$i18n.t("your_site"));

        this.chartOptions.series.push({
          name: this.$i18n.t("domain_authority"),
          data: [Math.round(this.data.serpMedian), this.data.urlValue],
        });
      } else {
        this.chartOptions.series = [];
        this.chartOptions.xAxis.categories = [];
      }
    },
  },
  watch: {
    data() {
      this.mapData();
    },
  },
  mounted() {
    if (this.data) {
      this.mapData();
    }
  },
};
</script>

<i18n>
{
  "en": {
    "your_site": "your website",
    "serp": "SERP",
    "my_DA_vs_serp": "Your domain Authority vs the SERP",
    "domain_authority": "Domain Authority",
    "median_DA": "Median DA"
  },
  "fr": {
    "your_site": "Votre site",
    "serp": "SERP",
    "my_DA_vs_serp": "Mon Domain Authority vs la SERP",
    "domain_authority": "Domain Authority",
    "median_DA": "DA Median"
  },
  "de": {
    "your_site": "Deine Seite",
    "serp": "SERP",
    "my_DA_vs_serp": "Mon Domain Authority vs la SERP",
    "domain_authority": "Domänenautorität",
    "median_DA": "Dame Punkt"
  }
}
</i18n>
