<template>
  <div>
    <a
      :href="urlDomain"
      target="_blank"
      >{{ params.data.sourceDomain }}<i class="fas fa-external-link-alt"></i
    ></a>
  </div>
</template>

<script>
export default {
  computed: {
    urlDomain() {
      if (this.params.data.sourceDomain) {
        let url = this.params.data.sourceDomain
          .replace("https://", "")
          .replace("http://", "");
        return "https://" + url;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.fas {
  margin-left: 0.5rem;
}
</style>
