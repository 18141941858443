<template>
  <div>
    <el-tooltip
      v-if="params.data.status === 'REFUSED' && params.data.reasonOfRefusal"
    >
      <div slot="content">
        <b>{{ $t("reasonOfRefusal") }}: </b>{{ getReasonOfRefusal() }}
        <br /><br />
        <b>{{ $t("details") }}: </b>{{ params.data.detailsOfRefusal }}
      </div>
      <div>
        {{ $t(params.data.status.toLowerCase()) }}
        <i
          class="fas fa-info-circle"
          style="color: orange"
        ></i>
      </div>
    </el-tooltip>
    <el-tooltip
      v-else-if="
        params.data.status === 'PUBLICATION_PENDING' &&
        params.data.reasonOfDelay
      "
    >
      <div slot="content">
        <b>{{ $t("reasonOfDelay") }}: </b
        >{{ $t(params.data.reasonOfDelay.toLowerCase()) }} <br /><br />
        <b>{{ $t("details") }}: </b>{{ params.data.detailsOfDelay }}
      </div>
      <div>
        {{ $t(params.data.status.toLowerCase()) }}
        <i
          class="fas fa-info-circle"
          style="color: orange"
        ></i>
      </div>
    </el-tooltip>
    <el-tooltip v-else-if="displayTooltip">
      <div slot="content">
        <b>{{ $t("sourcing") }}</b>
        <ul
          v-for="(item, index) in params.data.sourcingReasons"
          :key="index"
          class="test"
        >
          <li>{{ $t(item.toLowerCase()) }}</li>
        </ul>
      </div>
      <div>
        {{ $t(params.data.status.toLowerCase()) }}
        <i
          class="fas fa-info-circle"
          style="color: #28a745"
        ></i>
      </div>
    </el-tooltip>
    <div v-else>
      {{ $t(params.data.status.toLowerCase()) }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    displayTooltip() {
      if (
        Array.isArray(this.params.data.sourcingReasons) &&
        this.params.data.sourcingReasons.length > 0
      ) {
        return (
          this.params.data.status === "DRAFT" ||
          this.params.data.status === "VALIDATED" ||
          this.params.data.status === "TO_VALIDATE"
        );
      }
    },
  },
  methods: {
    getReasonOfRefusal() {
      const reason = this.params.data.reasonOfRefusal.toLowerCase();
      return this.$t(`backlinks.domainRefused.${reason}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  line-height: 0.5em;
}
</style>

<i18n>
{
  "en": {
    "status": "Status",
    "draft": "Draft",
    "to_validate": "Domain to validate",
    "canceled": "Backlink canceled",
    "refused": "Domain refused",
    "validated": "Domain validated",
    "redaction": "Article in writing",
    "article_to_validate": "Article to validate",
    "modify": "Article being modified",
    "article_validated": "Article validated",
    "publication_pending": "Pending publication",
    "published": "Backlink published",
    "details": "Precisions",
    "reasonOfRefusal": "Reason for refusal",
    "reasonOfDelay": "Reason for delay",
    "website_not_responding": "The site is no longer responding",
    "modifications_take_too_long": "Modifications are in progress",
    "busy_editorial_calendar": "Editorial calendar too busy",
    "sourcing": "Sourcing reasons :",
    "fast_publication": "Fast Publication",
    "high_traffic": "High Traffic",
    "e_commerce_site": "E-commerce Site",
    "low_cost": "Little expensive at the moment T",
    "high_tf": "High TF",
    "high_da": "High DA",
    "positioned_client_keyword": "Positioned on client keyword",
    "positioned_keyword_long_tail": "Positioned on keyword long tail close",
    "close_complementary_semantics": "Close/complementary semantics",
    "related_topic": "Related topic",
    "concurrent_site": "Competitor site",
    "concurrent_spot": "Competitors have this spot"
  },
  "fr": {
    "status": "Statut",
    "draft": "Brouillon",
    "to_validate": "Domaine à valider",
    "canceled": "Backlink annulé",
    "refused": "Domaine refusé",
    "validated": "Domaine validé",
    "redaction": "Article en rédaction",
    "article_to_validate": "Article à valider",
    "modify": "Article en cours de modification",
    "article_validated": "Article validé",
    "publication_pending": "Publication en attente",
    "published": "Backlink publié",
    "reasonOfRefusal": "Raison du refus",
    "reasonOfDelay": "Raison du délai",
    "details": "Précisions",
    "website_not_responding": "Le site ne répond plus",
    "modifications_take_too_long": "Les modifications sont en cours",
    "busy_editorial_calendar": "Calendrier éditorial trop chargé",
    "sourcing": "Raisons du sourcing :",
    "fast_publication": "Publication rapide",
    "high_traffic": "Trafic élevé",
    "e_commerce_site": "Site E-commerce",
    "low_cost": "Peu cher à l'instant T",
    "high_tf": "TF élevé",
    "high_da": "DA élevé",
    "positioned_client_keyword": "Positionné sur MC client",
    "positioned_keyword_long_tail": "Positionné sur MC longue traîne proche",
    "close_complementary_semantics": "Sémantique proche / complémentaire",
    "related_topic": "Thématique connexe",
    "concurrent_site": "Site concurrent",
    "concurrent_spot": "Concurrents ont ce spot"
  },
  "de": {
    "status": "Status",
    "draft": "Desorganisiert",
    "to_validate": "Zu validierende Domäne",
    "canceled": "Backlink abgebrochen",
    "refused": "Domäne abgelehnt",
    "validated": "Validierte Domäne",
    "redaction": "Artikel schriftlich",
    "article_to_validate": "Zu validierender Artikel",
    "modify": "Artikel wird geändert",
    "article_validated": "Artikel validiert",
    "publication_pending": "Veröffentlichung steht bevor",
    "published": "Backlink veröffentlicht",
    "reasonOfRefusal": "Ablehnungsgrund",
    "reasonOfDelay": "Grund für die Verzögerung",
    "details": "Einzelheiten",
    "website_not_responding": "Die Seite reagiert nicht mehr",
    "modifications_take_too_long": "Änderungen sind in Bearbeitung",
    "busy_editorial_calendar": "Überladener Redaktionskalender",
    "sourcing": "Gründe für die Beschaffung :",
    "fast_publication": "Schneller Beitrag",
    "high_traffic": "Viel Verkehr",
    "e_commerce_site": "E-Commerce-Website",
    "low_cost": "Im Moment etwas teuer T",
    "high_tf": "hoher TF",
    "high_da": "hohe AD",
    "positioned_client_keyword": "Positioniert auf Kunden-Keyword",
    "positioned_keyword_long_tail": "Positioniert auf Keyword Long Tail Close",
    "close_complementary_semantics": "Schließen/komplementäre Semantik",
    "related_topic": "Verwandtes Thema",
    "concurrent_site": "Konkurrenzseite",
    "concurrent_spot": "Konkurrenten haben diesen Platz"
  }
}
</i18n>
