<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h3 class="title">{{ title }}</h3>
      </div>
      <dynamic-form
        ref="dynamicFormComponentRef"
        v-model="entity"
        :form="link.form"
        :default-search-engine="defaultSearchEngine"
        @isDomainSourceCorrectlyFormatted="
          handleDomainSourceIncorrectlyFormatted($event)
        "
        @isTargetUrlCorrectlyFormattedOrEmpty="
          handleTargetUrlIncorrectlyFormatted($event)
        "
      >
      </dynamic-form>
      <div class="submit">
        <el-button
          :disabled="
            !isTargetUrlCorrectlyFormattedOrEmpty ||
            !isDomainSourceCorrectlyFormatted
          "
          type="success"
          plain
          @click="submit(link)"
        >
          {{ $t("confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import DynamicForm from "@/components/DynamicForm";

export default {
  components: {
    DynamicForm,
  },
  props: {
    title: String,
    value: Boolean,
    link: Object,
    defaultSearchEngine: Object,
  },
  data() {
    return {
      entity: {},
      isTargetUrlCorrectlyFormattedOrEmpty: false,
      isDomainSourceCorrectlyFormatted: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    submit() {
      this.$refs.dynamicFormComponentRef.$refs.dynamicFormRef.validate(
        (valid) => {
          if (valid) {
            let details = {};
            details.entity = this.entity;
            details.link = this.link;
            this.$emit("add-entity", details);
            this.dialogVisible = false;
          }
        }
      );
    },
    handleDomainSourceIncorrectlyFormatted(isDomainSourceCorrectlyFormatted) {
      this.isDomainSourceCorrectlyFormatted = isDomainSourceCorrectlyFormatted;
    },
    handleTargetUrlIncorrectlyFormatted(isTargetUrlCorrectlyFormattedOrEmpty) {
      this.isTargetUrlCorrectlyFormattedOrEmpty =
        isTargetUrlCorrectlyFormattedOrEmpty;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  flex-direction: row;
}

.submit {
  margin-top: 1rem;
  text-align: center;
}

.title {
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "confirm": "Confirm"
  },
  "fr": {
    "confirm": "Valider"
  },
  "de": {
    "confirm": "Bestätigen"
  }
}
</i18n>
