<template>
  <div>
    <div class="container">
      <div class="text">
        {{ $t("categories") }}
      </div>
      <div
        class="warning"
        v-if="showWarningData"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('warningMsgData')"
          placement="right-start"
        >
          <i class="el-icon-warning"> </i>
        </el-tooltip>
      </div>
      <div
        class="warning"
        v-else-if="showWarning"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('warningMsg', [host])"
          placement="right-start"
        >
          <i class="el-icon-warning"> </i>
        </el-tooltip>
      </div>
    </div>

    <div class="categories">
      <el-tooltip
        v-for="(category, index) in categories"
        v-bind:key="category.category"
        effect="dark"
        :content="category.category"
        placement="left"
      >
        <div
          class="category"
          v-if="category != ''"
        >
          <i
            class="fas fa-medal"
            v-bind:class="classFromPosition(index)"
          ></i>
          {{ croppedCategoryName(category.category) }} ({{ category.score }})
        </div>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    classification: Object,
    host: String,
    showWarning: Boolean,
    showWarningData: Boolean,
  },
  methods: {
    croppedCategoryName(category) {
      return category.slice(category.lastIndexOf("/") + 1, category.length);
    },
    classFromPosition(position) {
      if (position == 0) return "first";
      if (position == 1) return "second";
      if (position == 2) return "third";
    },
  },
  computed: {
    categories() {
      if (this.classification.majestic) {
        return this.classification.majestic
          .filter((category) => category.categoryMajestic !== "")
          .map((category) => ({
            ...category,
            category: category.categoryMajestic,
          }));
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.warning {
  font-size: 1rem;
  color: #e6a23c;
}
.text {
  font-size: 1.15rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: center;
}

.categories {
  margin-top: 1.4rem;
  padding-left: 1.35rem;

  .category {
    margin-bottom: 1rem;
    font-size: 1.05rem;
  }

  .first {
    color: #ffd700;
  }

  .second {
    color: #a9a9a9;
  }

  .third {
    color: #6a5211;
  }
}
</style>

<i18n>
{
  "en": {
    "categories": "Categories",
    "warningMsg": " This report shows the Trust flow and Citation flow of {0}",
    "warningMsgData": "Data unavailable"
  },
  "fr": {
    "categories": "Catégories",
    "warningMsg": " Ce rapport affiche Trust flow and Citation flow de {0}",
    "warningMsgData": "Données indisponible"
  },
  "de": {
    "categories": "Kategorien",
    "warningMsg": "Dieser Bericht zeigt den Vertrauensfluss und den Zitierfluss von {0}",
    "warningMsgData": "Keine Daten verfügbar"
  }
}
</i18n>
