<template>
  <div>
    <h4><i class="fas fa-exclamation-circle"></i> {{ $t("priority") }}</h4>
    <el-select
      class="value"
      :value="value"
      size="medium"
      @click.native="$emit('click')"
      @input="(priority) => $emit('input', priority)"
      clearable
    >
      <el-option
        v-for="priority in priorityOptions"
        :key="priority.value"
        :label="priority.label"
        :value="priority.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      // In a perfect world, priorityOptions should actually be returned by the tasks service for more flexibility
      // and better maintenance
      priorityOptions: [
        { value: "LOW", label: this.$i18n.t("low") },
        { value: "MEDIUM", label: this.$i18n.t("medium") },
        { value: "HIGH", label: this.$i18n.t("high") },
        { value: "CRITICAL", label: this.$i18n.t("critical") },
      ],
    };
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "priority": "Priority",
    "low": "Low",
    "medium": "Medium",
    "high": "High",
    "critical": "Critical"
  },
  "fr": {
    "priority": "Priorité",
    "low": "Basse",
    "medium": "Moyenne",
    "high": "Elevée",
    "critical": "Critique"
  },
  "de": {
    "priority": "Priorität",
    "low": "Niedrig",
    "medium": "Bedeuten",
    "high": "Hoch",
    "critical": "Kritik"
  }
}
</i18n>
