<template>
  <el-card class="add-campaign">
    <div>
      <el-button
        type="primary"
        plain
        @click="$emit('click')"
      >
        <span v-if="isCampaign">
          <i class="fas fa-plus"></i> {{ $t("addCampaign") }}
        </span>
        <span v-else> <i class="fas fa-plus"></i> {{ $t("addProject") }} </span>
      </el-button>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    isCampaign: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.add-campaign {
  text-align: center;
  margin-bottom: 2rem;
}
</style>

<i18n>
{
  "en": {
    "addCampaign": "Add a campaign",
    "addProject": "add a project"
  },
  "fr": {
    "addCampaign": "Ajouter une campagne",
    "addProject": "Ajouter un projet"
  },
  "de": {
    "addCampaign": "Kampagne hinzufügen",
    "addProject": "Füge ein Projekt hinzu"
  }
}
</i18n>
