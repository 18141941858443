<template>
  <el-dialog
    :title="$t('add_competitor')"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="40%"
  >
    <el-form
      :model="addCompetitorForm"
      ref="addCompetitorForm"
      label-width="165px"
      label-position="top"
    >
      <el-form-item :label="$t('url_of_competitor')">
        <el-input
          v-model="addCompetitorForm.url"
          clearable
          :disabled="disabled()"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('competitor_group')">
        <el-select
          v-model="addCompetitorForm.competitorType"
          clearable
          v-if="studyLinks.competitors.hasOwnProperty('create')"
        >
          <el-option
            v-for="type in studyLinks.competitors.create.form[1].options"
            :label="$t(type.toLowerCase())"
            :value="type"
            :key="type"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="success"
          @click="submit(addCompetitorForm)"
          >{{ $t("confirm") }}</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: {
    competitorUrl: String,
    dialogVisible: Boolean,
    studyLinks: Object,
  },
  data() {
    return {
      addCompetitorForm: {
        url: null,
        competitorType: "DIRECT",
      },
    };
  },
  methods: {
    submit(form) {
      this.$emit("create-competitor", form);
    },
    disabled() {
      if (this.competitorUrl != null) {
        this.addCompetitorForm.url = this.competitorUrl;
        return true;
      } else return false;
    },
    handleClose() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  float: right;
}
.el-select {
  width: 100%;
}
</style>

<i18n>
{
  "en": {
    "add_competitor": "Add competitor",
    "competitor_group": "Group of competitors",
    "confirm": "Confirm",
    "direct": "Direct",
    "indirect": "Indirect",
    "others": "Others",
    "url_of_competitor": "URL of the competitor"
  },
  "fr": {
    "add_competitor": "Ajouter un concurrent",
    "competitor_group": "Groupe de concurrents",
    "confirm": "Valider",
    "direct": "Direct",
    "indirect": "Indirect",
    "others": "Autres",
    "url_of_competitor": "URL de votre concurrent"
  },
  "de": {
    "add_competitor": "Fügen Sie einen Konkurrenten hinzu",
    "competitor_group": "Gruppe von Konkurrenten",
    "confirm": "Bestätigen",
    "direct": "Direkte",
    "indirect": "Indirekt",
    "others": "Andere",
    "url_of_competitor": "Die URL Ihres Konkurrenten"
  }
}
</i18n>
