<template>
  <div>
    <slot name="header"></slot>

    <div class="account">
      <div class="traffic">
        <div class="container">
          <slot name="body"></slot>
          <div>
            <div v-if="accountConfiguration">
              <traffic
                v-if="
                  accountConfiguration.data.attributes.graphType == 'traffic' ||
                  accountConfiguration.data.attributes.graphType == 'both'
                "
                :account-configuration-json="accountConfiguration"
                :accountId="accountId"
                :dimensionsData="dimensionsData"
              ></traffic>
              <conversion
                v-if="
                  accountConfiguration.data.attributes.graphType ==
                    'conversion' ||
                  accountConfiguration.data.attributes.graphType == 'both'
                "
                :account-configuration-json="accountConfiguration"
                :accountId="accountId"
                :dimensionsData="dimensionsData"
              ></conversion>
              <positions
                :account-configuration-json="accountConfiguration"
                :accountId="accountId"
                :companyId="companyId"
                :currentUser="currentUser"
              ></positions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const ConfigurationsRepository = RepositoryFactory.get("configurations");
import Traffic from "./Traffic";
import Positions from "./Positions";
import Conversion from "./Conversion";

export default {
  props: {
    accountId: Number,
    dimensionsData: Array,
    companyId: Number,
    currentUser: Object,
  },
  components: {
    Traffic,
    Positions,
    Conversion,
  },
  data() {
    return {
      accountConfiguration: null,
    };
  },
  methods: {
    fetchDefaultFilters() {
      ConfigurationsRepository.getConfiguration(this.accountId).then((data) => {
        this.accountConfiguration = data;
      });
    },
  },
  created() {
    this.fetchDefaultFilters();
  },
};
</script>

<style scoped lang="scss"></style>

<i18n>
{
  "en": {},
  "fr": {},
  "de": {}
}
</i18n>
