<template>
  <el-form 
    :model="noteForm" 
    :rules="rules" 
    ref="noteForm" 
    class="form-container"
  >
    <el-row style="flex: 3;">
      <div style="display: flex;">
        <el-form-item 
          :label="$t('title')" 
          prop="title" 
          style="margin-right: 8px; flex: 2"
        >
          <el-input v-model="noteForm.title" required />
        </el-form-item>
        <el-form-item 
          :label="$t('date')" 
          prop="date" 
          style="flex: 1"
        >
          <el-date-picker 
            v-model="noteForm.date" 
            :placeholder="$t('date')" 
            type="date" 
            style="width: 100%" 
            :picker-options="pickerOptions"
          />
        </el-form-item>
      </div>
      <el-form-item :label="$t('comment')">
        <el-input v-model="noteForm.comment" required />
      </el-form-item>
    </el-row>
    <div class="button-container">
      <el-form-item>
        <el-button 
          class="icon-size" 
          icon="fas fa-check" 
          type="text" 
          :loading="isCreating"
          @click="createOrUpdateNote" 
        />
      </el-form-item>
      <el-form-item>
        <el-button 
          class="icon-size" 
          icon="fas fa-trash"
          style="color: red; margin-left: 16px;" 
          type="text" 
          @click="cancelNewNote" 
        />
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    noteForm: Object,
    studyId: Number,
  },
  data() {
    return {
      isCreating: false,
      rules: {
        title: [
          { 
            required: true, 
            message: this.$i18n.t('pleaseAdd') + this.$i18n.t('aTitle'), 
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true, 
            message: this.$i18n.t('pleaseAdd') + this.$i18n.t('aDate'), 
            trigger: "blur",
          }
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }
    }
  },
  methods: {
    createOrUpdateNote() {
      this.$refs.noteForm.validate((valid) => {
        if (valid) {
          this.isCreating = true
          const payload = {
            data: {
              type: 'NOTE',
              id: this.studyId.toString(),
              attributes: this.noteForm
            }
          }
          const updateRequest = this.noteForm.id ? `/${this.noteForm.id}` : ''
          const method = this.noteForm.id ? 'patch' : 'post'
          const createOrUpdate = this.noteForm.id ? 'update' : 'create'

          this.$api[method](`studies/${this.studyId}/notes${updateRequest}`,
            payload, { headers: { 'Accept': 'application/vnd.api+json' } })
          .then(() => {
            // Formating date for ag-grid on create/update note
            const date = new Date(this.noteForm.date);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');

            this.noteForm.date = `${year}-${month}-${day}`
            this.isCreating = false
            this.$emit('refreshNotes', createOrUpdate + "_success")
          })
          .catch(() => {
            this.isCreating = false
            if (this.noteForm.title.trim().length === 0) {
              this.$message.error(this.$i18n.t('pleaseAdd') + this.$i18n.t('aTitle'))
              return false
            }
            this.$message.error(this.$i18n.t(createOrUpdate + "_failure"));
            console.log('Error while creating')
          })
        } else {
          return false;
        }
      });
    },
    cancelNewNote() {
      this.$emit("resetForm")
    },
  }
}
</script>

<style scoped>
.form-container {
  display: flex;
  border-color: #dcdfe6;
  border-style: solid;
  border-width: 1px;
  padding: 25px;
  margin-top: 8px;
}

.button-container {
  justify-content: end;
  align-items: center;
  display: flex;
  border-color: black;
  flex: 2;
}

.icon-size {
  font-size: 20px;
}
</style>

<i18n>
{  
  "en": {
    "pleaseAdd": "Please add a ",
    "title": "Title",
    "date": "Date",
    "comment": "Comment",
    "aTitle": "title",
    "aDate": "date",
    "update_success": "Note updated successfully",
    "create_success": "Note created successfully",
    "update_failure": "There was an error while updating the note",
    "create_failure": "There was an error while creating the note"
  },
  "fr": {
    "pleaseAdd": "Veuilllez ajouter ",
    "title": "Titre",
    "date": "Date",
    "comment": "Commentaire",
    "aTitle": "un titre",
    "aDate": "une date",
    "update_success": "Note mise à jour avec succès",
    "create_success": "Note créée avec succès",
    "update_failure": "Une erreur s'est produite lors de la mise à jour de la note",
    "create_failure": "Une erreur s'est produite lors de la création de la note"
  },
  "de": {
    "pleaseAdd": "Bitte fügen Sie eine hinzu ",
    "title": "Titel",
    "date": "Treffen",
    "comment": "Kommentar",
    "aTitle": "titel",
    "aDate": "ein Treffen",
    "update_success": "Notiz update erfolgreich",
    "create_success": "Notiz erfolgreich erstellt",
    "update_failure": "Beim Aktualisieren der Notiz ist ein Fehler aufgetreten",
    "create_failure": "Beim Erstellen der Notiz ist ein Fehler aufgetreten"
  }
}
</i18n>
