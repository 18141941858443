<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h3
          class="title"
          v-if="isCampaign"
        >
          {{ $t("EditCampaign") }}
        </h3>
        <h3
          class="title"
          v-else
        >
          {{ $t("EditProject") }}
        </h3>
      </div>
      <dynamic-form
        v-model="updatedCampaign"
        :form="data.form"
      >
      </dynamic-form>
      <div class="submit">
        <el-button
          type="success"
          plain
          @click="submit()"
        >
          {{ $t("validate") }}
        </el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import DynamicForm from "@/components/DynamicForm";

export default {
  props: {
    value: Boolean,
    data: Object,
    isCampaign: Boolean,
  },
  components: {
    DynamicForm,
  },
  data() {
    return {
      updatedCampaign: {},
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    submit() {
      let details = {};
      details.campaign = this.updatedCampaign;
      details.link = this.data;
      this.$emit("update-campaign", details);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  flex-direction: row;
}

.title {
  text-align: center;
}

.submit {
  margin-top: 1rem;
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "EditCampaign": "Edit campaign",
    "EditProject": "Edit project",
    "validate": "Validate"
  },
  "fr": {
    "EditCampaign": "Modifier la campagne",
    "EditProject": "Modifier le projet",
    "validate": "Valider"
  },
  "de": {
    "EditCampaign": "Kampagne bearbeiten",
    "EditProject": "Ändern Sie das Projekt",
    "validate": "Bestätigen"
  }
}
</i18n>
