<template>
  <span>

    <el-dialog
      :visible.sync="dialogVisible"
      width="75%"
      top="5vh"
      :append-to-body="true"
    >
      <div
        v-if="keywordData"
        slot="title"
      >
        <div
          :id="'keywordDialog-' + keywordId"
          class="el-dialog__title"
        >
          {{ $t("keyword", { text: keywordData.text }) }}
          <span class="tags">
            <el-tag
              v-for="tag in keywordData.tags"
              :key="tag"
              size="medium"
              >{{ tag }}</el-tag
            >
          </span>
        </div>
        <div class="flex-container">
          <el-form
            :inline="true"
            size="mini"
          >
            <el-form-item :label="$i18n.t('mode')">
              <el-radio-group
                v-model="frequencyButton"
                size="small"
                @change="callFetchKeywordData"
              >
                <el-radio-button
                  label="DAY"
                  :disabled="keywordData.refreshFrequency !== 'DAILY'"
                  >{{ $t("daily") }}</el-radio-button
                >
                <el-radio-button label="WEEK">{{
                  $t("weekly")
                }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <search-engine-select
                v-model="searchEngine"
              ></search-engine-select>
            </el-form-item>
            <period-picker
              v-model="period"
              :is-show-label="false"
              :frequency="frequencyButton"
            ></period-picker>
          </el-form>
          <div class="actions">
            <a
              :href="`/a/${study.id}/keywords/${keywordId}`"
              target="_blank"
              class="el-button el-button--primary el-button--small"
              >{{ $t("optimize") }}</a
            >
            <el-button
              size="small"
              @click="showTask"
              >{{ $t("add_to_actions") }}</el-button
            >
          </div>
        </div>
      </div>
      <div v-loading="isLoading">
        <template v-if="keywordData">
          <metrics
            :keyword-data="keywordData"
            :date-difference="dateDifference"
          ></metrics>
          <el-divider></el-divider>
          <positioned-pages
            v-if="
              keywordData.position.position < 101 ||
              keywordData.positionAssigned
            "
            :position="keywordData.position"
            :position-assigned="keywordData.positionAssigned"
            :is-assigned="!!keywordData.positionAssigned"
            :serp-results="keywordData.serpResults"
          ></positioned-pages>
          <positioning
            :keyword-id="keywordId"
            :initial-serps-result="keywordData.serpsResult"
            :initial-position="keywordData.position"
            :initial-serp-results="keywordData.serpResults"
            :initial-serp-statistics="keywordData.serpStatistics"
            :initial-past-serp-results="keywordData.pastSerpResults"
            :search-engine="searchEngine"
            :date="period[1]"
            :past-date="period[0]"
            :frequency="frequencyButton"
            :closest-end-date="keywordData.date"
            :tracked-keyword="trackedKeyword"
            :studyId="studyId"
          ></positioning>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="taskDialogVisible"
      :close-on-click-modal="false"
      width="62%"
      :append-to-body="true"
    >
      <task-creation-form
        v-if="keywordData"
        :key="keywordId"
        :title="$t('work_on_keyword', { keywordText: keywordData.text })"
        :keywords="[{ text: keywordData.text, id: keywordId }]"
        :assigned-to-user-id="currentUser.id"
        :user-options="[
          {
            id: currentUser.id,
            firstName: currentUser.first_name,
            lastName: currentUser.last_name,
          },
        ]"
        :current-user="currentUser"
        :study-id="study.id"
        @on-created-task="taskDialogVisible = false"
      />
    </el-dialog>
  </span>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
import SearchEngineSelect from "@/components/SearchEngineSelect";
import PeriodPicker from "@/components/formItems/PeriodPicker";
import Metrics from "@/pages/KeywordDialog/Metrics";
import PositionedPages from "@/pages/KeywordDialog/PositionedPages";
import Positioning from "@/pages/KeywordDialog/Positioning";

import { metaContentsMixin } from "@/mixins/metaContentsMixin";
import { isSameDomainNameMixin } from "@/mixins/isSameDomainNameMixin";
import { formatDateWithDashes } from "@/javascripts/formatDate";
export default {
  components: {
    PeriodPicker,
    Metrics,
    PositionedPages,
    Positioning,
    TaskCreationForm: () => import("@/components/task/TaskCreationForm"),
    SearchEngineSelect,
  },
  mixins: [metaContentsMixin, isSameDomainNameMixin],
  props: {
    value: Boolean,
    keywordId: Number,
    initialSearchEngine: Object,
    initialFrequency: String,
    currentUser: Object,
    studyId: Number,
    initialPastDate:{
      type:DateTime,
      default: ()=> { return DateTime.utc().startOf("week").minus({ weeks: 12 })}
    },
    initialDate:{
      type: DateTime,
      default: () => { return DateTime.utc().startOf("week")}
    },
  },
  data() {
    return {
      period:[this.initialPastDate, this.initialDate],

      searchEngine: this.initialSearchEngine ? this.initialSearchEngine : {},
      keywordData: null,
      trackedKeyword: null,
      isLoading: false,
      activeTab: "positioning",
      addTagsPopoverVisible: false,
      taskDialogVisible: false,
      frequencyButton: this.initialFrequency ? this.initialFrequency : "WEEK",
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    dateDifference() {
      if (this.period[1] && this.period[0])
        return this.period[1].diff(this.period[0], "days").days;
      return null;
    },
    ...mapState(["study"]),
  },
  watch: {
    keywordId() {
      this.keywordData = null;
      if (this.keywordId) this.getTrackedKeyword();
    },
    searchEngine() {
      this.keywordData = null;
      this.fetchKeywordData();
    },
    period(){
      this.callFetchKeywordData();
    }
  },
  mounted() {
    if (this.keywordId) this.getTrackedKeyword();
    if (!this.initialSearchEngine && this.study) {
      this.searchEngine = this.study.searchEngines.find(
        (searchEngine) => searchEngine.id === this.study.defaultSearchEngineId
      );
    }
  },
  methods: {
    fetchKeywordData() {
      if (this.trackedKeyword) {
        this.isLoading = true;
        const request = {
          text: this.trackedKeyword.text,
          locationId: this.trackedKeyword.locationId,
          searchEngineParameters: {
            isoCountryCode: this.searchEngine.isoCountryCode,
            device: this.searchEngine.device,
            isoLanguageCode: this.searchEngine.isoLanguageCode,
          },
          rangeDate: {
            start: this.period[0].toString().split("T")[0],
            end: this.period[1].toString().split("T")[0],
          },
          frequency: this.frequencyButton === "WEEK" ? "WEEKLY" : "DAILY",
        };
        this.$api
          .post("/serps/history", request)
          .then((response) => {
            const size = Object.keys(response.data.data.history).length;
            if (size > 0) {
              let serpsResult = Object.entries(
                response.data.data.history
              ).sort();
              let serpResult = serpsResult[size - 1][1];
              serpResult.left = serpResult.left.filter(
                (result) => result.url != null
              );
              let pastSerpResult = serpsResult[0][1];
              pastSerpResult.left = pastSerpResult.left.filter(
                (result) => result.url != null
              );
              let requestUrlMetrics = {};
              requestUrlMetrics.urls = this.tenFirstUrlsOfSerp(serpResult);
              let pagesNumberOfWords = new Map();

              this.fetchPositionAndPagesUrlsAsync(
                request,
                (contentLengthresponse) => {
                  contentLengthresponse.data.data.forEach((hash) => {
                    pagesNumberOfWords.set(hash.url, hash.contentLength);
                  });
                  this.appendUrlMetricsCall(
                    requestUrlMetrics,
                    serpResult,
                    pastSerpResult,
                    pagesNumberOfWords,
                    serpsResult,
                    request
                  );
                }
              );
            }
          })
          .catch((error) => {
            console.error(error);
            this.$message({
              message: this.$i18n.t("fetch_keyword_data_failure"),
              type: "error",
              duration: 6000,
            });
            this.isLoading = false;
          });
      }
    },
    appendUrlMetricsCall(
      requestUrlMetrics,
      serpResult,
      pastSerpResult,
      pagesNumberOfWords,
      serpsResult,
      request
    ) {
      requestUrlMetrics.minDate = formatDateWithDashes(this.period[0]);
      requestUrlMetrics.isoCountryCode = this.searchEngine.isoCountryCode;
      this.$api
        .post(`/netlinking/urls/metrics`, { ...requestUrlMetrics })
        .then((response) => {
          const urlsMetricsData = response.data;
          serpResult.left.map((website) => {
            urlsMetricsData.data.map((urlMetrics) => {
              if (this.isSameDomainName(urlMetrics.url.url, website.hostName)) {
                if (urlMetrics.metrics) {
                  website.domain = urlMetrics.url.url;
                  website.domainAuthority = urlMetrics.metrics.domainAuthority;
                  website.externalBacklinks =
                    urlMetrics.metrics.externalBacklinks;
                }
              }
              if (pagesNumberOfWords.has(website.url)) {
                website.contentLength = pagesNumberOfWords.get(website.url);
              }
            });
            website.hostName = new URL(website.url).hostname;
          });
          pastSerpResult.left.map((website) => {
            website.hostName = new URL(website.url).hostname;
          });

          const keywordData = {
            text: this.trackedKeyword.text,
            refreshFrequency: this.trackedKeyword.frequency,
            tags: this.trackedKeyword.tags,
            volume: serpResult.volume,
            date: request.rangeDate.end,
            opportunityScore: this.trackedKeyword.opportunityScore,
            position: this.getPosition(serpResult),
            pastPosition: this.getPastPosition(pastSerpResult),
            positionAssigned: this.getPositionAssigned(serpResult),
            pastPositionAssigned: this.getPastPositionAssigned(pastSerpResult),
            serpResults: serpResult.left,
            pastSerpResults: pastSerpResult.left,
            serpStatistics: this.getSerpStatistics(serpResult),
            serpsResult: serpsResult,
          };
          this.keywordData = keywordData;
          this.isLoading = false;
        });
    },
    getTrackedKeyword() {
      this.$api
        .get(`/tracked-keywords/${this.keywordId}`)
        .then((response) => {
          this.trackedKeyword = response.data.data;
          this.fetchKeywordData();
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            message: this.$i18n.t("fetch_keyword_data_failure"),
            type: "error",
            duration: 6000,
          });
          this.isLoading = false;
        });
    },

    tenFirstUrlsOfSerp(serpResult) {
      if (serpResult && serpResult.left.length > 0) {
        //Filter updated to take into account urls of the study that are not part of the top 10
        return serpResult.left
          .filter((website) => {
            return (
              website.position <= 10 || website.url.includes(this.study.url)
            );
          })
          .map((website) => {
            return { url: website.url, type: "DOMAIN" };
          });
      }
    },
    getPosition(serpResult) {
      let position = serpResult.left.filter((website) => {
        return this.isSameDomainName(this.study.url, website.hostName);
      });
      if (position.length == 0) {
        return { domain: this.study.url, position: 101 };
      } else {
        return position.map((website) => {
          return { domain: this.study.url, position: website.position };
        })[0];
      }
    },
    getPastPosition(pastSerpResult) {
      let pastPosition = pastSerpResult.left.filter((website) => {
        return this.isSameDomainName(this.study.url, website.hostName);
      });
      if (pastPosition.length == 0) {
        return { domain: this.study.url, position: 101 };
      } else {
        return pastPosition.map((website) => {
          return { domain: this.study.url, position: website.position };
        })[0];
      }
    },
    getPositionAssigned(serpResult) {
      if (this.trackedKeyword.assignedUrl) {
        let assignedPosition = serpResult.left.filter((website) => {
          return website.url == this.trackedKeyword.assignedUrl;
        });
        if (assignedPosition.length == 0) {
          return {
            domain: this.study.url,
            url: this.trackedKeyword.assignedUrl,
            position: 101,
          };
        } else {
          return assignedPosition.map((website) => {
            return {
              domain: this.study.url,
              url: website.url,
              position: website.position,
            };
          })[0];
        }
      } else {
        return null;
      }
    },
    getPastPositionAssigned(pastSerpResult) {
      if (this.trackedKeyword.assignedUrl) {
        let pastPositionAssigned = pastSerpResult.left.filter((website) => {
          return website.url == this.trackedKeyword.assignedUrl;
        });
        if (pastPositionAssigned.length == 0) {
          return { domain: this.study.url, position: 101 };
        } else {
          return pastPositionAssigned.map((website) => {
            return { domain: this.study.url, position: website.position };
          });
        }
      } else {
        return null;
      }
    },
    getSerpStatistics(serpResult) {
      let top10Position = serpResult.left
        .filter((website) => {
          return website.position <= 10;
        })
        .map((website) => {
          return {
            domainAuthority: website.domainAuthority,
            contentLength: website.contentLength,
          };
        });
      top10Position.sort((a, b) => {
        return a.domainAuthority - b.domainAuthority;
      });
      const minDomainAuthority = top10Position[0].domainAuthority;

      let top10PositionFiltered = top10Position.filter((a) => {
        if (
          a.hasOwnProperty("contentLength") &&
          a.contentLength !== null &&
          a.contentLength !== undefined
        )
          return a;
      });

      top10PositionFiltered.sort((a, b) => {
        return a.contentLength - b.contentLength;
      });

      let minNumberOfWords = 0;
      if (top10PositionFiltered.length > 0) {
        minNumberOfWords = top10PositionFiltered[0].contentLength;
      }
      const domainAuthority = { min: minDomainAuthority };
      const wordsCount = {
        min: minNumberOfWords,
        median: this.metaContentsData.contentMedian,
      };
      return { domainAuthority: domainAuthority, wordsCount: wordsCount };
    },
    showTask() {
      this.taskDialogVisible = true;
    },
    callFetchKeywordData() {
      this.keywordData = null;
      this.fetchKeywordData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__header {
    .el-dialog__title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .flex-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .el-tag {
      margin: 0 0.25rem 0.25rem 0;
    }

    .el-tag + .el-tag {
      margin-left: 0.5rem;
    }

    .add-tag-button {
      margin-left: 0.5rem;
      font-size: 12px;
      height: 28px;
      padding: 0 10px;
      line-height: 26px;
    }

    .actions {
      text-align: right;
    }
  }

  .el-dialog__body {
    .el-tabs .el-tabs__item h3 {
      margin-bottom: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "keyword": "Keyword: %{text}",
    "add_tag": "+ Add",
    "optimize": "Optimize",
    "add_to_actions": "Add a task",
    "positioning": "Positioning",
    "engagement": "Engagement",
    "work_on_keyword": "Work on keyword: %{keywordText}",
    "fetch_keyword_data_failure": "Fetching keyword data has failed.",
    "task_created_success": "Task created successfully",
    "task_created_failure": "Task creation failed",
    "mode": "Frequency",
    "daily": "Daily",
    "weekly": "Weekly"
  },
  "fr": {
    "keyword": "Mot clé : %{text}",
    "add_tag": "+ Ajouter",
    "optimize": "Optimiser",
    "add_to_actions": "Ajouter une tâche",
    "positioning": "Positionnement",
    "engagement": "Engagement",
    "work_on_keyword": "Travailler le mot clé : %{keywordText}",
    "fetch_keyword_data_failure": "La récupération des données du mot clé a échoué.",
    "task_created_success": "Tâche crée avec succès",
    "task_created_failure": "La création de la tâche a échoué",
    "mode": "Périodicité",
    "period": "Mode",
    "daily": "Journalier",
    "weekly": "Hebdomadaire"
  },
  "de": {
    "keyword": "Passwort: %{text}",
    "add_tag": "+ Hinzufügen",
    "optimize": "Zu optimieren",
    "add_to_actions": "Aufgabe hinzufügen",
    "positioning": "Positionierung",
    "engagement": "Engagement",
    "work_on_keyword": "Bearbeiten Sie das Schlüsselwort: %{keywordText}",
    "fetch_keyword_data_failure": "Keyword-Daten konnten nicht abgerufen werden.",
    "task_created_success": "Aufgabe erfolgreich erstellt",
    "task_created_failure": "Aufgabenerstellung fehlgeschlagen",
    "mode": "Periodizität",
    "period": "Modus",
    "daily": "Täglich",
    "weekly": "Wöchentlich"
  }
}
</i18n>
