<template>
  <div>
    <div class="flex">
      <i
        v-if="start == null"
        class="grid-length-explanation"
        >{{ $t("grid20ResultsDisplay") }}</i
      >
      <div
        class="export-button"
        v-if="exportableData"
      >
        <el-button
          size="mini"
          @click="exportData"
          ><i class="fas fa-file-excel"></i> {{ $t("excel_export") }}
        </el-button>
      </div>
    </div>
    <ag-grid-vue
      style="height: 550px"
      class="ag-theme-material keyword-grid"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :sorting="true"
      :filter="true"
      :floatingFilter="true"
      rowSelection="multiple"
      :localeTextFunc="localeTextFunc"
      :suppressRowClickSelection="true"
      :suppressCellSelection="true"
      :suppressContextMenu="true"
      @gridSizeChanged="adjustGrid"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
      :context="context"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import KeywordRenderer from "@/components/gridRenderers/KeywordRenderer";
import PositionRenderer from "@/components/gridRenderers/PositionRenderer";

import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

export default {
  mixins: [agGridMixin],
  props: {
    keywordsData: Array,
    studyId: Number,
    start: Number,
    end: Number,
    startDate: DateTime,
    endDate: DateTime,
    topType: String,
    exportableData: Boolean,
    height: Number,
  },
  components: {
    AgGridVue,
    KeywordRenderer,
    PositionRenderer,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
      headerHeight: null,
    };
  },
  computed: {
    sortOrder() {
      if (this.topType == "ENTRY") {
        return "desc";
      } else if (this.topType == "EXIT") {
        return "asc";
      }
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setColumnDefs();
      this.rowData = this.keywordsData;
      const grids = document.getElementsByClassName("keyword-grid");
      grids[0].style.height = this.height + "px";
      grids[1].style.height = this.height + "px";
      // document.getElementsByClassName("keyword-grid").style.height = this.height + "px";
    },
    adjustGrid() {
      if (this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          headerTooltip: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 100,
          filter: "agTextColumnFilter",
          cellRendererFramework: "KeywordRenderer",
        },
        {
          headerName: this.$i18n.t("volume"),
          headerTooltip: this.$i18n.t("volume"),
          field: "volume",
          width: 110,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("position"),
          headerTooltip: this.$i18n.t("position"),
          field: "position",
          width: 115,
          valueFormatter: positionFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          cellRendererFramework: "PositionRenderer",
        },
        {
          field: "pastPosition",
          hide: "true",
          suppressToolPanel: true,
        },
        {
          headerName: this.$i18n.t("traffic_variation"),
          headerTooltip: this.$i18n.t("traffic_variation"),
          field: "estimatedTrafficVariation",
          width: 120,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          sort: this.sortOrder,
          suppressSizeToFit: true,
          cellClass: (params) => {
            if (params.value > 0) return "positive";
            if (params.value < 0) return "negative";
            return "neutral";
          },
        },
      ];
    },
    exportData() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      const startDateStr =
        ("0" + startDate.getDate()).slice(-2) +
        "/" +
        ("0" + (startDate.getMonth() + 1)).slice(-2) +
        "/" +
        startDate.getFullYear();
      const endDateStr =
        ("0" + endDate.getDate()).slice(-2) +
        "/" +
        ("0" + (endDate.getMonth() + 1)).slice(-2) +
        "/" +
        endDate.getFullYear();
      const params = {
        skipHeader: false,
        allColumns: true,
        onlySelected: false,
        fileName:
          this.topType +
          " Positions : " +
          this.start +
          " to " +
          this.end +
          "- SmartKeyword",
        sheetName:
          this.topType + " Positions : " + this.start + " to " + this.end,
        processHeaderCallback: (params) => {
          if (params.column.colDef.field === "position") {
            return this.$i18n.t("position") + " " + endDateStr;
          } else if (params.column.colDef.field === "pastPosition") {
            return this.$i18n.t("position") + " " + startDateStr;
          }
          return params.column.colDef.headerName;
        },
        processCellCallback: (params) => {
          if (params.value && params.column.colDef.field === "pastPosition") {
            if (params.value > 100) {
              return "> 100";
            } else {
              return params.value;
            }
          } else {
            return params.value;
          }
        },
      };
      this.gridApi.exportDataAsExcel(params);
    },
    showKeyword(keywordId) {
      this.$emit("show-keyword", keywordId);
    },
  },
  watch: {
    keywordsData() {
      this.rowData = this.keywordsData;
    },
  },
  beforeMount() {
    this.context = {
      studyId: this.studyId,
      showKeyword: this.showKeyword,
    };
  },
};

let positionFormatter = (params) => {
  if (params.value) {
    return params.value;
  } else {
    return "> 100";
  }
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.flex {
  display: flex;
  align-items: flex-end;

  .grid-length-explanation {
    margin-right: 3rem;
    word-break: break-word;
  }

  .export-button {
    margin-left: auto;
  }
}

::v-deep .positive {
  color: $--color-success;
  text-align: right;
}

::v-deep .neutral {
  text-align: right;
}

::v-deep .negative {
  color: $--color-danger;
  text-align: right;
}

@media print {
  .export-button {
    display: none !important;
  }
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "excel_export": "Export to Excel",
    "keyword": "Keyword",
    "volume": "Volume",
    "position": "Rank",
    "traffic_variation": "Δ Estimated traffic",
    "grid20ResultsDisplay": "The table shows the 20 most important variations"
  },
  "fr": {
    "excel_export": "Export Excel",
    "keyword": "Mot clé",
    "volume": "Volume",
    "position": "Position",
    "traffic_variation": "Δ Trafic estimé",
    "grid20ResultsDisplay": "Le tableau affiche les 20 variations les plus importantes"
  },
  "de": {
    "excel_export": "Excel exportieren",
    "keyword": "Stichwort",
    "volume": "Volumen",
    "position": "Position",
    "traffic_variation": "Δ Geschätzter Verkehr",
    "grid20ResultsDisplay": "Die Tabelle zeigt die 20 wichtigsten Varianten"
  }
}
</i18n>
