<template>
  <li class="nav-item pl-3">
    <a
      v-if="gscConfigurationsExist"
      :href="linkGoogleGscSPA"
      class="nav-link"
    >
      Google Search Console
    </a>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      linkGoogleGscSPA: null,
      googleGscAnchor: "#googleGSC",
    };
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
    gscConfigurationsExist() {
      return this.studyLinks["gscConfigurations"];
    },
  },
  mounted() {
    // this.linkGoogleGscSPA = `/spa/to?redirect_uri=/studies/${this.study["id"]}/configuration?tab=googleGsc`
    this.linkGoogleGscSPA = `/spa-app/studies/${this.study["id"]}/configuration?tab=googleGsc`;
  },
};
</script>
