<template>
  <div>
    <el-card>
      <center>
        <span v-if="topType === 'ENTRY'"
          ><b>{{ $t("top_progressions") }}</b></span
        >
        <span v-else
          ><b>{{ $t("flop_progressions") }}</b></span
        >
      </center>
      <grid
        :keywords-data="keywordsData"
        :study-id="studyId"
        :top-type="topType"
        :start-date="startDate"
        :end-date="endDate"
        @show-keyword="$emit('show-keyword', $event)"
        @show-task="$emit('show-task', $event)"
      >
      </grid>
    </el-card>
  </div>
</template>

<script>
import Grid from "@/pages/Summary/TopMovements/KeywordGrid";

export default {
  props: {
    studyId: Number,
    startDate: Number,
    endDate: Number,
    topType: String,
    keywordsData: Array,
  },
  components: {
    Grid,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "top_progressions": "Winners",
    "flop_progressions": "Losers"
  },
  "fr": {
    "top_progressions": "Les plus fortes hausses",
    "flop_progressions": "Les plus fortes baisses"
  },
  "de": {
    "top_progressions": "Die größten Anstiege",
    "flop_progressions": "Die größten Tropfen"
  }
}
</i18n>
