<template>
  <div>
    <div class="export-button">
      <el-button
        size="mini"
        @click="exportData"
        ><i class="fas fa-file-excel"></i> {{ $t("excel_export") }}
      </el-button>
    </div>
    <ag-grid-vue
      style="height: 550px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :resizable="true"
      :sorting="true"
      :filter="true"
      :floatingFilter="true"
      rowSelection="multiple"
      :localeTextFunc="localeTextFunc"
      :suppressRowClickSelection="true"
      :suppressCellSelection="true"
      :suppressContextMenu="true"
      :excelStyles="excelStyles"
      @gridSizeChanged="adjustGrid"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
      :context="context"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import KeywordRenderer from "@/components/gridRenderers/KeywordRenderer";
import PositionRenderer from "@/components/gridRenderers/PositionRenderer";

import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import { DateTime } from "luxon";

export default {
  mixins: [agGridMixin],
  props: {
    positionHistoryData: Array,
    studyId: Number,
    firstDate: Object,
    lastDate: Object,
    frequency: String,
  },
  components: {
    AgGridVue,
    KeywordRenderer,
    PositionRenderer,
  },
  data() {
    return {
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: { sortable: true, resizable: true },
      excelStyles: [
        {
          id: "decreased-value",
          interior: {
            color: "#98e698",
            pattern: "Solid",
          },
        },
        {
          id: "increased-value",
          interior: {
            color: "#ff6666",
            pattern: "Solid",
          },
        },
      ],
    };
  },
  computed: {
    columnDefs() {
      return [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 200,
          filter: "agTextColumnFilter",
          cellRendererFramework: "KeywordRenderer",
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          width: 125,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
        {
          headerName: this.$i18n.t("position"),
          groupId: "PositionsGroupId",
          children: this.columnsPosition,
        },
      ];
    },
    columnsPosition() {
      let children = [];
      for (
        let date = this.lastDate;
        date >= this.firstDate;
        date = date.minus({ day: this.frequency === "DAY" ? 1 : 7 })
      ) {
        children.push({
          headerName:
            this.$i18n.locale === "en"
              ? date.toFormat("MM/dd/yyyy")
              : date.toFormat("dd/MM/yyyy"),
          field: date.toFormat("yyyy-MM-dd"),
          width: 140,
          suppressSizeToFit: true,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          valueFormatter: positionFormatter,
          valueGetter: this.getPositionValue,
          cellClass: this.applyColorClass,
        });
      }
      return children;
    },
    positionHistoryDataView() {
      const positionHistoryDataView = [];
      const positionsHistoryData = this.positionHistoryData;
      positionsHistoryData.forEach((positionsHistory) => {
        let history = new Map();
        Object.keys(positionsHistory.history).sort().forEach((date) => {
          history.set(date, positionsHistory.history[date].position);
        });
        positionHistoryDataView.push({ ...positionsHistory, history });
      });
      return positionHistoryDataView;
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.rowData = this.positionHistoryDataView;
    },
    adjustGrid() {
      if (this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    getPositionValue(params) {
      if (params.data.history.has(params.colDef.field)) {
        return params.data.history.get(params.colDef.field);
      } else {
        return null;
      }
    },
    applyColorClass(params) {
      const classes = ["ag-numeric-cell"];
      const value = params.value;
      if (value) {
        const position = value;
        const history = Array.from(params.data.history);
        const currentPosition = history.indexOf(
          history.find(
            (position) => String(position[0]) === params.colDef.field
          )
        );
        if (currentPosition - 1 >= 0) {
          const previousPosition = history[currentPosition - 1][1];
          if (position > 100 && previousPosition > 100) {
            return classes;
          }
          if (position > previousPosition) {
            classes.push("increased-value");
          } else if (position < previousPosition) {
            classes.push("decreased-value");
          }
        }
      }
      return classes;
    },
    exportData() {
      const params = {
        skipHeader: false,
        allColumns: true,
        onlySelected: false,
        fileName: "Positions evolution - SmartKeyword",
        sheetName: "Positions evolution",
        processCellCallback: (params) => {
          if (params.value && !params.column.colDef.field) {
            if (params.value > 100) {
              return "> 100";
            } else {
              return params.value;
            }
          } else {
            return params.value;
          }
        },
      };
      this.gridApi.exportDataAsExcel(params);
    },
    showKeyword(keywordId) {
      this.$emit("show-keyword", keywordId);
    },
  },
  watch: {
    positionHistoryData() {
      if (this.gridApi) {
        this.rowData = this.positionHistoryDataView;
        this.gridApi.sizeColumnsToFit();
      }
    },
    rowData() {
      if (this.gridApi) this.gridApi.sizeColumnsToFit();
    },
  },
  beforeMount() {
    this.context = {
      studyId: this.studyId,
      showKeyword: this.showKeyword,
    };
  },
};

let positionFormatter = (params) => {
  if (params.value === null) {
    return "--";
  } else if (params.value < 101) {
    return params.value;
  } else {
    return "> 100";
  }
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.export-button {
  text-align: right;
}

::v-deep .increased-value {
  background: rgba(255, 0, 0, 0.1);
}

::v-deep .decreased-value {
  background: rgba(0, 255, 0, 0.1);
}
</style>

<i18n>
{
  "en": {
    "excel_export": "Export to Excel",
    "keyword": "Keyword",
    "volume": "Volume",
    "position": "Rank",
    "traffic_variation": "Traffic variation"
  },
  "fr": {
    "excel_export": "Export Excel",
    "keyword": "Mot clé",
    "volume": "Volume",
    "position": "Position",
    "traffic_variation": "Variation de trafic"
  },
  "de": {
    "excel_export": "Excel exportieren",
    "keyword": "Stichwort",
    "volume": "Volumen",
    "position": "Position",
    "traffic_variation": "Verkehrsvariation"
  }
}
</i18n>
