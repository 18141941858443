<template>
  <div>
    <div class="presets">
      <div class="label">
        {{ $t("presets") }}
      </div>
      <div>
        <el-radio-group
          v-model="preset"
          plain
          size="mini"
        >
          <el-radio-button :label="null">{{
            $t("bestKeywords")
          }}</el-radio-button>
          <el-tooltip
            :content="$t('lowCtrExplanation')"
            placement="bottom"
          >
            <el-radio-button label="low_ctr">{{
              $t("lowCtr")
            }}</el-radio-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('opportunitiesExplanation')"
            placement="bottom"
          >
            <el-radio-button label="opportunities">{{
              $t("opportunities")
            }}</el-radio-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('missedKeywordsExplanation')"
            placement="bottom"
          >
            <el-radio-button label="missed_keywords">{{
              $t("missedKeywords")
            }}</el-radio-button>
          </el-tooltip>
        </el-radio-group>
      </div>
    </div>
    <div class="actions">
      <import-and-export-grid-actions
        v-if="accountId"
        :gridApi="gridApi"
        :columnApi="columnApi"
        :accountId="accountId"
        :isRowsSelected="isRowsSelected"
        @importKeywords="importKeywords($event)"
        @exportExcel="exportExcel()"
      >
      </import-and-export-grid-actions>
    </div>
    <ag-grid-vue
      v-loading="isLoading"
      style="height: 800px"
      class="ag-theme-material"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      rowSelection="multiple"
      :isRowSelectable="isRowSelectable"
      :rowData="rowData"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      @selection-changed="selectionChanged"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";

const TrafficsRepository = RepositoryFactory.get("traffics");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

import ImportAndExportGridActions from "@/components/ImportAndExportGridActions";
import KeywordImportedRenderer from "@/components/gridRenderers/KeywordImportedRenderer";
import { listAndImportKeywordsMixin } from "@/mixins/listAndImportKeywordsMixin";
export default {
  mixins: [agGridMixin, listAndImportKeywordsMixin],
  props: {
    dimensionsData: Array,
    filters: Array,
    period: Array,
    comparativePeriod: Array,
  },
  components: {
    AgGridVue,
    ImportAndExportGridActions,
    KeywordImportedRenderer,
  },
  computed: {
    columnDefs() {
      let columnDefs = [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 300,
          filter: "agTextColumnFilter",
          cellRendererFramework: "KeywordImportedRenderer",
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: this.$i18n.t("clicks"),
          field: "main_clicks",
          valueGetter: (params) => roundNumber(params.data["main_clicks"]),
          valueFormatter: numberFormatter,
          aggFunc: "sum",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
        },
      ];
      if (this.comparativePeriod !== null && this.comparativePeriod.length) {
        columnDefs = columnDefs.concat([
          {
            headerName: this.$i18n.t("comparativeClicks"),
            field: "comparative_clicks",
            valueGetter: (params) =>
              roundNumber(params.data["comparative_clicks"]),
            valueFormatter: numberFormatter,
            aggFunc: "sum",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
          },
          {
            headerName: this.$i18n.t("clicksEvolution"),
            field: "clicks_evolution",
            valueGetter: (params) =>
              roundNumber(params.data["clicks_evolution"]),
            valueFormatter: numberFormatter,
            aggFunc: "sum",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
          },
        ]);
      }

      if (
        this.comparativePeriod === null ||
        this.comparativePeriod.length === 0
      ) {
        columnDefs = columnDefs.concat([
          {
            headerName: this.$i18n.t("averagePosition"),
            field: "mainAvgPosition",
            valueGetter: (params) =>
              Math.round(params.data["main_avg_position"] * 10) / 10,
            aggFunc: "avg",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
          },
        ]);
      }

      columnDefs = columnDefs.concat([
        {
          headerName: this.$i18n.t("impressions"),
          field: "main_impressions",
          valueGetter: (params) => roundNumber(params.data["main_impressions"]),
          valueFormatter: numberFormatter,
          aggFunc: "sum",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          hide: this.comparativePeriod !== null,
        },
      ]);

      if (this.comparativePeriod !== null) {
        columnDefs = columnDefs.concat([
          {
            headerName: this.$i18n.t("comparativeImpressions"),
            field: "comparative_impressions",
            valueGetter: (params) =>
              roundNumber(params.data["comparative_impressions"]),
            aggFunc: "sum",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
            hide: this.comparativePeriod !== null,
          },
          {
            headerName: this.$i18n.t("impressions_evolution"),
            field: "impressions_evolution",
            valueGetter: (params) =>
              roundNumber(params.data["impressions_evolution"]),
            valueFormatter: numberFormatter,
            aggFunc: "sum",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
            hide: this.comparativePeriod !== null,
          },
        ]);
      }

      if (
        this.comparativePeriod === null ||
        this.comparativePeriod.length === 0
      ) {
        columnDefs = columnDefs.concat([
          {
            headerName: this.$i18n.t("ctr"),
            field: "main_avg_ctr",
            valueGetter: (params) =>
              Math.round(params.data["main_avg_ctr"] * 100 * 100) / 100,
            //valueFormatter: numberFormatter,
            aggFunc: "avg",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            filterParams: {
              defaultOption: "greaterThan",
            },
            suppressSizeToFit: true,
          },
        ]);
      }

      return columnDefs;
    },
  },
  data() {
    return {
      rowData: null,
      gridApi: null,
      columnApi: null,
      gridOptions: { onColumnVisible: () => this.adjustGrid() },
      defaultColDef: { sortable: true, resizable: true },
      preset: null,
      isLoading: false,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    loadGrid() {
      this.mapData();
    },
    mapData() {
      this.isLoading = true;
      TrafficsRepository.createTrafficGrid(
        this.accountId,
        this.filters,
        "Keyword",
        this.period,
        this.comparativePeriod,
        this.preset
      )
        .then((data) => {
          this.getStudyKeywords(data);
        })
        .catch((error) => console.error(error));
    },
    exportExcel() {
      const columnKeys = this.columnApi
        .getAllDisplayedColumnGroups()
        .map((columnGroup) => columnGroup.colId);
      const params = {
        skipHeader: false,
        columnKeys: columnKeys,
        onlySelected: false,
        fileName: "Traffic Keywords Export - SmartKeyword",
        sheetName: "Traffic Keywords",
      };
      this.gridApi.exportDataAsExcel(params);
    },
  },
  beforeMount() {
    this.loadGrid();
  },
  watch: {
    $props: {
      handler: "loadGrid",
      deep: true,
    },
    preset() {
      this.mapData();
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let roundNumber = (number) => Math.round(number * 100) / 100;
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.presets {
  text-align: left;

  .label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}

.actions {
  text-align: right;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "presets": "Presets",
    "bestKeywords": "Best keywords",
    "lowCtr": "Low CTR",
    "excelExport": "Export to Excel",
    "keyword": "Keyword",
    "clicks": "Clicks",
    "comparativeClicks": "Comparative clicks",
    "averagePosition": "Average ranking",
    "impressions": "Impressions",
    "comparativeImpressions": "Comparative impressions",
    "impressions_evolution": "Impressions evolution",
    "ctr": "Average CTR (%)",
    "clicksEvolution": "Clicks evolution",
    "opportunities": "Opportunities",
    "missedKeywords": "Missed keywords",
    "lowCtrExplanation": "Keywords with low click-to-impression ratio",
    "missedKeywordsExplanation": "Keywords with an average position greater than 20 and with significant volume",
    "opportunitiesExplanation": "Keywords with an average position between 8 and 20 and whose volume is important",
    "keywords_import": "Import selected keywords",
    "add_keywords_success": "Keywords were added successfully.",
    "add_keywords_fail": "An error occurred."
  },
  "fr": {
    "presets": "Présélections",
    "bestKeywords": "Meilleurs mots clés",
    "lowCtr": "CTR faible",
    "excelExport": "Export Excel",
    "keyword": "Mots clé",
    "clicks": "Clics",
    "comparativeClicks": "Clics comparatifs",
    "averagePosition": "Position moyenne",
    "impressions": "Impressions",
    "comparativeImpressions": "Impressions comparatives",
    "impressions_evolution": "Evolution impressions",
    "ctr": "CTR moyen (%)",
    "clicksEvolution": "Evolution Clics",
    "opportunities": "Opportunités",
    "missedKeywords": "Mots clés manqués",
    "lowCtrExplanation": "Mots clés avec ratio clic/impressions faible",
    "missedKeywordsExplanation": "Mots clés avec une position moyenne supérieure à 20 et dont le volume est important",
    "opportunitiesExplanation": "Mots clés avec une position moyenne entre 8 et 20 et dont le volume est important",
    "keywords_import": "Importer les mots clés sélectionnés",
    "add_keywords_success": "Les mots clés ont été importés avec succès.",
    "add_keywords_fail": "Une erreur est survenue."
  },
  "de": {
    "presets": "Voreinstellungen",
    "bestKeywords": "Beste Schlüsselwörter",
    "lowCtr": "niedrige CTR",
    "excelExport": "Excel exportieren",
    "keyword": "Schlüsselwörter",
    "clicks": "Klicks",
    "comparativeClicks": "Vergleichende Klicks",
    "averagePosition": "Durchschnittliche Position",
    "impressions": "Impressionen",
    "comparativeImpressions": "Vergleichende Eindrücke",
    "impressions_evolution": "Impressionen der Evolution",
    "ctr": "CTR moyen (%)",
    "clicksEvolution": "Evolution Klicks",
    "opportunities": "Gelegenheiten",
    "missedKeywords": "Verpasste Schlüsselwörter",
    "lowCtrExplanation": "Keywords mit niedrigem Klick-zu-Impression-Verhältnis",
    "missedKeywordsExplanation": "Keywords mit einer durchschnittlichen Position größer als 20 und mit einem hohen Volumen",
    "opportunitiesExplanation": "Keywords mit einer durchschnittlichen Position zwischen 8 und 20 und deren Volumen wichtig ist",
    "keywords_import": "Importieren Sie ausgewählte Schlüsselwörter",
    "add_keywords_success": "Die Keywords wurden erfolgreich importiert.",
    "add_keywords_fail": "Ein Fehler ist aufgetreten."
  }
}
</i18n>
