export const isSameDomainNameMixin = {
  methods: {
    isSameDomainName(domain, hostname) {
      try {
        if (!hostname) return false;

        let subdomdains = domain;
        if (domain.startsWith("http") || domain.startsWith("https"))
          subdomdains = new URL(domain).hostname;
        subdomdains = subdomdains.split(".").reverse();
        let hostNameList = hostname.split(".").reverse();
        for (let i = 0; i < subdomdains.length; i++) {
          if (!hostNameList[i]) return false;
          if (hostNameList[i] != subdomdains[i]) return false;
        }
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
};