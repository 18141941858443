<template>
  <el-tooltip
    placement="top"
    :content="params.value"
  >
    <div>
      <a
        target="_blank"
        :href="`${params.value}`"
        >{{ params.value }}</a
      >
    </div>
  </el-tooltip>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
</style>
