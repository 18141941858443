<template>
  <div>
    <div class="title">
      {{ $t("trust_flow_and_citation_flow") }}
    </div>
    <div>
      <highcharts
        ref="highchart"
        :options="chartOptions"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import { epochDayToDateTime } from "@/javascripts/dateHelpers";

export default {
  props: {
    data: Object,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 300,
          zoomType: "x",
        },
        title: {
          text: "",
          useHTML: true,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          title: {
            text: this.$i18n.t("percentage"),
          },
          min: 0,
          max: 100,
        },
        tooltip: {
          shared: true,
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateSeries() {
      if (this.data && Object.keys(this.data.history).length > 0) {
        this.chartOptions.series = [];
        const trustFlowData = [];
        const citationFlowData = [];

        for (const [key, value] of Object.entries(this.data.history)) {
          if (value.trustFlow) {
            trustFlowData.push({
              x: new Date(key).getTime(),
              y: value.trustFlow,
            });
          }

          if (value.citationFlow) {
            citationFlowData.push({
              x: new Date(key).getTime(),
              y: value.citationFlow,
            });
          }
        }

        this.chartOptions.series.push({
          name: this.$i18n.t("trust_flow"),
          data: trustFlowData,
        });

        this.chartOptions.series.push({
          name: this.$i18n.t("citation_flow"),
          data: citationFlowData,
        });
      } else {
        this.chartOptions.series = [];
      }
    },
  },
  mounted() {
    this.updateSeries();
  },
  watch: {
    data() {
      this.updateSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>

<i18n>
{
  "en": {
    "trust_flow": "Trust Flow",
    "citation_flow": "Citation Flow",
    "percentage": "Percentage",
    "trust_flow_and_citation_flow": "Trust Flow / Citation Flow"
  },
  "fr": {
    "trust_flow": "Trust Flow",
    "citation_flow": "Citation Flow",
    "percentage": "Pourcentage",
    "trust_flow_and_citation_flow": "Trust Flow / Citation Flow"
  },
  "de": {
    "trust_flow": "Vertrauensfluss",
    "citation_flow": "Zitierfluss",
    "percentage": "Prozentsatz",
    "trust_flow_and_citation_flow": "Vertrauensfluss / Zitationsfluss"
  }
}
</i18n>
