<template>
  <div class="metrics">
    <div class="container">
      <!-- <h4>{{$t("global_metrics")}}</h4> -->
      <el-row
        type="flex"
        :gutter="24"
      >
        <el-col :span="6">
          <el-card>
            <h5>{{ $t("seoTrafic") }}</h5>
            <div class="metric-data">
              <h2>
                {{ data ? Math.round(data.all_metrics.SumPageviews) : "-" }}
              </h2>
            </div>
            <div>{{ $t("visits") }}</div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card>
            <h5>{{ $t("bestKeyword") }}</h5>
            <el-tooltip
              placement="top"
              :content="
                data && data.best_keywords.length > 0
                  ? data.best_keywords[0].keyword
                  : '-'
              "
            >
              <div class="metric-data">
                <h3 class="keyword">
                  {{
                    data && data.best_keywords.length > 0
                      ? data.best_keywords[0].keyword
                      : "-"
                  }}
                </h3>
              </div>
            </el-tooltip>
            <div>
              {{ $t("numberOfClicks") }}:
              {{
                data && data.best_keywords.length > 0
                  ? data.best_keywords[0].clicks
                  : "-"
              }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card>
            <h5>{{ $t("averageTime") }}</h5>
            <div class="metric-data">
              <h2>
                {{ data ? Math.round(data.all_metrics.AvgTimeOnPage) : "-" }}
              </h2>
            </div>
            <div>{{ $t("seconds") }}</div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card>
            <h5>{{ $t("reboundRate") }}</h5>
            <div class="metric-data">
              <h2>
                {{ data ? Math.round(data.all_metrics.AvgBounceRate) : "-" }}%
              </h2>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>

<style scoped lang="scss">
.metrics {
  .el-col {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .el-card {
    display: flex;
    flex-grow: 1;
    ::v-deep .el-card__body {
      width: 100%;
    }

    .metric-data {
      h2,
      h3 {
        padding-bottom: 5px;
      }

      .keyword {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "visits": "Sessions",
    "bestKeyword": "Best Keyword",
    "averageTime": "Average Time",
    "reboundRate": "Bound Rate",
    "seoTrafic": "SEO Traffic",
    "numberOfClicks": "Number of clicks",
    "seconds": "seconds"
  },
  "fr": {
    "visits": "visites",
    "bestKeyword": "Meilleur mot clé",
    "averageTime": "Temps moyen",
    "reboundRate": "Taux de rebond",
    "seoTrafic": "Trafic SEO",
    "numberOfClicks": "Nombre de clics",
    "seconds": "secondes"
  },
  "de": {
    "visits": "Besuche",
    "bestKeyword": "Bestes Stichwort",
    "averageTime": "Durchschnittliche Zeit",
    "reboundRate": "Rebound-Rate",
    "seoTrafic": "SEO-Traffic",
    "numberOfClicks": "Klicken Sie auf Name",
    "seconds": "Sekunden"
  }
}
</i18n>
