<template>
  <el-dialog
    :title="$t('add_group')"
    :visible.sync="dialogVisible"
    width="30%"
  >
    <el-form
      size="mini"
      label-width="180px"
      @submit.native.prevent
    >
      <el-form-item
        :label="$t('url_of_competitor')"
        v-if="selectedUrl == null"
      >
        <el-input
          v-model="competitor.url"
          ref="urlInput"
          size="mini"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('competitor_group')">
        <el-select
          v-model="competitor.group"
          filterable
          allow-create
          ref="groupSelect"
          size="mini"
        >
          <el-option
            v-for="group in competitorGroup"
            :key="group.value"
            :label="group.label"
            :value="group.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button
        plain
        type="primary"
        @click="sendForm"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    selectedUrl: String,
    selectedCompetitorGroup: String,
    studyId: Number,
    competitorTypeOptions: Array,
  },
  components: {},
  data() {
    return {
      competitor: {
        url: this.selectedUrl,
        group: this.selectedCompetitorGroup,
      },
    };
  },
  methods: {
    sendForm() {
      this.$emit("submit", this.competitor);
      this.$emit("input", false);
      this.competitor = {
        url: this.selectedUrl,
        group: this.selectedCompetitorGroup,
      };
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
    competitorGroup() {
      return this.competitorTypeOptions.filter((group) => group.value != null);
    },
  },
  watch: {
    selectedUrl() {
      this.competitor.url = this.selectedUrl;
    },
    selectedCompetitorGroup() {
      this.competitor.group = this.selectedCompetitorGroup;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
</style>

<i18n>
{
  "en": {
    "url_of_competitor": "URL of the competitor",
    "competitor_group": "Group of competitors",
    "add_group": "Add to a group",
    "confirm": "Confirm",
    "others": "Others"
  },
  "fr": {
    "url_of_competitor": "URL de votre concurrent",
    "competitor_group": "Groupe de concurrents",
    "add_group": "Ajouter à un groupe",
    "confirm": "Valider",
    "others": "Autres"
  },
  "de": {
    "url_of_competitor": "Die URL Ihres Konkurrenten",
    "competitor_group": "Gruppe von Konkurrenten",
    "add_group": "Zur Gruppe hinzufügen",
    "confirm": "Bestätigen",
    "others": "Andere"
  }
}
</i18n>
