<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h3 class="title">{{ $t("edit_client_info") }}</h3>
      </div>
      <dynamic-form
        v-model="updatedClient"
        :form="data.form"
      >
      </dynamic-form>
      <div class="submit">
        <el-button
          type="success"
          plain
          @click="submit()"
        >
          {{ $t("validate") }}
        </el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import DynamicForm from "@/components/DynamicForm";

export default {
  props: {
    value: Boolean,
    data: Object,
  },
  components: {
    DynamicForm,
  },
  data() {
    return {
      updatedClient: {},
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    submit() {
      let details = {};
      details.client = this.updatedClient;
      details.link = this.data;
      this.$emit("update-client", details);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  flex-direction: row;
}

.title {
  text-align: center;
}

.submit {
  margin-top: 1rem;
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "edit_client_info": "Client Information",
    "validate": "Validate"
  },
  "fr": {
    "edit_client_info": "Informations Client",
    "validate": "Valider"
  },
  "de": {
    "edit_client_info": "Kundeninformation",
    "validate": "Bestätigen"
  }
}
</i18n>
