<template>
  <div class="serp-stats">
    <div>
      <el-tooltip
        v-if="domainAuthority"
        effect="dark"
        :content="$t('minimum_domain_authority_tooltip')"
        placement="right"
        popper-class="serp-stat-popper"
      >
        <span class="stat">
          <span class="label">{{ $t("minimum_domain_authority") }}</span>
          <span class="value">{{ domainAuthority.min }}</span>
        </span>
      </el-tooltip>
      <el-tooltip
        v-if="referringDomainsCount"
        effect="dark"
        :content="$t('minimum_referring_domains_tooltip')"
        placement="right"
        popper-class="serp-stat-popper"
      >
        <span class="stat">
          <span class="label">{{ $t("minimum_referring_domains") }}</span>
          <span class="value">{{ referringDomainsCount.min }}</span>
        </span>
      </el-tooltip>
    </div>
    <div>
      <el-tooltip
        v-if="wordsCount"
        effect="dark"
        :content="$t('minimum_words_count_tooltip')"
        placement="right"
        popper-class="serp-stat-popper"
      >
        <span class="stat">
          <span class="label">{{ $t("minimum_words_count") }}</span>
          <span class="value">{{ wordsCount.min }}</span>
        </span>
      </el-tooltip>
      <el-tooltip
        v-if="wordsCount"
        effect="dark"
        :content="$t('median_words_count_tooltip')"
        placement="right"
        popper-class="serp-stat-popper"
      >
        <span class="stat">
          <span class="label">{{ $t("median_words_count") }}</span>
          <span class="value">{{ wordsCount.median }}</span>
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    domainAuthority: Object,
    referringDomainsCount: Object,
    wordsCount: Object,
  },
};
</script>

<style lang="scss">
.serp-stat-popper {
  max-width: 500px;
}
</style>
<style lang="scss" scoped>
@import "@/styles/variables";

.serp-stats {
  margin-bottom: 0.5rem;

  .stat {
    padding-right: 0.5rem;

    .label {
      color: $--color-text-secondary;
    }

    .value {
      font-weight: 500;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "minimum_domain_authority": "Minimum Domain Authority",
    "minimum_domain_authority_tooltip": "This number is the result of Google's first page with the smallest 'Domain Authority' (data provided by Moz.com and informing on a website's reputation, on a scale from 1 to 100 (100 being for websites such as Wikipedia..).",
    "minimum_referring_domains": "Minimum referring domains",
    "minimum_referring_domains_tooltip": "This number is the result of Google's first page with the least external links towards the website (those links being counted once per linking domains).",
    "median_words_count": "Median words count",
    "median_words_count_tooltip": "This number corresponds to the median number of words of Google's first page.",
    "minimum_words_count": "Minimum words count",
    "minimum_words_count_tooltip": "This number is Google's first page's result having the smallest number of words."
  },
  "fr": {
    "minimum_domain_authority": "Domain Authority minimum",
    "minimum_domain_authority_tooltip": "Ce nombre correspond au résultat de la 1ère page de Google ayant le plus petit 'Domain Authority' (cette donnée est fournie par Moz.com et reflète la réputation d'un site, sur une échelle de 1 à 100. 100 étant pour des sites comme Wikipedia..).",
    "minimum_referring_domains": "Domaines référents minimum",
    "minimum_referring_domains_tooltip": "Ce nombre correspond au résultat de la 1ère page de Google ayant le moins de liens externes vers son site (ces liens étant comptés de façon unique par nom de domaine référent).",
    "median_words_count": "Nombre de mots médian",
    "median_words_count_tooltip": "Ce nombre correspond à la médiane du nombre de mots de la 1ère page de Google.",
    "minimum_words_count": "Nombre de mots minimum",
    "minimum_words_count_tooltip": "Ce nombre correspond au résultat de la 1ère page de Google ayant le plus petit nombre de mots."
  },
  "de": {
    "minimum_domain_authority": "Mindestdomänenautorität",
    "minimum_domain_authority_tooltip": "Diese Zahl entspricht dem Ergebnis der ersten Seite von Google mit der kleinsten „Domain Authority“ (diese Daten werden von Moz.com bereitgestellt und spiegeln die Reputation einer Website auf einer Skala von 1 bis 100 wider. 100 steht für Websites wie Wikipedia ..).",
    "minimum_referring_domains": "Minimale verweisende Domains",
    "minimum_referring_domains_tooltip": "Diese Zahl entspricht dem Ergebnis der 1. Seite von Google mit den wenigsten externen Links zu seiner Website (diese Links werden eindeutig nach verweisendem Domainnamen gezählt).",
    "median_words_count": "Mittlere Wortzahl",
    "median_words_count_tooltip": "Diese Zahl entspricht dem Median der Wortzahl der 1. Seite von Google.",
    "minimum_words_count": "Minimale Wortzahl",
    "minimum_words_count_tooltip": "Diese Zahl entspricht dem Ergebnis der 1. Seite von Google mit den wenigsten Wörtern."
  }
}
</i18n>
