<template>
  <div>
    <div class="d-flex flex-row justify-content-between mb-1">
      <span class="category-title"> {{ $t("tagMetaDesc") }}</span>
      <el-tooltip
        :content="$t('tooltipMeta')"
        placement="top"
      >
        <i class="fas fa-question-circle my-auto"></i>
      </el-tooltip>
    </div>
    <div class="seo-scores">
      <div class="element seo-score">
        <el-progress
          class="mini-dashboard"
          type="dashboard"
          :percentage="ownScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div class="text">
          {{ $t("myScore") }}
        </div>
      </div>
      <el-divider
        direction="vertical"
        class="vertical-divider"
      />
      <div class="element seo-score">
        <el-progress
          class="mini-dashboard"
          type="dashboard"
          :percentage="serpScore"
          :stroke-width="7"
          :format="format"
          :color="colors"
        >
        </el-progress>
        <div class="text">
          {{ $t("serp") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ownScore: Number,
    serpScore: Number,
  },
  data() {
    return {
      colors: [
        { color: "#FF0000", percentage: 20 },
        { color: "#FFCC00", percentage: 40 },
        { color: "#4ECDC4", percentage: 60 },
        { color: "#66FF00", percentage: 80 },
        { color: "#008000", percentage: 100 },
      ],
    };
  },
  methods: {
    format(percentage) {
      return percentage;
    },
  },
};
</script>

<style lang="scss" scoped>
.seo-scores {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;

  .vertical-divider {
    height: unset;
  }

  .element {
    flex: 1;
  }

  .seo-score {
    text-align: center;

    .text {
      text-align: center;
      font-size: 0.9rem;
      word-break: break-word;
    }

    .mini-dashboard {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        font-size: 0.8rem !important;
      }
      ::v-deep .el-progress-circle {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}

.category-title {
  font-weight: bold;
}
</style>

<i18n>
{
    "en": {
        "tagMetaDesc": "Meta description tag",
        "serp": "SERP",
        "myScore": "My score",
        "tooltipMeta":"This score allows you to compare your beacon to that of others and thus improve your CTR (clicks through rate)"
    },
    "fr": {
        "tagMetaDesc": "Balise Meta Description",
        "serp": "SERP",
        "myScore": "Mon score",
        "tooltipMeta":"Ce score vous permet de comparer votre balise à celle des autres et ainsi d'améliorer votre CTR (taux de clic ou encore \"clics through rate\")"
    },
    "it": {
        "tagMetaDesc": "Meta tag descrizione",
        "serp": "SERP",
        "myScore": "Il mio punteggio",
        "tooltipMeta":"Questo punteggio ti permette di confrontare il tuo beacon con quello di altri e quindi migliorare il tuo CTR (clicks through rate)"
    },
    "es": {
        "tagMetaDesc": "Etiqueta de meta descripción",
        "serp": "SERP",
        "myScore": "Mi puntuación",
        "tooltipMeta":"Esta puntuación le permite comparar su baliza con la de otros y así mejorar su CTR (tasa de clics)"
    },
    "de": {
        "tagMetaDesc": "Meta-Beschreibungs-Tag",
        "serp": "SERP",
        "myScore": "Meine Punktzahl",
        "tooltipMeta":"Mit diesem Score können Sie Ihren Beacon mit dem anderer vergleichen und so Ihre CTR (Clicks Through Rate) verbessern"
    },
    "nl": {
        "tagMetaDesc": "Metabeschrijvingstag",
        "serp": "SERP",
        "myScore": "Mijn score",
        "tooltipMeta":"Met deze score kunt u uw baken vergelijken met die van anderen en zo uw CTR (klikfrequentie) verbeteren"
    }
}
</i18n>
