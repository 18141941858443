<template>
  <ag-grid-vue
    style="height: 800px"
    class="ag-theme-material"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :rowData="rowData"
    :resizable="true"
    :sorting="true"
    :filter="true"
    :floatingFilter="true"
    :localeTextFunc="localeTextFunc"
    :suppressCellSelection="true"
    @gridReady="onGridReady"
    @firstDataRendered="adjustGrid"
    @rowDataChanged="adjustGrid"
    :context="context"
  >
  </ag-grid-vue>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const UsersRepository = RepositoryFactory.get("users");
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";

let ActionsComponent = {
  template: `
		<div>
			<a :href="'/u/' + userId + '/edit'"><el-button size="mini" plain icon="fas fa-user-edit"></el-button></a>
			<el-button size="mini" type="danger" plain icon="fas fa-trash-alt" @click="invokeDeleteUser"></el-button>
		</div>
	`,
  data: function () {
    return {
      userId: null,
    };
  },
  mounted() {
    this.userId = this.params.data.id;
  },
  methods: {
    invokeDeleteUser() {
      this.params.context.usersGrid.deleteUser(this.userId);
    },
  },
};

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
    ActionsComponent,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.defaultColDef = {
        menuTabs: ["filterMenuTab"],
        sortable: true,
        resizable: true,
      };
      this.columnDefs = [
        {
          headerName: "ID",
          field: "id",
          minWidth: 100,
          maxWidth: 120,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterValueGetter: (params) =>
            params.data ? parseInt(params.data.id) : null,
          filterParams: {
            defaultOption: "equals",
          },
        },
        {
          headerName: this.$i18n.t("first_name"),
          field: "firstName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("last_name"),
          field: "lastName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("email"),
          field: "email",
          filter: "agTextColumnFilter",
        },
        {
          headerName: this.$i18n.t("owner"),
          width: 125,
          field: "owner",
          cellRenderer: this.checkRenderer,
          cellClass: "text-center",
        },
        {
          headerName: this.$i18n.t("admin"),
          width: 125,
          field: "isAdmin",
          cellRenderer: this.checkRenderer,
          cellClass: "text-center",
        },
        {
          headerName: this.$i18n.t("actions"),
          minWidth: 130,
          maxWidth: 130,
          cellRendererFramework: "ActionsComponent",
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sorting: false,
        },
      ];
    },
    fetchData() {
      UsersRepository.getUsers()
        .then((data) => {
          this.rowData = data.data.map((user) => ({
            id: user.id,
            ...user.attributes,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(userId) {
      if (confirm(this.$i18n.t("delete_confirmation"))) {
        UsersRepository.deleteUser(userId)
          .then((data) => {
            this.rowData = this.rowData.filter((row) => row.id !== userId);
            this.$message.success(this.$i18n.t("delete_success"));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    linkRenderer(params) {
      if (params.value === null) {
        return "<i>" + this.$i18n.t("grid.no_value") + "</i>";
      }

      return `<a href="/a/${params.data.id}">${params.value}</a>`;
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.fetchData();
    this.context = { usersGrid: this };
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.text-center {
  text-align: center;
}
::v-deep a + .el-button {
  margin-left: 10px;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "first_name": "First Name",
    "last_name": "Last name",
    "email": "Email",
    "owner": "Owner",
    "admin": "Admin",
    "actions": "Actions",
    "delete_confirmation": "Are you sure you want to delete this user ?",
    "delete_success": "User was successfully deleted."
  },
  "fr": {
    "first_name": "Prénom",
    "last_name": "Nom",
    "email": "Email",
    "owner": "Propriétaire",
    "admin": "Admin",
    "actions": "Actions",
    "delete_confirmation": "Etes-vous sûr de vouloir supprimer cet utilisateur ?",
    "delete_success": "L'utilisateur a été supprimé avec succès."
  },
  "de": {
    "first_name": "Vorname",
    "last_name": "Name",
    "email": "Email",
    "owner": "Eigentümer",
    "admin": "Administrator",
    "actions": "Aktionen",
    "delete_confirmation": "Möchten Sie diesen Benutzer wirklich löschen?",
    "delete_success": "Der Benutzer wurde erfolgreich gelöscht."
  }
}
</i18n>
