<template>
  <el-card shadow="never">
    <el-row>
      <el-col :span="20">
        <div class="inputs-sections">
          <el-form
            size="mini"
            @submit.native.prevent
          >
            <classAdwordsTree
              v-model="classAdwords"
              ref="classAdwordsRef"
              class="filters-form-item"
            ></classAdwordsTree>
          </el-form>
          <el-form
            size="mini"
            @submit.native.prevent
          >
            <filters
              v-model="keywordFilters"
              class="filters-form-item"
            ></filters>
          </el-form>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="action">
          <el-button
            plain
            type="primary"
            @click="submit"
            >{{ $t("search") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import ClassAdwordsTree from "@/pages/KeywordsSearch/Shared/FormItems/ClassAdwordsTree";
import Filters from "@/pages/KeywordsSearch/Shared/FormItems/Filters";

export default {
  components: {
    ClassAdwordsTree,
    Filters,
  },
  data() {
    return {
      keywordFilters: [],
      classAdwords: [],
    };
  },
  methods: {
    submit() {
      const request = {
        computeKeywordsBroad: true,
        fromClassAdwords: this.classAdwords,
        excludeKeywords: this.keywordFilters,
      };
      this.$emit("submit", request);
    },
  },
};
</script>

<style scoped lang="scss">
.el-card .el-form {
  margin-bottom: 0rem;
}

.action {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "search": "Search"
  },
  "fr": {
    "search": "Rechercher"
  },
  "de": {
    "search": "Forschen"
  }
}
</i18n>
