import Repository from "@/services/repository";

export default {
  fetchRecommendations(studyId, keywordId) {
    return Repository.get(
      `/a/${studyId}/keywords/${keywordId}/recommendations.json`,
      {}
    ).then((response) => response.data);
  },
};
