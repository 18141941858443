<template>
  <div class="comment-container">
    <div
      class="actions"
      v-if="currentUserIsAuthor"
    >
      <div v-if="!editing">
        <el-link
          class="edit"
          icon="el-icon-edit"
          @click="startEditing"
        >
          {{ $t("edit") }}
        </el-link>
        <el-link
          class="delete"
          icon="el-icon-delete-solid"
          @click="deleteComment"
        >
          {{ $t("delete") }}
        </el-link>
      </div>
      <div v-else>
        <el-link
          class="cancel"
          @click="cancelChanges"
        >
          <i class="fas fa-times"></i> {{ $t("cancel") }}
        </el-link>
        <el-link
          class="save"
          icon="el-icon-check"
          @click="saveEdit"
        >
          {{ $t("save") }}
        </el-link>
      </div>
    </div>
    <div class="comment">
      <el-tooltip
        v-if="user"
        :content="authorFullName"
        placement="top"
      >
        <div class="user">{{ authorInitials }}</div>
      </el-tooltip>
      <div class="editor border-light-grey">
        <TiptapEditor
          v-model="commentText"
          :isEditable="currentUserIsAuthor && editing"
          :isEditing="editing"
        >
        </TiptapEditor>
      </div>
    </div>
    <div
      class="date-last-update"
      v-if="commentHasADate"
    >
      {{ lastUpdateDateString() }}
    </div>
  </div>
</template>

<script>
import TiptapEditor from "@/components/TiptapV2";

export default {
  props: {
    value: [String, Object],
    creatingComment: Boolean,
    currentUser: Object,
    users: Array,
  },
  components: {
    TiptapEditor,
  },
  data() {
    return {
      editing: false,
      oldValue: this.value ? this.value.data : null,
      commentText: this.value ? this.value.data : null,
      author: {
        firstName: "",
        lastName: "",
      },
    };
  },
  methods: {
    deleteComment() {
      this.$emit("delete-comment", this.value);
    },
    startEditing() {
      this.editing = true;
      this.oldValue = this.commentText;
    },
    saveEdit() {
      this.editing = false;
      this.value.data = this.commentText;
      this.oldValue = this.commentText;
      this.value.updatedAt = new Date();
      this.$emit("on-save-comment", this.value);
    },
    cancelChanges() {
      if (!this.creatingComment) {
        this.commentText = this.oldValue;
        this.editing = false;
      } else {
        this.commentText = this.oldValue;
      }
    },
    update(comment) {
      this.$emit("input", comment);
    },
    lastUpdateDateString() {
      if (this.commentHasADate) {
        const date = new Date(this.value.updatedAt);
        return this.$i18n.t("last_update", {
          dateFormatted: date.toLocaleString(),
        });
      }
    },
  },
  computed: {
    currentUserIsAuthor() {
      return this.value && this.currentUser.id === this.value.userId;
    },
    user() {
      if (this.creatingComment) {
        return {
          firstName: this.currentUser.first_name,
          lastName: this.currentUser.last_name,
        };
      } else {
        if (this.users) {
          const user = this.users.find((user) => user.id === this.value.userId);
          if (user) {
            // const userNames = user.label.split(' ')
            return { firstName: user.firstName, lastName: user.lastName };
          }
        }
        return null;
      }
    },
    authorInitials() {
      return this.user.firstName.charAt(0) + "." + this.user.lastName.charAt(0);
    },
    authorFullName() {
      return this.user.firstName + " " + this.user.lastName;
    },
    commentHasADate() {
      if (!this.creatingComment) {
        return this.value.updatedAt || this.value.createdAt;
      }
      return false;
    },
  },
  mounted() {
    if (this.creatingComment) {
      this.update({
        userId: this.currentUser.id,
        type: "comment",
      });
      this.author = {
        firstName: this.currentUser.first_name,
        lastName: this.currentUser.last_name,
      };
      this.editing = true;
    }
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/variables";

.comment-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 1.5rem;

  .actions {
    margin-left: auto;
  }

  .delete {
    margin-left: 5px;
  }

  .edit {
    margin-left: auto;
  }

  .save {
    margin-left: 5px;
  }

  .cancel {
    margin-left: auto;
  }

  .date-last-update {
    margin-left: auto;
    font-style: italic;
    font-size: 0.8rem;
  }

  .comment {
    display: flex;
    width: 100%;

    .user {
      margin-right: 1rem;
      background-color: $--color-info-light;
      border-radius: 25em;
      height: 28px;
      width: 28px;
      line-height: 28px;
      text-align: center;
      font-weight: 500;
    }

    .border-light-grey {
      border: 1px solid #dcdfe6;
      border-radius: 5px;
      padding: 12px;
    }

    .editor {
      height: 100%;
      width: 100%;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "description": "Description",
    "save": "Save",
    "edit": "Edit",
    "cancel": "Cancel",
    "delete": "Delete",
    "last_update": "Last update - %{dateFormatted}"
  },
  "fr": {
    "description": "Description",
    "save": "Sauvegarder",
    "edit": "Modifier",
    "cancel": "Annuler",
    "delete": "Supprimer",
    "last_update": "Dernière édition - %{dateFormatted}"
  },
  "de": {
    "description": "Beschreibung",
    "save": "Zu schützen",
    "edit": "Modifikator",
    "cancel": "Stornieren",
    "delete": "LÖSCHEN",
    "last_update": "Zuletzt bearbeitet - %{dateFormatted}"
  }
}
</i18n>
