<template>
  <div>
    <div
      class="warning"
      v-if="showWarningData"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('warningMsgData')"
        placement="right-start"
      >
        <i class="el-icon-warning"> </i>
      </el-tooltip>
    </div>
    <div
      class="warning"
      v-else-if="showWarningFolder"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('warningMsgFolder', [host])"
        placement="right-start"
      >
        <i class="el-icon-warning"> </i>
      </el-tooltip>
    </div>
    <div
      class="flex-center"
      v-if="metrics"
    >
      <div>
        <div
          v-if="metrics.referringDomainsWithEquity"
          class="value"
        >
          {{ metrics.referringDomainsWithEquity | formatNumber }}
        </div>
        <div
          v-else
          class="text"
        >
          --
        </div>
        <div class="small-text">
          {{ $t("referringDomains") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    metrics: Object,
    host: String,
    showWarningData: Boolean,
    showWarningFolder: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.flex-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .value {
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  .small-text {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-align: center;
  }
}

.warning {
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  color: #e6a23c;
}
</style>
<i18n>
{
  "en": {
    "referringDomains": "Referring domains",
    "warningMsgData": "Data unavailable",
    "warningMsgFolder": " This report shows the number referring domains of {0}"
  },
  "fr": {
    "referringDomains": "Domaines référents",
    "warningMsgData": "Données indisponibles",
    "warningMsgFolder": " Ce rapport affiche le nombre de domaines référents du {0}"
  },
  "de": {
    "referringDomains": "Verweisende Domains",
    "warningMsgData": "Keine Daten verfügbar",
    "warningMsgFolder": "Dieser Bericht zeigt die Anzahl der verweisenden Domains von {0}"
  }
}
</i18n>
