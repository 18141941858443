import axios from "axios";
import { RepositoryFactory } from "@/services/repositoryFactory";

const UsersRepository = RepositoryFactory.get("users");

class Client {
  async getInternalClient() {
    if (
      !sessionStorage.hasOwnProperty("acccessTokenResponse") ||
      sessionStorage.acccessTokenResponse == "undefined" ||
      Date.now() / 1000 + 5 * 60 >
        JSON.parse(sessionStorage.acccessTokenResponse).expires_at
    ) {
      const acccessTokenResponse = await this.getAccessToken();
      sessionStorage.acccessTokenResponse =
        JSON.stringify(acccessTokenResponse);
    }

    return axios.create({
      baseURL: "https://" + process.env.API_HOST,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(sessionStorage.acccessTokenResponse).access_token,
      },
      timeout: 60000,
    });
  }

  getAccessToken() {
    let refreshToken = this.getRefreshToken();
    let params = {};

    if (refreshToken) {
      params = {
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      };
    } else {
      refreshToken = this.getRefreshTokenCookie();
      if (refreshToken) {
        params = {
          refresh_token: refreshToken,
        };
      } else {
        return axios
          .post("/rails/oauth/token", null, {
            headers: {
              "X-CSRF-Token": document
                .querySelector("meta[name='csrf-token']")
                .getAttribute("content"),
            },
          })
          .then((response) => response.data);
      }
    }
    return axios
      .post("https://" + process.env.API_HOST + "/oauth/token", null, {
        params: params,
        timeout: 60000,
      })
      .then((response) => response.data)
      .catch(() => {
        UsersRepository.logoutUser()
          .then((data) => {
            window.location = "/users/sign_in";
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }

  getRefreshToken() {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return refreshToken;
  }

  getRefreshTokenCookie() {
    const cookie = document.cookie;
    const parts = cookie.split(" ");
    const refreshTokenCookie = parts
      .map((part) => part.split("="))
      .find(([key, value]) => key === "refresh-token");
    if (refreshTokenCookie && refreshTokenCookie.length >= 2) {
      return refreshTokenCookie[1].trim();
    } else {
      return null;
    }
  }
}

const client = new Client();

export default {
  get(...params) {
    return client
      .getInternalClient()
      .then((internalClient) => internalClient.get(...params));
  },
  post(...params) {
    return client
      .getInternalClient()
      .then((internalClient) => internalClient.post(...params));
  },
  put(...params) {
    return client
      .getInternalClient()
      .then((internalClient) => internalClient.put(...params));
  },
  patch(...params) {
    return client
      .getInternalClient()
      .then((internalClient) => internalClient.patch(...params));
  },
  delete(...params) {
    return client
      .getInternalClient()
      .then((internalClient) => internalClient.delete(...params));
  },
};
