<template>
  <div>
    <a
      class="no-decoration-on-print"
      v-if="!params.data.isHost"
      target="_blank"
      :href="`/a/${params.context.studyId}/competitors/${params.data.id}`"
      >{{ params.value }}</a
    >
    <span
      v-else
      class="host"
      >{{ params.value }}</span
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.host {
  color: #28a745;
}

@media print {
  .no-decoration-on-print {
    text-decoration: none !important;
    pointer-events: none !important;
    cursor: default !important;
    color: black;
  }
}
</style>
