<template>
  <span>
    <el-dialog
      :title="$t('title')"
      :visible.sync="dialogVisible"
      :destroy-on-close="false"
      :show-close="false"
      :close-on-click-modal="false"
      width="75%"
      v-loading="isLoading"
    >
      <el-alert
        v-if="showError"
        :title="$t('errorMessage')"
        type="error"
        class="my-2"
      >
      </el-alert>
      <el-form
        ref="gaForm"
        :model="enteredData"
        :rules="validationRules"
        label-width="120px"
      >
        <el-form-item
          :label="$t('account')"
          prop="account"
        >
          <el-input
            :placeholder="$t('accountPlaceHolder')"
            v-model.number="enteredData.account"
          />
        </el-form-item>
        <el-form-item
          :label="$t('property')"
          prop="property"
        >
          <el-input
            :placeholder="$t('propertyPlaceHolder')"
            v-model="enteredData.property"
          />
        </el-form-item>
        <el-form-item
          :label="$t('view')"
          prop="view"
        >
          <el-input
            :placeholder="$t('viewPlaceHolder')"
            v-model.number="enteredData.view"
          />
        </el-form-item>
        <div class="d-flex flex-row justify-content-end mt-3">
          <el-button
            @click="closeDialog()"
            plain
          >
            {{ $t("cancel") }}</el-button
          >
          <el-button
            @click="submitData('gaForm')"
            type="primary"
          >
            <span v-if="isCreate"> {{ $t("connect") }} </span>
            <span v-else> {{ $t("update") }} </span>
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </span>
</template>

<script>
export default {
  props: {
    type: Object,
    attributes: Object,
    isCreate: Boolean,
    value: Boolean,
    isLoading: Boolean,
    showError: Boolean,
  },

  data() {
    return {
      enteredData: this.attributes,
      validationRules: {
        account: [
          {
            required: true,
            message: this.$t("accountRequired"),
            trigger: "change",
          },
          {
            type: "number",
            message: this.$t("accountNeedToBeInteger"),
          },
        ],
        property: [
          {
            required: true,
            message: this.$t("propertyRequired"),
            trigger: "change",
          },
        ],
        view: [
          {
            required: true,
            message: this.$t("viewRequired"),
            trigger: "change",
          },
          {
            type: "number",
            message: this.$t("viewNeedToBeInteger"),
          },
        ],
      },
    };
  },
  methods: {
    submitData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let configuration = {
            data: {
              type: this.type.value,
              attributes: {
                account: this.enteredData.account,
                property: this.enteredData.property.trim(),
                view: this.enteredData.view,
              },
            },
          };
          this.$emit("send-ga-configuration", configuration);
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      this.$emit("close-view-dialog");
    },
    checkThatItIsANumber(rule, value, callback) {
      if (!Number.isInteger(value)) {
        callback(new Error(this.$t("fieldNeedToBeInteger")));
      }
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
};
</script>
<i18n>
{
  "en": {
    "title": "Configuration Google Analytics",
    "connectManually": "Connect manually",
    "account": "Account",
    "property": "Property",
    "view": "View",
    "accountPlaceHolder": "Example: 19****82",
    "propertyPlaceHolder": "Example: UA-19****82-2",
    "viewPlaceHolder": "Example: 13******0",
    "connect": "Connect",
    "update": "Update",
    "cancel": "Cancel",
    "errorMessage": "An error has occurred, check that your Google Analytics configuration is correct",
    "viewRequired": "The view field is required",
    "propertyRequired": "The property field is required",
    "accountRequired": "The account field is required",
    "accountNeedToBeInteger": "The account field must be a number",
    "viewNeedToBeInteger": "The view field must be a number"
  },
  "fr": {
    "title": "Google Analytics Configuration",
    "connectManually": "Connectez manuellement",
    "account": "Compte",
    "property": "Propriété",
    "view": "Vue",
    "accountPlaceHolder": "Exemple: 19*****82",
    "propertyPlaceHolder": "Exemple: UA-19*****82-2",
    "viewPlaceHolder": "Exemple: 13******0",
    "connect": "Se Connecter",
    "update": "Mettre à jour",
    "cancel": "Annuler",
    "errorMessage": "Une erreur est survenue, vérifier que votre configuration Google Analytics est correcte",
    "viewRequired": "Le champ de vue est obligatoire",
    "propertyRequired": "Le champ de propriété est obligatoire",
    "accountRequired": "Le champ du compte est obligatoire",
    "accountNeedToBeInteger": "Le champ du compte doit être un nombre",
    "viewNeedToBeInteger": "Le champ de vue doit être un nombre"
  },
  "de": {
    "title": "Google Analytics-Konfiguration",
    "connectManually": "Manuell verbinden",
    "account": "Konto",
    "property": "Eigentum",
    "view": "Gesehen",
    "accountPlaceHolder": "Beispiel: 19*****82",
    "propertyPlaceHolder": "Zum Beispiel: UA-19*****82-2",
    "viewPlaceHolder": "Beispiel: 13******0",
    "connect": "Einloggen",
    "update": "Aktualisieren",
    "cancel": "Stornieren",
    "errorMessage": "Ein Fehler ist aufgetreten, überprüfen Sie, ob Ihre Google Analytics-Konfiguration korrekt ist",
    "viewRequired": "Sichtfeld ist erforderlich",
    "propertyRequired": "Das Eigenschaftsfeld ist erforderlich",
    "accountRequired": "Kontofeld ist erforderlich",
    "accountNeedToBeInteger": "Das Kontofeld muss eine Zahl sein",
    "viewNeedToBeInteger": "Sichtfeld muss eine Zahl sein"
  }
}
</i18n>
