<template>
  <div>
    <div class="export">
      <el-button
        size="mini"
        @click="exportExcel()"
      >
        <i class="fas fa-file-excel"></i> {{ $t("export") }}
      </el-button>
    </div>
    <ag-grid-vue
      style="height: 650px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :resizable="true"
      :filter="true"
      :floatingFilter="true"
      :localeTextFunc="localeTextFunc"
      :suppressCellSelection="true"
      @gridReady="onGridReady"
      @gridSizeChanged="adjustGrid"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import NoFollowRenderer from "@/components/gridRenderers/NoFollowRenderer";
import WebsiteRenderer from "@/components/gridRenderers/WebsiteRenderer";

export default {
  mixins: [agGridMixin],
  components: {
    AgGridVue,
    NoFollowRenderer,
    WebsiteRenderer,
  },
  props: {
    dataTopBacklinks: Object,
  },
  data() {
    return {
      columnDefs: null,
      defaultColDef: { sortable: true, resizable: true },
      rowData: null,
      gridApi: null,
      columnApi: null,
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    adjustGrid() {
      if (this.columnApi.columnController.scrollWidth > 0) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("url_source"),
          field: "urlSource",
          filter: "agTextColumnFilter",
          minWidth: 500,
          cellRendererFramework: "WebsiteRenderer",
        },
        {
          headerName: this.$i18n.t("trust_flow"),
          field: "trustFlow",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
        },
        {
          headerName: this.$i18n.t("citation_flow"),
          field: "citationFlow",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: numberFormatter,
        },
        {
          headerName: this.$i18n.t("no_follow"),
          field: "noFollow",
          type: "numericColumn",
          cellRendererFramework: "NoFollowRenderer",
        },
      ];
    },
    mapData() {
      if (!this.dataTopBacklinks || !this.dataTopBacklinks.data) {
        this.rowData = [];
      } else {
        if (this.dataTopBacklinks.data.length === 1) {
          this.rowData =
            this.dataTopBacklinks.data[0].attributes.urlBacklinkData.map(
              (backlink) => {
                return {
                  urlSource: backlink.sourceUrl.url,
                  domainAuthority: backlink.sourceUrl.metrics.domainAuthority,
                  citationFlow: backlink.sourceUrl.metrics.citationFlow,
                  trustFlow: backlink.sourceUrl.metrics.trustFlow,
                  noFollow: backlink.noFollow ? true : false,
                };
              }
            );
        } else {
          this.rowData = [];
        }
      }
    },
    exportExcel() {
      const params = {
        skipHeader: false,
        onlySelected: false,
        fileName: "Top backlinks - SmartKeyword",
        sheetName: "Top backlinks",
        allColumns: true,
      };

      this.gridApi.exportDataAsExcel(params);
    },
  },
  beforeMount() {
    this.setColumnDefs();
  },
  mounted() {
    this.mapData();
  },
  watch: {
    dataTopBacklinks() {
      this.mapData();
    },
  },
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};
let numberFormatter = (params) => formatNumber(params.value);
</script>

<style scoped lang="scss">
.export {
  text-align: right;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "url_source": "Url source",
    "page_authority": "Page authority",
    "domain_authority": "Domain authority",
    "citation_flow": "Citation flow",
    "trust_flow": "Trust flow",
    "no_follow": "No follow ?",
    "export": "Export"
  },
  "fr": {
    "url_source": "Url source",
    "page_authority": "Page authority",
    "domain_authority": "Domain authority",
    "citation_flow": "Citation flow",
    "trust_flow": "Trust flow",
    "no_follow": "No follow ?",
    "export": "Exporter"
  },
  "de": {
    "url_source": "URL-Quelle",
    "page_authority": "Seitenautorität",
    "domain_authority": "Domänenautorität",
    "citation_flow": "Zitierfluss",
    "trust_flow": "Vertrauensfluss",
    "no_follow": "Nein folgen?",
    "export": "Exporteur"
  }
}
</i18n>
