<template>
  <div>
    <app-introduction :title="$t('add_keywords_via_file')"></app-introduction>

    <slot></slot>
  </div>
</template>

<script>
import AppIntroduction from "@/components/AppIntroduction";

export default {
  components: {
    AppIntroduction,
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "add_keywords_via_file": "Add / Edit keywords per file"
  },
  "fr": {
    "add_keywords_via_file": "Ajout / Édition de mots clés via un fichier Excel"
  },
  "de": {
    "add_keywords_via_file": "Hinzufügen / Bearbeiten von Schlüsselwörtern über eine Excel-Datei"
  }
}
</i18n>
