<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="75%"
      top="5vh"
    >
      <div slot="title">
        <h3 class="title">{{ $t("edit_backlink") }}</h3>
      </div>
      <dynamic-form
        v-model="updatedBacklink"
        :form="data.form"
        :default-search-engine="defaultSearchEngine"
        @isDomainSourceCorrectlyFormatted="
          handleDomainSourceIncorrectlyFormatted($event)
        "
        @isTargetUrlCorrectlyFormattedOrEmpty="
          handleTargetUrlIncorrectlyFormatted($event)
        "
      >
      </dynamic-form>
      <div class="submit">
        <el-button
          :disabled="
            !isTargetUrlCorrectlyFormattedOrEmpty ||
            !isDomainSourceCorrectlyFormatted
          "
          type="success"
          plain
          @click="submit()"
        >
          {{ $t("validate") }}
        </el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import DynamicForm from "@/components/DynamicForm";

export default {
  props: {
    value: Boolean,
    data: Object,
    defaultSearchEngine: Object,
  },
  components: {
    DynamicForm,
  },
  data() {
    return {
      updatedBacklink: {},
      isTargetUrlCorrectlyFormattedOrEmpty: false,
      isDomainSourceCorrectlyFormatted: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
  methods: {
    submit() {
      let details = {};
      details.entity = this.updatedBacklink;
      details.link = this.data;
      this.$emit("update-backlink", details);
      this.dialogVisible = false;
    },
    handleDomainSourceIncorrectlyFormatted(isDomainSourceCorrectlyFormatted) {
      this.isDomainSourceCorrectlyFormatted = isDomainSourceCorrectlyFormatted;
    },
    handleTargetUrlIncorrectlyFormatted(isTargetUrlCorrectlyFormattedOrEmpty) {
      this.isTargetUrlCorrectlyFormattedOrEmpty =
        isTargetUrlCorrectlyFormattedOrEmpty;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  overflow-y: scroll;
  max-height: 700px;

  .fat-divider {
    height: 3px;
  }

  .collapse-form {
    margin-bottom: 2rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
}

.title {
  text-align: center;
}

.submit {
  margin-top: 1rem;
  text-align: center;
}
</style>

<i18n>
{
  "en": {
    "edit_backlink": "Edit Backlink",
	"validate": "Validate"
  },
  "fr": {
    "edit_backlink": "Backlink",
	"validate": "Valider"
  },
  "de": {
    "edit_backlink": "Rückverlinkung",
	"validate": "Bestätigen"
  }
}
</i18n>
