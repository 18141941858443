<template>
  <span>
    <el-dialog
      :visible.sync="dialogVisible"
      width="66%"
      :before-close="refusePoll"
      :close-on-click-modal="false"
    >
      <div
        v-if="step == 0"
        class="rate-section"
      >
        <h4 class="question-title">
          {{ $t("how_likely_you_recommend_question") }}
        </h4>
        <span class="ratings">
          <span class="text-explication">( {{ $t("unlikely") }} )</span>
          <el-radio-group
            v-model="npsResponse.rating"
            size="medium"
          >
            <el-radio-button
              v-for="rate in ratingPossibilities"
              v-bind:key="rate"
              :label="rate"
            >
            </el-radio-button>
          </el-radio-group>
          <span class="text-explication">( {{ $t("very_liekly") }} )</span>
        </span>
      </div>

      <div
        v-if="step == 1"
        class="feedback-section"
      >
        <h4 class="thank-you-title">{{ $t("thank_you") }}</h4>
        <div class="comment-explanation">{{ $t("additional_comments") }}</div>

        <el-card class="what-you-like">
          <h5 class="what-you-like-title">{{ $t("what_you_like") }}</h5>
          <el-checkbox-group
            v-model="npsResponse.additional.positive.likedFeatures"
          >
            <el-checkbox
              v-for="feature in features"
              v-bind:key="feature.value"
              :label="feature.value"
            >
              {{ feature.label }}
            </el-checkbox>
          </el-checkbox-group>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :placeholder="placeHolderPositiveArea"
            v-model="npsResponse.additional.positive.feedback"
          >
          </el-input>
        </el-card>

        <el-card class="what-you-dislike">
          <h5 class="what-you-dislike-title">
            {{ $t("areas_for_improvements") }}
          </h5>
          <el-checkbox-group
            v-model="npsResponse.additional.negative.dislikedFeatures"
          >
            <el-checkbox
              v-for="feature in features"
              v-bind:key="feature.value"
              :label="feature.value"
            >
              {{ feature.label }}
            </el-checkbox>
          </el-checkbox-group>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :placeholder="placeHolderNegativeArea"
            v-model="npsResponse.additional.negative.feedback"
          >
          </el-input>
        </el-card>
      </div>

      <el-divider></el-divider>
      <div class="buttons-action">
        <el-link
          v-if="step == 0"
          class="close invisible"
          >{{ $t("skip_and_close") }}</el-link
        >
        <el-button
          v-if="step == 0"
          :disabled="npsResponse.rating == null"
          class="send"
          type="success"
          plain
          @click="nextStep"
          >{{ $t("poll_validate") }}</el-button
        >
        <el-link
          v-if="step == 0"
          class="close"
          @click="refusePoll"
          >{{ $t("skip_and_close") }}</el-link
        >
        <el-button
          v-if="step == 1"
          class="send"
          type="success"
          plain
          @click="sendPoll"
          >{{ $t("poll_send") }}</el-button
        >
      </div>
    </el-dialog>
  </span>
</template>

<script>
import { RepositoryFactory } from "@/services/repositoryFactory";
const NpsRatingsRepository = RepositoryFactory.get("npsRatingsRepository");

export default {
  components: {},
  props: {
    value: Boolean,
  },
  data() {
    return {
      step: 0,
      ratingPossibilities: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      features: [
        { value: "conseil", label: this.$i18n.t("support") },
        { value: "académie", label: this.$i18n.t("academy") },
        { value: "marketplace", label: this.$i18n.t("marketplace") },
        {
          value: "plateforme en ligne",
          label: this.$i18n.t("online_platform"),
        },
      ],
      npsResponse: {
        rating: null,
        additional: {
          positive: {
            likedFeatures: [],
            feedback: "",
          },
          negative: {
            dislikedFeatures: [],
            feedback: "",
          },
        },
        refused: false,
      },
      placeHolderPositiveArea: this.$i18n.t("tell_us_more"),
      placeHolderNegativeArea: this.$i18n.t("we_would_be_happy_to_hear_why"),
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
    sendPoll() {
      NpsRatingsRepository.savePoll(this.npsResponse).then((data) => {
        /*
				this.$message({
					message: this.$i18n.t("thanks_for_your_feedbacks"),
					type: "success",
					duration: 6000
				})
				*/
      });
      this.$emit("input", false);
    },
    refusePoll() {
      this.npsResponse.refused = true;
      NpsRatingsRepository.savePoll(this.npsResponse).then((data) => {});
      this.$emit("input", false);
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(dialogVisible) {
        this.$emit("input", dialogVisible);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.rate-section {
  text-align: center;
  margin-bottom: 2rem;

  .question-title {
    margin-bottom: 1rem;
    word-break: normal;
  }

  .ratings {
    .text-explication {
      white-space: nowrap;
    }
  }
}

.feedback-section {
  text-align: center;
  margin-bottom: 2rem;

  .question-title {
    margin-bottom: 1rem;
    word-break: normal;
  }

  .thank-you-title {
    margin-bottom: 1rem;
    word-break: normal;
  }

  .comment-explanation {
    margin-bottom: 1rem;
    word-break: normal;
  }

  .what-you-like {
    text-align: center;
    margin-bottom: 2rem;

    .what-you-like-title {
      color: $--color-success;
      margin-bottom: 1rem;
    }
  }

  .what-you-dislike {
    text-align: center;

    .what-you-dislike-title {
      color: $--color-danger;
      margin-bottom: 1rem;
    }
  }
}

.buttons-action {
  display: flex;
  justify-content: space-between;

  .invisible {
    visibility: hidden;
  }

  .close {
    margin-left: auto;
  }

  .send {
    margin: auto;
  }
}
</style>

<i18n>
{
  "en": {
    "additional_comments": "Don't hesitate to send us additional feedbacks below if you wish.",
    "how_likely_you_recommend_question": "How likely are you to recommend SmartKeyword to a friend or colleague?",
    "unlikely": "Unlikely",
    "very_likely": "Very likely",
    "what_you_like": "What you like",
    "areas_for_improvements": "Areas for improvement",
    "skip_and_close": "Skip and close",
    "poll_validate": "Send my feedback",
    "poll_send": "Validate & close",
    "support": "The support",
    "academy": "The academy",
    "formations": "The formations",
    "marketplace": "The marketplace",
    "online_platform": "The online platform",
    "thank_you": "Thank you for your feedbacks !",
    "thanks_for_your_feedbacks": "Thank you for your feedbacks !",
    "tell_us_more": "Tell us more !",
    "we_would_be_happy_to_hear_why": "We are very interested in your feedbacks"
  },
  "fr": {
    "additional_comments": "Si vous le souhaitez, vous pouvez également laisser des commentaires ci-dessous.",
    "unlikely": "Peu probable",
    "very_liekly": "Très probable",
    "how_likely_you_recommend_question": "Quelle est la probabilité que vous recommandiez SmartKeyword à un ami ou collègue ?",
    "what_you_like": "Ce que vous aimez",
    "areas_for_improvements": "Les axes d'amélioration",
    "skip_and_close": "Passer et fermer",
    "poll_validate": "Envoyer mon feedback",
    "poll_send": "Valider et fermer",
    "support": "Conseil",
    "academy": "L'académie",
    "formations": "Les formations",
    "marketplace": "La marketplace",
    "online_platform": "La plateforme en ligne",
    "thank_you": "Merci pour vous retours !",
    "thanks_for_your_feedbacks": "Vos feedback ont bien été pris en compte, merci !",
    "tell_us_more": "Dites-nous en plus !",
    "we_would_be_happy_to_hear_why": "Nous sommes très preneur de vos feedbacks !"
  },
  "de": {
    "additional_comments": "Wenn Sie möchten, können Sie unten auch Kommentare hinterlassen.",
    "unlikely": "Unwahrscheinlich",
    "very_liekly": "Sehr wahrscheinlich",
    "how_likely_you_recommend_question": "Wie wahrscheinlich ist es, dass Sie SmartKeyword einem Freund oder Kollegen weiterempfehlen?",
    "what_you_like": "Was Du möchtest",
    "areas_for_improvements": "Verbesserungswürdige Bereiche",
    "skip_and_close": "Passieren und schließen",
    "poll_validate": "Senden Sie mein Feedback",
    "poll_send": "Bestätigen und schließen",
    "support": "Beratung",
    "academy": "die Akademie",
    "formations": "Ausbildung",
    "marketplace": "Auf dem Marktplatz",
    "online_platform": "Die Online-Plattform",
    "thank_you": "Vielen Dank für Ihr Feedback!",
    "thanks_for_your_feedbacks": "Ihr Feedback wurde berücksichtigt, danke!",
    "tell_us_more": "Erzählen Sie uns mehr!",
    "we_would_be_happy_to_hear_why": "Wir sind sehr an Ihrem Feedback interessiert!"
  }
}
</i18n>
