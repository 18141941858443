<template>
  <div>
    <app-introduction
      :title="keyword.text"
      video-link="https://www.youtube.com/watch?v=mEsvDAuXoq8"
      :documentations="[
        { link: $t('documentationLink'), description: $t('documentationDesc') },
      ]"
      :user="currentUser"
    >
      <template #sub-header>
        <span>{{ $t("searchAndClicks", { searches, clicks }) }}</span>
      </template>

      <template  #right>
        <span>
          <el-button
            type="primary"
            size="small"
            @click="showKeyword(keyword.id)"
          >
            {{ $t("showSummary") }}
          </el-button>
          <keyword-dialog
            v-if="study && keyword"
            v-model="keywordDialogVisible"
            :keyword-id="selectedKeywordId"
            :current-user="currentUser"
            :studyId="study.id"
          ></keyword-dialog>
        </span>

        <span>
          <el-button
            type="primary"
            size="small"
            @click="showTask(keyword.id, keyword.text)"
          >
            {{ $t("addToActions") }}
          </el-button>
          <el-dialog
            width="62%"
            :visible.sync="taskDialogVisible"
          >
            <task-creation-form
              v-if="study"
              :study-id="study.id"
              :title="$t('workOnKeyword', { keywordText: keyword.text })"
              :keywords="[{ text: selectedKeywordText, id: selectedKeywordId }]"
              :current-user="currentUser"
              :user-options="userOptions"
              :assigned-to-user-id="currentUser.id"
              @on-created-task="taskDialogVisible = false"
            ></task-creation-form>
          </el-dialog>
        </span>

        <span v-if="googleResult">
          <el-button
            type="primary"
            size="small"
            :loading="refreshDataLoading"
            @click="refreshData"
          >
            <i class="fas fa-sync-alt fa-fw"></i>
            <span>{{ $t("refresh_keyword_data") }}</span>
          </el-button>
        </span>

        <div
          v-if="crawl"
          class="my-2 text-right"
        >
          <small>{{ $t("pageLastAnalysis", { date: crawl.date }) }}</small>
          <div v-if="crawl.httpStatus == 200 || crawl.httpStatus == 403">
            <div v-if="crawl.httpStatus == 403">
              <p class="message">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('crawlerNotAuthorizedMsg')"
                  placement="bottom-start"
                >
                  <span
                    ><i class="el-icon-warning"></i
                    >{{ $t("crawlerNotAuthorized") }}</span
                  >
                </el-tooltip>
              </p>
            </div>
          </div>
          <div v-else>
            <p class="message">
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('crawlerNotPassedMsg')"
                placement="bottom-start"
              >
                <i class="el-icon-warning"></i>{{ $t("crawlerProblem") }}
              </el-tooltip>
            </p>
          </div>
        </div>
        <div
          v-else
          class="my-2 text-right"
        >
          <p class="message">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('crawlerNotPassedMsg')"
              placement="bottom-start"
            >
              <i class="el-icon-warning"></i>{{ $t("crawlerNotPassed") }}
            </el-tooltip>
          </p>
        </div>
      </template>
    </app-introduction>

    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

import AppIntroduction from "@/components/AppIntroduction";
import KeywordDialog from "@/pages/KeywordDialog";
import TaskCreationForm from "@/components/task/TaskCreationForm";

import { RepositoryFactory } from "@/services/repositoryFactory";
import base64url from "base64url";

const RefreshDataRepository = RepositoryFactory.get("refreshData");
const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  components: {
    AppIntroduction,
    KeywordDialog,
    TaskCreationForm,
  },
  props: {
    keyword: Object,
    searches: String,
    clicks: String,
    currentUser: Object,
    googleResult: Object,
    companyId: Number,
  },
  data() {
    return {
      selectedKeywordId: null,
      keywordDialogVisible: false,
      selectedKeywordText: "",
      taskDialogVisible: false,
      refreshDataLoading: false,
      userOptions: [],
      crawl: null,
    };
  },
  computed: {
    ...mapState(["study"]),
  },
  methods: {
    fetchUsers() {
      CompaniesRepository.getCompanyUsersForTasks(this.companyId).then(
        (data) => {
          this.userOptions = data.data.map((user) => ({
            id: parseInt(user.id),
            firstName: user.attributes.firstName,
            lastName: user.attributes.lastName,
          }));
        }
      );
    },
    showKeyword(keywordId) {
      this.selectedKeywordId = keywordId;
      this.keywordDialogVisible = true;
    },
    showTask(keywordId, keywordText) {
      this.selectedKeywordId = keywordId;
      this.selectedKeywordText = keywordText;
      this.taskDialogVisible = true;
    },
    refreshData() {
      this.refreshDataLoading = true;
      RefreshDataRepository.refreshData(this.study.id, this.keyword.id)
        .then(() => {
          this.refreshDataLoading = false;
          location.reload();
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("fail"),
            type: "error",
            duration: 6000,
          });
          this.refreshDataLoading = false;
        });
    },
    getCrawlMeta() {
      if (this.googleResult && this.googleResult["url"]) {
        const encodedUrl = base64url(this.googleResult["url"]);
        this.$api
          .get(`/pages/url/${encodedUrl}`, {
            headers: { "Accept-Language": this.$i18n.locale },
          })
          .then((response) => {
            this.crawl = response.data.meta.crawl;
          })
          .catch(() => {
            this.$message({
              message: this.$i18n.t("failPage"),
              type: "error",
              duration: 6000,
            });
          });
      }
    },
  },
  created() {
    this.getCrawlMeta();
    this.fetchUsers();
  },
};
</script>

<style scoped>
.message {
  font-size: 1.3rem;
}
</style>

<i18n>
{
  "en": {
    "addToActions": "Add a task",
    "documentationDesc": "How to work on a keyword ?",
    "fail": "Data refresh failed",
    "failPage": "Get crawl information failed",
    "pageLastAnalysis": "Last analysis of the page on: %{date}",
    "refresh_keyword_data": "Refresh keyword data",
    "searchAndClicks": "%{searches} monthly searches - %{clicks} / clicks on Google Adwords",
    "showSummary": "Show keyword's SERP",
    "success": "Data refresh is ongoing, please visit back in a few minutes..",
    "workOnKeyword": "Work on keyword: %{keywordText}",
    "documentationLink": "http://docs.smartkeyword.io/guide-smartkeyword/mots-cles/comment-travailler-un-mot-cle-grace-a-smartkeyword",
    "crawlerNotPassed": "The crawler has not passed yet",
    "crawlerNotPassedMsg": "A problem occurred during the crawl, try to refresh the keyword data",
    "crawlerNotAuthorized": "Crawl has been blocked",
    "crawlerNotAuthorizedMsg": "Your server has blocked our crawler, contact us for more information",
    "crawlerProblem": "A problem occurred during the crawl"
  },
  "fr": {
    "addToActions": "Ajouter une tâche",
    "documentationDesc": "Comment travailler un mot clé ?",
    "fail": "Le rafraîchissement de données est a échoué",
    "failPage": "Récupération des information du crawler échoué",
    "pageLastAnalysis": "Dernière analyse de la page: %{date}",
    "refresh_keyword_data": "Rafraîchir les données liées au mot clé",
    "searchAndClicks": "%{searches} recherches mensuelles - %{clicks} / clic sur Google Adwords",
    "showSummary": "Afficher les positions et la SERP du mot-clé",
    "success": "Le rafraîchissement de données est en cours, veuillez revenir dans quelques minutes..",
    "workOnKeyword": "Travailler le mot clé : %{keywordText}",
    "documentationLink": "http://docs.smartkeyword.io/guide-smartkeyword/mots-cles/comment-travailler-un-mot-cle-grace-a-smartkeyword",
    "crawlerNotPassed": "Le crawler n'est pas encore passé",
    "crawlerNotPassedMsg": "Un problème est survenu lors du crawl, tentez de rafraîchir les données liées au mot clé",
    "crawlerNotAuthorized": "Le crawl n'a pas été autorisé",
    "crawlerNotAuthorizedMsg": "Votre serveur a bloqué notre crawler, contactez-nous pour plus d’informations",
    "crawlerProblem": "Un problème est survenu lors du crawl"
  },
  "de": {
    "addToActions": "Aufgabe hinzufügen",
    "documentationDesc": "Wie arbeitet man an einem Schlüsselwort?",
    "fail": "Die Datenaktualisierung ist fehlgeschlagen",
    "failPage": "Fehler beim Abrufen von Crawler-Informationen",
    "pageLastAnalysis": "Crawlen der letzten Seite: %{date}",
    "refresh_keyword_data": "Aktualisieren Schlüsselwort Daten",
    "searchAndClicks": "%{searches} monatliche Suchanfragen - %{clicks} / Klick auf Google Adwords",
    "showSummary": "Zeigen Keyword-Positionen und SERP",
    "success": "Die Datenaktualisierung wird durchgeführt, bitte versuchen Sie es in ein paar Minuten erneut.",
    "workOnKeyword": "Bearbeiten Sie das Schlüsselwort: %{keywordText}",
    "documentationLink": "http://docs.smartkeyword.io/guide-smartkeyword/mots-cles/comment-travailler-un-mot-cle-grace-a-smartkeyword",
    "crawlerNotPassed": "Der Crawler ist noch nicht vorbei",
    "crawlerNotPassedMsg": "Beim Crawlen ist ein Problem aufgetreten. Versuchen Sie, die Daten zum Keyword zu aktualisieren",
    "crawlerNotAuthorized": "Kriechen war nicht erlaubt",
    "crawlerNotAuthorizedMsg": "Ihr Server hat unseren Crawler blockiert, kontaktieren Sie uns für weitere Informationen",
    "crawlerProblem": "Beim Crawlen ist ein Problem aufgetreten"
  }
}
</i18n>
