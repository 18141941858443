<template>
  <div>
    <h4><i class="fas fa-user-tag"></i> {{ $t("followedBy") }}</h4>
    <ul v-if="displayedWatchers && displayedWatchers.length > 0">
      <li
        v-for="watcher in displayedWatchers"
        v-bind:key="watcher[valueKey]"
      >
        <el-tag
          :closable="deletableWatcherValueKey.includes(watcher[valueKey])"
          @close="deleteWatcher(watcher)"
        >
          <span>
            {{ watcher.firstName + " " + watcher.lastName }}
          </span>
        </el-tag>
      </li>
    </ul>
    <el-select
      class="value"
      v-model="watcher"
      :placeholder="$t('watchersPlaceholder')"
      filterable
      size="medium"
      @input="addWatcher()"
      @click.native="$emit('click')"
    >
      <el-option
        v-for="watcher in watchersOptions"
        :key="watcher[valueKey]"
        :label="nameDisplay(watcher)"
        :value="watcher"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    displayedWatchers: Array,
    studyId: Number,
    removeWatcherAction: Object,
    addWatcherAction: Object,
    currentUser: Object,
  },
  data() {
    return {
      watcherIds: this.value, // this is to hack watchers display
      taskCreationWatchersOptions: null,
      watcher: null,
    };
  },
  computed: {
    watchersOptions() {
      if (this.addWatcherAction) {
        return this.addWatcherAction.hrefVars.watcherId.options;
      } else {
        return this.taskCreationWatchersOptions;
      }
    },
    valueKey() {
      if (this.addWatcherAction) {
        return this.addWatcherAction.hrefVars.watcherId.valueKey;
      } else {
        return "id";
      }
    },
    deletableWatcherValueKey() {
      if (this.removeWatcherAction) {
        return this.removeWatcherAction.hrefVars.watcherId.options.map(
          (watcher) => watcher[this.valueKey]
        );
      } else {
        return this.watcherIds;
      }
    },
  },
  methods: {
    addWatcher() {
      if (this.watcher) {
        this.watcherIds.push(this.watcher.id);
        this.displayedWatchers.push(this.watcher);

        if (this.addWatcherAction) {
          let hrefVarName =
            this.addWatcherAction.hrefTemplate.match(/\{(.*)\}/);
          let hrefVarValue =
            this.watcher[
              this.addWatcherAction.hrefVars[hrefVarName[1]].valueKey
            ];
          const apiCall = {
            href: this.addWatcherAction.hrefTemplate.replace(
              /\{(.*)\}/,
              hrefVarValue
            ),
            method: this.addWatcherAction.method,
          };
          this.$emit("input", apiCall);
        }

        this.watcher = null;
      }
    },
    deleteWatcher(watcher) {
      this.watcherIds.splice(this.watcherIds.indexOf(watcher.id), 1);
      this.displayedWatchers.splice(this.displayedWatchers.indexOf(watcher), 1);

      if (this.removeWatcherAction) {
        let hrefVarName =
          this.removeWatcherAction.hrefTemplate.match(/\{(.*)\}/);
        let hrefVarValue =
          watcher[this.removeWatcherAction.hrefVars[hrefVarName[1]].valueKey];
        const apiCall = {
          href: this.removeWatcherAction.hrefTemplate.replace(
            /\{(.*)\}/,
            hrefVarValue
          ),
          method: this.removeWatcherAction.method,
        };
        this.$emit("input", apiCall);
      }
    },
    nameDisplay(watcher) {
      return watcher.firstName + " " + watcher.lastName;
    },
  },
  mounted() {
    if (!this.addWatcherAction) {
      this.$api
        .post(`/studies/${this.studyId}/users-for-tasks/search`, {
          userId: this.currentUser.id,
        })
        .then((response) => {
          this.taskCreationWatchersOptions = response.data;
        });
    }
  },
};
</script>

<style scoped>
li {
  margin-bottom: 0.35rem;
}

.is-in-database {
  cursor: pointer;
}
</style>

<i18n>
{
  "en": {
    "followedBy": "Followed by",
    "watchersPlaceholder": "Add users.."
  },
  "fr": {
    "followedBy": "Suivi par",
    "watchersPlaceholder": "Ajouter utilisateurs..."
  },
  "de": {
    "followedBy": "gefolgt von",
    "watchersPlaceholder": "Benutzer hinzufügen..."
  }
}
</i18n>
